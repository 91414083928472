import { Machine, assign } from 'xstate';

export const states = {
  introduction: 'introduction',
  fetchingRemoteConfig: 'fetchingRemoteConfig',
  fetchingConfig: 'fetchingConfig',
  choose: 'choose',
  answerSuboption: 'answerSuboption',
  confirm: 'confirm',
  acknowledgment: 'acknowledgment',
  skip: 'skip'
};

export const transitions = {
  fetchingConfig: 'fetchingConfig',
  confirm: 'confirm',
  skip: 'skip',
  choose: 'choose'
};

export const configType = {
  singleChoice: 'SURVEY_TYPE_SINGLE_CHOICE',
  multipleChoices: 'SURVEY_TYPE_MULTIPLE_CHOICES'
};

export default Machine(
  {
    id: 'survey',
    initial: states.fetchingRemoteConfig,
    context: {
      config: {},
      surveySlug: undefined,
      options: []
    },
    states: {
      [states.fetchingRemoteConfig]: {
        on: {
          '': [
            {
              cond: 'noRemoteConfigFound',
              target: states.skip
            },
            {
              target: states.introduction
            }
          ]
        }
      },
      [states.introduction]: {
        on: {
          [transitions.fetchingConfig]: states.fetchingConfig
        }
      },
      [states.fetchingConfig]: {
        invoke: {
          src: 'getSurveyConfig',
          onDone: {
            target: states.choose,
            actions: ['setConfig']
          },
          onError: {
            target: states.skip
          }
        },
        on: {
          [transitions.skip]: states.skip
        }
      },
      [states.choose]: {
        on: {
          [transitions.skip]: states.skip,
          [transitions.confirm]: {
            target: states.confirm,
            actions: ['setSelectedOptions']
          }
        }
      },
      [states.confirm]: {
        invoke: {
          src: 'answerSurvey',
          onDone: {
            target: states.acknowledgment,
            actions: ['saveAnsweredSurveyKey']
          },
          onError: {
            target: states.acknowledgment,
            actions: ['clearPersistedState']
          }
        }
      },
      [states.acknowledgment]: {
        on: {
          [transitions.skip]: states.skip
        }
      },
      [states.skip]: {
        entry: ['goForNextPage'],
        type: 'final'
      }
    }
  },
  {
    actions: {
      setConfig: assign({
        config: (_, event) => {
          return event.data;
        },
        options: (_, event) => {
          return event.data.options.map(option => {
            return {
              ...option,
              key: option.id.toString(),
              label: option.title
            };
          });
        }
      }),
      setSelectedOptions: assign({
        selectedOptions: (_, event) => {
          return event.selectedOptions;
        }
      })
    }
  }
);
