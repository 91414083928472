import React from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';

import {
  ListItem,
  ButtonBase,
  Chip,
  Box,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import {
  Error,
  Refresh as RefreshIcon,
  Done as DoneIcon,
  ArrowForward,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  Replay as ReplayIcon,
  MoreVert
} from '@material-ui/icons';
import {
  colors,
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';

import { problemReasons } from 'view/templates/delivering-problems/constants';
import { formatAddress, formatVicinity } from 'view/text-formatters';
import StatusCodeChip from 'view/atoms/status-code-chip';
import {
  statusCodes,
  isMutationStatusEqualToPackageStatus
} from 'operations/update-status';
import { mutationStates } from 'models/package-mutation';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import { splitLoggiAddress, buildLoggiAddressV2 } from 'view/utils';
import { messages } from 'operations/deadline-time';
import sendEventToAnalytics from 'operations/firebase';

import DeadlineTime from 'view/molecules/assignment-row/deadline-time';
import { pickupStateEnum } from 'operations/pickup/pickup-state-db';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import getPackageBarcode from 'view/utils/package';
import useStyles from './styles';
import AssignmentIndex from './assignment-index';

function FailedToSyncLabel({ onRetry, useNewTheme }) {
  const { colors: themeColors, typography } = useTheme();
  return (
    <>
      {useNewTheme ? (
        <Box
          data-testid="sync-status"
          display="flex"
          alignItems="center"
          px={2.5}
          py={0.75}
          bgcolor={themeColors.status.negative.light}
        >
          <Box display="flex" color={themeColors.status.negative.pure}>
            <ErrorOutlineOutlinedIcon data-testid="failed-sync-icon" />
          </Box>

          <Box ml={1.5} flexGrow={1}>
            <Typography
              variant="body2"
              style={{ fontWeight: typography.fontWeightMedium }}
            >
              {messages.failedToSyncLabel}
            </Typography>
          </Box>

          <Box>
            <Button
              style={{ color: themeColors.status.negative.pure }}
              onClick={onRetry}
            >
              {messages.sendAgainButton}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          data-testid="sync-status"
          display="flex"
          alignItems="center"
          px={2.5}
          py={0.75}
          borderRadius={8}
          bgcolor={colors.red[50]}
        >
          <Box display="flex" color={colors.red[500]}>
            <Error data-testid="failed-sync-icon" />
          </Box>

          <Box ml={1.5} flexGrow={1}>
            <Typography variant="body2">
              {messages.failedToSyncLabel}
            </Typography>
          </Box>

          <Box ml={1.5}>
            <Button style={{ color: colors.red[500] }} onClick={onRetry}>
              {messages.sendAgainButton}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
FailedToSyncLabel.propTypes = {
  onRetry: PropTypes.func.isRequired,
  useNewTheme: PropTypes.bool
};

FailedToSyncLabel.defaultProps = {
  useNewTheme: false
};

function SyncedButCanTryAgain({ onRetry, useNewTheme }) {
  const { colors: themeColors, typography } = useTheme();
  return (
    <>
      {useNewTheme ? (
        <Box
          data-testid="sync-status"
          pt={2.5}
          pb={1}
          bgcolor={themeColors.status.attention.light}
        >
          <Box
            display="flex"
            px={2.5}
            pb={1.5}
            color={themeColors.status.attention.pure}
            alignItems="center"
          >
            <ReplayIcon data-testid="index-sync-icon" />
            <Box ml={1.5} color={themeColors.neutrals.typeface.primary}>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: typography.fontWeightMedium }}
              >
                {messages.failedToSyncLabel}
              </Typography>
            </Box>
          </Box>

          <Box px={2.5} color={themeColors.neutrals.typeface.secondary}>
            <Typography variant="body1">
              {messages.tryToSyncAgainMessage}
            </Typography>
          </Box>
          <Button
            color={themeColors.neutrals.typeface.primary}
            size="medium"
            onClick={onRetry}
          >
            {messages.tryToSyncAgainButton}
          </Button>
        </Box>
      ) : (
        <Box
          data-testid="sync-status"
          p={2.5}
          borderRadius={8}
          bgcolor={colors.yellow[50]}
        >
          <Box display="flex" color={colors.yellow[500]}>
            <RefreshIcon data-testid="index-sync-icon" />
            <Box ml={1.5} color={colors.smoke[900]}>
              <Typography variant="subtitle1">{messages.syncLabel}</Typography>
            </Box>
          </Box>

          <Box pt={1.5} color={colors.smoke[900]}>
            <Typography variant="body2">
              {messages.tryToSyncAgainMessage}
            </Typography>
          </Box>

          <Box pt={2.5}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth
              onClick={onRetry}
            >
              {messages.tryToSyncAgainButton}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
SyncedButCanTryAgain.propTypes = {
  onRetry: PropTypes.func.isRequired,
  useNewTheme: PropTypes.bool
};
SyncedButCanTryAgain.defaultProps = {
  useNewTheme: false
};

function SyncingLabel() {
  return (
    <Box display="flex" alignItems="center" mb={1.25} data-testid="sync-status">
      <CircularProgress size="16px" />

      <Box ml={1} color={colors.smoke[800]}>
        <Typography variant="body2">{messages.syncingLabel}</Typography>
      </Box>
    </Box>
  );
}

function renderPickupCompletedChip(pickupCompleted) {
  if (pickupCompleted) {
    return (
      <Box pb={1}>
        <Chip
          size="small"
          label="Coleta concluída"
          deleteIcon={<DoneIcon style={{ color: colors.root[0] }} />}
          style={{ backgroundColor: colors.green[500], color: colors.root[0] }}
          onDelete={() => {}}
        />
      </Box>
    );
  }
  return null;
}

function AssignmentRow({ deadlineTime, assignment, onClick, onRetry }) {
  const newTheme = getThemeForUserType({
    userType: userTypeEnum.drivers,
    version: availableThemeVersions.drivers.v2
  });

  const enableNewAssignmentList = useFeatureSwitch(
    featureSwitches.enableNewAssignmentList
  );
  const enableNewAssignmentListByDriverRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewAssignmentList
  );

  const isListDeliveredPackagesEnabledForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByCompanyRelation
    )
  );

  const isListDeliveredPackagesEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByLastMileCompany
    )
  );

  const isListDeliveredPackagesEnabledForDriver =
    isListDeliveredPackagesEnabledForCompanyRelation &&
    isListDeliveredPackagesEnabledForDriverLMC;

  const showSyncedButTryAgainForInconsistentStatus =
    enableNewAssignmentList &&
    isListDeliveredPackagesEnabledForDriver &&
    !isMutationStatusEqualToPackageStatus(assignment);

  const showSyncedButCanTryAgainOnNewAssignmentList =
    showSyncedButTryAgainForInconsistentStatus ||
    (enableNewAssignmentList && !isListDeliveredPackagesEnabledForDriver);

  const shouldShowPackageDetailsModalByLastMileCompany = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enablePackageDetailsModalByLastMileCompany)
  );

  const showPackageDetailsModalForDriver =
    enableNewAssignmentListByDriverRelation &&
    shouldShowPackageDetailsModalByLastMileCompany;

  const showBarcodeOnAssignmentList = useFeatureSwitch(
    featureSwitches.showBarcodeOnAssignmentList
  );

  const classes = useStyles();

  const statusCode = assignment.status?.code;

  const pickupCompleted = assignment.pickupState === pickupStateEnum.completed;

  const taskCompleted = statusCode === statusCodes.DELIVERED || pickupCompleted;

  const isPickup = !!assignment.pickupFlow;

  const isSyncing = assignment.mutationSyncState === mutationStates.SYNCING;

  const hasFailedToSync =
    assignment.mutationSyncState === mutationStates.FAILED_TO_SYNC || isSyncing;
  const isSynced = assignment.mutationSyncState === mutationStates.SYNCED;

  const isUnableToGoToDeliveryLocation =
    statusCode === problemReasons.unableToGoToDeliveryLocation.id;

  const handleRetry = () => {
    if (isSynced && showSyncedButTryAgainForInconsistentStatus) {
      // send event only for inconsistent status of already synced packages
      sendEventToAnalytics('synced_but_tried_again_inconsistent_status', {
        packageId: assignment.packageId,
        mutationStatus: assignment.status?.code,
        backendStatus: assignment.backendStatus?.code,
        mutationSyncState: assignment.mutationSyncState
      });
    }
    onRetry(assignment);
  };

  const lessEmphasis = isSyncing || isSynced || taskCompleted;

  const addressLines = buildLoggiAddressV2(assignment?.addressDestination);
  const hasLoggiAddress = !!addressLines;

  const { addressTitle, addressBody } = splitLoggiAddress(addressLines);

  return (
    <>
      {enableNewAssignmentListByDriverRelation ? (
        <ThemeProvider theme={newTheme}>
          <Box clone>
            <ListItem className={classes.listItem}>
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                mt={2.5}
                ml={2.5}
                mr={2.5}
                borderRadius={8}
                border={1}
                borderColor={colors.smoke[200]}
                bgcolor={lessEmphasis ? colors.smoke[50] : colors.root[0]}
              >
                <ButtonBase
                  className={classes.button}
                  disabled={
                    !showPackageDetailsModalForDriver ? taskCompleted : false
                  }
                  onClick={() => onClick(assignment)}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    p={2.5}
                  >
                    <Box width="100%" textAlign="left">
                      {isSyncing && <SyncingLabel />}
                      <Box
                        display="flex"
                        mb={1.5}
                        justifyContent="space-between"
                      >
                        <AssignmentIndex
                          assignmentIndex={assignment?.assignmentDisplayId}
                          useNewTheme
                          assignmentStatusCode={statusCode}
                        />
                        {statusCode >= 0 && (
                          <StatusCodeChip status={statusCode} useNewTheme />
                        )}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box width="100%">
                          <Box color={colors.smoke[900]}>
                            {renderPickupCompletedChip(pickupCompleted)}
                            <Box display="flex" justifyContent="space-between">
                              <DeadlineTime
                                isPickup={isPickup}
                                deadlineTime={deadlineTime}
                                showNewDeadlineTime={
                                  enableNewAssignmentListByDriverRelation
                                }
                                statusCode={statusCode}
                              />
                              <Box mt={1}>
                                {assignment?.status?.code !==
                                  statusCodes.PICKUP &&
                                showPackageDetailsModalForDriver ? (
                                  <MoreVert />
                                ) : (
                                  <ArrowForward />
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box mt={1.25}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                color:
                                  newTheme.colors.neutrals.typeface.secondary
                              }}
                            >
                              {hasLoggiAddress
                                ? `${addressTitle} ${addressBody}`
                                : `${formatAddress(
                                    assignment
                                  )} ${formatVicinity(assignment.destination)}`}
                            </Typography>
                          </Box>
                          {showBarcodeOnAssignmentList && assignment?.barcode && (
                            <Box mt={2}>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color:
                                    newTheme.colors.neutrals.typeface.secondary,
                                  fontWeight: 600
                                }}
                              >
                                {getPackageBarcode(assignment)}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ButtonBase>
                {hasFailedToSync && (
                  <Box pb={2.5}>
                    <FailedToSyncLabel onRetry={handleRetry} useNewTheme />
                  </Box>
                )}
                {isSynced &&
                  !isUnableToGoToDeliveryLocation &&
                  showSyncedButCanTryAgainOnNewAssignmentList && (
                    <Box pb={2.5}>
                      <SyncedButCanTryAgain onRetry={handleRetry} useNewTheme />
                    </Box>
                  )}
              </Box>
            </ListItem>
          </Box>
        </ThemeProvider>
      ) : (
        <Box bgcolor={lessEmphasis ? colors.smoke[50] : colors.root[0]} clone>
          <ListItem divider={!!assignment} className={classes.listItem}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <ButtonBase
                className={classes.button}
                disabled={taskCompleted}
                onClick={() => onClick(assignment)}
              >
                <Box display="flex" flexDirection="column" flexGrow={1} p={2.5}>
                  <Box display="flex">
                    <AssignmentIndex
                      assignmentIndex={assignment?.assignmentDisplayId}
                    />

                    <Box width="100%" textAlign="left">
                      {isSyncing && <SyncingLabel />}
                      {statusCode >= 0 && (
                        <Box display="flex" mb={1.5}>
                          <StatusCodeChip status={statusCode} />
                        </Box>
                      )}

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Box color={colors.smoke[900]}>
                            {renderPickupCompletedChip(pickupCompleted)}
                            <DeadlineTime
                              isPickup={isPickup}
                              deadlineTime={deadlineTime}
                            />

                            <Typography variant="h6">
                              {hasLoggiAddress
                                ? addressTitle
                                : formatAddress(assignment)}
                            </Typography>
                          </Box>

                          <Box mt={1.25}>
                            <Typography variant="body2">
                              <Box component="span" color={colors.smoke[800]}>
                                {hasLoggiAddress
                                  ? addressBody
                                  : formatVicinity(assignment.destination)}
                              </Box>
                            </Typography>
                          </Box>
                        </Box>

                        {!taskCompleted && (
                          <Box mt={1.25} color={colors.blue[500]}>
                            <ArrowIcon />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </ButtonBase>
              {hasFailedToSync && (
                <Box px={2.5} pb={2.5}>
                  <FailedToSyncLabel onRetry={handleRetry} />
                </Box>
              )}
              {isSynced && !isUnableToGoToDeliveryLocation && (
                <Box px={2.5} pb={2.5}>
                  <SyncedButCanTryAgain onRetry={handleRetry} />
                </Box>
              )}
            </Box>
          </ListItem>
        </Box>
      )}
    </>
  );
}

AssignmentRow.propTypes = {
  assignment: PropTypes.oneOfType([
    SharedPropTypes.assignmentWaypoint,
    SharedPropTypes.package,
    SharedPropTypes.pickup
  ]).isRequired,
  onClick: PropTypes.func,
  onRetry: PropTypes.func,
  deadlineTime: PropTypes.string
};

AssignmentRow.defaultProps = {
  onClick: () => {},
  onRetry: () => {},
  deadlineTime: ''
};

export default React.memo(AssignmentRow);
