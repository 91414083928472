import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { PAGES } from 'view';
import computeDistanceBetween from 'geolib/es/getDistance';
import { getDriverPosition } from 'view/pages/assignment-list/google-maps/utils';
import InfoBox from 'view/atoms/infobox';
import { buildLoggiAddressV2 } from 'view/utils';
import ConfirmCancelationDrawer from 'view/pages/capacity-reserve-details/cancelation-drawer';
import IdentificationDrawer from 'view/pages/capacity-reserve-details/identification-drawer';
import getDeadlineCollectMessage from 'operations/deadline-time';

export const messages = {
  title: deadlineTimeMessage => (
    <Typography variant="h5">
      Oferta programada para{' '}
      <b>
        {deadlineTimeMessage.charAt(0).toUpperCase() +
          deadlineTimeMessage.slice(1)}
      </b>
    </Typography>
  ),
  locationSection: {
    title: 'Retirar pacotes em',
    distance: distance => `Ponto há ${distance}km de você`,
    regionTitle: 'Rota prevista para região de',
    regionSubtitle: 'A região de entrega pode incluir bairros vizinhos.'
  },
  information: {
    title: 'Fique atento',
    subtitle:
      'Você tem uma rota ativa, para iniciar a sua oferta programada você precisa finalizar sua rota atual.'
  },
  buttonStartPickup: 'Cheguei na agência',
  buttonCantGo: 'Cancelar programação',
  backButtonAriaLabel: 'Voltar'
};

const drawerTypes = {
  identification: 'identification',
  cancelation: 'cancelation',
  idle: 'idle'
};

const newTheme = getThemeForUserType({
  userType: userTypeEnum.drivers,
  version: availableThemeVersions.drivers.v2
});

const getDriverDistanceFromLocation = destination => {
  const driverCoords = getDriverPosition();
  const destinationCoords = {
    lat: destination?.coordinates?.latitude,
    lng: destination?.coordinates?.longitude
  };

  if (
    driverCoords.lat &&
    driverCoords.lng &&
    destinationCoords.lat &&
    destinationCoords.lng
  ) {
    const driverDistanceFromLocation = computeDistanceBetween(
      driverCoords,
      destinationCoords
    );
    return driverDistanceFromLocation
      ? Math.round(driverDistanceFromLocation / 1000)
      : null;
  }

  return null;
};

function CapacityReserveDetails() {
  const { colors, typography } = newTheme;
  const { pxToRem } = typography;

  const history = useHistory();
  const pickup = history.location.state || {};
  const [drawerTypeOpen, setDrawerTypeOpen] = useState(drawerTypes.idle);

  const deadlineTimeMessage = getDeadlineCollectMessage({
    deadlineTime: pickup.deadlineCollectTime,
    startTime: pickup.startTime,
    showStartTime: true
  });

  const onGoBackClick = () => history.goBack();

  const closeDrawers = () => setDrawerTypeOpen(drawerTypes.idle);

  const onConfirmPickupClick = () => {
    history.push(
      PAGES.CAPACITY_RESERVE_BARCODE_READER.replace(
        ':pickupId',
        pickup.pickupId
      )
    );
  };

  const driverDistanceFromLocationKM = getDriverDistanceFromLocation(
    pickup.destination
  );

  return (
    <ThemeProvider theme={newTheme}>
      <Box height="100%" display="flex" flexDirection="column" pt={1}>
        <IconButton
          onClick={onGoBackClick}
          data-testid="capacity-back-button"
          style={{ padding: pxToRem(24), alignSelf: 'start' }}
        >
          <ArrowBack
            style={{ color: colors.neutrals.typeface.primary }}
            aria-label={messages.backButtonAriaLabel}
          />
        </IconButton>
        <Box px={3} display="flex" flexDirection="column" flexGrow={1}>
          {messages.title(deadlineTimeMessage)}
          <Box pt={4} color={colors.neutrals.typeface.secondary}>
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              {messages.locationSection.title}
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: pxToRem(8), marginBottom: pxToRem(8) }}
            >
              {buildLoggiAddressV2(pickup.destination)}
            </Typography>
            {driverDistanceFromLocationKM > 0 && (
              <Typography variant="body1">
                {messages.locationSection.distance(
                  driverDistanceFromLocationKM
                )}
              </Typography>
            )}
          </Box>
          {pickup.regionName && (
            <Box py={4} color={colors.neutrals.typeface.secondary}>
              <Typography variant="caption" style={{ fontWeight: 600 }}>
                {messages.locationSection.regionTitle}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  color: colors.neutrals.typeface.primary,
                  marginBottom: pxToRem(8)
                }}
              >
                {pickup.regionName}
              </Typography>
              <Typography variant="body1">
                {messages.locationSection.regionSubtitle}
              </Typography>
            </Box>
          )}
        </Box>
        <InfoBox
          title={messages.information.title}
          subtitle={messages.information.subtitle}
        />
        <Box p={2} position="sticky" bottom={0} mt="auto">
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            onClick={() => setDrawerTypeOpen(drawerTypes.identification)}
            style={{ marginBottom: pxToRem(10) }}
          >
            {messages.buttonStartPickup}
          </Button>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="outlined"
            data-testid="cant-go-button"
            onClick={() => setDrawerTypeOpen(drawerTypes.cancelation)}
          >
            {messages.buttonCantGo}
          </Button>
        </Box>
      </Box>
      <ConfirmCancelationDrawer
        isOpen={drawerTypeOpen === drawerTypes.cancelation}
        onCloseDrawerClick={closeDrawers}
        pickup={pickup}
      />
      <IdentificationDrawer
        pickup={pickup}
        isOpen={drawerTypeOpen === drawerTypes.identification}
        closeDrawer={closeDrawers}
        onConfirm={onConfirmPickupClick}
      />
    </ThemeProvider>
  );
}
export default CapacityReserveDetails;
