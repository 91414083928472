import firebase from 'firebase/app';

/**
 *
 * @param {string} event - event name
 * @param {object} eventParams - custom event params
 *
 * @returns {void}
 */
export default function sendEventToAnalytics(event, eventParams = {}) {
  firebase.analytics().logEvent(event, eventParams);
}
