import React from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';
import { Box, Divider, Typography } from '@material-ui/core';
import CapacityReserveListItem from './list-item';

export const texts = {
  title: 'Oferta programada'
};

function CapacityReserveList({ assignmentsWaypoints }) {
  if (!assignmentsWaypoints?.length) return <></>;

  return (
    <>
      <Box py={2} px={3}>
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          {texts.title}
        </Typography>
      </Box>
      <Divider />
      {assignmentsWaypoints.map(waypoint => (
        <CapacityReserveListItem
          key={waypoint.pickupId}
          assignmentWaypoint={waypoint}
        />
      ))}
    </>
  );
}

CapacityReserveList.propTypes = {
  assignmentsWaypoints: PropTypes.arrayOf(SharedPropTypes.assignmentWaypoint)
    .isRequired
};

export default CapacityReserveList;
