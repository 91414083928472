const buildLoggiAddressV2 = address => {
  if (!address?.postalAddress) return '';
  const addr = address.postalAddress;
  let fullAddress = '';
  fullAddress += addr.addressLines?.filter(x => !!x).join(' ') || '';
  fullAddress += addr.sublocality ? ` ${addr.sublocality}` : '';
  fullAddress += addr.locality ? ` ${addr.locality}` : '';
  fullAddress += addr.administrativeArea ? ` - ${addr.administrativeArea}` : '';
  fullAddress += addr.postalCode ? ` ${addr.postalCode}` : '';
  return fullAddress?.trim();
};

const parseOfferWaypoint = waypoint => {
  const { address } = waypoint;

  let neighborhood = '';
  let city = '';

  if (
    address.postalAddress &&
    address.postalAddress.locality &&
    address.postalAddress.sublocality
  ) {
    city = address.postalAddress.locality;
    neighborhood = address.postalAddress.sublocality;
  } else {
    neighborhood = address.correiosAddress.bairro;
    city = address.correiosAddress.cidade;
  }

  const indexDisplay = waypoint.index;
  const coordinates = {
    latitude: address.coordinates.latitude,
    longitude: address.coordinates.longitude
  };

  const loggiAddress = buildLoggiAddressV2(address);

  const typeService = waypoint.tripServiceType;
  const deadlineDisplay = waypoint.deadline;

  return {
    neighborhood,
    city,
    indexDisplay,
    coordinates,
    loggiAddress,
    typeService,
    deadlineDisplay
  };
};

const parseInt64String = str => {
  if (str === '0') return undefined;

  return str;
};

export const parseOfferProtoToOfferCard = offerCard => {
  return {
    itineraryId: parseInt64String(offerCard.itineraryId),
    demandId: parseInt64String(offerCard.demandId),
    steps: offerCard.steps,
    price: offerCard.compensationValue,
    distance: offerCard.distance,
    deadlineCollect: offerCard.collectDeadline,
    startTimeCollect: offerCard.collectStartTime,
    recommended: offerCard.recommended,
    waypoints: offerCard.waypoints.map(waypoint =>
      parseOfferWaypoint(waypoint)
    ),
    id: offerCard.id,
    directAttribution: offerCard.directAttribution,
    combined: offerCard.combined,
    opportunistic: offerCard.opportunistic,
    trip: offerCard.trip,
    shipperName: offerCard.shipperName,
    closingTime: offerCard.distributionCenter?.closingTime,
    name: offerCard.distributionCenter?.name,
    capacityReserve: offerCard.capacityReserve,
    tagMessage: offerCard.offerMetainfo?.tagMessage,
    tagColor: offerCard.offerMetainfo?.tagColor,
    distanceDriverFirstService: offerCard.distanceDriverFirstServiceMeters,
    regionName: offerCard.regionName
  };
};

export default parseOfferProtoToOfferCard;
