const TEXT = {
  PACKAGE_LIST: 'Suas entregas',
  UNAVAILABLE_PACKAGE_LIST_TITLE: 'Esta lista não está disponível',
  UNAVAILABLE_PACKAGE_LIST:
    'Entre em contato com seu gestor para receber a lista de entregas adequada.',
  TOTAL_PACKAGES: 'No momento, você não tem nenhum pacote para entregar.',
  PACKAGES_COUNTER: '0/0'
};

export default TEXT;
