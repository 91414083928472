import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import { Redirect, useLocation } from 'react-router';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { colors } from '@loggi/mar';
import {
  Box,
  Grid,
  Card,
  List,
  ListItem,
  Typography,
  CircularProgress,
  IconButton,
  Button
} from '@material-ui/core';
import { ArrowBack, Cancel, ChevronRight } from '@material-ui/icons';
import { PAGES } from 'view';
import SharedPropTypes from 'view/shared-prop-types';
import Alert from 'view/atoms/alert';
import RecipientPhone from 'view/molecules/recipient-phone';
import Notes from 'view/molecules/notes/notes';
import SuccessSyncTemplate from 'view/templates/success-sync';
import FacadePhoto from 'view/pages/facade-photo';
import DriverDistanceFromDeliveryPointTemplate from 'view/templates/driver-distance-from-delivery-point';
import { getLastKnownLocation } from 'operations/geolocation/geolocation';
import { statusCodes } from 'operations/update-status';
import computeDistanceBetween from 'geolib/es/getDistance';
import getProxyPhone, { getWhatsAppProxy } from 'operations/proxy-phone';
import { capitalizeString } from 'view/text-formatters';
import DetailsStepSuboptions from './delivering-problems-suboptions';
import { ReactComponent as EmergencyButton } from './icons/emergency-button-blue.svg';
import {
  useSanchesBackground,
  useWhiteBackground,
  useCardStyles
} from './styles';
import deliveringProblemsMachine, { STATES, ACTIONS } from './machine';
import { problemReasons, problemsList, messages } from './constants';
import GeolocationPermission from '../geolocation-permission';
import {
  DrawerMailBox,
  statesEnum as DRAWER_MAILBOX_STATE
} from './mailbox/drawer-mailbox';

export const sendToHelpPage = () => {
  window.location.href = problemReasons.unavailableSafeDeliveryToken.helpLink;
};

function RecipientContactBox({ packageId, recipient, whatsappExperiment }) {
  return (
    <Box
      bgcolor={colors.smoke[50]}
      borderRadius={8}
      m={2.5}
      p={2.5}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box pb={0.5} color={colors.smoke[700]}>
          <Typography variant="body2">{messages.tryToSolve}</Typography>
        </Box>
        <Box color={colors.smoke[900]}>
          <Typography variant="subtitle1">
            {capitalizeString(recipient.name)}
          </Typography>
        </Box>
      </Box>

      <Box px={1.5} py={1.5} data-testid="recipient-phone">
        <RecipientPhone
          recipientName={recipient.name}
          experimentName={whatsappExperiment}
          getPhone={() => getProxyPhone(packageId)}
          getWhatsAppNumber={() => getWhatsAppProxy(packageId)}
          packageId={packageId}
          calledFrom="deliveringProblems"
        />
      </Box>
    </Box>
  );
}

RecipientContactBox.propTypes = {
  packageId: PropTypes.string.isRequired,
  recipient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string
  }).isRequired,
  whatsappExperiment: PropTypes.string
};

RecipientContactBox.defaultProps = {
  whatsappExperiment: ''
};

function SelectionStep({
  packageId,
  recipient,
  whatsappExperiment,
  problems,
  onProblemSelection,
  onGoBack
}) {
  const cardStyles = useCardStyles();
  const headerStyles = useWhiteBackground();

  const history = useHistory();
  const goToEmergencyPage = () => history.push(PAGES.EMERGENCY);

  const Header = React.memo(() => (
    <Box px={0.5} py={2.5}>
      <Box>
        <Grid container justify="space-between" alignItems="center">
          <IconButton
            style={{ color: colors.blue[500] }}
            onClick={onGoBack}
            data-testid="emergency-back"
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={goToEmergencyPage}
            data-testid="emergency-button"
          >
            <EmergencyButton />
          </IconButton>
        </Grid>
      </Box>
    </Box>
  ));

  return (
    <Box
      classes={headerStyles}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Header backButtonOnClick={onGoBack} />
      <Box flexGrow={1}>
        <Card elevation={0} classes={cardStyles}>
          <Box pt={1.5}>
            <Box px={2.5}>
              <Typography variant="h5" data-testid="selection-step-title">
                {messages.selectionStep.headerText}
              </Typography>

              <Box py={2.5} color={colors.smoke[700]}>
                <Typography
                  variant="body1"
                  data-testid="selection-step-subtitle"
                >
                  {messages.selectionStep.subtitle(problems.length)}
                </Typography>
              </Box>
            </Box>
            <List>
              {problems.map(problem => (
                <ListItem
                  button
                  divider
                  onClick={() => {
                    onProblemSelection(problem);
                  }}
                  key={problem.id}
                >
                  <Box
                    py={1}
                    px={0.5}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box flex={1} justifyContent="flex-start">
                      <Typography variant="body1">{problem.text}</Typography>
                      {problem.description && (
                        <Box color={colors.smoke[700]} py={1.5}>
                          <Typography variant="body2">
                            {problem.description}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      color={colors.blue[500]}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ChevronRight data-testid="chevron-right-icon" />
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Card>
      </Box>
      <Box py={9}>
        <Box position="fixed" bottom="0" width="100%">
          <RecipientContactBox
            packageId={packageId}
            recipient={recipient}
            whatsappExperiment={whatsappExperiment}
          />
        </Box>
      </Box>
    </Box>
  );
}

SelectionStep.propTypes = {
  packageId: PropTypes.string.isRequired,
  recipient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string
  }).isRequired,
  whatsappExperiment: PropTypes.string,
  problems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  onProblemSelection: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired
};

SelectionStep.defaultProps = {
  whatsappExperiment: ''
};

function LoadingStep() {
  const sanchesBackgroundStyles = useSanchesBackground();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      classes={sanchesBackgroundStyles}
      color={colors.root[0]}
      px={2.5}
    >
      <Box pb={3.5}>
        <CircularProgress color="inherit" />
      </Box>
      <Typography color="inherit" variant="subtitle1" align="center">
        {messages.loadingStep.infoText}
      </Typography>
    </Box>
  );
}
function DetailsStep({
  packageId,
  recipient,
  whatsappExperiment,
  problem,
  onConfirmProblem,
  onGoBack,
  hasDeliveryMailbox,
  pkg
}) {
  const cardStyles = useCardStyles();
  const headerStyles = useWhiteBackground();
  const [notes, setNotes] = useState('');
  const [mailbox, setMailbox] = useState(DRAWER_MAILBOX_STATE.CLOSED);

  const isMailboxDrawerOpen =
    problem.id === problemReasons.recipientUnavailable.id && hasDeliveryMailbox;

  return (
    <>
      <Box
        classes={headerStyles}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box>
          <Box px={0.5} py={2.5}>
            <Grid container justify="space-between" alignItems="center">
              <IconButton>
                <ArrowBack
                  style={{ color: colors.blue[500] }}
                  onClick={onGoBack}
                />
              </IconButton>
            </Grid>
          </Box>
        </Box>
        <Box flexGrow={1}>
          <Card elevation={0} classes={cardStyles}>
            <Box pt={1.5}>
              <Box px={2.5}>
                <Typography variant="h5">
                  {problem.detailsScreenTitle}
                </Typography>

                <Box color={colors.smoke[700]} mt={1.5}>
                  <Typography variant="body1">
                    {messages.detailsScreenText}
                  </Typography>
                </Box>
              </Box>

              <RecipientContactBox
                packageId={packageId}
                recipient={recipient}
                whatsappExperiment={whatsappExperiment}
              />

              <Box px={2.5}>
                <Notes
                  isSuccess={false}
                  value={notes}
                  onChange={event => setNotes(event.target.value)}
                />
              </Box>

              <Box pb={2.5} display="flex" flexWrap="wrap">
                <Box px={2.5} pt={3} pb={2} bottom="0" width="100%">
                  <Button
                    onClick={() => onConfirmProblem(notes)}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    {messages.confirmProblemButtonLabel}
                  </Button>
                </Box>
                {problem.helpLink && (
                  <Box px={3} pb={2.5} bottom="0" width="100%">
                    <Button
                      onClick={sendToHelpPage}
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      {messages.tokenDoubtsButtonLabel}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
        </Box>
        {isMailboxDrawerOpen && (
          <DrawerMailBox
            drawerState={mailbox}
            setDrawerState={setMailbox}
            pkg={pkg}
          />
        )}
      </Box>
    </>
  );
}

DetailsStep.propTypes = {
  packageId: PropTypes.string.isRequired,
  recipient: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string
  }).isRequired,
  whatsappExperiment: PropTypes.string,
  problem: PropTypes.shape({
    id: PropTypes.number,
    detailsScreenTitle: PropTypes.string,
    textProblemToken: PropTypes.string,
    helpLink: PropTypes.string
  }).isRequired,
  onConfirmProblem: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  hasDeliveryMailbox: PropTypes.bool.isRequired,
  pkg: SharedPropTypes.package.isRequired
};

DetailsStep.defaultProps = {
  whatsappExperiment: ''
};

function DeliveringProblems({ goBack }) {
  const location = useLocation();
  const driverLastKnownLocation = getLastKnownLocation();
  const { pkg, tokenAttempts } = location.state || '';
  const {
    packageId,
    whatsappExperiment,
    recipient,
    safeDelivery,
    deliveryEvidenceSignedUrl,
    canDeliveryMailbox,
    status
  } = pkg || {};
  const hasSafeDelivery = !!safeDelivery;
  const hasDeliveryMailbox = !!(
    canDeliveryMailbox && deliveryEvidenceSignedUrl
  );

  const driverCoords = {
    lat: driverLastKnownLocation?.latitude,
    lng: driverLastKnownLocation?.longitude
  };

  const destinationCoords = {
    lat:
      pkg?.destination?.lat || pkg?.addressDestination?.coordinates?.latitude,
    lng:
      pkg?.destination?.lng || pkg?.addressDestination?.coordinates?.longitude
  };

  const history = useHistory();

  const historyGoBackAction = 'POP';
  const backAction = history.action === historyGoBackAction;

  const [current, send] = useMachine(
    deliveringProblemsMachine.withContext({
      ...deliveringProblemsMachine.context,
      pkg,
      packageId,
      tokenAttempts,
      hasSafeDelivery,
      driverLastKnownLocation,
      driverCoords,
      destinationCoords,
      deliveryEvidenceSignedUrl,
      backAction,
      problemsList,
      safeDelivery,
      isPackageDelivered: status?.code === statusCodes.DELIVERED
    }),
    {
      actions: {
        goBack
      }
    }
  );

  const goBackAction = () => send(ACTIONS.BACK);
  const selectProblemAction = problem =>
    send(ACTIONS.CHOOSE_PROBLEM, {
      problem
    });
  const confirmProblem = () => send(ACTIONS.CONFIRM_PROBLEM);
  const cleanNotificationMessageAction = () => send(ACTIONS.CLEAN_NOTIFICATION);
  const continueAction = () => send(ACTIONS.CONTINUE);

  const onChange = notes => send(ACTIONS.CHANGE_NOTES, { value: { notes } });
  const onChangeOptions = options =>
    send(ACTIONS.SUBOPTION, { value: { options } });
  const { notificationMessage } = current.context;

  return (
    <>
      <Alert
        open={!!notificationMessage}
        onClose={cleanNotificationMessageAction}
        message={notificationMessage || ''}
        color={colors.red[700]}
        startAdornment={<Cancel fontSize="large" />}
      />

      {current.value === STATES.selection && (
        <SelectionStep
          packageId={packageId}
          recipient={recipient}
          whatsappExperiment={whatsappExperiment}
          problems={current.context.problemsListFiltered}
          onGoBack={goBackAction}
          onProblemSelection={selectProblemAction}
        />
      )}

      {current.value === STATES.detailsStep && (
        <DetailsStep
          packageId={packageId}
          recipient={recipient}
          whatsappExperiment={whatsappExperiment}
          problem={current.context.problem}
          onConfirmProblem={notes => {
            onChange(notes);
            confirmProblem();
          }}
          onGoBack={goBackAction}
          hasDeliveryMailbox={hasDeliveryMailbox}
          pkg={pkg}
        />
      )}

      {current.value === STATES.detailsStepSuboption && (
        <DetailsStepSuboptions
          onConfirmProblem={options => {
            onChangeOptions(options);
            confirmProblem();
          }}
          onGoBack={goBackAction}
          problem={current.context.problem}
        />
      )}

      {current.value === STATES.loading && <LoadingStep />}

      {current.value === STATES.facadePhoto && (
        <FacadePhoto
          facadePhotoContext={{
            ...current.context,
            status: current.context.problem.id
          }}
          onGoBack={goBackAction}
        />
      )}

      {current.value === STATES.successStep &&
        current.event.data !== messages.notifications.noAttempToDeliver && (
          <SuccessSyncTemplate onContinue={continueAction} />
        )}

      {current.value === STATES.redirectStep && (
        <Redirect to={PAGES.PACKAGE_LIST} />
      )}

      {current.value === STATES.successStep &&
        current.event.data === messages.notifications.noAttempToDeliver && (
          <Redirect to={PAGES.PACKAGE_LIST} />
        )}

      {current.value === STATES.redirectToPkgDetails && (
        <Redirect
          to={{
            pathname: PAGES.PACKAGE_DETAILS.replace(
              ':packageId',
              current.context.pkg?.packageId
            ),
            state: current.context.pkg
          }}
        />
      )}

      {current.value === STATES.showGeolocationError && (
        <GeolocationPermission />
      )}

      {current.value === STATES.driverIsOutsideDestinationAreaRadius && (
        <DriverDistanceFromDeliveryPointTemplate
          onGoBack={goBackAction}
          onClick={continueAction}
          distance={computeDistanceBetween(driverCoords, destinationCoords)}
        />
      )}
    </>
  );
}

DeliveringProblems.propTypes = {
  goBack: PropTypes.func.isRequired,
  safeDelivery: PropTypes.shape({
    safeDeliveryId: string,
    tokenHash: string,
    salt: string
  })
};

DeliveringProblems.defaultProps = {
  safeDelivery: {}
};

export default React.memo(DeliveringProblems);
