/* eslint-disable */
import React, { useState } from 'react';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import colors from '@loggi/mar/src/themes/drivers/colors';
import {
  Button,
  Box,
  Typography,
  SwipeableDrawer,
  ThemeProvider
} from '@material-ui/core';
import capacityReserveIconV2 from './icons/capacity-reserve-icon-v2.svg';
import Puller from '../../../molecules/puller';

const localStorageKey = 'notified-of-capacity-reserve-v2';

export const removeDriverNotifiedOfCapacityReserve = () => {
  localStorage.removeItem(localStorageKey);
};

export const setDriverNotifiedOfCapacityReserve = () => {
  localStorage.setItem(localStorageKey, true);
};

const getDriverNotifiedOfCapacityReserve = () => {
  return JSON.parse(localStorage.getItem(localStorageKey));
};

export const messages = {
  subtitle: 'Novidade para você!',
  title: 'Programe ofertas para depois',
  body:
    ' Na página de "Ofertas", acesse "Ofertas Programadas" e aceite uma rota antecipadamente.',
  button: 'Ok, entendi',
  observationBox: {
    title: 'Fique atento',
    body:
      'Caso tenha uma rota ativa, para iniciar a sua oferta programada você precisa finalizar sua rota atual.'
  }
};

export default function CapacityReserveNotificationDrawerV2() {
  const [shouldNotifyDriver, setShouldNotifyDriver] = useState(
    () => !getDriverNotifiedOfCapacityReserve()
  );

  const onNotificationClose = () => {
    setDriverNotifiedOfCapacityReserve();
    setShouldNotifyDriver(false);
  };

  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <SwipeableDrawer
        data-testid="capacity-notification-drawer-v2"
        open={shouldNotifyDriver}
        anchor="bottom"
        onClose={onNotificationClose}
        PaperProps={{
          style: {
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            paddingTop: '16px'
          }
        }}
      >
        <Puller mt={0} height="0.25rem" />

        <Box display="flex" flexDirection="column">
          <Box px={3} pt={3} pb={2} flexGrow={1}>
            <Box pb={2} direction="row">
              <img src={capacityReserveIconV2} />
            </Box>

            <Box pb={2} direction="row">
              <Box pb={0.5} color={colors.neutrals.typeface.secondary}>
                <Typography variant="subtitle2">{messages.subtitle}</Typography>
              </Box>
              <Typography variant="h5" fontWeight="700">
                <strong>{messages.title}</strong>
              </Typography>
            </Box>

            <Box
              pb={3}
              color={colors.neutrals.typeface.secondary}
              direction="row"
            >
              <Typography variant="body1">{messages.body}</Typography>
            </Box>

            <Box
              borderRadius={8}
              border={1}
              borderColor={colors.neutrals.shapes.light}
              mb={2}
              style={{
                backgroundColor: colors.neutrals.shapes.lighter
              }}
              direction="row"
            >
              <Box px={2} py={2}>
                <Box pb={1}>
                  <Typography variant="body2">
                    <b>{messages.observationBox.title}</b>
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {messages.observationBox.body}
                </Typography>
              </Box>
            </Box>

            <Box pt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={onNotificationClose}
                data-testid="capacity-notification-drawer-v2-button"
                direction="row"
              >
                {messages.button}
              </Button>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </ThemeProvider>
  );
}
