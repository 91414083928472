import * as Sentry from '@sentry/browser';
import captureExceptionToSentry from 'operations/config/sentry';

/**
 * Returns a enhanced function that handles errors on operations
 * that use Dexie by logging the error on Sentry and returning a
 * more human-friendly error.
 *
 * @param {string} dbName
 * @param {func} fn - operation
 * @returns {func} - enhanced operation
 */
function handleDexieErrors({ dbName, fn, operationName }) {
  const enhancedFunction = async (...args) => {
    return fn(...args).catch(error => {
      Sentry.addBreadcrumb({
        category: 'db',
        message: `${operationName}@${dbName}: ${error.name}`,
        level: 'fatal',
        type: 'error'
      });
      captureExceptionToSentry(error);
      throw new Error(`Erro no banco de dados ${dbName}. ${error.message}`);
    });
  };

  return enhancedFunction;
}

function wrapOperationsInDexieErrorHandler({ dbName, operations }) {
  const proxyHandler = {
    get(object, propertyName) {
      const originalProperty = object[propertyName];
      return handleDexieErrors({
        dbName,
        fn: originalProperty,
        operationName: propertyName
      });
    }
  };

  return new Proxy(operations, proxyHandler);
}

export default wrapOperationsInDexieErrorHandler;
