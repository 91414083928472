import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { isCognitoUserAssociatedWithLoggiUser } from 'infra-operations/auth/cognito';
import deviceValidation from 'infra-operations/services/device-validation';
import { removeCurrentUserInfo } from 'infra-operations/storage/credential';
import DeviceValidationError from 'view/pages/device-validation-error';
import LoadingTemplate from 'view/templates/loading';
import OnboardingCognitoSmsPage from './cognito-sms';
import OnboardingCognitoPage from './cognito';

export default function OnboardingPage({
  createAccountPath,
  onSuccessLoginRedirectPath,
  enableSmsAuth,
  sendEventToAnalytics,
  enableFixSignInWithAnotherAccount
}) {
  const [driverData, setDriverData] = useState(null);
  const [isUILoading, setIsUILoading] = useState(false);
  const [deviceRegistrationError, setDeviceRegistrationError] = useState(null);
  const isDriverLoggedIn = driverData?.isAuthenticated;

  const {
    state: { authenticatedUser, loading: isCognitoLoading, urlRedirect },
    fetchCurrentUserFromCognito,
    signOut,
    setUrlRedirect
  } = useAmplifyAuth();

  const handleCreateAccountButtonClick = () => {
    setIsUILoading(true);
    window.location.href = createAccountPath;
  };

  const logout = async () => {
    setIsUILoading(true);
    if (enableFixSignInWithAnotherAccount) {
      window.driverAppBridge?.logout?.();
    }
    await signOut();
  };

  const cognitoUserIsAssociated = isCognitoUserAssociatedWithLoggiUser(
    authenticatedUser
  );

  useEffect(() => {
    if (!isCognitoLoading && cognitoUserIsAssociated) {
      setIsUILoading(true);
      setDriverData(null);

      deviceValidation()
        .then(response => {
          setDriverData(response);
        })
        .catch(error => {
          removeCurrentUserInfo();

          if (error.code === 401 && Array.isArray(error.errors)) {
            const { code, email } = error.errors[0] || {};
            setDriverData({ email, isAuthenticated: false });
            setDeviceRegistrationError(code);
          } else {
            setDriverData(error);
          }
        })
        .finally(() => {
          setIsUILoading(false);
        });
    }
  }, [isCognitoLoading, cognitoUserIsAssociated]);

  useEffect(() => {
    if (isDriverLoggedIn) {
      setIsUILoading(true);

      fetchCurrentUserFromCognito()
        .then(() => {
          setIsUILoading(false);
          window.location.href = onSuccessLoginRedirectPath;
        })
        .catch(error => {
          setDriverData(error);
          setIsUILoading(false);
        });
    }
  }, [
    isDriverLoggedIn,
    fetchCurrentUserFromCognito,
    onSuccessLoginRedirectPath
  ]);

  if (isUILoading || isCognitoLoading) {
    return <LoadingTemplate />;
  }

  if (deviceRegistrationError) {
    return (
      <DeviceValidationError
        errorDevice={deviceRegistrationError}
        email={driverData?.email}
      />
    );
  }

  if (enableSmsAuth && urlRedirect === createAccountPath && authenticatedUser) {
    setUrlRedirect('');
    handleCreateAccountButtonClick();
  }

  if (enableSmsAuth) {
    sendEventToAnalytics('sms_and_cognito_login_screen', {});
    return (
      <OnboardingCognitoSmsPage
        createAccountPath={createAccountPath}
        authenticatedUser={authenticatedUser}
        handleCreateAccountButtonClick={handleCreateAccountButtonClick}
        logout={logout}
        sendEventToAnalytics={sendEventToAnalytics}
      />
    );
  }

  sendEventToAnalytics('cognito_login_screen', {});
  return (
    <OnboardingCognitoPage
      logout={logout}
      handleCreateAccountButtonClick={handleCreateAccountButtonClick}
      authenticatedUser={authenticatedUser}
    />
  );
}

OnboardingPage.propTypes = {
  createAccountPath: PropTypes.string,
  onSuccessLoginRedirectPath: PropTypes.string,
  enableSmsAuth: PropTypes.bool,
  sendEventToAnalytics: PropTypes.func,
  enableFixSignInWithAnotherAccount: PropTypes.bool
};

OnboardingPage.defaultProps = {
  createAccountPath: null,
  onSuccessLoginRedirectPath: null,
  enableSmsAuth: false,
  sendEventToAnalytics: () => {},
  enableFixSignInWithAnotherAccount: false
};
