import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import SharedPropTypes from 'view/shared-prop-types';
import { ListItem, ButtonBase, Box, Typography, Chip } from '@material-ui/core';
import DeadlineTime from 'view/molecules/assignment-row/deadline-time';
import { buildLoggiAddressV2 } from 'view/utils';
import { pickupStateEnum } from 'operations/pickup/pickup-state-db';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitches
} from 'operations/feature-switches';
import { ArrowForward } from '@material-ui/icons';
import AssignmentIndex from 'view/molecules/assignment-row/assignment-index';

function CapacityReserveListItem({ assignmentWaypoint }) {
  const address = buildLoggiAddressV2(assignmentWaypoint.destination);

  const pickupCompleted =
    assignmentWaypoint.pickupState === pickupStateEnum.completed;

  const startTime = assignmentWaypoint?.startTime || '';
  const deadlineTime = assignmentWaypoint.deadlineTime || '';

  const enableNewAssignmentList = useFeatureSwitch(
    featureSwitches.enableNewAssignmentList
  );
  const enableNewAssignmentListByDriverRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewAssignmentList
  );

  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  return (
    <ListItem divider alignItems="flex-start" style={{ padding: 0 }}>
      <ButtonBase
        data-testid="capacity-reserve-list-item"
        onClick={assignmentWaypoint.actions.goToDetails}
        disabled={pickupCompleted}
        style={{ width: '100%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          textAlign="left"
          px={3}
          py={4}
        >
          <Box
            display="flex"
            mb={1.5}
            justifyContent="space-between"
            alignItems="start"
          >
            <AssignmentIndex
              assignmentIndex={assignmentWaypoint.displayId}
              shouldShowScheduledPickupIcon
              useNewTheme={enableNewAssignmentListByDriverRelation}
            />
            {pickupCompleted && (
              <Chip
                size="small"
                label="Coletado"
                deleteIcon={<></>}
                style={{
                  backgroundColor: colors.status.positive.pure,
                  color: colors.neutrals.white.pure,
                  marginBottom: pxToRem(16)
                }}
                onDelete={() => ({})}
              />
            )}
          </Box>
          <Box
            color={colors.smoke[900]}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mr={2}
            >
              <DeadlineTime
                isPickup
                deadlineTime={deadlineTime}
                startTime={startTime}
                showStartTime
                showNewDeadlineTime={enableNewAssignmentListByDriverRelation}
                isCapacityReserve
              />
              <Typography
                variant="body1"
                style={{
                  color: colors.neutrals.typeface.primary,
                  marginTop: pxToRem(8),
                  fontWeight: 600
                }}
              >
                {address}
              </Typography>
            </Box>
            {!pickupCompleted && <ArrowForward />}
          </Box>
        </Box>
      </ButtonBase>
    </ListItem>
  );
}

CapacityReserveListItem.propTypes = {
  assignmentWaypoint: SharedPropTypes.assignmentWaypoint.isRequired
};

export default CapacityReserveListItem;
