import React, { useState, useEffect } from 'react';
import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { BarcodeFormat } from '@zxing/library';
import {
  useFeatureSwitch,
  getSplittedValuesFromFS
} from '@loggi/firebase-feature-switches';
import { useSnackbar } from 'notistack';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForCompanyId
} from 'operations/feature-switches';
import sendEventToAnalytics from 'operations/firebase';
import { checkBarcodes, setPickupHadTypedBarcodes } from 'operations/pickup';
import BarcodeReaderTemplateV2, {
  playSuccessBeep,
  playErrorBeep
} from 'view/templates/barcode-reader/index-v2';
import DrawerWithSteps from 'view/molecules/drawer-with-steps';
import { barcodesAllowedFormats } from 'view/pages/pickup/constants';
import SharedPropTypes from 'view/shared-prop-types';
import InfoDrawer from 'view/molecules/info-drawer';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import { ReactComponent as ErrorBipImage } from './icons/bip-error.svg';
import { ReactComponent as WarningBipImage } from './icons/bip-warning.svg';
import PackageInfoDrawer from './package-info-drawer';
import BarcodeInput from './barcode-input';

export const helpSteps = [
  {
    title: 'É comum o pacote ter mais de uma etiqueta',
    subtitle:
      'Experimente bipar códigos de outras etiquetas coladas na embalagem antes de informar um problema na retirada.'
  },
  {
    title: 'Pode acontecer do código não ser legível para o bipe',
    subtitle:
      'Nesses casos, digite o código numérico através do botão localizado na parte superior direita da tela.'
  }
];

const states = {
  idle: 'idle',
  loading: 'loading',
  success: 'success'
};

export default function PickupScannerV2({
  pickup,
  beepedPackages,
  saveBeepedPackage,
  onGoBack,
  onReviewListClick,
  userIdentification
}) {
  const theme = useTheme();
  const { colors } = theme;
  const companyId = pickup?.shipper?.companyId;
  const pickupCode = pickup?.pickupCode;

  const { enqueueSnackbar } = useSnackbar();
  const [showBarcodeInput, setShowBarcodeInput] = useState(false);
  const [showHelpDrawer, setShowHelpDrawer] = useState(false);
  const [infoDrawerProps, setInfoDrawerProps] = useState({ open: false });
  const [state, setState] = useState(states.idle);
  const [scannedBarcode, setScannedBarcode] = useState({});
  const [expectedPackagesCodesMap, setExpectedPackagesCodesMap] = useState(
    new Set()
  );

  const enabledLMCsForListOfPlannedVolumesWithMultimodals = useFeatureSwitch(
    featureSwitches.enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  const isListOfPlannedVolumesEnabledForMultimodals = featureSwitchEnabledForDriverLMC(
    enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  // Even with the FS configured as number, we need to convert it because the FS returns a string
  const percentageToAddInVolumesLengthForMultimodalsFS =
    useFeatureSwitch(
      featureSwitches.percentageToAddInVolumesLengthForMultimodals
    ) || '0.20';
  const percentageToAddInVolumesLengthForMultimodals = Number(
    percentageToAddInVolumesLengthForMultimodalsFS
  );
  // Feature switch used to block pickup unexpected packages.
  const enabledBlockCompanyIdToPickupUnexpectedPackages = useFeatureSwitch(
    featureSwitches.enabledBlockCompanyIdToPickupUnexpectedPackages
  );
  /**
   * This data is for us to show the information of the inputted barcode
   * without adding to the driver's list in BarcodeInput component
   */
  const [
    packageWithBarcodeInputted,
    setPackageWithBarcodeInputted
  ] = useState();

  useEffect(() => {
    sendEventToAnalytics('pickup_scanner_new_flow', {
      pickup: pickupCode,
      driverId: userIdentification
    });

    const packagesToPickupBarcodes = pickup?.assignments?.[0]?.cargos?.map(
      pkg => pkg.code
    );
    setExpectedPackagesCodesMap(new Set(packagesToPickupBarcodes));
  }, [pickupCode, userIdentification, pickup]);

  const [, setScanningStart] = usePersistedState(
    `${persistedStateKeys.packageScanningStart}/${pickup?.pickupCode}`,
    ''
  );

  const enableUseScannerFormatFromFS = useFeatureSwitch(
    featureSwitches.enableUseScannerFormatsFromFS
  );
  const possibleFormatFSValues = useFeatureSwitch(
    featureSwitches.pickupScannerPossibleFormats
  );

  const scannerPossibleFormatValues = getSplittedValuesFromFS(
    possibleFormatFSValues
  ).filter(value => value in BarcodeFormat);

  const isPickupWithMultiModals = () => {
    return (
      parseInt(pickup?.assignments[0].scheduledVehicles, 10) > 1 &&
      isListOfPlannedVolumesEnabledForMultimodals
    );
  };

  const getTotalCounter = () => {
    if (!isPickupWithMultiModals())
      return pickup?.assignments?.[0].cargos?.length;

    const expectedNumberVolumes = Math.floor(
      pickup?.assignments?.[0].cargos?.length /
        parseInt(pickup?.assignments[0].scheduledVehicles, 10)
    );
    return (
      expectedNumberVolumes +
      Math.floor(
        expectedNumberVolumes * percentageToAddInVolumesLengthForMultimodals
      )
    );
  };

  /**
   * Get the barcodes formats allowed in camera scanner
   * @returns {Array<BarcodeFormat>}
   */
  const getPossibleFormats = () => {
    if (enableUseScannerFormatFromFS) {
      return scannerPossibleFormatValues.map(value => BarcodeFormat[value]);
    }
    return barcodesAllowedFormats;
  };

  /**
   * Checks if the barcode is already in driver's list
   * @param {string} barcodeRead barcode beeped or typed
   * @returns {bool}
   */
  const barcodeAlreadyInList = barcodeRead => {
    if (beepedPackages.length === 0) return false;
    return (
      beepedPackages.filter(
        pkg =>
          pkg.barcodes.filter(barcode => barcode === barcodeRead).length > 0
      ).length > 0
    );
  };

  /**
   * checks if the barcode is predicted in the list
   * of items to be collected.
   * @param {string} barcode beeped or typed
   * @returns {bool}
   */
  const itemsInPickup = barcode => expectedPackagesCodesMap.has(barcode);

  /**
   * Set the parameter 'open' to false for InfoDrawer
   */
  const closeInfoDrawer = () => {
    setInfoDrawerProps(props => {
      return { ...props, open: false };
    });
  };

  /**
   * Set barcode added error by screen
   * If page is BarcodeInput we add the error to package info
   * If page is BarcodeTemplate we show DrawerInfo
   * @param {string} barcode barcode
   * @param {string} errorTitle error title to be shown
   * @param {string} errorSubtitle error subtitle to be shown
   * @param {bool} isFromBarcodeInput
   */
  const setBarcodeAddedError = ({
    barcode,
    errorTitle,
    errorSubtitle,
    showMainButton,
    leftButton,
    rightButton,
    isFromBarcodeInput,
    image = <ErrorBipImage />,
    cancelledPackage = false
  }) => {
    if (isFromBarcodeInput) {
      setPackageWithBarcodeInputted({
        barcodes: [barcode],
        error: errorTitle,
        errorSubtitle,
        cancelledPackage
      });
    } else {
      setInfoDrawerProps({
        open: true,
        image,
        title: errorTitle,
        subtitle: errorSubtitle,
        onCloseClick: closeInfoDrawer,
        leftButton,
        rightButton,
        mainButton: {
          show: showMainButton,
          text: 'Ok, entendi',
          action: closeInfoDrawer
        }
      });
    }
    playErrorBeep();
  };

  /**
   * @param {Array} barcodesPackagesCheck
   * add package in driver list.
   */
  const addPackages = barcodesPackagesCheck => {
    setPackageWithBarcodeInputted(null);
    setPackageWithBarcodeInputted({
      ...barcodesPackagesCheck[0],
      error: 'Item não previsto'
    });
    showSnackbar({
      message: 'Item adicionado na lista',
      variant: 'successSnackbar',
      enqueueSnackbar
    });

    saveBeepedPackage(pkgs => [...pkgs, barcodesPackagesCheck[0]]);
    playSuccessBeep();
    sendEventToAnalytics('add_items_not_planned', {
      pickup: pickupCode,
      barcode: barcodesPackagesCheck[0].barcodes?.[0],
      driverId: userIdentification
    });
    closeInfoDrawer();
  };

  /**
   * @param {Array} barcodesPackagesCheck
   * not add package in driver list.
   */
  const cancelAddPackages = barcodesPackagesCheck => {
    setPackageWithBarcodeInputted(null);
    setPackageWithBarcodeInputted({
      ...barcodesPackagesCheck[0],
      error: 'Item não previsto',
      cancelledPackage: true
    });
    showSnackbar({
      message: 'Item não adicionado na lista',
      variant: 'error',
      enqueueSnackbar
    });
    sendEventToAnalytics('cancel_items_not_planned', {
      pickup: pickupCode,
      barcode: barcodesPackagesCheck[0].barcodes?.[0],
      driverId: userIdentification
    });
    closeInfoDrawer();
  };

  /**
   * @param {string} barcode
   * @param {bool} isFromBarcodeInput
   * show unintegrated error message.
   */
  const showUnintegratedError = (barcode, isFromBarcodeInput) => {
    setBarcodeAddedError({
      barcode,
      errorTitle: isFromBarcodeInput
        ? 'Item não está no nosso sistema'
        : 'Não conseguimos identificar o código do item',
      errorSubtitle:
        'Acesse a ajuda para conferir dicas ou tente bipar um código de outra etiqueta.',
      image: <WarningBipImage />,
      leftButton: {
        show: true,
        text: 'Ajuda',
        action: () => setShowHelpDrawer(true)
      },
      rightButton: {
        show: true,
        text: 'Tente Novamente',
        action: closeInfoDrawer
      },
      isFromBarcodeInput
    });
    showSnackbar({
      message: 'Item não adicionado na lista',
      variant: 'error',
      enqueueSnackbar
    });
  };

  /**
   * @param {string} barcode
   * @param {bool} isFromBarcodeInput
   * show error message if barcode already in list.
   */
  const showBarcodeAlreadyInList = (barcode, isFromBarcodeInput) => {
    setState(states.idle);
    showSnackbar({
      message: 'Item não adicionado na lista',
      variant: 'error',
      enqueueSnackbar
    });
    return setPackageWithBarcodeInputted({
      barcodes: [barcode],
      error: 'Item já identificado',
      errorSubtitle: 'Não é possível adicionar itens duplicados a sua lista.',
      isFromBarcodeInput
    });
  };
  /**
   * @param {string} barcode
   * @param {bool} isFromBarcodeInput
   * show already collected error message.
   */
  const showAlreadyCollectedError = (barcode, isFromBarcodeInput) => {
    setPackageWithBarcodeInputted({
      barcodes: [barcode],
      error: 'Item já retirado',
      errorSubtitle:
        'O item já foi coletado por outro entregador e por isso não será contabilizado.',
      isFromBarcodeInput
    });
    showSnackbar({
      message: 'Item não adicionado na lista',
      variant: 'error',
      enqueueSnackbar
    });
  };

  /**
   * @param {object} error
   * @param {string} barcode
   * @param {bool} isFromBarcodeInput
   * filters error messages coming from the backend and handles them.
   */
  const filteredPackageErrors = (error, barcode, isFromBarcodeInput) => {
    const unintegratedPackageError =
      'Este pacote aqui não está no nosso sistema. Remova da sua lista para continuar.';
    const barcodeAlreadyPickupError =
      'Um pacote com este código de barras já foi coletado em outro momento por outra pessoa. Remova da sua lista para continuar.';
    if (error.message === unintegratedPackageError) {
      showUnintegratedError(barcode, isFromBarcodeInput);
    } else if (error.message === barcodeAlreadyPickupError) {
      showAlreadyCollectedError(barcode, isFromBarcodeInput);
    } else {
      setBarcodeAddedError({
        barcode,
        errorTitle: error.message,
        errorSubtitle:
          'Acesse a ajuda para conferir dicas ou tente bipar um código de outra etiqueta.',
        showMainButton: true,
        isFromBarcodeInput
      });
    }
  };

  /**
   * @param {string} barcode
   * @param {BarcodeFormat} format: one of BarcodeFormat OR value "TYPED"
   * @param {bool} isFromBarcodeInput: If true, the barcode's package is not
   * added to the list until further action.
   */
  const onBarcodeAdded = ({ barcode, format, isFromBarcodeInput = false }) => {
    if (state === states.loading) {
      return;
    }

    setState(states.loading);
    if (!beepedPackages.length) {
      setScanningStart(new Date().toISOString());
    }

    if (barcodeAlreadyInList(barcode)) {
      showBarcodeAlreadyInList(barcode, isFromBarcodeInput);
      return;
    }

    sendEventToAnalytics('pickup_scanner_new_flow_barcode_identified', {
      pickup: pickupCode,
      format: format === 'TYPED' ? 'typed' : 'camera_beep',
      driverId: userIdentification
    });

    checkBarcodes({
      barcodes: [[{ content: barcode, format }]],
      companyId,
      pickupCode
    })
      .fetchError(() => {
        throw new Error('Confira a sua conexão e tente outra vez');
      })
      .json(({ barcodesPackagesCheck }) => {
        const barcodesWithError = barcodesPackagesCheck.filter(
          item => !!item.error
        );
        if (barcodesWithError.length > 0) {
          throw new Error(barcodesWithError[0].error);
        }
        playSuccessBeep();
        setState(states.success);

        const enabledBlockCompanyUnexpectedPackages = featureSwitchEnabledForCompanyId(
          {
            companyId,
            fsValue: enabledBlockCompanyIdToPickupUnexpectedPackages
          }
        );

        if (enabledBlockCompanyUnexpectedPackages && !itemsInPickup(barcode)) {
          setState(states.idle);
          return setInfoDrawerProps({
            open: true,
            image: <ErrorBipImage />,
            title: 'Item não previsto',
            subtitle:
              'O item não pode ser adicionado à sua lista por não fazer parte desta retirada',
            onCloseClick: closeInfoDrawer,
            mainButton: {
              show: true,
              text: 'Ok, entendi',
              action: () => cancelAddPackages(barcodesPackagesCheck)
            }
          });
        }

        if (!itemsInPickup(barcode)) {
          setState(states.idle);
          return setInfoDrawerProps({
            open: true,
            image: <WarningBipImage />,
            title: 'Item não previsto',
            subtitle:
              'Você pode adicioná-los, mas fique atento à capacidade do veículo. Deseja adicionar?',
            onCloseClick: closeInfoDrawer,
            leftButton: {
              show: true,
              text: 'Cancelar',
              action: () => cancelAddPackages(barcodesPackagesCheck)
            },
            rightButton: {
              show: true,
              text: 'Adicionar',
              action: () => {
                addPackages(barcodesPackagesCheck);
                if (format === 'TYPED') {
                  setPickupHadTypedBarcodes(pickup?.pickupCode);
                }
              }
            }
          });
        }
        setPackageWithBarcodeInputted({
          ...barcodesPackagesCheck[0],
          error: '',
          isFromBarcodeInput
        });
        showSnackbar({
          message: 'Item adicionado na lista',
          variant: 'successSnackbar',
          enqueueSnackbar
        });

        if (format === 'TYPED') {
          setPickupHadTypedBarcodes(pickup?.pickupCode);
        }

        return saveBeepedPackage(pkgs => [...pkgs, barcodesPackagesCheck[0]]);
      })
      .catch(error => {
        filteredPackageErrors(error, barcode, isFromBarcodeInput);
        return setState(states.idle);
      });
  };

  useEffect(() => {
    if (Object.keys(scannedBarcode).length) {
      onBarcodeAdded(scannedBarcode);
      setScannedBarcode({});
    }
  }, [scannedBarcode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      bgcolor={colors.neutrals.typeface.primary}
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="pickup-scanner-v2"
    >
      {!showBarcodeInput && (
        <>
          <BarcodeReaderTemplateV2
            onGoBack={onGoBack}
            onBarcodeRead={result => {
              setScannedBarcode(result);
            }}
            allowedFormats={getPossibleFormats()}
            onTypeBarcodeClick={() => {
              setPackageWithBarcodeInputted(null);
              setShowBarcodeInput(true);
            }}
            onHelpClick={() => setShowHelpDrawer(true)}
          />
          <PackageInfoDrawer
            currentCounter={beepedPackages.length}
            totalCounter={getTotalCounter()}
            loading={state === states.loading}
            beepedPackages={beepedPackages}
            onReviewListClick={onReviewListClick}
            packageInfo={packageWithBarcodeInputted}
          />
        </>
      )}
      {showBarcodeInput && (
        <BarcodeInput
          onGoBackClick={() => setShowBarcodeInput(false)}
          onHelpClick={() => setShowHelpDrawer(true)}
          fetchPackageInfo={result => {
            setScannedBarcode(result);
          }}
          loading={state === states.loading}
          packageInfo={packageWithBarcodeInputted}
          totalCounter={getTotalCounter()}
          beepedPackages={beepedPackages}
        />
      )}
      {showHelpDrawer && (
        <DrawerWithSteps
          open={showHelpDrawer}
          title="Ajuda"
          steps={helpSteps}
          onCloseClick={() => setShowHelpDrawer(false)}
        />
      )}
      {infoDrawerProps.open && (
        <InfoDrawer
          open={infoDrawerProps.open}
          image={infoDrawerProps.image}
          title={infoDrawerProps.title}
          subtitle={infoDrawerProps.subtitle}
          onCloseClick={closeInfoDrawer}
          mainButton={infoDrawerProps.mainButton}
          leftButton={infoDrawerProps.leftButton}
          rightButton={infoDrawerProps.rightButton}
        />
      )}
    </Box>
  );
}

PickupScannerV2.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired,
  onGoBack: PropTypes.func.isRequired,
  beepedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  saveBeepedPackage: PropTypes.func.isRequired,
  onReviewListClick: PropTypes.func.isRequired,
  userIdentification: PropTypes.number.isRequired
};

PickupScannerV2.defaultProps = {
  beepedPackages: []
};

PickupScannerV2.url = '/v2/bipar_pacotes';
