import getDistance from 'infra/services/get-distance';

function sortByProperty(
  list,
  propertyName,
  ascending = true,
  sortingFunction = item => item
) {
  list.sort((a, b) => {
    const valueA = sortingFunction(a[propertyName]);
    const valueB = sortingFunction(b[propertyName]);
    const order = ascending ? 1 : -1;

    return order * (valueA - valueB);
  });
}

async function closest(offerList) {
  const offerListWithDistanceFromDriver = await Promise.all(
    offerList.map(async offer => ({
      ...offer,
      distanceFromDriver: await getDistance(offer.waypoints[0].coordinates)
    }))
  );

  sortByProperty(offerListWithDistanceFromDriver, 'distanceFromDriver');

  return offerListWithDistanceFromDriver;
}

function pricier(offerList) {
  const clonedOfferList = [...offerList];
  sortByProperty(clonedOfferList, 'price', false, parseFloat);

  return clonedOfferList;
}

function lesserSteps(offerList) {
  const clonedOfferList = [...offerList];
  sortByProperty(clonedOfferList, 'steps');

  return clonedOfferList;
}

function shortRoutes(offerList) {
  const clonedOfferList = [...offerList];
  sortByProperty(clonedOfferList, 'distance');

  return clonedOfferList;
}

export { closest, pricier, lesserSteps, shortRoutes };
