import { useEffect } from 'react';
import history, { goTo } from 'operations/history';
import { surveyPersistedStateKeys } from 'operations/survey';
import {
  remoteConfigFlags,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForDriverCompanyTypeRelation
} from 'operations/feature-switches';
import captureExceptionToSentry from 'operations/config/sentry';
import { useRemoteConfig } from '@loggi/firebase-feature-switches';

export const skipSurveyKey = 'skipSurvey';

export default function useSurvey() {
  const { availableSurveys } = useRemoteConfig(
    remoteConfigFlags.surveys.name,
    remoteConfigFlags.surveys.defaultValue
  );
  const { rules, requiredFields } = remoteConfigFlags.surveys.extras;

  /**
   *
   * @param {*} surveyRemoteConfig Survey config coming from remote config
   * @returns {Boolean} true if the surveyRemoteConfig contains all required fields
   */
  function isRemoteConfigValid(surveyRemoteConfig) {
    if (
      requiredFields.every(requiredField => surveyRemoteConfig[requiredField])
    ) {
      return true;
    }
    captureExceptionToSentry({
      message: `Invalid survey remote config: ${JSON.stringify(
        surveyRemoteConfig
      )}`
    });
    return false;
  }

  /**
   *
   * @param {*} configuredLMCsForRollout LMCs configured in remote config
   * @returns {Boolean} true if the survey should be presented to the driver according to LMC
   */
  function isRemoteConfigEnabledForLMC(configuredLMCsForRollout) {
    if (!configuredLMCsForRollout) return false;
    if (configuredLMCsForRollout === 'all') return true;
    return featureSwitchEnabledForDriverLMC(configuredLMCsForRollout);
  }

  /**
   *
   * @param {*} configuredCompanyRelationForRollout Company relation (MEI, LEVE, FROTA PRÓPRIA) configured in remote config
   * @returns {Boolean} true if the survey should be presented to the driver according to company relation
   */
  function isRemoteConfigEnabledForCompanyRelation(
    configuredCompanyRelationForRollout
  ) {
    if (!configuredCompanyRelationForRollout) return false;
    if (configuredCompanyRelationForRollout === 'all') return true;
    return featureSwitchEnabledForDriverCompanyTypeRelation(
      configuredCompanyRelationForRollout
    );
  }

  /**
   *
   * @param {*} surveyRemoteConfig Survey config coming from remote config
   * @returns {Boolean} true if the survey matches the rollout rules
   */
  function matchesRolloutRules(surveyRemoteConfig) {
    return (
      isRemoteConfigEnabledForLMC(surveyRemoteConfig.rolloutLMCs) &&
      isRemoteConfigEnabledForCompanyRelation(
        surveyRemoteConfig.rolloutCompanyRelation
      )
    );
  }

  /**
   *
   * @param {String} configPath path coming from remote config
   * @param {String} currentPath path coming from history.listen
   * @returns {Boolean} true if the currentPath matches the configPath
   */
  function isPathInRemoteConfig(configPath, currentPath) {
    if (configPath.useRegex) {
      const regex = new RegExp(configPath.route);
      return regex.test(currentPath);
    }
    return configPath.route === currentPath;
  }

  /**
   *
   * @param {*} param  Paths coming from history.listen
   * @param {String} param.previousPath Route where the user was.
   * @param {String} param.nextPath Route where the user is going.
   * @returns {Object} Survey config that matches the paths. If no config matches, returns null
   */
  function getSurveyRemoteConfig({ previousPath, nextPath }) {
    if (nextPath.includes('/survey')) {
      return null;
    }
    return availableSurveys.find(survey => {
      const matchFrom = isPathInRemoteConfig(survey.from, previousPath);
      const matchTo = isPathInRemoteConfig(survey.to, nextPath);
      return matchFrom && matchTo;
    });
  }

  /**
   *
   * @param {String} lastAnswered ISO String of the last time the survey was answered
   * @param {String} remoteConfigRule Survey recurrence rule coming from remote config
   * @param {Number} datetimeRule Datetime rule in milliseconds
   * @returns {Boolean} true if the survey has expired
   */
  const hasSurveyAnswerExpired = (
    lastAnswered,
    remoteConfigRule,
    datetimeRule
  ) => {
    if (!lastAnswered) return true;

    const numberConfigured = remoteConfigRule.split(':')[0]; // 2:days or 2:weeks
    const diffTime = Math.abs(new Date() - new Date(lastAnswered));
    const diffNumber = Math.floor(diffTime / datetimeRule);
    return diffNumber >= numberConfigured;
  };

  /**
   *
   * @param {Object} surveyRemoteConfig Survey config coming from remote config
   * @returns {Boolean} true if the survey should be shown
   */
  function matchesRecurrenceRule(surveyRemoteConfig) {
    if (surveyRemoteConfig.rule === rules.always) {
      return true;
    }

    const lastTimeSurveyAnswered = JSON.parse(
      localStorage.getItem(
        `${surveyPersistedStateKeys.surveyAnswered.key}/${
          surveyRemoteConfig.slug
        }`
      )
    );

    if (surveyRemoteConfig.rule === rules.once) {
      return !lastTimeSurveyAnswered;
    }

    if (surveyRemoteConfig.rule.includes(rules.days)) {
      return hasSurveyAnswerExpired(
        lastTimeSurveyAnswered,
        surveyRemoteConfig.rule,
        1000 * 60 * 60 * 24
      );
    }

    if (surveyRemoteConfig.rule.includes(rules.weeks)) {
      return hasSurveyAnswerExpired(
        lastTimeSurveyAnswered,
        surveyRemoteConfig.rule,
        1000 * 60 * 60 * 24 * 7
      );
    }
    return false;
  }

  let prevLocation;
  useEffect(() => {
    const unlisten = history.listen(nextLocation => {
      /**
       * If any page has any conditional to show the survey,
       * the component must send the flag defined in skipSurveyKey as true for
       * cases in which the survey should not be shown
       */
      const showSurvey = !nextLocation.state?.[skipSurveyKey];
      const surveyRemoteConfig = getSurveyRemoteConfig({
        previousPath: prevLocation?.pathname,
        nextPath: nextLocation?.pathname
      });

      if (nextLocation.state && skipSurveyKey in nextLocation.state) {
        /**
         * We clear the flag so that other routes do not suffer interference
         */
        // eslint-disable-next-line no-param-reassign
        delete nextLocation.state?.[skipSurveyKey];
      }
      if (
        showSurvey &&
        surveyRemoteConfig &&
        isRemoteConfigValid(surveyRemoteConfig) &&
        matchesRecurrenceRule(surveyRemoteConfig) &&
        matchesRolloutRules(surveyRemoteConfig)
      ) {
        goTo.survey(surveyRemoteConfig.slug, {
          ...nextLocation.state,
          routeToGo: nextLocation.pathname
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevLocation = nextLocation;
    });
    return () => unlisten();
  }, [availableSurveys.length]);

  return prevLocation;
}
