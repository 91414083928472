import apiLoggiWeb from 'infra/api';
import apiAllocation from 'infra/api/allocation';
import geolocation from './geolocation';
import { OFFER_CHANNEL } from './constants';

export const getDriverOffersQuery = `
  query GetDriverOffers($filterType: Int, $filterValues: [String], $latitude: Float, $longitude: Float) {
    getDriverOffers(filterType: $filterType, filterValues: $filterValues, latitude: $latitude, longitude: $longitude) {
      itineraryId
      distance
      price
      steps
      deadlineCollect
      waypoints {
        city
        neighborhood
        indexDisplay
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

async function getAvailableOffersOnAllocation({
  filterType,
  filterValues,
  sortBy,
  offerChannel
}) {
  const pos = await geolocation();
  let parameters = `?driverPosition.latitude=${
    pos.latitude
  }&driverPosition.longitude=${pos.longitude}&channel=${offerChannel}`;
  if (sortBy) parameters += `&filter.sortBy=${sortBy}`;
  if (filterType && filterValues)
    parameters += `&filter.type=${filterType}&filter.items.value=${filterValues}`;
  const url = `/v1/offerdemand/availableoffers/${parameters}`;

  return apiAllocation()
    .url(url)
    .get()
    .json();
}

export default async function getAvailableOffers({
  filterType = null,
  filterValues = null,
  showcase = false,
  sortBy = null,
  useAllocation = false
} = {}) {
  if (useAllocation) {
    const offerChannel = showcase
      ? OFFER_CHANNEL.OFFER_CHANNEL_SHOWCASE
      : OFFER_CHANNEL.OFFER_CHANNEL_AVAILABLE_OFFERS;

    return getAvailableOffersOnAllocation({
      filterType,
      filterValues,
      sortBy,
      offerChannel
    });
  }

  const pos = await geolocation();

  let searchParams = `?latitude=${pos.latitude}&longitude=${
    pos.longitude
  }&showcase=${showcase}`;
  if (filterType && filterValues) {
    searchParams += `&filter_type=${filterType}&filter_values=${filterValues}`;
  }
  if (sortBy) searchParams += `&sort_by=${sortBy}`;

  const url = `/drivers/v2/offer_list/${searchParams}`;

  return apiLoggiWeb()
    .url(url)
    .get()
    .json();
}
