import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar } from '@material-ui/core';
import { ReactComponent as IconBoxOutline } from 'view/molecules/assignment-row/icons/pin.svg';
import { ReactComponent as IconCapacityReserve } from 'view/molecules/assignment-row/icons/schedule-assignment.svg';
import { ReactComponent as IconCapacityReserveNewTheme } from 'view/molecules/assignment-row/icons/schedule-assignment-new-theme.svg';
import { ReactComponent as IconCapacityReservefromDetails } from 'view/molecules/assignment-row/icons/schedule-assignment-pickup-details.svg';
import { ReactComponent as IconBoxOutlineNewTheme } from 'view/molecules/assignment-row/icons/pin-new-theme.svg';
import { ReactComponent as IconBoxOutlineNewThemefromDetails } from 'view/molecules/assignment-row/icons/pin-new-theme-pickup-details.svg';
import { indexIconStyle, indexIconType } from './constants';

const numberIconTemplate = (
  index,
  useNewTheme,
  fromDetails,
  assignmentStatusCode
) => {
  return (
    <>
      {useNewTheme ? (
        <Box
          data-testid="index-icon-new-theme-box"
          style={
            indexIconStyle[indexIconType.numberNewTheme](
              fromDetails,
              assignmentStatusCode
            ).style
          }
          border={
            indexIconStyle[indexIconType.numberNewTheme](
              fromDetails,
              assignmentStatusCode
            ).border
          }
          borderColor={
            indexIconStyle[indexIconType.numberNewTheme](
              fromDetails,
              assignmentStatusCode
            ).borderColor
          }
          clone
        >
          <Avatar data-testid="index-icon-new-theme">
            <Box
              color={
                indexIconStyle[indexIconType.numberNewTheme](
                  fromDetails,
                  assignmentStatusCode
                ).color
              }
              fontSize="14px"
              fontWeight={700}
              textAlign="center"
            >
              {index}
            </Box>
          </Avatar>
        </Box>
      ) : (
        <Box
          style={indexIconStyle[indexIconType.number].style}
          border={indexIconStyle[indexIconType.number].border}
          borderColor={indexIconStyle[indexIconType.number].borderColor}
          clone
        >
          <Avatar data-testid="index-icon">
            <Box
              color={indexIconStyle[indexIconType.number].color}
              fontSize="0.5em"
              fontWeight={900}
              textAlign="center"
            >
              {index}
            </Box>
          </Avatar>
        </Box>
      )}
    </>
  );
};

const getAssignmentIcon = (useNewTheme, fromDetails) => {
  if (useNewTheme) {
    if (fromDetails) {
      return (
        <IconBoxOutlineNewThemefromDetails data-testid="index-details-icon-new-theme" />
      );
    }
    return (
      <IconBoxOutlineNewTheme data-testid="index-assignment-icon-new-theme" />
    );
  }
  return <IconBoxOutline data-testid="index-assignment-icon" />;
};

const getCapacityReserveIcon = (useNewTheme, fromDetails) => {
  if (useNewTheme) {
    if (fromDetails) {
      return (
        <IconCapacityReservefromDetails data-testid="index-details-icon" />
      );
    }
    return (
      <IconCapacityReserveNewTheme
        data-testid="index-capacity-reserve-icon-new-theme"
        style={{ height: 26, width: 26 }}
      />
    );
  }
  return <IconCapacityReserve data-testid="index-capacity-reserve-icon" />;
};

const iconMap = {
  [indexIconType.default]: (_, useNewTheme, fromDetails) =>
    getAssignmentIcon(useNewTheme, fromDetails),
  [indexIconType.number]: (
    assignmentIndex,
    useNewTheme,
    fromDetails,
    assignmentStatusCode
  ) =>
    numberIconTemplate(
      assignmentIndex,
      useNewTheme,
      fromDetails,
      assignmentStatusCode
    ),
  [indexIconType.capacityReserve]: (_, useNewTheme, fromDetails) =>
    getCapacityReserveIcon(useNewTheme, fromDetails)
};

function AssignmentIndex({
  assignmentIndex,
  shouldShowScheduledPickupIcon,
  useNewTheme,
  fromDetails,
  assignmentStatusCode
}) {
  const keyToIconMap = () => {
    if (shouldShowScheduledPickupIcon) return indexIconType.capacityReserve;
    if (assignmentIndex && assignmentIndex !== '0') return indexIconType.number;
    return indexIconType.default;
  };

  return (
    <Box mr={2.5} display="flex" flexDirection="column" justifyContent="center">
      {iconMap[keyToIconMap()](
        assignmentIndex,
        useNewTheme,
        fromDetails,
        assignmentStatusCode
      )}
    </Box>
  );
}

AssignmentIndex.propTypes = {
  assignmentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldShowScheduledPickupIcon: PropTypes.bool,
  useNewTheme: PropTypes.bool,
  fromDetails: PropTypes.bool,
  assignmentStatusCode: PropTypes.number
};

AssignmentIndex.defaultProps = {
  assignmentIndex: '',
  shouldShowScheduledPickupIcon: false,
  useNewTheme: false,
  fromDetails: false,
  assignmentStatusCode: null
};

export default AssignmentIndex;
