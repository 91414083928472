import { Machine, assign, sendParent } from 'xstate';

export const STATES = {
  initStep: 'initStep',
  signatureStep: 'signatureStep'
};

export const ACTIONS = {
  CONTINUE: 'CONTINUE',
  SIGNATURE: 'SIGNATURE',
  CHANGE: 'CHANGE',
  BACK: 'BACK'
};

const signatureMachine = Machine(
  {
    id: 'signature',
    context: {
      signature: null
    },
    initial: STATES.initStep,
    states: {
      [STATES.initStep]: {
        on: {
          [ACTIONS.BACK]: {
            actions: 'back'
          },
          [ACTIONS.SIGNATURE]: {
            target: STATES.signatureStep
          }
        }
      },
      [STATES.signatureStep]: {
        on: {
          [ACTIONS.CHANGE]: {
            actions: ['change']
          },
          [ACTIONS.BACK]: {
            actions: ['back']
          },
          [ACTIONS.CONTINUE]: {
            cond: 'hasSignature',
            actions: ['continue']
          }
        }
      }
    }
  },
  {
    guards: {
      hasSignature: context => {
        return !!context.signature;
      }
    },
    actions: {
      back: sendParent(() => ({
        type: ACTIONS.BACK
      })),
      change: assign({
        signature: (_, event) => {
          return event.value.signature;
        }
      }),
      continue: sendParent(context => ({
        type: 'SIGNATURE.INFO',
        data: context
      }))
    }
  }
);

export default signatureMachine;
