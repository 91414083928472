import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import getDistance from 'infra/services/get-distance';
import {
  noDecimalFormatDistance,
  formatMoney,
  parseDistance
} from 'view/molecules/offer-card/util';
import CONSTANTS from 'view/molecules/offer-card/constants';

const OfferCardOpportunistic = ({ offer }) => {
  const [originDistance, setOriginDistance] = useState(null);

  const point = offer.waypoints[0].coordinates;

  useEffect(() => {
    getDistance(point)
      .then(dist => setOriginDistance(parseDistance(dist)))
      .catch(() => setOriginDistance('-'));
  }, [point]);

  return (
    <Box
      bgcolor="#E5F0FF"
      color="#003B99"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={3}
      borderRadius={14}
    >
      <Typography
        data-testid="offer-header"
        variant="subtitle1"
        font-weight="600"
      >
        No seu caminho,{' '}
        <Box fontWeight="fontWeightBold" data-testid="origin-distance">
          {originDistance} de você!
        </Box>
      </Typography>

      <Typography variant="body2">
        <Box pt={2}>{CONSTANTS.EARN_UP_TO}</Box>
      </Typography>

      <Typography variant="h5">
        <Box fontWeight="fontWeightBold">{formatMoney(offer.price)}</Box>
      </Typography>

      <Typography variant="body1">
        <Box pt={1}>
          {offer.steps} {CONSTANTS.STOPS} •{' '}
          {noDecimalFormatDistance(offer.distance)} {CONSTANTS.TOTALS}
        </Box>
      </Typography>
    </Box>
  );
};

OfferCardOpportunistic.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    steps: PropTypes.number.isRequired,
    deadlineCollect: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired
};

export default OfferCardOpportunistic;
