const opportunisticSurveyShow = 'opportunisticSurveyShow';

function getShowOpportunisticSurvey() {
  const result = localStorage.getItem(opportunisticSurveyShow);
  return JSON.parse(result) === true;
}

function setShowOpportunisticSurvey() {
  const result = localStorage.getItem(opportunisticSurveyShow);
  if (!result) {
    localStorage.setItem(opportunisticSurveyShow, true);
  }
}

function setOpportunisticSurveyAnswered() {
  localStorage.setItem(opportunisticSurveyShow, false);
}

export {
  getShowOpportunisticSurvey,
  setShowOpportunisticSurvey,
  setOpportunisticSurveyAnswered
};
