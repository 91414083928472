import { Machine } from 'xstate';

export const mutationStates = {
  SYNCING: 'syncing',
  SYNCED: 'synced',
  FAILED_TO_SYNC: 'failed to sync'
};

const packageMutationMachine = Machine({
  id: 'package mutation',
  initial: 'syncing',
  states: {
    [mutationStates.SYNCING]: {
      on: {
        success: 'synced',
        failure: 'failed to sync'
      }
    },
    [mutationStates.SYNCED]: {
      type: 'final'
    },
    [mutationStates.FAILED_TO_SYNC]: {
      type: 'final'
    }
  }
});

export default packageMutationMachine;
