export default {
  EMPTY_LIST_TITLE: 'Sem ofertas no momento',
  EMPTY_LIST_SUBTITLE:
    'Confira se está com localização ativada em uma região com ofertas ou se a sua última rota está finalizada.',
  DISABLED_LIST_TITLE: 'Procurando uma oferta?',
  DISABLED_LIST_SUBTITLE:
    'Você pode buscar uma na agência mais próxima ou aguardar as notificações.',
  TEXT_BUTTON_REFRESH: 'Atualizar',
  FEATURED_OFFERS: 'Ofertas em destaque',
  SHOW_ALL_OFFERS: 'Mostrar todas as ofertas',
  NEED_HELP: 'Precisa de ajuda?'
};
