import firebase from 'firebase/app';
import { mutationStates } from 'models/package-mutation';
import {
  messages,
  problemReasons
} from 'view/templates/delivering-problems/constants';
import db from './mutations-db';
import deliveryEvidenceDB from './delivery-evidence-db';
import updateStatusService from './update-status-service';
import doUploadFileToS3 from './upload-file-s3';
import packageDeliveredStatusDB from './package-info-delivered-status-db';

export const VALIDATION_ERROR_MESSAGE =
  'Campos inválidos. Verifique os valores informados';
export const SUCCESS_MESSAGE = 'Entrega salva! Agora é partir pra próxima';

/**
 * Core function used by view layer to dispatch package update status action.
 *
 * @param {object} updateStatusData object containing values for keys: status,
 *                                  barcode, latitude, longitude.
 * @param {function} onUpload function containing image and url.
 *
 * @returns {Promise}
 */
export const doUploadDeliveryEvidenceToS3 = async updateStatusData => {
  const deliveryEvidence = await deliveryEvidenceDB.getDeliveryEvidenceByPackageId(
    updateStatusData.packageId
  );
  if (deliveryEvidence) {
    return doUploadFileToS3(
      deliveryEvidence.image,
      deliveryEvidence.url,
      deliveryEvidence.contentType
    );
  }
  return Promise.resolve();
};

export const putPackageInfoDeliveredStatus = async (
  updateStatusData,
  markDeliveredPkg
) => {
  if (updateStatusData.status === 2 && markDeliveredPkg) {
    const pkgDeliveredInfoData = await packageDeliveredStatusDB.getPackageDeliveredStatusByPackageId(
      updateStatusData.packageId
    );
    const packageDeliveredStatusData = {
      packageId: updateStatusData.packageId,
      statusUpdateCount: pkgDeliveredInfoData?.statusUpdateCount
        ? pkgDeliveredInfoData.statusUpdateCount + 1
        : 1,
      updated: new Date().toISOString()
    };

    return packageDeliveredStatusDB.putPackageDeliveredStatus(
      packageDeliveredStatusData
    );
  }
  return Promise.resolve();
};

export const updateStatus = async ({
  updateStatusData,
  onMutationStateChange = () => ({}),
  markDeliveredPkg = false
}) => {
  if (updateStatusData?.status === undefined || !updateStatusData?.packageId) {
    return Promise.reject(new Error(VALIDATION_ERROR_MESSAGE));
  }

  const promise = db.putPackageMutation(updateStatusData);

  firebase.analytics().logEvent('update_status', {
    packageId: updateStatusData.packageId,
    updateStatusData
  });

  promise
    .then(() => onMutationStateChange(mutationStates.SYNCING))
    .then(() => doUploadDeliveryEvidenceToS3(updateStatusData))
    .then(() => updateStatusService(updateStatusData))
    .then(() =>
      putPackageInfoDeliveredStatus(updateStatusData, markDeliveredPkg)
    )
    .then(() =>
      deliveryEvidenceDB.deleteDeliveryEvidence(updateStatusData.packageId)
    )
    .then(() => db.updateMutationState(updateStatusData, 'success'))
    .catch(error => db.updateMutationState(updateStatusData, 'failure', error))
    .then(({ nextState, error }) => onMutationStateChange(nextState, error));

  if (
    updateStatusData.status === problemReasons.unableToGoToDeliveryLocation.id
  ) {
    return promise.then(() => messages.notifications.noAttempToDeliver);
  }

  return promise.then(() => ({ message: SUCCESS_MESSAGE }));
};
