export default {
  notifications: {
    defaultUpdateStatusFailureText:
      'Eita! Deu algum ruim ao atualizar a entrega, tente novamente.'
  },
  selectionStep: {
    headerText: 'Problemas na entrega'
  },
  loadingStep: {
    infoText: 'Estamos atualizando a informação da sua entrega...'
  },
  tryToSolve: 'Tentar resolver ligando para',
  confirmProblemButtonLabel: 'Informar tentativa',
  detailsScreenText:
    'Tudo bem. Nesse caso você pode tentar novamente mais tarde ou  devolver ainda hoje para agência.'
};
