import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { GoogleSignIn } from '@loggi/authentication';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import getAppVersion from '@loggi/drivers-app-version';
import { colors } from '@loggi/mar';
import { GOOGLE_PROVIDER } from '@loggi/authentication/src/screens/signin/constants';
import { ReactComponent as LoggiIcon } from 'view/assets/svg/loggi.svg';
import { ReactComponent as EmailProfileIcon } from 'view/assets/svg/email-profile.svg';
import { pages } from 'routes';
import {
  onboardingPageTexts,
  useStyles,
  SMS_SIGN_IN_BUTTON_ID
} from './constants';

const SmsSignIn = ({ sendEventToAnalytics }) => {
  return (
    <Box>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          size="large"
          data-testid={SMS_SIGN_IN_BUTTON_ID}
          onClick={() => {
            sendEventToAnalytics('signed_in_by_sms', {});
            window.driverAppBridge?.redirectToSMSLogin?.();
          }}
        >
          {onboardingPageTexts.smsLogin}
        </Button>
      </Grid>
    </Box>
  );
};
SmsSignIn.propTypes = {
  sendEventToAnalytics: PropTypes.func
};

SmsSignIn.defaultProps = {
  sendEventToAnalytics: () => {}
};

const CreateAccount = ({ createAccountPath }) => {
  const classes = useStyles();
  const { federatedSignIn } = useAmplifyAuth();

  const createAccountClick = () => {
    federatedSignIn(GOOGLE_PROVIDER, createAccountPath);
  };
  return (
    <Box>
      <Grid item>
        <Box display="flex">
          <Typography variant="body1">
            {onboardingPageTexts.isNewUser}{' '}
            <Button
              className={classes.link}
              onClick={() => {
                createAccountClick();
              }}
            >
              {onboardingPageTexts.signup}
            </Button>
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};
CreateAccount.propTypes = {
  createAccountPath: PropTypes.string.isRequired
};

function UserLoggedInToRegister({ isAlreadyDriver }) {
  return (
    <Box pt={2.5}>
      <EmailProfileIcon height="40px" />
      <Box>
        <Typography variant="h4">
          {onboardingPageTexts.titleNotAssociateCognito}
        </Typography>
      </Box>
      <Box pt={1.5}>
        <Typography variant="subtitle2">
          {isAlreadyDriver
            ? onboardingPageTexts.questionNotAssociateCognitoAlreadyDriver
            : onboardingPageTexts.questionNotAssociateCognito}
        </Typography>
      </Box>
    </Box>
  );
}
UserLoggedInToRegister.propTypes = {
  isAlreadyDriver: PropTypes.bool
};
UserLoggedInToRegister.defaultProps = {
  isAlreadyDriver: false
};

function UserLoggedInToRegisterButtons({
  logout,
  handleCreateAccountButtonClick,
  authenticatedUser,
  sendEventToAnalytics,
  isAlreadyDriver
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box textAlign="center">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => {
              sendEventToAnalytics('associate_account_with_cognito', {
                email: authenticatedUser.email
              });
              localStorage.removeItem('redirectToAssociateCognitoPage');
              history.push(pages.login);
            }}
          >
            {isAlreadyDriver
              ? onboardingPageTexts.associateAccount
              : onboardingPageTexts.alreadyDriver}
          </Button>
        </Grid>
        {!isAlreadyDriver && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                sendEventToAnalytics('create_account_by_cognito', {
                  email: authenticatedUser.email
                });
                handleCreateAccountButtonClick();
              }}
            >
              {onboardingPageTexts.wantCreateAccount}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>{authenticatedUser.email}</b> {onboardingPageTexts.wrongEmail}{' '}
            <Button className={classes.link} onClick={logout}>
              {onboardingPageTexts.logoutAction}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

UserLoggedInToRegisterButtons.propTypes = {
  logout: PropTypes.func.isRequired,
  handleCreateAccountButtonClick: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  sendEventToAnalytics: PropTypes.func,
  isAlreadyDriver: PropTypes.bool
};
UserLoggedInToRegisterButtons.defaultProps = {
  isAlreadyDriver: false
};

UserLoggedInToRegisterButtons.defaultProps = {
  sendEventToAnalytics: () => {}
};

const UserNotLoggedIn = () => {
  return (
    <Box>
      <LoggiIcon height="40px" />
      <Box pt={2.5}>
        <Typography variant="h3" component="span" fontWeight="fontWeightBold">
          {onboardingPageTexts.titleCognitoSms}
        </Typography>
      </Box>
      <Box pb={1.5}>
        <Typography>{onboardingPageTexts.subtitleCognitoSms}</Typography>
      </Box>
    </Box>
  );
};

const UserNotLoggedInButtons = ({
  createAccountPath,
  sendEventToAnalytics
}) => {
  const classes = useStyles();

  const onHelpMe = event => {
    event.preventDefault();
    window.open(onboardingPageTexts.helpLink);
  };
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <CreateAccount createAccountPath={createAccountPath} />
      </Box>
      <GoogleSignIn
        buttonText={onboardingPageTexts.googleLogin}
        sendEventToAnalytics={sendEventToAnalytics}
      />
      <SmsSignIn sendEventToAnalytics={sendEventToAnalytics} />
      <Box py={2.5} textAlign="center">
        <Typography variant="body1">
          {onboardingPageTexts.help}{' '}
          <Button className={classes.link} onClick={onHelpMe}>
            {onboardingPageTexts.helpAction}
          </Button>
        </Typography>
        <Box
          textAlign="center"
          alignItems="flex-start"
          py={4}
          color={colors.smoke[700]}
          data-testid="app-version"
        >
          {getAppVersion()}
        </Box>
      </Box>
    </Box>
  );
};

UserNotLoggedInButtons.propTypes = {
  createAccountPath: PropTypes.string.isRequired,
  sendEventToAnalytics: PropTypes.func
};

UserNotLoggedInButtons.defaultProps = {
  sendEventToAnalytics: () => {}
};

export default function OnboardingCognitoSmsPage({
  createAccountPath,
  authenticatedUser,
  handleCreateAccountButtonClick,
  logout,
  sendEventToAnalytics
}) {
  const classes = useStyles();

  const isAlreadyDriver = () => {
    const redirectToAssociateCognitoPage = localStorage.getItem(
      'redirectToAssociateCognitoPage'
    );
    return Boolean(authenticatedUser && redirectToAssociateCognitoPage);
  };

  return (
    <Grid>
      <Grid item sm={12} md={6} className={classes.contentGridMobile}>
        <Box pt={5} px={3.5} className={classes.contentTextMobile}>
          <Box display="flex" flexGrow={1} alignItems="flex-start">
            {authenticatedUser ? (
              <UserLoggedInToRegister isAlreadyDriver={isAlreadyDriver()} />
            ) : (
              <UserNotLoggedIn />
            )}
          </Box>
          <Box className={classes.cognitoSmsBox}>
            {authenticatedUser ? (
              <UserLoggedInToRegisterButtons
                logout={logout}
                handleCreateAccountButtonClick={handleCreateAccountButtonClick}
                authenticatedUser={authenticatedUser}
                sendEventToAnalytics={sendEventToAnalytics}
                isAlreadyDriver={isAlreadyDriver()}
              />
            ) : (
              <UserNotLoggedInButtons
                createAccountPath={createAccountPath}
                sendEventToAnalytics={sendEventToAnalytics}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

OnboardingCognitoSmsPage.propTypes = {
  authenticatedUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  createAccountPath: PropTypes.string.isRequired,
  handleCreateAccountButtonClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  sendEventToAnalytics: PropTypes.func
};

OnboardingCognitoSmsPage.defaultProps = {
  sendEventToAnalytics: () => {}
};
