import { facadePhotoTypes, messages } from './constants';

export const getTitle = type => {
  switch (type) {
    case facadePhotoTypes.pickupFacadePhoto:
      return messages.pickup.title;
    case facadePhotoTypes.deliveryMailBox:
      return messages.mailbox.title;
    default:
      return messages.delivery.title;
  }
};

export default { getTitle };
