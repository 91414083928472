import React from 'react';

import { colors } from '@loggi/mar';
import { Box, Typography, Button } from '@material-ui/core';

import {
  logout,
  authMethods,
  getAuthMethod
} from 'operations/auth/credentials-storage';
import { ReactComponent as PackageWithWarningTriangle } from 'view/atoms/illustrations/package-with-warning-triangle.svg';

const loginText = 'Entrar';

export const messages = {
  shared: {
    title: 'Opa! O seu acesso no aplicativo expirou'
  },
  default: {
    description: 'Entre novamente para fazer suas atividades',
    buttonConfirm: loginText
  },
  [authMethods.cognito]: {
    description: 'Entre novamente com seu email',
    buttonConfirm: loginText
  },
  [authMethods.email_api_key]: {
    description: 'Entre novamente com seu telefone',
    buttonConfirm: loginText
  },
  [authMethods.link]: {
    description:
      'Tente acessar o link novamente. Se não funcionar, peça para seu gestor',
    buttonConfirm: ''
  }
};

export default function AuthError() {
  const authMethod = getAuthMethod();
  const data = messages[authMethod || 'default'];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      px={2.5}
      pt={4}
      pb={2.5}
      color={colors.smoke[900]}
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box textAlign="center">
          <PackageWithWarningTriangle />
        </Box>

        <Box py={1} display="flex" justifyContent="center">
          <Typography variant="subtitle1" align="center">
            {messages.shared.title}
          </Typography>
        </Box>
        <Box
          py={1}
          color={colors.smoke[700]}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Typography variant="body2" align="center">
            {data.description}
          </Typography>
        </Box>
      </Box>
      {data.buttonConfirm && (
        <Box pt={3}>
          <Button
            variant="contained"
            data-testid="main-button"
            color="primary"
            size="large"
            fullWidth
            onClick={() => {
              logout();
            }}
          >
            {data.buttonConfirm}
          </Button>
        </Box>
      )}
    </Box>
  );
}
