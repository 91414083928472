const messages = {
  newPackageReturnReminder: {
    title: deadlineDeliveryTime =>
      `Entregue os pacotes${
        deadlineDeliveryTime ? ` ${deadlineDeliveryTime}` : ' até às 22:00'
      }`,
    dcReturnTimeAlert:
      'Você pode ligar para quem vai receber o pacote direto pelo aplicativo.',
    agencyReturnReminderAlert:
      'Atenção: Caso não consiga entregar, devolva os pacotes restantes na base indicada.',
    proceedButtonText: 'Começar a entregar'
  },
  packageReturnReminder: {
    title: 'Antes de começar',
    returnPackage: 'Entregue os pacotes: ',
    getReturnWeekday: returnPointCloseAt =>
      `. Se não conseguir, vá para a agência indicada${
        returnPointCloseAt ? ` até ${returnPointCloseAt}` : ''
      } e devolva ainda hoje.`,
    returnWeekend: '. Se não conseguir, vá para a agência indicada e devolva.',
    dcReturnTimeAlert:
      'É bom lembrar: você pode ligar para quem vai receber o pacote direto pelo aplicativo.',
    howToReturnButtonText: 'Saiba como devolver',
    alrightIGetItButtonText: 'Ok, entendi',
    defaultReturnReminder: 'até às 22h',
    geofence: {
      title: 'Registre informações da entrega no local de entrega.',
      subtitle:
        'Para registrar a entrega é obrigatório estar no endereço de destino indicado na plataforma.',
      button: 'Próximo'
    }
  },
  agencyReminder: {
    title: 'Entregue na base indicada',
    text:
      'Os pacotes que você coletou devem ser entregues na base indicada pelo aplicativo',
    startDeliveryButton: 'Começar entrega',
    infoBox:
      'É bom lembrar que você não precisa entregar no endereço que está na etiqueta do pacote, beleza?'
  }
};

export default messages;
