import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LocationLoggiPermission } from 'view/atoms/illustrations/location-loggi-permission.svg';
import { Box, Typography, Button } from '@material-ui/core';

export const messages = {
  title: 'Ative sua localização em tempo real',
  text:
    'Percebemos que sua localização está desativada. Para continuar entregando, basta ir nas configurações do aplicativo, selecionar Localização e escolher a opção Permitir o tempo todo.',
  permissionVerifyButton: 'Verificar permissão de localização'
};

export default function GeolocationPermissionNativeApp({ onPermissionVerify }) {
  return (
    <Box
      px={2.5}
      py={2.5}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <LocationLoggiPermission />

        <Box pt={5} pb={1.25} display="flex">
          <Typography variant="h5" align="center">
            {messages.title}
          </Typography>
        </Box>
        <Typography variant="subtitle2" align="center">
          {messages.text}
        </Typography>
      </Box>

      <Box>
        <Box pb={2}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            data-testid="btn-start-delivery-to-agency"
            onClick={onPermissionVerify}
          >
            {messages.permissionVerifyButton}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

GeolocationPermissionNativeApp.propTypes = {
  onPermissionVerify: PropTypes.func.isRequired
};
