import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import messages from './messages';

function BarcodeItem({ content }) {
  return (
    <Box
      border={0}
      borderBottom={1}
      borderColor={colors.smoke[300]}
      px={0.5}
      py={2.5}
    >
      <Typography variant="subtitle2">
        {messages.packageScanningForMultipleBarcodes.textPackage}
      </Typography>
      <Typography
        variant="body2"
        color="textPrimary"
        data-testid="multiple-barcode"
      >
        {content}
      </Typography>
    </Box>
  );
}
BarcodeItem.propTypes = {
  content: PropTypes.string.isRequired
};

export default function PackageScanningForMultipleBarcodes({
  barcodesContent
}) {
  return (
    <Box
      bgcolor="white"
      position="fixed"
      borderRadius={16}
      mb={-2}
      pb={2}
      bottom="0"
      width="100%"
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        py={2.5}
        px={2.5}
        pb={0}
      >
        <Typography variant="h5">
          {messages.packageScanningForMultipleBarcodes.title}
        </Typography>
        <Box mt={1} mb={4}>
          <Typography variant="subtitle2" color="textSecondary">
            {messages.packageScanningForMultipleBarcodes.text}
          </Typography>
        </Box>
      </Box>
      <Box>
        {barcodesContent.map(({ content }) => (
          <BarcodeItem content={content} key={content} />
        ))}
      </Box>
    </Box>
  );
}
PackageScanningForMultipleBarcodes.propTypes = {
  barcodesContent: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      format: PropTypes.string
    })
  ).isRequired
};
