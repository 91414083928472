import { isValidISODate } from '../utils';

/**
 * Parse ISO8601 Date in HH:mm (Hours minute) format
 *
 * @param {string} isoDate
 * @return {string} The time in format HH:mm
 */
const parseDateToHours = isoDate => {
  if (!isValidISODate(isoDate)) return isoDate;

  const date = new Date(isoDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const additionalZeroInHours = hours < 10 ? '0' : '';
  const additionalZeroInMinutes = minutes < 10 ? '0' : '';

  return `${additionalZeroInHours}${hours}:${additionalZeroInMinutes}${minutes}`;
};

export default parseDateToHours;
