import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { Typography, Box, IconButton, Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';

import { colors } from '@loggi/mar';

import SharedPropTypes from 'view/shared-prop-types';
import { playSuccessBeep, playErrorBeep } from 'view/sounds';
import getPosition from 'operations/geolocation';
import { trackEnd, eventNames } from 'operations/elapsed-time';
import deliveryEvidenceDB from 'operations/update-status/delivery-evidence-db';
import pickupEvidenceDb from 'operations/pickup-evidence/pickup-evidence-db';
import { updateStatus } from 'operations/update-status/update-status';
import { savePickupEvidence } from 'operations/pickup-evidence/save-pickup-evidence';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { facadePhotoTypes, messages } from './constants';

import PhotoTemplate from './photo-template';
import { getTitle } from './utils';

export default function TakeAPhoto({
  facadePhotoContext,
  onGoBack,
  nextStep,
  defaultImageData,
  onUpdateStatus = updateStatus,
  onGetPosition = getPosition,
  type
}) {
  const [imageData, setImageData] = useState(defaultImageData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const changeStatusDeliveredPackageForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageByCompanyRelation
    )
  );

  const changeStatusDeliveredPackageForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageForDriverLastMileCompany
    )
  );

  const changeStatusDeliveredPackages =
    changeStatusDeliveredPackageForCompanyRelation &&
    changeStatusDeliveredPackageForDriverLMC;

  async function successHandler() {
    playSuccessBeep();
    setLoading(false);
    nextStep();
  }

  function errorHandler(err) {
    setLoading(false);
    setError(err);
    playErrorBeep();
  }

  async function updateStatusPackage() {
    const { latitude, longitude } = await onGetPosition();

    return onUpdateStatus({
      updateStatusData: {
        ...facadePhotoContext.pkg,
        status: facadePhotoContext.status,
        receiverName: facadePhotoContext.name,
        receiverDocument: facadePhotoContext.document,
        receiverDocumentType: facadePhotoContext.documentType,
        driverNotes: facadePhotoContext.notes,
        recipientRelationship: facadePhotoContext.recipientRelationship,
        safeDeliveryTokenAttempts: facadePhotoContext.tokenAttempts,
        deliveryLocationCode: facadePhotoContext.deliveryOption?.id,
        digitalSignatureSignedUrl: null,
        latitude,
        longitude,
        updateStatusTime: new Date().toISOString()
      },
      markDeliveredPkg: changeStatusDeliveredPackages
    });
  }

  function getPhoto() {
    return Buffer.from(
      imageData.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
  }

  function buildDeliveryEvidence() {
    const photoDecoded = getPhoto();

    return {
      packageId: facadePhotoContext.pkg.packageId,
      deliveryEvidence: {
        image: photoDecoded,
        url: facadePhotoContext.pkg.deliveryEvidenceSignedUrl.signedUrl,
        contentType:
          facadePhotoContext.pkg.deliveryEvidenceSignedUrl
            .signedUrlContentTypeHeader
      }
    };
  }

  function buildPickupEvidence() {
    return {
      pickupId: facadePhotoContext.pickupId,
      problemSlug: facadePhotoContext.problemSlug,
      image: getPhoto(),
      url: facadePhotoContext.pickupProblemEvidenceSignedUrl?.signedUrl,
      contentType:
        facadePhotoContext.pickupProblemEvidenceSignedUrl
          ?.signedUrlContentTypeHeader
    };
  }

  function sendPickupPhotoToAWS() {
    pickupEvidenceDb
      .putPickupEvidence(buildPickupEvidence())
      .then(() => savePickupEvidence(facadePhotoContext.pickupId))
      .catch(err => {
        Sentry.captureException(err);
      });
    successHandler();
  }

  function handleSendClick() {
    setError('');
    setLoading(true);

    if (type === facadePhotoTypes.pickupFacadePhoto) {
      sendPickupPhotoToAWS();
    } else {
      const promise = deliveryEvidenceDB.putDeliveryEvidence(
        buildDeliveryEvidence()
      );

      // End the tracking and send to Firebase.
      trackEnd(eventNames.facadePhoto, facadePhotoContext.pkg.packageId);

      promise
        .then(() => updateStatusPackage())
        .then(() => successHandler())
        .catch(err => {
          errorHandler(err.message);
        });
    }
  }

  return (
    <>
      <Box pt={0.5} display="flex" flexDirection="column" height="100%">
        <Box py={2} px={0.5}>
          <IconButton onClick={onGoBack} data-testid="document-back-button">
            <ArrowBack style={{ color: colors.blue[500] }} />
          </IconButton>
        </Box>

        <Box p={2.5} color={colors.smoke[900]}>
          <Typography variant="h5">{getTitle(type)}</Typography>

          <Box mt={1.5}>
            <Box mt={1.5} data-testid="box-photo-template-testid">
              <PhotoTemplate
                imageData={imageData}
                setImageData={setImageData}
              />
            </Box>

            {imageData != null && (
              <Box>
                {loading ? (
                  <Grid container justify="center">
                    <Box p={2}>
                      <CircularProgress justify="center" />
                    </Box>
                  </Grid>
                ) : (
                  <Box
                    px={2}
                    my={1.5}
                    display="flex"
                    textAlign="center"
                    justifyContent="space-between"
                  >
                    <Box width={1 / 2} mx={0.2} pt={1}>
                      <Button
                        p={3}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        id="anotherPhoto"
                        data-testid="anotherPhoto-test"
                        onClick={() => setImageData(null)}
                      >
                        {messages.takeAnotherPhoto}
                      </Button>
                    </Box>
                    <Box width={1 / 2} mx={0.2} pt={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        id="sendPhoto"
                        data-testid="sendPhoto-test"
                        color="primary"
                        onClick={handleSendClick}
                      >
                        {messages.sendPhoto}
                      </Button>
                    </Box>
                  </Box>
                )}
                {!loading && error && (
                  <>
                    <Box
                      py={1}
                      className="centered"
                      data-testid="errorAlert-test"
                    >
                      <Alert severity="error">{error}</Alert>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

TakeAPhoto.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  facadePhotoContext: SharedPropTypes.facadePhotoContext,
  defaultImageData: PropTypes.string,
  onUpdateStatus: PropTypes.func,
  onGetPosition: PropTypes.func,
  type: PropTypes.oneOf(Object.values(facadePhotoTypes))
};

TakeAPhoto.defaultProps = {
  facadePhotoContext: null,
  defaultImageData: null,
  onGetPosition: getPosition,
  onUpdateStatus: updateStatus,
  type: facadePhotoTypes.deliveryFacadePhotoDelivery
};
