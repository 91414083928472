import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';

import { Box, IconButton, Button, TextField, Grid } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { messages } from './constants';

export default function UserFilledSuboption({ onConfirmNewOption }) {
  const newSuboptionStates = {
    showNewOptionButton: 'showNewOptionButton',
    showInputAndConfirmButton: 'showInputAndConfirmButton',
    showEditOptionButton: 'showEditOptionButton',
    showNewOption: 'showNewOption'
  };

  const [newOption, setNewOption] = useState('');
  const [newSuboptionState, setNewSuboptionState] = useState(
    newSuboptionStates.showNewOptionButton
  );

  const isNewOptionEmpty = newOption.trim().length === 0;

  const showNewOptionButton = () =>
    isNewOptionEmpty &&
    setNewSuboptionState(newSuboptionStates.showNewOptionButton);

  const showInputAndConfirmButton = () => {
    setNewSuboptionState(newSuboptionStates.showInputAndConfirmButton);
  };

  const addNewOption = () =>
    !isNewOptionEmpty &&
    onConfirmNewOption(newOption, showInputAndConfirmButton);

  const onConfirmOption = () => {
    addNewOption();
    setNewSuboptionState(newSuboptionStates.showNewOption);
    showNewOptionButton();
  };

  const NewOptionButton = () => (
    <Box px={0.5}>
      <Button
        data-testid="new-added-option"
        color="primary"
        onClick={() => {
          setNewSuboptionState(newSuboptionStates.showInputAndConfirmButton);
        }}
      >
        {messages.addNewOption}
      </Button>
    </Box>
  );

  const InputAndConfirmButton = () => (
    <Box px={3} pt={3}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <TextField
            autoFocus
            size="large"
            fullWidth
            color="primary"
            data-testid="add-new-option"
            label={messages.addNewOptionLabel}
            variant="outlined"
            value={newOption}
            onChange={event => setNewOption(event.target.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <IconButton
            color="primary"
            style={{
              height: '56px',
              width: '56px',
              color: colors.root[0],
              backgroundColor: colors.blue[500]
            }}
            data-testid="recipient-confirm-button"
            onClick={() => onConfirmOption()}
          >
            <Check />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

  const canShowInsertOptionButton =
    newSuboptionState === newSuboptionStates.showNewOptionButton;
  const canShowInputAndConfirmButton =
    newSuboptionState === newSuboptionStates.showInputAndConfirmButton;

  return (
    <Box>
      {canShowInsertOptionButton && <NewOptionButton />}
      {canShowInputAndConfirmButton && <InputAndConfirmButton />}
    </Box>
  );
}

UserFilledSuboption.propTypes = {
  onConfirmNewOption: PropTypes.func.isRequired
};
