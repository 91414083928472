import { Machine, assign } from 'xstate';
import { LABELS } from './constants';
import formatCategories from './utils';

export const STATES = {
  returnToPickup: 'returnToPickup',
  loadingProblems: 'loadingProblems',
  categorySelection: 'categorySelection',
  problemSelection: 'problemSelection',
  problemConfirmation: 'problemConfirmation',
  confirmingProblem: 'confirmingProblem',
  facadePhoto: 'facadePhoto',
  redirect: 'redirect'
};

export const TRANSITIONS = {
  back: 'back',
  selectCategory: 'selectCategory',
  selectProblem: 'selectProblem',
  confirmProblem: 'confirmProblem'
};

export default Machine(
  {
    id: 'pickup-problems',
    initial: STATES.loadingProblems,
    context: {
      title: undefined,
      subtitle: undefined,
      problems: undefined,
      categories: undefined,
      selectedCategory: undefined,
      selectedProblem: undefined,
      reason: undefined
    },
    states: {
      [STATES.loadingProblems]: {
        invoke: {
          src: 'loadProblems',
          onDone: {
            target: STATES.categorySelection,
            actions: ['formatProblems']
          },
          onError: {
            target: STATES.returnToPickup,
            actions: ['notifyError']
          }
        },
        entry: assign({
          title: LABELS.categorySelection.title,
          subtitle: LABELS.categorySelection.subtitle
        }),
        on: {
          [TRANSITIONS.back]: STATES.returnToPickup
        }
      },
      [STATES.categorySelection]: {
        entry: assign({
          title: LABELS.categorySelection.title,
          subtitle: LABELS.categorySelection.subtitle
        }),
        on: {
          [TRANSITIONS.selectCategory]: {
            target: STATES.problemSelection,
            actions: assign({
              selectedCategory: (_, event) => event.selectedCategory
            })
          },
          [TRANSITIONS.back]: STATES.returnToPickup
        }
      },
      [STATES.problemSelection]: {
        entry: assign({
          title: context => context.selectedCategory.title,
          subtitle: context =>
            LABELS.problemSelection[context.selectedCategory.slug].subtitle
        }),
        on: {
          [TRANSITIONS.back]: STATES.categorySelection,
          [TRANSITIONS.selectProblem]: {
            target: STATES.problemConfirmation,
            actions: assign({
              selectedProblem: (_, event) => event.selectedProblem
            })
          }
        }
      },
      [STATES.problemConfirmation]: {
        entry: assign({
          title: context => context.selectedProblem.description,
          subtitle: context =>
            LABELS.problemConfirmation[context.selectedCategory.slug]
              ?.subtitle || LABELS.problemConfirmation.defaultSubtitle
        }),
        on: {
          [TRANSITIONS.back]: STATES.problemSelection,
          [TRANSITIONS.confirmProblem]: {
            target: STATES.confirmingProblem,
            actions: assign({
              reason: (_, event) => event.reason
            })
          }
        }
      },
      [STATES.confirmingProblem]: {
        invoke: {
          src: 'confirmProblem',
          onDone: [
            {
              target: STATES.facadePhoto,
              cond: 'requireFacadePhoto'
            },
            { target: STATES.redirect }
          ],
          onError: {
            target: STATES.problemConfirmation,
            actions: ['notifyError']
          }
        }
      },
      [STATES.facadePhoto]: {
        on: {
          [TRANSITIONS.back]: STATES.problemConfirmation
        }
      },
      [STATES.redirect]: {
        entry: ['notifyProblemConfirmationSuccess'],
        type: 'final'
      },

      [STATES.returnToPickup]: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      formatProblems: assign({
        problems: (_, event) => event.data,
        categories: (_, event) => formatCategories(event.data)
      })
    }
  }
);
