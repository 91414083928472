import React from 'react';

import { colors } from '@loggi/mar';
import { Box, Typography, Chip } from '@material-ui/core';
import calendarIcon from './calendar.svg';

const CapacityReserveListButton = () => (
  <Box
    borderRadius={8}
    border={1}
    borderColor={colors.smoke[200]}
    py={2}
    px={3}
    mt={2}
    data-testid="capacity-reserve-list-button"
  >
    <Box
      pb={1}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <img src={calendarIcon} alt="Calendário" style={{ marginRight: '8px' }} />
      <Chip label="Novidade!" variant="outlined" size="small" />
    </Box>

    <Box>
      <Typography
        variant="subtitle1"
        style={{ color: colors.bassis[700], fontWeight: '700' }}
        data-testid="capacity-reserve-list-button-blue-subtitle"
      >
        Ver ofertas programadas
      </Typography>
      <Typography
        variant="subtitle1"
        data-testid="capacity-reserve-list-button-subtitle"
      >
        para planejar sua rotina.
      </Typography>
    </Box>
  </Box>
);

export default React.memo(CapacityReserveListButton);
