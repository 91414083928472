import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';

import { PAGES } from 'view';

import { ReactComponent as PendingSyncPackage } from 'view/atoms/illustrations/sync-pending-package/sync-pending-package.svg';
import { colors } from '@loggi/mar';

import { useHistory } from 'react-router-dom';

export const SYNC_EXPLANATION =
  'Para finalizar as entregas e ver o valor do repasse, é preciso sincronizar os pacotes da sua lista. Verifique sua conexão e toque no botão azul ao lado do endereço.';
export const SINGLE_PACKAGE_PENDING = 'pacote com falha de sincronia.';
export const MANY_PACKAGES_PENDING = 'pacotes com falha de sincronia.';
export const OK_GOT_IT = 'Ok, entendi';

export default function PendingSyncTemplate({ pendingSyncPackages }) {
  const history = useHistory();

  const pendingPackagesText = `${pendingSyncPackages} ${
    pendingSyncPackages > 1 ? MANY_PACKAGES_PENDING : SINGLE_PACKAGE_PENDING
  }`;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        p={2.5}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <PendingSyncPackage />
          <Box px={1} pb={1}>
            <Typography variant="h5">
              Você tem
              <Box component="span" ml={1} color={colors.blue[500]}>
                {pendingPackagesText}
              </Box>
            </Typography>
          </Box>
          <Box color={colors.smoke[500]}>
            <Typography variant="body1">{SYNC_EXPLANATION}</Typography>
          </Box>
        </Box>

        <Box pt={2.5} width="100%">
          <Button
            fullWidth
            size="large"
            onClick={() => history.push(PAGES.PACKAGE_LIST)}
            variant="contained"
            color="primary"
          >
            {OK_GOT_IT}
          </Button>
        </Box>
      </Box>
    </>
  );
}

PendingSyncTemplate.propTypes = {
  pendingSyncPackages: PropTypes.number.isRequired
};
