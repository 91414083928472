import React from 'react';
import { createPortal } from 'react-dom';
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Box } from '@material-ui/core';
import {
  getDriverPosition,
  getAssignmentCoordinates
} from 'view/pages/assignment-list/google-maps/utils';
import { colors } from '@loggi/mar';
import styleMinimalist from '@loggi/google-maps';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { ReactComponent as EllipseCurrentLocation } from 'view/pages/assignment-list/google-maps/icons/driver-current-location-ellipsis.svg';
import NumeratedPackage from 'view/pages/assignment-list/google-maps/icons/point-white.svg';
import NumeratedPackageSelected from 'view/pages/assignment-list/google-maps/icons/point-blue.svg';
import NumeratedPackageNewTheme from 'view/pages/assignment-list/google-maps/icons/point-white-new-theme.svg';
import NumeratedPackageSelectedNewTheme from 'view/pages/assignment-list/google-maps/icons/point-blue-new-theme.svg';
import PinSelected from 'view/pages/assignment-list/google-maps/icons/pin-selected.svg';
import Pin from 'view/pages/assignment-list/google-maps/icons/pin.svg';
import PinSelectedNewTheme from 'view/pages/assignment-list/google-maps/icons/pin-selected-new-theme.svg';
import PinNewTheme from 'view/pages/assignment-list/google-maps/icons/pin-new-theme.svg';
import getDriverType, { DRIVER_TYPE } from 'operations/feature-switches/driver';
import PropTypes from 'prop-types';

let map;
let maps;
let markers = [];
let previousSelectedMarker = null;
let currentSelectedMarker = null;
const blueColor = colors.blue[500];
const blueColorNewTheme = '#006AFF';
const whiteColor = colors.root[0];
const loggiTowerCoords = {
  lat: -23.55743,
  lng: -46.662891
};

const DriverPositionMarker = () => (
  <Box>
    <EllipseCurrentLocation />
  </Box>
);

const getMarkerPropsWithDisplayId = (markerDisplayId, useNewTheme) => {
  return {
    icon: useNewTheme ? NumeratedPackageNewTheme : NumeratedPackage,
    iconSelected: useNewTheme
      ? NumeratedPackageSelectedNewTheme
      : NumeratedPackageSelected,
    label: {
      text: `${markerDisplayId}`,
      color: useNewTheme ? blueColorNewTheme : blueColor,
      fontWeight: 'bold'
    }
  };
};

const getMarkerPropsWithoutDisplayId = useNewTheme => {
  return useNewTheme
    ? {
        icon: PinNewTheme,
        iconSelected: PinSelectedNewTheme
      }
    : {
        icon: Pin,
        iconSelected: PinSelected
      };
};

const getLocation = () => {
  const driverPosition = getDriverPosition();
  const hasDriverPosition = !!driverPosition.lat && !!driverPosition.lng;
  if (hasDriverPosition) {
    return driverPosition;
  }

  const firstMarkerPosition = markers[0]?.position;
  const hasMarkerPosition = !!firstMarkerPosition;
  if (hasMarkerPosition) {
    return firstMarkerPosition;
  }

  return loggiTowerCoords;
};

export const setCenterButtonControl = defaultZoom => {
  const centerControlDiv = document.createElement('div');
  centerControlDiv.setAttribute('id', 'center-control-div');
  // the svg is the button's content that recenters the map on the driver's position. The innerHTML takes a svg string to serialize the HTML content.
  centerControlDiv.innerHTML =
    "<svg width='64' height='64' viewBox='0 0 72 58' fill='none'> <g filter='url(#filter0_d_7984_12798)'> <rect x='4' width='56' height='56' rx='28' fill='white' shape-rendering='crispEdges'/>  <path fill-rule='evenodd' clip-rule='evenodd' d='M21 29H23.0549C23.5161 33.1716 26.8284 36.4839 31 36.9451V39H33V36.9451C37.1716 36.4839 40.4839 33.1716 40.9451 29H43V27H40.9451C40.4839 22.8284 37.1716 19.5161 33 19.0549V17H31V19.0549C26.8284 19.5161 23.5161 22.8284 23.0549 27H21V29ZM25 28C25 24.134 28.134 21 32 21C35.866 21 39 24.134 39 28C39 31.866 35.866 35 32 35C28.134 35 25 31.866 25 28ZM32 24C34.2091 24 36 25.7909 36 28C36 30.2091 34.2091 32 32 32C29.7909 32 28 30.2091 28 28C28 25.7909 29.7909 24 32 24ZM30 28C30 26.8954 30.8954 26 32 26C33.1046 26 34 26.8954 34 28C34 29.1046 33.1046 30 32 30C30.8954 30 30 29.1046 30 28Z' fill='black'/> </g> <defs> <filter id='filter0_d_7984_12798' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'> <feFlood flood-opacity='0' result='BackgroundImageFix'/> <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/> <feOffset dy='4'/> <feGaussianBlur stdDeviation='2'/> <feComposite in2='hardAlpha' operator='out'/> <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/> <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7984_12798'/> <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_7984_12798' result='shape'/> </filter> </defs> </svg>";

  centerControlDiv.addEventListener('click', () => {
    const mapCenterPosition = getLocation();
    map.setCenter(mapCenterPosition);
    map.setZoom(defaultZoom);
  });

  map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
};

export function onLoadGoogleMaps(mapInstance, mapsInstance, defaultZoom) {
  map = mapInstance;
  maps = mapsInstance;
  setCenterButtonControl(defaultZoom);
}

const setMarkerStyle = (marker, icon, color) => {
  marker.setIcon(icon);
  if (marker.showDisplayId) {
    const label = marker.getLabel();
    label.color = color;
    marker.setLabel(label);
  }
};

const restoreDefaultMarkerColor = selectedMarker => {
  const markerProps = selectedMarker?.showDisplayId
    ? getMarkerPropsWithDisplayId(
        selectedMarker?.displayId,
        selectedMarker?.useNewTheme
      )
    : getMarkerPropsWithoutDisplayId(selectedMarker.useNewTheme);
  setMarkerStyle(
    selectedMarker,
    markerProps.icon,
    selectedMarker?.useNewTheme ? blueColorNewTheme : blueColor
  );
};

export const changeMarkerColor = (marker, selectedMarker) => {
  if (selectedMarker) restoreDefaultMarkerColor(selectedMarker);
  const markerProps = marker?.showDisplayId
    ? getMarkerPropsWithDisplayId('', marker?.useNewTheme)
    : getMarkerPropsWithoutDisplayId(marker.useNewTheme);
  setMarkerStyle(marker, markerProps.iconSelected, whiteColor);
};

const setMarkerOnClickListener = (marker, assignmentListRef, index) => {
  marker.addListener('click', () => {
    previousSelectedMarker = currentSelectedMarker;
    changeMarkerColor(marker, previousSelectedMarker);
    currentSelectedMarker = marker;
    assignmentListRef.current.scrollToIndex({
      index
    });
  });
};

const clearAllMarkers = () => {
  markers.forEach(marker => marker.setMap(null));
  markers = [];
};

function AddMarkerClusterer() {
  const enableMarkerClustererGoogleMapsFS = useFeatureSwitch(
    featureSwitches.enableMarkerClustererGoogleMapsByLmc
  );
  const isEnableMarkerClusterForLMC = featureSwitchEnabledForDriverLMC(
    enableMarkerClustererGoogleMapsFS
  );
  if (isEnableMarkerClusterForLMC) return new MarkerClusterer({ markers, map });
}

function SetBoundsAndRecenter(addedMarkers) {
  const isZoomWithBoundsEnabled = useFeatureSwitch(
    featureSwitches.enableZoomWithBoundsInGoogleMaps
  );

  if (isZoomWithBoundsEnabled && addedMarkers.length > 0) {
    const bounds = new maps.LatLngBounds();

    const driverPosition = getDriverPosition();
    const hasDriverPosition = !!driverPosition.lat && !!driverPosition.lng;
    if (hasDriverPosition) {
      bounds.extend(new maps.LatLng(driverPosition.lat, driverPosition.lng));
    }
    addedMarkers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    map.setZoom(map.getZoom());
  }
}

export function SetMarkersOnGoogleMaps(allAssignments, assignmentListRef) {
  clearAllMarkers();

  const enableNewAssignmentList = useFeatureSwitch(
    featureSwitches.enableNewAssignmentList
  );
  const enableNewAssignmentListByDriverRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewAssignmentList
  );
  const useAssignmentIndexOnMap = useFeatureSwitch(
    featureSwitches.useAssignmentIndexOnMap
  );

  if (!map || !maps) return;

  const addedMarkers = [];
  allAssignments.map(
    ({ assignmentDisplayId, displayId, assignmentIndex }, index) => {
      const markerDisplayId = useAssignmentIndexOnMap
        ? assignmentIndex
        : assignmentDisplayId || displayId;
      const markerProps = markerDisplayId
        ? getMarkerPropsWithDisplayId(
            markerDisplayId,
            enableNewAssignmentListByDriverRelation
          )
        : getMarkerPropsWithoutDisplayId(
            enableNewAssignmentListByDriverRelation
          );
      const marker = new maps.Marker({
        position: getAssignmentCoordinates(allAssignments[index], maps),
        map,
        label: markerProps.label,
        icon: markerProps.icon,
        displayId: useAssignmentIndexOnMap ? markerDisplayId : index,
        showDisplayId: !!markerDisplayId,
        useNewTheme: enableNewAssignmentListByDriverRelation
      });

      setMarkerOnClickListener(marker, assignmentListRef, index);
      markers.push(marker);
      addedMarkers.push(marker);

      return markers;
    }
  );

  /**
   * Recenter zoom according to markers
   * We need to use the addedMarkers array because the markers array isn't updated yet
   * by the time the SetBoundsAndRecenter function is called.
   */
  SetBoundsAndRecenter(addedMarkers);

  // Add a marker clusterer to manage the markers.
  AddMarkerClusterer();
}

// prettier-ignore
export function Map({ defaultZoom }) { // NOSONAR
  const mapCenterPosition = getLocation();
  return (
    <Box
      style={{ height: '40vh', width: '100%' }}
      data-testid="google-map-assignment-list"
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_DYNAMIC_MAP_API_KEY
        }}
        defaultCenter={{
          lat: mapCenterPosition.lat,
          lng: mapCenterPosition.lng
        }}
        defaultZoom={defaultZoom}
        options={{
          styles: styleMinimalist,
          zoomControl: false,
          fullscreenControl: true
        }}
        onGoogleApiLoaded={({ map: mapInstance, maps: mapsInstance }) =>
          onLoadGoogleMaps(mapInstance, mapsInstance, defaultZoom)
        }
        yesIWantToUseGoogleMapApiInternals
      >
        {/* to add a google marker, we just need to add lat and lng props to any child of GoogleMapReact component */}
        <DriverPositionMarker
          lat={mapCenterPosition.lat}
          lng={mapCenterPosition.lng}
        />
      </GoogleMapReact>
    </Box>
  );
}
Map.propTypes = {
  defaultZoom: PropTypes.number.isRequired
};

export default function GoogleMaps() {
  const defaultZoom = useFeatureSwitch(featureSwitches.defaultZoomByFs);

  const isGoogleMapsOnAssignmentListEnabled = useFeatureSwitch(
    featureSwitches.googleMapsOnAssignmentList
  );
  const disableToLeveGoogleMapsOnAssignmentList = useFeatureSwitch(
    featureSwitches.disableToLeveGoogleMapsOnAssignmentList
  );

  const driverLeve = DRIVER_TYPE.LEVE === getDriverType();

  const disableToLeve = driverLeve && disableToLeveGoogleMapsOnAssignmentList;

  if (!isGoogleMapsOnAssignmentListEnabled || disableToLeve) return <></>;

  const mapNode = document.getElementById('map-container');

  const defaultZoomValue = Number(defaultZoom) > 0 ? Number(defaultZoom) : 7;
  return createPortal(<Map defaultZoom={defaultZoomValue} />, mapNode);
}
