import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PAGES } from 'view';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as MapIcon } from './icon_map.svg';

export const messages = {
  routeCanceled: 'A sua rota foi cancelada.',
  explanationOfTheCanceledRoute: allowedDelay =>
    `Você passou do horário limite de ${allowedDelay} minutos para` +
    ` chegar no ponto de coleta. A rota é cancelada se passar do prazo.`,
  important: 'Importante!',
  timeoutWarning:
    `Só aceite ofertas se puder chegar dentro do tempo limite. ` +
    `O prazo é o suficiente para ir com calma. 😄`,
  backToHomeScreen: 'Voltar para a tela de início'
};

export function RouteCanceled({ backToHomeScreen, allowedDelay }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      minHeight="100%"
      px={2.5}
    >
      <Box display="flex" justifyContent="center" pt={5}>
        <MapIcon />
      </Box>
      <Box pt={5} pb={2.5} textAlign="center">
        <Typography variant="h6">{messages.routeCanceled}</Typography>
        <Box pt={1.25}>
          <Typography variant="subtitle2" color="textSecondary">
            {messages.explanationOfTheCanceledRoute(allowedDelay)}
          </Typography>
        </Box>
      </Box>
      <Box py={2.5}>
        <Alert severity="info" color="info">
          <AlertTitle>{messages.important}</AlertTitle>
          {messages.timeoutWarning}
        </Alert>
      </Box>
      <Box py={5} pb={2.5}>
        <Box pb={2.5}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="small"
            onClick={backToHomeScreen}
          >
            {messages.backToHomeScreen}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

RouteCanceled.propTypes = {
  backToHomeScreen: PropTypes.func.isRequired,
  allowedDelay: PropTypes.number.isRequired
};

export default function RouteCanceledPage() {
  const history = useHistory();
  const { state } = useLocation();
  const goToHomeScreen = () =>
    state.withoutItinerary
      ? history.push(PAGES.PACKAGE_LIST)
      : history.push(PAGES.FINISH_DELIVERING);

  return (
    <RouteCanceled
      backToHomeScreen={goToHomeScreen}
      allowedDelay={state.allowedDelay}
    />
  );
}
