import React from 'react';
import { Typography } from '@material-ui/core';
import { pickupFlowEnum } from './constants';

const messages = {
  pickupDetails: {
    title: 'Coletar em',
    shipperLabel: 'Procurar por',
    buttonProblems: 'Desalocar',
    buttonStartPickup: 'Começar coleta',
    buttonNavigation: 'Como chegar',
    collectUntil: 'Chegar até',
    textIn: 'em',
    nowUntil: 'Agora, até',
    actionText: {
      toDo: 'Coletar'
    }
  },
  pickupProblems: {
    title: 'Problemas na coleta',
    buttonErrorConfirm: 'Ok, entendi'
  },
  pickupProblemDetails: {
    title: 'Eita! Algo deu errado.',
    subtitle:
      'Tudo bem. Nesse caso você pode informar que não vai mais fazer a coleta.',
    subtitleProblemComplement:
      'Tudo bem. Nesse caso você deve informar porque não vai mais fazer a coleta.',
    infoBoxTitle: 'É bom lembrar!',
    infoBoxSubtitle:
      'Ao aceitar fazer uma entrega, você é responsável por ela. Insista, a não ser que aconteça um imprevisto.',
    buttonCancelPickup: 'Desalocar da coleta',
    buttonErrorConfirm: 'Ok, entendi',
    genericError:
      'Eita! Algo deu errado. Você pode tentar de novo daqui a pouco.',
    drawerConfirmPickupCancel: {
      title: 'Tem certeza que quer desalocar?',
      description:
        'Ao aceitar fazer uma coleta, você é responsável por ela. Insista, a não ser que aconteça um imprevisto.',
      buttonConfirm: 'Quero desalocar',
      buttonDismiss: 'Vou coletar'
    },
    labelProblemComplement: 'Descreva aqui...'
  },
  pickupCode: {
    [pickupFlowEnum.useBarcodeBip]: {
      title: 'Bipe todos os pacotes que couberem no seu veículo',
      subtitle:
        'Identifique-se com o cliente para ter acesso aos pacotes e começar',
      pickupCodeLabel: 'Número da coleta',
      onContinueButtonLabel: 'Continuar',
      onContinueButtonLoadingLabel: ''
    },
    [pickupFlowEnum.useXDAppAgencyTransfer]: {
      title: 'Identifique-se na agência e informe o número da saca',
      subtitle:
        'Assim você pode ter acesso aos pacotes. Confira se está tudo certo antes de sair para entregar.',
      pickupCodeLabel: 'Número da saca',
      onContinueButtonLabel: 'Ver lista de pacotes',
      onContinueButtonLoadingLabel: 'Aguarde...'
    },
    [pickupFlowEnum.automaticFill]: {
      title: (
        <Typography variant="h5" paragraph>
          O item está sem etiqueta? Tudo bem, <b>pode coletar assim mesmo.</b>
        </Typography>
      ),
      subtitle: (
        <Typography variant="subtitle2" paragraph>
          Apenas <b>nesta coleta</b>, você pode pegar o item como ele estiver. A
          base da Loggi vai cuidar de tudo.
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2" paragraph>
          Pode continuar! Na próxima tela, toque em <b>Conferir pacotes</b> e
          siga com a coleta.
        </Typography>
      ),
      onContinueButtonLabel: 'Continuar'
    }
  },
  pickupPackages: {
    getTitle: packagesLength => {
      return packagesLength === 1
        ? `Sua lista tem ${packagesLength} pacote`
        : `Sua lista tem ${packagesLength} pacotes`;
    },
    getSubtitle: packagesLength => {
      return packagesLength > 1
        ? 'Confira os pacotes e confirme para começar a fazer as entregas'
        : 'Confira o pacote e confirme para começar a fazer as entregas';
    },
    companyFormatter: company => {
      return `Pacote de ${company}`;
    },
    alertSuccessLoadingPackages: 'Tudo certo! Lista de pacotes atualizada',
    buttonConfirmPickup: 'Tudo certo, vou levar',
    buttonUpdate: 'Atualizar',
    buttonLoading: 'Aguarde...',
    buttonErrorConfirm: 'Ok, entendi',
    custodyNotTransferError:
      'Aguarde os pacotes serem conferidos. Identificou algum pacote que não quer levar? É só avisar ao time da agência.'
  },
  pickupCheckPackages: {
    getTitle: packagesLength =>
      packagesLength === 1
        ? 'Confira o item'
        : `Confira os ${packagesLength} itens`,
    subTitle:
      'Veja se os itens que você coletou estão corretos e confirme para fazer a entrega.',
    buttonRemovePackage: 'Remover item',
    buttonKeepList: 'Manter na lista',
    buttonConfirm: 'Tudo certo, vou levar',
    buttonAddMore: 'Continuar adicionando'
  },
  drawerPickupCheckConfirm: {
    title: 'Você adicionou todos os itens que vai levar?',
    description:
      'Os itens que não forem adicionados à sua lista não vão poder ser coletados nem recebidos nas bases.',
    buttonConfirm: 'Já adicionei todos',
    buttonDismiss: 'Continuar adicionando'
  },
  drawerPickupCheckError: {
    title: 'Opa! Parece que algum pacote da sua lista está com erro',
    description: 'Retire os itens com erro da sua lista para prosseguir.',
    buttonConfirm: 'Ok'
  },
  defaultErrors: {
    generic: 'Aconteceu algum erro para atualizar a lista',
    network: 'Você pode tentar novamente daqui a pouco'
  },
  recipientDocument: {
    title: 'Confirme a coleta',
    subtitle: 'Peça o nome e CPF de quem te atendeu.',
    buttonConfirm: 'Confirmar coleta'
  },
  fakeLoading: {
    mounting: 'Montando sua lista...',
    optimizing: 'Otimizando a rota...',
    finishing: 'Finalizando...'
  },
  pickupBipInform: {
    title: 'Adicione todos os pacotes que for coletar à sua lista',
    description: 'Os que não forem identificados não vão poder ser coletados.',
    nextButton: 'Próximo'
  },
  pickupScanningInstructions: {
    title: 'Veja as informações na etiqueta do pacote',
    description:
      'Adicione o pacote à lista fazendo a leitura do código QR ou de barras. Se a leitura falhar, você pode digitar o código da etiqueta.',
    startBipButton: 'Adicionar pacotes'
  },
  pickupScanner: {
    errorBarcodeAlreadyIncluded:
      'Opa! Este pacote já foi bipado e está na sua lista.',
    notificationPackageAdded: 'O pacote foi adicionado à lista.',
    notificationPackageRemoved:
      'Tudo certo! O pacote foi removido da sua lista.',
    networkErrorNotification:
      'Opa, parece que você está sem internet. Confira a sua conexão e tente outra vez.',
    genericErrorNotification: 'Houve algum erro. Tente outra vez.',
    packageNotFoundNotitification: 'Eita! Algo deu errado com os pacotes.'
  },
  agencyRemider: {
    title: 'A entrega de todos os pacotes é no mesmo lugar.',
    text:
      'É só seguir as instruções de entrega no aplicativo depois de coletar',
    startPickupButton: 'Beleza, criar lista',
    buttonProblems: 'Estou com dúvidas'
  },
  packageScanningForMultipleBarcodes: {
    title: 'Bipe outro código de barras do mesmo pacote',
    text:
      'Encontre outro código na etiqueta para incluir esse pacote na sua lista.',
    textPackage: 'Esse você já bipou',
    errorBarcodeAlreadyIncluded:
      'Opa! Bipe um segundo código do mesmo pacote para continuar'
  },
  packageNotIntegrated: {
    text:
      'Opa! Esse código não tem os dados do pacote. Tente buscar outros códigos de barra na etiqueta.',
    buttonReadOtherCode: 'Ler outros códigos',
    buttonTypeCode: 'Eita, leu errado. Vou digitar'
  },
  pickupSurveyDrawer: {
    title: 'Como foi sua coleta?',
    text:
      'Sua avaliação será utilizada para melhorar a experiência de todos os entregadores',
    showPickupSurveyButton: 'Avaliar',
    skipPickupSurveyButton: 'Pular'
  },
  loadingCompletePickup: {
    title: 'Tudo certo com a coleta dos pacotes!',
    text:
      'As novas atividades podem demorar alguns segundos para aparecer para você, aguarde e atualize a lista de atividades quando possível.',
    buttonCloseLoadingComplete: 'Ir para a Lista de atividades'
  }
};

export default messages;
