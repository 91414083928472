import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { colors } from '@loggi/mar';
import { Avatar, Box, Button, IconButton, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { getCurrentUser } from 'operations/auth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import { pickupFlowEnum } from './constants';
import allMessages from './messages';

export const drawerData = {
  title:
    'Aguarde os pacotes serem conferidos. Identificou algum pacote que não quer levar? É só avisar ao time da agência.',
  description: '',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <ErrorOutlineIcon />
};

export default function PickupCodePage({
  pickupFlow,
  pickupCode,
  onContinue,
  onCloseButtonClicked
}) {
  const [drawerState, setDrawerState] = useState(DRAWER_STATE.CLOSED);
  const {
    state: { authenticatedUser: cognitoUser }
  } = useAmplifyAuth();

  const username = getCurrentUser().fullName || cognitoUser?.name;
  const messages = allMessages.pickupCode[pickupFlow];
  const isShowOnlyLastDigitsSealBag = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.showOnlyLastDigitsSealBag)
  );

  const formattedPickupCode = () => {
    const matchedPickupFlow =
      pickupFlow === pickupFlowEnum.useXDAppAgencyTransfer;
    if (matchedPickupFlow && isShowOnlyLastDigitsSealBag) {
      return pickupCode.slice(-4);
    }
    return pickupCode;
  };

  return (
    <>
      <Box
        p={2.5}
        px={0.5}
        display="flex"
        flexDirection="column"
        minHeight="100%"
      >
        <Box>
          <IconButton
            onClick={onCloseButtonClicked}
            data-testid="close"
            color="inherit"
          >
            <ArrowBack color="primary" />
          </IconButton>
        </Box>
        <Box
          px={2.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
        >
          <Box flexGrow={1} overflow="auto">
            <Box my={2.5}>
              <Typography variant="h5" paragraph>
                {messages.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              bgcolor={colors.smoke[50]}
              borderRadius={8}
              justifyContent="center"
              py={4}
            >
              <Box mx={2.5} mb={2.5}>
                <Typography align="center" variant="body1">
                  {messages.subtitle}
                </Typography>
              </Box>
              <Box>
                <Avatar
                  src={getCurrentUser()?.driverProfilePictureUrl}
                  style={{ width: 100, height: 100 }}
                />
              </Box>
              <Box mt={2}>
                <Typography variant="h4">{username}</Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="body1">
                  {messages.pickupCodeLabel}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="h4">
                  <Box component="span" fontWeight="700">
                    {formattedPickupCode}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              size="large"
              fullWidth
              color="primary"
              variant="contained"
              onClick={onContinue}
            >
              {messages.onContinueButtonLabel}
            </Button>
          </Box>
        </Box>

        <NotificationDrawer
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          data={drawerState === DRAWER_STATE.CUSTOM ? drawerData : undefined}
        />
      </Box>
    </>
  );
}

PickupCodePage.propTypes = {
  pickupFlow: PropTypes.oneOf(Object.values(pickupFlowEnum)).isRequired,
  pickupCode: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired
};

PickupCodePage.url = '/codigo_identificacao';
