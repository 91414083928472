import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  FormControl,
  Chip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomRadioButtonGroup from 'view/molecules/radio-button-group';

export const filterValues = {
  default: 'default',
  identified: 'identified',
  toIdentify: 'to-identify',
  notPlanned: 'notPlanned'
};

export const filterValuesLabelChip = {
  [filterValues.default]: 'Todos',
  [filterValues.identified]: 'Itens identificados',
  [filterValues.toIdentify]: 'Itens não identificados',
  [filterValues.notPlanned]: 'Itens não previstos'
};

export const filterValuesLabel = {
  [filterValues.default]: totalItens => `Todos (${totalItens})`,
  [filterValues.identified]: itensIdentified =>
    `Itens identificados (${itensIdentified})`,
  [filterValues.toIdentify]: itensToIdentify =>
    `Itens não identificados (${itensToIdentify})`,
  [filterValues.notPlanned]: notPlanned => `Itens não previstos (${notPlanned})`
};

export const texts = {
  title: 'Filtrar itens na lista',
  subtitle: 'Escolha apenas uma opção',
  button: 'Confirmar'
};

/**
 * @param {Object} itensIdentified Quantity of itens already identified
 * @param {Object} itensToIdentify Quantity of itens to identify
 * @param {Object} itensNotPlanned Quantity of itens unforseen
 */
function FilterDrawer({
  disabled,
  defaultValue,
  itensIdentified,
  itensToIdentify,
  itensNotPlanned,
  onFilterConfirm
}) {
  const { colors } = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(
    defaultValue || filterValues.default
  );
  const [filteredValue, setFilteredValue] = useState(
    defaultValue || filterValues.default
  );

  const filterValuesAndQuantityMap = [
    {
      key: filterValues.default,
      label: filterValuesLabel[filterValues.default](
        itensIdentified + itensToIdentify
      )
    },
    {
      key: filterValues.identified,
      label: filterValuesLabel[filterValues.identified](itensIdentified)
    },
    {
      key: filterValues.toIdentify,
      label: filterValuesLabel[filterValues.toIdentify](itensToIdentify)
    },
    {
      key: filterValues.notPlanned,
      label: filterValuesLabel[filterValues.notPlanned](itensNotPlanned)
    }
  ];

  return (
    <>
      {filteredValue === filterValues.default && (
        <Chip
          disabled={disabled}
          data-testid="pickup-packages-list-chip-filter"
          style={{
            height: 40
          }}
          variant="outlined"
          label="Filtrar"
          onClick={() => setFilterOpen(true)}
          onDelete={() => setFilterOpen(true)}
          deleteIcon={
            <ExpandMoreIcon
              style={{ color: colors.neutrals.typeface.secondary }}
              data-testid="pickup-packages-list-chip-filter-icon"
            />
          }
        />
      )}
      {filteredValue !== filterValues.default && (
        <Chip
          disabled={disabled}
          data-testid="pickup-packages-list-chip-filtered"
          style={{
            height: 40,
            color: colors.primary.pure,
            backgroundColor: colors.primary.light
          }}
          label={filterValuesLabelChip[filterValue]}
          onDelete={() => {
            onFilterConfirm(filterValues.default);
            setFilteredValue(filterValues.default);
          }}
          deleteIcon={
            <HighlightOffIcon
              style={{ color: colors.primary.pure }}
              data-testid="pickup-packages-list-chip-filtered-delete"
            />
          }
        />
      )}
      <Drawer
        data-testid="pickup-packages-list-filter"
        open={filterOpen}
        anchor="bottom"
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16
          }
        }}
        p={1.5}
      >
        <Box pl={2.5} pr={1} pt={3} display="flex" justifyContent="flex-end">
          <IconButton
            onClick={() => setFilterOpen(false)}
            data-testid="pickup-packages-list-filter-close-button"
          >
            <CloseIcon style={{ color: colors.neutrals.typeface.primary }} />
          </IconButton>
        </Box>
        <Box px={2.5} py={1.5}>
          <Box mb={1.5}>
            <Typography
              variant="body1"
              color={colors.neutrals.typeface.primary}
              style={{ fontWeight: 600 }}
            >
              {texts.title}
            </Typography>
            <Typography
              variant="body2"
              color={colors.neutrals.typeface.secondary}
            >
              {texts.subtitle}
            </Typography>
          </Box>
        </Box>
        <Box px={4} pb={1.5}>
          <FormControl mt={1.5} fullWidth>
            <CustomRadioButtonGroup
              radioValues={filterValuesAndQuantityMap}
              ariaLabel="filter-itens"
              value={filterValue}
              onValueChanged={setFilterValue}
            />
          </FormControl>
        </Box>
        <Box px={2.5} py={1.5}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              onFilterConfirm(filterValue);
              setFilteredValue(filterValue);
              setFilterOpen(false);
            }}
          >
            {texts.button}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

FilterDrawer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  itensIdentified: PropTypes.number.isRequired,
  itensToIdentify: PropTypes.number.isRequired,
  itensNotPlanned: PropTypes.number.isRequired,
  onFilterConfirm: PropTypes.func.isRequired
};

export default React.memo(FilterDrawer);
