import { colors } from '@loggi/mar';

export default {
  0: {
    size: 'small',
    label: 'Não consegui ir ao local',
    style: {
      backgroundColor: colors.smoke[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#E6E6E6',
      color: '#1A1A1A'
    }
  },
  2: {
    size: 'small',
    label: 'Entregue',
    style: {
      backgroundColor: colors.green[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#4FC2A0',
      color: '#FFFFFF'
    }
  },
  11: {
    size: 'small',
    label: 'Se recusou a receber',
    style: {
      backgroundColor: colors.smoke[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#E6E6E6',
      color: '#1A1A1A'
    }
  },
  12: {
    size: 'small',
    label: 'Não encontrei ninguém',
    style: {
      backgroundColor: colors.smoke[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#E6E6E6',
      color: '#1A1A1A'
    }
  },
  13: {
    size: 'small',
    label: 'Não encontrei o endereço',
    style: {
      backgroundColor: colors.smoke[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#E6E6E6',
      color: '#1A1A1A'
    }
  },
  117: {
    size: 'small',
    label: 'Não consegui o código de confirmação',
    style: {
      backgroundColor: colors.smoke[500],
      color: colors.root[0]
    },
    /* new theme style */
    style2: {
      backgroundColor: '#E6E6E6',
      color: '#1A1A1A'
    }
  }
};

export const unsucessfulStatusCodes = [0, 11, 12, 13, 117];
