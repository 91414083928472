/**
 * DraftDB is a database where we keep all the package mutations made by the user.
 * "Package Mutation" here refers to any status change made by the user.
 *
 * DraftDB keeps its information on a table in the IndexedDB and all operations made
 * to this table are coordinated by a library called Dexie.
 *
 * Before triggering a request to the server with the update status data, that mutation
 * is registrered on DraftDB. This ensures that the information inputted by the user is
 * not lost between offline/online cycles and page refreshes.
 */

import Dexie from 'dexie';
import wrapOperationsInDexieErrorHandler from 'operations/handle-dexie-errors';
import { errorTypes } from 'operations/assignment-list/constants';
import {
  mutationStates,
  packageMutationMachine
} from 'models/package-mutation';
import {
  getStatusDescriptionByStatusCode,
  isFinalPackageStatusCode,
  statusCodes
} from './update-status-codes';
import {
  sortPackagesByIndexAndDisplayId,
  isPackageMutationSynced
} from './utils';

const MUTATION_INCONSISTENT_ERROR_MSG =
  'Mutation inconsistent: payload status is different';

/** @type {Dexie} */
const db = new Dexie('draft', {
  chromeTransactionDurability: 'relaxed'
});

db.version(1).stores({
  mutations: `
        packageId,
        state,
        created
      `
});

/** @param {PackageMutation.payload} payload
 *  @returns {Promise<number>} - primary key of the package mutation */
const putPackageMutation = async payload =>
  db.table('mutations').put({
    packageId: payload.packageId,
    state: packageMutationMachine.initialState.value,
    payload,
    created: Date.now()
  });

/** @returns {Promise<void>} */
const clearDB = async () => {
  await db.mutations.clear();
};

/** @param {number} id - package id
 *  @returns {Promise<Array<PackageMutation>>} */
const getPackageMutationByPackageId = async id => {
  const mutation = await db
    .table('mutations')
    .where({ packageId: id })
    .first();

  if (!mutation) return undefined;

  const { packageId, payload, state, created } = mutation;
  return { packageId, payload, state, created };
};

/** @returns {Promise<Array<PackageMutation>>} */
const getPackageMutations = async () => {
  const mutations = await db.table('mutations').toArray();

  return mutations.map(mutation => {
    if (!mutation) return undefined;
    const { packageId, payload, state, created } = mutation;
    return { packageId, payload, state, created };
  });
};

const getPendingPackageMutations = async () => {
  const packageMutations = await getPackageMutations();

  return packageMutations.filter(
    mutation =>
      mutation.state === mutationStates.FAILED_TO_SYNC ||
      mutation.state === mutationStates.SYNCING
  );
};

/** @param {number} packageId
 *  @returns {Promise<void>} */
const deletePackageMutation = async packageId =>
  db.table('mutations').delete(packageId);

/**
 *  This function is responsible for, given a package payload, verify, based on the payload status,
 *  if it is the related mutation, and update the mutation state accordingly to the passed action.
 *
 *  It is needed because if user requests for a new mutation update when there is an ongoing one,
 *  we could change mutation state wrongly
 *
 *  @param {PackageMutation.payload} payload
 *  @param {string} action
 *  @param {object} error
 *  @returns {Promise<void>} */
const updateMutationState = async ({ packageId, status }, action, error) => {
  const packageMutation = await getPackageMutationByPackageId(packageId);

  // If the packageMutation has been deleted, we don't need to update it
  if (packageMutation === undefined) return Promise.resolve({});

  if (packageMutation.payload.status !== status) {
    throw new Error(MUTATION_INCONSISTENT_ERROR_MSG);
  }

  const nextState = packageMutationMachine.transition(
    packageMutation.state,
    action
  );

  let errorData;

  if (error) {
    errorData = {
      message: error.message || error.response.message || error.response.raw,
      type: error.isFetchError
        ? errorTypes.networkError
        : errorTypes.genericError
    };
  }

  return db
    .table('mutations')
    .update(packageId, { state: nextState.value })
    .then(() => ({ nextState: nextState.value, error: errorData }));
};

/**
 * @param {Object} params
 * @param {Object} params.pkg - a package in the PackageList
 * @param {PackageMutation} params.mutation
 * @returns {Object} - pkg + mutation, with the mutation information always overwriting the package info
 *                                     and a flag indicating the current mutation sync state
 * @private
 */
const mergePackageWithMutation = ({ pkg, mutation, listDeliveredPackages }) => {
  if (!mutation) {
    return listDeliveredPackages
      ? {
          ...pkg,
          backendStatus: pkg.status
        }
      : pkg;
  }

  const mutationStatusCode = mutation.payload.status;

  const statusDescription = getStatusDescriptionByStatusCode({
    statusCode: mutationStatusCode
  });

  const packageWithMutation = {
    ...pkg,
    mutationSyncState: mutation.state,
    status: {
      code: mutationStatusCode,
      description: statusDescription
    }
  };

  return listDeliveredPackages
    ? {
        ...packageWithMutation,
        backendStatus: pkg.status
      }
    : packageWithMutation;
};

/**
 * Format a mutation of a package delivered, synced and with same
 * status saved in backend, to be shown on the package list
 * @param {*} mutation
 * @returns {Object} Mutation payload formatted to show on the package list
 */
const formatMutationsOfDeliveredAndSyncedPackages = mutation => {
  const mutationStatus = {
    code: mutation.payload.status,
    description: getStatusDescriptionByStatusCode({
      statusCode: mutation.payload.status
    })
  };
  return {
    ...mutation.payload,
    mutationSyncState: mutation.state,
    // If the mutation enters this method it means that
    // the package is delivered, synced and the status of
    // the mutation is equal to the package status saved in backend
    backendStatus: mutationStatus,
    status: mutationStatus
  };
};

/**
 * Based on the PackageList, delete some package mutations
 * The mutations are deleted if the correspondent package on the PackageList:
 *  - does not exist
 *  - in in a final status (i.e. delivered or refused)
 *  - has the same status as the mutation's (i.e. the mutation was synced)
 *
 * @modifies {DraftDB}
 *
 * @param {Object} params
 * @param {Object} params.packageList
 * @returns {Pomise<void>}
 */
const cleanMutationsBasedOnPackageList = async packageList => {
  const mutations = await getPackageMutations();

  const deletions = mutations.map(mutation => {
    const pkg = packageList.find(p => p.packageId === mutation.packageId);

    const pkgDoesNotExist = !pkg;
    const pkgIsInFinalStatus =
      pkg &&
      isFinalPackageStatusCode({
        statusCode: pkg.status.code
      });
    const pkgStatusIsEqualToTheMutation =
      pkg && mutation.payload.status === pkg.status.code;

    const pkgIsInFinalStatusAndSynced =
      pkgIsInFinalStatus && isPackageMutationSynced(mutation.state);

    /**
     * We do this check because we handle edge cases poorly in the update
     * status endpoint.
     * In this case, sometimes recipient unavailable doesn't change the
     * package's status (minimum distance guard for MEI drivers). But the
     * endpoint does return a success code.
     * Because of the fact that we only delete mutations based on the above
     * three conditions, this was causing drivers to go crazy about the
     * "sync again" message. As the endpoint was never changing the package's
     * status.
     * @see {https://loggi.slack.com/archives/C02EAHG2CTT/p1649097691958509}
     */
    const isSyncedRecipientUnavailable =
      pkg &&
      mutation.payload.status === statusCodes.RECIPIENT_UNAVAILABLE &&
      mutation.state === mutationStates.SYNCED;

    if (
      pkgDoesNotExist ||
      pkgIsInFinalStatusAndSynced ||
      pkgStatusIsEqualToTheMutation ||
      isSyncedRecipientUnavailable
    ) {
      // final status of a package, either delivered or refused
      return deletePackageMutation(mutation.packageId);
    }

    return Promise.resolve();
  });

  // await all deletions fo happen
  await Promise.all(deletions);
};

/**
 * Based on the mutations list, delete delivered packages mutations
 * @modifies {DraftDB}
 *
 * @returns {Pomise<void>}
 */
const clearMutationsOfDeliveredPackages = async () => {
  const mutations = await getPackageMutations();

  const deletions = mutations.map(mutation => {
    const pkgAlreadyDeliveredAndSynced =
      mutation.payload.status === statusCodes.DELIVERED &&
      mutation.state === mutationStates.SYNCED;
    if (pkgAlreadyDeliveredAndSynced) {
      return deletePackageMutation(mutation.packageId);
    }
    return Promise.resolve();
  });
  // await all deletions fo happen
  await Promise.all(deletions);
};

/**
 * Check if the mutation status is equal to the package status saved in backend
 * @param {*} pkg
 * @returns {boolean} true if the mutation status is equal to the package status saved in backend
 */
export const isMutationStatusEqualToPackageStatus = pkg => {
  return pkg.backendStatus?.code === pkg.status?.code;
};

/**
 * Sort packages according to hierarchy:
 * - Packages unacked (no attempt to deliver - no mutation)
 * - Packages with mutation - any attempt of status update generates a mutation (success or failure to deliver)
 *
 * Sort packages according to index:
 * - In both hierarchies, packages are sorted by index
 *
 * @param {Array} mutations mutations from the DB
 * @param {Array} mergedPackagesWithMutation packages from the API merged with the mutations in method mergePackageList
 * @returns {Array}
 */
const sortPackagesByHierarchyAndIndex = (
  mutations,
  mergedPackagesWithMutation
) => {
  const packagesWithActionPriority = mergedPackagesWithMutation.filter(
    m =>
      m.status.code !== statusCodes.DELIVERED ||
      m.mutationSyncState !== mutationStates.SYNCED ||
      !isMutationStatusEqualToPackageStatus(m)
  );

  const packagesWithActionPriorityIds = packagesWithActionPriority.map(
    p => p.packageId
  );

  const deliveredAndSyncedPackages = mutations
    .filter(
      m =>
        m.payload.status === statusCodes.DELIVERED &&
        m.state === mutationStates.SYNCED &&
        !packagesWithActionPriorityIds.includes(m.packageId)
    )
    .map(formatMutationsOfDeliveredAndSyncedPackages);

  return [
    ...sortPackagesByIndexAndDisplayId(packagesWithActionPriority),
    ...sortPackagesByIndexAndDisplayId(deliveredAndSyncedPackages)
  ];
};

/**
 * @param {Array} packageList
 * @param {boolean} listDeliveredPackages if true, we'll include the delivered packages mutations to driver's list and re-sort the packages
 * @returns {Promise<Array>} - merged package list with draft db */
const mergePackageList = async ({
  packageList,
  listDeliveredPackages = false,
  considerDeliveredPackagesFromBackend = false
}) => {
  const mutations = await getPackageMutations();

  const mergedPackagesWithMutation = packageList.map(pkg => {
    const mutationAssociatedWithThisPackage = mutations.find(
      mutation => mutation.packageId === pkg.packageId
    );

    return mergePackageWithMutation({
      pkg,
      mutation: mutationAssociatedWithThisPackage,
      listDeliveredPackages
    });
  });

  if (considerDeliveredPackagesFromBackend) {
    const idsPkgsFromBackend = packageList.map(p => p.packageId);
    const pkgsFromMutationOnly = mutations
      .filter(m => !idsPkgsFromBackend.includes(m.packageId))
      .map(formatMutationsOfDeliveredAndSyncedPackages);
    return [...mergedPackagesWithMutation, ...pkgsFromMutationOnly];
  }

  if (listDeliveredPackages) {
    return sortPackagesByHierarchyAndIndex(
      mutations,
      mergedPackagesWithMutation
    );
  }
  return sortPackagesByIndexAndDisplayId(mergedPackagesWithMutation);
};

export default wrapOperationsInDexieErrorHandler({
  dbName: 'draft',
  operations: {
    putPackageMutation,
    clearDB,
    getPackageMutationByPackageId,
    getPackageMutations,
    getPendingPackageMutations,
    deletePackageMutation,
    updateMutationState,
    cleanMutationsBasedOnPackageList,
    clearMutationsOfDeliveredPackages,
    mergePackageList
  }
});
