import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box, Grid, List, ListItem, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

export default function PickupProblemsList({ items }) {
  const theme = useTheme();
  const { colors, typography } = theme;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <List disablePadding>
        {items.map(({ title, subtitle, onClick }) => (
          <ListItem button divider key={title} onClick={onClick}>
            <Grid container spacing={1}>
              <Box
                my={2.5}
                mx={1.5}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    style={{
                      ...typography.h7,
                      fontWeight: typography.fontWeightMedium
                    }}
                  >
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography
                      variant="body2"
                      style={{ lineHeight: '1.25rem' }}
                    >
                      {subtitle}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Box
                    color={colors.neutrals.typeface.primary}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ChevronRight data-testid="chevron-right-icon" />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

PickupProblemsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired
};
