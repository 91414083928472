import db from './assignment-list-db';

const { saveAssignmentList, getAssignmentList } = db;

/**
 * This function replaces all the cargos of the first assignment of the
 * AssignmentWaypoint with `id` equal to the passed `assignmentWaypointId`
 *
 * @param {Number} assignmentWaypointId
 * @param {Array<Cargos>} newCargos
 * @returns {Promise<AssignmentList>}
 */
export default async function updateAssignmentWaypointCargos(
  assignmentWaypointId,
  newCargos
) {
  const assignmentList = await getAssignmentList();
  const { packages, pickups, assignmentsWaypoints } = assignmentList;

  const mutatedAssignmentsWaypoints = assignmentsWaypoints.map(waypoint => {
    const matchedWaypoint = waypoint.waypointId === assignmentWaypointId;
    if (matchedWaypoint) {
      /* eslint-disable no-param-reassign */
      waypoint.assignments[0].cargos = newCargos;
    }
    return waypoint;
  });

  const mutatedAssignmentList = {
    packages,
    pickups,
    assignmentsWaypoints: mutatedAssignmentsWaypoints
  };

  await saveAssignmentList(mutatedAssignmentList);

  return mutatedAssignmentList;
}
