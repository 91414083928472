import React from 'react';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import { interpret, Interpreter } from 'xstate';
import { Box } from '@material-ui/core';
import { DELIVERY_OPTIONS_MAP } from 'view/pages/delivery-options/constants';
import { Document } from 'view/pages/protocol/document';
import recipientMachine, { STATES, ACTIONS } from './machine';

export { recipientMachine };

export default function Recipient({ recipientMachineRef }) {
  const [current, send] = useService(recipientMachineRef);

  const onContinue = () => send(ACTIONS.CONTINUE);
  const onGoBack = () => send(ACTIONS.BACK);

  const onChange = ({
    name,
    document,
    documentType,
    notes,
    recipientRelationship
  }) =>
    send(ACTIONS.CHANGE, {
      value: { name, document, documentType, notes, recipientRelationship }
    });

  const { deliveryOption } = current.context;
  const shouldCheckDocument = current.context.pkg?.shouldCheckDocument;

  const hasRecipientRelationship = !!(
    deliveryOption &&
    deliveryOption.id === DELIVERY_OPTIONS_MAP.OTHER_RECIPIENT.id
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {current.value === STATES.documentStep && (
        <Document
          deliveryOption={deliveryOption}
          hasRecipientRelationship={hasRecipientRelationship}
          shouldCheckDocument={shouldCheckDocument}
          isDelivery
          onGoBack={onGoBack}
          onConfirm={({
            name,
            document,
            documentType,
            notes,
            recipientRelationship
          }) => {
            onChange({
              name,
              document,
              documentType,
              notes,
              recipientRelationship
            });
            onContinue();
          }}
        />
      )}

      {current.value === STATES.loadingStep && <h1>Loading step</h1>}
    </Box>
  );
}
export const makeRecipientMachineRef = (context = {}) => {
  const service = interpret(
    recipientMachine.withContext({
      ...recipientMachine.context,
      ...context
    })
  );
  service.start();
  return service;
};

Recipient.propTypes = {
  recipientMachineRef: PropTypes.instanceOf(Interpreter)
};
Recipient.defaultProps = {
  recipientMachineRef: makeRecipientMachineRef()
};
