import { Machine, assign } from 'xstate';
import {
  getPickupPackages,
  finishPickup,
  markPickupAsCompleted
} from 'operations/pickup';
import messages from './messages';

export const STATE = {
  initial: 'initial', // When the screen opens,
  loadingPackages: 'loadingPackages', // When updating package list
  confirmingPackages: 'confirmingPackages', // When user agrees to take the packages
  error: 'error', // When endpoint to start shipment/update packages fails
  successLoadingPackages: 'successLoadingPackages', // Should display the success loading list information
  successConfirmingPackages: 'successConfirmingPackages', // Should redirect user to package list
  refreshPackages: 'refreshPackages'
};

export const EVENT = {
  loadPackages: 'loadPackages', // Call to update the package list
  confirmPackages: 'confirmPackages', // Call to confirm the package list
  returnToInitial: 'returnToInitial', // Call to return to initial state
  refreshPackages: 'refreshPackages'
};

export const ERROR_DATA = {
  network: {
    message: messages.defaultErrors.network,
    icon: 'noWifi',
    event: EVENT.returnToInitial
  },
  error: {
    message: messages.defaultErrors.generic,
    icon: 'error',
    event: EVENT.returnToInitial
  },
  errorOutdateToSync: {
    message: messages.defaultErrors.generic,
    icon: 'error',
    event: EVENT.loadPackages
  },
  custodyNotTransfer: {
    message: messages.pickupPackages.custodyNotTransferError,
    icon: 'error',
    event: EVENT.loadPackages
  }
};

const fetchPickupPackages = pickupId => {
  return getPickupPackages({ pickupId })
    .error(428, error => {
      throw Object.assign(new Error(ERROR_DATA.custodyNotTransfer.message), {
        errorData: ERROR_DATA.custodyNotTransfer,
        ...error
      });
    })
    .fetchError(error => {
      throw Object.assign(new Error(ERROR_DATA.network.message), {
        errorData: ERROR_DATA.network,
        ...error
      });
    })
    .json();
};

export default Machine(
  {
    id: 'pickup-packages',
    initial: STATE.loadingPackages,
    context: {
      pickup: null,
      packages: [],
      errorMessage: '',
      errorIcon: undefined,
      errorEvent: undefined,
      ignoreProxy: false
    },
    states: {
      [STATE.initial]: {
        on: {
          [EVENT.loadPackages]: STATE.loadingPackages,
          [EVENT.confirmPackages]: STATE.confirmingPackages,
          [EVENT.refreshPackages]: STATE.refreshPackages
        }
      },
      [STATE.loadingPackages]: {
        invoke: {
          src: ({ pickup }) => fetchPickupPackages(pickup?.pickupId),
          onDone: {
            target: STATE.initial,
            actions: ['handleApiSuccess']
          },
          onError: {
            target: STATE.error,
            actions: ['handleApiError']
          }
        }
      },
      [STATE.refreshPackages]: {
        invoke: {
          src: ({ pickup }) => {
            return fetchPickupPackages(pickup?.pickupId);
          },
          onDone: {
            target: STATE.successLoadingPackages,
            actions: ['handleApiSuccess']
          },
          onError: {
            target: STATE.error,
            actions: ['handleApiError']
          }
        }
      },
      [STATE.confirmingPackages]: {
        invoke: {
          src: ({ pickup, packages, ignoreProxy }) =>
            finishPickup({ pickup, packages, ignoreProxy })
              .error(428, error => {
                const errorData = {
                  errorData: ERROR_DATA.errorOutdateToSync,
                  ...error
                };

                throw errorData;
              })
              .fetchError(error => {
                const errorData = {
                  errorData: ERROR_DATA.network,
                  ...error
                };

                throw errorData;
              })
              .json(() => {
                markPickupAsCompleted(pickup);
              }),
          onDone: {
            target: STATE.successConfirmingPackages
          },
          onError: {
            target: STATE.error,
            actions: ['handleApiError']
          }
        }
      },
      [STATE.error]: {
        on: {
          [EVENT.loadPackages]: STATE.loadingPackages,
          [EVENT.returnToInitial]: STATE.initial
        }
      },
      [STATE.successLoadingPackages]: {
        on: {
          [EVENT.returnToInitial]: STATE.initial,
          [EVENT.loadPackages]: STATE.loadingPackages,
          [EVENT.confirmPackages]: STATE.confirmingPackages
        }
      },
      [STATE.successConfirmingPackages]: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      handleApiError: assign({
        errorMessage: (_, event) => {
          return (
            event.data?.json?.errors?.map(item => item.message).join('\n') ||
            event.data?.errorData?.message ||
            ERROR_DATA.error.message
          );
        },
        errorIcon: (_, event) => {
          return event.data?.errorData?.icon || ERROR_DATA.error.icon;
        },
        errorEvent: (_, event) => {
          return event.data?.errorData?.event || EVENT.returnToInitial;
        }
      }),
      handleApiSuccess: assign({
        packages: (_, event) => {
          return event.data.packages || [];
        }
      })
    }
  }
);
