import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Typography, Button, Drawer } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import {
  setDatesDriverHasSeenOnboarding,
  getDatesDriverHasSeenOnboarding,
  dateDriverHasSeenChangePackageStatus,
  dateDriverHasSeenOnboarding
} from 'operations/assignment-list/onboarding/storage';
import { Alert } from '@material-ui/lab';
import { ReactComponent as OnBoardingIcon } from './icons/onboarding.svg';

export const texts = {
  title: 'Novidades para você',
  subtitle: (
    <Typography variant="h5">
      A partir de agora <b>veja o que você já finalizou</b>!
    </Typography>
  ),
  body:
    'Ao final da listagem agora é possível visualizar todos os pacotes que já foram entregues na sua rota',
  warningTitle: 'Caso seja necessário',
  warningBody:
    'Você consegue ajustar as marcações dos pacotes em casos de problema. Fique atento para garantir a marcação correta!',
  button: 'Ok, entendi'
};

const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

/**
 * We'll show the onboarding page twice for the driver, one time for each day.
 *
 * Returns if the onboarding page should be shown to the driver according to the rules:
 * 1. FS of delivered packages is enabled for driver
 * 2. Driver has not seen the onboarding page yet
 * 3. Driver has seen the onboarding page only once (not count the same day)
 * @param {*} isListDeliveredPackagesEnabledForDriver FS of delivered packages
 * @returns {boolean}
 */
const shouldShowOnBoardingPage = isListDeliveredPackagesEnabledForDriver => {
  if (!isListDeliveredPackagesEnabledForDriver) return false;

  const dates = getDatesDriverHasSeenOnboarding(dateDriverHasSeenOnboarding);
  if (!dates) return true;

  if (dates.length >= 2) {
    return false;
  }

  return dates.length === 1
    ? !isSameDay(dates[0], new Date().toISOString())
    : false;
};

const shouldShowOnBoardingPageWithWarning = showWarningOnboarding => {
  if (!showWarningOnboarding) return false;

  const dates = getDatesDriverHasSeenOnboarding(
    dateDriverHasSeenChangePackageStatus
  );
  if (!dates) return true;

  if (dates.length >= 2) {
    return false;
  }

  return dates.length === 1
    ? !isSameDay(dates[0], new Date().toISOString())
    : false;
};

function OnBoardingAssignmentList() {
  const isListDeliveredPackagesEnabledForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByCompanyRelation
    )
  );

  const isListDeliveredPackagesEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByLastMileCompany
    )
  );

  const showWarningOnboardingByRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageByCompanyRelation
    )
  );

  const showWarningOnboardingByLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageForDriverLastMileCompany
    )
  );

  const showWarningOnboarding =
    showWarningOnboardingByLMC && showWarningOnboardingByRelation;

  const shouldShowWarningOnboarding = useMemo(() => {
    return showWarningOnboarding;
  }, [showWarningOnboarding]);

  const isListDeliveredPackagesEnabledForDriver = useMemo(() => {
    return (
      isListDeliveredPackagesEnabledForCompanyRelation &&
      isListDeliveredPackagesEnabledForDriverLMC
    );
  }, [
    isListDeliveredPackagesEnabledForCompanyRelation,
    isListDeliveredPackagesEnabledForDriverLMC
  ]);

  const [showOnBoarding, setShowOnBoarding] = useState(false);

  const {
    colors,
    typography: { pxToRem }
  } = useTheme();

  const closeOnBoarding = useCallback(() => {
    setDatesDriverHasSeenOnboarding(dateDriverHasSeenOnboarding);
    if (showWarningOnboarding) {
      setDatesDriverHasSeenOnboarding(dateDriverHasSeenChangePackageStatus);
    }
    setShowOnBoarding(false);
  }, [setShowOnBoarding, showWarningOnboarding]);

  useEffect(() => {
    if (
      isListDeliveredPackagesEnabledForDriver &&
      shouldShowWarningOnboarding
    ) {
      return setShowOnBoarding(
        shouldShowOnBoardingPageWithWarning(shouldShowWarningOnboarding)
      );
    }
    return setShowOnBoarding(
      shouldShowOnBoardingPage(isListDeliveredPackagesEnabledForDriver)
    );
  }, [
    isListDeliveredPackagesEnabledForDriver,
    setShowOnBoarding,
    shouldShowWarningOnboarding
  ]);

  return (
    <Drawer
      open={showOnBoarding}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          height: '100%'
        }
      }}
    >
      <Box p={3} display="flex" flexDirection="column" height="100%">
        <OnBoardingIcon />
        <Typography
          variant="subtitle2"
          style={{
            marginTop: pxToRem(24),
            marginBottom: pxToRem(4),
            color: colors.neutrals.typeface.secondary
          }}
        >
          {texts.title}
        </Typography>
        {texts.subtitle}
        <Typography
          variant="body1"
          style={{
            marginTop: pxToRem(16),
            color: colors.neutrals.typeface.secondary
          }}
        >
          {texts.body}
        </Typography>
        {shouldShowWarningOnboarding && (
          <Box mt="auto">
            <Alert severity="info">
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 'bold'
                }}
              >
                {texts.warningTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  marginTop: pxToRem(6)
                }}
              >
                {texts.warningBody}
              </Typography>
            </Alert>
          </Box>
        )}
        <Box mt="auto">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={closeOnBoarding}
          >
            {texts.button}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default React.memo(OnBoardingAssignmentList);
