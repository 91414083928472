export default {
  V2: {
    SUBTITLE: {
      OPPORTUNISTIC: 'Em destaque',
      CAPACITY_RESERVE: 'Para amanhã',
      DEFAULT: 'Para hoje'
    },
    HEADER: {
      DEFAULT: 'Ofertas',
      CAPACITY_RESERVE: 'Ofertas programadas'
    },
    ERROR: {
      LOAD_OFFER: 'Não foi possível carregar a oferta'
    }
  }
};
