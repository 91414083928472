import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import {
  useFeatureSwitch,
  useRemoteConfig
} from '@loggi/firebase-feature-switches';
import { Redirect } from 'react-router-dom';
import PickupProblemsTemplate from 'view/templates/pickup-problems-template';
import PickupProblemsListSkeleton from 'view/molecules/pickup-problems-list/pickup-problems-list-skeleton';
import PickupProblemsList from 'view/molecules/pickup-problems-list';
import PickupProblemsDetail from 'view/molecules/pickup-problems-detail';
import FacadePhoto from 'view/pages/facade-photo';
import { useSnackbar } from 'notistack';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForPickupFlow,
  remoteConfigFlags
} from 'operations/feature-switches';
import { PAGES } from 'view';
import { getPickupProblems, cancelPickup } from 'operations/pickup';
import sharedPropTypes from 'view/shared-prop-types';
import pickupProblemsMachine, {
  STATES,
  TRANSITIONS
} from './pickup-problems.machine';
import { LABELS } from './constants';

export default function PickupProblemsPage({ pickup, onGoBack }) {
  const { enqueueSnackbar } = useSnackbar();
  const [reason, setReason] = useState('');
  const enablePickupFacadePhotoFS = useFeatureSwitch(
    featureSwitches.enablePickupFacadePhoto
  );
  const isFacadePhotoEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    enablePickupFacadePhotoFS
  );
  const enablePickupFacadePhotoForPickupFlowFS = useFeatureSwitch(
    featureSwitches.enablePickupFacadePhotoForPickupFlow
  );
  const isFacadePhotoEnabledForPickupFlow = featureSwitchEnabledForPickupFlow({
    pickupFlow: pickup.pickupFlow,
    featureSwitchValue: enablePickupFacadePhotoForPickupFlowFS
  });
  const problemSlugsThatRequireFacadePhoto = useRemoteConfig(
    remoteConfigFlags.problemSlugsThatRequireFacadePhoto.name,
    remoteConfigFlags.problemSlugsThatRequireFacadePhoto.defaultValue
  );

  const [currentState, send] = useMachine(pickupProblemsMachine, {
    actions: {
      notifyError: () => {
        showSnackbar({
          message: LABELS.genericError,
          variant: 'error',
          enqueueSnackbar
        });
      },
      notifyProblemConfirmationSuccess: () => {
        showSnackbar({
          message: LABELS.successMessage,
          variant: 'successSnackbar',
          enqueueSnackbar
        });
      }
    },
    guards: {
      requireFacadePhoto: context =>
        isFacadePhotoEnabledForDriverLMC &&
        isFacadePhotoEnabledForPickupFlow &&
        problemSlugsThatRequireFacadePhoto[context.selectedProblem.slug]
    },
    services: {
      confirmProblem: async context => {
        return (await cancelPickup({
          pickup,
          problemSlug: context.selectedProblem.slug,
          problemComplement: context.reason,
          ignoreProxy: true,
          sendDriverPosition: true
        })).json();
      },
      loadProblems: async () => {
        const { reasons } = await getPickupProblems().json();

        return reasons;
      }
    }
  });

  const handleProblemConfirmationGoBack = () => {
    setReason('');
    send(TRANSITIONS.back);
  };

  if (currentState.matches(STATES.returnToPickup)) {
    onGoBack();
  }

  return (
    <>
      {currentState.matches(STATES.loadingProblems) && (
        <PickupProblemsTemplate
          title={currentState.context.title}
          subtitle={currentState.context.subtitle}
          onGoBack={() => send(TRANSITIONS.back)}
        >
          <PickupProblemsListSkeleton />
        </PickupProblemsTemplate>
      )}

      {currentState.matches(STATES.categorySelection) && (
        <PickupProblemsTemplate
          title={currentState.context.title}
          subtitle={currentState.context.subtitle}
          onGoBack={() => send(TRANSITIONS.back)}
        >
          <PickupProblemsList
            items={currentState.context.categories.map(category => ({
              title: category.title,
              subtitle: category.subtitle,
              onClick: () =>
                send(TRANSITIONS.selectCategory, {
                  selectedCategory: category
                })
            }))}
          />
        </PickupProblemsTemplate>
      )}

      {currentState.matches(STATES.problemSelection) && (
        <PickupProblemsTemplate
          title={currentState.context.title}
          subtitle={currentState.context.subtitle}
          onGoBack={() => send(TRANSITIONS.back)}
        >
          <PickupProblemsList
            items={currentState.context.problems
              .filter(
                problem =>
                  problem.category ===
                  currentState.context.selectedCategory.slug
              )
              .map(problem => ({
                title: problem.description,
                onClick: () =>
                  send(TRANSITIONS.selectProblem, { selectedProblem: problem })
              }))}
          />
        </PickupProblemsTemplate>
      )}

      {currentState.matches(STATES.problemConfirmation) && (
        <PickupProblemsTemplate
          title={currentState.context.title}
          subtitle={currentState.context.subtitle}
          onGoBack={handleProblemConfirmationGoBack}
        >
          <PickupProblemsDetail
            onChange={text => setReason(text)}
            infobox={LABELS.problemConfirmation.infobox}
            onSubmit={() => {
              send(TRANSITIONS.confirmProblem, { reason });
            }}
            defaultTextValue={reason}
          />
        </PickupProblemsTemplate>
      )}

      {currentState.matches(STATES.facadePhoto) && (
        <FacadePhoto
          onGoBack={() => send(TRANSITIONS.back)}
          facadePhotoContext={{
            pickupId: pickup.pickupId,
            problemSlug: currentState.context.selectedProblem.slug,
            pickupProblemEvidenceSignedUrl:
              pickup.pickupProblemEvidenceSignedUrl
          }}
        />
      )}

      {currentState.matches(STATES.redirect) && (
        <Redirect to={PAGES.PACKAGE_LIST} />
      )}
    </>
  );
}

PickupProblemsPage.propTypes = {
  pickup: sharedPropTypes.pickup.isRequired,
  onGoBack: PropTypes.func.isRequired
};

PickupProblemsPage.url = '/problemas';
