import Moment from 'moment';
import { statusCodes } from 'operations/update-status';
import getBrowserNameAndVersion from 'operations/get-browser-info';
import getDriverAppVersion from 'operations/get-driver-app-version';
import { formatFullAddress } from 'view/text-formatters/package-info';
import { getCurrentUser } from 'operations/auth';

export const isPickup = packages =>
  packages?.length && packages[0]?.status?.code === statusCodes.PICKUP;

export const parseToDate = date => Moment(date).toDate();

export const getPickupDate = packages =>
  isPickup(packages)
    ? parseToDate(packages[0]?.status?.updated)
    : Moment().toDate();

export const isWeekendDelivery = packages =>
  isPickup(packages)
    ? Moment(packages[0].status.updated).isoWeekday() >= 6
    : Moment().isoWeekday() >= 6;

export const getDayOfWeek = date => Moment(date).isoWeekday();

export const isValidISODate = date =>
  Moment(date, Moment.ISO_8601, true).isValid();

export const isValidHourMinuteFormat = hourMinuteText => {
  const validFormats = ['HH:mm:ss', 'HH:mm', 'H:mm'];
  return Moment(hourMinuteText, validFormats, true).isValid();
};

export const cutPhrase = (phrase, start, end) => {
  return phrase
    .split(' ')
    .slice(start, end)
    .join(' ');
};

export const splitLoggiAddress = addressLines => {
  let addressTitle;
  let addressBody;

  if (addressLines) {
    const [minAddressTitleWords, maxAddressTitleWords] = [0, 5];
    const [minAddressBodyWords, maxAddressBodyWords] = [5, 100];

    addressTitle = cutPhrase(
      addressLines,
      minAddressTitleWords,
      maxAddressTitleWords
    );
    addressBody = cutPhrase(
      addressLines,
      minAddressBodyWords,
      maxAddressBodyWords
    );
  }

  return { addressTitle, addressBody };
};

export function buildLoggiAddressV2(addressDestination) {
  if (!addressDestination?.postalAddress) return '';
  const addr = addressDestination.postalAddress;
  let fullAddress = '';
  fullAddress = (addr.addressLines?.filter(x => !!x) || []).join(' ');
  fullAddress += addr.sublocality ? ` ${addr.sublocality}` : '';
  fullAddress += addr.locality ? ` ${addr.locality}` : '';
  fullAddress += addr.administrativeArea ? ` - ${addr.administrativeArea}` : '';
  fullAddress += addr.postalCode ? ` ${addr.postalCode}` : '';
  return fullAddress?.trim();
}

export function createErrorMessage(error) {
  if (!error) return '';

  const flechaVersion = process.env.REACT_APP_VERSION;
  const browser = getBrowserNameAndVersion();
  const driverEmail = getCurrentUser()?.email;
  const driverAppVersion = getDriverAppVersion();

  return JSON.stringify({
    error,
    flechaVersion,
    browser,
    driverEmail,
    driverAppVersion
  });
}

export function isEmptyObject(object) {
  if (!object) return true;

  if (typeof object === 'object') {
    return (
      Object.prototype.toString.call(object) === '[object Object]' &&
      JSON.stringify(object) === '{}'
    );
  }

  return false;
}

export const setScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export function formatAddressToPickupPackage(pkg) {
  return pkg.addressDestination
    ? buildLoggiAddressV2(pkg.addressDestination)
    : formatFullAddress(pkg.destination, { useZipCode: false });
}
