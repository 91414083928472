import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { buildLoggiAddressV2, splitLoggiAddress } from 'view/utils';
import DeadlineTime from 'view/molecules/assignment-row/deadline-time';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { messages } from './constants';

function formatDeadlineTime(deadline) {
  const isPickup = true;
  if (deadline) {
    return <DeadlineTime deadlineTime={deadline} isPickup={isPickup} />;
  }
  return <></>;
}

export default function OfferNotification() {
  const history = useHistory();
  const {
    location: {
      state: { notificationType, eventAddress, deadline }
    }
  } = history || {};

  const message = messages[notificationType];
  const eventAddressV2 = buildLoggiAddressV2(eventAddress);
  const { addressTitle, addressBody } = splitLoggiAddress(eventAddressV2);

  const goBackButton = () => {
    history.goBack();
  };

  return (
    <Box height="100%" minHeight="100%" p={2}>
      <Box
        pt={7.5}
        textAlign="left"
        alignItems="left"
        data-testid="offer-notification-header"
      >
        {message.getIcon()}
        <Typography variant="h4">
          Atividade{' '}
          <Box fontWeight="fontWeightBold" display="inline">
            {message.title}
          </Box>
        </Typography>
      </Box>
      <Box py={2.5} pt={3}>
        <Alert severity="info" color="info" icon={false}>
          {formatDeadlineTime(deadline)}
          <Typography>
            <Box fontSize={16} fontWeight={400} pt={0.5} pb={0.5}>
              {addressTitle}
            </Box>
          </Typography>
          <Typography variant="body2">
            <Box component="span" color={colors.smoke[800]} pb={5}>
              {addressBody}
            </Box>
          </Typography>
        </Alert>
      </Box>
      <Box pt={4.5} pb={4.5}>
        {message.explanationOfferNotification()}
      </Box>
      <Box py={5} pb={2.5} pt={10}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          onClick={goBackButton}
          data-testid="close-button"
        >
          {messages.button}
        </Button>
      </Box>
    </Box>
  );
}
