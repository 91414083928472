export default function formatCategories(data) {
  const categories = data.reduce((acc, obj) => {
    const existingCategory = acc.find(item => item.slug === obj.category);

    if (existingCategory) {
      existingCategory.subtitles.push(obj.description);
    } else {
      acc.push({
        slug: obj.category,
        title: obj.categoryDescription,
        subtitles: [obj.description]
      });
    }

    return acc;
  }, []);

  return categories.map(category => ({
    title: category.title,
    subtitle: category.subtitles.join(' • '),
    slug: category.slug
  }));
}
