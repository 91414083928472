import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Button,
  Chip,
  Badge
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import { colors } from '@loggi/mar';
import { Virtuoso } from 'react-virtuoso';

import { useRoundedBorder } from 'view/utils/styles';

import messages from './messages';
import { packageError } from './constants';

function PackageItem({ item, onDelete, fromPackageCheck }) {
  const isLoading = !item.packageId && !item.error && !fromPackageCheck;

  const bgColorError =
    item.error === packageError.networkError
      ? colors.smoke[50]
      : colors.root[0];

  return (
    <Box
      // eslint-disable-next-line spaced-comment
      /* We need to set and unset the borders because of a bug
       * in Material-UI v4. This is the current workaround until
       * v5 is released.
       * https://github.com/mui-org/material-ui/issues/16995#issuecomment-594134826*/
      border={1}
      borderTop={0}
      borderLeft={0}
      borderRight={0}
      borderColor="grey.200"
      px={2.5}
      py={1}
      bgcolor={bgColorError}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Box py={2.5}>
          {isLoading && (
            <Box py={1}>
              <Chip size="small" label="Carregando..." />
            </Box>
          )}
          <Box display="flex" alignItems="center">
            {item.error === packageError.networkError && (
              <Box mr={3}>
                <Badge color="error" variant="dot" />
              </Box>
            )}
            <Box>
              <Typography variant="body1">{item.recipientName}</Typography>
              <Box>
                {item.barcodes.map(barcode => (
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    data-testid="barcode"
                    key={barcode}
                  >
                    {barcode}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        {item.error !== packageError.networkError && (
          <IconButton
            data-testid={`remove-barcode-${item.barcodes}`}
            color="primary"
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      {item.error && item.error !== packageError.networkError && (
        <Box pb={2.5} pt={1.5}>
          <Alert severity="error">{item.error}</Alert>
        </Box>
      )}
    </Box>
  );
}
PackageItem.propTypes = {
  item: PropTypes.shape({
    barcodes: PropTypes.arrayOf(PropTypes.string),
    recipientName: PropTypes.string,
    error: PropTypes.string,
    packageId: PropTypes.string
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  fromPackageCheck: PropTypes.bool
};

PackageItem.defaultProps = {
  fromPackageCheck: false
};

function ScannedPackagesList({
  packages,
  onRemovePackage,
  useWindowScroll,
  fromPackageCheck
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);

  const onDeleteClick = pkg => {
    setPackageToDelete(pkg);
    setOpenDrawer(true);
  };

  // inserts packages with network error at the beginning of the list
  const pkgsWithNetworkError = packages.filter(
    pkg => pkg.error === packageError.networkError
  );
  const pkgsWithoutNetworkError = packages.filter(
    pkg => pkg.error !== packageError.networkError
  );
  const sortedPackages = [...pkgsWithNetworkError, ...pkgsWithoutNetworkError];

  return (
    <>
      <Box>
        <Virtuoso
          useWindowScroll={useWindowScroll}
          data={sortedPackages}
          itemContent={(_, pkg) => (
            <PackageItem
              item={pkg}
              onDelete={() => {
                onDeleteClick(pkg);
              }}
              key={pkg.barcodes}
              fromPackageCheck={fromPackageCheck}
            />
          )}
        />
      </Box>

      <RemovePackageDrawer
        pkg={packageToDelete}
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onRemove={() => {
          onRemovePackage(packageToDelete);
          setOpenDrawer(false);
        }}
      />
    </>
  );
}
ScannedPackagesList.propTypes = {
  packages: PropTypes.arrayOf(PackageItem.propTypes.item),
  onRemovePackage: PropTypes.func.isRequired,
  useWindowScroll: PropTypes.bool,
  fromPackageCheck: PropTypes.bool
};
ScannedPackagesList.defaultProps = {
  packages: [],
  useWindowScroll: true,
  fromPackageCheck: false
};

function RemovePackageDrawer({ pkg, isOpen, onClose, onRemove }) {
  const roundedBorder = useRoundedBorder();
  const pkgInfoString = [pkg?.recipientName, pkg?.barcode]
    .filter(item => !!item)
    .join(' - ');

  return (
    <Drawer
      open={isOpen}
      anchor="bottom"
      classes={{ paper: roundedBorder.root }}
    >
      <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
        <Box color={colors.smoke[900]}>
          <Typography variant="body1" align="center">
            Você quer remover o pacote de{' '}
            <Box display="inline" fontWeight="bold" component="span">
              {pkgInfoString}
            </Box>{' '}
            da sua lista?
          </Typography>
        </Box>

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onRemove}
            fullWidth
          >
            {messages.pickupCheckPackages.buttonRemovePackage}
          </Button>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
              fullWidth
            >
              {messages.pickupCheckPackages.buttonKeepList}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
RemovePackageDrawer.propTypes = {
  pkg: PropTypes.shape({
    recipientName: PropTypes.string,
    barcode: PropTypes.string
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};
RemovePackageDrawer.defaultProps = {
  pkg: {}
};

export default ScannedPackagesList;
