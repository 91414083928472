// eslint-disable-next-line import/no-named-as-default
import api from '../config/api';

/**
 * @typedef {Object} EmptyResponse
 * `data` is the response that was provided by the server.
 * The response should include an empty object.
 * @property {Object} EmptyResponse.data
 * `data` The empty object returned in the response
 */
/**
 * Wrapped PATCH request on Leve's API to ask for a route to the assignment list
 * if the user is a Leve's driver
 * @example
 * // An example of the output
 * {
 *  data: {},
 *  status: 200,
 *  statusText: 'OK',
 *  headers: {},
 *  config: {},
 *  request: {}
 * }
 * @return {Promise<EmptyResponse>} Wretch response object that contain an `EmptyResponse`
 */
export default function askAssignmentListRouteService() {
  return api.patch('/last-mile/v1/shipment/routing');
}
