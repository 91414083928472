import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isAuthenticated } from 'operations/auth';
import { PAGES } from 'view';

/**
 * A private route is a {Route} that is used when it must be protected from anonymous access.
 * Only logged users are able to reach it and any unlogged user will be redirected to the login page.
 * Its parameters are the same as Route (mainly path) and are passed to it.
 *
 * @param {String} path the path representing the route
 * @param {Function} component the page to be rendered at path
 */

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...rest}
    render={props =>
      isAuthenticated() ? (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: PAGES.UNAUTHENTICATED,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Location)
  ]),
  path: PropTypes.string
};

PrivateRoute.defaultProps = {
  component: null,
  location: null,
  path: ''
};

export default PrivateRoute;
