const deliveryDayOfWeekLocalStorageKey = 'day-of-week-delivery';
const deadlineDeliveryTimeLocalStorageKey = 'deadline-delivery-time';

function getDayOfWeek() {
  const dayOfWeekStr = localStorage.getItem(deliveryDayOfWeekLocalStorageKey);
  if (dayOfWeekStr) {
    return Number.parseInt(dayOfWeekStr, 10);
  }

  return null;
}

function getDeadlineDeliveryTime() {
  return localStorage.getItem(deadlineDeliveryTimeLocalStorageKey);
}

function clearDayOfWeek() {
  localStorage.setItem(deliveryDayOfWeekLocalStorageKey, '');
}

function clearDeadlineDeliveryTime() {
  localStorage.setItem(deadlineDeliveryTimeLocalStorageKey, '');
}

function setDayOfWeek(dayOfWeek) {
  clearDayOfWeek();
  if (Number.isInteger(dayOfWeek)) {
    localStorage.setItem(deliveryDayOfWeekLocalStorageKey, dayOfWeek);
  }
}

function setDeadlineDeliveryTime(deadlineDeliveryTime) {
  clearDeadlineDeliveryTime();

  if (deadlineDeliveryTime) {
    localStorage.setItem(
      deadlineDeliveryTimeLocalStorageKey,
      deadlineDeliveryTime
    );
  }
}

export {
  getDeadlineDeliveryTime,
  setDeadlineDeliveryTime,
  clearDeadlineDeliveryTime,
  getDayOfWeek,
  setDayOfWeek,
  clearDayOfWeek
};
