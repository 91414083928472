import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import {
  Box,
  Chip,
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@material-ui/lab';
import { ExpandMore } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import InfoIcon from '@material-ui/icons/Info';

import * as storageDB from 'operations/package-return';
import * as deliveryTimeStorage from 'operations/delivery-storage';

import { PAGES as PagesURLs } from 'view';
import SharedPropTypes from 'view/shared-prop-types';
import {
  DestinationDrawer,
  STATE_ENUM
} from 'view/molecules/destination-drawer';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import { splitLoggiAddress, buildLoggiAddressV2 } from 'view/utils';
import { formatAddress, formatVicinity } from 'view/text-formatters';
import { colors } from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitches
} from 'operations/feature-switches';
import messages from './messages';
import useNearbyDCs from './use-nearby-dcs';
import useBlueIconHeaderStyles from './styles';

function compareCurrentTimeWithReturnPointCloseAt(returnPointCloseAt) {
  const dayOfWeek = deliveryTimeStorage.getDayOfWeek();
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // convert hour "HH:mm" to minutes
  const timeToMinutes = time => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const currentTime = getCurrentTime();
  const currentTimeInMinutes = timeToMinutes(currentTime);
  const returnPointCloseAtInMinutes = timeToMinutes(returnPointCloseAt);
  const message = {};

  if (currentTimeInMinutes >= returnPointCloseAtInMinutes) {
    message.title = messages.nearbyDCs.closedDCTitle;
    message.subtitle =
      dayOfWeek >= 5
        ? messages.nearbyDCs.closedDCMessageWeekend
        : messages.nearbyDCs.closedDCMessageWeek;
    message.color = `${colors.red[100]}`;
  } else if (returnPointCloseAtInMinutes - currentTimeInMinutes <= 60) {
    message.title = messages.nearbyDCs.closeDCTitle;
    message.subtitle =
      dayOfWeek >= 5
        ? messages.nearbyDCs.almostCloseDCMessageWeekend
        : messages.nearbyDCs.almostCloseDCMessageWeek;
    message.color = `${colors.yellow[50]}`;
  } else {
    message.title = messages.nearbyDCs.getCloseDCTitle;
    message.subtitle =
      dayOfWeek >= 5
        ? messages.nearbyDCs.closeDCMessageWeekend
        : messages.nearbyDCs.closeDCMessageWeek;
    message.color = `${colors.green[100]}`;
  }
  return message;
}
function AlertTextBox({ messageText }) {
  return (
    <Box
      bgcolor={colors.smoke[50]}
      p={2.5}
      my={2.5}
      display="flex"
      borderRadius={8}
    >
      <Box display="flex" alignItems="center">
        <Box component="span" color={colors.smoke[900]} mr={1.5}>
          <Typography variant="caption">
            <InfoIcon fontSize="small" />
          </Typography>
        </Box>
      </Box>
      <Box color={colors.smoke[900]}>
        <Typography variant="body2">{messageText}</Typography>
      </Box>
    </Box>
  );
}
AlertTextBox.propTypes = {
  messageText: PropTypes.string.isRequired
};

export function QuestionsAboutPackageReturn({ onOk }) {
  const closeIconStyle = useBlueIconHeaderStyles();
  const dayOfWeek = deliveryTimeStorage.getDayOfWeek();
  const returnPointCloseAt = storageDB.getReturnPointCloseAt();
  const deadlineDeliveryTime = deliveryTimeStorage.getDeadlineDeliveryTime();

  return (
    <Box>
      <Box py={2.5} px={0.5}>
        <IconButton>
          <ArrowBackIcon
            aria-label="back"
            className={closeIconStyle.root}
            onClick={onOk}
            data-testid="btn-questions-return-pkg"
          />
        </IconButton>
      </Box>
      <Box px={2.5}>
        <Typography variant="h5">
          {messages.questionsAboutPackageReturn.title}
        </Typography>
      </Box>
      {/* eslint-disable react/no-array-index-key */}
      <Box px={0.5}>
        {messages.questionsAboutPackageReturn
          .qAndAs(dayOfWeek, returnPointCloseAt, deadlineDeliveryTime)
          .map(({ question, answer }, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                IconButtonProps={{ className: closeIconStyle.root }}
              >
                <Box py={1}>
                  <Typography variant="subtitle2">{question}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box my={-2}>
                  <Typography variant="body1">
                    <span>{answer}</span>
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  );
}
QuestionsAboutPackageReturn.url = '/duvidas';

QuestionsAboutPackageReturn.propTypes = {
  onOk: PropTypes.func.isRequired
};

export function NearbyDC({
  destination,
  returnPointOpenAt,
  returnPointCloseAt,
  addressDestination
}) {
  const [drawerState, setDrawerState] = useState(STATE_ENUM.CLOSED);
  const [notificationMessage, setNotificationMessage] = useState('');

  const enableNewPackageReturnReminder = useFeatureSwitch(
    featureSwitches.enableNewPackageReturnReminder
  );

  const enableNewPackageReturnReminderByRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewPackageReturnReminder
  );

  const addressLines = buildLoggiAddressV2(addressDestination);

  const hasLoggiAddress = !!addressLines;

  const packageDestinationReturn = hasLoggiAddress
    ? addressDestination
    : destination;

  const { addressTitle, addressBody } = splitLoggiAddress(addressLines);

  const message = compareCurrentTimeWithReturnPointCloseAt(returnPointCloseAt);

  const destinationDrawerAndAlert = (
    <>
      <Alert
        open={!!notificationMessage}
        onClose={() => setNotificationMessage('')}
        message={notificationMessage}
        notificationFunction={setNotificationMessage}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...ALERT_TYPES.SUCCESS}
      />
      <DestinationDrawer
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        notificationFunction={setNotificationMessage}
        destination={packageDestinationReturn}
      />
    </>
  );

  return (
    <>
      <ListItem button onClick={() => setDrawerState(STATE_ENUM.OPEN)} divider>
        <Box py={2.25}>
          <Chip
            style={{
              color: colors.smoke[900],
              backgroundColor: enableNewPackageReturnReminderByRelation
                ? message.color
                : ''
            }}
            size="small"
            icon={<WatchLaterIcon />}
            label={messages.nearbyDCs.getReturnUntilLabel({
              from: returnPointOpenAt,
              until: returnPointCloseAt
            })}
          />
          <Box pt={1.5} pb={1.25}>
            <Typography variant="h6">
              {hasLoggiAddress
                ? addressTitle
                : formatAddress({ destination: packageDestinationReturn })}
            </Typography>
          </Box>
          <Typography variant="body2">
            {hasLoggiAddress
              ? addressBody
              : formatVicinity({ destination: packageDestinationReturn })}
          </Typography>
          <Box
            pt={1.25}
            fontSize="body1.fontSize"
            fontWeight={600}
            color="primary.main"
          >
            <Typography variant="inherit">
              {messages.nearbyDCs.howToGetThereLabelText}
            </Typography>
          </Box>
        </Box>
      </ListItem>

      {destinationDrawerAndAlert}
    </>
  );
}

NearbyDC.defaultProps = {
  destination: {},
  addressDestination: {}
};

NearbyDC.propTypes = {
  destination: SharedPropTypes.destination,
  returnPointOpenAt: PropTypes.string.isRequired,
  returnPointCloseAt: PropTypes.string.isRequired,
  addressDestination: SharedPropTypes.addressDestination
};

function LoadingList() {
  return (
    <Box flexGrow={40} px={2.5}>
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={0.5}
        height={20}
        width={164}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={1.5}
        height={32}
        width={285}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={1.5}
        height={20}
        width={129}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={0.5}
        height={24}
        width={90}
      />
    </Box>
  );
}

function CloseDC({ DCListSize, returnPointCloseAt }) {
  const enableNewPackageReturnReminder = useFeatureSwitch(
    featureSwitches.enableNewPackageReturnReminder
  );

  const enableNewPackageReturnReminderByRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewPackageReturnReminder
  );

  const message = compareCurrentTimeWithReturnPointCloseAt(returnPointCloseAt);

  return (
    <Box p={2.5}>
      {enableNewPackageReturnReminderByRelation ? (
        <>
          {DCListSize > 1 ? (
            <>
              <Box>
                <Typography variant="h6">
                  {messages.nearbyDCs.closeDCTitle}
                </Typography>
              </Box>
              <Box whiteSpace="pre-wrap" mt={2}>
                <Typography variant="body1">
                  {messages.nearbyDCs.getCloseDCMessage}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="h6">{message.title}</Typography>
              </Box>
              <Box whiteSpace="pre-wrap" mt={2}>
                <Typography variant="body1">{message.subtitle}</Typography>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box>
            <Typography variant="h6">
              {messages.nearbyDCs.closeDCTitle}
            </Typography>
          </Box>
          <Box whiteSpace="pre-wrap">
            <Typography variant="body1">
              {messages.nearbyDCs.getCloseDCMessage}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

CloseDC.propTypes = {
  DCListSize: PropTypes.number.isRequired,
  returnPointCloseAt: PropTypes.string
};

CloseDC.defaultProps = {
  returnPointCloseAt: ''
};

export function NearbyDCs({ getReturnPointsList }) {
  const {
    state,
    isLoading,

    returnPointsList,
    hasReturnPointsList,

    retry
  } = useNearbyDCs(getReturnPointsList);

  const drawerState =
    state === 'failure'
      ? DRAWER_STATE.RETURN_LIST_NETWORK_ERROR
      : DRAWER_STATE.CLOSED;

  const history = useHistory();
  const setDrawerState = () => history.goBack();

  const extraButton = (
    <Box py={2.5}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={retry}
      >
        Tentar de novo
      </Button>
    </Box>
  );

  if (hasReturnPointsList) {
    storageDB.setReturnPointCloseAt(returnPointsList);
  } else {
    storageDB.clearReturnPointCloseAt();
  }

  const getReturnPointCloseAt = storageDB.getReturnPointCloseAt();

  return (
    <Box
      minHeight="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      py={2.5}
    >
      <Box px={0.5}>
        <IconButton aria-label="back" color="primary" onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box flexGrow={2} overflow="auto">
        <List>
          <Box px={2.5}>
            <Box pb={1.25}>
              <Typography variant="h5">
                {messages.nearbyDCs.getTitle(returnPointsList.length)}
              </Typography>
            </Box>
          </Box>

          {isLoading && <LoadingList />}
          {hasReturnPointsList && (
            <>
              {returnPointsList.map(
                ({
                  destination,
                  returnPointCloseAt,
                  returnPointOpenAt,
                  addressDestination
                }) => (
                  <Box
                    key={
                      destination?.lat ||
                      addressDestination?.coordinates?.latitude
                    }
                  >
                    <NearbyDC
                      destination={destination}
                      returnPointOpenAt={returnPointOpenAt}
                      returnPointCloseAt={returnPointCloseAt}
                      addressDestination={addressDestination}
                    />
                  </Box>
                )
              )}
              <CloseDC
                DCListSize={returnPointsList?.length || 0}
                returnPointCloseAt={getReturnPointCloseAt}
              />
            </>
          )}
        </List>
      </Box>
      <Box py={2.5} px={2.5}>
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          component={Link}
          to={PagesURLs.FINISH_DELIVERING}
        >
          {messages.nearbyDCs.finishShipmentButtonText}
        </Button>
      </Box>
      <NotificationDrawer
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        extraButton={extraButton}
      />
    </Box>
  );
}
NearbyDCs.url = '/agencias-proximas';

NearbyDCs.propTypes = {
  getReturnPointsList: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  deleteEmptyLeftSpace: {
    '&:before': {
      flex: 0,
      padding: '0'
    }
  },
  noLeftPaddingTimeline: {
    padding: '6px 0px'
  },
  smallPaddingTimelineContent: {
    paddingTop: '3px',
    paddingBottom: '20px'
  }
}));

export function HowToReturn() {
  const classes = useStyles();
  const history = useHistory();
  const enableNewPackageReturnReminder = useFeatureSwitch(
    featureSwitches.enableNewPackageReturnReminder
  );

  const enableNewPackageReturnReminderByRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewPackageReturnReminder
  );

  return (
    <Box py={2.5}>
      <Box px={0.5} pb={2.5}>
        <IconButton aria-label="back" color="primary" onClick={history.goBack}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      {enableNewPackageReturnReminderByRelation ? (
        <Box
          px={2.5}
          minHeight="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Box pb={1.25}>
              <Typography variant="h5">
                {messages.newHowToReturn.title}
              </Typography>
            </Box>
            <Typography variant="subtitle2">
              {messages.newHowToReturn.subtitle}
            </Typography>

            <Timeline className={classes.noLeftPaddingTimeline}>
              {messages.newHowToReturn.steps.map((step, index) => (
                <TimelineItem
                  className={classes.deleteEmptyLeftSpace}
                  key={index}
                >
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined" />
                    {index !== messages.howToReturn.steps.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent
                    className={classes.smallPaddingTimelineContent}
                  >
                    <Typography variant="body1">{step}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>

          <Box>
            <Box pb={2}>
              <Button
                fullWidth
                color="primary"
                size="large"
                variant="contained"
                // navigation props
                // the `to` prop only works if you use react-router's Link as component
                component={Link}
                to={location => `${location.pathname}${NearbyDCs.url}`}
              >
                {messages.newHowToReturn.seeNearbyDCsButtonText}
              </Button>
            </Box>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="outlined"
              component={Link}
              to={location =>
                `${location.pathname}${QuestionsAboutPackageReturn.url}`
              }
            >
              {messages.howToReturn.iHaveQuestionsAboutPackageReturnButtonText}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          px={2.5}
          minHeight="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Box pb={1.25}>
              <Typography variant="h5">{messages.howToReturn.title}</Typography>
            </Box>
            <Typography variant="subtitle2">
              {messages.howToReturn.subtitle}
            </Typography>

            <Timeline className={classes.noLeftPaddingTimeline}>
              {messages.howToReturn.steps.map((step, index) => (
                <TimelineItem
                  className={classes.deleteEmptyLeftSpace}
                  key={index}
                >
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined" />
                    {index !== messages.howToReturn.steps.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent
                    className={classes.smallPaddingTimelineContent}
                  >
                    <Typography variant="body1">{step}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>

            <AlertTextBox
              messageText={messages.howToReturn.dcReturnTimeAlert}
            />
          </Box>

          <Box>
            <Box pb={2}>
              <Button
                fullWidth
                color="primary"
                size="large"
                variant="contained"
                // navigation props
                // the `to` prop only works if you use react-router's Link as component
                component={Link}
                to={location => `${location.pathname}${NearbyDCs.url}`}
              >
                {messages.howToReturn.seeNearbyDCsButtonText}
              </Button>
            </Box>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="outlined"
              component={Link}
              to={location =>
                `${location.pathname}${QuestionsAboutPackageReturn.url}`
              }
            >
              {messages.howToReturn.iHaveQuestionsAboutPackageReturnButtonText}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
