import React from 'react';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import colors from '@loggi/mar/src/themes/drivers/colors';
import PropTypes from 'prop-types';
import { Box, Typography, Button, ThemeProvider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

const CapacityReserveConfirmationDrawer = ({ open, onClose, onOpen }) => {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Drawer
        data-testid="capacity-reserve-confirmation-drawer"
        open={open}
        anchor="bottom"
        onClose={onClose}
        onOpen={onOpen}
        PaperProps={{
          style: {
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            padding: '1.5rem'
          }
        }}
        bgcolor={colors.neutrals.white.pure}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          color={colors.neutrals.typeface.primary}
          pb="1.5rem"
          gridGap="0.5rem"
        >
          <Typography variant="subtitle1">
            <Box fontWeight="fontWeightMedium">
              Oferta programada com sucesso!
            </Box>
          </Typography>
          <Typography variant="body2">
            Você tem uma rota ativa, finalize antes de começar a oferta
            programada.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Button
            data-testid="capacity-reserve-confirmation-close-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
          >
            Ok, entendi
          </Button>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

CapacityReserveConfirmationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func
};

CapacityReserveConfirmationDrawer.defaultProps = {
  onOpen: () => ({})
};

export default CapacityReserveConfirmationDrawer;
