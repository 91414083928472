import { createPickupProblemEvidence } from 'operations/pickup/pickup-service';
import doUploadFileToS3 from 'operations/update-status/upload-file-s3';
import pickupEvidenceDb from './pickup-evidence-db';
import { messages } from './constants';

export const savePickupEvidence = async pickupId => {
  const evidence = await pickupEvidenceDb.getByPickupId(pickupId);

  if (!evidence) {
    return Promise.reject(new Error(messages.noEvidenceError));
  }

  return doUploadFileToS3(evidence.image, evidence.url, evidence.contentType)
    .then(() =>
      createPickupProblemEvidence({
        pickupId,
        problemSlug: evidence.problemSlug,
        collectedTime: evidence.created
      })
    )
    .then(() => pickupEvidenceDb.deleteByPickupId(pickupId));
};

export default { savePickupEvidence };
