export const CATEGORIES = {
  dontWantTheOffer: 'dont_want_the_offer',
  willNotBeAbleToCollect: 'will_not_be_able_to_collect',
  hadAProblemWithClient: 'had_a_problem_with_client'
};

export const LABELS = {
  categorySelection: {
    title: 'Desalocar da coleta',
    subtitle: 'Conte para gente o que aconteceu.'
  },
  problemSelection: {
    [CATEGORIES.dontWantTheOffer]: {
      subtitle: 'Conte mais o que aconteceu com a oferta.'
    },
    [CATEGORIES.willNotBeAbleToCollect]: {
      subtitle: 'Conte mais o que aconteceu com você.'
    },
    [CATEGORIES.hadAProblemWithClient]: {
      subtitle: 'Conte mais o que aconteceu no cliente.'
    }
  },
  problemConfirmation: {
    defaultSubtitle:
      'Tudo bem. Se quiser, você pode dar mais detalhes do que aconteceu.',
    [CATEGORIES.hadAProblemWithClient]: {
      subtitle:
        'Tudo bem. Se quiser, dê mais detalhes para ajudar o time de suporte a analisar o caso.'
    },
    infobox: {
      title: 'É bom saber!',
      subtitle:
        'Informar o motivo ajuda a melhorar a experiência de coletar pacotes.'
    }
  },
  successMessage: 'Você foi desalocado da coleta.',
  genericError: 'Eita! Algo deu errado. Você pode tentar de novo daqui a pouco.'
};
