import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

export default function InfoBox({ showIcon, title, subtitle }) {
  const theme = useTheme();
  const { colors, typography } = theme;

  return (
    <Box
      borderRadius={8}
      mx={3}
      p={2.5}
      display="flex"
      bgcolor={colors.neutrals.shapes.lighter}
    >
      {showIcon && (
        <Box>
          <InfoRoundedIcon
            fontSize="small"
            data-testid="info-icon"
            fill={colors.neutrals.shapes.dark}
          />
        </Box>
      )}

      <Box pl={1.5} flexDirection="column" justifyContent="center">
        <Box>
          <Typography
            variant="body2"
            style={{ fontWeight: typography.fontWeightBold }}
          >
            {title}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body2">{subtitle}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showIcon: PropTypes.bool
};

InfoBox.defaultProps = {
  showIcon: false
};
