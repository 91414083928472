import React from 'react';
import { useMachine } from '@xstate/react';
import { Switch, useRouteMatch, Route, useHistory } from 'react-router-dom';
import SharedPropTypes from 'view/shared-prop-types';
import PickupProblems from './pickup-problems';
import PickupProblemDetails from './pickup-problem-details';

import PickupProblemsMachine from './pickup-problems.machine';

export default function PickupProblemsPage({ pickup }) {
  const { path } = useRouteMatch();
  const history = useHistory();
  /* eslint-disable no-unused-vars */
  const [current, _, service] = useMachine(
    PickupProblemsMachine.withContext({
      problems: [],
      pickup
    })
  );

  const goBack = () => history.goBack();

  return (
    <Switch>
      <Route exact path={path}>
        <PickupProblems pickupProblemsMachine={service} handleBack={goBack} />
      </Route>
      <Route
        path={`${path}${PickupProblemDetails.url}`}
        render={({ match }) => {
          const { params } = match;
          const { problemCode } = params;
          const problem = current.context.problems.find(
            p => p.slug === problemCode
          );

          return (
            <PickupProblemDetails
              problem={problem}
              handleBack={goBack}
              pickup={pickup}
            />
          );
        }}
      />
    </Switch>
  );
}

PickupProblemsPage.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired
};
