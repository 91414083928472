import React from 'react';
import SharedPropTypes from 'view/shared-prop-types';
import { ListItem, ButtonBase, Box, Typography, Chip } from '@material-ui/core';
import {
  colors,
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import ArrowIcon from '@material-ui/icons/NavigateNext';
import DoneIcon from '@material-ui/icons/Done';
import DeadlineTime from 'view/molecules/assignment-row/deadline-time';
import { ThemeProvider } from '@material-ui/core/styles';
import { splitLoggiAddress, buildLoggiAddressV2 } from 'view/utils';
import { pickupStateEnum } from 'operations/pickup/pickup-state-db';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitches
} from 'operations/feature-switches';
import { ArrowForward } from '@material-ui/icons';
import { isAssignmentWaypointPickup } from './utils';
import AssignmentIndex from '../assignment-row/assignment-index';

function AssignmentWaypointRow({ assignmentWaypoint }) {
  const address = buildLoggiAddressV2(assignmentWaypoint.destination);
  const { addressTitle, addressBody } = splitLoggiAddress(address);
  const pickupCompleted =
    assignmentWaypoint.pickupState === pickupStateEnum.completed;
  const disabled = pickupCompleted;
  const isPickup = isAssignmentWaypointPickup(assignmentWaypoint);
  const shouldShowCapacityReserveDetails =
    isPickup && assignmentWaypoint?.assignments[0]?.isCapacityReserve;
  const startTime = assignmentWaypoint?.startTime || '';
  const deadlineTime = assignmentWaypoint.deadlineTime || '';
  const enableNewAssignmentList = useFeatureSwitch(
    featureSwitches.enableNewAssignmentList
  );
  const enableNewAssignmentListByDriverRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewAssignmentList
  );

  return (
    <>
      {enableNewAssignmentListByDriverRelation ? (
        <ThemeProvider
          theme={getThemeForUserType({
            userType: userTypeEnum.drivers,
            version: availableThemeVersions.drivers.v2
          })}
        >
          <Box clone>
            <ListItem alignItems="flex-start" style={{ padding: 0 }}>
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                mt={2.5}
                ml={2.5}
                mr={2.5}
                borderRadius={8}
                border={1}
                borderColor={colors.smoke[200]}
                bgcolor={disabled && colors.smoke[50]}
              >
                <ButtonBase
                  onClick={assignmentWaypoint.actions.goToDetails}
                  style={{ width: '100%' }}
                  disabled={disabled}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    p={2.5}
                  >
                    <Box display="flex">
                      <Box width="100%" textAlign="left">
                        <Box
                          display="flex"
                          mb={1.5}
                          justifyContent="space-between"
                        >
                          <AssignmentIndex
                            assignmentIndex={assignmentWaypoint.displayId}
                            shouldShowScheduledPickupIcon={
                              shouldShowCapacityReserveDetails
                            }
                            useNewTheme={
                              enableNewAssignmentListByDriverRelation
                            }
                          />
                          {pickupCompleted && (
                            <Box pb={1}>
                              <Chip
                                size="small"
                                label="Coletado"
                                deleteIcon={<></>}
                                style={{
                                  backgroundColor: colors.green[500],
                                  color: colors.root[0]
                                }}
                                onDelete={() => ({})}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box
                            color={colors.smoke[900]}
                            textAlign="left"
                            width="100%"
                          >
                            <Box display="flex" justifyContent="space-between">
                              <DeadlineTime
                                isPickup={isPickup}
                                deadlineTime={deadlineTime}
                                startTime={startTime}
                                showStartTime={shouldShowCapacityReserveDetails}
                                showNewDeadlineTime={
                                  enableNewAssignmentListByDriverRelation
                                }
                              />
                              {!pickupCompleted && (
                                <Box mt={1}>
                                  <ArrowForward />
                                </Box>
                              )}
                            </Box>
                            <Box mt={1.25} color={colors.smoke[800]}>
                              <Typography
                                variant="body1"
                                style={{ color: '#666666' }}
                              >
                                {addressTitle} {addressBody}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ButtonBase>
              </Box>
            </ListItem>
          </Box>
        </ThemeProvider>
      ) : (
        <Box bgcolor={disabled && colors.smoke[50]} clone>
          <ListItem
            divider={!!assignmentWaypoint}
            alignItems="flex-start"
            style={{ padding: 0 }}
          >
            <ButtonBase
              onClick={assignmentWaypoint.actions.goToDetails}
              style={{ width: '100%' }}
              disabled={disabled}
            >
              <Box display="flex" flexDirection="column" flexGrow={1} p={2.5}>
                <Box display="flex">
                  <AssignmentIndex
                    assignmentIndex={assignmentWaypoint.displayId}
                    shouldShowScheduledPickupIcon={
                      shouldShowCapacityReserveDetails
                    }
                  />
                  {/* if assignments waypoints have statuses, it should go here */}

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box color={colors.smoke[900]} textAlign="left">
                      {pickupCompleted && (
                        <Box pb={1}>
                          <Chip
                            size="small"
                            label="Coleta concluída"
                            deleteIcon={
                              <DoneIcon style={{ color: colors.root[0] }} />
                            }
                            style={{
                              backgroundColor: colors.green[500],
                              color: colors.root[0]
                            }}
                            onDelete={() => ({})}
                          />
                        </Box>
                      )}
                      <DeadlineTime
                        isPickup={isPickup}
                        deadlineTime={deadlineTime}
                        startTime={startTime}
                        showStartTime={shouldShowCapacityReserveDetails}
                        showNewDeadlineTime={false}
                      />
                      <Typography variant="subtitle1">
                        {addressTitle}
                      </Typography>
                      <Box
                        component="div"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        fontSize={14}
                        maxWidth={205}
                        mt={1}
                      >
                        <Box component="span">{addressBody}</Box>
                      </Box>
                    </Box>

                    {!pickupCompleted && (
                      <Box mt={1.25} color={colors.blue[500]}>
                        <ArrowIcon />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </ButtonBase>
          </ListItem>
        </Box>
      )}
    </>
  );
}

AssignmentWaypointRow.propTypes = {
  assignmentWaypoint: SharedPropTypes.assignmentWaypoint.isRequired
};

export default AssignmentWaypointRow;
