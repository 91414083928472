import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import DynamicLabelTextField from 'view/atoms/dynamic-label-text-field';
import InfoBox from 'view/atoms/infobox';

export default function OpenFieldForm({
  title,
  subtitle,
  buttonText,
  infoBoxTexts,
  inputLabelsTexts,
  initialValue,
  onGoBack,
  onSubmit
}) {
  const inputRef = useRef(null);

  const submit = () => {
    onSubmit(inputRef.current.value);
  };

  useEffect(() => {
    if (initialValue) {
      inputRef.current.value = initialValue;
      inputRef.current.focus();
    }
  }, [initialValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      {onGoBack && (
        <Box px={2} pt={3}>
          <IconButton
            color="inherit"
            onClick={onGoBack}
            data-testid="open-field-form-back-button"
          >
            <ArrowBack />
          </IconButton>
        </Box>
      )}
      <Box px={4}>
        {(title || subtitle) && (
          <>
            {title && (
              <Typography variant="h6" data-testid="open-field-form-title">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant="body2"
                style={{ marginTop: 14 }}
                data-testid="open-field-form-subtitle"
              >
                {subtitle}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box px={4} py={3}>
        <DynamicLabelTextField
          inputRef={inputRef}
          fullWidth
          color="primary"
          variant="outlined"
          label={inputLabelsTexts.label}
          focusedLabel={inputLabelsTexts.focusedLabel}
          filledLabel={inputLabelsTexts.focusedLabel}
          type="text"
          multiline
          rows="5"
        />
      </Box>
      <Box px={1}>
        <InfoBox
          title={infoBoxTexts.title}
          subtitle={infoBoxTexts.subtitle}
          showIcon
        />
      </Box>
      <Box px={4} py={3}>
        <Button
          size="large"
          fullWidth
          color="primary"
          variant="contained"
          onClick={submit}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}

OpenFieldForm.defaultProps = {
  title: '',
  subtitle: '',
  infoBoxTexts: {
    title: 'É bom saber!',
    subtitle: 'Informar o motivo ajuda a melhorar a experiência'
  },
  inputLabelsTexts: {
    label: 'Informe aqui o motivo, se quiser',
    focusedLabel: 'Motivo'
  },
  initialValue: null,
  onGoBack: null
};

OpenFieldForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  infoBoxTexts: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
  inputLabelsTexts: PropTypes.shape({
    label: PropTypes.string,
    focusedLabel: PropTypes.string
  }),
  initialValue: PropTypes.string,
  onGoBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};
