import React from 'react';

import { useHistory, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMachine } from '@xstate/react';
import { Machine, assign, spawn, send } from 'xstate';
import { Cancel } from '@material-ui/icons';
import { colors } from '@loggi/mar';

import Alert from 'view/atoms/alert';

import { PAGES } from 'view';
import DeliveryOptions from 'view/pages/delivery-options/index';
import { getLastKnownLocation } from 'operations/geolocation/geolocation';
import computeDistanceBetween from 'geolib/es/getDistance';
import GeolocationPermission from 'view/templates/geolocation-permission';

import { DELIVERY_OPTIONS_MAP } from 'view/pages/delivery-options/constants';

import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import Signature, { signatureMachine } from '../signature';
import Recipient, { recipientMachine } from '../recipient';
import PackageSync, { packageSyncMachine } from '../package-sync';
import FacadePhoto from '../../pages/facade-photo';
import { shouldNotifyDriverAboutDistance } from '../driver-distance-from-delivery-point/utils';
import DriverDistanceFromDeliveryPointTemplate from '../driver-distance-from-delivery-point';

const protocolMachine = Machine(
  {
    id: 'protocol',
    initial: 'checkDriverLocation',
    context: {
      notificationMessage: '',

      // machine refs
      signatureMachineRef: null,
      recipientMachineRef: null,
      packageSyncMachineRef: null,

      // data
      status: 2, // delivered
      process: 'delivery',
      destination: 'delivered',
      signature: null,
      isExpectedRecipient: null,
      pkg: null,
      name: null,
      document: null,
      tokenAttempts: null,
      deliveryOption: null,
      canDeliveryMailbox: null,
      deliveryEvidenceSignedUrl: null,
      digitalSignatureSignedUrl: null,
      canShowDeliveryOptions: null,
      canCollectDigitalSignatureAsDefault: null,
      geoFenceRadius: 0,
      markDeliveredPkg: false
    },
    states: {
      checkDriverLocation: {
        on: {
          '': [
            {
              cond: context => {
                const { driverLastKnownLocation } = context;

                return !driverLastKnownLocation;
              },
              target: 'showGeolocationError'
            },
            // On failure when the package can be delivered to the mailbox,
            // the delivery person will be notified and directed to the
            // facadePhoto status if he chooses to make the delivery.
            {
              cond: context => {
                const { deliveryOption } = context;

                return (
                  deliveryOption?.id ===
                  DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id
                );
              },
              target: 'facadePhoto'
            },
            {
              cond: context => {
                const {
                  driverCoords,
                  destinationCoords,
                  hasSafeDelivery,
                  geoFenceRadius
                } = context;

                return shouldNotifyDriverAboutDistance(
                  driverCoords,
                  destinationCoords,
                  hasSafeDelivery,
                  geoFenceRadius
                );
              },
              target: 'driverIsOutsideDestinationAreaRadius'
            },
            {
              cond: 'hasDeliveryOptions',
              target: 'deliveryOptions'
            },
            {
              target: 'recipient'
            }
          ]
        }
      },
      showGeolocationError: {},
      driverIsOutsideDestinationAreaRadius: {},
      recipient: {
        entry: ['spawnRecipientMachine'],
        on: {
          BACK: [
            {
              cond: 'hasDeliveryOptions',
              target: 'deliveryOptions'
            },
            {
              actions: ['goBack']
            }
          ],
          'RECIPIENT.INFO': [
            {
              target: 'signature',
              cond: 'hasDigitalSignatureAsDefault',
              actions: ['saveRecipientData']
            },
            {
              target: 'facadePhoto',
              cond: 'hasDeliveryEvidenceSignedUrl',
              actions: ['saveRecipientData']
            },
            {
              target: 'packageSync',
              actions: ['saveRecipientDataWithoutDigitalSignature']
            }
          ]
        }
      },
      signature: {
        entry: ['spawnSignatureMachine'],
        on: {
          'SIGNATURE.INFO': {
            target: 'packageSync',
            actions: ['saveSignatureData']
          },
          BACK: [
            {
              cond: 'hasDigitalSignatureAsDefault',
              target: 'recipient'
            },
            {
              target: 'facadePhoto'
            }
          ]
        }
      },
      packageSync: {
        entry: ['spawnPackageSyncMachine'],
        on: {
          BACK: 'recipient',
          SET_NOTIFICATION: {
            actions: assign({
              notificationMessage: (_, event) => event.data.message
            })
          }
        }
      },
      facadePhoto: {
        on: {
          BACK: [
            {
              cond: context => {
                const { deliveryOption } = context;
                if (deliveryOption == null) {
                  return false;
                }
                return (
                  deliveryOption.id === DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id
                );
              },
              target: 'deliveryOptions'
            },
            {
              target: 'recipient'
            }
          ],
          CANNOT_TAKE_A_PHOTO: {
            target: 'signature'
          }
        }
      },
      deliveryOptions: {
        on: {
          CHOOSE_DELIVERY_OPTION: {
            actions: [
              assign({
                deliveryOption: (_, event) => event
              }),
              send(context => {
                const { id } = context.deliveryOption;
                const type =
                  id === DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id
                    ? 'FACADE_PHOTO'
                    : 'RECIPIENT';
                return {
                  type
                };
              })
            ]
          }
        }
      }
    },
    on: {
      CLEAN_NOTIFICATION: {
        actions: assign({
          notificationMessage: null
        })
      },
      FACADE_PHOTO: {
        target: 'facadePhoto'
      },
      DELIVERY_OPTIONS: {
        target: 'deliveryOptions'
      },
      RECIPIENT: {
        target: 'recipient'
      }
    }
  },
  {
    actions: {
      goBack: () => {
        throw new Error(
          'this must be passed down from whom is instantiating the machine'
        );
      },
      spawnRecipientMachine: assign({
        recipientMachineRef: context => {
          return spawn(
            recipientMachine.withContext({
              pkg: context.pkg,
              deliveryOption: context.deliveryOption
            }),
            { sync: true }
          );
        }
      }),
      spawnSignatureMachine: assign({
        signatureMachineRef: context => {
          return spawn(
            signatureMachine.withContext({
              signature: context.signature,
              digitalSignatureSignedUrl: context.digitalSignatureSignedUrl
            }),
            { sync: true }
          );
        }
      }),
      spawnPackageSyncMachine: assign({
        packageSyncMachineRef: context => {
          let receiverSignature = context.signature;
          if (context.digitalSignatureSignedUrl) {
            receiverSignature = '';
          }
          return spawn(
            packageSyncMachine.withContext({
              ...context.pkg,
              status: context.status,
              receiverName: context.name,
              receiverDocument: context.document,
              receiverDocumentType: context.documentType,
              notes: context.notes,
              recipientRelationship: context.recipientRelationship,
              receiverSignature,
              process: context.process,
              destination: context.destination,
              safeDeliveryTokenAttempts: context.tokenAttempts,
              deliveryOption: context.deliveryOption,
              // we must return the state machine context deliveryEvidenceSignedUrl,
              // when there is a digital signature its value will be null
              deliveryEvidenceSignedUrl: context.deliveryEvidenceSignedUrl,
              digitalSignatureSignedUrl: context.digitalSignatureSignedUrl,
              markDeliveredPkg: context.markDeliveredPkg
            }),
            { sync: true }
          );
        }
      }),
      saveRecipientData: assign((_, { data }) => {
        const {
          name,
          document,
          documentType,
          notes,
          recipientRelationship
        } = data;
        return { name, document, documentType, notes, recipientRelationship };
      }),
      saveRecipientDataWithoutDigitalSignature: assign((_, { data }) => {
        const {
          name,
          document,
          documentType,
          notes,
          recipientRelationship
        } = data;
        return {
          name,
          document,
          documentType,
          notes,
          recipientRelationship,
          digitalSignatureSignedUrl: null
        };
      }),
      saveSignatureData: assign((_, { data }) => {
        const { signature } = data;
        return { signature, deliveryEvidenceSignedUrl: null };
      })
    },
    guards: {
      hasDeliveryEvidenceSignedUrl: context => {
        return !!context.pkg.deliveryEvidenceSignedUrl;
      },
      hasDeliveryOptions: context => {
        return (
          (context.pkg.canDeliveryMailbox &&
            context.pkg.deliveryEvidenceSignedUrl) ||
          context.pkg.canShowDeliveryOptions
        );
      },
      hasDigitalSignatureAsDefault: context => {
        return context.pkg.canCollectDigitalSignatureAsDefault;
      }
    }
  }
);

function Protocol() {
  const location = useLocation();
  const { pkg, tokenAttempts, deliveryOption } = location.state || {};

  const changeStatusDeliveredPackageForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageByCompanyRelation
    )
  );

  const changeStatusDeliveredPackageForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageForDriverLastMileCompany
    )
  );

  const changeStatusDeliveredPackages =
    changeStatusDeliveredPackageForCompanyRelation &&
    changeStatusDeliveredPackageForDriverLMC;

  const history = useHistory();

  if (!pkg) return <Redirect to={PAGES.PACKAGE_LIST} />;

  const driverLastKnownLocation = getLastKnownLocation();

  const {
    safeDelivery = '',
    deliveryEvidenceSignedUrl = '',
    canDeliverySignature = false,
    canCollectDigitalSignatureAsDefault = true,
    geoFenceRadius = 0
  } = pkg;

  const hasSafeDelivery = !!safeDelivery;

  const driverCoords = {
    lat: driverLastKnownLocation?.latitude,
    lng: driverLastKnownLocation?.longitude
  };

  const destinationCoords = {
    lat:
      pkg?.destination?.lat || pkg?.addressDestination?.coordinates?.latitude,
    lng:
      pkg?.destination?.lng || pkg?.addressDestination?.coordinates?.longitude
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [current, sendEvent] = useMachine(
    protocolMachine.withContext({
      ...protocolMachine.context,

      // data
      pkg,
      name: pkg.recipient.name,
      document: pkg.recipient.document,
      tokenAttempts,
      driverLastKnownLocation,
      driverCoords,
      destinationCoords,
      hasSafeDelivery,
      canDeliveryMailbox: pkg.canDeliveryMailbox,
      deliveryEvidenceSignedUrl: pkg.deliveryEvidenceSignedUrl,
      digitalSignatureSignedUrl: pkg.digitalSignatureSignedUrl,
      deliveryOption,
      canShowDeliveryOptions: pkg.canShowDeliveryOptions,
      canCollectDigitalSignatureAsDefault,
      geoFenceRadius,
      markDeliveredPkg: changeStatusDeliveredPackages
    }),
    {
      devTools: true,
      actions: {
        goBack: history.goBack
      }
    }
  );

  const cleanNotificationAction = () => sendEvent('CLEAN_NOTIFICATION');

  const continueAction = () => {
    const event =
      (current.context.pkg?.canDeliveryMailbox && deliveryEvidenceSignedUrl) ||
      pkg.canShowDeliveryOptions
        ? 'DELIVERY_OPTIONS'
        : 'RECIPIENT';

    sendEvent(event);
  };

  const chooseDeliveryOptionAction = option =>
    sendEvent('CHOOSE_DELIVERY_OPTION', option);

  const sendToPackageDetails = () =>
    history.push(PAGES.PACKAGE_DETAILS.replace(':packageId', pkg.packageId));

  const facadePhotoGoBackEvent = () => sendEvent('BACK');
  const facadePhotoGoFallbackEvent = () => sendEvent('CANNOT_TAKE_A_PHOTO');
  const signatureGoBackEvent = () => sendEvent('BACK');

  return (
    <>
      <Alert
        open={!!current.context.notificationMessage}
        onClose={cleanNotificationAction}
        message={current.context.notificationMessage}
        color={colors.red[700]}
        startAdornment={<Cancel fontSize="large" />}
      />

      {current.value === 'recipient' && (
        <Recipient recipientMachineRef={current.context.recipientMachineRef} />
      )}

      {current.value === 'packageSync' && (
        <PackageSync
          packageSyncMachineRef={current.context.packageSyncMachineRef}
        />
      )}

      {current.value === 'facadePhoto' && (
        <FacadePhoto
          onGoBack={facadePhotoGoBackEvent}
          onGoFallback={
            canDeliverySignature ? facadePhotoGoFallbackEvent : undefined
          }
          facadePhotoContext={current.context}
        />
      )}

      {current.value === 'signature' && (
        <Signature
          onGoBack={signatureGoBackEvent}
          signatureMachineRef={current.context.signatureMachineRef}
          digitalSignatureContext={current.context}
        />
      )}

      {current.value === 'showGeolocationError' && <GeolocationPermission />}

      {current.value === 'driverIsOutsideDestinationAreaRadius' && (
        <DriverDistanceFromDeliveryPointTemplate
          onGoBack={history.goBack}
          onClick={continueAction}
          distance={computeDistanceBetween(driverCoords, destinationCoords)}
          geoFenceRadius={geoFenceRadius}
          driverCoords={driverCoords}
          pkg={pkg}
        />
      )}

      {current.value === 'deliveryOptions' && (
        <DeliveryOptions
          pkg={pkg}
          onOptionSelection={chooseDeliveryOptionAction}
          onGoBack={sendToPackageDetails}
        />
      )}
    </>
  );
}

export default Protocol;
