import Dexie from 'dexie';
import wrapOperationsInDexieErrorHandler from 'operations/handle-dexie-errors';

/** @type {Dexie} */
const db = new Dexie('assignmentList', {
  chromeTransactionDurability: 'relaxed'
});

db.version(1).stores({
  assignmentList: `
        ++id
     `
});

/**
 * @typedef {Object} AssignmentList
 * @property {Array<Pickup>} AssignmentList.pickups
 * @property {Array<PackageMutation>} AssignmentList.packages */

/** @returns {Promise<AssignmentList>} */
const getAssignmentList = async () =>
  db
    .table('assignmentList')
    .toArray()
    .then(array => array[0]);

/**
 * @typedef {Object} AssignmentList
 * @property {Array<Pickup>} AssignmentList.pickups
 * @property {Array<PackageMutation>} AssignmentList.packages */

/**
 * @param {Objects} params
 * @param {Array<Package>} params.packages
 * @param {Array<Pickup>} params.pickups
 * @returns {Promise<AssignmentList>} */
const saveAssignmentList = async ({
  packages,
  pickups,
  assignmentsWaypoints
}) =>
  // always write to primary key 1, because we want to store <= 1 assignmentList
  db.table('assignmentList').put({
    id: 1,
    localTime: Date.now(),
    packages,
    pickups,
    assignmentsWaypoints
  });

/** @returns {Promise<void>} */
const clearDB = async () => db.table('assignmentList').clear();

export default wrapOperationsInDexieErrorHandler({
  dbName: 'assignmentList',
  operations: {
    getAssignmentList,
    saveAssignmentList,
    clearDB
  }
});
