import getThemeDrivers, {
  availableThemeVersion as availableDriversThemeVersion
} from './themes/drivers/index';
import theme from './theme';

export const userTypeEnum = {
  drivers: 'drivers'
};

export const availableThemeVersions = {
  drivers: availableDriversThemeVersion,
  default: 'default'
};

const getThemeForUserType = ({
  userType,
  version = availableThemeVersions.default
}) => {
  if (userType === userTypeEnum.drivers) {
    return getThemeDrivers({ version });
  }
  return theme;
};
export default getThemeForUserType;
