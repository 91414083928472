import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import firebase from 'firebase/app';
import 'firebase/remote-config';

import FinishDelivering from 'view/molecules/finish-delivering';

import {
  ProgressInfo,
  OverlayingCard,
  ContainerHeaderInfo
} from './components';

export default function EmptyListState({ updateList, children }) {
  const remoteConfig = firebase.remoteConfig();
  const [canTryAutoFinish, setCanTryAutoFinish] = useState(false);

  useEffect(() => {
    remoteConfig.fetchAndActivate();

    setCanTryAutoFinish(
      remoteConfig.getValue('enable_auto_finish_shipment').asBoolean()
    );
  }, [remoteConfig]);

  return (
    <>
      <ContainerHeaderInfo>
        <ProgressInfo packages={[]} pickups={[]} updateList={updateList} />
      </ContainerHeaderInfo>

      <OverlayingCard>
        <FinishDelivering canTryAutoFinish={canTryAutoFinish} />
      </OverlayingCard>
      {children}
    </>
  );
}

EmptyListState.propTypes = {
  updateList: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};
