/**
 * This map is required because the package statusCode
 * differs from when we send it and when we receice it.
 *
 * Sent:
 *  - Delivered: 1
 *  - Recipient Unavailable: 2
 *  - Invalid destination: 3
 *  - Refused: 4
 *
 * Received:
 *  - Delivered: 2
 *  - Recipient Unavailable: 12
 *  - Invalid destination: 13
 *  - Refused: 11
 */
export const statusCodes = {
  UNAVAILABLE: 0,
  DELIVERED: 2,
  PICKUP: 3,
  RECIPIENT_UNAVAILABLE: 12,
  INVALID_DESTINATION: 13,
  REFUSED: 11,
  UNAVAILABLE_SAFE_DELIVERY_TOKEN: 117
};

export const finalStatuses = {
  DELIVERED: true
};

export const statusDescriptions = {
  DELIVERED: 'Entrega feita! \uD83D\uDE0E',
  RECIPIENT_UNAVAILABLE: 'Não encontrei a pessoa',
  INVALID_DESTINATION: 'Não encontrei o endereço',
  REFUSED: 'Recusaram o pacote',
  UNAVAILABLE_SAFE_DELIVERY_TOKEN: 'Código de Entrega Segura Inválido'
};

/**
 * Object.fromEntries polyfill
 * @see https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_frompairs
 *
 * @param {Array} arr
 * @returns {Object}
 * @private
 */
const fromPairs = arr =>
  // eslint-disable-next-line
  arr.reduce((acc, val) => ((acc[val[0]] = val[1]), acc), {});

/** @param {Object} obj
 *  @returns {Object} - object where keys become values and values become keys */
export function reverseObject(obj) {
  const entries = Object.entries(obj);
  const reversedEntries = entries.map(entry => entry.reverse());
  return fromPairs(reversedEntries);
}

/** @param {Object} params
 *  @param {string} params.sentOrReceived - either 'sent' or 'received'
 *  @param {number} params.statusCode
 *  @returns {string} - corresponding status description */
export function getStatusDescriptionByStatusCode({ statusCode }) {
  const statusKey = reverseObject(statusCodes)[statusCode];
  return statusDescriptions[statusKey];
}

/** @param {Object} params
 *  @param {string} params.sentOrReceived - either 'sent' or 'received'
 *  @param {number} params.statusCode
 *  @returns {number} - corresponding status precedence */
export function isFinalPackageStatusCode({ statusCode }) {
  const statusKey = reverseObject(statusCodes)[statusCode];
  return statusKey in finalStatuses;
}
