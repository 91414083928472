import React from 'react';
import firebase from 'firebase/app';
import {
  Switch,
  useRouteMatch,
  useLocation,
  useParams,
  Route,
  useHistory
} from 'react-router-dom';
import { Redirect } from 'react-router';

import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { skipSurveyKey } from 'hooks/useSurvey';
import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForDriverCompanyTypeRelation
} from 'operations/feature-switches';
import {
  getClientDocument,
  clearClientDocument
} from 'operations/client-document';
import { PAGES } from 'view';
import { getCurrentUser } from 'operations/auth';
import { getPickupHadTypedBarcodes } from 'operations/pickup';
import sendEventToAnalytics from 'operations/firebase';
import { isEmptyObject } from 'view/utils';
import PickupDetails from './pickup-details';
import PickupCode from './pickup-code';
import PickupAutomaticFill from './pickup-automatic-fill';
import PickupProblems from './problems/pickup-problems';
import PickupPackages from './pickup-packages';
import PickupProblemsPage from './problems';
import PickupProblemsPageV2 from './pickup-problems-v2';
import BipInform from './pickup-bip-inform';
import PickupScanner from './pickup-scanner';
import PickupScannerV2 from './pickup-scanner-v2';
import PickupPackagesList from './pickup-packages-list';
import UncollectedVolumesReport from './uncollected-volumes-report';
import PickupSummary from './summary';
import PickupCheckPackages from './pickup-check-packages';
import PickupSenderDocumentPage from './pickup-sender-document';
import PickupScanningInstructions from './pickup-scanning-instructions';
import {
  pickupFlowEnum,
  PICKUP_TYPES,
  TAG_PICKUP_SURVEY,
  barcodesAllowedFormats
} from './constants';
import PickupSurvey from './pickup-survey';

const isPickupWithCargos = pickup => {
  return pickup?.assignments?.[0]?.cargos?.length > 0;
};

export default function Pickup() {
  const { pickupCode } = useParams();
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const pickup = location.state;
  const [packages, setPackages] = usePersistedState(
    `${persistedStateKeys.pickupScannedPackages}/${pickupCode}`,
    []
  );
  const pickupHadTypedBarcodes = getPickupHadTypedBarcodes(pickupCode);

  const driversLMCsEnabledToNewPickupFlowByRelation = useFeatureSwitch(
    featureSwitches.enableNewPickupScannerFlowForDriverByRelation
  );

  const isNewScannerFlowEnabledForRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    driversLMCsEnabledToNewPickupFlowByRelation
  );

  const isNewPickupProblemsPageEnabled = useFeatureSwitch(
    featureSwitches.enableNewPickupProblemsPage
  );

  // Phase 3 FSs init
  const driversLMCsToPhaseThree = useFeatureSwitch(
    featureSwitches.enableNewPickupScannerFlowPhaseThree
  );
  const isPhaseThreeEnabledForLMC = featureSwitchEnabledForDriverLMC(
    driversLMCsToPhaseThree
  );
  const isPhaseThreeEnabled =
    isPhaseThreeEnabledForLMC && isNewScannerFlowEnabledForRelation;
  // Phase 3 FSs end

  const shouldGoToNewFlow =
    isNewScannerFlowEnabledForRelation && isPickupWithCargos(pickup);

  const isNewBeyondFlowEnabled = useFeatureSwitch(
    featureSwitches.enableNewFlowBeyond
  );

  const isRedirectEnabledWhenNoPickupFound = useFeatureSwitch(
    featureSwitches.enablePickupRouteRedirect
  );

  const multibarcodesFormatPackages = packages.map(item =>
    item.barcode
      ? { ...item, barcode: undefined, barcodes: [item.barcode] }
      : item
  );

  if (pickup && pickup.pickupFlow === pickupFlowEnum.automaticFill) {
    const assignmentsCargos = pickup.assignments[0].cargos;
    const alreadyDefinedBarcodes = multibarcodesFormatPackages.map(
      item => item.barcodes
    );
    const flatArray = [].concat(...alreadyDefinedBarcodes);
    assignmentsCargos.forEach(item => {
      if (!flatArray.includes(item.code)) {
        multibarcodesFormatPackages.push({ barcodes: [item.code] });
      }
    });
  }

  const [skipScanningTutorial, setSkipScanningTutorial] = usePersistedState(
    persistedStateKeys.skipPickupScanningTutorial,
    false
  );

  const pathWithArgs = path.replace(':pickupCode', pickupCode);

  const shipperName = getClientDocument().name;
  const tag = TAG_PICKUP_SURVEY.SUCCESS;
  const packagesIds = packages.map(item => item.packageId);
  const { email: driverEmail, id: driverId } = getCurrentUser() || {};

  const getUnidentifiedVolumes = () => {
    if (!isPickupWithCargos(pickup)) return [];
    const scannedPackages = new Set(
      multibarcodesFormatPackages.map(pkg => pkg.loggiKey)
    );
    return pickup.assignments[0].cargos.filter(
      v => !scannedPackages.has(v.loggiKey)
    );
  };

  const pathsThatDoesntRequirePickupObject = [
    `${pathWithArgs}${PickupSenderDocumentPage.url}${PickupSurvey.url}`
  ];
  if (
    (!pickup || isEmptyObject(pickup)) &&
    !pathsThatDoesntRequirePickupObject.includes(location.pathname) &&
    isRedirectEnabledWhenNoPickupFound
  ) {
    sendEventToAnalytics('pickup_route_loaded_without_pickup_in_state', {
      driverId,
      driverEmail,
      pickupCode
    });
    return <Redirect to={PAGES.PACKAGE_LIST} />;
  }

  return (
    <Switch>
      <Route exact path={pathWithArgs}>
        <PickupDetails
          pickup={pickup}
          handleNextPage={() => {
            if (pickup.pickupFlow === pickupFlowEnum.automaticFill) {
              history.push(`${pathWithArgs}${PickupAutomaticFill.url}`, pickup);
            } else {
              history.push(`${pathWithArgs}${PickupCode.url}`, pickup);
            }
          }}
          handleProblemsPage={() => {
            history.push(`${pathWithArgs}${PickupProblems.url}`, pickup);
          }}
        />
      </Route>
      <Route exact path={`${pathWithArgs}${PickupCode.url}`}>
        <PickupCode
          pickupFlow={pickup?.pickupFlow}
          pickupCode={pickupCode}
          onContinue={() => {
            if (pickup.pickupFlow === pickupFlowEnum.useBarcodeBip) {
              if (!skipScanningTutorial) {
                setSkipScanningTutorial(true);
                /**
                 * Using replace instead of push this way, when user clicks on
                 * go back in other screens they don't fall again in the instructions
                 * page.
                 */
                history.replace(`${pathWithArgs}${BipInform.url}`, pickup);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (shouldGoToNewFlow) {
                  history.push(
                    `${pathWithArgs}${PickupPackagesList.url}`,
                    pickup
                  );
                } else {
                  history.push(`${pathWithArgs}${PickupScanner.url}`, pickup);
                }
              }
            } else {
              history.push(`${pathWithArgs}${PickupPackages.url}`, pickup);
            }
          }}
          onCloseButtonClicked={() => {
            /**
             * Using replace instead of goBack because this is a nested component,
             * and by doing goBack we can fall into a loop of screens pushed.
             * By adding replace, we know exactly where the go back button should go next.
             */
            history.replace(pathWithArgs, pickup);
          }}
        />
      </Route>
      <Route exact path={`${pathWithArgs}${PickupAutomaticFill.url}`}>
        <PickupAutomaticFill
          pickupFlow={pickup?.pickupFlow}
          pickupCode={pickupCode}
          onContinue={() => {
            const url = isNewBeyondFlowEnabled
              ? PickupCheckPackages.url
              : PickupScanner.url;
            history.push(`${pathWithArgs}${url}`, pickup);
          }}
          onCloseButtonClicked={() => {
            history.goBack();
          }}
        />
      </Route>
      {isNewPickupProblemsPageEnabled && (
        <Route path={`${path}${PickupProblemsPageV2.url}`}>
          <PickupProblemsPageV2 pickup={pickup} onGoBack={history.goBack} />
        </Route>
      )}
      {!isNewPickupProblemsPageEnabled && (
        <Route path={`${path}${PickupProblems.url}`}>
          <PickupProblemsPage pickup={pickup} />
        </Route>
      )}
      <Route exact path={`${pathWithArgs}${PickupPackages.url}`}>
        <PickupPackages
          handleBack={() => {
            history.goBack();
          }}
        />
      </Route>
      <Route path={`${path}${PickupScanner.url}`}>
        <PickupScanner
          initialScannedBarcodes={multibarcodesFormatPackages.map(
            pkg => pkg.barcodes
          )}
          initialScannedPackages={multibarcodesFormatPackages}
          saveScannedPackages={setPackages}
          onBarcodesCheckSuccess={() => {
            history.push(`${pathWithArgs}${PickupCheckPackages.url}`, pickup);
          }}
          onGoBack={scannedPackages => {
            setPackages(scannedPackages);
            /**
             * Using replace instead of goBack because this is a nested component,
             * and by doing goBack we can fall into a loop of screens pushed.
             * By adding replace, we know exactly where the go back button should go next.
             */
            history.replace(`${pathWithArgs}${PickupCode.url}`, pickup);
          }}
          persistedStateKey={pickupCode}
          pickup={pickup}
          allowedFormats={barcodesAllowedFormats}
          setOpenInstructions={() => {
            setSkipScanningTutorial(false);
            history.push(`${pathWithArgs}${BipInform.url}`, pickup);
          }}
        />
      </Route>
      <Route exact path={`${path}${PickupScannerV2.url}`}>
        <ThemeProvider
          theme={getThemeForUserType({
            userType: userTypeEnum.drivers,
            version: availableThemeVersions.drivers.v2
          })}
        >
          <PickupScannerV2
            pickup={pickup}
            beepedPackages={multibarcodesFormatPackages}
            saveBeepedPackage={setPackages}
            onGoBack={() => {
              /**
               * Using replace instead of goBack because this is a nested component,
               * and by doing goBack we can fall into a loop of screens pushed.
               * By adding replace, we know exactly where the go back button should go next.
               */
              history.push(`${pathWithArgs}${PickupPackagesList.url}`, pickup);
            }}
            onReviewListClick={() => {
              history.push(`${pathWithArgs}${PickupPackagesList.url}`, pickup);
            }}
            userIdentification={driverId}
          />
        </ThemeProvider>
      </Route>
      <Route exact path={`${path}${PickupPackagesList.url}`}>
        <ThemeProvider
          theme={getThemeForUserType({
            userType: userTypeEnum.drivers,
            version: availableThemeVersions.drivers.v2
          })}
        >
          <PickupPackagesList
            pickup={pickup}
            scannedPackages={multibarcodesFormatPackages}
            packagesToPickup={pickup?.assignments?.[0]?.cargos}
            onGoBack={() => {
              /**
               * Using replace instead of goBack because this is a nested component,
               * and by doing goBack we can fall into a loop of screens pushed.
               * By adding replace, we know exactly where the go back button should go next.
               */
              history.replace(`${pathWithArgs}${PickupCode.url}`, pickup);
            }}
            onStartIdentificationClick={() => {
              history.push(`${pathWithArgs}${PickupScannerV2.url}`, pickup);
            }}
            saveScannedPackages={setPackages}
            onSuccess={() => {
              if (isPhaseThreeEnabled) {
                history.push(
                  `${pathWithArgs}${UncollectedVolumesReport.url}`,
                  pickup
                );
              } else {
                history.push(
                  `${pathWithArgs}${PickupSenderDocumentPage.url}`,
                  pickup
                );
              }
            }}
            userIdentification={driverId}
          />
        </ThemeProvider>
      </Route>
      <Route exact path={`${path}${UncollectedVolumesReport.url}`}>
        <ThemeProvider
          theme={getThemeForUserType({
            userType: userTypeEnum.drivers,
            version: availableThemeVersions.drivers.v2
          })}
        >
          <UncollectedVolumesReport
            pickup={pickup}
            uncollectedVolumes={getUnidentifiedVolumes()}
            onGoBack={() => {
              /**
               * Using replace instead of goBack because this is a nested component,
               * and by doing goBack we can fall into a loop of screens pushed.
               * By adding replace, we know exactly where the go back button should go next.
               */
              history.replace(
                `${pathWithArgs}${PickupPackagesList.url}`,
                pickup
              );
            }}
            onNextClick={() => {
              history.push(`${pathWithArgs}${PickupSummary.url}`, {
                ...pickup,
                [skipSurveyKey]: !pickupHadTypedBarcodes
              });
            }}
            userIdentification={driverId}
          />
        </ThemeProvider>
      </Route>
      <Route exact path={`${path}${PickupSummary.url}`}>
        <ThemeProvider
          theme={getThemeForUserType({
            userType: userTypeEnum.drivers,
            version: availableThemeVersions.drivers.v2
          })}
        >
          <PickupSummary
            pickup={pickup}
            collectedVolumesQuantity={multibarcodesFormatPackages.length}
            uncollectedVolumesQuantity={getUnidentifiedVolumes().length}
            onGoBack={() => {
              /**
               * Using replace instead of goBack because this is a nested component,
               * and by doing goBack we can fall into a loop of screens pushed.
               * By adding replace, we know exactly where the go back button should go next.
               */
              history.replace(
                `${pathWithArgs}${UncollectedVolumesReport.url}`,
                pickup
              );
            }}
            onConfirm={() => {
              history.push(
                `${pathWithArgs}${PickupSenderDocumentPage.url}`,
                pickup
              );
            }}
            userIdentification={driverId}
          />
        </ThemeProvider>
      </Route>
      <Route path={`${path}${BipInform.url}`}>
        <BipInform
          onBackButtonClicked={() => {
            history.goBack();
          }}
          onContinue={() => {
            history.push(
              `${pathWithArgs}${PickupScanningInstructions.url}`,
              pickup
            );
          }}
        />
      </Route>
      <Route path={`${path}${PickupCheckPackages.url}`}>
        <PickupCheckPackages
          pickup={pickup}
          packages={multibarcodesFormatPackages}
          updatePackages={setPackages}
          onRemovePackage={packagesToBeDeleted => {
            const filteredPackages = multibarcodesFormatPackages.filter(
              pkg => pkg.barcodes !== packagesToBeDeleted.barcodes
            );
            if (filteredPackages.length === 0) {
              history.goBack();
            }
            setPackages(filteredPackages);
            firebase.analytics().logEvent('deleted_package', {
              page_title: 'check_packages_ui'
            });
          }}
          goBack={() => {
            history.goBack();
          }}
          onConfirm={() => {
            history.push(
              `${pathWithArgs}${PickupSenderDocumentPage.url}`,
              pickup
            );
          }}
          shouldCheckBarcodesOnConfirm={
            isNewBeyondFlowEnabled &&
            pickup?.pickupFlow === pickupFlowEnum.automaticFill
          }
        />
      </Route>
      <Route exact path={`${path}${PickupSenderDocumentPage.url}`}>
        <PickupSenderDocumentPage
          packages={multibarcodesFormatPackages}
          pickup={pickup}
          driverId={driverId}
        />
      </Route>
      <Route path={`${path}${PickupScanningInstructions.url}`}>
        <PickupScanningInstructions
          onBackButtonClicked={() => {
            history.goBack();
          }}
          onStartBip={() => {
            if (!skipScanningTutorial) {
              setSkipScanningTutorial(true);
            }
            if (shouldGoToNewFlow) {
              history.push(`${pathWithArgs}${PickupPackagesList.url}`, pickup);
            } else {
              history.push(`${pathWithArgs}${PickupScanner.url}`, pickup);
            }
          }}
        />
      </Route>
      <Route
        exact
        path={`${pathWithArgs}${PickupSenderDocumentPage.url}${
          PickupSurvey.url
        }`}
      >
        <PickupSurvey
          userIdentification={driverEmail}
          tag={tag}
          pickupCode={pickupCode}
          pickupType={PICKUP_TYPES.SHIPPER}
          packagesIds={packagesIds}
          shipperName={shipperName}
          onClose={() => {
            clearClientDocument();
            history.push(PAGES.PACKAGE_LIST);
          }}
        />
      </Route>
    </Switch>
  );
}
