import { useState, useEffect, useCallback } from 'react';

import firebase from 'firebase/app';

import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import {
  updateStatus,
  getPackageMutationByPackageId,
  mergePackageList
} from 'operations/update-status';

import defaultGetAssignmentList, {
  fetchAndSaveNewAssignmentList
} from 'operations/assignment-list/assignment-list';
import { errorTypes } from 'operations/assignment-list';
import { setScrollToTop } from 'view/utils';
import { goTo } from 'operations/history';
import { isGeolocationErrorType } from 'operations/assignment-list/constants';
import {
  isAssignmentWaypointPickup,
  parseAssignmentWaypointToLastMilePickup
} from 'view/molecules/assignment-list/utils';

import useAssignmentWaypointsFromAllocation from './use-assignment-from-allocation';

export const messages = {
  packageStatusUpdateSyncFail:
    'Erro de sincronia. Siga entregando e atualize quando puder.',
  packageListUpdateSyncFail:
    'Opa! Não foi possível atualizar a lista. Tente de novo para conferir os pacotes.'
};

// Idle: Waiting for instructions to do the request.
// Loading: Getting data from API.
// Error: Error retrieving data from API.
// Complete: Request to API with success.
export const statesEnum = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  COMPLETE: 'COMPLETE'
};

export function parseAssignmentWaypoint(assignmentWaypoint = {}) {
  // this is done in this "weird" way to assure the actions
  // refer to the new object with the additional properties
  const newAssignmentWaypoint = {
    ...assignmentWaypoint
  };
  newAssignmentWaypoint.actions = {
    goToDetails: () =>
      isAssignmentWaypointPickup(newAssignmentWaypoint)
        ? goTo.pickupDetails(
            parseAssignmentWaypointToLastMilePickup(newAssignmentWaypoint)
          )
        : goTo.assignmentWaypointDetails(newAssignmentWaypoint)
  };
  return newAssignmentWaypoint;
}

export function parseAssignmentsWaypoints(assignmentsWaypoints = []) {
  return assignmentsWaypoints.map(parseAssignmentWaypoint);
}

export function mergeAssignmentWaypoints(
  currentAssignmentWaypoints = [],
  newAssignmentWaypoints = []
) {
  if (!newAssignmentWaypoints?.length) return currentAssignmentWaypoints;

  if (!currentAssignmentWaypoints?.length)
    return parseAssignmentsWaypoints(newAssignmentWaypoints);

  const mapIds = new Set(
    currentAssignmentWaypoints.map(waypoint => waypoint.waypointId)
  );

  /**
   * we make a filter in waypoints added in currentAssignmentWaypoints
   * to prevent a waypoint duplication.
   *
   * There's concurrency scenario when we will have two endpoints:
   * 1: the first is from assingment-list when there's a call to
   * allocation api(to get accepted_pickups)
   *
   * 2: the second is a proxy to allocation api. In both cases will return accepted_pickups
   * merged with waypoints to flecha.
   *
   * When we turn on the FS "enableResponseFromAllocationService" this will stop to happen.
   *
   * enableResponseFromAllocationService (fs on frontend) - when active the assignment response
   * will merge with allocation response
   *
   * disable_call_allocation_on_assignment_list (fs on backend) - when active will stop to response
   * allocation service from assignment_list endpoint
   *
   * * OBS *
   * First we must enable fs on the frontend and then on the backend
   */
  return [
    ...currentAssignmentWaypoints,
    ...newAssignmentWaypoints
      .filter(w => !mapIds.has(w.waypointId))
      .map(waypoint => parseAssignmentWaypoint(waypoint))
  ];
}

function useAssignmentList(getAssignmentList = defaultGetAssignmentList) {
  const { IDLE, ERROR, LOADING } = statesEnum;
  const [currentState, setCurrentState] = useState({
    state: LOADING
  });

  const [errorType, setErrorType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isEnableResponseFromAllocationService = useFeatureSwitch(
    featureSwitches.enableResponseFromAllocationService
  );

  const isConsiderDeliveredPackagesFromBackendEnabled = useFeatureSwitch(
    featureSwitches.considerDeliveredPackagesFromBackend
  );

  const isListDeliveredPackagesEnabledForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByCompanyRelation
    )
  );

  const isListDeliveredPackagesEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByLastMileCompany
    )
  );

  const changeStatusDeliveredPackageForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageByCompanyRelation
    )
  );

  const changeStatusDeliveredPackageForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableStatusChangeDeliveredPackageForDriverLastMileCompany
    )
  );

  const changeStatusDeliveredPackages =
    changeStatusDeliveredPackageForCompanyRelation &&
    changeStatusDeliveredPackageForDriverLMC;

  const isListDeliveredPackagesEnabledForDriver =
    isListDeliveredPackagesEnabledForCompanyRelation &&
    isListDeliveredPackagesEnabledForDriverLMC;

  // assignment list state
  const [assignmentList, setAssignmentList] = useState(null);
  const {
    packages,
    pickups,
    assignmentsWaypoints,
    localTime: timestamp,
    deliveryFlow,
    hasReturnSteps
  } = assignmentList || {};

  const {
    assignments: assignmentsFromAllocation,
    error: errorFromAllocation,
    hasError: hasErrorFromAllocation,
    cleanError: cleanErrorFromAllocation
  } = useAssignmentWaypointsFromAllocation();

  useEffect(() => {
    if (hasErrorFromAllocation && isEnableResponseFromAllocationService) {
      setCurrentState({ state: ERROR });
    }
  }, [hasErrorFromAllocation, ERROR, isEnableResponseFromAllocationService]);

  const isLoading = currentState.state === LOADING;

  const hasAssignmentList =
    !isLoading &&
    (pickups || packages || assignmentsWaypoints || assignmentsFromAllocation);
  const isAnEmptyList =
    hasAssignmentList &&
    !packages?.length &&
    !pickups?.length &&
    !assignmentsWaypoints?.length &&
    !assignmentsFromAllocation?.length;

  const isAListWithData = hasAssignmentList && !isAnEmptyList;

  const didFailToFetchList = !isLoading && !hasAssignmentList;
  const didFailToUpdateList =
    !isLoading &&
    currentState.state === ERROR &&
    hasAssignmentList &&
    !isGeolocationErrorType(errorType) &&
    errorType !== errorTypes.storageError;

  const [didFailToUpdatePackage, setDidFailToUpdatePackage] = useState(false);

  const cleanError = () => {
    setErrorType('');
    setErrorMessage('');
    cleanErrorFromAllocation();
  };

  const cleanCurrentState = () => {
    setCurrentState({ state: IDLE });
    cleanErrorFromAllocation();
  };

  const handleNewAssignmentList = promise =>
    promise
      .then(newAssignmentList => {
        setDidFailToUpdatePackage(false);
        setCurrentState({ state: IDLE });

        if (newAssignmentList.error?.type) {
          setErrorType(newAssignmentList.error.type);
          setErrorMessage(newAssignmentList.error.message);
          setCurrentState({ state: ERROR });
        }

        setAssignmentList(newAssignmentList);
      })
      .catch(error => {
        setErrorType(error.type);
        setErrorMessage(error.message);
        setCurrentState({ state: ERROR });
      });

  const getList = () => {
    const trace = firebase.performance().trace('ASSIGNMENT_LIST_FETCH');
    trace.start();

    return handleNewAssignmentList(
      getAssignmentList(
        isListDeliveredPackagesEnabledForDriver,
        isConsiderDeliveredPackagesFromBackendEnabled
      )
    ).finally(() => {
      trace.stop();
    });
  };

  useEffect(() => {
    getList();
    // defaultGetAssignmentList is the real dependency we want
    // to put into the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultGetAssignmentList,
    isListDeliveredPackagesEnabledForDriver,
    isConsiderDeliveredPackagesFromBackendEnabled
  ]);

  const fetchNewList = useCallback(() => {
    return handleNewAssignmentList(
      fetchAndSaveNewAssignmentList(
        isListDeliveredPackagesEnabledForDriver,
        isConsiderDeliveredPackagesFromBackendEnabled
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isListDeliveredPackagesEnabledForDriver,
    isConsiderDeliveredPackagesFromBackendEnabled
  ]);

  const retryFetchingList = () => {
    setCurrentState({ state: LOADING });
    cleanError();
    setScrollToTop();
    return fetchNewList();
  };

  const retryPackageSync = pkg => {
    const onPackageMutationStateChange = (packageMutationState, error) => {
      if (error) {
        setErrorType(error.type);
        setErrorMessage(error.message);
        setCurrentState({ state: ERROR });
        setDidFailToUpdatePackage(true);
      }

      mergePackageList({
        packageList: packages,
        listDeliveredPackages: isListDeliveredPackagesEnabledForDriver,
        considerDeliveredPackagesFromBackend: isConsiderDeliveredPackagesFromBackendEnabled
      }).then(mergedPackages => {
        setAssignmentList(oldAssignmentList => ({
          ...oldAssignmentList,
          packages: mergedPackages
        }));
      });
    };

    getPackageMutationByPackageId(pkg.packageId)
      .then(packageMutation => {
        return updateStatus({
          updateStatusData: packageMutation.payload,
          onMutationStateChange: onPackageMutationStateChange,
          markDeliveredPkg: changeStatusDeliveredPackages
        });
      })
      .catch(error => {
        setErrorType(error.type);
        setErrorMessage(error.message);
        setCurrentState({ state: ERROR });
        setDidFailToUpdatePackage(true);
      });
  };

  let assignmentsWaypointsResponse;

  if (isEnableResponseFromAllocationService) {
    assignmentsWaypointsResponse = mergeAssignmentWaypoints(
      parseAssignmentsWaypoints(assignmentsWaypoints),
      assignmentsFromAllocation
    );
  } else {
    assignmentsWaypointsResponse = parseAssignmentsWaypoints(
      assignmentsWaypoints
    );
  }

  return {
    currentState,
    errorType,
    errorMessage,
    setCurrentState,
    cleanError,
    cleanCurrentState,

    packages: packages || [],
    assignmentsWaypoints: assignmentsWaypointsResponse,
    pickups,
    hasReturnSteps,
    deliveryFlow,
    timestamp,

    isAnEmptyList,
    isAListWithData,
    didFailToFetchList,
    didFailToUpdateList,
    didFailToUpdatePackage,

    fetchNewList,
    retryPackageSync,
    retryFetchingList,

    allocationErrorType: errorFromAllocation?.errorType,
    allocationErrorMessage: errorFromAllocation?.errorMessage
  };
}

export default useAssignmentList;
