import apiLoggiWeb from './loggiweb';

function parserErrorFromApi(error) {
  let errorObject;

  if (typeof error.json === 'string') {
    /**
     * When an exception is thrown in backend, it will arrive as a string
     * for example, in python: raise ValidationError(str(e))
     */

    errorObject = { message: error.json };
  } else if (Array.isArray(error.json) && typeof error.json[0] === 'string') {
    /**
     * When an exception is thrown in backend, it will arrive as a string or as an array
     * and the first item as a string
     * for example, in python: raise InvalidPackageUpdateStatusRequest(
     *   "Invalid request as package_id or status is missing or an empty data was passed"
     * )
     * 0: "Invalid request as package_id or status is missing or an empty data was passed"
     */

    errorObject = { message: error.json[0] };
  } else if (error.json.message) {
    errorObject = error.json;
  } else if (error.json.detail) {
    /**
     * There is a class validation on authentication parameters that throws api error with a
     * detail field... we use the "message" field in the front end
     */
    errorObject = { ...error.json };
    errorObject.message = error.json.detail;
  } else if (error.json.errors) {
    errorObject = error.json;
  }

  return {
    response: {
      ...errorObject,
      status: error.status,
      raw: JSON.stringify(error)
    }
  };
}

/*
 * This object is created and exported to maintain compatibility with the unused
 * axios library and is a part of the transition period from axios to wretch.
 *
 * @deprecated
 */
export default {
  get: endpoint =>
    apiLoggiWeb()
      .url(endpoint)
      .get()
      .fetchError(err => {
        const message = err?.message || '';

        // eslint-disable-next-line no-throw-literal
        throw new Error(message, {
          isFetchError: true
        });
      })
      .json()
      .catch(err => {
        if (err.json && err.status) {
          // no-throw-literal
          throw parserErrorFromApi(err);
        }
        throw err;
      }),
  patch: (endpoint, data) =>
    apiLoggiWeb()
      .url(endpoint)
      .patch(data)
      .fetchError(err => {
        const message = err?.message || '';

        // eslint-disable-next-line no-throw-literal
        throw new Error(message, {
          isFetchError: true,
          ...err
        });
      })
      .json()
      .catch(err => {
        if (err.json && err.status) {
          // no-throw-literal
          throw parserErrorFromApi(err);
        }
        throw err;
      }),
  post: (endpoint, object) =>
    apiLoggiWeb()
      .url(endpoint)
      .post(object)
      .fetchError(err => {
        const message = err?.message || '';

        // eslint-disable-next-line no-throw-literal
        throw new Error(message, {
          isFetchError: true,
          ...err
        });
      })
      .json()
      .catch(err => {
        if (err.json && err.status) {
          // no-throw-literal
          throw parserErrorFromApi(err);
        }
        throw err;
      })
};
