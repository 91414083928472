import { colors } from '@loggi/mar';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Drawer
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BarcodeReaderTemplate from 'view/templates/barcode-reader';
import { ReactComponent as BarcodeReader } from '../../atoms/illustrations/barcode-reader.svg';

export const messages = {
  inputSearchPlaceholder: 'Busque por código ou endereço',
  buttonResetSearch: 'Apagar texto da pesquisa',
  buttonReadBarcode: 'Ler código de barras'
};

export default function SearchBar({ onSearchChanged, search }) {
  const [showBarcodeReader, setShowBarcodeReader] = useState(false);

  const closeBarcodeReader = () => setShowBarcodeReader(false);

  return (
    <>
      <Box px={2.5} zIndex={1}>
        <TextField
          variant="outlined"
          placeholder={messages.inputSearchPlaceholder}
          fullWidth
          InputProps={{
            style: { fontSize: 12 },
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <Box>
                    <Button
                      size="medium"
                      color="primary"
                      aria-label={messages.buttonResetSearch}
                      onClick={() => onSearchChanged('')}
                    >
                      <CloseIcon />
                    </Button>
                  </Box>
                )}
                <Box pl={1} borderLeft={`1px solid ${colors.smoke[200]}`}>
                  <Button
                    size="small"
                    color="primary"
                    aria-label={messages.buttonReadBarcode}
                    onClick={() => setShowBarcodeReader(true)}
                  >
                    <BarcodeReader />
                  </Button>
                </Box>
              </InputAdornment>
            )
          }}
          onChange={event => onSearchChanged(event.target.value)}
          value={search}
        />
      </Box>
      <Drawer
        anchor="bottom"
        open={showBarcodeReader}
        onClose={closeBarcodeReader}
        PaperProps={{ style: { height: '100%' } }}
      >
        <Box height="100%" bgcolor={colors.smoke[900]} px={4.5}>
          <BarcodeReaderTemplate
            onGoBack={closeBarcodeReader}
            onBarcodeRead={newBarcode => {
              onSearchChanged(newBarcode?.content);
              setShowBarcodeReader(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

SearchBar.propTypes = {
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};
