import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import { PAGES } from 'view';

import { ReactComponent as PendingPackagesIcon } from 'view/atoms/illustrations/pending-packages/pending-packages-icon.svg';

import { colors } from '@loggi/mar';

import { useHistory } from 'react-router-dom';

export const CONTINUE_DELIVERIES = 'Continuar entregas';
export const PENDING_TEXT =
  'Você pode tentar entregar de novo ou devolver na agência indicada no aplicativo.';
export const RETURN_PACKAGES = 'Devolver';
export const SINGLE_PACKAGE_PENDING = 'pacote não entregue.';
export const MANY_PACKAGE_PENDING = 'pacotes não entregues.';

export default function PendingDeliveryTemplate({ pendingDeliveryPackages }) {
  const history = useHistory();

  const pendingPackagesText = `${pendingDeliveryPackages} ${
    pendingDeliveryPackages > 1 ? MANY_PACKAGE_PENDING : SINGLE_PACKAGE_PENDING
  }`;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%"
        textAlign="center"
        p={2.5}
      >
        <PendingPackagesIcon />
        <Box p={1} mt={2.5}>
          <Typography variant="h5">
            Você ainda tem
            <Box component="span" ml={1} color={colors.blue[500]}>
              {pendingPackagesText}
            </Box>
          </Typography>
        </Box>
        <Box color={colors.smoke[700]}>
          <Typography variant="body1">{PENDING_TEXT}</Typography>
        </Box>
      </Box>

      <Box p={2.5} position="absolute" bottom="0" width="100%">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button
              size="large"
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => history.push(PAGES.PACKAGE_RETURN)}
            >
              {RETURN_PACKAGES}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              fullWidth
              size="large"
              onClick={() => history.push(PAGES.PACKAGE_LIST)}
              variant="contained"
              color="primary"
            >
              {CONTINUE_DELIVERIES}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

PendingDeliveryTemplate.propTypes = {
  pendingDeliveryPackages: PropTypes.number.isRequired
};
