import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as Emoji } from 'view/atoms/icons/loggi/emoji.svg';
import { Box, Typography, Drawer, Button } from '@material-ui/core';
import { useRoundedBorder } from 'view/utils/styles';
import messages from './messages';
import PickupSurvey from './pickup-survey';

export default function PickupSurveyDrawer({ isOpen, closeDrawer }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const roundedBorder = useRoundedBorder();

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDrawer}
      classes={{ paper: roundedBorder.root }}
    >
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        pt={10}
        pb={2}
        px={2}
      >
        <Emoji />

        <Box pt={5} display="flex">
          <Typography variant="h5" align="center">
            {messages.pickupSurveyDrawer.title}
          </Typography>
        </Box>
        <Box px={4.5} pt={2} display="flex">
          <Typography variant="caption" align="center" color="textSecondary">
            {messages.pickupSurveyDrawer.text}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box py={2.5} mx={2.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              history.push(`${pathname}${PickupSurvey.url}`);
            }}
          >
            {messages.pickupSurveyDrawer.showPickupSurveyButton}
          </Button>
        </Box>
        <Box pb={2.5} mx={2.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="outlined"
            onClick={closeDrawer}
          >
            {messages.pickupSurveyDrawer.skipPickupSurveyButton}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

PickupSurveyDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired
};
