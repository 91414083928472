import ShowcasePage from './view/pages/showcase';
// This is exported so we can track via GA the access from
// the carroussel and the button in the home.
import {
  OFFER_LIST_ACCESS,
  OFFER_LIST_ACCESS_SOURCE_CARROUSSEL_BUTTON,
  OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON,
  registerOfferListAccess
} from './infra/services/analytics';

import OffersPageV2 from './view/pages/offer-screen/v2';
import BarcodeReaderPage from './view/pages/barcode-reader';
import OfferScreen from './view/pages/offer-screen';

export {
  OffersPageV2,
  OFFER_LIST_ACCESS,
  OFFER_LIST_ACCESS_SOURCE_CARROUSSEL_BUTTON,
  OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON,
  registerOfferListAccess,
  BarcodeReaderPage,
  OfferScreen
};

export default ShowcasePage;
