import { colors } from '@loggi/mar';
import {
  Box,
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  Phone,
  Close,
  WhatsApp,
  ArrowForwardIos,
  Error
} from '@material-ui/icons';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { featureSwitches } from 'operations/feature-switches';
import sendEventToAnalytics from 'operations/firebase';
import Alert from 'view/atoms/alert';
import { ReactComponent as PackageWithWarningTriangle } from 'view/atoms/illustrations/package-with-warning-triangle.svg';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import {
  closeAndStartNewWhatsAppConversation,
  getWhatsAppConversation
} from 'operations/proxy-phone/proxy-phone';
import {
  STATE,
  useIconHeaderStyles,
  NOTIFICATIONS_ENUM,
  recipientPhoneTexts,
  EXPERIMENT_ENUM
} from './constants';
import getNotificationData from './notifications';

export const messages = {
  speakWith: 'Falar com ',
  through: ' por:',
  whatsAppContact: {
    title: 'Fale com quem vai receber',
    body: {
      initial:
        'Entre em contato para achar o destinatário e tirar dúvidas sobre a entrega. Por exemplo:',
      bullets: [
        'Qual o número do local?',
        'Algum ponto de referência?',
        'Que horas você está disponível?'
      ],
      list: [
        'Na hora do contato, fale apenas sobre a entrega do pacote;',
        'Não salve dados dessa pessoa e não entre em contato após a entrega.'
      ],
      end:
        'Se não conseguir falar na hora, faça outras atividades e tente de novo depois.'
    },
    confirmButton: 'Ok, entendi.'
  }
};

const {
  WHATSAPP_INVITATION_SENT,
  WHATSAPP_CONVERSATION_LIMIT_REACHED
} = NOTIFICATIONS_ENUM;

function RecipientContactDrawer({
  isOpen,
  contactedPersonName,
  contactOptions,
  closeDrawer
}) {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDrawer}
      PaperProps={{ square: false, component: Box, mb: -3 }}
    >
      <Box pb={3}>
        <Box pl={0.5} pt={2}>
          <IconButton onClick={closeDrawer}>
            <Close color="primary" style={{ fontSize: 28 }} />
          </IconButton>
        </Box>
        <Box px={2.5} pt={1.5} pb={1.5}>
          <Typography variant="h5" component="span">
            {messages.speakWith}
            <Box
              color="primary.main"
              component="span"
              fontWeight="fontWeightMedium"
            >
              {contactedPersonName}
            </Box>
            {messages.through}
          </Typography>
        </Box>
        <List>
          {contactOptions.map(option => (
            <ListItem button onClick={option.onClick} divider key={option.name}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>
                <Box py={1.5} fontWeight="fontWeightMedium">
                  {option.name}
                </Box>
              </ListItemText>
              <ArrowForwardIos style={{ fontSize: 16 }} color="primary" />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
RecipientContactDrawer.propTypes = {
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node
    })
  ).isRequired,
  contactedPersonName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired
};

function WhatsAppContactDrawer({ isOpen, closeDrawer, onContinue, isLoading }) {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDrawer}
      PaperProps={{ square: false, component: Box, mb: -3 }}
    >
      <Box pb={3}>
        <Box pl={0.5} pt={2}>
          <IconButton onClick={closeDrawer}>
            <Close color="primary" style={{ fontSize: 28 }} />
          </IconButton>
        </Box>
        <Box px={2.5}>
          <PackageWithWarningTriangle />
        </Box>
        <Box px={2.5} pt={1.5} pb={1.5} color={colors.smoke[900]}>
          <Typography
            variant="h6"
            component="span"
            fontWeight={600}
            fontSize={20}
          >
            {messages.whatsAppContact.title}
          </Typography>
        </Box>
        <Box px={2.5} pt={1.25} pb={1.5} color={colors.smoke[800]}>
          <Typography variant="body1" fontSize={16}>
            {messages.whatsAppContact.body.initial}
          </Typography>
        </Box>
        <Typography variant="body1" fontSize={16} component="span">
          <Box px={2.5} pt={1.25} pb={1.5} color={colors.smoke[800]}>
            {messages.whatsAppContact.body.bullets.map(bulletText => (
              <Box key={bulletText}>{`\u2022 ${bulletText}`}</Box>
            ))}
          </Box>
          <Box px={2.5} pt={1.25} pb={1.5} color={colors.smoke[800]}>
            {messages.whatsAppContact.body.end}
          </Box>
          <Box pl={1} pr={2.5} color={colors.smoke[800]}>
            <List component="ol">
              {messages.whatsAppContact.body.list.map((listText, index) => (
                <ListItem key={listText}>
                  {`${index + 1}. ${listText}`}
                </ListItem>
              ))}
            </List>
          </Box>
        </Typography>
        <Box px={2.5} pt={2.5} pb={2.5} width="100%" mb="20px">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={onContinue}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                data-testid="loading-whatsapp-conversation"
              />
            ) : (
              messages.whatsAppContact.confirmButton
            )}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
WhatsAppContactDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

function CloseCurrentConversationButton({
  proxyPhone,
  onCloseConversation,
  isLoading
}) {
  return (
    <Box width="100%" mb="20px">
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={() => onCloseConversation(proxyPhone)}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress
            size={24}
            data-testid="loading-close-whatsapp-conversation"
          />
        ) : (
          'Encerrar a ativa e começar outra'
        )}
      </Button>
    </Box>
  );
}
CloseCurrentConversationButton.propTypes = {
  proxyPhone: PropTypes.string.isRequired,
  onCloseConversation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default function RecipientPhone({
  recipientName,
  getPhone,
  getWhatsAppNumber,
  experimentName,
  packageId,
  calledFrom
}) {
  const [notificationDrawerState, setNotificationDrawerState] = useState(
    DRAWER_STATE.CLOSED
  );
  const [state, setState] = useState(STATE.INITIAL);
  const [toasterMessage, setToasterMessage] = useState('');
  const [activeWhatsAppConversation, setActiveWhatsAppMessage] = useState({
    receiverName: null,
    whatsappProxyPhone: null
  });
  const iconHeaderStyles = useIconHeaderStyles();

  const [isRecipientContactDrawerOpen, setRecipientContactOpen] = useState(
    false
  );

  const openContactDrawer = () => setRecipientContactOpen(true);
  const closeContactDrawer = () => setRecipientContactOpen(false);

  const sendStatistics = () =>
    isRecipientContactDrawerOpen
      ? sendEventToAnalytics('contactBoxCalledFrom', { calledFrom })
      : null;

  const [isWhatsAppContactDrawerOpen, setWhatsAppContactOpen] = useState(false);
  const openWhatsAppContactDrawer = () => setWhatsAppContactOpen(true);
  const closeWhatsAppContactDrawer = () => setWhatsAppContactOpen(false);

  const isWhatsappApiChangeEnabled = useFeatureSwitch(
    featureSwitches.changeWhatsappAPI
  );

  const handleHttpErrors = promise => {
    if (!promise.error) {
      // if promise doesn't have wretch handlers
      // normalize it so it looks like one that has
      return {
        json: (...args) => promise.then(...args),
        then: promise.then,
        catch: promise.catch
      };
    }

    return promise
      .error(401, () => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.UNAUTHORIZED);
      })
      .error(404, () => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .error(412, () => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      });
  };

  const makeGetPhoneRequest = () => {
    setState(STATE.LOADING);

    handleHttpErrors(getPhone())
      .json(response => {
        setState(STATE.INITIAL);
        const phone = response.recipientPhone;
        window.location.href = `tel:${phone}`;
      })
      .catch(() => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_PHONE);
      });
  };

  const makeGetWhatsAppRequest = () => {
    setState(STATE.LOADING);

    getWhatsAppConversation(packageId)
      .notFound(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .badRequest(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .json(response => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        const { deepLink } = response;
        if (isWhatsappApiChangeEnabled) {
          window.location = deepLink;
        } else {
          window.open(deepLink, '_blank');
        }
      })
      .catch(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_PHONE);
      });
  };

  const makeGetWhatsAppProxyRequest = () => {
    setState(STATE.LOADING);

    getWhatsAppNumber()
      .error(412, () => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setToasterMessage(recipientPhoneTexts.duplicatedInvitation);
      })
      .error(409, ({ json }) => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        const { whatsappActiveConversations } = json;

        setState(STATE.INITIAL);
        setActiveWhatsAppMessage(whatsappActiveConversations[0]);
        setNotificationDrawerState(WHATSAPP_CONVERSATION_LIMIT_REACHED);
      })
      .notFound(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .badRequest(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .json(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(WHATSAPP_INVITATION_SENT);
      })
      .catch(() => {
        closeContactDrawer();
        closeWhatsAppContactDrawer();
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_PHONE);
      });
  };

  const makeCloseAndStartConversationRequest = closeProxyPhone => {
    setState(STATE.LOADING);

    closeAndStartNewWhatsAppConversation(packageId, closeProxyPhone)
      .notFound(() => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .badRequest(() => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_INVALID_PHONE);
      })
      .json(() => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(WHATSAPP_INVITATION_SENT);
      })
      .catch(() => {
        setState(STATE.INITIAL);
        setNotificationDrawerState(DRAWER_STATE.ERROR_PHONE);
      });
  };

  const contactOptions = [
    {
      name: 'WhatsApp',
      onClick: openWhatsAppContactDrawer,
      icon: <WhatsApp />
    },
    {
      name: 'Ligação',
      onClick: makeGetPhoneRequest,
      icon: <Phone />
    }
  ];

  return (
    <>
      <IconButton
        variant="outlined"
        classes={iconHeaderStyles}
        disabled={state === STATE.LOADING}
        data-testid="recipient-phone-button"
        onClick={() => {
          sendStatistics();
          const onClick =
            experimentName === EXPERIMENT_ENUM.WHATSAPP_TWILIO_PROXY ||
            experimentName === EXPERIMENT_ENUM.WHATSAPP
              ? openContactDrawer
              : makeGetPhoneRequest;
          onClick();
        }}
      >
        <Phone />
      </IconButton>
      <RecipientContactDrawer
        contactedPersonName={recipientName}
        contactOptions={contactOptions}
        isOpen={isRecipientContactDrawerOpen}
        closeDrawer={closeContactDrawer}
      />
      <WhatsAppContactDrawer
        isOpen={isWhatsAppContactDrawerOpen}
        closeDrawer={closeWhatsAppContactDrawer}
        onContinue={
          experimentName === EXPERIMENT_ENUM.WHATSAPP_TWILIO_PROXY
            ? makeGetWhatsAppProxyRequest
            : makeGetWhatsAppRequest
        }
        isLoading={state === STATE.LOADING}
      />
      <NotificationDrawer
        drawerState={notificationDrawerState}
        setDrawerState={setNotificationDrawerState}
        data={getNotificationData(notificationDrawerState, {
          recipientName,
          activeConversation: activeWhatsAppConversation.receiverName
        })}
        extraButton={
          notificationDrawerState === WHATSAPP_CONVERSATION_LIMIT_REACHED ? (
            <CloseCurrentConversationButton
              proxyPhone={activeWhatsAppConversation.whatsappProxyPhone}
              onCloseConversation={makeCloseAndStartConversationRequest}
              isLoading={state === STATE.LOADING}
            />
          ) : null
        }
      />
      <Alert
        message={toasterMessage}
        startAdornment={<Error fontSize="large" color="error" />}
        open={!!toasterMessage}
        onClose={() => setToasterMessage('')}
      />
    </>
  );
}

RecipientPhone.propTypes = {
  recipientName: PropTypes.string,
  experimentName: PropTypes.string,
  getPhone: PropTypes.func.isRequired,
  getWhatsAppNumber: PropTypes.func,
  packageId: PropTypes.string,
  calledFrom: PropTypes.string
};

RecipientPhone.defaultProps = {
  packageId: null,
  recipientName: '',
  experimentName: '',
  calledFrom: '',
  getWhatsAppNumber: () => {}
};
