import React from 'react';
import { ReactComponent as PkgsIconError } from 'view/atoms/illustrations/pending-packages/pending-barcode-packages.svg';

const messages = {
  finishUntil: (assignmentFlow, deadlineDeliveryTime) => {
    const isDelivery = assignmentFlow.includes('DELIVERY');
    const activityType = isDelivery ? 'Entregar' : 'Coletar';
    const deadlineDefaultMessage = isDelivery ? 'hoje' : 'agora';
    if (deadlineDeliveryTime) {
      return `${activityType} até ${deadlineDeliveryTime}`;
    }
    return `${activityType} ${deadlineDefaultMessage}`;
  },
  deliveryToAgency: {
    defaultDeliveryName: 'Base Loggi',
    deliveryButton: 'Entreguei',
    navigationButton: 'Como chegar',
    deliverPkgsTo: packagesCount => {
      const pacoteOrPacotes = packagesCount > 1 ? 'pacotes' : 'pacote';
      return `${packagesCount} ${pacoteOrPacotes} para`;
    }
  },
  hasPendingPkgs: {
    icon: <PkgsIconError />,
    description:
      'Caso já tenha entregado, aguarde o time da agência receber para que você possa finalizar essa entrega.',
    buttonVariant: 'contained',
    button: 'Ok, entendi.'
  },
  apiResponseError: errorMessage => ({
    icon: <PkgsIconError />,
    title: 'Opa! Algo deu errado.',
    description:
      errorMessage || 'Você pode tentar de novo daqui a alguns instantes.',
    buttonVariant: 'contained',
    button: 'Ok, entendi.'
  }),
  emptyDrawer: {
    title: '',
    description: '',
    buttonVariant: 'contained',
    button: ''
  }
};

export default messages;
