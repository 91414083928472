import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';

/**
 * The default touch event scrolls the browser entirely, and for a dragging component like this
 * we don't want that behaviour, that way, we should prevent it with this function
 * */
function removeTouchDefault() {
  const canvas = document.getElementById('canvas');

  if (!canvas) return;

  canvas.addEventListener('touchstart', event => {
    event.preventDefault();
  });

  canvas.addEventListener('touchmove', event => {
    event.preventDefault();
  });

  canvas.addEventListener('touchend', event => {
    event.preventDefault();
  });

  canvas.addEventListener('touchcancel', event => {
    event.preventDefault();
  });

  const resizeCanvas = () => {
    const rect = canvas.getBoundingClientRect();

    canvas.width = rect.width;
    canvas.height = rect.height;
  };

  window.addEventListener('resize', resizeCanvas, false);

  resizeCanvas();
}

export default function Signature({ signature, clear, onChange }) {
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);

  const setTouchPosition = e => {
    const canvas = document.getElementById('canvas');

    const rect = canvas.getBoundingClientRect();
    const touch = e.touches[0];

    const scaleX =
      canvas.width / rect.width === Infinity ? 1 : canvas.width / rect.width;
    const scaleY =
      canvas.height / rect.height === Infinity
        ? 1
        : canvas.height / rect.height;

    pos.x = (touch.clientX - rect.left) * scaleX;
    pos.y = (touch.clientY - rect.top) * scaleY;

    setPos(pos);
  };

  const draw = e => {
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');

    if (!dragging) return;

    if (!hasSignature) {
      setHasSignature(true);
    }

    context.beginPath();

    context.lineWidth = signature.size;
    context.lineCap = signature.lineCap;
    context.strokeStyle = signature.color;
    context.imageSmoothingEnabled = true;

    context.moveTo(pos.x, pos.y);
    setTouchPosition(e);
    context.lineTo(pos.x, pos.y);

    context.stroke();
  };

  useEffect(() => {
    if (clear) {
      const canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');

      setPos({ x: 0, y: 0 });
      setDragging(false);

      if (hasSignature) {
        setHasSignature(false);
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      onChange();
    }
  }, [clear, hasSignature, onChange]);

  useEffect(() => {
    removeTouchDefault();
  }, []);

  return (
    <Box px={3.5} height="100%" style={{ overflow: 'hidden' }}>
      <canvas
        id="canvas"
        style={{
          height: '100%',
          width: '100%',
          borderRadius: '16px',
          backgroundColor: colors.smoke[50]
        }}
        onTouchMove={e => draw(e)}
        onTouchStart={e => {
          setDragging(true);
          setTouchPosition(e);
        }}
        onTouchEnd={e => {
          setDragging(false);
          onChange(e.target);
        }}
      />
    </Box>
  );
}

Signature.displayName = 'Signature';

Signature.propTypes = {
  signature: PropTypes.shape({
    size: PropTypes.number,
    lineCap: PropTypes.string,
    color: PropTypes.string
  }),
  clear: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Signature.defaultProps = {
  signature: {
    size: 1,
    lineCap: 'round',
    color: '#000000'
  },
  clear: false
};
