import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

const videoElementId = 'videoScanner';
const timeBetweenScansMillis = 1500;

export function handleBarcodeReading(onDetected, result, err) {
  if (result) {
    onDetected(result.text);
  }

  // ignoring NotFoundException because it happens while
  // the user is not scanning a barcode
  if (err && !(err instanceof NotFoundException)) {
    throw Error(err);
  }
}

export function ZebraCameraScanner({ onDetected }) {
  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader(
      null,
      timeBetweenScansMillis
    );

    codeReader.decodeFromVideoDevice(null, videoElementId, (result, err) => {
      handleBarcodeReading(onDetected, result, err);
    });

    return () => {
      codeReader.reset();
    };

    // We are disabling it, because we're not passing onDetected as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // We are disabling this rule because we don't need track options
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      style={{
        'border-radius': '16px',
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }}
      id={videoElementId}
      data-testid="zebra-video"
    />
  );
}

ZebraCameraScanner.propTypes = {
  onDetected: PropTypes.func.isRequired
};
