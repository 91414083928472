import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as MotocaIcon } from 'view/images/motoca.svg';
import { colors } from '@loggi/mar';

function EndRouteReminder() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      px={3}
      pt={2.5}
      boxSizing="border-box"
      width="100%"
      height="100%"
      data-testid="showcase-end-route-reminder"
    >
      <Box width="100%" borderRadius={1} bgcolor={colors.yellow[50]} p={3}>
        <MotocaIcon width={56} />
        <Typography variant="body1">
          <Box fontWeight="fontWeightMedium" mb={1}>
            Sempre finalize sua rota
          </Box>
        </Typography>
        <Typography variant="body2">
          Não se esqueça de finalizar sua rota no fim do dia.
        </Typography>
      </Box>
    </Box>
  );
}

export default React.memo(EndRouteReminder);
