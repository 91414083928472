import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { PAGES } from 'view';
import { ReactComponent as PendingBarcodePackages } from 'view/atoms/illustrations/pending-packages/pending-barcode-packages.svg';
import { colors } from '@loggi/mar';
import { useHistory } from 'react-router-dom';
import messages from './messages';

export default function PendingDeliverToDcTemplate({ pendingPackages }) {
  const history = useHistory();

  const pendingPackagesText = `${pendingPackages} ${
    pendingPackages > 1
      ? messages.pendingDeliverToDcTemplate.multiplePendingPackages
      : messages.pendingDeliverToDcTemplate.singlePendingPackage
  }`;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%"
        textAlign="center"
        p={2.5}
      >
        <PendingBarcodePackages />
        <Box p={1} mt={2.5}>
          {pendingPackages !== 0 && (
            <Typography variant="h5">
              Ainda tem
              <Box component="span" ml={1} color={colors.blue[500]}>
                {pendingPackagesText}
              </Box>
            </Typography>
          )}
          {pendingPackages === 0 && (
            <Typography variant="h5">
              {messages.pendingProtocol.confirmDelivery}
            </Typography>
          )}
        </Box>
        <Box color={colors.smoke[700]}>
          <Typography variant="body1">
            {pendingPackages !== 0 &&
              messages.pendingDeliverToDcTemplate.pendingDescription}
            {pendingPackages === 0 &&
              messages.pendingProtocol.pendingDescription}
          </Typography>
        </Box>
      </Box>

      <Box p={2.5} position="absolute" bottom="0" width="100%">
        <Box py={2.5}>
          <Button
            fullWidth
            size="large"
            onClick={() => history.push(PAGES.PACKAGE_LIST)}
            variant="contained"
            color="primary"
          >
            {pendingPackages !== 0 &&
              messages.pendingDeliverToDcTemplate.continueDeliveriesButton}
            {pendingPackages === 0 &&
              messages.pendingProtocol.confirmDeliveryButton}
          </Button>
        </Box>
      </Box>
    </>
  );
}

PendingDeliverToDcTemplate.propTypes = {
  pendingPackages: PropTypes.number.isRequired
};
