import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Drawer, Button } from '@material-ui/core';
import { useRoundedBorder } from 'view/utils/styles';
import { ReactComponent as PackageWithBarcodeNotFound } from 'view/atoms/illustrations/one-package-with-barcode-not-found.svg';
import { ReactComponent as Barcode } from 'view/atoms/icons/barcode.svg';
import messages from './messages';

export default function PackageNotIntegratedDrawerError({
  onTypeBarcode,
  onReadOtherBarcode,
  barcodeNotFound
}) {
  const roundedBorder = useRoundedBorder();

  return (
    <Drawer anchor="bottom" open classes={{ paper: roundedBorder.root }}>
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        pt={7}
        pb={4}
        px={4.5}
      >
        <PackageWithBarcodeNotFound style={{ width: 100 }} />
        <Typography
          variant="subtitle2"
          align="center"
          style={{ marginTop: 32 }}
        >
          <Box mt={4} mb={4}>
            {messages.packageNotIntegrated.text}
          </Box>
        </Typography>
        <Box mt={4} display="flex" flexDirection="row" height="100%">
          <Barcode />
          <Typography
            variant="subtitle2"
            align="center"
            style={{ marginLeft: 5 }}
          >
            <Box ml={1}>{barcodeNotFound}</Box>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box pt={3.5} pb={2.5} mx={4.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            data-testid="btn-scan-another-barcode"
            onClick={() => {
              onReadOtherBarcode();
            }}
          >
            {messages.packageNotIntegrated.buttonReadOtherCode}
          </Button>
        </Box>
        <Box pb={2.5} mx={4.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="outlined"
            data-testid="btn-type-barcode"
            onClick={onTypeBarcode}
          >
            {messages.packageNotIntegrated.buttonTypeCode}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

PackageNotIntegratedDrawerError.propTypes = {
  onTypeBarcode: PropTypes.func.isRequired,
  onReadOtherBarcode: PropTypes.func.isRequired,
  barcodeNotFound: PropTypes.string.isRequired
};
