import apiLoggiWeb from 'operations/config/api/loggiweb';

export default function getProxyPhone(packageId) {
  return apiLoggiWeb()
    .url(`/last-mile/v1/packages/${packageId}/recipient_phone`)
    .get();
}

function getPhoneMask(encryptedPhone, pickupCode) {
  return apiLoggiWeb()
    .url(`/last-mile/v1/proxy_phone`)
    .post({
      encrypted_phone: encryptedPhone,
      session_name: pickupCode
    });
}

function postWhatsAppProxyConversation(packageId, closeProxyPhone = {}) {
  return apiLoggiWeb()
    .url(
      `/communications/v1/messaging/delivery_attempt/${packageId}/whatsapp-proxy-conversation`
    )
    .post(closeProxyPhone);
}

function postWhatsAppConversation(packageId) {
  return apiLoggiWeb()
    .url(
      `/communications/v1/messaging/delivery_attempt/${packageId}/whatsapp-conversation`
    )
    .post({});
}

const getWhatsAppProxy = packageId => postWhatsAppProxyConversation(packageId);

const closeAndStartNewWhatsAppConversation = (packageId, closeProxyPhone) =>
  postWhatsAppProxyConversation(packageId, { closeProxyPhone });

const getWhatsAppConversation = packageId =>
  postWhatsAppConversation(packageId);

export {
  getPhoneMask,
  getWhatsAppProxy,
  closeAndStartNewWhatsAppConversation,
  getWhatsAppConversation
};
