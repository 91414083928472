import React from 'react';
import { OfferScreen } from '@loggi/driverapp-routes-list';
import { setRedirectToPickupPackagesValue } from 'hooks/use-redirect-to-pickup-packages';

export default function OfferScreenWrapper() {
  return (
    <OfferScreen
      shouldOpenDeepLinkOnAccept={false}
      additionalOnAcceptAction={setRedirectToPickupPackagesValue}
    />
  );
}
