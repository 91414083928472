import db, { isMutationStatusEqualToPackageStatus } from './mutations-db';
import pendingSyncPackages from './pending-sync-packages';
import packageInfoDeliveredStatusDB from './package-info-delivered-status-db';
import {
  sortPackagesByIndexAndDisplayId,
  isPackageMutationSynced
} from './utils';

const {
  mergePackageList,
  cleanMutationsBasedOnPackageList,
  clearMutationsOfDeliveredPackages,
  getPackageMutationByPackageId
} = db;
export {
  mergePackageList,
  cleanMutationsBasedOnPackageList,
  clearMutationsOfDeliveredPackages,
  getPackageMutationByPackageId,
  isMutationStatusEqualToPackageStatus
};
export { updateStatus } from './update-status';
export { statusCodes } from './update-status-codes';

const {
  putPackageDeliveredStatus,
  getPackageDeliveredStatusByPackageId
} = packageInfoDeliveredStatusDB;
export { putPackageDeliveredStatus, getPackageDeliveredStatusByPackageId };

export {
  pendingSyncPackages,
  sortPackagesByIndexAndDisplayId,
  isPackageMutationSynced
};
