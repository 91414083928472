import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Typography, RadioGroup, Radio } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomFormLabel from 'view/atoms/custom-form-label';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function CustomRadioButtonGroup({
  radioValues,
  value,
  onValueChanged,
  ariaLabel
}) {
  const { colors } = useTheme();

  return (
    <RadioGroup
      aria-label={ariaLabel}
      value={value}
      onChange={e => onValueChanged(e.target.value)}
    >
      {radioValues.map(v => (
        <CustomFormLabel
          checked={value === v.key}
          key={v.key}
          value={v.key}
          control={<Radio checkedIcon={<CheckCircleIcon />} />}
          label={
            <Typography
              variant="body2"
              color={colors.neutrals.typeface.primary}
              style={{ fontWeight: 500 }}
            >
              {v.label}
            </Typography>
          }
        />
      ))}
    </RadioGroup>
  );
}

CustomRadioButtonGroup.propTypes = {
  radioValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onValueChanged: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string
};

CustomRadioButtonGroup.defaultProps = {
  ariaLabel: 'custom-form-label'
};
