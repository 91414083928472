import { SORT_BY } from 'infra/services/constants';

const LABELS = {
  MENU_ITEMS: {
    [SORT_BY.SORT_BY_CLOSEST]: 'Mais perto de você',
    [SORT_BY.SORT_BY_HIGHEST_EARNINGS]: 'Ganhos maiores',
    [SORT_BY.SORT_BY_LEAST_STEPS]: 'Menos paradas',
    [SORT_BY.SORT_BY_SHORTEST_ROUTES]: 'Rotas mais curtas'
  },
  MENU_TITLE: 'Ordenar por'
};

export default LABELS;
