import React from 'react';
import { string, func, number } from 'prop-types';
import { Button, Box, Typography, Drawer } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReactComponent as AlertIcon } from './icons/alert-icon.svg';

const STATE_ENUM = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  isOpen: state => state !== STATE_ENUM.CLOSED
};

const TEXTS = {
  prevTitle: 'Você errou o código de confirmação ',
  nextTitle:
    '  vezes. Se não quiser mais tentar, informe o motivo e devolva na base.',
  buttonTryAgain: 'Tentar de novo',
  buttonReportAttempt: 'Informar tentativa'
};

function ProblemsWithSafeDeliveryCode({
  drawerState,
  setDrawerState,
  setDrawerStateProblem,
  countState,
  onProblems
}) {
  const tryAgain = () => {
    setDrawerState(STATE_ENUM.OPEN);
  };

  return (
    <>
      <Drawer
        data-testid="safe-delivery-problems-drawer"
        anchor="bottom"
        open={STATE_ENUM.isOpen(drawerState)}
        onClose={() => setDrawerStateProblem(STATE_ENUM.CLOSED)}
        PaperProps={{ square: false, component: Box, mb: -3 }}
      >
        <Box pb={3}>
          {STATE_ENUM.isOpen(drawerState) && (
            <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
              <Box textAlign="center">
                <AlertIcon data-testid="alert-icon" />
              </Box>

              <Box py={1} display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  align="center"
                  data-testid="problems-text"
                >
                  {`${TEXTS.prevTitle} ${countState} ${TEXTS.nextTitle}`}
                </Typography>
              </Box>

              <Box>
                <Box pb={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={tryAgain}
                  >
                    {TEXTS.buttonTryAgain}
                  </Button>
                </Box>

                <Box pb={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={onProblems}
                  >
                    {TEXTS.buttonReportAttempt}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}

ProblemsWithSafeDeliveryCode.propTypes = {
  drawerState: string.isRequired,
  setDrawerState: func.isRequired,
  setDrawerStateProblem: func.isRequired,
  countState: number,
  onProblems: func.isRequired
};

ProblemsWithSafeDeliveryCode.defaultProps = {
  countState: null
};

export { ProblemsWithSafeDeliveryCode, STATE_ENUM, TEXTS };
