import wretch from 'wretch';
import { dedupe } from 'wretch-middlewares';
import { releaseTag } from '../../../../package.json';
import {
  authMiddleware,
  intercept401Middleware,
  intercept403Middleware,
  intercept410Middleware,
  intercept426Middleware
} from './middlewares';

const storeDedupe = dedupe({
  // we need to store dedupe in this variable for the wretch do not recreate it's instance
  //
  // Avoid send multiple identical requests before receiving a response
  // For now, we're using only for status-update endpoint
  skip: (url, opts) => opts.skipDedupe || !url.includes('status-update'),
  key: (url, opts) => {
    const { body } = opts;
    return `${url}${JSON.stringify(body)}`;
  }
});

export default function apiLoggiWeb() {
  return wretch()
    .url(process.env.REACT_APP_LOGGI_WEB_URL)
    .headers({
      appVersion: process.env.REACT_APP_VERSION || releaseTag,
      service: 'flecha'
    })
    .middlewares(
      [
        authMiddleware,
        intercept401Middleware,
        intercept410Middleware,
        intercept403Middleware,
        intercept426Middleware,
        storeDedupe
      ],
      true
    )
    .errorType('json');
}
