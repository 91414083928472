import React, { useState } from 'react';
import sharedPropTypes from 'view/shared-prop-types';
import { PropTypes } from 'prop-types';
import { PAGES } from 'view';
import { useHistory } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { updateAssignmentWaypointCargos } from 'operations/assignment-list';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import { getUndeliveredCargosForWaypoint } from 'operations/pickup';
import messages from '../messages';

export default function DeliveryToDcButton({ assignment, waypointId }) {
  const history = useHistory();
  const [isFetchingPendingCargos, setIsFetchingPendingCargos] = useState(false);
  const [pendingCargos, setPendingCargos] = useState(assignment.cargos);

  const [error, setError] = useState(messages.emptyDrawer);
  const [notificationDrawerState, setNotificationDrawerState] = useState(
    DRAWER_STATE.CLOSED
  );
  const [apiErrorNotificationState, setApiErrorNotificationState] = useState(
    DRAWER_STATE.CLOSED
  );
  const [apiError, setApiError] = useState(messages.emptyDrawer);
  const [isDeliverySuccess, setIsDeliverySuccess] = useState(false);

  const updatePageWithPendingCargos = async () => {
    const mutatedAssignmentList = await updateAssignmentWaypointCargos(
      waypointId,
      pendingCargos
    );

    return history.replace({
      pathname: PAGES.STOP_POINT,
      state: {
        assignmentWaypoint: mutatedAssignmentList.assignmentsWaypoints[0]
      }
    });
  };

  const handleApiResponse = newPendingCargos => {
    setIsFetchingPendingCargos(false);
    setPendingCargos(newPendingCargos);

    if (newPendingCargos.length > 0) {
      setError(messages.hasPendingPkgs);
      setNotificationDrawerState(DRAWER_STATE.OPEN);
    } else {
      setIsDeliverySuccess(true);
      setTimeout(() => {
        history.push('/');
      }, 2000);
    }
  };

  const handleDeliverClick = () => {
    setIsFetchingPendingCargos(true);

    getUndeliveredCargosForWaypoint(waypointId)
      .json(response => {
        const res = response?.assignmentCargos || [];
        handleApiResponse(res);
      })
      .catch(e => {
        setIsFetchingPendingCargos(false);
        setApiErrorNotificationState(DRAWER_STATE.OPEN);
        setApiError(messages.apiResponseError(e.json?.message));
      });
  };

  return (
    <>
      <Box
        px={3.5}
        py={2.5}
        position="fixed"
        bottom="0"
        bgcolor="white"
        width="100%"
        zIndex={2}
        borderTop={`1px solid ${colors.smoke[300]}`}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDeliverClick}
          fullWidth
        >
          {isFetchingPendingCargos ? (
            <CircularProgress size={24} />
          ) : (
            messages.deliveryToAgency.deliveryButton
          )}
        </Button>
      </Box>

      <NotificationDrawer
        drawerState={notificationDrawerState}
        setDrawerState={setNotificationDrawerState}
        data={{
          icon: error.icon,
          title: `Ainda tem ${pendingCargos.length} pacotes com você.`,
          description: error.description,
          buttonVariant: error.buttonVariant,
          button: error.button
        }}
        onClose={updatePageWithPendingCargos}
      />

      <NotificationDrawer
        drawerState={apiErrorNotificationState}
        setDrawerState={setApiErrorNotificationState}
        data={{
          icon: apiError.icon,
          title: apiError.title,
          description: apiError.description,
          buttonVariant: apiError.buttonVariant,
          button: apiError.button
        }}
      />

      <Alert
        message="Entrega finalizada com sucesso"
        open={isDeliverySuccess}
        startAdornment={ALERT_TYPES.SUCCESS.startAdornment}
        color={ALERT_TYPES.SUCCESS.color}
        onClose={() => setIsDeliverySuccess(false)}
      />
    </>
  );
}

DeliveryToDcButton.propTypes = {
  assignment: sharedPropTypes.assignment.isRequired,
  waypointId: PropTypes.string.isRequired
};
