export const facadePhotoTypes = {
  deliveryFacadePhotoDelivery: 'deliveryFacadePhotoDelivery',
  deliveryMailBox: 'deliveryMailBox',
  pickupFacadePhoto: 'pickupFacadePhoto'
};

export const messages = {
  takePhotoButton: 'Tirar foto',
  cannotTakePhoto: 'Não é possível tirar a foto',
  takeAnotherPhoto: 'Tirar outra',
  sendPhoto: 'Enviar foto',
  linkToHelpCenter: 'Precisa de ajuda? Veja as dicas aqui.',
  helpLink: 'https://ajuda.loggi.com/hc/pt-br/articles/4403307520653',
  delivery: {
    title: 'Tire uma foto mostrando a fachada do local'
  },
  mailbox: {
    title: 'Mostre a caixa de correspondência',
    subtitle: 'Tire uma foto que mostre ',
    continueSubtitleBold: 'a fachada e a caixa de correspondência.',
    finishSubtitle: ' Tente também enquadrar o número do local.'
  },
  pickup: {
    title: 'Tire uma foto do local',
    subtitle:
      'Precisamos de uma comprovação que você está no local da retirada dos itens.',
    successTitle: 'Foto Enviada!',
    successButtonTitle: 'Voltar para atividades'
  }
};
