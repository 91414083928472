import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Button,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { PAGES } from 'view';
import { colors } from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { cancelPickup } from 'operations/pickup/pickup';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import PickupProblemComplementInput from 'view/pages/pickup/problems/pickup-problem-complement-input';

export const cancelationReasons = {
  unableToGo: {
    text: 'Não vou chegar a tempo',
    description:
      'Imprevisto no caminho \u2022 Trânsito \u2022 Alagamento ou enchente',
    slug: 'wont_arrive_in_time',
    show: true
  },
  vehicleProblems: {
    text: 'Problemas com o veículo',
    description: 'Rodízio \u2022 Manutenção',
    slug: 'vehicle_problems',
    show: true
  },
  anotherActivity: {
    text: 'Escolhi outra atividade',
    description: 'Fora da Loggi \u2022 Motivo pessoal',
    slug: 'another_activity',
    show: true
  },
  noChoiceRoute: {
    text: 'Não gostei das opções',
    description:
      'Rotas longas \u2022 Quantidade de pacotes \u2022 Valor de repasse baixo',
    slug: 'no_choice',
    show: false
  }
};
export const snackBarMessages = {
  canceledReserve:
    'Oferta programada  cancelada e  removida do final da sua lista.',
  weakInternet: 'Sua internet está fraca, não foi possível cancelar.',
  cantCancel: 'Não foi possível cancelar.'
};

export const messages = {
  headerText: (
    <Typography variant="h5">
      Por que você quer <b>cancelar a programação?</b>
    </Typography>
  ),
  buttonMessage: 'Cancelar programação'
};

export default function CapacityReserveCancelation() {
  const history = useHistory();
  const [optionValue, setOptionValue] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { pickup, isFromIdentificationDrawer } = history.location?.state || {};

  const showSnackbarResponse = (message, variant) => {
    showSnackbar({
      message,
      variant,
      enqueueSnackbar
    });
  };

  const lmcsEnabledToCallAllocation = useFeatureSwitch(
    featureSwitches.callAllocationDirectly
  );
  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    lmcsEnabledToCallAllocation
  );
  const sendDriverLocationOnQuitPickup = useFeatureSwitch(
    featureSwitches.sendDriverLocationOnQuitPickup
  );
  const enableProblemComplementAntecipatedOffer = useFeatureSwitch(
    featureSwitches.enableProblemComplementAntecipatedOffer
  );
  const [problemComplement, setProblemComplement] = useState('');

  const cancelPickupAction = () => {
    cancelPickup({
      pickup,
      problemSlug: optionValue,
      problemComplement,
      ignoreProxy,
      sendDriverPosition: sendDriverLocationOnQuitPickup
    }).then(result => {
      result
        .fetchError(() => {
          showSnackbarResponse(snackBarMessages.weakInternet, 'error');
        })
        .json(() => {
          showSnackbarResponse(
            snackBarMessages.canceledReserve,
            'successSnackbar'
          );
          history.push(PAGES.PACKAGE_LIST);
        })
        .catch(() => {
          showSnackbarResponse(snackBarMessages.cantCancel, 'error');
        });
    });
  };

  const confirmingCancelIsDisabled =
    !problemComplement &&
    enableProblemComplementAntecipatedOffer &&
    !optionValue;

  const handleChange = e => {
    setOptionValue(e.target.value);
  };

  cancelationReasons.noChoiceRoute.show = !!isFromIdentificationDrawer;

  const problems = Object.values(cancelationReasons);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box py={3} px={1}>
        <IconButton
          data-testid="capacity-cancelation-back-button"
          onClick={() => history.goBack()}
          style={{ color: colors.smoke[900] }}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      <Box px={3}>{messages.headerText}</Box>
      <Box flexGrow={1}>
        <List>
          <RadioGroup value={optionValue} onChange={handleChange}>
            {problems
              .filter(reason => reason.show)
              .map(reason => {
                return (
                  <ListItem divider key={reason.slug}>
                    <Box p={1} display="flex">
                      <FormControlLabel
                        value={reason.slug}
                        control={<Radio />}
                        label={
                          <Box px={1}>
                            <Typography variant="body1">
                              {reason.text}
                            </Typography>{' '}
                            <Box color={colors.smoke[700]}>
                              <Typography variant="body2">
                                {reason.description}
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </ListItem>
                );
              })}
          </RadioGroup>
        </List>
      </Box>
      <Box flexGrow={1} px={3}>
        {enableProblemComplementAntecipatedOffer && (
          <PickupProblemComplementInput
            onChange={setProblemComplement}
            problemComplement={problemComplement}
          />
        )}
      </Box>
      <Box p={3}>
        <Button
          disabled={confirmingCancelIsDisabled}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={cancelPickupAction}
        >
          {messages.buttonMessage}
        </Button>
      </Box>
    </Box>
  );
}
