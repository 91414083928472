import React from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';

import { Box } from '@material-ui/core';

import FinishDelivering from 'view/molecules/finish-delivering';
import ListWithData from 'view/molecules/assignment-list';
import GoogleMapsRef from 'view/pages/assignment-list/google-maps/google-maps-ref';

import { getCurrentUser } from 'operations/auth/credentials-storage';

import {
  ProgressInfo,
  OverlayingCard,
  ContainerHeaderInfo,
  AskForOfferRouteBox
} from './components';

export default function ListWithDataState({
  packages,
  pickups,
  assignmentsWaypoints,
  onItemClick,
  onPickupItemClick,
  retryPackageSync,
  updateList,
  offerRouteButtonCallback
}) {
  const getCurrentUserCompanyType = () => {
    const currentUser = getCurrentUser();
    return currentUser?.companyType || '';
  };

  const isUserLeve =
    getCurrentUserCompanyType() === 'LAST_MILE_COMPANY_TYPE_LEVE';

  return (
    <>
      <ContainerHeaderInfo>
        <Box>
          <ProgressInfo
            packages={packages}
            pickups={pickups}
            updateList={updateList}
            assignmentsWaypoints={assignmentsWaypoints}
          />
        </Box>
      </ContainerHeaderInfo>

      <GoogleMapsRef />

      <OverlayingCard>
        <ListWithData
          packages={packages}
          pickups={pickups}
          assignmentsWaypoints={assignmentsWaypoints}
          onPackageClick={onItemClick}
          onPickupClick={onPickupItemClick}
          onRetry={retryPackageSync}
        />

        {isUserLeve && Boolean(packages?.length) && (
          <AskForOfferRouteBox successCallback={offerRouteButtonCallback} />
        )}

        <FinishDelivering canTryAutoFinish={false} />
      </OverlayingCard>
    </>
  );
}
ListWithDataState.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  pickups: SharedPropTypes.pickups.isRequired,
  assignmentsWaypoints: SharedPropTypes.assignmentsWaypoints.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onPickupItemClick: PropTypes.func.isRequired,
  retryPackageSync: PropTypes.func.isRequired,
  updateList: PropTypes.node.isRequired,
  offerRouteButtonCallback: PropTypes.func.isRequired
};
