import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Typography, Box, Snackbar, SnackbarContent } from '@material-ui/core';

import { ReactComponent as AlertIcon } from './icons/ic-alert.svg';
import { ReactComponent as CheckIcon } from './icons/ic-check.svg';
import { ReactComponent as ErrorIcon } from './icons/ic-error.svg';
import { ReactComponent as InfoIcon } from './icons/ic-info.svg';

const autoHideDuration = 5000;

const variantIcon = {
  success: CheckIcon,
  warning: AlertIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const SnackbarAlert = forwardRef((props, ref) => {
  const { id, open, message, variant } = props;
  const Icon = variantIcon[variant];

  const { closeSnackbar } = useSnackbar();

  return (
    <Snackbar
      ref={ref}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => closeSnackbar(id)}
    >
      <SnackbarContent
        message={
          <Box
            display="flex"
            alignItems="center"
            data-testid="snackbar-content"
          >
            <Box pr={2.5}>
              <Icon />
            </Box>
            <Typography variant="body1">{message}</Typography>
          </Box>
        }
      />
    </Snackbar>
  );
});

SnackbarAlert.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default SnackbarAlert;
