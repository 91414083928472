import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { interpret, Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import { colors } from '@loggi/mar';
import deliveryEvidenceDB from 'operations/update-status/delivery-evidence-db';
import sharedPropTypes from 'view/shared-prop-types';
import signatureMachine, { ACTIONS, STATES } from './machine';
import SignatureComponent from './signature-component/index';
import cropImage from './utils/index';

export { signatureMachine };

export const messageText = {
  title: 'Colete a assinatura de quem recebeu o pacote',
  buttonTouchHandIcon: 'Toque para assinar',
  buttonRedoSignature: 'Refazer',
  buttonSend: 'Enviar'
};

export default function Signature({
  onGoBack,
  signatureMachineRef,
  digitalSignatureContext
}) {
  const [current, send] = useService(signatureMachineRef);
  const [signature, setSignature] = useState(undefined);
  const [clear, setClear] = useState(false);

  const isCanvasBlank = canvas => {
    if (!canvas) {
      return true;
    }
    const context = canvas.getContext('2d');

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );
    return !pixelBuffer.some(color => color !== 0);
  };

  function buildDigitalSignature() {
    const digitalSignature = Buffer.from(
      signature.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );

    return {
      packageId: digitalSignatureContext.pkg.packageId,
      deliveryEvidence: {
        image: digitalSignature,
        url: digitalSignatureContext.pkg.digitalSignatureSignedUrl.signedUrl,
        contentType: 'image/jpeg'
      }
    };
  }

  const onContinue = () => {
    if (digitalSignatureContext.pkg.digitalSignatureSignedUrl) {
      deliveryEvidenceDB.putDeliveryEvidence(buildDigitalSignature());
    }

    send(ACTIONS.CONTINUE);
  };
  const onRedo = () => setClear(true);
  const onChange = canvas => {
    const image = isCanvasBlank(canvas) ? undefined : cropImage(canvas);
    setSignature(image);
    send(ACTIONS.CHANGE, {
      value: { signature: image }
    });
  };

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear, setClear]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box pt={0.5} display="flex" flexDirection="column">
        <Box py={2} px={0.5}>
          <IconButton onClick={onGoBack} data-testid="signature-back-button">
            <ArrowBack style={{ color: colors.blue[500] }} />
          </IconButton>
        </Box>
      </Box>

      {current.value === STATES.initStep && (
        <>
          <Box p={2.5} color={colors.smoke[900]}>
            <Box>
              <Typography variant="h5">{messageText.title}</Typography>
            </Box>
          </Box>
          <Box px={3.5} height="100%">
            <Box
              data-testid="buttonTouch"
              width="100%"
              height="100%"
              borderRadius="16px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: colors.smoke[50],
                color: colors.smoke[500]
              }}
              onClick={() => send(ACTIONS.SIGNATURE)}
            >
              <Box data-testid="icon-emoji">
                <TouchAppOutlinedIcon aria-label="Hand down" color="primary" />
              </Box>
              <Box>
                <Typography data-testid="driver-distance-alert-template">
                  {messageText.buttonTouchHandIcon}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {current.value === STATES.signatureStep && (
        <SignatureComponent
          style={{
            backgroundColor: colors.smoke[50],
            borderRadius: '16px',
            flexGrow: 1,
            position: 'relative'
          }}
          clear={clear}
          onChange={onChange}
          mx={2.5}
          data-testid="signature-container"
        />
      )}

      <Box px={3.5} py={2.5}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item xs>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              disabled={signature === undefined}
              onClick={onRedo}
            >
              {messageText.buttonRedoSignature}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={signature === undefined}
              onClick={onContinue}
            >
              {messageText.buttonSend}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export const makeSignatureMachineRef = () => {
  const service = interpret(signatureMachine);
  service.start();
  return service;
};

Signature.propTypes = {
  signatureMachineRef: PropTypes.instanceOf(Interpreter),
  onGoBack: PropTypes.func,
  digitalSignatureContext: sharedPropTypes.digitalSignatureContext
};

Signature.defaultProps = {
  signatureMachineRef: makeSignatureMachineRef(),
  onGoBack: undefined,
  digitalSignatureContext: null
};
