import React, { useState } from 'react';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import colors from '@loggi/mar/src/themes/drivers/colors';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Divider
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';

import {
  parseISODateToHours,
  getDiffInDaysBetweenDateAndNow,
  noDecimalFormatDistance
} from 'view/molecules/offer-card/util';
import getDistance from 'infra/services/get-distance';
import { ReactComponent as InformationIcon } from '../icons/information-capacity-reserve.svg';
import CONSTANTS from '../constants';

const getFormattedDeadlineMessage = (startTime, deadlineTime) => {
  const days = getDiffInDaysBetweenDateAndNow(startTime);

  let startDayString = CONSTANTS.TODAY;
  if (days > 1) {
    const datetime = new Date(startTime);
    const dateFormatOptions = { month: '2-digit', day: '2-digit' };
    startDayString = datetime.toLocaleDateString('pt-br', dateFormatOptions);
  } else if (days === 1) {
    startDayString = CONSTANTS.TOMORROW;
  }

  const startHour = parseISODateToHours(startTime);
  const deadlineHour = parseISODateToHours(deadlineTime);
  return `${startDayString}, ${startHour} ${CONSTANTS.TO} ${deadlineHour}`;
};

export const messages = {
  title: (startTimeDate, endTimeDate) => (
    <>
      {CONSTANTS.CAPACITY_RESERVE.V2.TITLE} <br />
      {getFormattedDeadlineMessage(startTimeDate, endTimeDate)}
    </>
  )
};

function CapacityReserveDetails({ offer, onAccept, onBack }) {
  const [distance, setDistance] = useState('-');

  getDistance(offer.waypoints[0].coordinates)
    .then(dist => setDistance(noDecimalFormatDistance(dist)))
    .catch(() => {});

  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        bgcolor={colors.root[0]}
        color={colors.neutrals.typeface.primary}
        mt={-1}
        data-testid="capacity-reserve-details-v2"
        overflow="auto"
      >
        <Box
          py={2}
          px={3}
          pl={1.5}
          display="flex"
          justifyContent="space-between"
        >
          <IconButton onClick={onBack} data-testid="capacity-back-button">
            <ArrowBack />
          </IconButton>
        </Box>

        <Box my={1} px={3} display="flex">
          <Typography variant="h5">
            <Box fontWeight="fontWeightMedium">
              {messages.title(offer.startTimeCollect, offer.deadlineCollect)}
            </Box>
          </Typography>
        </Box>
        <Box mt={3} px={3} flexGrow={1}>
          <Box py={1}>
            <Typography variant="caption">
              <Box
                fontWeight="fontWeightMedium"
                color={colors.neutrals.typeface.secondary}
              >
                Retirar pacotes em
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box fontWeight="fontWeightMedium">{`Agência ${
                offer.waypoints[0]?.neighborhood
              }`}</Box>
            </Typography>
            <Typography component="span" variant="body2">
              <Box py={1} color={colors.neutrals.typeface.secondary}>
                {offer.waypoints[0]?.loggiAddress}
              </Box>
              <Box color={colors.neutrals.typeface.secondary}>
                Ponto há {distance} de você
              </Box>
            </Typography>
          </Box>

          <Box mt={2} py={1}>
            <Typography variant="caption">
              <Box
                fontWeight="fontWeightMedium"
                color={colors.neutrals.typeface.secondary}
              >
                Rota prevista para região de
              </Box>
            </Typography>
            <Typography variant="h6">
              <Box fontWeight="fontWeightMedium">{offer.regionName}</Box>
            </Typography>
            <Typography component="span" variant="body2">
              <Box color={colors.neutrals.typeface.secondary} pt={1}>
                A região de entrega pode incluir bairros vizinhos.
              </Box>
            </Typography>
          </Box>
        </Box>

        <Box my={3} px={3}>
          <Box
            borderRadius={16}
            textAlign="left"
            bgcolor={colors.brand.lighter}
            p={3}
          >
            <InformationIcon />

            <Typography component="span" variant="body1">
              <Box pt={2} fontWeight="fontWeightMedium">
                Bom lembrar!
              </Box>
            </Typography>
            <Typography component="span" variant="body2">
              <Box pt={1}>
                É importante chegar durante o horário de retirada da oferta.
              </Box>
            </Typography>
          </Box>
        </Box>

        <Box position="sticky" bottom="0" bgcolor={colors.root[0]}>
          <Divider style={{ backgroundColor: colors.smoke[100] }} />
          <Box px={3} mt={2} pb={1}>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                onAccept(offer);
              }}
            >
              {CONSTANTS.CAPACITY_RESERVE.V2.BUTTON_TEXT}
            </Button>
            <Typography component="span" variant="body2">
              <Box pt={2} color={colors.neutrals.typeface.secondary}>
                O valor da oferta e as paradas da rota serão informadas no
                momento da retirada.
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

CapacityReserveDetails.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  offer: PropTypes.shape({
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string.isRequired,
    startTimeCollect: PropTypes.string.isRequired,
    regionName: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired
};

export default React.memo(CapacityReserveDetails);
