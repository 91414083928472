import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Typography, FormGroup, Checkbox } from '@material-ui/core';
import CustomFormLabel from 'view/atoms/custom-form-label';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function CustomCheckboxGroup({
  options,
  onValueChanged,
  ariaLabel
}) {
  const { colors } = useTheme();

  return (
    <FormGroup aria-label={ariaLabel}>
      {options.map(option => (
        <CustomFormLabel
          checked={option.checked}
          key={option.key}
          value={option.key}
          control={<Checkbox />}
          label={
            <Typography
              variant="body2"
              color={colors.neutrals.typeface.primary}
              style={{ fontWeight: 500 }}
            >
              {option.label}
            </Typography>
          }
          onChange={e => {
            onValueChanged(option.key, e.target.checked);
          }}
        />
      ))}
    </FormGroup>
  );
}

CustomCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onValueChanged: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string
};

CustomCheckboxGroup.defaultProps = {
  ariaLabel: 'custom-checkbox-form-label'
};
