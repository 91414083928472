import React from 'react';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import {
  List,
  ListItem,
  Box,
  Typography,
  IconButton,
  Drawer,
  Button
} from '@material-ui/core';
import { ArrowBack, ChevronRight } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import { ReactComponent as WifiOffIcon } from 'view/molecules/notification-drawer/icons/no-wifi.svg';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PAGES } from 'view';
import PhoneBox from 'view/molecules/phone-box';
import { ReactComponent as EmergencyButton } from 'view/templates/delivering-problems/icons/emergency-button-blue.svg';
import { useRoundedBorder } from 'view/utils/styles';
import { Machine } from 'xstate';
import { LoadingList } from '../pickup-packages';
import { STATES } from './pickup-problems.machine';
import messages from '../messages';
import { pickupFlowEnum } from '../constants';

export function NotificationDrawer({ isOpen, machine, handleBack }) {
  const roundedBorder = useRoundedBorder();

  const ERROR_ICON_MAP = {
    noWifi: <WifiOffIcon data-testid="network-error-icon" />,
    error: <ErrorOutlineIcon data-testid="error-icon" />
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={handleBack}
      classes={{ paper: roundedBorder.root }}
    >
      {isOpen && (
        <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
          <Box textAlign="center">
            {ERROR_ICON_MAP[machine.context.errorIcon]}
          </Box>
          <Box
            py={1}
            color={colors.smoke[700]}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="body2" align="center">
              {machine.context.errorMessage}
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleBack}
            >
              {messages.pickupProblems.buttonErrorConfirm}
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

NotificationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  machine: PropTypes.shape({
    context: PropTypes.shape({
      errorMessage: PropTypes.string,
      errorIcon: PropTypes.string
    }).isRequired
  }).isRequired,
  handleBack: PropTypes.func.isRequired
};

export default function PickupProblems({ handleBack, pickupProblemsMachine }) {
  const location = useLocation();

  const history = useHistory();

  const { url } = useRouteMatch();

  const [current] = useService(pickupProblemsMachine);

  const isDrawerOpen = current.matches(STATES.error);

  const listView = current.matches(STATES.loading) ? (
    <LoadingList />
  ) : (
    <Box>
      <Box mx={3} mt={2}>
        <Typography variant="h5">{messages.pickupProblems.title}</Typography>
      </Box>
      <List>
        {current.context.problems.map(problem => (
          <ListItem
            button
            divider
            key={problem.slug}
            onClick={() => {
              history.push(`${url}/${problem.slug}`, location.state);
            }}
            style={{ paddingRight: 5 }}
          >
            <Box
              mx={1.25}
              my={1.5}
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box flex={1} justifyContent="flex-start">
                <Typography variant="body1">{problem.description}</Typography>
              </Box>
              <Box
                color={colors.blue[500]}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ChevronRight data-testid="chevron-right-icon" />
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            p={2.5}
            px={0.5}
          >
            <IconButton onClick={() => history.goBack()} data-testid="back">
              <ArrowBack color="primary" />
            </IconButton>
            <Box pr={0.5}>
              {' '}
              <IconButton
                component={Link}
                to={PAGES.EMERGENCY}
                data-testid="emergency-button"
              >
                <EmergencyButton />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          {listView}
        </Box>
        {current.context.pickup?.pickupFlow === pickupFlowEnum.useBarcodeBip &&
          current.context.pickup.shipper?.phone && (
            <Box
              mx={3.5}
              mt={2}
              onClick={() => {
                firebase.analytics().logEvent('phone_call', {
                  page_title: 'problems_list_ui'
                });
              }}
            >
              <PhoneBox recipient={current.context.pickup.shipper} />
            </Box>
          )}
      </Box>
      <NotificationDrawer
        isOpen={isDrawerOpen}
        machine={current}
        handleBack={handleBack}
      />
    </>
  );
}

PickupProblems.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pickupProblemsMachine: PropTypes.instanceOf(Machine).isRequired
};

PickupProblems.url = '/problemas';
