// eslint-disable-next-line import/no-named-as-default
import api from '../config/api';

/**
 * @typedef {Object} Package
 *
 * @property {string} Package.packageId
 * @property {string} Package.barcode
 * @property {string} Package.assignedTo
 * @property {string} Package.companyName
 *
 * @property {Object} Package.status
 * @property {number} Package.status.code
 * @property {string} Package.status.description
 * @property {string} Package.status.updated
 *
 * @property {Object} Package.recipient
 * @property {string} Package.recipient.name
 * @property {string} Package.recipient.phone
 *
 * @property {Object} Package.destination
 * @property {string} Package.destination.addressStreet
 * @property {string} Package.destination.addressNumber
 * @property {string} Package.destination.addressComplement
 * @property {string} Package.destination.city
 * @property {string} Package.destination.state
 * @property {string} Package.destination.vicinity
 * @property {string} Package.destination.zipCode
 * @property {number} Package.destination.lat
 * @property {number} Package.destination.lng
 * @property {number} Package.destination.distance
 *
 * @property {Object} Package.safeDelivery
 * @property {number} Package.safeDelivery.safeDeliveryId
 * @property {string} Package.safeDelivery.tokenHash
 * @property {string} Package.safeDelivery.salt
 *
 * @property {boolean} Package.doNotAcceptMailboxDelivery
 * @property {boolean} Package.canDeliveryMailbox
 * @property {boolean} Package.shouldCheckDocument
 * @property {boolean} Package.canDeliverySignature
 * @property {boolean} Package.canShowDeliveryOptions
 * @property {boolean} Package.canCollectDigitalSignatureAsDefault
 *
 */

/**
 * @typedef {Object} Pickup
 * @property {Object} Pickup.destination
 * @property {string} Pickup.destination.addressStreet
 * @property {string} Pickup.destination.addressNumber
 * @property {string} Pickup.destination.addressComplement
 * @property {string} Pickup.destination.city
 * @property {string} Pickup.destination.state
 * @property {string} Pickup.destination.vicinity
 * @property {string} Pickup.destination.zipCode
 * @property {number} Pickup.destination.lat
 * @property {number} Pickup.destination.lng
 * @property {number} Pickup.destination.distance
 * @property {Object} Pickup.shipper
 * @property {string} Pickup.shipper.name
 * @property {string} Pickup.shipper.phone
 * @property {string} Pickup.pickupCode
 */

/**
 * @typedef {Object} AssignmentListResponse
 * `data` is the response that was provided by the server.
 * The response should include an array of packages and an array
 * of pickups.
 *
 * @property {Array<Package>} AssignmentListResponse.packages
 * `data.packages` The list of packages returned in the response.
 * @property {Array<Pickup>} AssignmentListResponse.pickups
 * `data.pickups` The list of pickups returned in the response
 */

/**
 * Wrapped GET request on Leve's API to return a list of packages
 * to deliver and a list of pickups of current user
 *
 * @param {Object} filter.pos
 * `pos` is used to inform to the backend the current location of the user to sort
 * records by the distance until this location
 *
 * @param {number} filter.pos.latitude
 * @param {number} filter.pos.longitude
 *
 * @example
 * // An example of the inputed data
 * const response = await AssignmentList({
 *   pos: {
 *     lat: -12.548684,
 *     lng: -55.719810
 *   }
 * });
 *
 * console.log(response);
 * // An example of the output
 * {
 *   data: {
 *     packages: [
 *       {
 *          "packageId": "330949",
 *          "barcode": "0fdafa9",
 *          "assignedTo": "Mauricio Guerta3",
 *          "companyName": "Loggi",
 *          "status": {
 *            "code": 0,
 *            "description": "Não disponível",
 *            "updated": "2020-01-28T18:06:11.046Z"
 *          },
 *          "recipient": {
 *            "name": "João Dorimê Amenô",
 *            "phone": "5511912345600",
 *            "document": ""
 *          },
 *          "destination": {
 *            "addressStreet": "Township Hwy 132",
 *            "addressNumber": "24189",
 *            "addressComplement": "close to a heart. This address is literally close to a heart, see on the map",
 *            "zipCode": "44028",
 *            "city": "Columbia Station",
 *            "state": "OH",
 *            "vicinity": "Heidi Smith",
 *            "lat": 41.303921,
 *            "lng": -81.901693,
 *            "distance": 1000.0
 *          },
 *          "assignmentIndex": "1",
 *          "assignmentDisplayId": "1",
 *          "deliveryEvidenceSignedUrl": {
 *            "signedUrl": "http://aws.s3"
 *          }
 *        }
 *     ],
 *     pickups: [
 *      {
 *          destination: {
 *           addressStreet: 'Rua Aurora - Não disponível',
 *            addressNumber: '267',
 *            addressComplement: 'Apartamento 63',
 *            city: 'Sao Paulo',
 *            state: 'SP',
 *            vicinity: 'Santa Ifigênia',
 *            zipCode: '01209-001',
 *            lat: -23.575491,
 *            lng: -46.531361,
 *            distance: 100.0
 *          },
 *          shipper: {
 *            name: 'Pequeno empresario LTDA',
 *            phone: '11999999988'
 *          },
 *          pickupCode: '3487'
 *        },
 *        {
 *          destination: {
 *            addressStreet: 'Rua Chinforimpula - Não disponível',
 *            addressNumber: '137',
 *            addressComplement: 'Apartamento 21',
 *            city: 'Sao Paulo',
 *            state: 'SP',
 *            vicinity: 'Chorifompila',
 *            zipCode: '01222-001',
 *            lat: -23.575491,
 *            lng: -46.531361,
 *            distance: 100.0
 *         },
 *         shipper: {
 *            name: 'Outro Gato LTDA',
 *            phone: '11999992030'
 *          },
 *          pickupCode: '1234'
 *        }
 *    ],
 *		pagination: {
 *			numberPages: "-1",
 *			numberItems: "3",
 *			page: "1",
 *			itemsPerPage: "15"
 *		},
 *		time: "2020-03-06T14:10:15.151036Z"
 *   },
 *   status: 200,
 *   statusText: 'OK',
 *   headers: {},
 *   config: {},
 *   request: {}
 * }
 *
 * @return {Promise<AssignmentListResponse>} Wretch response object that contain an `AssignmentListResponse`
 */
export default function getAssignmentListService({ pos }) {
  const latLngQueryParameters =
    +pos.latitude && +pos.longitude
      ? `lat=${pos.latitude}&lng=${pos.longitude}&`
      : '';

  return api.get(
    `/last-mile/v1/shipment/assignment_list?${latLngQueryParameters}`
  );
}
