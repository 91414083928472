import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import SharedPropTypes from 'view/shared-prop-types';
import StatusCodeChip from 'view/atoms/status-code-chip';
import { TEXT } from 'view/templates/package-details/constants';
import CloseIcon from '@material-ui/icons/Close';
import { statusCodes } from 'operations/update-status';
import { capitalizeString } from 'view/text-formatters';
import { buildLoggiAddressV2, splitLoggiAddress } from 'view/utils';
import { deliveryFlowEnum } from 'view/pages/assignment-list/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PAGES } from 'view';
import DeadlineTime from '../assignment-row/deadline-time';
import AssignmentIndex from '../assignment-row/assignment-index';
import DeliveredPackageReminder from '../delivered-package-reminder';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */

export default function PackageDetailsDrawer({ open, onCloseClick, pkg }) {
  const [
    openDeliveredPackageReminder,
    setOpenDeliveredPackageReminder
  ] = useState(false);
  const { colors } = useTheme();

  const history = useHistory();

  const addressLines = buildLoggiAddressV2(pkg.addressDestination);
  const hasLoggiAddress = !!addressLines;
  const { addressTitle, addressBody } = splitLoggiAddress(addressLines);

  const isDeliveryToDc = deliveryFlowEnum === 'deliver_to_dc';

  const deadlineTime = pkg.deadlineDeliveryTime;

  const {
    recipient: { name: recipientName },
    barcode = '',
    destination: { addressStreet, addressNumber, vicinity, city, state },
    doNotAcceptMailboxDelivery = false,
    canDeliveryMailbox = false,
    deliveryEvidenceSignedUrl = null
  } = pkg;

  const statusCode = pkg.status?.code;

  const [effectiveAddressTitle, effectiveAddressBody] = hasLoggiAddress
    ? [addressTitle, addressBody]
    : [
        `${capitalizeString(addressStreet)}, ${addressNumber}`,
        `${capitalizeString(vicinity)}, ${city} - ${state}`
      ];

  const hasDeliveryMailbox = canDeliveryMailbox && deliveryEvidenceSignedUrl;

  const configWarning = hasDeliveryMailbox
    ? { color: colors.status.attention.light, text: TEXT.MAILBOX_ALERT }
    : {
        color: colors.status.negative.light,
        text: TEXT.PREVENT_MAILBOX_WARNING
      };

  const showWarningMailBox = doNotAcceptMailboxDelivery || hasDeliveryMailbox;

  const redirectToDeliveryProblems = () => {
    if (statusCode === statusCodes.DELIVERED) {
      setOpenDeliveredPackageReminder(true);
    } else {
      history.push({
        pathname: PAGES.DELIVERING_PROBLEMS,
        state: {
          pkg
        }
      });
    }
  };

  return (
    <Drawer
      data-testid="package-details-drawer"
      open={open}
      onClose={onCloseClick}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
    >
      <Box display="flex" justifyContent="flex-end" mr={0.5}>
        <IconButton
          onClick={onCloseClick}
          data-testid="info-drawer-close-button"
        >
          <CloseIcon style={{ color: colors.neutrals.typeface.primary }} />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} p={2.5}>
        <Box width="100%" textAlign="left">
          <Box display="flex" mb={3} justifyContent="space-between">
            <AssignmentIndex
              assignmentIndex={pkg.assignmentDisplayId}
              useNewTheme
              assignmentStatusCode={statusCode}
            />
            {statusCode >= 0 && (
              <StatusCodeChip status={statusCode} useNewTheme />
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="100%">
              <Box color={colors.neutrals.typeface.secondary}>
                <Box display="flex" justifyContent="space-between">
                  <DeadlineTime
                    deadlineTime={deadlineTime}
                    showNewDeadlineTime
                    statusCode={statusCode}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={2} mb={2}>
            <Typography variant="subtitle1" style={{ color: '#666666' }}>
              {effectiveAddressTitle} {effectiveAddressBody}
            </Typography>
          </Box>
          <Box mt={3} borderLeft={3} borderColor={colors.smoke[100]}>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              paddingLeft={2}
            >
              <Box width="100%" textAlign="left">
                <Grid container alignItems="center">
                  <Grid xs item>
                    <Box pb={0.5} color={colors.neutrals.typeface.secondary}>
                      <Typography variant="body2">Código do pacote</Typography>
                    </Box>
                    <Box color={colors.neutrals.typeface.primary}>
                      <Typography variant="body1">{barcode}</Typography>
                    </Box>
                    <Box
                      pt={2}
                      pb={0.5}
                      color={colors.neutrals.typeface.secondary}
                    >
                      <Typography variant="body2">Entregar para</Typography>
                    </Box>
                    <Box color={colors.neutrals.typeface.primary}>
                      <Typography variant="body1">
                        {capitalizeString(recipientName)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {showWarningMailBox && (
            <>
              <Box
                mt={3}
                mb={1}
                width="100%"
                textAlign="left"
                color={colors.neutrals.typeface.secondary}
              >
                <Typography variant="button">Dicas para entregar</Typography>
              </Box>
              <Box
                py={1.5}
                pl={2.5}
                pr={1.5}
                borderRadius={17}
                display="flex"
                alignItems="center"
                bgcolor={configWarning.color}
                color={colors.neutrals.typeface.primary}
                width="100%"
                textAlign="left"
              >
                <Typography variant="button">{configWarning.text}</Typography>
              </Box>
            </>
          )}
          <Box pt={3}>
            {!isDeliveryToDc && (
              <Grid container justify="space-between">
                {statusCode !== statusCodes.DELIVERED && (
                  <Button
                    data-testid="confirm-delivery-button"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => {
                      history.push({
                        pathname: PAGES.PROTOCOL,
                        state: {
                          pkg
                        }
                      });
                    }}
                  >
                    {TEXT.BUTTON_SIGNATURE}
                  </Button>
                )}
                <Box py={1} flexGrow={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={redirectToDeliveryProblems}
                  >
                    {TEXT.BUTTON_PROBLEMS}
                  </Button>
                </Box>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
      {openDeliveredPackageReminder && (
        <DeliveredPackageReminder
          open={openDeliveredPackageReminder}
          onCloseClick={onCloseClick}
          pkg={pkg}
        />
      )}
    </Drawer>
  );
}

PackageDetailsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  pkg: SharedPropTypes.package.isRequired
};
