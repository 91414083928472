/**
 * PickupEvidenceDb is a database where we keep all the pickup evidence when something goes wrong.
 * Before triggering a request to the server with the create a pickup evidence, that mutation
 * is registrered on PickupEvidenceDb. This ensures that the information inputted by the user is
 * not lost between offline/online cycles and page refreshes.
 */

import Dexie from 'dexie';
import wrapOperationsInDexieErrorHandler from 'operations/handle-dexie-errors';
import { dbConfig } from './constants';

/**
 * Create a new DB managed by Dexie.
 *
 * @returns Dexie
 */
const createDB = () => {
  const db = new Dexie(dbConfig.tableName, {
    chromeTransactionDurability: 'relaxed'
  });

  db.version(dbConfig.version).stores({
    pickupEvidence: `
          pickupId,
          problemSlug,
          image,
          url,
          contentType
        `
  });

  return db;
};

const pickupEvidenceDB = createDB();
const table = pickupEvidenceDB.table(dbConfig.tableName);

/**
 * Clears the pickupEvidence table.
 *
 * @returns {Promise}
 */
const clear = async () => {
  await table.clear();
};

/**
 * Puts a pickupEvidence object into the pickupEvidence table.
 *
 * @param {object} evidence - The pickupEvidence object to be added.
 * @property {number} pickupId - The pickup order id of the evidence.
 * @property {string} problemSlug - The slug of the pickup problem.
 * @property {Uint8Array} image - The image data of the evidence.
 * @property {string} url - The URL of the evidence.
 * @property {string} contentType - The content type of the evidence.
 *
 * @returns {Promise}
 */
const putPickupEvidence = async evidence => {
  await table.put({
    ...evidence,
    created: Date.now()
  });
};

/**
 * Deletes a pickupEvidence object from the pickupEvidence table.
 *
 * @param {number} pickupId - The pickup order id of the evidence.
 *
 * @returns {Promise}
 */
const deleteByPickupId = async pickupId => {
  await table.delete(pickupId);
};

/**
 * Gets a pickupEvidence object from the pickupEvidence table.
 *
 * @param {number} pickupId - The pickup order id of the evidence.
 *
 * @returns {(object|null)}
 * @property {number} pickupId - The pickup order id of the evidence.
 * @property {string} problemSlug - The slug of the pickup problem.
 * @property {Uint8Array} image - The image data of the evidence.
 * @property {string} url - The URL of the evidence.
 * @property {string} contentType - The content type of the evidence.
 * @property {number} created - The creation timestamp of the evidence.
 */
const getByPickupId = async pickupId => {
  const evidence = await table.where({ pickupId }).first();

  return evidence || null;
};

export default wrapOperationsInDexieErrorHandler({
  dbName: 'pickupEvidenceDB',
  operations: {
    clear,
    putPickupEvidence,
    getByPickupId,
    deleteByPickupId
  }
});
