import computeDistanceBetween from 'geolib/es/getDistance';

/**
 * 1000 = 1km
 */
export const DESTINATION = {
  AREA_RADIUS: 1000
};

/**
 *
 * @param {GeolocationCoordinates} driverPosition Must be an object with Lat and Lng only.
 * @param {GeolocationCoordinates} destinationPosition Package destination. Must be an object with Lat and Lng only.
 * @returns
 */
export function isDistanceAboveAllowed(
  driverPosition,
  destinationPosition,
  geoFenceRadius
) {
  const destinationRadius =
    geoFenceRadius !== 0 ? geoFenceRadius : DESTINATION.AREA_RADIUS;
  const distance = computeDistanceBetween(driverPosition, destinationPosition);
  return distance > destinationRadius;
}

/**
 *
 * @param {GeolocationCoordinates} driverPosition Must be an object with Lat and Lng only.
 * @param {GeolocationCoordinates} destinationPosition Package destination. Must be an object with Lat and Lng only.
 * @param {Boolean} hasSafeDelivery If the package has Safe Delivery or not.
 * @param {Number} geoFenceRadius Radius of the geofence.
 * @returns
 */
export function shouldNotifyDriverAboutDistance(
  driverPosition,
  destinationPosition,
  hasSafeDelivery,
  geoFenceRadius = 0
) {
  return (
    isDistanceAboveAllowed(
      driverPosition,
      destinationPosition,
      geoFenceRadius
    ) && !hasSafeDelivery
  );
}
