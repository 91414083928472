import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';

export const drawerDisplayedStorageKey = 'prevent_drawer_display_for_pkgs';

function storePackages(packageIds) {
  localStorage.setItem(
    drawerDisplayedStorageKey,
    JSON.stringify([...new Set(packageIds)])
  );
}

storePackages.propTypes = {
  packageIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

function getStoredPackageIds() {
  let packageIds = localStorage.getItem(drawerDisplayedStorageKey);
  try {
    // just in case non-json is store;
    packageIds = JSON.parse(packageIds) || [];
  } catch (error) {
    // In case it catches a error, it repairs the storage content.
    packageIds = {};
  }

  // the root stored value MUST BE a array
  if (packageIds.constructor.name !== 'Array') {
    packageIds = [];
    storePackages(packageIds);
  }

  return packageIds;
}

export const markWarningAlreadySeen = packageId => {
  if (!packageId) return;

  const displayedPackages = getStoredPackageIds();
  displayedPackages.push(packageId);
  storePackages(displayedPackages);
};

markWarningAlreadySeen.propTypes = {
  packageIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const warningWasSeen = packageId => {
  if (!packageId) return false;
  return getStoredPackageIds().includes(packageId);
};

warningWasSeen.propTypes = {
  packageIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const cleanupWarningRecords = async packages => {
  const storedPackages = getStoredPackageIds();

  if (storedPackages && storedPackages.constructor.name === 'Array') {
    const filteredPackages = storedPackages.filter(n =>
      packages.map(x => x.packageId).includes(n)
    );

    storePackages(filteredPackages);
  }
};

cleanupWarningRecords.propTypes = {
  packages: PropTypes.arrayOf(SharedPropTypes.package).isRequired
};
