import React from 'react';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import colors from '@loggi/mar/src/themes/drivers/colors';
import PropTypes from 'prop-types';
import { Box, Typography, Button, ThemeProvider } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Puller from 'view/molecules/puller';

import { ReactComponent as SortByIcon } from './icons/sort-by.svg';

const SortByNotificationDrawer = ({ open, onClose, onOpen }) => {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <SwipeableDrawer
        data-testid="onboarding-sort-by-drawer"
        open={open}
        anchor="bottom"
        onClose={onClose}
        onOpen={onOpen}
        PaperProps={{
          style: {
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            padding: '1rem 1.5rem 1.5rem 1.5rem'
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          bgcolor={colors.root[0]}
          color={colors.neutrals.typeface.primary}
          gridGap="1.5rem"
        >
          <Puller mt={0} height="0.25rem" />
          <Box textAlign="center">
            <SortByIcon />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gridGap="1rem"
          >
            <Typography variant="body1">Novidade para você</Typography>
            <Typography variant="h5">
              Ofertas <strong>mais fáceis</strong> de encontrar!
            </Typography>
            <Typography component="span" variant="body2">
              Agora, você consegue organizar as ofertas de acordo com as
              características da rota.
              <p>
                Sua escolha fica salva e você pode alterar o filtro quantas
                vezes quiser.
              </p>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" pt={2}>
          <Button
            data-testid="onboarding-sort-by-close-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
          >
            Ok, entendi
          </Button>
        </Box>
      </SwipeableDrawer>
    </ThemeProvider>
  );
};

SortByNotificationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func
};

SortByNotificationDrawer.defaultProps = {
  onOpen: () => ({})
};

export default SortByNotificationDrawer;
