import { useState, useEffect } from 'react';
import { interpret } from 'xstate';
import { useService } from '@xstate/react';

import orderResumeMachine from './machine';

export const statusEnum = {
  loading: 'loading',
  success: 'success',
  failure: 'failure'
};

function useSimpleFinishShipment(trigger = false) {
  const [service] = useState(interpret(orderResumeMachine));
  const [current] = useService(service);

  useEffect(() => {
    if (trigger) {
      service.start();
    }
  }, [trigger, service]);

  const status = statusEnum[current.value] || statusEnum.failure;

  return { status, earnings: current.context.earnings };
}

export default useSimpleFinishShipment;
