import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { getDriverType } from 'operations/feature-switches';
import firebase from 'firebase/app';
import { setWatchPosition } from 'operations/geolocation';
import firebaseInit from './firebase/firebase-init';
import 'operations/config/sentry';
import App from './view';

if (!window.globalThis) {
  /* We need this because of a bug in react-virtuoso,
   * see: https://github.com/petyosi/react-virtuoso/issues/473
   * the "fix" they did, didn't fix the issue.
   */
  window.globalThis = window;
}

if (process.env.REACT_APP_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-KCQ6PPR'
  };

  TagManager.initialize(tagManagerArgs);
}

firebaseInit();
firebase.analytics().setUserProperties({ fleet: getDriverType() });
setWatchPosition();

ReactDOM.render(<App />, document.getElementById('root'));
