import SuccessBeep from './assets/sounds/beep_success.wav';
import ErrorBeep from './assets/sounds/beep_error.wav';

export function playSuccessBeep() {
  const audio = new Audio(SuccessBeep);
  audio.play();
}

export function playErrorBeep() {
  const audio = new Audio(ErrorBeep);
  audio.play();
}
