import React from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import SharedPropTypes from 'view/shared-prop-types';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Drawer,
  Grid
} from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import { colors } from '@loggi/mar';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import { ReactComponent as WifiOffIcon } from 'view/molecules/notification-drawer/icons/no-wifi.svg';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import { formatAddressToPickupPackage } from 'view/utils';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import { PAGES as PagesURLs } from 'view';
import { useRoundedBorder } from 'view/utils/styles';
import pickupPackagesMachine, { STATE, EVENT } from './pickup-packages.machine';

import messages from './messages';

function PackageItem({ pkg }) {
  const address = formatAddressToPickupPackage(pkg);

  return (
    <ListItem divider>
      <Box py={1.0} px={0.5}>
        <ListItemText primary={pkg.recipient.name} />
        <Typography variant="body2" color="textPrimary">
          {address}
        </Typography>
        {pkg.companyName && (
          <Typography variant="body2" color="textPrimary">
            {messages.pickupPackages.companyFormatter(pkg.companyName)}
          </Typography>
        )}
        <Typography variant="body2" color="textPrimary">
          {pkg.trackingKey}
        </Typography>
      </Box>
    </ListItem>
  );
}

PackageItem.propTypes = {
  pkg: SharedPropTypes.package.isRequired
};

function NotificationDrawer({ isOpen, machine, sendEvent }) {
  const roundedBorder = useRoundedBorder();

  const ERROR_ICON_MAP = {
    noWifi: <WifiOffIcon data-testid="network-error-icon" />,
    error: <ErrorOutlineIcon data-testid="error-icon" />
  };

  const buttonText =
    machine.context.errorEvent === EVENT.refreshPackages ||
    machine.context.errorEvent === EVENT.loadPackages
      ? messages.pickupPackages.buttonUpdate
      : messages.pickupPackages.buttonErrorConfirm;

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={() => sendEvent(EVENT.returnToInitial)}
      classes={{ paper: roundedBorder.root }}
    >
      {isOpen && (
        <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
          <Box textAlign="center">
            {ERROR_ICON_MAP[machine.context.errorIcon]}
          </Box>
          <Box
            py={1}
            color={colors.smoke[700]}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="body1" align="center">
              {machine.context.errorMessage}
            </Typography>
          </Box>
          <Box pt={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                sendEvent(machine.context.errorEvent);
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

NotificationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  machine: PropTypes.shape({
    context: PropTypes.shape({
      errorMessage: PropTypes.string,
      errorEvent: PropTypes.string,
      errorIcon: PropTypes.string
    }).isRequired
  }).isRequired,
  sendEvent: PropTypes.func.isRequired
};

export function LoadingList() {
  return (
    <Box data-testid="loading-list" flexGrow={40} px={2.5}>
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={0.5}
        height={20}
        width={164}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={1.5}
        height={32}
        width={285}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={1.5}
        height={20}
        width={129}
      />
      <Skeleton
        component={Box}
        variant="rect"
        borderRadius="8px"
        my={0.5}
        height={24}
        width={90}
      />
    </Box>
  );
}

export default function PickupPackagesPage({ handleBack }) {
  const location = useLocation();
  const pickup = location?.state || {};

  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.callCompletePickupFromAllocation)
  );

  const [current, send] = useMachine(
    pickupPackagesMachine.withContext({
      pickup,
      packages: [],
      ignoreProxy
    })
  );

  const isDrawerOpen = current.matches(STATE.error);

  const listView =
    current.matches(STATE.loadingPackages) ||
    current.matches(STATE.refreshPackages) ? (
      <LoadingList />
    ) : (
      <List>
        <Box px={2.5}>
          <Box pb={1.25}>
            <Typography variant="h5">
              {messages.pickupPackages.getTitle(
                current.context.packages.length
              )}
            </Typography>
          </Box>

          <Typography variant="subtitle2">
            {messages.pickupPackages.getSubtitle(
              current.context.packages.length
            )}
          </Typography>
        </Box>
        {current.context.packages.map(pkg => (
          <PackageItem pkg={pkg} key={pkg.packageId} />
        ))}
      </List>
    );

  return (
    <>
      {current.matches(STATE.successConfirmingPackages) && (
        <Redirect to={PagesURLs.PACKAGE_LIST} />
      )}

      <Alert
        open={current.matches(STATE.successLoadingPackages)}
        message={messages.pickupPackages.alertSuccessLoadingPackages}
        onClose={() => send(EVENT.returnToInitial)}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...ALERT_TYPES.SUCCESS}
      />

      <Box minHeight="100%" height="100%" display="flex" flexDirection="column">
        <Box px={2.5} pt={2.5}>
          <Grid container justify="space-between" alignItems="center">
            <Box ml={-1.75} pb={1.5}>
              <IconButton
                data-testid="back-icon"
                color="primary"
                onClick={handleBack}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box mr={-1.75} pb={1.5}>
              <IconButton
                data-testid="refresh-icon"
                disabled={
                  current.matches(STATE.confirmingPackages) ||
                  current.matches(STATE.loadingPackages)
                }
                color="primary"
                onClick={() => {
                  send(EVENT.refreshPackages);
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          </Grid>
        </Box>

        <Box flexGrow={2} overflow="auto">
          {listView}
        </Box>

        <Box py={2.5} px={2.5}>
          <Button
            disabled={
              current.matches(STATE.confirmingPackages) ||
              current.matches(STATE.refreshPackages) ||
              current.matches(STATE.loadingPackages)
            }
            size="large"
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              send(EVENT.confirmPackages);
            }}
          >
            {current.matches(STATE.confirmingPackages)
              ? messages.pickupPackages.buttonLoading
              : messages.pickupPackages.buttonConfirmPickup}
          </Button>
        </Box>
      </Box>

      <NotificationDrawer
        isOpen={isDrawerOpen}
        machine={current}
        sendEvent={send}
      />
    </>
  );
}

PickupPackagesPage.propTypes = {
  handleBack: PropTypes.func.isRequired
};

PickupPackagesPage.url = '/pacotes';
