import React from 'react';

import firebase from 'firebase/app';
import {
  Switch,
  useRouteMatch,
  Redirect,
  Route,
  useHistory
} from 'react-router-dom';

import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';

import {
  getClientDocument,
  clearClientDocument
} from 'operations/client-document';
import { PAGES } from 'view';
import { getCurrentUser } from 'operations/auth';
import PickupScanningInstructions from '../pickup-scanning-instructions';
import PickupScanner from '../pickup-scanner';
import PickupCheckPackages from '../pickup-check-packages';
import PickupDocumentPage from '../pickup-document';
import {
  PICKUP_TYPES,
  DRIVER_APP_DEEP_LINK,
  TAG_PICKUP_SURVEY,
  barcodesAllowedFormats
} from '../constants';
import PickupSurvey from '../pickup-survey';

export default function DirectPickup() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const persistedStateKey = 'directPickup';
  const [packages, setPackages] = usePersistedState(
    `${persistedStateKeys.pickupScannedPackages}/${persistedStateKey}`,
    []
  );
  const multibarcodesFormatPackages = packages.map(item =>
    item.barcode
      ? {
          ...item,
          barcode: undefined,
          barcodes: [item.barcode]
        }
      : item
  );
  const [skipScanningTutorial, setSkipScanningTutorial] = usePersistedState(
    persistedStateKeys.skipPickupScanningTutorial,
    false
  );
  const shipperName = getClientDocument().name;
  const tag = TAG_PICKUP_SURVEY.SUCCESS;
  const packagesIds = packages.map(item => item.packageId);
  const driverEmail = getCurrentUser()?.email;

  const goBack = () => {
    if (process.env.REACT_APP_DRIVERS_URL) {
      window.location.href = process.env.REACT_APP_DRIVERS_URL;
      return;
    }

    if (window.driverAppBridge?.closeActivity) {
      window.driverAppBridge?.closeActivity();
      return;
    }

    history.goBack();
  };

  return (
    <Switch>
      <Route exact path={path}>
        {skipScanningTutorial ? (
          <Redirect to={`${path}${PickupScanner.url}`} />
        ) : (
          <PickupScanningInstructions
            onBackButtonClicked={() => {
              goBack();
            }}
            onStartBip={() => {
              setSkipScanningTutorial(true);
              history.push(`${path}${PickupScanner.url}`);
            }}
          />
        )}
      </Route>
      <Route exact path={`${path}${PickupScanner.url}`}>
        <PickupScanner
          initialScannedBarcodes={multibarcodesFormatPackages.map(
            pkg => pkg.barcodes
          )}
          initialScannedPackages={multibarcodesFormatPackages}
          saveScannedPackages={setPackages}
          onBarcodesCheckSuccess={() => {
            history.push(`${path}${PickupCheckPackages.url}`);
          }}
          onGoBack={scannedPackages => {
            setPackages(scannedPackages);
            goBack();
          }}
          persistedStateKey={persistedStateKey}
          allowedFormats={barcodesAllowedFormats}
          setOpenInstructions={() => {
            setSkipScanningTutorial(false);
            /**
             * Use replace instead of push to simulate a reload since we're
             * already in this path url.
             */
            history.replace(path);
          }}
        />
      </Route>
      <Route exact path={`${path}${PickupCheckPackages.url}`}>
        <PickupCheckPackages
          packages={multibarcodesFormatPackages}
          onRemovePackage={packagesToBeDeleted => {
            const filteredPackages = multibarcodesFormatPackages.filter(
              pkg => pkg.barcodes !== packagesToBeDeleted.barcodes
            );
            if (filteredPackages.length === 0) {
              history.goBack();
            }
            setPackages(filteredPackages);
            firebase.analytics().logEvent('deleted_package', {
              page_title: 'check_packages_ui'
            });
          }}
          goBack={() => {
            history.goBack();
          }}
          onConfirm={() => {
            history.push(`${path}${PickupDocumentPage.url}`);
          }}
        />
      </Route>
      <Route exact path={`${path}${PickupDocumentPage.url}`}>
        <PickupDocumentPage
          packages={multibarcodesFormatPackages}
          pickupType={PICKUP_TYPES.DIRECT}
          persistedStateKey={persistedStateKey}
        />
      </Route>
      <Route path={`${path}${PickupDocumentPage.url}${PickupSurvey.url}`}>
        <PickupSurvey
          userIdentification={driverEmail}
          tag={tag}
          pickupType={PICKUP_TYPES.DIRECT}
          packagesIds={packagesIds}
          shipperName={shipperName}
          onClose={() => {
            clearClientDocument();
            if (window?.driverAppBridge) {
              window.location = DRIVER_APP_DEEP_LINK;
            } else {
              history.push(PAGES.PACKAGE_LIST);
            }
          }}
        />
      </Route>
    </Switch>
  );
}
