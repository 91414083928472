import { getCurrentUser } from 'operations/auth';

const DRIVER_TYPE = {
  MEI: 'mei',
  LEVE: 'leve',
  CLT: 'clt',
  TRANSP: 'transp'
};

const meiRelation =
  'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_CONTRACTOR';
const transpRelation =
  'LAST_MILE_COMPANY_TYPE_CARRIER:DRIVER_COMPANY_RELATION_COLLABORATOR';
const leveRelation =
  'LAST_MILE_COMPANY_TYPE_LEVE:DRIVER_COMPANY_RELATION_COLLABORATOR';
const cltRelation =
  'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_STAFF';

function getDriverType() {
  const { companyType, companyRelation } = getCurrentUser() || {};
  const relation = `${companyType}:${companyRelation}`;

  switch (relation) {
    case meiRelation:
      return DRIVER_TYPE.MEI;
    case cltRelation:
      return DRIVER_TYPE.CLT;
    case transpRelation:
      return DRIVER_TYPE.TRANSP;
    case leveRelation:
      return DRIVER_TYPE.LEVE;
    default:
      return null;
  }
}

export default getDriverType;
export { DRIVER_TYPE };
