import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { interpret, Interpreter } from 'xstate';
import { useService } from '@xstate/react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { PAGES } from 'view';
import SuccessSyncTemplate from 'view/templates/success-sync';
import messages from './constants';
import useSanchesBackground from './styles';
import packageSyncMachine, { STATES, ACTIONS } from './machine';

export { packageSyncMachine };

function LoadingStep() {
  const sanchesBackgroundStyles = useSanchesBackground();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      classes={sanchesBackgroundStyles}
      color="white"
      px={3.5}
    >
      <Box pb={5}>
        <CircularProgress color="inherit" />
      </Box>

      <Typography color="inherit" variant="subtitle1" align="center">
        {messages.loadingStep.infoText}
      </Typography>
    </Box>
  );
}

export default function PackageSync({ packageSyncMachineRef }) {
  const [current, send] = useService(packageSyncMachineRef);
  const redirect = () => send(ACTIONS.REDIRECT);

  return (
    <>
      {current.value === STATES.loadingStep && <LoadingStep />}
      {current.value === STATES.successStep && (
        <SuccessSyncTemplate onContinue={redirect} />
      )}
      {current.value === STATES.redirectStep && (
        <Redirect to={PAGES.PACKAGE_LIST} />
      )}
    </>
  );
}
export const makePackageSyncMachineRef = (context = {}) => {
  const service = interpret(
    packageSyncMachine.withContext({
      ...packageSyncMachine.context,
      ...context
    })
  );
  service.start();
  return service;
};

PackageSync.propTypes = {
  packageSyncMachineRef: PropTypes.instanceOf(Interpreter).isRequired
};
