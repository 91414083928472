import React from 'react';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { Box, Typography, TextField } from '@material-ui/core';
import messages from '../messages';

const dataTestId = {
  idproblemComplementField: 'problem-complement-field'
};

export default function PickupProblemComplementInput({ onChange }) {
  return (
    <Box>
      <Box mt={1.25} color={colors.smoke[800]}>
        <Typography>
          {messages.pickupProblemDetails.subtitleProblemComplement}
        </Typography>
      </Box>
      <Box pt={3}>
        <TextField
          size="large"
          fullWidth
          color="primary"
          variant="outlined"
          data-testid={dataTestId.idproblemComplementField}
          label={messages.pickupProblemDetails.labelProblemComplement}
          type="text"
          onChange={event => {
            onChange(event.target.value);
          }}
          multiline
          rows="4"
        />
      </Box>
    </Box>
  );
}

PickupProblemComplementInput.propTypes = {
  onChange: PropTypes.func.isRequired
};
