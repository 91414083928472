import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { colors } from '@loggi/mar';

import { checkBarcodes } from 'operations/pickup';
import SharedPropTypes from 'view/shared-prop-types';
import ConfirmDismissDrawer from 'view/molecules/confirm-dismiss-drawer';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/confirm-dismiss-drawer/icons/error-outline.svg';
import ScannedPackagesList from './scanned-packages-list';
import messages from './messages';

const statesEnum = {
  idle: 'idle',
  loading: 'loading'
};

export default function PickupCheckPackages({
  pickup,
  packages,
  goBack,
  onConfirm,
  onRemovePackage,
  updatePackages,
  shouldCheckBarcodesOnConfirm
}) {
  const [state, setState] = useState(statesEnum.idle);
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isErrorDrawerOpen, setIsErrorDrawerOpen] = useState(false);
  const openDrawer = () => setOpenDrawer(true);
  const closeDrawer = () => {
    setOpenDrawer(false);
    goBack();
  };

  const onCheckBarcodes = () => {
    if (state === statesEnum.loading || !shouldCheckBarcodesOnConfirm) {
      return;
    }
    setState(statesEnum.loading);
    const companyId = pickup?.shipper?.companyId;
    const pickupCode = pickup?.pickupCode;
    const barcodesToCheck = packages.map(pkg =>
      pkg.barcodes.map(barcode => {
        return {
          content: barcode
        };
      })
    );
    checkBarcodes({ barcodes: barcodesToCheck, companyId, pickupCode })
      .json(({ barcodesPackagesCheck }) => {
        const packagesWithError = barcodesPackagesCheck.filter(
          item => !!item.error
        );

        setState(statesEnum.idle);
        const updatedPackages = barcodesPackagesCheck.map(barcodesCheck => {
          const existentPackage = packages.find(
            pkg => pkg.barcodes === barcodesCheck.barcodes
          );
          return {
            ...existentPackage,
            ...barcodesCheck
          };
        });
        updatePackages(updatedPackages);
        if (packagesWithError.length > 0) {
          setIsErrorDrawerOpen(true);
        } else {
          openDrawer();
        }
      })
      .catch(() => {
        setIsErrorDrawerOpen(true);
        setState(statesEnum.idle);
      });
  };

  return (
    <>
      <Box py={3} display="flex" flexDirection="column" minHeight="100%">
        <Box pl={1}>
          <IconButton>
            <ArrowBack
              onClick={goBack}
              style={{ color: colors.blue[500] }}
              data-testid="go-back-button"
            />
          </IconButton>
        </Box>
        <Box p={2.5} color={colors.smoke[900]}>
          <Box>
            <Typography variant="h5">
              {messages.pickupCheckPackages.getTitle(packages.length)}
            </Typography>
          </Box>
          <Box mt={1.5}>
            <Typography variant="body1">
              {messages.pickupCheckPackages.subTitle}
            </Typography>
          </Box>
        </Box>

        <Box>
          <ScannedPackagesList
            packages={packages}
            onRemovePackage={onRemovePackage}
            fromPackageCheck
          />
        </Box>

        <Box
          px={2.5}
          width="100%"
          display="flex"
          flexGrow={1}
          justifyContent="end"
          flexDirection="column"
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={
              shouldCheckBarcodesOnConfirm ? onCheckBarcodes : openDrawer
            }
            fullWidth
          >
            {messages.pickupCheckPackages.buttonConfirm}
          </Button>
          <Button
            style={{ marginTop: 10 }}
            color="primary"
            size="large"
            onClick={goBack}
            fullWidth
          >
            {messages.pickupCheckPackages.buttonAddMore}
          </Button>
        </Box>
      </Box>

      <ConfirmDismissDrawer
        icon={<ErrorOutlineIcon />}
        data={messages.drawerPickupCheckConfirm}
        isOpen={isOpenDrawer}
        handleConfirmAction={onConfirm}
        handleDismissAction={closeDrawer}
        isError={false}
      />
      {isErrorDrawerOpen && (
        <ConfirmDismissDrawer
          icon={<ErrorOutlineIcon />}
          data={messages.drawerPickupCheckError}
          isOpen={isErrorDrawerOpen}
          handleConfirmAction={() => setIsErrorDrawerOpen(false)}
        />
      )}
    </>
  );
}
PickupCheckPackages.propTypes = {
  pickup: SharedPropTypes.pickup,
  packages: ScannedPackagesList.propTypes.packages.isRequired,
  onRemovePackage: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  updatePackages: PropTypes.func,
  shouldCheckBarcodesOnConfirm: PropTypes.bool
};

PickupCheckPackages.defaultProps = {
  pickup: null,
  updatePackages: () => ({}),
  shouldCheckBarcodesOnConfirm: false
};

PickupCheckPackages.url = '/conferir_pacotes';
