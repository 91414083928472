import React from 'react';

import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

import { PAGES } from 'view';
import { ReactComponent as Loggi } from 'view/atoms/icons/loggi/loggi.svg';
import { ReactComponent as PackageWithBarcode } from 'view/atoms/illustrations/one-package-with-barcode.svg';

const useStyles = makeStyles({
  container: {
    backgroundImage: colors.gradients.sanches
  }
});

export const messages = {
  yourActivities: 'Suas atividades',
  youDontHavePackages: 'Você não tem nenhum pacote para entregar.',
  yourListIsEmpty: 'Sua lista ainda está vazia',
  theyllAppearHere:
    'Assim que adicionarem novas entregas, elas aparecerão aqui.',
  updateList: 'Atualizar lista'
};

export default function NoAttribution() {
  const classes = useStyles();
  const history = useHistory();
  const goToAssignmentList = () => history.push(PAGES.PACKAGE_LIST);

  return (
    <Box
      pt={5}
      display="flex"
      minHeight="100%"
      flexDirection="column"
      className={classes.container}
    >
      <Box px={3.5}>
        <Loggi color={colors.root[0]} />
      </Box>

      <Box
        px={3.5}
        pb={3.5}
        mt={8}
        display="flex"
        alignItems="flex-end"
        color={colors.root[0]}
      >
        <Box>
          <Box display="flex" alignItems="center" justifyContent="left">
            <Typography variant="body2">{messages.yourActivities}</Typography>
          </Box>
          <Box py={1.5} color={colors.blue[50]}>
            <Typography variant="h4">{messages.youDontHavePackages}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        alignItems="end"
        flexGrow={1}
        bgcolor={colors.root[0]}
        borderRadius="16px"
        mb="-16px"
      >
        <Box p={3.5}>
          <Box py={1}>
            <PackageWithBarcode />
          </Box>

          <Box py={1}>
            <Typography variant="h5">{messages.yourListIsEmpty}</Typography>
          </Box>

          <Box py={1} pb={2.5} color={colors.smoke[700]}>
            <Typography>{messages.theyllAppearHere}</Typography>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={goToAssignmentList}
          >
            {messages.updateList}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
