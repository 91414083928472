import { PopupButton } from '@typeform/embed-react';
import decamelizeKeys from 'decamelize-keys';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'view';
import { DRIVER_APP_DEEP_LINK } from 'view/templates/order-resume/order-resume';
import { getItineraryID } from 'operations/assignment-list/assignment-list';
import { getCurrentUser } from 'operations/auth';

export const googleMapsSurveyCalledFrom = {
  finishShipment: 'finishShipment',
  assignmentList: 'assignmentList'
};

export default function GoogleMapsSurvey() {
  const formId = `${process.env.REACT_APP_GOOGLE_MAPS_SURVEY_FORM_ID}`;
  const itineraryId = getItineraryID();
  const driverEmail = getCurrentUser()?.email;
  const history = useHistory();
  const { page = '', earnings = 0 } = history.location || {};

  const hiddenFields = decamelizeKeys({
    driverEmail,
    itineraryId,
    page
  });

  return (
    <PopupButton
      id={formId}
      hidden={hiddenFields}
      open="load"
      autoClose
      hideFooter
      hideHeaders
      style={{ display: 'none' }}
      data-testid="google-maps-survey"
      onClose={() => {
        if (page === googleMapsSurveyCalledFrom.finishShipment) {
          if (window?.driverAppBridge) {
            window.location = `${DRIVER_APP_DEEP_LINK}?earnings=${earnings}`;
          } else {
            history.push(PAGES.NO_ATTRIBUTION);
          }
        } else {
          history.push(PAGES.PACKAGE_LIST);
        }
      }}
    />
  );
}
