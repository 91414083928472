import { Machine, sendParent } from 'xstate';
import { updateStatus } from 'operations/update-status';
import getPosition from 'operations/geolocation';

export const STATES = {
  loadingStep: 'loadingStep',
  successStep: 'successStep',
  redirectStep: 'redirectStep'
};

export const ACTIONS = {
  REDIRECT: 'REDIRECT'
};

const packageSyncMachine = Machine({
  id: 'packageSync',
  initial: STATES.loadingStep,
  context: {
    packageId: null,
    status: null,
    receiverName: null,
    receiverDocument: null,
    receiverDocumentType: null,
    notes: null,
    recipientRelationship: null,
    receiverSignature: null,
    digitalSignatureSignedUrl: null,
    process: null,
    destination: null,
    tokenAttempts: null,
    markDeliveredPkg: false
  },
  states: {
    [STATES.loadingStep]: {
      invoke: {
        id: 'updateStatus',
        src: context =>
          getPosition().then(({ latitude, longitude }) => {
            return updateStatus({
              updateStatusData: {
                ...context,
                latitude,
                longitude,
                updateStatusTime: new Date().toISOString(),
                safeDeliveryTokenAttempts: context.tokenAttempts,
                driverNotes: context.notes,
                recipientRelationship: context.recipientRelationship,
                deliveryLocationCode: context.deliveryOption?.id,
                digitalSignatureSignedUrl: context.digitalSignatureSignedUrl,
                experiments: []
              },
              markDeliveredPkg: context.markDeliveredPkg
            });
          }),
        onDone: {
          target: STATES.successStep
        },
        onError: {
          actions: [
            sendParent((_, event) => ({
              type: 'SET_NOTIFICATION',
              data: {
                message: event.data.message
              }
            })),
            sendParent(() => ({
              type: 'BACK'
            }))
          ]
        }
      }
    },
    [STATES.successStep]: {
      on: {
        [ACTIONS.REDIRECT]: STATES.redirectStep
      }
    },
    [STATES.redirectStep]: {}
  }
});

export default packageSyncMachine;
