import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { PAGES } from 'view';
import { ModeCommentOutlined } from '@material-ui/icons';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { googleMapsSurveyCalledFrom } from 'view/pages/assignment-list/google-maps/google-maps-survey';
import { featureSwitches } from 'operations/feature-switches';

export const messages = {
  mapOptionMessage: 'O que achou do novo mapa?',
  avaliateMap: 'Avaliar'
};
export default function GoogleMapsSurveyOption() {
  const history = useHistory();

  const handleGoogleMapsSurveyClick = () => {
    history.push({
      pathname: PAGES.GOOGLE_MAPS_SURVEY,
      page: googleMapsSurveyCalledFrom.assignmentList
    });
  };

  const cursorPointer = { cursor: 'pointer' };

  const isGoogleMapsSurveyEnabled = useFeatureSwitch(
    featureSwitches.googleMapsSurvey
  );

  if (!isGoogleMapsSurveyEnabled) return <></>;

  return (
    <Box
      bgcolor={colors.smoke[25]}
      px={2.5}
      py={1.5}
      onClick={handleGoogleMapsSurveyClick}
      style={cursorPointer}
      display="flex"
    >
      <Box display="flex" alignItems="center">
        <Box component="span" color={colors.smoke[700]} mr={1.5}>
          <Typography variant="caption">
            <ModeCommentOutlined fontSize="small" />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box color={colors.root[700]}>
          <Typography variant="body2">{messages.mapOptionMessage}</Typography>
        </Box>
        <Box
          py={0.5}
          color="primary.main"
          fontWeight={600}
          data-testid="survey-map-button"
        >
          <Typography variant="body2" style={{ fontWeight: '700' }}>
            {messages.avaliateMap}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
