import { buildLoggiAddressV2 } from 'view/utils';
import { getLastKnownLocation } from 'operations/geolocation/geolocation';

const getAssignmentCoordinates = (assignment, maps) => {
  const addressLines = buildLoggiAddressV2(assignment.addressDestination);
  const hasLoggiAddress = !!addressLines;

  if (hasLoggiAddress) {
    const { latitude, longitude } = assignment.addressDestination.coordinates;
    return new maps.LatLng(latitude, longitude);
  }

  if (assignment.destination.coordinates) {
    const { latitude, longitude } = assignment.destination.coordinates;
    return new maps.LatLng(latitude, longitude);
  }

  const { lat, lng } = assignment.destination;
  return new maps.LatLng(lat, lng);
};

const getDriverPosition = () => {
  const driverLastKnownLocation = getLastKnownLocation();
  return {
    lat: driverLastKnownLocation?.latitude,
    lng: driverLastKnownLocation?.longitude
  };
};

export { getAssignmentCoordinates, getDriverPosition };
