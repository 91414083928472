import React from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';
import { colors } from '@loggi/mar';
import { ArrowBack } from '@material-ui/icons';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import ExclamationIcon from '@material-ui/icons/ErrorOutline';
import sendEventToAnalytics from 'operations/firebase';
import { getItineraryID } from 'operations/assignment-list/assignment-list';
import { getCurrentUser } from 'operations/auth';
import { ReactComponent as MapRepresentation } from '../../atoms/illustrations/map_point.svg';

export const messages = {
  youAre: 'Você está a',
  meters: 'metros',
  deliveryPlace: 'do local de entrega.',
  placeGeoFence: 'Você está a mais de',
  alertDescription:
    'Para registrar uma tentativa de entrega, é ideal estar próximo do local. Você quer continuar mesmo assim?',
  alertDescriptionGeoFence:
    'É necessário estar no endereço de destino para registrar a informação de entrega. Siga para o endereço indicado na Plataforma.',
  continue: 'Continuar'
};

export default function DriverDistanceFromDeliveryPoint({
  onGoBack,
  onClick,
  distance,
  geoFenceRadius,
  driverCoords,
  pkg
}) {
  if (geoFenceRadius !== 0) {
    const { id: driverId } = getCurrentUser() || {};
    sendEventToAnalytics('delivery_attempt', {
      distance,
      driverCoords,
      driverId,
      packageId: pkg.packageId,
      itineraryId: getItineraryID()
    });
  }
  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box px={0.5} pt={2.5} color="primary">
          <IconButton
            onClick={onGoBack}
            data-testid="driver-distance-goback-button"
          >
            <ArrowBack style={{ color: colors.blue[500] }} />
          </IconButton>
        </Box>
        <Box>
          <Box px={2.5} bgcolor={colors.root[0]}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <MapRepresentation />
            </Box>
            <Box
              mb={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box fontSize="3rem">
                <ExclamationIcon
                  aria-label="Erro"
                  fontSize="inherit"
                  color="primary"
                />
              </Box>
            </Box>
            <Box textAlign="center">
              <Box>
                {geoFenceRadius === 0 && (
                  <Typography variant="h6">
                    {messages.youAre}{' '}
                    <span style={{ color: colors.blue[500] }}>
                      {distance} {messages.meters}
                    </span>{' '}
                    {messages.deliveryPlace}
                  </Typography>
                )}
                {geoFenceRadius !== 0 && (
                  <Typography variant="h6">
                    {messages.placeGeoFence} {geoFenceRadius} {messages.meters}{' '}
                    {messages.deliveryPlace}
                  </Typography>
                )}
              </Box>
              <Box mt={1}>
                <Typography data-testid="driver-distance-alert-template">
                  {geoFenceRadius !== 0
                    ? messages.alertDescriptionGeoFence
                    : messages.alertDescription}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {geoFenceRadius === 0 && (
          <Box
            mx={2.5}
            my={3}
            display="flex"
            flexGrow={1}
            alignItems="flex-end"
          >
            <Button
              data-testid="driver-distance-continue-button"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={onClick}
            >
              {messages.continue}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

DriverDistanceFromDeliveryPoint.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  distance: PropTypes.number.isRequired,
  geoFenceRadius: PropTypes.number,
  driverCoords: PropTypes.shape().isRequired,
  pkg: SharedPropTypes.package.isRequired
};

DriverDistanceFromDeliveryPoint.defaultProps = {
  geoFenceRadius: 0
};
