import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Button, Drawer } from '@material-ui/core';
import { buildLoggiAddressV2 } from 'view/utils';
import PackageCounter from './package-counter';

export const texts = {
  button: 'Revisar lista'
};

export default function PackageInfoDrawer({
  totalCounter,
  loading,
  beepedPackages,
  onReviewListClick,
  packageInfo
}) {
  const theme = useTheme();
  const { colors } = theme;

  const currentCounter = beepedPackages.length;

  const checkErrorAndNotCancelledPackage = () => {
    return (
      packageInfo?.barcodes &&
      packageInfo?.error &&
      !packageInfo?.cancelledPackage
    );
  };

  return (
    <Drawer
      data-testid="package-info-drawer"
      open
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
      variant="permanent"
      p={1.5}
    >
      {!loading && (
        <>
          <Box px={2.5} py={1.5}>
            <PackageCounter
              totalCounter={totalCounter}
              currentCounter={beepedPackages.length}
            />
            <Box py={1.5}>
              {beepedPackages.length === 0 ? (
                <>
                  {checkErrorAndNotCancelledPackage() ? (
                    <>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 600,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {packageInfo.barcodes[0]}
                      </Typography>
                      <Box
                        width="fit-content"
                        bgcolor={colors.status.negative.light}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            fontWeight: 600,
                            color: colors.neutrals.typeface.primary
                          }}
                        >
                          {packageInfo.error}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        style={{ color: colors.neutrals.typeface.secondary }}
                      >
                        {packageInfo.errorSubtitle}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        data-testid="package-info-drawer-instructions"
                      >
                        Posicione o código sob a <b>linha em vermelho</b> para
                        identificar
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <>
                  {packageInfo?.cancelledPackage ? (
                    <>
                      <Typography
                        variant="body1"
                        data-testid="package-info-drawer-instructions"
                      >
                        Posicione o código sob a <b>linha em vermelho</b> para
                        identificar
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 600,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {packageInfo?.barcodes
                          ? packageInfo.barcodes[0]
                          : beepedPackages[currentCounter - 1].barcodes[0]}
                      </Typography>
                      {packageInfo?.error && (
                        <>
                          <Box
                            width="fit-content"
                            bgcolor={
                              packageInfo.error === 'Item não previsto'
                                ? colors.status.attention.light
                                : colors.status.negative.light
                            }
                          >
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: 600,
                                color: colors.neutrals.typeface.primary
                              }}
                            >
                              {packageInfo.error}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {packageInfo?.errorSubtitle ? (
                        <>
                          <Typography
                            variant="body2"
                            style={{
                              color: colors.neutrals.typeface.secondary
                            }}
                          >
                            {packageInfo.errorSubtitle}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="body1">
                            {beepedPackages[currentCounter - 1].recipientName}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              color: colors.neutrals.typeface.secondary
                            }}
                          >
                            {buildLoggiAddressV2(
                              beepedPackages[currentCounter - 1]
                                .recipientAddress
                            )}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            {beepedPackages.length > 0 && (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={onReviewListClick}
              >
                {texts.button}
              </Button>
            )}
          </Box>
        </>
      )}
      {loading && (
        <Box p={2} data-testid="package-info-drawer-skeleton">
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Box>
      )}
    </Drawer>
  );
}

PackageInfoDrawer.propTypes = {
  totalCounter: PropTypes.number,
  loading: PropTypes.bool,
  beepedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string),
      recipientName: PropTypes.string,
      recipientAddress: PropTypes.string
    })
  ),
  packageInfo: PropTypes.shape({
    barcodes: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
    errorSubtitle: PropTypes.string,
    cancelledPackage: PropTypes.bool,
    recipientName: PropTypes.string,
    recipientAddress: PropTypes.string
  }),
  onReviewListClick: PropTypes.func.isRequired
};

PackageInfoDrawer.defaultProps = {
  totalCounter: 0,
  loading: true,
  beepedPackages: [],
  packageInfo: null
};
