import { isAssignmentWaypointPickup } from 'view/molecules/assignment-list/utils';

export const pickupStateEnum = {
  completed: 'completed'
};

const localStorageKey = 'pickup-states';

export function clearDB() {
  localStorage.removeItem(localStorageKey);
}

export function getPickupStates() {
  return JSON.parse(localStorage.getItem(localStorageKey)) || {};
}

/**
 * Generates a string to identify a pickup in pickup-states localStorage.
 *
 * Expects an object with only one entry. The entry key's is the pickup's
 * unique ID name: pickupCode for LastMilePickups, pickupId for demands from
 * allocation.
 *
 * e.g. { pickupCode: "GDP2306" }
 *      { pickupId: 712 }
 *
 * @param {Object} pickupIdentifier
 * @returns {string}
 */
function makeIdentifierString(pickupIdentifier) {
  const pickupIdentifierKey = Object.keys(pickupIdentifier)[0];
  const pickupIdentifierValue = pickupIdentifier[pickupIdentifierKey];
  return `${pickupIdentifierKey}:${pickupIdentifierValue}`;
}

/**
 * Mark a pickup as completed.
 *
 * @param {LastMilePickup} pickup
 * @returns {void}
 */
export function markPickupAsCompleted(pickup) {
  const { pickupCode, pickupId } = pickup;
  const pickupIdentifier = pickupId ? { pickupId } : { pickupCode };

  const currentPickupStates = getPickupStates();
  const nextPickupStates = {
    ...currentPickupStates,
    [makeIdentifierString(pickupIdentifier)]: pickupStateEnum.completed
  };

  localStorage.setItem(localStorageKey, JSON.stringify(nextPickupStates));
}

export function mergeAssignmentListWithPickupStates(assignmentList) {
  if (!assignmentList) {
    return {};
  }

  const pickupStates = getPickupStates();
  const lastMilePickups = assignmentList.pickups || [];
  const mergedPickups = lastMilePickups.map(pickup => {
    const identifier = makeIdentifierString({ pickupCode: pickup.pickupCode });
    const pickupState = pickupStates[identifier];
    return {
      ...pickup,
      pickupState
    };
  });

  const waypoints = assignmentList.assignmentWaypoints || [];
  const mergedWaypoints = waypoints.map(waypoint => {
    if (!isAssignmentWaypointPickup(waypoint)) {
      return waypoint;
    }

    const { pickupId } = waypoint.assignments[0];
    const identifier = makeIdentifierString({ pickupId });
    const pickupState = pickupStates[identifier];
    return {
      ...waypoint,
      pickupState
    };
  });

  return {
    ...assignmentList,
    pickups: mergedPickups,
    assignmentWaypoints: mergedWaypoints
  };
}

export function cleanPickupStatesBasedOnAssignmentList(assignmentList = {}) {
  const lastMilePickups = assignmentList.pickups || [];
  const pickupWaypoints = (assignmentList.assignmentWaypoints || []).filter(
    isAssignmentWaypointPickup
  );

  if (!lastMilePickups.length && !pickupWaypoints.length) {
    clearDB();
  }
}
