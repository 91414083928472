import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors, pxToRem } from '@loggi/mar';

import { ReactComponent as Loggi } from 'view/atoms/icons/loggi/loggi.svg';
import { ReactComponent as EmptySearchIcon } from 'view/atoms/illustrations/empty/empty.svg';
import AssignmentListLastUpdate from 'view/molecules/assignment-list-last-update';

import TEXT from './constants';

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: colors.gradients.sanches
  }
}));

export default function Unauthenticated() {
  const classes = useStyles();

  const packageListTimestamp = Date.now();

  return (
    <Box
      pt={5}
      display="flex"
      minHeight="100%"
      flexDirection="column"
      className={classes.container}
    >
      <Box px={3.5}>
        <Loggi color={colors.root[0]} />
      </Box>

      <Box
        px={3.5}
        pb={5}
        mt={30}
        display="flex"
        alignItems="flex-end"
        color={colors.root[0]}
      >
        <Box>
          <Box display="flex" alignItems="center" justifyContent="left">
            <Typography variant="body2">{TEXT.PACKAGE_LIST}</Typography>
          </Box>
          <Box py={1.5} color={colors.blue[50]}>
            <Typography variant="h4">{TEXT.TOTAL_PACKAGES}</Typography>
          </Box>
        </Box>
      </Box>

      <Box
        alignItems="end"
        flexGrow={1}
        bgcolor={colors.root[0]}
        borderRadius="16px"
        mb={-2}
      >
        <Box p={3.5}>
          <Box display="flex" justifyContent="center">
            <Box
              border={`solid ${pxToRem(2)} ${colors.smoke[100]}`}
              borderRadius="3px"
              width={pxToRem(52)}
            />
          </Box>

          <Box py={1}>
            <EmptySearchIcon />
          </Box>

          <Box py={1}>
            <Typography variant="h5">
              {TEXT.UNAVAILABLE_PACKAGE_LIST_TITLE}
            </Typography>
          </Box>

          <Box py={1} color={colors.smoke[700]}>
            <Typography>{TEXT.UNAVAILABLE_PACKAGE_LIST}</Typography>
          </Box>
        </Box>
      </Box>
      <AssignmentListLastUpdate packageListTimestamp={packageListTimestamp} />
    </Box>
  );
}
