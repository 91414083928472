import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Backdrop } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { parseOfferProtoToOfferCard } from 'infra/services/parsers';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForDriverId
} from 'operations/feature-switches';
import getDistance from 'infra/services/get-distance';
import getOffer from 'infra/services/get-offer';
import OfferDetails from '../../pages/offer-details';
import CapacityReserveDetails from '../../pages/offer-details/capacity-reserve-details';
import CapacityReserveDetailsV2 from '../../pages/offer-details/v2/capacity-reserve-details';

import TEXT from './constants';
import {
  formatMoney,
  noDecimalFormatDistance,
  parseISODateToHours,
  useStyles
} from '../offer-card/util';
import {
  RecommendedTooltip,
  DistanceTooltip,
  CapacityReserveToolTip,
  OpportunisticToolTip,
  RoundVIPToolTip
} from './tooltip';
import CapacityReserveTooltipBody from './v2/capacity-reserve-tool-body';

export const cardPropertiesEnum = {
  capacityReserve: offer => {
    return {
      messages: {
        subtitle: `Disponível entre`,
        info: `${parseISODateToHours(
          offer.startTimeCollect
        )} e ${parseISODateToHours(offer.deadlineCollect)}`,
        directionsInfo: `${TEXT.LOGGI} • ${offer.waypoints[0]?.neighborhood}`
      },
      styles: {
        bgcolor: colors.blue[50],
        color: colors.blue[900],
        infoVariant: 'h6'
      }
    };
  },
  opportunistic: offer => {
    return {
      messages: {
        subtitle: TEXT.UP_TO,
        info: formatMoney(offer.price),
        directionsInfo: `${offer.steps} ${
          TEXT.STEPS
        } • ${noDecimalFormatDistance(offer.distance)}`
      },
      styles: {
        bgcolor: '#E5F0FF',
        color: '#003B99',
        infoVariant: 'h5'
      }
    };
  },
  default: offer => {
    return {
      messages: {
        subtitle: TEXT.UP_TO,
        info: formatMoney(offer.price),
        directionsInfo: `${offer.steps} ${
          TEXT.STEPS
        } • ${noDecimalFormatDistance(offer.distance)}`
      },
      styles: {
        bgcolor: colors.root[0],
        color: colors.root[900],
        infoVariant: 'h5'
      }
    };
  }
};
function ShowcaseCard({
  offer,
  onAccept,
  onMoreOffers,
  onError,
  getOfferService
}) {
  const classes = useStyles();
  const [distance, setDistance] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(offer);
  const [isOfferDetailsOpen, setIsOfferDetailsOpen] = useState(false);
  const isEnabledUseAllocationToListOffersByDriverId = featureSwitchEnabledForDriverId(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffersByDriverId)
  );
  const isEnabledUseAllocationToListOffers = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffers)
  );
  const [hasLoadedOffer, setHasLoadedOffer] = useState(false);

  const useAllocation =
    isEnabledUseAllocationToListOffers ||
    isEnabledUseAllocationToListOffersByDriverId;

  const lmcsEnableNewCapacityReserveCard = useFeatureSwitch(
    featureSwitches.enableNewCapacityReserveCard
  );
  const isEnabledNewCapacityReserveCard = featureSwitchEnabledForDriverLMC(
    lmcsEnableNewCapacityReserveCard
  );

  const firstWaypoint = offer.waypoints[0].coordinates;

  const handleStateOfferDetails = () => {
    if (!isOfferDetailsOpen) {
      if (!hasLoadedOffer && !offer.capacityReserve && useAllocation) {
        getOfferService(offer.id)
          .then(response => {
            const offerCard = response?.offerCard
              ? parseOfferProtoToOfferCard(response.offerCard)
              : onError(TEXT.LOAD_OFFER_ERROR);

            setSelectedOffer(offerCard);
            setHasLoadedOffer(true);
          })
          .catch(err => {
            onError(err.message);
          });
      }

      setIsOfferDetailsOpen(!isOfferDetailsOpen);
    }
  };

  const titleComponent = () => {
    const reason = offer.preferenceReason;
    if (offer.capacityReserve) {
      return (
        <CapacityReserveToolTip startTimeCollect={offer.startTimeCollect} />
      );
    }
    if (offer.opportunistic) {
      return <OpportunisticToolTip distance={distance} />;
    }
    if (offer.recommended) {
      return <RecommendedTooltip />;
    }
    if (reason === 'PREFERENCE_REASON_ROUND_VIP') {
      return <RoundVIPToolTip />;
    }
    return <DistanceTooltip distance={distance} />;
  };

  useEffect(() => {
    getDistance(firstWaypoint)
      .then(dist => setDistance(noDecimalFormatDistance(dist)))
      .catch(() => setDistance('-'));
  }, [firstWaypoint]);

  let propertiesKey = 'default';
  if (offer.capacityReserve && !isEnabledNewCapacityReserveCard)
    propertiesKey = 'capacityReserve';
  else if (offer.opportunistic) propertiesKey = 'opportunistic';

  const cardProperties = cardPropertiesEnum[propertiesKey](offer);

  return (
    <Box
      borderRadius={8}
      bgcolor={cardProperties.styles.bgcolor}
      border={1}
      borderColor={colors.smoke[200]}
      color={cardProperties.styles.color}
      p={3}
      onClick={handleStateOfferDetails}
      data-testid="showcase-card"
      height="100%"
    >
      <Backdrop
        className={classes.backdrop}
        open={isOfferDetailsOpen}
        data-testid="showcase-backdrop"
      >
        {offer.capacityReserve && isEnabledNewCapacityReserveCard && (
          <CapacityReserveDetailsV2
            offer={offer}
            onAccept={onAccept}
            onBack={() => setIsOfferDetailsOpen(!isOfferDetailsOpen)}
          />
        )}
        {offer.capacityReserve && !isEnabledNewCapacityReserveCard && (
          <CapacityReserveDetails
            offer={offer}
            onAccept={onAccept}
            onMoreOffers={onMoreOffers}
            onBack={() => setIsOfferDetailsOpen(!isOfferDetailsOpen)}
          />
        )}
        {!offer.capacityReserve && (
          <OfferDetails
            offer={selectedOffer}
            onAccept={onAccept}
            onMoreOffers={onMoreOffers}
            onBack={() => setIsOfferDetailsOpen(!isOfferDetailsOpen)}
          />
        )}
      </Backdrop>
      {offer.capacityReserve && isEnabledNewCapacityReserveCard ? (
        <CapacityReserveTooltipBody
          collectStartTime={offer.startTimeCollect}
          collectEndTime={offer.deadlineCollect}
          regionName={offer.regionName}
        />
      ) : (
        <Box height="100%" display="flex" flexDirection="column">
          <Box flexGrow={1}>{titleComponent()}</Box>
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box
                  pt={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  data-testid="showcase-subtitle"
                >
                  <Typography variant="caption" component="span">
                    {cardProperties.messages.subtitle}
                  </Typography>
                </Box>
                <Box
                  pt={0.5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" component="span">
                    <Box
                      component="strong"
                      fontWeight="fontWeightBold"
                      display="flex"
                      alignItems="center"
                      data-testid="showcase-info"
                    >
                      {cardProperties.messages.info}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                noWrap
                data-testid="showcase-directions-info"
              >
                {cardProperties.messages.directionsInfo}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

ShowcaseCard.defaultProps = {
  getOfferService: getOffer
};

ShowcaseCard.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    capacityReserve: PropTypes.bool,
    opportunistic: PropTypes.bool,
    startTimeCollect: PropTypes.string,
    regionName: PropTypes.string,
    preferenceReason: PropTypes.string.isRequired,
    deadlineCollect: PropTypes.string.isRequired,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onMoreOffers: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  getOfferService: PropTypes.func
};

export default React.memo(ShowcaseCard);
