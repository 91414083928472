const hasReadReminderLocalStorageKey = 'has-read-package-return-reminder';

function shouldShowPackageReturnReminder() {
  const hasReadPackageReturnReminderStorage = localStorage.getItem(
    hasReadReminderLocalStorageKey
  );

  return !hasReadPackageReturnReminderStorage;
}

function userHasReadPackageReturnReminder() {
  localStorage.setItem(hasReadReminderLocalStorageKey, true);
}

function clearHasReadPackageReturnReminder() {
  localStorage.removeItem(hasReadReminderLocalStorageKey);
}

export {
  shouldShowPackageReturnReminder,
  userHasReadPackageReturnReminder,
  clearHasReadPackageReturnReminder
};
