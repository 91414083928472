import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Drawer, Button, IconButton } from '@material-ui/core';
import { PAGES } from 'view';
import SharedPropTypes from 'view/shared-prop-types';
import { useRoundedBorder } from 'view/utils/styles';
import CloseIcon from '@material-ui/icons/Close';

export const messages = {
  title: (
    <Typography
      variant="h6"
      style={{
        fontWeight: 400
      }}
    >
      Informe o número abaixo <b>para escolher sua rota</b>
    </Typography>
  ),
  readBarcodeButton: 'Começar coleta',
  cancelPickupButton: 'Cancelar programação'
};

export default function IdentificationDrawer({
  pickup,
  isOpen,
  closeDrawer,
  onConfirm
}) {
  const history = useHistory();
  const roundedBorder = useRoundedBorder();
  const onReserveCancelationClick = () =>
    history.push({
      pathname: PAGES.CAPACITY_RESERVE_CANCELATION,
      state: { pickup, isFromIdentificationDrawer: true }
    });

  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDrawer}
      classes={{ paper: roundedBorder.root }}
    >
      <Box px={3} pt={4} display="flex" flexDirection="column">
        <IconButton
          data-testid="drawer-back-icon"
          onClick={closeDrawer}
          style={{ alignSelf: 'end', marginBottom: pxToRem(24) }}
        >
          <CloseIcon style={{ color: colors.neutrals.typeface.primary }} />
        </IconButton>
        {messages.title}
        <Box
          textAlign="center"
          borderRadius={16}
          mt={3}
          p={2}
          bgcolor={colors.neutrals.shapes.light}
          color={colors.neutrals.typeface.primary}
        >
          <Typography variant="h4">
            <b>{pickup.pickupId}</b>
          </Typography>
        </Box>
        <Box py={3}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            onClick={onConfirm}
            style={{ marginBottom: pxToRem(10) }}
          >
            {messages.readBarcodeButton}
          </Button>
          <Button
            fullWidth
            color="primary"
            size="large"
            onClick={onReserveCancelationClick}
            data-testid="cancelButtonFromIdentificationDrawer"
          >
            {messages.cancelPickupButton}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

IdentificationDrawer.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
