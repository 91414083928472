import { useEffect } from 'react';

import { goTo } from 'operations/history';
import {
  removePickupIdFromOfferCapacityReserve,
  getPickupIdFromOfferCapacityReserve,
  setPickupIdFromOfferCapacityReserve
} from 'operations/pickup';
import { parseAssignmentWaypointToLastMilePickup } from 'view/molecules/assignment-list/utils';

/**
 * This method will be called when the driver
 * accept an offer after beep a bag in drive thru
 * for a capacity reserve pickup
 * @param {*} demandId
 */
const setRedirectToPickupPackagesValue = demandId => {
  setPickupIdFromOfferCapacityReserve(demandId);
  goTo.assignmentList();
};

export default function useRedirectToPickupPackages(assignmentsWaypoints) {
  const pickupIdFromOffer = getPickupIdFromOfferCapacityReserve();

  useEffect(() => {
    if (pickupIdFromOffer && assignmentsWaypoints?.length > 0) {
      const capacityReservePickup = assignmentsWaypoints?.find(waypoint => {
        return (
          waypoint.assignments.filter(
            assignment => assignment.pickupId === pickupIdFromOffer
          ).length > 0
        );
      });

      if (capacityReservePickup) {
        const { actions, ...pickupWithNoActions } = capacityReservePickup;
        const parsedPickup = parseAssignmentWaypointToLastMilePickup(
          pickupWithNoActions
        );
        goTo.pickupPackages(parsedPickup);
      }
      removePickupIdFromOfferCapacityReserve();
    }
  }, [assignmentsWaypoints, pickupIdFromOffer]);

  return [pickupIdFromOffer];
}

export { setRedirectToPickupPackagesValue };
