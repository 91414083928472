export const TIME_MESSAGES = {
  today: 'hoje',
  until: 'até',
  nextWorkday: 'próximo dia útil'
};

export const DEADLINE_DAY = {
  today: {
    withTime: 'todayWithTime',
    default: 'todayDefault'
  },
  nextWorkday: {
    defaultUntil: 'nextWorkdayDefaultUntil',
    defaultOn: 'nextWorkdayDefaultOn'
  }
};
