import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

export default function ShowcaseContainer({ page }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      p={0}
      width="100%"
      height="100%"
    >
      {page}
    </Box>
  );
}

ShowcaseContainer.propTypes = {
  page: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};
