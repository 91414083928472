import api from 'operations/config/api/allocation';

/**
 * @param {string} surveySlug
 * @return {Promise<{Object<SurveyConfig>}>}
 */
function getSurveyConfig(surveySlug) {
  return api()
    .url(`/v1/survey/getsurvey/${surveySlug}`)
    .get();
}

/**
 * @param {Object} params
 * @param {string} params.surveySlug
 * @param {string} params.driverId
 * @param {Object[]} params.options
 * @param {Object[]} params.extras
 * @return {Promise<{Object<SurveyConfig>}>}
 */
function sendSurveyAnswer({ surveySlug, driverId, options, extras }) {
  return api()
    .url(`/v1/survey/submitresult`)
    .post({
      result: {
        slug_key: surveySlug,
        driver_id: driverId,
        options: options.map(o => {
          return {
            option_id: o.id,
            open_response: o.openResponse
          };
        }),
        extras: extras.map(e => {
          return {
            key: e.key,
            value: e.value
          };
        })
      }
    });
}

export { getSurveyConfig, sendSurveyAnswer };
