export default {
  FEATURED_OFFERS: 'Ofertas em destaque',
  SHOW_ALL_OFFERS: 'Mostrar todas as ofertas',
  FINISH_ITINERARY_TITLE: 'Sempre finalize sua rota',
  OFFER_NONE: 'Busque por ofertas disponíveis.',
  FINISH_ITINERARY_SUBTITLE:
    'Para aceitar novas ofertas e receber por elas, finalize a sua rota ativa.',
  OFFER_NONE_SUBTITLE:
    'Aqui embaixo você pode buscar novas ofertas para fazer enquanto espera.',
  OFFER_SEARCH: 'Buscar ofertas'
};

export const companyRelation = {
  collaborator: 'DRIVER_COMPANY_RELATION_COLLABORATOR',
  contractor: 'DRIVER_COMPANY_RELATION_CONTRACTOR',
  staff: 'DRIVER_COMPANY_RELATION_STAFF'
};

export const itineraryTypes = {
  itinerary_type_invalid: 'ITINERARY_TYPE_INVALID',
  itinerary_type_pro: 'ITINERARY_TYPE_PRO',
  itinerary_type_corp: 'ITINERARY_TYPE_CORP',
  itinerary_type_proish: 'ITINERARY_TYPE_PROISH'
};
