import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';

export const messages = {
  label: 'Relação com o destinatário',
  options: {
    empty: '',
    mother: 'Mãe',
    father: 'Pai',
    spouse: 'Esposo(a)',
    children: 'Filho(a)',
    siblings: 'Irmão(a)',
    neighbor: 'Vizinho(a)',
    other: 'Outro'
  }
};

export function RecipientRelationship({ onChange, value }) {
  return (
    <Box borderRadius={4} boxShadow={13} mt={3}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel data-testid="input-label" id="label">
          {messages.label}
        </InputLabel>
        <Select
          native
          labelId="label"
          label={messages.label}
          value={value}
          onChange={onChange}
          inputProps={{ 'data-testid': 'select' }}
        >
          {Object.keys(messages.options).map(key => (
            <option key={key} value={messages.options[key]}>
              {messages.options[key]}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

RecipientRelationship.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RecipientRelationship;
