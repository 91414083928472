import React from 'react';
import { Box, List, ListItem, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function PickupProblemsListSkeleton() {
  const items = ['item 1', 'item 2', 'item 3'];

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      data-testid="skeleton"
    >
      <List disablePadding>
        {items.map(item => (
          <ListItem key={item}>
            <Grid container direction="column" spacing={1}>
              <Box
                my={2}
                mx={1}
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Grid item>
                  <Skeleton
                    component={Box}
                    variant="rect"
                    borderRadius="8px"
                    my={0.25}
                    height={20}
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <Skeleton
                    component={Box}
                    variant="rect"
                    borderRadius="8px"
                    my={0.25}
                    height={40}
                    width={300}
                  />
                </Grid>
              </Box>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
