import { Machine, assign, sendParent } from 'xstate';

export const STATES = {
  documentStep: 'documentStep',
  loadingStep: 'loadingStep'
};

export const ACTIONS = {
  CONTINUE: 'CONTINUE',
  CHANGE: 'CHANGE',
  BACK: 'BACK'
};

const recipientMachine = Machine(
  {
    id: 'recipients',
    context: {
      name: '',
      document: '',
      documentType: '',
      notes: '',
      pkg: undefined
    },
    initial: STATES.documentStep,
    states: {
      [STATES.documentStep]: {
        on: {
          [ACTIONS.CHANGE]: {
            actions: assign({
              name: (_, event) => {
                return event.value.name;
              },
              document: (_, event) => {
                return event.value.document;
              },
              documentType: (_, event) => {
                return event.value.documentType;
              },
              notes: (_, event) => {
                return event.value.notes;
              },
              recipientRelationship: (_, event) => {
                return event.value.recipientRelationship;
              }
            })
          },
          [ACTIONS.BACK]: {
            actions: sendParent(() => ({
              type: ACTIONS.BACK
            }))
          },
          [ACTIONS.CONTINUE]: {
            cond: 'hasDocument',
            actions: sendParent(context => ({
              type: 'RECIPIENT.INFO',
              data: context
            }))
          }
        }
      }
    }
  },
  {
    guards: {
      hasName: context => {
        return !!context.name;
      },
      hasDocument: context => {
        return !!context.document;
      }
    }
  }
);

export default recipientMachine;
