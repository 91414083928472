import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CachedIcon from '@material-ui/icons/Cached';
import {
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ThreePackagesAndAClockIllustration } from 'view/atoms/illustrations/package-with-magnifying-glass.svg';
import AgencyReminder from './agency-reminder';
import ScannedPackagesList from './scanned-packages-list';

import { packageError } from './constants';

export const messages = {
  getScannedPackagesTitle: scannedPackagesLength => {
    const singularMessage = 'pacote adicionado';
    const pluralMessage = 'pacotes adicionados';
    const message =
      scannedPackagesLength === 1 ? singularMessage : pluralMessage;
    return `${scannedPackagesLength} ${message}`;
  },
  scannerInstruction:
    'Para adicionar pacotes, aponte a câmera para o código QR ou código de barras que estão na etiqueta.',
  seePackageList: 'Ver lista de pacotes',
  scanMorePackages: 'Bipar mais pacotes',
  checkPackagesButtonLabel: 'Conferir pacotes',
  buttonProblems: 'Problemas',
  networkErrorMessageLabel: 'Parece que aconteceu um erro de conexão.',
  tryAgain: 'Tentar de novo'
};

const checkPackagesButtonHeight = 78;

const isEmpty = packages => packages.length === 0;

const hasPackageWithNetworkError = packages =>
  packages.some(pkg => {
    return pkg.error === packageError.networkError;
  });

function ScannedPackageList({ packages, onDelete, onCheckPackages }) {
  return (
    <Box
      bottom={`${checkPackagesButtonHeight}px`}
      width="100%"
      overflow="auto"
      maxHeight="80%"
      borderRadius="16px"
      bgcolor="white"
      pb={2.25}
    >
      <Box pb={`${checkPackagesButtonHeight}px`} position="relative">
        <Box
          position="sticky"
          top="0"
          bgcolor="white"
          zIndex="2"
          px={2.5}
          pt={5}
          pb={1.25}
        >
          <Typography variant="h5">
            {messages.getScannedPackagesTitle(packages.length)}
          </Typography>
          {isEmpty(packages) && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              mt="60px"
            >
              <ThreePackagesAndAClockIllustration style={{ width: 100 }} />
              <Typography
                variant="subtitle1"
                align="center"
                style={{ marginTop: 20 }}
              >
                {messages.scannerInstruction}
              </Typography>
            </Box>
          )}
          {hasPackageWithNetworkError(packages) && (
            <Box mt={5}>
              <Typography variant="subtitle1">
                {messages.networkErrorMessageLabel}
              </Typography>
              <Box mt={2}>
                <Typography color="primary" onClick={onCheckPackages}>
                  {messages.tryAgain}{' '}
                  <CachedIcon
                    fontSize="small"
                    style={{ verticalAlign: 'sub', marginLeft: 10 }}
                  />
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <ScannedPackagesList packages={packages} onRemovePackage={onDelete} />
      </Box>
    </Box>
  );
}
ScannedPackageList.propTypes = {
  packages: ScannedPackagesList.propTypes.packages.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCheckPackages: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  containerCircularProgress: {
    color: colors.blue[600],
    height: 45
  }
}));

function CheckPackagesButton({ onClick, disabled, isLoading }) {
  const classes = useStyles();

  return (
    <Box
      position="fixed"
      bottom="0"
      bgcolor="white"
      width="100%"
      zIndex={2}
      borderTop={`1px solid ${colors.smoke[300]}`}
      p={2.5}
      display="flex"
    >
      <Grid container spacing={1} wrap="nowrap">
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onClick}
          disabled={disabled}
          fullWidth
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              data-testid="loading-biped-packages"
              className={classes.containerCircularProgress}
            />
          ) : (
            messages.checkPackagesButtonLabel
          )}
        </Button>
      </Grid>
    </Box>
  );
}
CheckPackagesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

function PackageScanning({ scannedPackages, onDelete, onCheckPackages }) {
  const [isFetchingBipedPackages, setIsFetchingBipedPackages] = useState(false);
  const [hasPackageWithError, setHasPackageWithError] = useState(false);

  useEffect(() => {
    setHasPackageWithError(scannedPackages.some(item => !!item.error));
  }, [scannedPackages]);

  const shouldDisableButton =
    hasPackageWithError || isFetchingBipedPackages || isEmpty(scannedPackages);

  const setIsFetchingState = () => {
    setIsFetchingBipedPackages(true);
    const barcodesToCheck = scannedPackages.map(({ barcodes }) => {
      return barcodes.map(barcode => ({ content: barcode }));
    });

    onCheckPackages(barcodesToCheck, true).finally(() => {
      setIsFetchingBipedPackages(false);
    });
  };

  return (
    <Box
      flex="1 1"
      bgcolor="#fff"
      style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
    >
      <ScannedPackageList
        packages={scannedPackages}
        onDelete={onDelete}
        onCheckPackages={() => setIsFetchingState()}
      />
      <CheckPackagesButton
        onClick={() => setIsFetchingState()}
        disabled={shouldDisableButton}
        isLoading={isFetchingBipedPackages}
      />
      <AgencyReminder />
    </Box>
  );
}
PackageScanning.propTypes = {
  scannedPackages: ScannedPackagesList.propTypes.packages.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCheckPackages: PropTypes.func.isRequired
};

export { messages as packageScanningMessages };
export default PackageScanning;
