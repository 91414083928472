import {
  closest,
  lesserSteps,
  pricier,
  shortRoutes
} from 'infra/services/filter-offers';
import { getDiffInDaysBetweenDateAndNow } from 'view/molecules/offer-card/util';
import { SORT_BY } from './constants';

async function sortByClosest(offers) {
  try {
    return await closest(offers);
  } catch (_) {
    return offers;
  }
}

export async function sortShowCaseOffers(offers) {
  const sameDayCapacityReserveOffers = [];
  const tomorrowCapacityReserveOffers = [];
  const defaultOffers = [];

  offers.forEach(offer => {
    if (offer.capacityReserve) {
      const days = getDiffInDaysBetweenDateAndNow(offer.collectStartTime);

      if (days === 0) {
        sameDayCapacityReserveOffers.push(offer);
      } else {
        tomorrowCapacityReserveOffers.push(offer);
      }
    } else {
      defaultOffers.push(offer);
    }
  });

  const sameDayCapacityReserveOfferSorted = await sortByClosest(
    sameDayCapacityReserveOffers
  );
  const tomorrowCapacityReserveOfferSorted = await sortByClosest(
    tomorrowCapacityReserveOffers
  );

  const capacityReserveOfferSorted = [
    ...sameDayCapacityReserveOfferSorted,
    ...tomorrowCapacityReserveOfferSorted
  ];

  if (capacityReserveOfferSorted.length === 0) return defaultOffers;

  if (capacityReserveOfferSorted.length === 1) {
    return [
      ...defaultOffers.slice(0, 1),
      capacityReserveOfferSorted[0],
      ...defaultOffers.slice(1)
    ];
  }

  const firstCapacityOffer = capacityReserveOfferSorted[0];
  const secondCapacityOffer = capacityReserveOfferSorted[1];
  const thirdAndFourthDefaultOffers = defaultOffers.slice(1, 3);
  const restOfDefaultOffers = defaultOffers.slice(3);
  const restOfCapacityOffer = capacityReserveOfferSorted.slice(2);

  return [
    ...defaultOffers.slice(0, 1),
    firstCapacityOffer,
    ...thirdAndFourthDefaultOffers,
    secondCapacityOffer,
    ...restOfDefaultOffers,
    ...restOfCapacityOffer
  ];
}

export async function sortRdOffers(offers, sortBy) {
  switch (sortBy) {
    case SORT_BY.SORT_BY_CLOSEST:
      return sortByClosest(offers);
    case SORT_BY.SORT_BY_HIGHEST_EARNINGS:
      return pricier(offers);
    case SORT_BY.SORT_BY_LEAST_STEPS:
      return lesserSteps(offers);
    case SORT_BY.SORT_BY_SHORTEST_ROUTES:
      return shortRoutes(offers);
    default:
      return sortByClosest(offers);
  }
}
