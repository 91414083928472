import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '@loggi/mar';

import { Typography, Box, Button, makeStyles } from '@material-ui/core';
import {
  Skeleton,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@material-ui/lab';
import { PAGES } from 'view';
import SharedPropTypes from 'view/shared-prop-types';
import AssignmentRow from 'view/molecules/assignment-row';
import useAssignmentList, { statesEnum } from 'hooks/use-assignment-list';
import { mutationStates } from 'models/package-mutation';

export const messages = {
  somethingHasHappened: 'Opa, aconteceu alguma coisa com sua rota.',
  returnThePackagesInYourCustody: packageCount =>
    `Vá até a agência Loggi na qual coletou para devolver os ${packageCount} pacotes que ainda estão com você.`,
  syncYourPackages:
    'Precisamos que você também tente atualizar os pacotes com falha de sincronia.',
  packageReturnInstructions: [
    'Quando chegar, explique que sua rota foi desalocada enquanto você estava entregando por um erro do sistema.',
    'Devolva para o time os pacotes que ainda estão com você',
    'Aguarde o pessoal da agência receber os pacotes e finalizar suas entregas.',
    'Se quiser, colete os mesmos pacotes de novo ou espere uma nova oferta'
  ],
  finishShipmentButton: 'Finalizar entregas',
  whyThisHappenedButton: 'Por que isso aconteceu?'
};

const useStyles = makeStyles(() => ({
  deleteEmptyLeftSpace: {
    '&:before': {
      flex: 0,
      padding: '0'
    }
  },
  noLeftPaddingTimeline: {
    padding: '6px 0px'
  },
  smallPaddingTimelineContent: {
    paddingTop: '3px',
    paddingBottom: '20px'
  }
}));
function PackageReturnInstructions() {
  const classes = useStyles();
  return (
    <Timeline className={classes.noLeftPaddingTimeline}>
      {messages.packageReturnInstructions.map((step, index) => (
        <TimelineItem
          className={classes.deleteEmptyLeftSpace}
          key={step.length}
        >
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined" />
            {index !== messages.packageReturnInstructions.length - 1 && (
              <TimelineConnector />
            )}
          </TimelineSeparator>
          <TimelineContent className={classes.smallPaddingTimelineContent}>
            <Typography variant="body1">{step}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export function ItineraryDisassociation({
  packagesWithSyncPending = [],
  packagesInCustodyCount,
  isLoading = false,
  retryPackage = () => {},
  finishShipment = () => {}
}) {
  const hasPackagesPendingToBeSynced = !!packagesWithSyncPending.length;
  const canFinishShipment = !isLoading && !hasPackagesPendingToBeSynced;

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight="100%">
      <Box flexGrow={2} overflow="auto">
        <Box px={2.5} pt={7} pb={2.5}>
          <Typography variant="h5">{messages.somethingHasHappened}</Typography>
          <Box pt={1.25}>
            <Typography variant="subtitle2">
              {messages.returnThePackagesInYourCustody(
                isLoading
                  ? ''
                  : packagesInCustodyCount - packagesWithSyncPending.length
              )}
            </Typography>
          </Box>
        </Box>

        {hasPackagesPendingToBeSynced && (
          <Box px={2.5} pb={3.5}>
            <Typography variant="subtitle2">
              {messages.syncYourPackages}
            </Typography>
          </Box>
        )}
        {isLoading && (
          <Box px={2.5}>
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              height={15}
              width={200}
            />
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              width={350}
              height={30}
            />
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              width={200}
              height={20}
            />
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              width={70}
              height={15}
            />
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              width={60}
              height={15}
            />
          </Box>
        )}
        {!isLoading &&
          packagesWithSyncPending.map(pkg => (
            <AssignmentRow
              key={pkg.packageId}
              assignment={pkg}
              onRetry={retryPackage}
            />
          ))}
        <Box px={2.5}>
          <PackageReturnInstructions />
        </Box>
      </Box>

      <Box
        px={2.5}
        py={2.5}
        pb={2.5}
        borderTop={1}
        boxShadow={24}
        borderColor={colors.smoke[300]}
      >
        <Box pb={2.5}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            disabled={!canFinishShipment}
            onClick={finishShipment}
          >
            {messages.finishShipmentButton}
          </Button>
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          href="https://ajuda.loggi.com/hc/pt-br/articles/360051561332"
        >
          {messages.whyThisHappenedButton}
        </Button>
      </Box>
    </Box>
  );
}

ItineraryDisassociation.propTypes = {
  packagesWithSyncPending: SharedPropTypes.packages.isRequired,
  packagesInCustodyCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  retryPackage: PropTypes.func.isRequired,
  finishShipment: PropTypes.func.isRequired
};
ItineraryDisassociation.defaultProps = {
  packagesInCustodyCount: null
};

export default function ItineraryDisassociationPage() {
  const location = useLocation();
  const history = useHistory();
  const { currentState, packages = [], retryPackageSync } = useAssignmentList();

  const packagesWithSyncPending = packages.filter(pkg =>
    [mutationStates.FAILED_TO_SYNC, mutationStates.SYNCING].includes(
      pkg.mutationSyncState
    )
  );

  // when assignment list loads, it'll make a request. if the request returns
  // a 410 with no package in custody, the driver will be redirected to the
  // driver app. if there's still packages in the driver's custody, the driver will
  // be redirected to this page again.
  const goToAssignmentList = () => history.push(PAGES.PACKAGE_LIST);
  const isLoading = currentState.state === statesEnum.LOADING;

  return (
    <ItineraryDisassociation
      isLoading={isLoading}
      packagesInCustodyCount={location.state.packagesInCustodyCount}
      packagesWithSyncPending={packagesWithSyncPending}
      retryPackage={retryPackageSync}
      finishShipment={goToAssignmentList}
    />
  );
}
