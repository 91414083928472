import React from 'react';
import { string, func, oneOfType } from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';
import { Button, Box, Typography, Drawer, Grid } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { formatFullAddress } from 'view/text-formatters/package-info';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRoundedBorder } from 'view/utils/styles';
import { buildLoggiAddressV2 } from 'view/utils';

import { ReactComponent as DirectionMap } from './direction-map.svg';

const STATE_ENUM = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  isOpen: state => state !== STATE_ENUM.CLOSED
};

const messages = {
  title: 'Como chegar',
  buttonAddressCopy: 'Copiar endereço',
  buttonOpenMapsApp: 'Abrir no mapa',
  notificationMessage: 'Endereço copiado com sucesso'
};

function DestinationDrawer({
  drawerState,
  setDrawerState,
  destination,
  notificationFunction
}) {
  const roundedBorder = useRoundedBorder();

  const addressLines = buildLoggiAddressV2(destination);
  const hasLoggiAddress = !!addressLines;

  const fullAddress = hasLoggiAddress
    ? addressLines
    : formatFullAddress(destination);

  const lat = hasLoggiAddress
    ? destination.coordinates.latitude
    : destination.lat;
  const lng = hasLoggiAddress
    ? destination.coordinates.longitude
    : destination.lng;

  const mapsUrl = `geo:${lat},${lng}?q=${lat},${lng} (${lat},${lng})`;

  return (
    <Drawer
      anchor="bottom"
      open={STATE_ENUM.isOpen(drawerState)}
      onClose={() => setDrawerState(STATE_ENUM.CLOSED)}
      classes={{ paper: roundedBorder.root }}
    >
      {STATE_ENUM.isOpen(drawerState) && (
        <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
          <Box textAlign="center">
            <DirectionMap />
          </Box>

          <Box py={1} display="flex" justifyContent="center">
            <Typography variant="h6" align="center">
              {messages.title}
            </Typography>
          </Box>
          <Box
            py={1}
            color={colors.smoke[800]}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="body1" align="center">
              {fullAddress}
            </Typography>
          </Box>

          <Box pt={5} pb={2.5}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item xs={6}>
                <CopyToClipboard
                  text={fullAddress}
                  onCopy={() => {
                    setDrawerState(STATE_ENUM.CLOSED);
                    notificationFunction(messages.notificationMessage);
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    {messages.buttonAddressCopy}
                  </Button>
                </CopyToClipboard>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setDrawerState(STATE_ENUM.CLOSED);
                    window.location = mapsUrl;
                  }}
                >
                  {messages.buttonOpenMapsApp}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

DestinationDrawer.propTypes = {
  notificationFunction: func.isRequired,
  drawerState: string.isRequired,
  setDrawerState: func.isRequired,
  destination: oneOfType([
    SharedPropTypes.destination,
    SharedPropTypes.loggiAddress
  ]).isRequired
};

export { DestinationDrawer, STATE_ENUM, messages };
