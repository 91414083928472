export const lastItineraryDateKey = 'last_itinerary_date';

export default function sendItineraryFinishedUTCDateToInsider(
  lastItineraryUTCDateValue
) {
  window?.driverAppBridge?.sendUTCDatePropertyToInsider?.(
    lastItineraryDateKey,
    lastItineraryUTCDateValue
  );
}
