import React from 'react';
import { Drawer, Box, Typography, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { ReactComponent as WifiOffIcon } from './icons/no-wifi.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';

export const states = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error'
};

export const data = {
  success: {
    title: 'Já vamos entrar em contato',
    subtitle:
      'Recebemos o seu pedido de ajuda. Espere um pouquinho que vamos falar com você.',
    icon: <SuccessIcon />
  },
  error: {
    generic: {
      title: 'Eita! Algo deu errado',
      subtitle: 'Você pode tentar de novo daqui a pouco.',
      icon: <WarningIcon />
    },
    network: {
      title: 'Opa! Problema com a sua internet',
      subtitle: 'Confira sua conexão e tente outra vez.',
      icon: <WifiOffIcon />
    }
  },
  button: 'Ok, entendi'
};

const getNotificationData = state => {
  const isOnline = window.navigator.onLine;
  if (state === states.success) return data.success;
  if (state === states.error)
    return isOnline ? data.error.generic : data.error.network;
  return {};
};

export default function NotificationDrawer({ state, onClose }) {
  const notificationData = getNotificationData(state);

  return (
    <Drawer
      data-testid="notification-drawer"
      anchor="bottom"
      open={state === states.success || state === states.error}
      PaperProps={{ square: false, component: Box, mb: -1 }}
    >
      <Box p={2.5} color={colors.smoke[900]}>
        <Box py={3} textAlign="center">
          {notificationData.icon}
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6" align="center">
            {notificationData.title}
          </Typography>
        </Box>
        <Box
          pt={1.25}
          color={colors.smoke[700]}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Typography variant="body2" align="center">
            {notificationData.subtitle}
          </Typography>
        </Box>
        <Box pt={5}>
          <Button
            variant="contained"
            data-testid="drawer-close-button"
            color="primary"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {data.button}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

NotificationDrawer.propTypes = {
  state: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};
