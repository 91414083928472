import React from 'react';
import PropTypes from 'prop-types';
import theme from '@loggi/mar';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AuthenticationRoutes from './routes';

import 'view/styles/index.css';

export default function DriverAuthenticationRoutes({
  createAccountPath,
  onSuccessLoginRedirectPath,
  enableSmsAuth,
  sendEventToAnalytics,
  enableFixSignInWithAnotherAccount
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticationRoutes
        createAccountPath={createAccountPath}
        onSuccessLoginRedirectPath={onSuccessLoginRedirectPath}
        enableSmsAuth={enableSmsAuth}
        sendEventToAnalytics={sendEventToAnalytics}
        enableFixSignInWithAnotherAccount={enableFixSignInWithAnotherAccount}
      />
    </ThemeProvider>
  );
}

DriverAuthenticationRoutes.propTypes = {
  createAccountPath: PropTypes.string,
  onSuccessLoginRedirectPath: PropTypes.string,
  enableSmsAuth: PropTypes.bool,
  sendEventToAnalytics: PropTypes.func,
  enableFixSignInWithAnotherAccount: PropTypes.bool
};

DriverAuthenticationRoutes.defaultProps = {
  createAccountPath: null,
  onSuccessLoginRedirectPath: null,
  enableSmsAuth: false,
  sendEventToAnalytics: () => {},
  enableFixSignInWithAnotherAccount: false
};
