import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography
} from '@material-ui/core';
import colors from '@loggi/mar/src/themes/drivers/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Check from '@material-ui/icons/Check';
import { SORT_BY } from 'infra/services/constants';
import LABELS from './constants';

const OrderButtonDrawer = ({ selectedSortOrder, setSelectedSortOrder }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSortOrderChange = sortOrder => {
    setSelectedSortOrder(sortOrder);
    setDrawerOpen(false);
  };

  return (
    <Box>
      <Chip
        onClick={() => {
          setDrawerOpen(true);
        }}
        label={LABELS.MENU_ITEMS[selectedSortOrder]}
        onDelete={() => {
          setDrawerOpen(true);
        }}
        deleteIcon={<ExpandMoreIcon style={{ color: colors.primary.pure }} />}
        style={{
          color: colors.primary.pure,
          backgroundColor: colors.primary.light,
          height: '2.5rem'
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        PaperProps={{
          style: {
            borderRadius: '1rem'
          }
        }}
      >
        <Box p={3}>
          <Box py={2}>
            <Typography variant="h6">{LABELS.MENU_TITLE}</Typography>
          </Box>
          <List>
            {Object.entries(LABELS.MENU_ITEMS).map(
              ([key, label], index, array) => (
                <Box key={key}>
                  <ListItem
                    key={key}
                    disableGutters
                    button
                    onClick={() => handleSortOrderChange(key)}
                  >
                    <Box py={1} width="100%">
                      {selectedSortOrder === key ? (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          style={{ color: colors.primary.pure }}
                        >
                          <ListItemText primary={label} />
                          <ListItemIcon>
                            <Check style={{ color: colors.primary.pure }} />
                          </ListItemIcon>
                        </Box>
                      ) : (
                        <ListItemText primary={label} />
                      )}
                    </Box>
                  </ListItem>
                  {index !== array.length - 1 && <Divider />}
                </Box>
              )
            )}
          </List>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

OrderButtonDrawer.defaultProps = {
  selectedSortOrder: SORT_BY.SORT_BY_CLOSEST,
  setSelectedSortOrder: () => {}
};

OrderButtonDrawer.propTypes = {
  selectedSortOrder: PropTypes.string,
  setSelectedSortOrder: PropTypes.func
};

export default OrderButtonDrawer;
