import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles({
  root: {
    background: colors.root[0]
  }
});

export default useStyles;
