import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LoggiBarcode } from 'view/atoms/illustrations/loggi-barcode.svg';
import {
  Box,
  Typography,
  Button,
  IconButton,
  MobileStepper
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import messages from './messages';
import useStyles from './styles';

export default function ScanningInstructions({
  onBackButtonClicked,
  onStartBip
}) {
  const classes = useStyles();

  return (
    <Box
      py={2.5}
      px={0.5}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <IconButton
          onClick={onBackButtonClicked}
          data-testid="scanning-info-back"
        >
          <ArrowBack color="primary" />
        </IconButton>
      </Box>

      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <LoggiBarcode />

        <Box px={1.5}>
          <Box pb={1.25} display="flex">
            <Typography variant="h5" align="center">
              {messages.pickupScanningInstructions.title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2" align="center">
              {messages.pickupScanningInstructions.description}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box alignItems="center" justifyContent="center" display="flex">
        <MobileStepper
          variant="dots"
          steps={2}
          position="static"
          activeStep={1}
          className={classes.root}
        />
      </Box>

      <Box pt={2} px={2.5}>
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          data-testid="btn-start-bip"
          onClick={onStartBip}
        >
          {messages.pickupScanningInstructions.startBipButton}
        </Button>
      </Box>
    </Box>
  );
}

ScanningInstructions.propTypes = {
  onStartBip: PropTypes.func.isRequired,
  onBackButtonClicked: PropTypes.func.isRequired
};

ScanningInstructions.url = '/instrucoes_de_bipagem';
