import { getAuthorizationTokens } from '@loggi/authentication-lib';

/* eslint-disable camelcase */
const CREDENTIAL_KEY = 'currentUser';
const AUTH_METHOD_KEY = 'auth_method';
const apiAuthKey = 'apiAuthKey';

export function parseJwt(token) {
  if (!token) {
    return undefined;
  }

  const base64Url = token.split('.')[1];
  if (!base64Url) {
    return undefined;
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const buffer = Buffer.from(base64, 'base64');

  return JSON.parse(buffer);
}

export const authMethods = {
  link: 'link',
  email_api_key: 'email_api_key',
  cognito: 'cognito'
};

const getDriverAppApiKey = () => window.driverAppBridge?.getLWApiKey?.();

export const getCredentials = async () => {
  const { idToken } = await getAuthorizationTokens();

  if (idToken) {
    return { access_token: idToken, directLoggiWeb: false };
  }

  const apiKey = getDriverAppApiKey();
  if (apiKey) {
    return { access_token: apiKey, directLoggiWeb: true };
  }

  const credentials = localStorage.getItem(CREDENTIAL_KEY);
  return JSON.parse(credentials) || {};
};

export const getAuthMethod = () => localStorage.getItem(AUTH_METHOD_KEY);

export const getApiAuthKey = () => localStorage.getItem(apiAuthKey);

export const setAuthMethod = authMethod =>
  localStorage.setItem(AUTH_METHOD_KEY, authMethod);

export const storeCredentials = (credential, authMethod = '') => {
  const parsedJwt = parseJwt(credential.access_token);

  const apiKey = parsedJwt?.apiAuthKey || '';

  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credential));
  localStorage.setItem(AUTH_METHOD_KEY, authMethod);
  localStorage.setItem(apiAuthKey, apiKey);
};

export const storeAlreadyLoggedCredentials = (data, authMethod) => {
  const credential = {
    username: data.email,
    roles: [''],
    photo: data.photo
  };

  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credential));
  localStorage.setItem(AUTH_METHOD_KEY, authMethod);
  localStorage.setItem(apiAuthKey, `ApiKey ${data.email}:${data.apiKey}`);
};

export const removeCredentials = () => {
  localStorage.removeItem(CREDENTIAL_KEY);
  localStorage.removeItem(apiAuthKey);
};

export const updateCredentials = async newCredentials => {
  const credentials = await getCredentials();
  const {
    access_token,
    refresh_token,
    token_type,
    expires_in,
    observability_id,
    photo,
    full_name
  } = newCredentials;
  credentials.access_token = access_token;
  credentials.refresh_token = refresh_token;
  credentials.token_type = token_type;
  credentials.expires_in = expires_in;
  credentials.observability_id = observability_id;
  credentials.photo = photo;
  credentials.full_name = full_name;

  localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(credentials));
};

export const logout = () => {
  removeCredentials();
  window.driverAppBridge?.logout();
};

export const isAuthenticated = async () => {
  const credentials = await getCredentials();
  return Object.entries(credentials).length > 0;
};

export const getLoggedUser = async () => getCredentials().username;

// It will verify if observability_id prop exists before
// returns value as old localStorage won't have this 'till
// updateCredentials or login again.
export const getObservabilityId = async () => {
  const credentials = await getCredentials();
  return 'observability_id' in credentials ? credentials.observability_id : '';
};

export const getUserInfo = () => {
  const credentials = localStorage.getItem(CREDENTIAL_KEY);
  return JSON.parse(credentials) || {};
};

const credentialCurrentUser = 'current_user';

export const getCurrentUser = () => {
  const creadentials = localStorage.getItem(credentialCurrentUser);
  return JSON.parse(creadentials) || {};
};

export const storeCurrentUser = credential => {
  localStorage.setItem(credentialCurrentUser, JSON.stringify(credential));
};
