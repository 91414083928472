import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { GoogleSignIn } from '@loggi/authentication';
import { ReactComponent as LoggiIcon } from 'view/assets/svg/loggi.svg';
import { pages } from 'routes';
import { onboardingPageTexts, useStyles } from './constants';

function UserNotLoggedIn() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Box pt={isDesktop ? 4.5 : 2.5}>
        <Typography variant="h3" component="span" fontWeight="fontWeightBold">
          {onboardingPageTexts.title}
        </Typography>
      </Box>
      <Box pt={1.5}>{onboardingPageTexts.subtitle}</Box>
    </Box>
  );
}

function UserNotLoggedInButtons() {
  const classes = useStyles();

  const onHelpMe = event => {
    event.preventDefault();
    window.open(onboardingPageTexts.helpLink);
  };

  return (
    <Box>
      <GoogleSignIn />
      <Box pt={2.5} pb={2.5} textAlign="center">
        <Typography variant="body1">
          {onboardingPageTexts.help}{' '}
          <Box component="a" className={classes.link} onClick={onHelpMe}>
            {onboardingPageTexts.helpAction}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

function CognitoNotAssociated({ authenticatedUser }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Box pt={isDesktop ? 4.5 : 2.5}>
        <Typography variant="h4">{onboardingPageTexts.titleCognito}</Typography>
      </Box>
      <Box pt={1.5}>
        <Typography variant="subtitle2">
          {`${authenticatedUser.name?.split(' ')[0]}${
            onboardingPageTexts.notFoundAccount
          }`}{' '}
          <Box component="span" fontWeight="fontWeightBold">
            {authenticatedUser.email}
          </Box>
          {onboardingPageTexts.notFoundAccountAction}
        </Typography>
      </Box>
    </Box>
  );
}

CognitoNotAssociated.propTypes = {
  authenticatedUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired
};

function CognitoNotAssociatedButtons({
  logout,
  handleCreateAccountButtonClick
}) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={() => history.push(pages.login)}
      >
        {onboardingPageTexts.loginAction}
      </Button>
      <Box pt={2} pb={3} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={handleCreateAccountButtonClick}
        >
          {onboardingPageTexts.createAccount}
        </Button>
      </Box>
      <Box pb={2.5} textAlign="center">
        <Typography variant="body1">
          {onboardingPageTexts.wrongEmail}{' '}
          <Box component="a" className={classes.link} onClick={logout}>
            {onboardingPageTexts.logoutAction}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

CognitoNotAssociatedButtons.propTypes = {
  logout: PropTypes.func.isRequired,
  handleCreateAccountButtonClick: PropTypes.func.isRequired
};

export default function OnboardingCognitoPage({
  authenticatedUser,
  handleCreateAccountButtonClick,
  logout
}) {
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const getStyleClasses = isScreenDesktop => {
    return isScreenDesktop
      ? {
          deviceClass: classes.headerDesktop,
          contentGridClass: classes.contentGridDesktop,
          contentTextClass: classes.contentTextDesktop
        }
      : {
          deviceClass: classes.headerMobile,
          contentGridClass: classes.contentGridMobile,
          contentTextClass: classes.contentTextMobile
        };
  };

  const { deviceClass, contentGridClass, contentTextClass } = getStyleClasses(
    isDesktop
  );
  const headerClass = `${deviceClass} ${classes.header}`;

  return (
    <Grid container className={classes.headerDesktop}>
      <Grid item sm={12} md={6} className={deviceClass}>
        <Box className={headerClass} />
      </Grid>
      <Grid item sm={12} md={6} className={contentGridClass}>
        <Box pt={5} px={3.5} className={contentTextClass}>
          <Box display="flex" flexGrow={1} alignItems="flex-start">
            <Box>
              <LoggiIcon height={isDesktop ? '60px' : '40px'} />
              {authenticatedUser ? (
                <CognitoNotAssociated authenticatedUser={authenticatedUser} />
              ) : (
                <UserNotLoggedIn />
              )}
            </Box>
          </Box>
          <Box pt={isDesktop ? 7.5 : 1.5}>
            {authenticatedUser ? (
              <CognitoNotAssociatedButtons
                logout={logout}
                handleCreateAccountButtonClick={handleCreateAccountButtonClick}
              />
            ) : (
              <UserNotLoggedInButtons />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

OnboardingCognitoPage.propTypes = {
  authenticatedUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }),
  handleCreateAccountButtonClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};
OnboardingCognitoPage.defaultProps = {
  authenticatedUser: null
};
