import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Box, Typography, Button, IconButton } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { ArrowBack } from '@material-ui/icons';

import {
  colors,
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { capitalizeString } from 'view/text-formatters';
import SharedPropTypes from 'view/shared-prop-types';
import getProxyPhone, { getWhatsAppProxy } from 'operations/proxy-phone';
import StatusCodeChip from 'view/atoms/status-code-chip';
import RecipientPhone from 'view/molecules/recipient-phone';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';

import { ReactComponent as WarningIcon } from 'view/molecules/notification-drawer/icons/warning.svg';

import {
  DestinationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/destination-drawer';
import ConfirmDismissDrawer from 'view/molecules/confirm-dismiss-drawer';
import getDeadlineCollectMessage, { messages } from 'operations/deadline-time';
import {
  buildLoggiAddressV2,
  splitLoggiAddress,
  setScrollToTop
} from 'view/utils';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { featureSwitches } from 'operations/feature-switches';
import { statusCodes } from 'operations/update-status';
import AssignmentIndex from 'view/molecules/assignment-row/assignment-index';
import AdditionalData from './additional-data';
import {
  SafeDeliveryTokenDrawer,
  STATE_ENUM as DRAWER_TOKEN_STATE
} from './safe-delivery';
import {
  ProblemsWithSafeDeliveryCode,
  STATE_ENUM as PROBLEM_TOKEN_STATE
} from './safe-delivery/drawer-problems-safe-delivery';
import { TEXT, messages as dialogMessages, useStyles } from './constants';
import {
  warningWasSeen,
  markWarningAlreadySeen
} from './mailbox-delivery/warning-storage';

function DoNotAcceptMailboxDrawer({ packageId, doNotAcceptMailboxDelivery }) {
  const [isOpen, setOpen] = useState(false);

  // Controls whether or not the user has already seen the alert, we're going to display it
  // only once.
  useEffect(() => {
    const wasDisplayed = warningWasSeen(packageId);
    if (doNotAcceptMailboxDelivery && !wasDisplayed) {
      setOpen(true);
      markWarningAlreadySeen(packageId);
    }
  }, [doNotAcceptMailboxDelivery, packageId]);

  return (
    <ConfirmDismissDrawer
      icon={<WarningIcon />}
      data={dialogMessages.doNotDeliverToMailbox}
      isOpen={isOpen}
      handleConfirmAction={() => {
        setOpen(false);
      }}
      isError={false}
    />
  );
}

export default function PackageDetails({
  onGoBack,
  onProblems,
  onSignature,
  pkg
}) {
  const {
    packageId,
    recipient: { name: recipientName },
    destination: {
      addressStreet,
      addressNumber,
      addressComplement,
      vicinity,
      city,
      state
    },
    safeDelivery,
    companyName = '',
    doNotAcceptMailboxDelivery = false,
    additionalDeliveryInfo = '',
    canDeliveryMailbox = false,
    deliveryEvidenceSignedUrl = null,
    deliveryFlow = '',
    direction
  } = pkg;

  setScrollToTop();

  const enableNewPackageDetails = useFeatureSwitch(
    featureSwitches.enableNewPickupAndPackageDetails
  );

  const addressLines = buildLoggiAddressV2(pkg.addressDestination);

  const hasLoggiAddress = !!addressLines;

  const { addressTitle, addressBody } = splitLoggiAddress(addressLines);

  const isDeliveryToDc = deliveryFlow === 'deliver_to_dc';

  const classes = useStyles();

  const statusCode = pkg.status?.code;

  const [count, setCount] = useState(0);

  const [destinationDrawerState, setDestinationDrawerState] = useState(
    DRAWER_STATE.CLOSED
  );

  const [notificationMessage, setNotificationMessage] = useState('');

  const [safeDeliveryTokenState, setSafeDeliveryTokenState] = useState(
    DRAWER_TOKEN_STATE.CLOSED
  );

  const [
    safeDeliveryProblemsTokenState,
    setSafeDeliveryProblemsTokenState
  ] = useState(PROBLEM_TOKEN_STATE.CLOSED);

  const [insertedTokenAttempts] = useState([]);

  const handleOnProblems = () => {
    onProblems(insertedTokenAttempts);
  };

  const handleOnSignature = () => {
    onSignature(insertedTokenAttempts);
  };

  const addressTypeEnum = {
    DESTINATION_CLASSIFICATION_TYPE_INVALID: '',
    DESTINATION_CLASSIFICATION_TYPE_RESIDENTIAL_BUILDING: 'Prédio residencial',
    DESTINATION_CLASSIFICATION_TYPE_COMMERCIAL: 'Comercial',
    DESTINATION_CLASSIFICATION_TYPE_HOUSE: 'Casa'
  };

  const deadlineTime = pkg.deadlineDeliveryTime;

  const defaultDeadlineMessage = `${
    messages.deliver
  }${getDeadlineCollectMessage({
    isPickup: false,
    deadlineTime
  })} em`;
  const deadlineTimeMessage = getDeadlineCollectMessage({
    deadlineTime: pkg.deadlineDeliveryTime,
    startTime: pkg.startTime,
    showNewDeadlineTime: true
  });

  const [
    packageDestination,
    effectiveAddressTitle,
    effectiveAddressBody
  ] = hasLoggiAddress
    ? [pkg.addressDestination, addressTitle, addressBody]
    : [
        pkg.destination,
        `${capitalizeString(addressStreet)}, ${addressNumber}`,
        `${capitalizeString(vicinity)}, ${city} - ${state}`
      ];

  const hasDeliveryMailbox = canDeliveryMailbox && deliveryEvidenceSignedUrl;

  const configWarning = hasDeliveryMailbox
    ? { color: colors.yellow[700], text: TEXT.MAILBOX_ALERT }
    : { color: colors.red[900], text: TEXT.PREVENT_MAILBOX_WARNING };

  const showWarningMailBox = doNotAcceptMailboxDelivery || hasDeliveryMailbox;

  return (
    <>
      <Alert
        open={!!notificationMessage}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...ALERT_TYPES.SUCCESS}
      />

      <Grid container direction="column" className={classes.container}>
        <Box p={2} pl={0.5}>
          <Grid container justify="space-between" alignItems="center">
            <IconButton onClick={onGoBack}>
              <ArrowBack
                className={classes.tab}
                aria-label={TEXT.BUTTON_BACK}
              />
            </IconButton>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setDestinationDrawerState(DRAWER_STATE.OPEN);
              }}
            >
              {TEXT.BUTTON_NAVIGATE}
            </Button>
          </Grid>
        </Box>

        <Box
          px={2.5}
          pt={5}
          pb={4}
          color={colors.root[0]}
          display="flex"
          flexGrow={1}
          alignItems="flex-end"
        >
          <Box>
            {enableNewPackageDetails ? (
              <>
                <Box mb={1}>
                  <ThemeProvider
                    theme={getThemeForUserType({
                      userType: userTypeEnum.drivers,
                      version: availableThemeVersions.drivers.v2
                    })}
                  >
                    <AssignmentIndex
                      assignmentIndex={pkg.assignmentDisplayId}
                      useNewTheme
                      fromDetails
                    />
                  </ThemeProvider>
                </Box>
                <Box color={colors.blue[50]} mb={0.5}>
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    {deadlineTimeMessage.charAt(0).toUpperCase() +
                      deadlineTimeMessage.slice(1)}
                  </Typography>
                </Box>

                <Typography
                  data-testid="package-details-action-text"
                  variant="h6"
                  style={{ fontWeight: 600 }}
                >
                  {statusCode === statusCodes.DELIVERED
                    ? dialogMessages.actionText.done
                    : dialogMessages.actionText.toDo}
                </Typography>

                <Box pt={1} color={colors.blue[50]} data-testid="address">
                  <Typography variant="body1">
                    {effectiveAddressTitle}{' '}
                    {vicinity ? effectiveAddressBody : ''}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box color={colors.blue[50]}>
                  <Typography variant="body2">
                    {defaultDeadlineMessage}
                  </Typography>
                </Box>
                <Typography variant="h4">{effectiveAddressTitle}</Typography>
                {vicinity && (
                  <Box
                    pt={0.8}
                    color={colors.blue[50]}
                    data-testid="address-vicinity"
                  >
                    <Typography variant="body2">
                      {effectiveAddressBody}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        {showWarningMailBox && (
          <Box px={1.5} pt={0} pb={3}>
            <Box
              py={0.5}
              pl={2.5}
              pr={2}
              borderRadius={100}
              display="flex"
              alignItems="center"
              bgcolor={colors.red[50]}
              color={colors.root[900]}
            >
              <Box component="span" color={configWarning.color}>
                <Typography variant="h6">●</Typography>
              </Box>
              <Box width="100%" textAlign="center">
                <Typography variant="button">{configWarning.text}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box px={2.5} pt={5} bgcolor={colors.root[0]} className={classes.card}>
          {statusCode >= 0 && <StatusCodeChip status={statusCode} />}
          <Grid container alignItems="center">
            <Grid xs item>
              <Box pt={2.5} pb={1} color={colors.smoke[800]}>
                <Typography variant="body2">
                  {`Pacote da ${companyName} para`}
                </Typography>
              </Box>

              <Box pb={1.5}>
                <Typography variant="h5">
                  {capitalizeString(recipientName)}
                </Typography>
              </Box>

              {addressComplement && (
                <Box color={colors.smoke[800]} data-testid="address-complement">
                  <Typography variant="body2">{addressComplement}</Typography>
                </Box>
              )}
            </Grid>

            {!isDeliveryToDc && (
              <Grid item data-testid="phone">
                <Box ml={3.5}>
                  <RecipientPhone
                    recipientName={recipientName}
                    experimentName={pkg?.whatsappExperiment}
                    getPhone={() => getProxyPhone(packageId)}
                    getWhatsAppNumber={() => getWhatsAppProxy(packageId)}
                    packageId={packageId}
                    calledFrom="packageDetails"
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          {additionalDeliveryInfo && (
            <AdditionalData
              addressInstructions={additionalDeliveryInfo.enrichedInstructions}
              whoCanReceiveThePackage={
                additionalDeliveryInfo.whoCanReceiveThePackage
              }
              addressType={addressTypeEnum[additionalDeliveryInfo.addressType]}
              direction={direction}
            />
          )}

          <Box pt={5} pb={2.5}>
            {!isDeliveryToDc && (
              <>
                {enableNewPackageDetails ? (
                  <Grid container spacing={1} wrap="nowrap">
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleOnProblems}
                      >
                        {TEXT.BUTTON_PROBLEMS}
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        data-testid="confirm-delivery-button"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => {
                          if (safeDelivery) {
                            setSafeDeliveryTokenState(DRAWER_TOKEN_STATE.OPEN);
                          } else {
                            handleOnSignature();
                          }
                        }}
                      >
                        {TEXT.BUTTON_SIGNATURE}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justify="space-between">
                    <Button
                      data-testid="confirm-delivery-button"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => {
                        if (safeDelivery) {
                          setSafeDeliveryTokenState(DRAWER_TOKEN_STATE.OPEN);
                        } else {
                          handleOnSignature();
                        }
                      }}
                    >
                      {TEXT.BUTTON_SIGNATURE}
                    </Button>
                    <Box py={1} flexGrow={1}>
                      <Button
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleOnProblems}
                      >
                        {TEXT.BUTTON_PROBLEMS}
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
            {isDeliveryToDc && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handleOnSignature}
                data-testid="package-details-button-dc-signature"
              >
                {TEXT.BUTTON_DC_SIGNATURE}
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      <DestinationDrawer
        drawerState={destinationDrawerState}
        setDrawerState={setDestinationDrawerState}
        notificationFunction={setNotificationMessage}
        destination={packageDestination}
      />
      <SafeDeliveryTokenDrawer
        drawerState={safeDeliveryTokenState}
        setDrawerState={setSafeDeliveryTokenState}
        setDrawerStateProblem={setSafeDeliveryProblemsTokenState}
        countState={count}
        setCountState={setCount}
        onConfirm={handleOnSignature}
        safeDelivery={pkg.safeDelivery}
        recipient={pkg.recipient}
        companyName={companyName}
        insertedTokenAttempts={insertedTokenAttempts}
      />
      <ProblemsWithSafeDeliveryCode
        drawerState={safeDeliveryProblemsTokenState}
        setDrawerState={setSafeDeliveryTokenState}
        setDrawerStateProblem={setSafeDeliveryProblemsTokenState}
        countState={count}
        pkg={pkg}
        onProblems={handleOnProblems}
      />
      <DoNotAcceptMailboxDrawer
        packageId={pkg.packageId}
        doNotAcceptMailboxDelivery={doNotAcceptMailboxDelivery}
      />
    </>
  );
}

PackageDetails.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onProblems: PropTypes.func.isRequired,
  onSignature: PropTypes.func.isRequired,
  pkg: SharedPropTypes.package.isRequired
};

DoNotAcceptMailboxDrawer.propTypes = {
  packageId: PropTypes.string.isRequired,
  doNotAcceptMailboxDelivery: PropTypes.bool.isRequired
};
