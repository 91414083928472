const getPackageBarcode = pkg => {
  const barcode = pkg?.barcode;
  const charsToShow = 8;

  if (barcode.length > charsToShow * 2) {
    const leftChars = barcode.substring(0, charsToShow);
    const rightChars = barcode.substring(barcode.length - charsToShow);
    const truncatedBarcode = `${leftChars}...${rightChars}`;
    return truncatedBarcode;
  }
  return barcode;
};

export default getPackageBarcode;
