import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'view/atoms/illustrations/error-template/error-icon.svg';

import { Box, Button, Typography, Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { ReactComponent as WifiOffIcon } from 'view/molecules/notification-drawer/icons/no-wifi.svg';
import { PAGES } from 'view';

import { useHistory } from 'react-router-dom';

export const TEXT_TRY_AGAIN_BUTTON = 'Tentar novamente';
export const TEXT_ERROR_TITLE = 'Eita! Algo deu errado';
export const TEXT_ERROR_DESCRIPTION =
  'Você pode tentar novamente daqui a pouco';
export const TEXT_NETWORK_ERROR_TITLE = 'Ué, parece que você está sem internet';
export const TEXT_NETWORK_ERROR_DESCRIPTION =
  'Confira a sua conexão e tente outra vez';

function parseErrorMessage(response) {
  if (response?.detail) {
    return response.detail;
  }

  if (response?.data?.message) {
    return response.data.message;
  }

  if (response?.errors) {
    const errors = response.errors.map(elem => elem.message).join('\n');

    if (errors) {
      return errors;
    }
  }

  return TEXT_ERROR_DESCRIPTION;
}

export default function ErrorTemplate({ response, onRetry }) {
  const history = useHistory();

  const genericError = {
    icon: <ErrorIcon />,
    title: TEXT_ERROR_TITLE,
    description: parseErrorMessage(response)
  };

  const networkError = {
    icon: <WifiOffIcon />,
    title: TEXT_NETWORK_ERROR_TITLE,
    description: TEXT_NETWORK_ERROR_DESCRIPTION
  };

  const { icon, title, description } = response ? genericError : networkError;

  return (
    <>
      <Box py={2.5} px={0.5}>
        <Grid container justify="space-between" alignItems="center">
          <IconButton
            onClick={() => history.push(PAGES.PACKAGE_LIST)}
            data-testid="close"
          >
            <Close style={{ color: colors.blue[500] }} />
          </IconButton>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="100%"
        textAlign="center"
        p={2.5}
      >
        <Box textAlign="center">{icon}</Box>
        <Box p={1} mt={2.5}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box color={colors.smoke[700]}>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Box>

      <Box p={2.5} position="absolute" bottom="0" width="100%">
        <Button
          onClick={onRetry}
          size="large"
          fullWidth
          color="primary"
          variant="outlined"
        >
          {TEXT_TRY_AGAIN_BUTTON}
        </Button>
      </Box>
    </>
  );
}
ErrorTemplate.propTypes = {
  response: PropTypes.shape({
    detail: PropTypes.string,
    data: PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    }),
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string
      })
    ),
    status: PropTypes.number
  }),
  onRetry: PropTypes.func.isRequired
};

ErrorTemplate.defaultProps = {
  response: null
};
