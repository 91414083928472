import React from 'react';
import DriversEmergency from '@loggi/drivers-emergency';
import { useHistory } from 'react-router-dom';
import shipmentEmergencyOperation from 'operations/shipment-emergency';
import getDriverType, { DRIVER_TYPE } from 'operations/feature-switches/driver';

export default function EmergencyWrapper() {
  const history = useHistory();
  const driverType = getDriverType();
  return (
    <DriversEmergency
      canNotifyLoggi={driverType === DRIVER_TYPE.MEI}
      notifyLoggi={shipmentEmergencyOperation}
      goBack={() => {
        history.goBack();
      }}
    />
  );
}
