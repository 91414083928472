/* eslint-disable camelcase */
import {
  getCredentials,
  logout,
  isAuthenticated,
  getObservabilityId
} from 'operations/auth';
import { goTo } from 'operations/history';
import assignmentListDB from 'operations/assignment-list/assignment-list-db';
import { handleOfferNotification } from 'operations/offer/offer-service';
import { notificationTypeEnum } from 'view/pages/offer-notification/constants';

const { clearDB } = assignmentListDB;

const loginErrorMessage =
  'Você está sem acesso na plataforma, é necessário entrar novamente';

async function getAccessToken() {
  if (await isAuthenticated()) {
    // Driver App token or Micronaut token
    const credentials = await getCredentials();
    return {
      token: credentials.access_token,
      directLoggiWeb: credentials.directLoggiWeb || false
    };
  }
  return '';
}

const replaceTokenOnRequestOptions = async ({
  token,
  options,
  directLoggiWeb
}) => ({
  ...options,
  headers: {
    ...(options.headers || {}),
    ...{
      Authorization: directLoggiWeb ? token : `Bearer ${token}`
    },
    ...{ observabilityId: await getObservabilityId() }
  }
});

const authMiddleware = next => async (url, options) => {
  const { token, directLoggiWeb } = await getAccessToken();

  if (!token) {
    goTo.authError();
    throw Error(loginErrorMessage);
  }

  const optionsWithAuthToken = await replaceTokenOnRequestOptions({
    token,
    options,
    directLoggiWeb
  });

  return next(url, optionsWithAuthToken);
};

const intercept410Middleware = next => async (url, options) => {
  const checkStatus = async response => {
    if (response?.status === 410) {
      const data = await response.clone().json();
      const notification = data?.errors?.[0]?.[0];

      if (notification && notification.type) {
        handleOfferNotification({
          notificationType: notification.type,
          eventAddress: notification.address,
          eventIsoDate: notification.event_iso_date
        });
      } else if (
        data?.errors[0]?.type ===
        notificationTypeEnum.https.itineraryDeadlineDeallocation
      ) {
        const allowedDelay = data?.errors[0]?.allowed_delay_minutes;
        const withoutItinerary = data?.errors[0]?.without_itinerary;
        goTo.itineraryDeadlineDeallocation({ allowedDelay, withoutItinerary });
      } else if (data?.has_packages_in_custody === false) {
        /* eslint-disable no-unused-expressions */
        window.driverAppBridge?.deallocateOrder();
        clearDB();
      } else {
        goTo.itineraryDisassociation({
          packagesInCustodyCount: data?.packages_in_custody_count
        });
      }
    }
    return response;
  };

  return next(url, options).then(checkStatus);
};

const intercept401Middleware = next => async (url, options) => {
  const checkStatus = async response => {
    if (response?.status === 401) {
      logout();
    }
    return response;
  };

  return next(url, options).then(checkStatus);
};

const intercept403Middleware = next => async (url, options) => {
  const checkStatus = async response => {
    if (response?.status === 403) {
      const data = await response.clone().json();

      const message = data?.message || data?.detail;

      if (
        message === 'Api Key invalid or not provided' ||
        message === 'Authentication credentials were not provided.'
      ) {
        logout();
      }
    }
    return response;
  };

  return next(url, options).then(checkStatus);
};

const intercept426Middleware = next => async (url, options) => {
  const checkStatus = async response => {
    if (response?.status === 426) {
      if (window.driverAppBridge?.updateAppVersion) {
        window.driverAppBridge?.updateAppVersion?.();
      }
    }
    return response;
  };

  return next(url, options).then(checkStatus);
};

const intercept409Middleware = next => async (url, options) => {
  const checkStatus = async response => {
    if (response?.status === 409) {
      const data = await response.clone().json();
      const details = data?.details?.[0];

      if (details?.notificationType) {
        const {
          notificationType,
          eventAddress,
          eventIsoDate,
          deadlineTime: allowedDelay,
          withoutItinerary,
          packagesInCustodyCount
        } = details;
        handleOfferNotification({
          notificationType,
          eventAddress,
          eventIsoDate,
          deadlineTime: allowedDelay,
          withoutItinerary,
          packagesInCustodyCount
        });
      } else {
        return response;
      }
    }
    return response;
  };

  return next(url, options).then(checkStatus);
};

const grpcInterceptors = {
  intercept409Middleware
};

export {
  getAccessToken,
  authMiddleware,
  replaceTokenOnRequestOptions,
  intercept401Middleware,
  intercept403Middleware,
  intercept410Middleware,
  intercept426Middleware,
  loginErrorMessage,
  grpcInterceptors
};
