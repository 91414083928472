import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, Dialog } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import messages from './messages';

export default function LoadingCompletePickupModal({ isOpen, onClose }) {
  return (
    <Dialog fullScreen display="flex" flexDirection="column" open={isOpen}>
      <Box p={3}>
        <Box>
          <CheckCircleOutline style={{ fontSize: '42px' }} />
        </Box>
        <Box pt={2}>
          <Typography variant="h4">
            {messages.loadingCompletePickup.title}
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography variant="subtitle1">
            {messages.loadingCompletePickup.text}
          </Typography>
        </Box>
      </Box>
      <Box p={2.5} display="flex" flexGrow={1} alignItems="flex-end">
        <Button
          data-testid="close-loading-complete-alert"
          display="flex"
          flexGrow={1}
          alignItems="flex-end"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {messages.loadingCompletePickup.buttonCloseLoadingComplete}
        </Button>
      </Box>
    </Dialog>
  );
}

LoadingCompletePickupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
