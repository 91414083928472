import { useEffect, useState } from 'react';

import { getAcceptedPickups } from 'operations/pickup/pickup-service';
import { errorTypes } from 'operations/assignment-list';

export default function useAssignmentWaypointsFromAllocation() {
  const [assignments, setAssignments] = useState(null);
  const [error, setError] = useState(null);

  const cleanError = () => {
    setError(null);
  };

  const hasError = !!error;

  const handlePickupAssignmentWaypoints = promise =>
    promise
      .then(assignmentsPickupsWaypoints => {
        setAssignments(assignmentsPickupsWaypoints?.assignmentsWaypoints);
      })
      .catch(err => {
        setError({
          errorType: errorTypes.genericError,
          errorMessage: err.message
        });
      });

  const getList = () => {
    return handlePickupAssignmentWaypoints(getAcceptedPickups());
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAcceptedPickups]);

  return {
    assignments,
    error,
    hasError,
    cleanError
  };
}
