import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton, Button, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { colors } from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { trackStart, eventNames } from 'operations/elapsed-time';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import { facadePhotoTypes, messages } from './constants';
import { getTitle } from './utils';
import facadePhotoImage from './assets/facadePhotoImage.png';
import mailboxImage from './assets/mailboxImage.png';

export default function SamplePhoto({
  nextStep,
  onGoFallback,
  onGoBack,
  type
}) {
  useEffect(() => {
    trackStart(eventNames.facadePhoto);
  }, []);

  const isMailbox = type === facadePhotoTypes.deliveryMailBox;
  const isPickupFacadePhoto = type === facadePhotoTypes.pickupFacadePhoto;
  const isFallbackDisabled = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.disableFacadePhotoFallback)
  );

  const sendToHelpPage = () => {
    window.open(messages.helpLink);
  };

  const hasDigitalSignature = !!(
    onGoFallback &&
    !isMailbox &&
    !isFallbackDisabled
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box pt={0.5} display="flex" flexDirection="column">
        <Grid container spacing={3} wrap="nowrap">
          <Grid item xs={10}>
            <Box py={2} px={0.5}>
              <IconButton onClick={onGoBack} data-testid="document-back-button">
                <ArrowBack style={{ color: colors.blue[500] }} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item>
            <Box py={2} px={0.5}>
              <IconButton onClick={sendToHelpPage} data-testid="help-button">
                <HelpOutlineOutlinedIcon style={{ color: colors.blue[500] }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box p={2.5} color={colors.smoke[900]}>
        <Box>
          <Typography variant="h5">{getTitle(type)}</Typography>
        </Box>

        {isMailbox && (
          <Box mt={1.5} data-testid="subtitle-mailbox">
            <Typography variant="body1">
              {messages.mailbox.subtitle}
              <strong>{messages.mailbox.continueSubtitleBold}</strong>
              {messages.mailbox.finishSubtitle}
            </Typography>
          </Box>
        )}

        {isPickupFacadePhoto && (
          <Box mt={1.5} data-testid="subtitle-pickup">
            <Typography variant="body1">{messages.pickup.subtitle}</Typography>
          </Box>
        )}
      </Box>

      <Box
        px={3.5}
        style={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          data-testid="img-photo-testid"
          src={isMailbox ? mailboxImage : facadePhotoImage}
          style={{
            width: 'auto',
            height: '100%'
          }}
          alt="Foto"
        />
      </Box>

      <Box px={3.5} py={2.5}>
        <Grid container spacing={1} wrap="wrap">
          <Grid item xs>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={nextStep}
            >
              {messages.takePhotoButton}
            </Button>
          </Grid>

          {hasDigitalSignature && (
            <Grid item xs>
              <Button
                color="primary"
                size="large"
                fullWidth
                onClick={onGoFallback}
              >
                {messages.cannotTakePhoto}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

SamplePhoto.propTypes = {
  nextStep: PropTypes.func,
  onGoFallback: PropTypes.func,
  onGoBack: PropTypes.func,
  type: PropTypes.oneOf(Object.values(facadePhotoTypes))
};

SamplePhoto.defaultProps = {
  nextStep: undefined,
  onGoFallback: undefined,
  onGoBack: undefined,
  type: facadePhotoTypes.deliveryFacadePhotoDelivery
};
