import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import SharedPropTypes from 'view/shared-prop-types';
import sendEventToAnalytics from 'operations/firebase';
import {
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import Header from 'view/molecules/header';
import { ReactComponent as MotocaIcon } from './icons/motoca.svg';

export const texts = {
  title: itensQuantity => (
    <>
      {itensQuantity > 1 ? (
        <Typography variant="h6">
          <b>{itensQuantity} itens para retirada</b>
        </Typography>
      ) : (
        <Typography variant="h6">
          <b>{itensQuantity} item para retirada</b>
        </Typography>
      )}
    </>
  ),
  subtitle: 'Veja abaixo um resumo com todos os itens que você identificou',
  summaryTitle: 'Resumo da retirada',
  identifiedLabel: 'Itens identificados',
  unidentifiedLabel: 'Itens não identificados',
  buttonConfirm: 'Ir para identificação'
};

export default function PickupSummary({
  pickup,
  collectedVolumesQuantity,
  uncollectedVolumesQuantity,
  onGoBack,
  onConfirm,
  userIdentification
}) {
  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  const enabledLMCsForListOfPlannedVolumesWithMultimodals = useFeatureSwitch(
    featureSwitches.enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  const isListOfPlannedVolumesEnabledForMultimodals = featureSwitchEnabledForDriverLMC(
    enabledLMCsForListOfPlannedVolumesWithMultimodals
  );

  const percentageToAddInVolumesLengthForMultimodalsFS =
    useFeatureSwitch(
      featureSwitches.percentageToAddInVolumesLengthForMultimodals
    ) || '0.20';
  const percentageToAddInVolumesLengthForMultimodals = Number(
    percentageToAddInVolumesLengthForMultimodalsFS
  );

  const isPickupWithMultiModals =
    parseInt(pickup?.assignments[0].scheduledVehicles, 10) > 1 &&
    isListOfPlannedVolumesEnabledForMultimodals;

  const getForeseenVolumesQuantity = () => {
    const pickupCargosQuantity = pickup?.assignments?.[0]?.cargos?.length;
    if (!isPickupWithMultiModals) return pickupCargosQuantity;

    const expectedNumberVolumes = Math.floor(
      pickupCargosQuantity /
        parseInt(pickup?.assignments[0].scheduledVehicles, 10)
    );
    return (
      expectedNumberVolumes +
      Math.floor(
        expectedNumberVolumes * percentageToAddInVolumesLengthForMultimodals
      )
    );
  };

  const pickupId = pickup?.pickupId;

  useEffect(() => {
    sendEventToAnalytics('pickup_summary_page', {
      pickup: pickupId,
      driverId: userIdentification
    });
  }, [pickupId, userIdentification]);

  return (
    <Box
      bgcolor={colors.neutrals.white.pure}
      color={colors.neutrals.typeface.primary}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Header backButton={{ action: () => onGoBack() }} />
      <Box px={4} pb={3}>
        <MotocaIcon style={{ marginBottom: pxToRem(24) }} />
        {texts.title(getForeseenVolumesQuantity())}
        <Box my={2}>
          <Typography variant="subtitle">{texts.subtitle}</Typography>
        </Box>
        <Divider />
      </Box>
      <Box px={4} pb={3}>
        <Typography
          variant="body"
          style={{ fontWeight: typography.fontWeightMedium }}
        >
          {texts.summaryTitle}
        </Typography>
        <Box
          color={colors.neutrals.typeface.secondary}
          display="flex"
          justifyContent="space-between"
          mt={0.85}
          mb={0.75}
        >
          <Typography variant="caption" style={{ fontWeight: 500 }}>
            {texts.identifiedLabel}
          </Typography>
          <Typography
            variant="caption"
            style={{ fontWeight: 500 }}
            data-testid="summary-collected-volumes-quantity"
          >
            {collectedVolumesQuantity}
          </Typography>
        </Box>
        {(!pickup?.assignments[0].scheduledVehicles ||
          parseInt(pickup?.assignments[0].scheduledVehicles, 10) === 1) && (
          <Box
            color={colors.neutrals.typeface.secondary}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="caption" style={{ fontWeight: 500 }}>
              {texts.unidentifiedLabel}
            </Typography>
            <Typography
              variant="caption"
              style={{ fontWeight: 500 }}
              data-testid="summary-uncollected-volumes-quantity"
            >
              {uncollectedVolumesQuantity}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        px={4}
        py={3}
        mt="auto"
        bgcolor={colors.neutrals.white.pure}
        position="sticky"
        bottom={0}
        borderTop={`2px solid ${colors.neutrals.shapes.lighter}`}
        display="flex"
        flexDirection="column"
      >
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          onClick={() => onConfirm()}
        >
          {texts.buttonConfirm}
        </Button>
      </Box>
    </Box>
  );
}

PickupSummary.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired,
  collectedVolumesQuantity: PropTypes.number.isRequired,
  uncollectedVolumesQuantity: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userIdentification: PropTypes.number.isRequired
};

PickupSummary.url = '/v2/resumo';
