import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Drawer,
  Button,
  IconButton,
  TextField,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as ScanIcon } from './icons/scan.svg';

export const messages = {
  title: (
    <Typography variant="h6">
      Informe o código da saca <b>para escolher sua rota</b>
    </Typography>
  ),
  addButton: 'Adicionar',
  labelTextBarcode: 'Digite o código da saca'
};

export default function InputBarcodeDrawer({
  isOpen,
  onClose,
  onBarcodeRead,
  loading
}) {
  const [packageBarcode, setPackageBarcode] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    const oldBarcode = packageBarcode;
    setPackageBarcode('');
    await onBarcodeRead(oldBarcode);
  };

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ square: false, component: Box, mb: -3 }}
    >
      <Box px={3} pt={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ScanIcon mb={1} />
          <IconButton data-testid="drawer-back-icon" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box py={2}>{messages.title}</Box>
        <Box py={2}>
          <form onSubmit={handleSubmit} data-testid="barcode-reader">
            <TextField
              label={messages.labelTextBarcode}
              fullWidth
              data-testid="barcode-reader-text-field"
              variant="outlined"
              size="large"
              type="text"
              disabled={loading}
              InputProps={{
                inputProps: {
                  'aria-label': 'barcode-reader'
                }
              }}
              onChange={event => {
                setPackageBarcode(event.target.value.toUpperCase());
              }}
              value={packageBarcode}
              required
            />
          </form>
        </Box>
        <Box pt={2} pb={5} align="center">
          {loading ? (
            <CircularProgress data-testid="circular-progress" />
          ) : (
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="contained"
              onClick={handleSubmit}
            >
              {messages.addButton}
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

InputBarcodeDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBarcodeRead: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
