import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Puller from 'view/molecules/puller/index';
import { buildLoggiAddressV2 } from 'view/utils';
import { ReactComponent as LoriDelivery } from './images/lori_delivery.svg';
import { ReactComponent as Warehouse } from './images/warehouse.svg';

export const messages = {
  title: distributionCenter => (
    <>
      <strong>Entregue hoje até {distributionCenter.closingTime}</strong> e
      continue recebendo novas ofertas no seu caminho.
    </>
  ),
  subtitle: 'ENTREGAR EM',
  button: 'Beleza!',
  agency: distributionCenter => `Base Loggi • ${distributionCenter.name}`,
  address: distributionCenter =>
    `${buildLoggiAddressV2(distributionCenter.address)}`
};

export default function AlertDelivery({ distributionCenter, isOpen, onClose }) {
  return (
    <SwipeableDrawer
      data-testid="alert-delivery"
      open={isOpen}
      anchor="bottom"
      onClose={onClose}
      PaperProps={{
        style: {
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          height: '32rem'
        }
      }}
    >
      <Puller />
      <Box display="flex" flexDirection="column" p={2} textAlign="left">
        <Box display="flex" flexDirection="column">
          <Box alignSelf="left">
            <LoriDelivery />
          </Box>
          <Typography variant="h6">
            <Box data-testid="alert-delivery-title">
              {messages.title(distributionCenter)}
            </Box>
          </Typography>
          <Box display="flex" flexDirection="column" mt={2}>
            <Box pl={1} borderLeft={3} borderColor="grey.100">
              <Warehouse />
              <Typography>
                <Box
                  fontSize={10}
                  fontWeight={700}
                  letterSpacing={1}
                  color="grey.600"
                >
                  {messages.subtitle}
                </Box>
                <Box fontSize={18} fontWeight={400}>
                  {messages.agency(distributionCenter)}
                </Box>
                <Box fontSize={16} fontWeight={400}>
                  {messages.address(distributionCenter)}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" pt={2} pb={2.5}>
          <Button
            data-testid="on-close-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
          >
            {messages.button}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}

AlertDelivery.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  distributionCenter: PropTypes.shape({
    closingTime: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string
  }).isRequired
};
