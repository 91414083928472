import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Drawer, Button, useTheme } from '@material-ui/core';
import sharedPropTypes from 'view/shared-prop-types';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'view';
import sendEventToAnalytics from 'operations/firebase';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import { useSnackbar } from 'notistack';
import { useRemoteConfig } from '@loggi/firebase-feature-switches';
import { remoteConfigFlags } from 'operations/feature-switches';
import { getPackageDeliveredStatusByPackageId } from 'operations/update-status';
import { ReactComponent as DeliveredPackageIcon } from './icons/delivered-package-reminder-icon.svg';

export const messages = {
  title: 'Esse pacote já foi marcado como entregue',
  description:
    'Se foi um erro e na verdade ele não foi entregue, informe um problema na entrega.',
  warning:
    'Pacotes não entregues ao destinatário devem ser devolvidos na agência indicada',
  backToRouteButton: 'Voltar para minha rota',
  reportDeliveryProblemButton: 'Informar problema na entrega',
  alertToast:
    'Limite para informar um problema na entrega nesse pacote atingido'
};

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function DeliveredPackageReminder({ open, onCloseClick, pkg }) {
  const history = useHistory();
  const { colors, typography } = useTheme();
  const { pxToRem } = typography;
  const { enqueueSnackbar } = useSnackbar();
  const changePackageDeliveredStatus = useRemoteConfig(
    remoteConfigFlags.changePackageDeliveredStatus.name,
    remoteConfigFlags.changePackageDeliveredStatus.defaultValue
  );

  /**
   * This function returns the package delivered status data
   * @returns {Promise<null | {statusUpdateCount: number, updated: string}>}
   */
  const packageDeliveredInfo = async () => {
    const pkgDeliveredInfoData = await getPackageDeliveredStatusByPackageId(
      pkg?.packageId
    );
    return pkgDeliveredInfoData || null;
  };

  const logAttemptLimitExpired = extraData => {
    sendEventToAnalytics(
      'cannot_change_package_delivered_status_by_attempt_limit',
      {
        packageId: pkg.packageId,
        ...extraData
      }
    );
  };

  const logExpirationTimeExpired = extraData => {
    sendEventToAnalytics(
      'cannot_change_package_delivered_status_by_expiration_time',
      {
        packageId: pkg.packageId,
        ...extraData
      }
    );
  };

  /**
   * This function checks if the package can change the delivered status
   * @returns {Promise<boolean>}
   */
  const canChangePackageDeliveredStatus = async () => {
    const pkgDeliveredInfoData = await packageDeliveredInfo();

    if (!pkgDeliveredInfoData) return true;

    const { statusUpdateCount, updated } = pkgDeliveredInfoData;

    const getExpirationTimeInMinutes =
      changePackageDeliveredStatus.expirationTimeInMinutes;
    const getAttemptsToChangeStatusRule =
      changePackageDeliveredStatus.limitAttemptsToChangeStatus;
    const currentDate = new Date();
    const diffMinutes = Math.floor(
      (currentDate - new Date(updated)) / (1000 * 60)
    );
    if (statusUpdateCount >= getAttemptsToChangeStatusRule) {
      logAttemptLimitExpired({ statusUpdatedCount: statusUpdateCount });
      return false;
    }
    if (diffMinutes >= getExpirationTimeInMinutes) {
      logExpirationTimeExpired({ expirationTime: diffMinutes });
      return false;
    }
    return true;
  };

  const reportDeliveryProblem = async () => {
    const canChangePackageStatus = await canChangePackageDeliveredStatus();
    if (!canChangePackageStatus) {
      showSnackbar({
        message: messages.alertToast,
        variant: 'alert',
        enqueueSnackbar
      });
    } else {
      sendEventToAnalytics('report_delivery_problem', {
        packageId: pkg.packageId
      });
      history.push({
        pathname: PAGES.DELIVERING_PROBLEMS,
        state: {
          pkg
        }
      });
    }
  };

  return (
    <Drawer
      data-testid="delivered-package-reminder"
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          height: '100%'
        }
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Box textAlign="center">
          <Box pt={16}>
            <DeliveredPackageIcon />
          </Box>
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginBottom: 12, marginTop: 12 }}
          >
            {messages.title}
          </Typography>
          <Typography variant="body1">{messages.description}</Typography>
        </Box>
        <>
          <Box p={3} borderRadius={8} bgcolor={colors.neutrals.shapes.lighter}>
            <Typography>{messages.warning}</Typography>
          </Box>
          <Box width="100%" display="flex" flexDirection="column">
            <Button
              data-testid="confirm-delivery-button"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={reportDeliveryProblem}
              style={{ marginBottom: pxToRem(8) }}
            >
              {messages.reportDeliveryProblemButton}
            </Button>

            <Button
              data-testid="return-to-route-button"
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={onCloseClick}
            >
              {messages.backToRouteButton}
            </Button>
          </Box>
        </>
      </Box>
    </Drawer>
  );
}

DeliveredPackageReminder.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  pkg: sharedPropTypes.package.isRequired
};
