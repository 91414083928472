import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  parseISODateToHours,
  getDiffInDaysBetweenDateAndNow
} from 'view/molecules/offer-card/util';
import { AcceptOfferButton } from 'view/molecules/offer-card/common';
import CONSTANTS from 'view/molecules/offer-card/constants';

const getOfferDay = startDate => {
  const days = getDiffInDaysBetweenDateAndNow(startDate);
  if (days === 0) {
    return 'Hoje, das';
  }
  return 'Amanhã, das';
};

export const messages = {
  offerInfo: offer => {
    return {
      title: (
        <Typography variant="body1">
          <b>{offer.regionName}</b>
        </Typography>
      ),
      timeWindow: `${getOfferDay(offer.startTimeCollect)} ${parseISODateToHours(
        offer.startTimeCollect
      )} às ${parseISODateToHours(offer.deadlineCollect)}`,
      distribution_center_name: `Agência ${offer.waypoints[0]?.neighborhood}`
    };
  }
};

const OfferCardCapacityReserve = ({ offer, onAccept }) => {
  return (
    <Box py={3} justifyContent="space-between">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">
            <Box color={colors.smoke[900]}>
              {CONSTANTS.CAPACITY_RESERVE.V3.HEADER}
            </Box>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <ArrowForwardIcon alt="details button" />
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        mt={1}
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}
      >
        <Typography variant="body1" style={{ fontWeight: '700' }}>
          {offer.regionName}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Box mt={1}>
            <Typography variant="body1">
              {messages.offerInfo(offer).timeWindow}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="body2">
              {messages.offerInfo(offer).distribution_center_name}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignContent="space-between">
          <Box display="flex" alignItems="flex-end">
            <AcceptOfferButton
              mx={0}
              size="medium"
              handleAccept={() => {
                onAccept(offer);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

OfferCardCapacityReserve.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    deadlineCollect: PropTypes.string,
    regionName: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onAccept: PropTypes.func.isRequired
};

export default OfferCardCapacityReserve;
