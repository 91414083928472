import Dexie from 'dexie';
import wrapOperationsInDexieErrorHandler from 'operations/handle-dexie-errors';

/** @type {Dexie} */
const packageInfoDeliveredStatus = new Dexie('packageDeliveredStatus', {
  chromeTransactionDurability: 'relaxed'
});

packageInfoDeliveredStatus.version(1).stores({
  packageDeliveredStatus: `
        packageId,
        statusUpdateCount,
        updated
      `
});

/** @returns {Promise<void>} */
const clearDB = async () => {
  await packageInfoDeliveredStatus.packageDeliveredStatus.clear();
};

/** @param {PackageDeliveredStatus} packageDeliveredStatus
 * @returns {Promise<number>} - primary key of the package delivered status */
const putPackageDeliveredStatus = async packageDeliveredStatus =>
  packageInfoDeliveredStatus.table('packageDeliveredStatus').put({
    packageId: packageDeliveredStatus.packageId,
    statusUpdateCount: packageDeliveredStatus.statusUpdateCount,
    updated: packageDeliveredStatus.updated
  });

/** @param {number} packageId
 * @returns {Promise<void>} */
const deletePackageDeliveredStatus = async packageId =>
  packageInfoDeliveredStatus.table('packageDeliveredStatus').delete(packageId);

/** @param {number} id - package id
 * @returns {Promise<PackageDeliveredStatus>} */
const getPackageDeliveredStatusByPackageId = async id => {
  const packageDeliveredStatus = await packageInfoDeliveredStatus
    .table('packageDeliveredStatus')
    .where({ packageId: id })
    .first();

  if (!packageDeliveredStatus) return undefined;

  const { packageId, statusUpdateCount, updated } = packageDeliveredStatus;
  return { packageId, statusUpdateCount, updated };
};

export default wrapOperationsInDexieErrorHandler({
  dbName: 'packageInfoDeliveredStatus',
  operations: {
    clearDB,
    putPackageDeliveredStatus,
    getPackageDeliveredStatusByPackageId,
    deletePackageDeliveredStatus
  }
});
