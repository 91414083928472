import React, { useState } from 'react';
import { colors } from '@loggi/mar';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Button, Typography, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  DestinationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/destination-drawer';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import SharedPropTypes from 'view/shared-prop-types';
import { buildLoggiAddressV2, splitLoggiAddress } from 'view/utils';
import messages from './messages';
import DeliveryToDcRow from './components/delivery-to-dc-row';

export default function AssignmentWaypointDetails() {
  const history = useHistory();

  const [destinationDrawerState, setDestinationDrawerState] = useState(
    DRAWER_STATE.CLOSED
  );

  const [notificationMessage, setNotificationMessage] = useState('');

  const { assignmentWaypoint } = history.location.state;

  const {
    destination,
    deadlineTime,
    assignments,
    waypointId
  } = assignmentWaypoint;

  const firstAssignment = assignments[0];

  const address = buildLoggiAddressV2(assignmentWaypoint.destination);
  const { addressTitle, addressBody } = splitLoggiAddress(address);

  const renderRows = () =>
    assignments.map(assignment => {
      if (
        assignment.flow ===
        SharedPropTypes.assignmentFlowEnum.assignmentFlowDeliveryToDc
      ) {
        return (
          <DeliveryToDcRow
            key={JSON.stringify(assignment)}
            assignment={assignment}
            waypointId={waypointId}
          />
        );
      }
      return null;
    });

  return (
    <>
      <Grid
        container
        direction="column"
        style={{ backgroundImage: colors.gradients.sanches }}
        minheight="100%"
      >
        <Box px={3.5} pt={5} pb={3.5}>
          <Grid container justify="space-between" alignItems="center">
            <IconButton onClick={() => history.goBack()} data-testid="go-back">
              <ArrowBack style={{ color: colors.root[0] }} />
            </IconButton>

            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setDestinationDrawerState(DRAWER_STATE.OPEN);
              }}
            >
              {messages.deliveryToAgency.navigationButton}
            </Button>
          </Grid>
        </Box>
        <Box
          px={3.5}
          py={5}
          color={colors.root[0]}
          display="flex"
          flexGrow={1}
          alignItems="flex-end"
        >
          <Box>
            <Box pb={1.5} color={colors.blue[50]}>
              <Typography variant="body2">
                {messages.finishUntil(firstAssignment.flow, deadlineTime)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4">{addressTitle}</Typography>
            </Box>

            <Box mt={1}>
              <Box mb={1}>
                <Typography variant="body2">
                  <Box component="span">{addressBody}</Box>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          pt={5}
          pb={2}
          bgcolor={colors.root[0]}
          style={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px'
          }}
        >
          {renderRows()}
        </Box>
      </Grid>

      <DestinationDrawer
        drawerState={destinationDrawerState}
        setDrawerState={setDestinationDrawerState}
        notificationFunction={setNotificationMessage}
        destination={destination}
        addressType={SharedPropTypes.addressTypesEnum.WALLY_V2}
      />

      <Alert
        open={!!notificationMessage}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
        startAdornment={ALERT_TYPES.SUCCESS.startAdornment}
        color={ALERT_TYPES.SUCCESS.color}
      />
    </>
  );
}
