import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@material-ui/core';
import getAppVersion from '@loggi/drivers-app-version';
import {
  NotificationDrawer,
  STATE_ENUM
} from 'view/molecules/notification-drawer';

export default function ConfirmDismissDrawer({
  icon,
  data,
  isOpen,
  alertData,
  handleConfirmAction,
  handleDismissAction,
  isError
}) {
  const { title, description, buttonConfirm, buttonDismiss } = data;
  const hasExtraButton = !!buttonDismiss;

  const ButtonConfirm = () => (
    <Box>
      <Box textAlign="center">
        {isError && (
          <Typography variant="caption">{getAppVersion()}</Typography>
        )}
      </Box>

      <Box py={2.5}>
        <Button
          variant="contained"
          data-testid="extra-button"
          color="primary"
          size="large"
          fullWidth
          onClick={handleConfirmAction}
        >
          {buttonConfirm}
        </Button>
      </Box>
    </Box>
  );

  const button = hasExtraButton ? buttonDismiss : buttonConfirm;
  const setDrawerState = hasExtraButton
    ? handleDismissAction
    : handleConfirmAction;
  const buttonVariant = hasExtraButton ? 'outlined' : 'contained';
  const extraButton = hasExtraButton ? <ButtonConfirm /> : undefined;

  return (
    isOpen && (
      <NotificationDrawer
        data={{
          icon,
          title,
          button,
          description,
          buttonVariant
        }}
        alertData={alertData}
        drawerState={STATE_ENUM.CUSTOM}
        setDrawerState={setDrawerState}
        extraButton={extraButton}
      />
    )
  );
}

ConfirmDismissDrawer.propTypes = {
  icon: PropTypes.element.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonConfirm: PropTypes.string.isRequired,
    buttonDismiss: PropTypes.string
  }).isRequired,
  alertData: PropTypes.shape({
    message: PropTypes.string,
    icon: PropTypes.node
  }),
  isOpen: PropTypes.bool.isRequired,
  handleConfirmAction: PropTypes.func.isRequired,
  handleDismissAction: PropTypes.func,
  isError: PropTypes.bool
};

ConfirmDismissDrawer.defaultProps = {
  handleDismissAction: undefined,
  alertData: undefined,
  isError: true
};
