import React from 'react';
import { Typography } from '@material-ui/core';
import { NOTIFICATIONS_ENUM, recipientPhoneTexts } from './constants';

const notificationData = {
  [NOTIFICATIONS_ENUM.WHATSAPP_INVITATION_SENT]: data => ({
    title: '',
    description: '',
    descriptionHtmlElement: (
      <>
        <Typography variant="body2" align="center">
          <strong>{data.recipientName}</strong>{' '}
          {recipientPhoneTexts.whatsappInvitationSent}
        </Typography>
      </>
    ),
    button: 'Ok, vou esperar',
    buttonVariant: 'contained',
    icon: null
  }),
  [NOTIFICATIONS_ENUM.WHATSAPP_CONVERSATION_LIMIT_REACHED]: data => ({
    title: '',
    description: '',
    descriptionHtmlElement: (
      <>
        <Typography
          variant="body2"
          align="center"
          style={{ marginBottom: '20px' }}
        >
          {`${recipientPhoneTexts.conversationLimit} `}
          <strong>{`${data.activeConversation}.`}</strong>
        </Typography>
        <Typography variant="body2" align="center">
          {`${recipientPhoneTexts.closeConversation1} `}
          <strong>{data.recipientName}</strong>
          {` ${recipientPhoneTexts.closeConversation2}`}
        </Typography>
      </>
    ),
    button: 'Continuar com a ativa',
    buttonVariant: 'outlined',
    icon: null
  })
};

const getNotificationData = (notificationName, data) => {
  const notificationDataBuilder = notificationData[notificationName];

  if (notificationDataBuilder) {
    return notificationDataBuilder(data);
  }

  return undefined;
};

export default getNotificationData;
