import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button, IconButton, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function InfoDrawer({
  open,
  title,
  subtitle,
  image,
  mainButton,
  leftButton,
  rightButton,
  onCloseClick
}) {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <Drawer
      data-testid="info-drawer"
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
      p={1.5}
    >
      <Box pl={2.5} pr={1} pt={3} display="flex" justifyContent="flex-end">
        <IconButton
          onClick={onCloseClick}
          data-testid="info-drawer-back-button"
        >
          <CloseIcon style={{ color: colors.neutrals.typeface.primary }} />
        </IconButton>
      </Box>
      <Box p={2.5} pt={0}>
        {image}
      </Box>
      <Box px={2.5} color={colors.neutrals.typeface.primary}>
        <Typography
          variant="body1"
          style={{ marginBottom: 8, fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
      <Box display="flex" p={2.5} pb={1.5}>
        {mainButton.show && (
          <Button
            fullWidth
            size="large"
            data-testid="info-drawer-main-button"
            variant="contained"
            color="primary"
            onClick={mainButton.action}
          >
            {mainButton.text}
          </Button>
        )}
        {leftButton.show && (
          <Button
            data-testid="info-drawer-left-button"
            color="primary"
            onClick={leftButton.action}
          >
            {leftButton.text}
          </Button>
        )}
        {rightButton.show && (
          <Button
            data-testid="info-drawer-right-button"
            variant="outlined"
            color="primary"
            style={{ marginLeft: 'auto', flexGrow: 2 }}
            onClick={rightButton.action}
          >
            {rightButton.text}
          </Button>
        )}
      </Box>
    </Drawer>
  );
}

InfoDrawer.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  mainButton: PropTypes.shape({
    show: PropTypes.bool,
    text: PropTypes.string,
    action: PropTypes.func
  }),
  leftButton: PropTypes.shape({
    show: PropTypes.bool,
    text: PropTypes.string,
    action: PropTypes.func
  }),
  rightButton: PropTypes.shape({
    show: PropTypes.bool,
    text: PropTypes.string,
    action: PropTypes.func
  })
};

InfoDrawer.defaultProps = {
  image: null,
  subtitle: '',
  mainButton: {},
  leftButton: {},
  rightButton: {}
};
