import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ReactComponent as LoggiIcon } from './icons/loggi.svg';

export const texts = {
  button: 'Enviar pesquisa'
};

function SurveyFooter({ onConfirm, disableButton }) {
  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  return (
    <Box
      py={2}
      px={3}
      mt="auto"
      bgcolor={colors.neutrals.white.pure}
      position="sticky"
      bottom={0}
      borderTop={`2px solid ${colors.neutrals.shapes.lighter}`}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        color={colors.neutrals.typeface.secondary}
        mt={2}
        mb={1.5}
      >
        <>
          <LockOutlinedIcon style={{ width: pxToRem(20) }} />
          <Typography variant="caption" style={{ marginLeft: pxToRem(10) }}>
            <b>Ambiente seguro da Loggi.</b> Seus dados estão protegidos aqui.
          </Typography>
        </>
        <LoggiIcon />
      </Box>

      <Button
        disabled={disableButton}
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={onConfirm}
      >
        {texts.button}
      </Button>
    </Box>
  );
}

SurveyFooter.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired
};

export default SurveyFooter;
