const localStorageKeys = {
  optimizedPackagesKey: 'optimized-packages',
  lastOptimizedKey: 'last-optimization',
  optimizationDrawerKey: 'has-showed-optimization-drawer'
};

export function cleanOptimizedPackagesBasedOnPackages(packages) {
  if (!packages || packages?.length === 0)
    localStorage.removeItem(localStorageKeys.optimizedPackagesKey);
}

export function cleanLastOptimization() {
  localStorage.removeItem(localStorageKeys.lastOptimizedKey);
}

export function saveUpdatedPackagesNotInList(updatedPackagesIds) {
  localStorage.setItem(
    localStorageKeys.optimizedPackagesKey,
    JSON.stringify(updatedPackagesIds)
  );
}

export function setLastOptimization(date) {
  localStorage.setItem(localStorageKeys.lastOptimizedKey, JSON.stringify(date));
}

export function getLastOptimization() {
  return (
    JSON.parse(localStorage.getItem(localStorageKeys.lastOptimizedKey)) || null
  );
}

export function setOptimizedPackages(optimizedPackages) {
  const packagesIds = optimizedPackages.map(pkg => pkg.packageId);
  saveUpdatedPackagesNotInList(packagesIds);
  setLastOptimization(new Date());
}

export function getOptimizedPackages() {
  return (
    JSON.parse(localStorage.getItem(localStorageKeys.optimizedPackagesKey)) ||
    []
  );
}

export async function mergeAssignmentListWithOptimizedPackages(packages) {
  if (!packages) {
    return null;
  }

  const optimizedPackages = getOptimizedPackages();

  if (!optimizedPackages || Object.keys(optimizedPackages).length === 0)
    return packages;

  const mapPackages = [];
  packages.forEach(pkg => {
    mapPackages[pkg.packageId] = pkg;
  });

  const packagesNotInList = optimizedPackages.filter(
    pkgId => !mapPackages[pkgId]
  );
  const packagesUpdated = optimizedPackages.filter(
    pkgId => !packagesNotInList.includes(pkgId)
  );

  const orderedList = [];
  packagesUpdated.forEach((pkgId, index) => {
    orderedList[index] = mapPackages[pkgId];
  });

  if (optimizedPackages.length > packagesUpdated.length)
    saveUpdatedPackagesNotInList(packagesUpdated);

  const packagesNotOptimized = packages.filter(
    pkg => !packagesUpdated.includes(pkg.packageId)
  );

  return [...orderedList, ...packagesNotOptimized];
}
