import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  Divider,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Header from 'view/molecules/header';
import { buildLoggiAddressV2 } from 'view/utils';
import PackageCounter from './package-counter';

export const texts = {
  title: () => (
    <Typography variant="h6">
      <b>Digite o código</b> para identificar o item
    </Typography>
  ),
  subtitle: 'Os números deverão estar próximos ao código de barras ou QR'
};

export default function BarcodeInput({
  onGoBackClick,
  onHelpClick,
  fetchPackageInfo,
  loading,
  packageInfo,
  totalCounter,
  beepedPackages
}) {
  const theme = useTheme();
  const { colors } = theme;

  const barcodeRef = useRef(null);
  const [barcodeCleared, setBarcodeCleared] = useState(false);

  /**
   * If a package contains an error we add the barcode value back to the input.
   */
  useEffect(() => {
    if (packageInfo?.error) {
      [barcodeRef.current.value] = packageInfo.barcodes;
    }
  }, [packageInfo]);

  /**
   * Set the barcode input value to "" and focus.
   */
  const resetBarcodeRef = () => {
    if (barcodeRef) {
      barcodeRef.current.value = '';
      barcodeRef.current.focus();
    }
  };

  /**
   * Handle submit or blur event to get the package info data
   * @param {} event
   */
  const getPackageInfoData = event => {
    event.preventDefault();
    if (barcodeRef?.current?.value) {
      fetchPackageInfo({
        barcode: barcodeRef.current.value,
        format: 'TYPED',
        isFromBarcodeInput: true
      });
      setBarcodeCleared(false);
      resetBarcodeRef();
    }
  };

  return (
    <Box
      bgcolor={colors.neutrals.white.pure}
      color={colors.neutrals.typeface.primary}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Header
        backButton={{ action: onGoBackClick }}
        helpButton={{ action: onHelpClick }}
      />
      <Box px={4} pb={5} display="flex" flexDirection="column">
        {texts.title()}
        <Typography variant="body2" style={{ marginTop: 16 }}>
          {texts.subtitle}
        </Typography>
        <Box pt={4}>
          <form onSubmit={getPackageInfoData}>
            <FormControl fullWidth>
              <OutlinedInput
                inputRef={barcodeRef}
                autoFocus
                error={
                  !barcodeCleared &&
                  packageInfo?.error &&
                  (packageInfo?.cancelledPackage ||
                    packageInfo?.error !== 'Item não previsto')
                }
                placeholder="Digite aqui"
                color={colors.neutrals.typeface.primary}
                data-testid="barcode-input-field"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: colors.neutrals.typeface.primary }}
                      onClick={() => {
                        setBarcodeCleared(true);
                        resetBarcodeRef();
                      }}
                      edge="end"
                      data-testid="barcode-input-clear-barcode-button"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </Box>
      </Box>
      <Divider />
      <Box p={4} display="flex" flexDirection="column" flexGrow={1}>
        {loading && (
          <>
            <Skeleton
              variant="text"
              width="30%"
              data-testid="barcode-info-skeleton-package-info"
            />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="50%" />
          </>
        )}
        {packageInfo?.barcodes && !loading && (
          <>
            <Box py={1.5}>
              <PackageCounter
                totalCounter={totalCounter}
                currentCounter={beepedPackages.length}
              />
            </Box>
            {!packageInfo?.cancelledPackage && (
              <>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {packageInfo.barcodes[0]}
                </Typography>
                {packageInfo?.error ? (
                  <>
                    <Box
                      width="fit-content"
                      bgcolor={
                        packageInfo.error === 'Item não previsto'
                          ? colors.status.attention.light
                          : colors.status.negative.light
                      }
                    >
                      <Typography
                        variant="caption"
                        style={{
                          fontWeight: 600,
                          color: colors.neutrals.typeface.primary
                        }}
                      >
                        {packageInfo.error}
                      </Typography>
                    </Box>
                    {packageInfo.errorSubtitle ? (
                      <Typography
                        variant="body2"
                        style={{
                          color: colors.neutrals.typeface.secondary
                        }}
                      >
                        {packageInfo.errorSubtitle}
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body1">
                          {packageInfo.recipientName}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            color: colors.neutrals.typeface.secondary
                          }}
                        >
                          {buildLoggiAddressV2(packageInfo.recipientAddress)}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="body1">
                      {packageInfo.recipientName}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: colors.neutrals.typeface.secondary
                      }}
                    >
                      {buildLoggiAddressV2(packageInfo.recipientAddress)}
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

BarcodeInput.propTypes = {
  onGoBackClick: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  fetchPackageInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  packageInfo: PropTypes.shape({
    barcodes: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
    cancelledPackage: PropTypes.bool,
    errorSubtitle: PropTypes.string,
    recipientName: PropTypes.string,
    recipientAddress: PropTypes.string
  }),
  totalCounter: PropTypes.number,
  beepedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string),
      recipientName: PropTypes.string,
      recipientAddress: PropTypes.string
    })
  )
};

BarcodeInput.defaultProps = {
  loading: false,
  packageInfo: null,
  totalCounter: 0,
  beepedPackages: []
};
