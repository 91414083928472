import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Chip
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CPF from 'cpf';
import Notes from 'view/molecules/notes/notes';
import RecipientRelationship from 'view/templates/protocol/recipient-relationship';
import { colors, pxToRem } from '@loggi/mar';
import { getClientDocument } from 'operations/client-document';
import Alert from '@material-ui/lab/Alert';
import { deliveryFlowEnum } from 'view/pages/assignment-list/constants';
import { pickupFlowEnum } from 'view/pages/pickup/constants';
import labelText from 'view/templates/recipient/constants';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { featureSwitches } from 'operations/feature-switches';

export const errorFillingData = {
  nameErrorMessageWhatUse: 'Use apenas letras e espaços',
  nameErrorMessageInvalid: 'Preencha com o nome completo',
  documentErrorMessageInvalid: 'Preencha com um documento válido'
};

export const dataTestId = {
  idNameField: 'name-field',
  idDocumentField: 'document-field',
  idDocumentTypeField: 'document-type-field'
};

export const documentTypeEnum = {
  cpf: 'cpf',
  rg: 'rg'
};

export function Document({
  onGoBack,
  onConfirm,
  isDelivery,
  flow,
  deliveryOption,
  hasRecipientRelationship,
  shouldCheckDocument
}) {
  const showDocumentField =
    flow !== deliveryFlowEnum.deliverToDC &&
    flow !== pickupFlowEnum.useXDAppAgencyTransfer;

  const textTitle = isDelivery
    ? 'Quem recebeu?'
    : 'Confirme os dados de quem te atendeu';

  const TEXTS = {
    getTitle: () =>
      deliveryOption != null ? deliveryOption.detailsScreenTitle : textTitle,
    getButtonConfirm: () =>
      isDelivery ? 'Confirmar entrega' : 'Confirmar coleta',
    warningCollection:
      'Preencha os dados na hora e local que estiver coletando.'
  };

  /**
   * The cached document is only valid for pickup flow
   * Therefore, if the flow is delivery, we don't want to use the cached document
   */
  const cacheDocument = isDelivery ? {} : getClientDocument();

  const [name, setName] = useState(cacheDocument?.name || '');
  const [documentType, setDocumentType] = useState(
    isDelivery ? documentTypeEnum.cpf : ''
  );
  const [document, setDocuments] = useState(cacheDocument?.document || '');
  const [notes, setNotes] = useState('');
  const [options, setOptions] = useState('');

  const [nameError, setErrorName] = useState([
    { error: false, errorMessage: '', shadowBox: 13 }
  ]);
  const [documentError, setErrorDocument] = useState([
    { error: false, errorMessage: '', shadowBox: 13 }
  ]);

  const sendReceiverDocumentType = useFeatureSwitch(
    featureSwitches.sendReceiverDocumentType
  );

  const submitButtonIsDisabled =
    !name ||
    (!document && showDocumentField) ||
    (!options && hasRecipientRelationship);
  const canShowWarning = !!(deliveryOption === null && isDelivery === false);

  const checkDocument = (doc, docType) => {
    const verifyDocument = /(^[A-Za-z]{0,3}[0-9]{6,}$)|(^[0-9]{6,}[A-Za-z]{0,3}$)/;

    if (docType === documentTypeEnum.cpf && CPF.isValid(doc)) {
      setDocuments(CPF.format(doc));
      setErrorDocument({
        error: false,
        errorMessage: '',
        shadowBox: 13
      });

      return true;
    }
    if (docType !== documentTypeEnum.cpf && verifyDocument.test(document)) {
      setDocuments(doc);
      setErrorDocument({
        error: false,
        errorMessage: '',
        shadowBox: 13
      });
      return true;
    }

    setDocuments(doc);
    setErrorDocument({
      error: true,
      errorMessage: errorFillingData.documentErrorMessageInvalid,
      shadowBox: 0
    });
    return false;
  };

  const verifyDocuments = () => {
    const verifyName = /^\s*(?:[^0-9~^!@#$%*()/\\.,_+-={}[°|'><?":& ]\s*){2,}$/;
    const verifyNameLength = /^\s*(?:[^\s]\s*){2,}$/;
    const verifyDocumenLength = /.{6,}/;

    let validDocs = true;

    if (!verifyNameLength.test(name)) {
      setErrorName({
        error: true,
        errorMessage: errorFillingData.nameErrorMessageInvalid,
        shadowBox: 0
      });
      validDocs = false;
    } else if (!verifyName.test(name)) {
      setErrorName({
        error: true,
        errorMessage: errorFillingData.nameErrorMessageWhatUse,
        shadowBox: 0
      });
      validDocs = false;
    }
    if (!verifyDocumenLength.test(document)) {
      setErrorDocument({
        error: true,
        errorMessage: errorFillingData.documentErrorMessageInvalid,
        shadowBox: 0
      });
      validDocs = false;
    } else if (!checkDocument(document, documentType)) {
      validDocs = false;
    }
    if (validDocs) {
      onConfirm({
        name,
        document,
        documentType: sendReceiverDocumentType ? documentType : '',
        notes,
        recipientRelationship: options
      });
    }
  };

  return (
    <>
      <Box
        pt={0.5}
        display="flex"
        flexDirection="column"
        height="100%"
        bgcolor="#FFF"
      >
        <Box py={2} px={0.5}>
          <IconButton onClick={onGoBack} data-testid="document-back-button">
            <ArrowBack style={{ color: colors.bassis[700] }} />
          </IconButton>
        </Box>

        <Box p={2.5} color={colors.smoke[900]}>
          <Typography variant="h5">{TEXTS.getTitle()}</Typography>
        </Box>

        <Box px={2.5}>
          <Box borderRadius={4} boxShadow={nameError.shadowBox}>
            <TextField
              error={nameError.error}
              helperText={nameError.errorMessage}
              variant="outlined"
              data-testid={dataTestId.idNameField}
              label={labelText.LABEL_NAME}
              type="text"
              fullWidth
              inputProps={{ maxLength: 32 }}
              value={name}
              onChange={event => {
                setName(event.target.value);
                setErrorName({
                  error: false,
                  errorMessage: '',
                  shadowBox: 13
                });
              }}
            />
          </Box>
          {showDocumentField && (
            <Box pt={2}>
              {isDelivery && (
                <>
                  <Typography>Documento do destinatário</Typography>
                  <Box my={2}>
                    {Object.keys(documentTypeEnum).map(docKey => (
                      <Chip
                        key={docKey}
                        data-testid={`document-type-${docKey}`}
                        variant="outlined"
                        label={docKey.toUpperCase()}
                        clickable
                        style={{
                          paddingLeft: pxToRem(16),
                          paddingRight: pxToRem(16),
                          marginRight: pxToRem(16),
                          backgroundColor:
                            documentType === documentTypeEnum[docKey]
                              ? colors.blue[50]
                              : '',
                          color:
                            documentType === documentTypeEnum[docKey]
                              ? colors.bassis[700]
                              : '',
                          border: `1px solid ${
                            documentType === documentTypeEnum[docKey]
                              ? colors.bassis[700]
                              : ''
                          }`,
                          fontSize: pxToRem(16)
                        }}
                        onClick={() => {
                          setDocumentType(documentTypeEnum[docKey]);
                          checkDocument(document, documentTypeEnum[docKey]);
                        }}
                      />
                    ))}
                  </Box>
                </>
              )}
              <TextField
                error={documentError.error}
                helperText={documentError.errorMessage}
                variant="outlined"
                data-testid={dataTestId.idDocumentField}
                label={
                  isDelivery
                    ? `Número do ${documentType.toUpperCase()}`
                    : labelText.LABEL_DOCUMENT
                }
                fullWidth
                inputProps={{ maxLength: 14 }}
                value={document}
                onChange={event => {
                  const doc = event.target.value;
                  checkDocument(doc, documentType);
                }}
              />
            </Box>
          )}
          {isDelivery && hasRecipientRelationship && (
            <RecipientRelationship
              value={options}
              onChange={event => setOptions(event.target.value)}
            />
          )}
          {isDelivery && (
            <Notes
              isSuccess
              value={notes}
              onChange={event => setNotes(event.target.value)}
              deliveryOption={deliveryOption}
            />
          )}
        </Box>

        {canShowWarning && (
          <Box py={2.5} px={2.5}>
            <Box
              bgcolor={colors.smoke[50]}
              my={2.5}
              display="flex"
              borderRadius={8}
            >
              <Alert severity="error" color="info">
                {TEXTS.warningCollection}
              </Alert>
            </Box>
          </Box>
        )}

        <Box p={2.5} display="flex" flexGrow={1} alignItems="flex-end" mb={2}>
          <Box width="100%">
            <Button
              display="flex"
              flexGrow={1}
              alignItems="flex-end"
              disabled={submitButtonIsDisabled}
              variant="contained"
              style={{
                background: submitButtonIsDisabled
                  ? colors.smoke[100]
                  : colors.gradients.bassis,
                color: submitButtonIsDisabled
                  ? colors.smoke[500]
                  : colors.root[0]
              }}
              size="large"
              fullWidth
              onClick={() => {
                if (isDelivery && shouldCheckDocument) {
                  verifyDocuments();
                } else {
                  onConfirm({
                    name,
                    document,
                    documentType: sendReceiverDocumentType ? documentType : '',
                    notes,
                    recipientRelationship: options
                  });
                }
              }}
            >
              {TEXTS.getButtonConfirm()}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

Document.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDelivery: PropTypes.bool.isRequired,
  flow: PropTypes.string,
  deliveryOption: PropTypes.shape({
    detailsScreenTitle: PropTypes.string,
    id: PropTypes.number
  }),
  hasRecipientRelationship: PropTypes.bool,
  shouldCheckDocument: PropTypes.bool
};

Document.defaultProps = {
  flow: null,
  deliveryOption: null,
  hasRecipientRelationship: false,
  shouldCheckDocument: false
};

export default {
  Document,
  errorFillingData,
  dataTestId
};
