import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'view';
import { colors } from '@loggi/mar';
import { QuestionsAboutPackageReturn } from 'view/pages/package-return';
import { ReactComponent as EmergencyButton } from './icons/emergency-button-assignment-list.svg';
import { ReactComponent as HelpIcon } from './icons/help-button-assignment-list.svg';

import assignmentListHeaderStyles from './styles';

export default function AssignmentListHeader({ displayHeaderButtons }) {
  const history = useHistory();

  const onFaqButtonClick = () => {
    history.push({
      pathname: `${PAGES.PACKAGE_RETURN}${QuestionsAboutPackageReturn.url}`,
      state: {
        displayButtonToCloseReminder: true
      }
    });
  };

  const onEmergencyButtonClick = () => history.push(PAGES.EMERGENCY);
  const onGoBackClick = () => {
    if (process.env.REACT_APP_DRIVERS_URL) {
      window.location.href = process.env.REACT_APP_DRIVERS_URL;
      return;
    }

    history.goBack();
  };

  return (
    <Box py={2.5} px={0.5} display="flex" bgcolor={colors.root[0]}>
      <Grid container justify="space-between" alignItems="center">
        <Box>
          <IconButton
            color="inherit"
            onClick={onGoBackClick}
            data-testid="close-flecha-button"
          >
            <ArrowBack />
          </IconButton>
        </Box>

        {displayHeaderButtons && (
          <Box display="flex" alignItems="center">
            <Box mr={-2}>
              <IconButton onClick={onFaqButtonClick} data-testid="faq-button">
                <HelpIcon style={assignmentListHeaderStyles.faqButton} />
              </IconButton>
            </Box>

            <Box px={2.5}>
              <IconButton
                style={assignmentListHeaderStyles.emergencyButton}
                onClick={onEmergencyButtonClick}
                data-testid="emergency-button"
              >
                <EmergencyButton />
              </IconButton>
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

AssignmentListHeader.propTypes = {
  displayHeaderButtons: PropTypes.bool
};

AssignmentListHeader.defaultProps = {
  displayHeaderButtons: true
};
