import { mutationStates } from 'models/package-mutation';

/**
 * Sort packages by assignment index and assignmentDisplayId
 * Sort by index -> sort by package position in the route
 * Sort by displayId -> sort by package position in waypoint in route
 * Example:
 * const packages = [
 *                    {assignmentIndex: 2, assignmentDisplayId: "3"},
 *                    {assignmentIndex: 0, assignmentDisplayId: 1},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2A"},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2BA"},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2B"},
 *                    {assignmentIndex: 3, assignmentDisplayId: "4AA" }
 *                    {assignmentIndex: 3, assignmentDisplayId: "4B"},
 *                    {assignmentIndex: 3, assignmentDisplayId: "4A"},
 *                   ]
 * After sort:
 * const packages = [
 *                    {assignmentIndex: 0, assignmentDisplayId: 1},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2A"},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2B"},
 *                    {assignmentIndex: 1, assignmentDisplayId: "2BA"},
 *                    {assignmentIndex: 2, assignmentDisplayId: "3"},
 *                    {assignmentIndex: 3, assignmentDisplayId: "4A"},
 *                    {assignmentIndex: 3, assignmentDisplayId: "4B"},
 *                    {assignmentIndex: 3, assignmentDisplayId: "4AA" }
 *                   ]
 * @param {*} packages
 * @returns
 */
const sortPackagesByIndexAndDisplayId = packages => {
  return packages.sort((previousPackage, currentPackage) => {
    return (
      Number(previousPackage.assignmentIndex) -
        Number(currentPackage.assignmentIndex) ||
      previousPackage.assignmentDisplayId?.length -
        currentPackage.assignmentDisplayId?.length ||
      previousPackage.assignmentDisplayId?.localeCompare(
        currentPackage?.assignmentDisplayId
      )
    );
  });
};

const isPackageMutationSynced = mutationState => {
  return !mutationState || mutationState === mutationStates.SYNCED;
};

export { sortPackagesByIndexAndDisplayId, isPackageMutationSynced };
