import { getCurrentUserInfo } from '@loggi/driver-authentication';
import api from 'infra/api';

export default async function identifyBarcode(barcode, pickupId) {
  const driver = getCurrentUserInfo();

  const companyRelation = {
    DRIVER_COMPANY_RELATION_CONTRACTOR: 1,
    DRIVER_COMPANY_RELATION_COLLABORATOR: 2,
    DRIVER_COMPANY_RELATION_STAFF: 3
  };

  const url = `/offer/drivethru`;

  const body = {
    external_key: barcode,
    lmc_identification: driver.lmcIdentification,
    driver_id: driver.pk,
    relation: companyRelation[driver.companyRelation],
    pickup_id: pickupId
  };

  return api()
    .url(url)
    .post(body)
    .json();
}
