import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import usePersistedState, {
  cleanPersistedState,
  persistedStateKeys
} from 'hooks/use-persisted-state';
import {
  clearClientDocument,
  setClientDocument
} from 'operations/client-document';
import getPosition from 'operations/geolocation';
import { shipmentPickup } from 'operations/pickup';
import PropTypes from 'prop-types';
import { PAGES } from 'view';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import { Document } from 'view/pages/protocol/document';
import { DRIVER_APP_DEEP_LINK, PICKUP_TYPES } from './constants';
import FakeLoading from './fake-loading';
import PickupSurveyDrawer from './pickup-survey-drawer';
import pickupSurveyIsEnabled from './pickup-switches';

const statesEnum = {
  idle: 'idle',
  loading: 'loading',
  error: 'error',
  success: 'success'
};

function useShipmentPickup(pickupType, persistedStateKey) {
  const [state, setState] = useState(statesEnum.idle);
  const [error, setError] = useState(null);
  const [scanningStartingTime] = usePersistedState(
    `${persistedStateKeys.packageScanningStart}/${persistedStateKey}`,
    ''
  );

  function callShipmentPickup(packages, pickupProtocol) {
    setState(statesEnum.loading);
    return getPosition()
      .then(
        ({ latitude, longitude }) => {
          return shipmentPickup({
            packages,
            pickupProtocol,
            scanningStartingTime,
            pickupType,
            latitude,
            longitude
          }).json(() => {
            cleanPersistedState(persistedStateKeys.pickupScannedPackages);
            cleanPersistedState(persistedStateKeys.packageScanningStart);
            setState(statesEnum.success);
          });
        },
        () => {
          setState(statesEnum.error);
          setError(
            'Certifique-se de que você deu permissão para compartilhar sua localização com a Loggi e tente novamente'
          );
        }
      )
      .catch(err => {
        setState(statesEnum.error);
        if (err?.json?.errors) {
          setError(err.json.errors[0].message);
        } else {
          setError('Você pode tentar novamente daqui a pouco');
        }
      });
  }

  return [state, error, callShipmentPickup];
}

export default function PickupDocument({
  packages,
  pickupType,
  persistedStateKey
}) {
  const history = useHistory();
  const [state, error, callShipmentPickup] = useShipmentPickup(
    pickupType,
    persistedStateKey
  );
  const [drawerState, setDrawerState] = useState(DRAWER_STATE.CLOSED);

  const drawerData = {
    title: 'Eita! Algo deu errado.',
    description: error,
    button: 'Ok, entendi',
    buttonVariant: 'contained',
    icon: <ErrorOutlineIcon />
  };

  const onComplete = useCallback(() => {
    clearClientDocument();
    if (window?.driverAppBridge) {
      window.location = DRIVER_APP_DEEP_LINK;
    } else {
      history.push(PAGES.PACKAGE_LIST);
    }
  }, [history]);

  const pickupSurveyEnabled = pickupSurveyIsEnabled();

  useEffect(() => {
    if (state === statesEnum.error) setDrawerState(DRAWER_STATE.CUSTOM);

    if (state === statesEnum.success && !pickupSurveyEnabled) {
      onComplete();
    }
  }, [state, pickupSurveyEnabled, onComplete]);

  if (state === statesEnum.loading) return <FakeLoading />;

  return (
    <>
      <Document
        isDelivery={false}
        onGoBack={history.goBack}
        onConfirm={({ name, document }) => {
          // We need to store the client document locally because in case of error api response
          // the form is erased due to the component re rendering
          setClientDocument(name, document);

          callShipmentPickup(packages, {
            name,
            document
          });
        }}
      />
      <NotificationDrawer
        data={drawerData}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
      />
      <PickupSurveyDrawer
        isOpen={state === statesEnum.success && pickupSurveyEnabled}
        closeDrawer={onComplete}
      />
    </>
  );
}

PickupDocument.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      recipientName: PropTypes.string,
      barcode: PropTypes.string
    }).isRequired
  ).isRequired,
  pickupType: PropTypes.number,
  persistedStateKey: PropTypes.string
};

PickupDocument.defaultProps = {
  pickupType: PICKUP_TYPES.DIRECT,
  persistedStateKey: ''
};

PickupDocument.url = '/protocolo';
