import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import CameraScanner from 'view/atoms/scanner2';
import useWindowOrientation from 'hooks/useWindowOrientation';
import Header from 'view/molecules/header';
import SuccessBeep from './assets/beep_success.wav';
import ErrorBeep from './assets/beep_error.wav';
import TEXTS from './constants';

export function playSuccessBeep() {
  const audio = new Audio(SuccessBeep);
  audio.play();
}

export function playErrorBeep() {
  const audio = new Audio(ErrorBeep);
  audio.play();
}

export default function BarcodeReaderTemplateV2({
  onGoBack,
  onBarcodeRead,
  onTypeBarcodeClick,
  onHelpClick,
  allowedFormats
}) {
  const orientation = useWindowOrientation();

  const onDetected = result => {
    onBarcodeRead({
      barcode: result.content,
      format: result.format
    });
  };

  return (
    <>
      <Header
        backButton={{ action: onGoBack }}
        helpButton={{ action: onHelpClick }}
        middleButton={{ text: TEXTS.TYPE_BARCODE, action: onTypeBarcodeClick }}
        backgroundIsDark
      />
      <Box p={1.5}>
        <CameraScanner
          onDetected={onDetected}
          orientation={orientation}
          allowedFormats={allowedFormats}
          addWhiteBorder={false}
          cameraHeight="236px"
          radius={8}
          addMiddleLine
        />
      </Box>
    </>
  );
}

BarcodeReaderTemplateV2.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onBarcodeRead: PropTypes.func.isRequired,
  onTypeBarcodeClick: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  allowedFormats: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ])
};

BarcodeReaderTemplateV2.defaultProps = {
  allowedFormats: []
};
