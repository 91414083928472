import React, { useState } from 'react';
import { Redirect } from 'react-router';

import SharedPropTypes from 'view/shared-prop-types';
import PropTypes from 'prop-types';

import { PAGES } from 'view';
import { DELIVERY_OPTIONS_MAP } from 'view/pages/delivery-options/constants';
import { facadePhotoTypes, messages } from './constants';

import SamplePhoto from './sample-photo';
import TakeAPhoto from './take-a-photo';
import SuccessSyncTemplate from '../../templates/success-sync';

export const STATE = {
  SAMPLE_PHOTO: 'samplePhoto',
  TAKE_A_PHOTO: 'takeAPhoto',
  SUCCESS: 'success',
  REDIRECT: 'redirect'
};

const getFacadePhotoType = facadePhotoContext => {
  if (facadePhotoContext?.pickupId) {
    return facadePhotoTypes.pickupFacadePhoto;
  }

  const { deliveryOption } = facadePhotoContext;

  if (deliveryOption?.id === DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id) {
    return facadePhotoTypes.deliveryMailBox;
  }

  return facadePhotoTypes.deliveryFacadePhotoDelivery;
};

export default function FacadePhoto({
  onGoBack,
  onGoFallback,
  facadePhotoContext,
  defaultState
}) {
  const [state, setState] = useState(defaultState);
  const type = getFacadePhotoType(facadePhotoContext);
  const isPickup = type === facadePhotoTypes.pickupFacadePhoto;

  return (
    <>
      {state === STATE.SAMPLE_PHOTO && (
        <SamplePhoto
          nextStep={() => setState(STATE.TAKE_A_PHOTO)}
          onGoBack={onGoBack}
          onGoFallback={onGoFallback}
          type={type}
        />
      )}

      {state === STATE.TAKE_A_PHOTO && (
        <TakeAPhoto
          onGoBack={() => setState(STATE.SAMPLE_PHOTO)}
          nextStep={() => setState(STATE.SUCCESS)}
          facadePhotoContext={facadePhotoContext}
          type={type}
        />
      )}

      {state === STATE.SUCCESS && (
        <SuccessSyncTemplate
          onContinue={() => setState(STATE.REDIRECT)}
          title={isPickup ? messages.pickup.successTitle : undefined}
          buttonTitle={
            isPickup ? messages.pickup.successButtonTitle : undefined
          }
        />
      )}

      {state === STATE.REDIRECT && <Redirect to={PAGES.PACKAGE_LIST} />}
    </>
  );
}

FacadePhoto.propTypes = {
  onGoBack: PropTypes.func,
  onGoFallback: PropTypes.func,
  facadePhotoContext: SharedPropTypes.facadePhotoContext,
  defaultState: PropTypes.string
};

FacadePhoto.defaultProps = {
  onGoBack: undefined,
  onGoFallback: undefined,
  facadePhotoContext: null,
  defaultState: STATE.SAMPLE_PHOTO
};
