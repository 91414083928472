const browers = {
  chrome: 'Chrome',
  edge: 'Edge(Chromium)',
  opera: 'Opera',
  ie: 'IE'
};

function getVersion(regrexBrowserVersion) {
  return regrexBrowserVersion[1] || '0';
}

function getBrowserName(browserInfoMatch) {
  return browserInfoMatch[1] || navigator.userAgent;
}

function getBrowserVersion(browserInfoMatch) {
  const regrexBrowserVersion = /version\/(\d+)/i;
  const browserVersion = navigator.userAgent.match(regrexBrowserVersion);
  if (browserVersion != null && browserVersion[1]) {
    return browserVersion[1];
  }
  return browserInfoMatch[2] || '0';
}

export default function getBrowserNameAndVersion() {
  const regrexBrowser = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i;
  const regrexIe = /trident/i;
  const regrexIeVersion = /\brv[ :]+(\d+)/g;
  const regrexEdgeVersion = /\bEdg\/(\d+)/;
  const regrexOpera = /\bOPR\/(\d+)/;

  const agent = navigator.userAgent;

  const browserInfoMatch = agent.match(regrexBrowser) || [];

  if (regrexIe.test(browserInfoMatch[1])) {
    const ieVersion = regrexIeVersion.exec(agent) || [];
    return { name: browers.ie, version: getVersion(ieVersion) };
  }

  if (browserInfoMatch[1] === browers.chrome) {
    const edgeVersion = agent.match(regrexEdgeVersion);
    if (edgeVersion != null) {
      return { name: browers.edge, version: getVersion(edgeVersion) };
    }
    const operaVersion = agent.match(regrexOpera);
    if (operaVersion != null) {
      return { name: browers.opera, version: getVersion(operaVersion) };
    }
  }

  const name = getBrowserName(browserInfoMatch);
  const version = getBrowserVersion(browserInfoMatch);

  return {
    name,
    version
  };
}
