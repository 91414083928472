import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '@loggi/mar';
import { errorTypes } from 'operations/assignment-list/constants';

import { ReactComponent as CrossedWifi } from 'view/atoms/illustrations/crossed-wifi.svg';
import { ReactComponent as PackageWithMagnifyingGlass } from 'view/atoms/illustrations/package-with-magnifying-glass.svg';
import { ReactComponent as CrossedStorage } from 'view/atoms/illustrations/crossed-storage.svg';
import { ReactComponent as PendingBarcodePackages } from 'view/atoms/illustrations/pending-packages/pending-barcode-packages.svg';

import ConfirmDismissDrawer from 'view/molecules/confirm-dismiss-drawer';

import { statesEnum } from 'hooks/use-assignment-list';

import {
  DRAWER_FAIL_TO_FETCH_TEXTS,
  DRAWER_FAIL_TO_UPDATE_TEXTS,
  DRAWER_FAIL_TO_UPDATE_PACKAGE_TEXTS
} from './constants';

import { AlertIcon } from './components';

const ALERT_DATA = {
  backgroundColor: colors.smoke[50],
  icon: <AlertIcon />,
  color: colors.smoke[900]
};

export const DRAWER_FAIL_TO_FETCH_ICONS = {
  [errorTypes.networkError]: <CrossedWifi />,
  [errorTypes.storageError]: <CrossedStorage />,
  [errorTypes.genericError]: <PackageWithMagnifyingGlass />
};

export const DRAWER_FAIL_TO_UPDATE_ICONS = {
  [errorTypes.networkError]: <CrossedWifi />,
  [errorTypes.genericError]: <PendingBarcodePackages />
};

export default function ErrorDrawers({
  currentState,
  cleanCurrentState,
  errorType,
  didFailToFetchList,
  didFailToUpdateList,
  didFailToUpdatePackage,
  errorMessage,
  retryFetchingList
}) {
  const iconDrawerFailToFetch =
    DRAWER_FAIL_TO_FETCH_ICONS[errorType] ||
    DRAWER_FAIL_TO_FETCH_ICONS[errorTypes.genericError];
  const dataDrawerFailToFetch =
    DRAWER_FAIL_TO_FETCH_TEXTS[errorType] ||
    DRAWER_FAIL_TO_FETCH_TEXTS[errorTypes.genericError];
  const iconDrawerFailToUpdate =
    DRAWER_FAIL_TO_UPDATE_ICONS[errorType] ||
    DRAWER_FAIL_TO_UPDATE_ICONS[errorTypes.genericError];
  const dataDrawerFailToUpdate =
    DRAWER_FAIL_TO_UPDATE_TEXTS[errorType] ||
    DRAWER_FAIL_TO_UPDATE_TEXTS[errorTypes.genericError];
  const dataDrawerFailToUpdatePackage =
    DRAWER_FAIL_TO_UPDATE_PACKAGE_TEXTS[errorType] ||
    DRAWER_FAIL_TO_UPDATE_PACKAGE_TEXTS[errorTypes.genericError];

  const drawerIcon = didFailToFetchList
    ? iconDrawerFailToFetch
    : iconDrawerFailToUpdate;

  let drawerData = dataDrawerFailToUpdate;

  if (didFailToFetchList) {
    drawerData = dataDrawerFailToFetch;
  } else if (didFailToUpdatePackage) {
    drawerData = dataDrawerFailToUpdatePackage;
  }

  const hasAssignmentListFetchError =
    didFailToFetchList || didFailToUpdateList || didFailToUpdatePackage;

  const dismissAndRetryFetchingList = () => {
    return retryFetchingList();
  };

  const dismissFetchingList = didFailToUpdateList
    ? () => cleanCurrentState()
    : undefined;

  const hasError = currentState.state === statesEnum.ERROR;

  return (
    <ConfirmDismissDrawer
      icon={drawerIcon}
      data={drawerData}
      alertData={
        errorMessage ? { ...ALERT_DATA, message: errorMessage } : undefined
      }
      isOpen={hasError && hasAssignmentListFetchError}
      handleConfirmAction={dismissAndRetryFetchingList}
      handleDismissAction={dismissFetchingList}
    />
  );
}

ErrorDrawers.propTypes = {
  currentState: PropTypes.shape({ state: PropTypes.string }).isRequired,
  cleanCurrentState: PropTypes.func.isRequired,
  errorType: PropTypes.string.isRequired,
  didFailToFetchList: PropTypes.bool.isRequired,
  didFailToUpdateList: PropTypes.bool.isRequired,
  didFailToUpdatePackage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  retryFetchingList: PropTypes.func.isRequired
};
