import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import { colors } from '@loggi/mar';
import {
  OFFER_LIST_ACCESS_SOURCE_DETAILS,
  OFFER_LIST_ACCESS_SOURCE_SHOWALL_SHOWCASE,
  registerOfferListAccess
} from 'infra/services/analytics';
import EmptyListComponent from 'view/molecules/empty-list';
import logOfferNotificationService from 'infra/services/log-offer-notification';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import SortByNotificationDrawer from 'view/templates/showcase/showcase-message/sort-by-notification-drawer';
import ShowcaseDrawer from './showcase-message/message';
import CapacityReserveNotificationDrawerV2 from './showcase-message/capacity-reserve-notification-drawer-v2';
import ShowcaseCard from '../../molecules/showcase-card';
import constants from './constants';

export const hasSeenShowcaseDrawer = 'has_seen_showcase_drawer';
export const HAS_SEEN_SORT_BY_DRAWER = 'has_seen_sort_by_drawer';

function ShowcaseTemplate({
  offers,
  disabled,
  onMoreOffers,
  onAccept,
  hasActivities,
  onError
}) {
  const [showcaseOpen, setShowcaseOpen] = useState(false);
  const [sortByDrawerOpen, setSortByDrawerOpen] = useState(false);

  const isEnableSortByInOfferList = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enableSortByInOfferList)
  );

  // Open showcase presentation message if never seen.
  useEffect(() => {
    setShowcaseOpen(!localStorage.getItem(hasSeenShowcaseDrawer));
    setSortByDrawerOpen(
      isEnableSortByInOfferList &&
        !localStorage.getItem(HAS_SEEN_SORT_BY_DRAWER)
    );
  }, [setShowcaseOpen, setSortByDrawerOpen, isEnableSortByInOfferList]);

  const enableLogOfferNotification = useFeatureSwitch(
    featureSwitches.enableLogOfferNotificationPresented
  );

  const offerCardsCapacityReserve = offers.filter(
    offer => offer.capacityReserve
  );

  useEffect(() => {
    if (disabled || !enableLogOfferNotification) return;
    logOfferNotificationService.logOffersPresented(offers);
  }, [offers, disabled, enableLogOfferNotification]);

  if (!hasActivities) {
    // It has not activities, and we have no offers to show, simply render a empty
    // list message component.
    if (!offers.length || disabled) {
      return (
        <Box pt={2}>
          <EmptyListComponent disabled={disabled} />
        </Box>
      );
    }
  } else if (!offers.length || disabled) {
    // When there is activity, and there is no offer to show, we'll simply render
    // the reminder card.
    return <></>;
  }

  const onMoreOffersRegistered = source => {
    registerOfferListAccess(source);
    onMoreOffers();
  };

  const onShowcaseClose = () => {
    localStorage.setItem(hasSeenShowcaseDrawer, true);
    setShowcaseOpen(false);
  };

  const onSortByDrawerClose = () => {
    localStorage.setItem(HAS_SEEN_SORT_BY_DRAWER, true);
    setSortByDrawerOpen(false);
  };

  const renderCapacityReserveNotificationDrawer = () => {
    return offerCardsCapacityReserve.length > 0 ? (
      <CapacityReserveNotificationDrawerV2 />
    ) : (
      <></>
    );
  };

  // If there is only a single offer, the card occupies the entire column.
  const isSingleOffer = offers.length === 1;
  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="flex-start"
    >
      <Box>
        <Typography color="textPrimary" variant="subtitle1">
          <Box fontWeight="fontWeightMedium" px={3} my={2}>
            {constants.FEATURED_OFFERS}
          </Box>
        </Typography>
      </Box>

      <Box style={{ overflowX: 'auto' }} width="100%">
        <Grid
          container
          style={{
            width: isSingleOffer ? '100%' : 'max-content',
            paddingRight: '16px',
            paddingLeft: '16px'
          }}
        >
          {offers.map((offer, key) => {
            return (
              <Box
                display="flex"
                key={offer.itineraryId}
                px="8px"
                width={isSingleOffer ? '100%' : '216px'}
                minHeight="256px"
                flexDirection="column"
              >
                <ShowcaseCard
                  offer={offer}
                  onMoreOffers={() =>
                    onMoreOffersRegistered(OFFER_LIST_ACCESS_SOURCE_DETAILS)
                  }
                  onAccept={onAccept(key)}
                  onError={onError}
                />
              </Box>
            );
          })}
        </Grid>
      </Box>

      <Box px={0.5}>
        <Button
          variant="text"
          onClick={() =>
            onMoreOffersRegistered(OFFER_LIST_ACCESS_SOURCE_SHOWALL_SHOWCASE)
          }
          size="medium"
          fullWidth
        >
          <Box
            width="100%"
            display="flex"
            alignItems="flex-start"
            color={colors.bassis[700]}
          >
            {constants.SHOW_ALL_OFFERS}
          </Box>
        </Button>
      </Box>
      <ShowcaseDrawer
        open={showcaseOpen}
        onClose={onShowcaseClose}
        data-testid="showcase-message"
      />
      <SortByNotificationDrawer
        open={sortByDrawerOpen}
        onClose={onSortByDrawerClose}
        data-testid="sort_by-drawer"
      />
      {renderCapacityReserveNotificationDrawer()}
    </Box>
  );
}

ShowcaseTemplate.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      itineraryId: PropTypes.number,
      demandId: PropTypes.number,
      distance: PropTypes.number.isRequired,
      steps: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      deadlineCollect: PropTypes.string.isRequired,
      waypoints: PropTypes.arrayOf(
        PropTypes.shape({
          neighborhood: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          indexDisplay: PropTypes.string.isRequired,
          coordinates: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired
          })
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  onMoreOffers: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  hasActivities: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired
};

export default React.memo(ShowcaseTemplate);
