const returnPointCloseAtLocalStorageKey = 'return-point-close-at';

function clearReturnPointCloseAt() {
  localStorage.setItem(returnPointCloseAtLocalStorageKey, '');
}

function getReturnPointCloseAt() {
  return localStorage.getItem(returnPointCloseAtLocalStorageKey);
}

function setReturnPointCloseAt(returnPoints) {
  clearReturnPointCloseAt();
  let returnPointMaxCloseAt = '';
  try {
    returnPoints.map(({ returnPointCloseAt }) => {
      if (
        parseInt(returnPointMaxCloseAt, 10) <
          parseInt(returnPointCloseAt, 10) ||
        returnPointMaxCloseAt === ''
      ) {
        returnPointMaxCloseAt = returnPointCloseAt;
      }
      return returnPointCloseAt;
    });
  } catch (error) {
    returnPointMaxCloseAt = '';
  }

  localStorage.setItem(
    returnPointCloseAtLocalStorageKey,
    returnPointMaxCloseAt
  );
}

export {
  getReturnPointCloseAt,
  setReturnPointCloseAt,
  clearReturnPointCloseAt
};
