/**
 * Returns the top and bottom image bound.
 * @param {Uint32Array} data buffer with image content
 * @param {number} width image width dimension
 * @param {number} height image height dimension
 * @returns {Object} object with top and bottom image bounds
 */
function getTopBottom(data, width, height) {
  let topBound;
  let bottomBound;

  // crop image discarding top and bottom empty rows
  for (
    let yAxis = 0;
    yAxis < height && (topBound === undefined || bottomBound === undefined);
    yAxis += 1
  ) {
    for (
      let xAxis = 0;
      xAxis < width && (topBound === undefined || bottomBound === undefined);
      xAxis += 1
    ) {
      // index for data array
      // yAxis is the row position
      // xAxis is the column position
      // width is the offset in data array
      // height is the offset in data array
      const topIndex = yAxis * width + xAxis;
      const bottomIndex = (height - yAxis) * width + xAxis;

      // defining the top bound if this row have any pixel
      if (topBound === undefined && data[topIndex]) {
        topBound = yAxis + 1;
      }

      // defining the bottom bound if this row have any pixel
      if (bottomBound === undefined && data[bottomIndex]) {
        bottomBound = height - yAxis + 1;
      }
    }
  }

  return {
    topBound,
    bottomBound
  };
}

/**
 * Returns the left and right image bound.
 * @param {Uint32Array} data buffer with image content
 * @param {number} width image width dimension
 * @param {number} top image top bound
 * @param {number} bottom image bottom bound
 * @returns {Object} object with left and right image bounds
 */
function getLeftRight(data, width, top, bottom) {
  let leftBound;
  let rightBound;

  // crop image discarding left and right empty columns
  for (
    let xAxis = 0;
    xAxis < width && (leftBound === undefined || rightBound === undefined);
    xAxis += 1
  ) {
    for (
      let yAxis = top;
      yAxis <= bottom && (leftBound === undefined || rightBound === undefined);
      yAxis += 1
    ) {
      // index for data array
      // yAxis is the row position
      // xAxis is the column position
      // width is the offset in data array
      const leftIndex = yAxis * width + xAxis;
      const rightIndex = yAxis * width + (width - xAxis);

      // defining the left bound if this column have any pixel
      if (leftBound === undefined && data[leftIndex]) {
        leftBound = xAxis;
      }

      // defining the right bound if this column have any pixel
      if (rightBound === undefined && data[rightIndex]) {
        rightBound = width - xAxis;
      }
    }
  }

  return {
    leftBound,
    rightBound
  };
}

/**
 * Change the background color (RGBA) default (transparent on png = black).
 * @param {canvas} canvas a canvas
 * @param {integer} red color 0-255
 * @param {integer} green color 0-255
 * @param {integer} blue color 0-255
 * @param {integer} opacity transparency 0-255
 */
function changeBackgroundColor(
  canvas,
  red = 255,
  green = 255,
  blue = 255,
  opacity = 255
) {
  const context = canvas.getContext('2d');
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  const { data } = imageData;
  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] === 0 &&
      data[i + 1] === 0 &&
      data[i + 2] === 0 &&
      data[i + 3] === 0
    ) {
      data[i] = red;
      data[i + 1] = green;
      data[i + 2] = blue;
      data[i + 3] = opacity;
    }
  }

  context.putImageData(imageData, 0, 0);
}

/**
 * Returns the cropped image bound.
 * @param {HTMLElement} canvas Must be a HTMLElement object.
 * @returns {Object} object with cropped images dimensions and x,y position
 */
export function getImageBounds(canvas) {
  const context = canvas.getContext('2d');
  const { width, height } = canvas;
  const data = new Uint32Array(
    context.getImageData(0, 0, width, height).data.buffer
  );

  const boundsTopBottom = getTopBottom(data, width, height);
  const boundsLeftRight = getLeftRight(
    data,
    width,
    boundsTopBottom.topBound,
    boundsTopBottom.bottomBound
  );

  const top = boundsTopBottom.topBound || 0;
  const bottom = boundsTopBottom.bottomBound || 0;
  const left = boundsLeftRight.leftBound || 0;
  const right = boundsLeftRight.rightBound || 0;

  return {
    x: left,
    y: top,
    width: Math.max(right - left, 1),
    height: Math.max(bottom - top, 1)
  };
}

/**
 * Crops canvas removing empty space at the sides until it finds the
 * first drawn pixel on each side.
 * @param {HTMLElement} canvas Must be a HTMLElement object.
 * @returns {string} content of the current canvas as an image
 */
export default function cropImage(canvas) {
  const cropped = document.createElement('canvas');
  const context = cropped.getContext('2d');
  const bounds = getImageBounds(canvas);
  const margin = 10;
  const marginIncrement = 2 * margin;

  const data = canvas
    .getContext('2d')
    .getImageData(bounds.x, bounds.y, bounds.width, bounds.height);

  cropped.width = bounds.width + marginIncrement;
  cropped.height = bounds.height + marginIncrement;

  context.putImageData(data, margin, margin);

  changeBackgroundColor(cropped);

  return cropped.toDataURL('image/jpeg', 0.5);
}
