export const DELIVERY_OPTIONS_MAP = {
  RECIPIENT: {
    id: 1,
    text: 'Com o destinatário',
    detailsScreenTitle: 'Peça o nome e documento do destinatário'
  },
  OTHER_RECIPIENT: {
    id: 2,
    text: 'Com algum conhecido',
    detailsScreenTitle: 'Peça o nome e documento de quem recebeu o pacote'
  },
  CONCIERGE: {
    id: 3,
    text: 'Na portaria',
    detailsScreenTitle: 'Peça o nome e documento de quem recebeu o pacote'
  },
  MAILBOX_DELIVERY: {
    id: 4,
    text: 'Na caixa de correspondência',
    detailsScreenTitle: 'Mostre a caixa de correspondência'
  }
};

export const DELIVERY_OPTIONS = Object.values(DELIVERY_OPTIONS_MAP);

export const messages = {
  headerText: 'Como você deixou o pacote?'
};
