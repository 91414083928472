import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  Button,
  Drawer,
  CircularProgress
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function ConfirmDrawer({
  open,
  title,
  subtitle,
  confirmButton,
  dismissButton
}) {
  const { colors } = useTheme();

  return (
    <Drawer
      data-testid="confirm-drawer"
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
      p={1.5}
    >
      <Box px={2.5} pt={2} color={colors.neutrals.typeface.primary}>
        <Typography
          variant="body1"
          style={{ marginBottom: 8, fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
      <Box display="flex" px={2.5} py={2} flexDirection="column">
        <Button
          fullWidth
          data-testid="confirm-drawer-main-button"
          variant="contained"
          color="primary"
          size="large"
          onClick={confirmButton.action}
        >
          {confirmButton.loading ? (
            <CircularProgress
              data-testid="confirm-drawer-loading"
              style={{
                width: 24,
                height: 24,
                color: colors.neutrals.white.pure
              }}
            />
          ) : (
            <>{confirmButton.text}</>
          )}
        </Button>
        <Button
          disabled={confirmButton.loading}
          fullWidth
          data-testid="confirm-drawer-bottom-button"
          color="primary"
          size="large"
          onClick={dismissButton.action}
          style={{ marginTop: 10 }}
        >
          {dismissButton.text}
        </Button>
      </Box>
    </Drawer>
  );
}

ConfirmDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  confirmButton: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.func,
    loading: PropTypes.bool
  }),
  dismissButton: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.func
  })
};

ConfirmDrawer.defaultProps = {
  subtitle: '',
  confirmButton: {},
  dismissButton: {}
};
