import { Box, Button, Typography } from '@material-ui/core';
import {
  OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON,
  registerOfferListAccess
} from 'infra/services/analytics';
import { sortShowCaseOffers } from 'infra/services/sort-rd-offers';
import { parseOfferProtoToOfferCard } from 'infra/services/parsers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import EndRouteReminder from 'view/molecules/end-route-reminder';
import LoadingShowcase from 'view/molecules/loading-showcase';
import { acceptOfferService as infraAcceptOfferService } from 'infra/services/accept-offer';
import getAvailableOffers from 'infra/services/get-driver-offers';
import ErrorAlert from 'view/molecules/error-alert';
import Loading from 'view/molecules/loading';
import ShowcaseTemplate from 'view/templates/showcase';
import { openDeepLink } from 'infra/services/app-bridge';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import CapacityReserveConfirmationDrawer from 'view/templates/showcase/showcase-message/capacity-reserve-confirmation-drawer';
import { featureSwitchEnabledForDriverId } from 'operations/feature-switches/feature-switches';
import TEXT, { companyRelation, itineraryTypes } from './constants';
import ShowcaseContainer from './container';
import { ReactComponent as DriverIcon } from './svg/driver.svg';
import { ReactComponent as NoneOfferIcon } from './svg/none_offer.svg';

function ShowcasePage({
  getDriverOffersService,
  acceptOfferService,
  driverInfo,
  onOfferSearchClick
}) {
  const [offerCards, setOfferCards] = useState([]);
  const [pageDisabled, setPageDisabled] = useState(false);
  const [offerLoading, setOfferLoading] = useState(true);
  const [error, setError] = useState('');
  const [showcase] = useState(true);
  const [canSeeShowcase, setCanSeeShowcase] = useState(false);
  const [
    capacityReserveConfirmDrawerOpen,
    setCapacityReserveConfirmDrawerOpen
  ] = useState(false);
  const hasActivities = driverInfo && driverInfo.pendingActivitiesCount > 0;

  const lmcsEnableNewCapacityReserveCard = useFeatureSwitch(
    featureSwitches.enableNewCapacityReserveCard
  );
  const isEnabledNewCapacityReserveCard = featureSwitchEnabledForDriverLMC(
    lmcsEnableNewCapacityReserveCard
  );
  const isEnabledUseAllocationToListOffersByDriverId = featureSwitchEnabledForDriverId(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffersByDriverId)
  );
  const isEnabledUseAllocationToListOffers = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffers)
  );
  const isEnableShowCapacityReserveConfirmation = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enableShowCapacityReserveConfirmation)
  );

  let icon = null;
  let title = null;
  let subtitle = null;
  let reminder = null;

  if (hasActivities) {
    icon = <DriverIcon height="60px" data-testid="driver-icon" />;
    title = TEXT.FINISH_ITINERARY_TITLE;
    subtitle = TEXT.FINISH_ITINERARY_SUBTITLE;
    reminder = <EndRouteReminder />;
  } else {
    icon = <NoneOfferIcon height="60px" data-testid="none-offer-icon" />;
    title = TEXT.OFFER_NONE;
    subtitle = TEXT.OFFER_NONE_SUBTITLE;
  }

  const handleCloseErrorAlert = () => setError('');

  const handleAcceptOffer = index => async offer => {
    setOfferLoading(true);

    const resp = await acceptOfferService(offer, index, 'SHOWCASE')
      .catch(err => {
        setError(err.message);
      })
      .finally(() => setOfferLoading(false));

    if (
      isEnableShowCapacityReserveConfirmation &&
      resp?.isDriverBusy &&
      offer.capacityReserve
    ) {
      setCapacityReserveConfirmDrawerOpen(true);
    } else {
      openDeepLink();
    }
  };

  const handleGetOfferServiceSuccess = response => {
    const offers = response.offers?.offerList
      ? response.offers.offerList.map(offer =>
          parseOfferProtoToOfferCard(offer)
        )
      : [];
    setPageDisabled(response.disabled);
    if (isEnabledNewCapacityReserveCard) {
      sortShowCaseOffers(offers).then(sortedOffers => {
        setOfferCards(sortedOffers);
        setCanSeeShowcase(true);
      });
    } else {
      setOfferCards(offers);
      setCanSeeShowcase(true);
    }
  };

  const handleGetOffers = () => {
    let isMounted = true;
    setOfferLoading(true);
    getDriverOffersService({
      showcase,
      useAllocation:
        isEnabledUseAllocationToListOffers ||
        isEnabledUseAllocationToListOffersByDriverId
    })
      .then(response => {
        if (isMounted) {
          handleGetOfferServiceSuccess(response);
        }
      })
      .catch(err => {
        if (err.status === 403) {
          if (isMounted) {
            setCanSeeShowcase(false);
          }
        } else if (err.status === 503) {
          setPageDisabled(true);
        } else {
          setError(err.message);
        }
      })
      .finally(() => {
        if (isMounted) {
          setOfferLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  };

  useEffect(handleGetOffers, []);

  const handleGetOffersError = message => {
    setError(message);
    setCanSeeShowcase(false);
    handleGetOffers();
  };

  const onCapacityReserveConfirmDrawerClose = () => {
    setCapacityReserveConfirmDrawerOpen(false);
    openDeepLink('/atividades/lista-atividades');
  };

  if (error) {
    return <ErrorAlert error={error} onClose={handleCloseErrorAlert} />;
  }

  if (canSeeShowcase) {
    const component = offerLoading ? (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <Loading />
      </Box>
    ) : (
      <>
        <ShowcaseTemplate
          offers={offerCards}
          disabled={pageDisabled}
          onMoreOffers={onOfferSearchClick}
          onAccept={handleAcceptOffer}
          hasActivities={hasActivities}
          onError={handleGetOffersError}
        />
        {reminder}
        <CapacityReserveConfirmationDrawer
          open={capacityReserveConfirmDrawerOpen}
          onClose={onCapacityReserveConfirmDrawerClose}
        />
      </>
    );

    return <ShowcaseContainer page={component} />;
  }

  return offerLoading ? (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
    >
      <LoadingShowcase />
    </Box>
  ) : (
    <>
      <Box
        pb={2.5}
        px={2.5}
        flexGrow={1}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        {icon}
        <Box py={1}>
          <Typography variant="subtitle2">
            <Box component="span" fontWeight="fontWeightBold">
              {title}
            </Box>
          </Typography>
        </Box>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      <Box pb={2.5} px={2.5} width="100%">
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => {
            registerOfferListAccess(OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON);
            onOfferSearchClick();
          }}
        >
          {TEXT.OFFER_SEARCH}
        </Button>
      </Box>
    </>
  );
}

ShowcasePage.propTypes = {
  getDriverOffersService: PropTypes.func,
  driverInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    availableForNotifications: PropTypes.bool,
    driverProfilePictureUrl: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    operationalStatus: PropTypes.string.isRequired,
    onboardTime: PropTypes.string,
    companyType: PropTypes.string,
    companyRelation: PropTypes.oneOf(Object.values(companyRelation)),
    itineraryType: PropTypes.oneOf(Object.values(itineraryTypes)),
    pendingActivitiesCount: PropTypes.number,
    showcaseEnabled: PropTypes.bool,
    menu: PropTypes.arrayOf(PropTypes.string),
    driverAppMinVersion: PropTypes.number
  }),
  acceptOfferService: PropTypes.func,
  onOfferSearchClick: PropTypes.func.isRequired
};

ShowcasePage.defaultProps = {
  getDriverOffersService: getAvailableOffers,
  acceptOfferService: infraAcceptOfferService,
  driverInfo: null
};

export default React.memo(ShowcasePage);
