import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserMultiFormatReader,
  NotFoundException,
  BarcodeFormat
} from '@zxing/library';
import DecodeHintType from '@zxing/library/esm5/core/DecodeHintType';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));

const videoElementId = 'videoScanner';
const timeBetweenScansMillis = 1500;

export default function CameraScanner({
  onDetected,
  orientation,
  allowedFormats,
  addWhiteBorder,
  cameraHeight,
  radius,
  addMiddleLine
}) {
  const classes = useStyles();
  const [canAddMiddleLine, setCanAddMiddleLine] = useState(false);

  useEffect(() => {
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, allowedFormats);

    const codeReader = new BrowserMultiFormatReader(
      hints,
      timeBetweenScansMillis
    );

    const videoElement = document.getElementById(videoElementId);

    codeReader.decodeFromVideoDevice(null, videoElement, (result, err) => {
      if (addMiddleLine) {
        setCanAddMiddleLine(true);
      }
      if (result) {
        onDetected({
          content: result.text,
          format: BarcodeFormat[result.format]
        });
      }

      // ignoring NotFoundException because it happens while
      // the user is not scanning a barcode
      if (err && !(err instanceof NotFoundException)) {
        throw Error(err);
      }
    });

    return () => {
      codeReader.reset();
      setCanAddMiddleLine(false);
    };

    // We are disabling this rule because every time we detect a barcode
    // the onDetected function is called and that makes the component rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation, addMiddleLine]);

  return (
    <Box
      overflow="hidden"
      border={addWhiteBorder ? 5 : 0}
      borderRadius={radius}
      borderColor="white"
      height={cameraHeight}
      mx={2.5}
      data-testid="pickup-scanner-video-container"
    >
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* We are disabling this rule because we don't need track options */
        /* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          id={videoElementId}
          className={classes.video}
          data-testid="pickup-scanner-video"
        />
        {canAddMiddleLine && (
          <div
            data-testid="pickup-scanner-video-red-line"
            style={{
              position: 'absolute',
              height: '4px',
              backgroundColor: 'red',
              top: '50%',
              width: '100%'
            }}
          />
        )}
      </div>
    </Box>
  );
}

CameraScanner.propTypes = {
  onDetected: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  allowedFormats: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  addWhiteBorder: PropTypes.bool,
  cameraHeight: PropTypes.string,
  radius: PropTypes.number,
  addMiddleLine: PropTypes.bool
};

CameraScanner.defaultProps = {
  allowedFormats: null,
  addWhiteBorder: true,
  cameraHeight: '128px',
  radius: 8,
  addMiddleLine: false
};
