import firebase from 'firebase/app';

const startTime = {};

export const eventNames = {
  facadePhoto: 'facade_photo'
};

export const calculateElapsedTime = (startElapsedTime, endElapsedTime) => {
  return Math.round((endElapsedTime - startElapsedTime) / 1000);
};

export const trackStart = eventName => {
  startTime[eventName] = new Date();
};

export const trackEnd = (eventName, packageId) => {
  firebase.analytics().logEvent('elapsed_time', {
    time: calculateElapsedTime(startTime[eventName], Date.now()),
    eventName,
    packageId
  });
};
