import { getCurrentUser } from 'operations/auth';
import { isFeatureSwitchEnabledForValue } from '@loggi/firebase-feature-switches';

const featureSwitches = {
  enableResponseFromAllocationService: 'enableResponseFromAllocationService',
  googleMapsOnAssignmentList: 'enableGoogleMapsAssignmentList',
  disableToLeveGoogleMapsOnAssignmentList:
    'disableToLeveGoogleMapsOnAssignmentList',
  googleMapsSurvey: 'enableGoogleMapsSurvey',
  opportunisticOfferSurvey: 'enableOpportunisticOfferSurvey',
  changeWhatsappAPI: 'enableWhatsappChange',
  enablePackageDirectionReturnMessage: 'enablePackageDirectionReturnMessage',
  callAllocationDirectly: 'callAllocationDirectly',
  enablePickupFacadePhoto: 'enablePickupFacadePhoto',
  enablePickupFacadePhotoForPickupFlow: 'enablePickupFacadePhotoForPickupFlow',
  sendDriverLocationOnQuitPickup: 'sendDriverLocationOnQuitPickup',
  enableRemovePackageWhenBarcodeIsDeleted:
    'enableRemovePackageWhenBarcodeIsDeleted',
  defaultZoomByFs: 'defaultZoomByFs',
  enablePickupProblemComplement: 'enablePickupProblemComplement',
  enableMarkerClustererGoogleMaps: 'enableMarkerClustererGoogleMaps',
  pickupScannerPossibleFormats: 'pickupScannerPossibleFormats',
  enableUseScannerFormatsFromFS: 'enableUseScannerFormatsFromFS',
  enableNewFlowBeyond: 'enableNewFlowBeyond',
  maxBarcodeLengthToTruncate: 'maxBarcodeLengthToTruncate',
  minBarcodeLengthToTruncate: 'minBarcodeLengthToTruncate',
  enableNewPickupScannerFlowForDriverByRelation:
    'enableNewPickupScannerFlowForDriverByRelation',
  enableNewPickupProblemsPage: 'enableNewPickupProblemsPage',
  enableProblemComplementAntecipatedOffer:
    'enableProblemComplementAntecipatedOffer',
  enableEventPackageWithoutBarcode: 'enableEventPackageWithoutBarcode',
  enablePlaceholder: 'enablePlaceholder',
  enableNewPickupScannerFlowPhaseThree: 'enableNewPickupScannerFlowPhaseThree',
  enabledLMCsForListOfPlannedVolumesWithMultimodals:
    'enableListOfPlannedVolumesForMultimodals',
  percentageToAddInVolumesLengthForMultimodals:
    'percentageToAddInVolumesLengthForMultimodals',
  enableMarkerClustererGoogleMapsByLmc: 'enableMarkerClustererGoogleMapsByLmc',
  enablePickupRouteRedirect: 'enablePickupRouteRedirect',
  driversEnabledForGeofence: 'driversEnabledForGeofence',
  enableZoomWithBoundsInGoogleMaps: 'enableZoomWithBoundsInGoogleMaps',
  enableNewPackageReturnReminder: 'enableNewPackageReturnReminder',
  enableNewAssignmentList: 'enableNewAssignmentList',
  enableNewPickupAndPackageDetails: 'enableNewPickupAndPackageDetails',
  enableListDeliveredPackagesByCompanyRelation:
    'enableListDeliveredPackagesByCompanyRelation',
  enableListDeliveredPackagesByLastMileCompany:
    'enableListDeliveredPackagesByLastMileCompany',
  enablePackageDetailsModalByLastMileCompany:
    'enablePackageDetailsModalByLastMileCompany',
  showBarcodeOnAssignmentList: 'showBarcodeOnAssignmentList',
  considerDeliveredPackagesFromBackend: 'considerDeliveredPackagesFromBackend',
  useAssignmentIndexOnMap: 'useAssignmentIndexOnMap',
  sendReceiverDocumentType: 'sendReceiverDocumentType',
  callCompletePickupFromAllocation: 'callCompletePickupFromAllocation',
  enableStatusChangeDeliveredPackageByCompanyRelation:
    'enableStatusChangeDeliveredPackageByCompanyRelation',
  enableStatusChangeDeliveredPackageForDriverLastMileCompany:
    'enableStatusChangeDeliveredPackageForDriverLastMileCompany',
  disableFacadePhotoFallback: 'disableFacadePhotoFallback',
  enableSendDataToInsider: 'enableSendDataToInsider',
  enabledBlockCompanyIdToPickupUnexpectedPackages:
    'enabledBlockCompanyIdToPickupUnexpectedPackages',
  showOnlyLastDigitsSealBag: 'showOnlyLastDigitsSealBag',
  enabledShowCustomLabelFieldsPod: 'enabledShowCustomLabelFieldsPod',
  podTitleOpenField: 'podTitleOpenField',
  podDescriptionPlaceHolder: 'podDescriptionPlaceHolder',
  podDescriptionWarning: 'podDescriptionWarning',
};

/**
 * Possible values for featureSwitchValue:
 * LMCs separated by commas -> 'loggi_sp_sao_paulo,loggi_tower_0,loggi_tower_2'
 * one LMC only -> 'loggi_sp_sao_paulo'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForDriverLMC = featureSwitchValue => {
  const driverLMC = getCurrentUser()?.lmcIdentification;
  return isFeatureSwitchEnabledForValue(driverLMC, featureSwitchValue);
};

/**
 * Possible values for featureSwitchValue:
 * LMCs separated by commas -> 'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_CONTRACTOR,LAST_MILE_COMPANY_TYPE_LEVE:DRIVER_COMPANY_RELATION_COLLABORATOR'
 * one LMC only -> 'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_CONTRACTOR'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 *
 * Relations:
 * MEI -> LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_CONTRACTOR
 * LEVE -> LAST_MILE_COMPANY_TYPE_LEVE:DRIVER_COMPANY_RELATION_COLLABORATOR
 * TRANSPORTADORA -> LAST_MILE_COMPANY_TYPE_CARRIER:DRIVER_COMPANY_RELATION_COLLABORATOR
 * CLT -> LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_STAFF
 */
export const featureSwitchEnabledForDriverCompanyTypeRelation = featureSwitchValue => {
  const {
    companyRelation: driverCompanyRelation,
    companyType: driverCompanyType
  } = getCurrentUser() || {};

  return isFeatureSwitchEnabledForValue(
    `${driverCompanyType}:${driverCompanyRelation}`,
    featureSwitchValue
  );
};

/**
 * Possible values for featureSwitchValue:
 * Pickup Flow sepparated by comma -> 'use_barcode_bip,use_xd_app_agency_transfer,automatic_fill'
 * one flow only -> 'use_barcode_bip'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForPickupFlow = ({
  pickupFlow,
  featureSwitchValue
}) => {
  return isFeatureSwitchEnabledForValue(pickupFlow, featureSwitchValue);
};

export const featureSwitchEnabledForCompanyId = ({ companyId, fsValue }) => {
  return isFeatureSwitchEnabledForValue(companyId, fsValue);
};

export default featureSwitches;

/**
 * Possible values for featureSwitchValue:
 * Pickup Problem sepparated by comma -> 'couldnt_pickup,quit_pickup,not_enough_space'
 * one flow only -> 'quit_pickup'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForPickupProblem = ({
  pickupProblem,
  featureSwitchValue
}) => {
  return isFeatureSwitchEnabledForValue(pickupProblem, featureSwitchValue);
};
