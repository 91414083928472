import apiLoggiWeb from 'operations/config/api/loggiweb';
import apiAllocation from 'operations/config/api/allocation';
import getMultibarcodesFromNotIntegratedPackages from 'operations/package-not-integrated';
import getPosition from 'operations/geolocation';
import { goTo } from 'operations/history';
import { notificationTypeEnum } from 'view/pages/offer-notification/constants';
import assignmentListDB from 'operations/assignment-list/assignment-list-db';

const { clearDB: clearAssignmentListDB } = assignmentListDB;

export const urls = {
  complete: {
    loggiWeb: `/offer/complete`,
    allocation: `/v1/offerdemand/completepickup`
  },
  quit: {
    loggiWeb: `/offer/quit`,
    allocation: `/v1/offerdemand/quitpickup`
  }
};

/**
 * @param {Array<Package>} packages - `packages` is used to inform server the packages what was visualized by driver
 * it will be used to match the server packages to check if the list is updated
 * @param {pickupId} pickup_id - should come from assignment
 * @param {firstBeepStartTime} - time that packages beep started
 * @return {Promise<{}>} Wretch response object that contain an empty response in case of success
 */
function completePickup({
  packages,
  pickupId,
  firstBeepStartTime,
  pickupProtocol,
  companyId,
  ignoreProxy = false
}) {
  const api = ignoreProxy ? apiAllocation() : apiLoggiWeb();
  const url = ignoreProxy ? urls.complete.allocation : urls.complete.loggiWeb;

  const packagesIds = packages.map(item => item.packageId);
  const timestamp = new Date().toISOString();

  const packagesNotIntegratedMultibarcodes = getMultibarcodesFromNotIntegratedPackages(
    packages
  );
  const volumes = packages.map(item => {
    return {
      external_key: item.packageId,
      tracking_key: item.trackingKey,
      barcode: item.barcodes?.length > 0 ? item.barcodes[0] : item.barcode,
      loggi_key: item.loggiKey
    };
  });

  return api.url(url).post({
    packages_ids: packagesIds,
    pickup_id: pickupId,
    first_beep_start_time: firstBeepStartTime || timestamp,
    protocol: pickupProtocol,
    company_id: parseInt(companyId, 10) || undefined,
    packages_not_integrated_multibarcodes: packagesNotIntegratedMultibarcodes,
    volumes
  });
}

/**
 * @param {string} pickup_id - should come from assignment
 * @param {string} problemSlug
 * @param {string} problemComplement
 */
async function quitPickup({
  pickupId,
  problemSlug,
  problemComplement = '',
  ignoreProxy = false,
  sendDriverPosition = false
}) {
  const api = ignoreProxy ? apiAllocation() : apiLoggiWeb();
  const url = ignoreProxy ? urls.quit.allocation : urls.quit.loggiWeb;
  const driverPosition = {};

  if (sendDriverPosition) {
    try {
      const { latitude, longitude } = await getPosition();
      driverPosition.driver_position = { latitude, longitude };
    } catch (err) {
      // do not block quitPickup request if the location fails
    }
  }

  return api.url(url).post({
    pickup_id: pickupId,
    reason: problemSlug,
    reason_complement: problemComplement,
    ...(ignoreProxy && sendDriverPosition && driverPosition)
  });
}

function handleOfferNotification({
  notificationType,
  eventAddress,
  eventIsoDate,
  deadlineTime,
  withoutItinerary,
  packagesInCustodyCount
}) {
  const isDeallocationByTimeout =
    notificationType === notificationTypeEnum.https.deallocationByTimeout ||
    notificationType === notificationTypeEnum.https.deallocationByRequest ||
    notificationType === notificationTypeEnum.grpc.deallocationByTimeout ||
    notificationType === notificationTypeEnum.grpc.deallocationByRequest;

  const isDeallocationByCancelation =
    notificationType === notificationTypeEnum.https.deallocationTypeCancelled ||
    notificationType === notificationTypeEnum.grpc.deallocationTypeCancelled;

  const isItineraryDeadlineDeallocation =
    notificationType ===
    notificationTypeEnum.grpc.itineraryDeadlineDeallocation;

  const isDriverNotAllocatedInPickup =
    notificationType === notificationTypeEnum.grpc.driverNotAllocatedInPickup;
  const hasPackagesInCustody = packagesInCustodyCount > 0;

  if (isDeallocationByTimeout || isDeallocationByCancelation) {
    goTo.offerNotification({
      notificationType: isDeallocationByTimeout
        ? notificationTypeEnum.grpc.deallocationByTimeout
        : notificationType,
      deadline: isDeallocationByTimeout ? eventIsoDate : null,
      eventAddress
    });
  } else if (isItineraryDeadlineDeallocation) {
    goTo.itineraryDeadlineDeallocation({
      allowedDelay: deadlineTime,
      withoutItinerary
    });
  } else if (isDriverNotAllocatedInPickup) {
    if (hasPackagesInCustody) {
      goTo.itineraryDisassociation({
        packagesInCustodyCount
      });
    } else {
      window.driverAppBridge?.deallocateOrder();
      clearAssignmentListDB();
    }
  }
}

export { completePickup, quitPickup, handleOfferNotification };
