import React from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as NavigationIcon } from './navigation_icon.svg';
import { ReactComponent as OfferCancelledIcon } from './offer_cancelled_icon.svg';

export const notificationTypeEnum = {
  https: {
    deallocationByTimeout: 1,
    deallocationByRequest: 2,
    deallocationTypeCancelled: 3,
    itineraryDeadlineDeallocation: 'itinerary_deadline_deallocation'
  },
  grpc: {
    deallocationByTimeout: 'NOTIFICATION_TYPE_DEALLOCATION_BY_TIMEOUT',
    deallocationByRequest: 'NOTIFICATION_TYPE_DEALLOCATION_BY_REQUEST',
    deallocationTypeCancelled: 'NOTIFICATION_TYPE_CANCELLATION',
    driverNotAllocatedInPickup:
      'NOTIFICATION_TYPE_DRIVER_NOT_ALLOCATED_IN_PICKUP',
    itineraryDeadlineDeallocation: 'NOTIFICATION_TYPE_DEALLOCATION_BY_DEADLINE'
  }
};

const messageTimeout = {
  explanationOfferNotification: () => (
    <Typography variant="subtitle2">
      Você não chegou dentro do prazo estimado. Aceite novas ofertas para
      continuar entregando.
    </Typography>
  ),
  title: 'expirou',
  getIcon: () => <NavigationIcon />
};

const messageCancelled = {
  explanationOfferNotification: () => (
    <Typography variant="subtitle2">
      Uma retirada que estava na sua lista de atividades foi{' '}
      <strong>cancelada</strong> por quem fez o pedido. Aceite novas ofertas
      para continuar entregando.
    </Typography>
  ),
  title: 'cancelada',
  getIcon: () => <OfferCancelledIcon />
};

export const messages = {
  [notificationTypeEnum.https.deallocationByTimeout]: messageTimeout,
  [notificationTypeEnum.https.deallocationTypeCancelled]: messageCancelled,

  [notificationTypeEnum.grpc.deallocationByTimeout]: messageTimeout,
  [notificationTypeEnum.grpc.deallocationTypeCancelled]: messageCancelled,

  button: 'Ok, entendi'
};
