import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { ArrowBack, Phone } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import NotificationDrawer, { states } from './notification-drawer';

const url = '/last-mile/v1/shipment/emergency';
export const phones = {
  firefighter: { name: 'Bombeiros', phone: '193' },
  EMS: { name: 'SAMU', phone: '192' },
  police: { name: 'Polícia', phone: '190' }
};

export const texts = {
  title: 'Emergência',
  subtitle: {
    mei:
      'Se sofreu acidente, foi roubado ou seu veículo quebrou, fale com a gente. Se precisar, entre em contato com algum órgão de segurança, a ligação é gratuita.',
    leve:
      'Se você sofreu um acidente, foi roubado ou seu veículo quebrou, avise a base Leve. Ou, caso você precise, entre em contato com um algum órgão de segurança.'
  },
  buttonLoggi: 'Avisar a Loggi'
};

function useNotifyLoggi(notifyLoggi) {
  const [state, setState] = useState(states.idle);

  function callNotifyLoggi() {
    setState(states.loading);
    return notifyLoggi(url)
      .then(() => {
        setState(states.success);
      })
      .catch(() => {
        setState(states.error);
      });
  }

  return [state, setState, callNotifyLoggi];
}

export default function DriversEmergency({
  canNotifyLoggi,
  notifyLoggi,
  goBack
}) {
  const [state, setState, callNotifyLoggi] = useNotifyLoggi(notifyLoggi);

  return (
    <>
      <Box
        bgcolor={colors.root[0]}
        display="flex"
        flexDirection="column"
        py={2.5}
      >
        <Box flexGrow={1}>
          <Box px={0.7} pb={2}>
            <IconButton onClick={goBack} data-testid="back-button">
              <ArrowBack style={{ color: colors.blue[500] }} />
            </IconButton>
          </Box>
          <Box p={2.5}>
            <Box color={colors.root[900]}>
              <Typography variant="h5">{texts.title}</Typography>
            </Box>
            <Box py={1.25} color={colors.smoke[800]}>
              <Typography variant="body1">
                {texts.subtitle[canNotifyLoggi ? 'mei' : 'leve']}
              </Typography>
            </Box>
          </Box>
          {Object.values(phones).map(item => (
            <Box mx={2.5} key={item.name}>
              <Box
                bgcolor={colors.smoke[50]}
                borderRadius={8}
                my={2.5}
                p={2}
                display="flex"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box pb={0.5} color={colors.smoke[700]}>
                    <Typography variant="body2" color={colors.smoke[800]}>
                      Ligar {item.phone}
                    </Typography>
                  </Box>
                  <Box color={colors.smoke[900]}>
                    <Typography variant="subtitle1">{item.name}</Typography>
                  </Box>
                </Box>

                <Box p={1.5} data-testid="recipient-phone">
                  <IconButton
                    variant="outlined"
                    style={{
                      height: '40px',
                      width: '40px',
                      border: `solid 1px ${colors.blue[500]}`,
                      color: colors.blue[500],
                      backgroundColor: colors.root[0]
                    }}
                    data-testid="recipient-phone-button"
                    onClick={() => {
                      window.location.href = `tel:${item.phone}`;
                    }}
                  >
                    <Phone />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        {canNotifyLoggi && (
          <Box px={2.5} mt={7}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              data-testid="button-notify-loggi"
              onClick={() => {
                callNotifyLoggi();
              }}
            >
              {texts.buttonLoggi}
            </Button>
          </Box>
        )}
      </Box>
      <NotificationDrawer
        state={state}
        onClose={() => {
          setState(states.idle);
        }}
      />
    </>
  );
}

DriversEmergency.propTypes = {
  canNotifyLoggi: PropTypes.bool,
  notifyLoggi: PropTypes.func,
  goBack: PropTypes.func.isRequired
};

DriversEmergency.defaultProps = {
  canNotifyLoggi: false,
  notifyLoggi: () => ({})
};
