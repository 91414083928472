import {
  registerOfferListAcceptClick,
  registerOfferListAcceptSuccess,
  registerOfferListAcceptError,
  registerOfferListAcceptRestClick,
  registerOfferListAcceptRestSuccess,
  registerOfferListAcceptRestError
} from 'infra/services/analytics';
import apiAllocation from 'infra/api/allocation';
import graphqlRequest from '../graphql-request';
import geolocation from './geolocation';
import { ChannelTypes } from './constants';

const acceptOfferMutation = `
  mutation AcceptOfferMutation($itineraryId: Int!, $offerChannel: String) {
    acceptOfferMutation(input: {
          itineraryId: $itineraryId,
          offerChannel: $offerChannel
      }) {
        isSuccessfully: success
        errors {
            message
        }
    }
  }
`;

/**
 * Performs a API request to acceptOfferMutation
 *
 * @param {Object} variables - The variables that be used in graphql request
 * @param {int} variables.itineraryId - Of offer to be accepted
 * @returns {Promise}
 */
export default function acceptOffer(variables) {
  return graphqlRequest({
    query: acceptOfferMutation,
    variables
  }).then(({ data, errors }) => {
    const response = data.acceptOfferMutation;
    if (response && response.isSuccessfully) {
      return response;
    }

    let errorResponse;
    if (response && 'errors' in response) {
      errorResponse = { errors: response.errors };
      return Promise.reject(errorResponse);
    }
    errorResponse = { errors };
    return Promise.reject(errorResponse);
  });
}

export async function acceptOfferRest(offerId, pickupId, offerChannel) {
  const position = await geolocation();
  const body = {
    offer_id: offerId,
    pickup_id: pickupId,
    offer_channel: offerChannel,
    driver_position: {
      lat: position?.latitude,
      lon: position?.longitude
    }
  };

  const api = apiAllocation();
  const url = '/v1/offerdemand/acceptoffer';

  return api
    .url(url)
    .post(body)
    .json();
}

export function acceptOfferService(
  { itineraryId, demandId, id },
  index,
  channelType
) {
  if (itineraryId) {
    registerOfferListAcceptClick(index, itineraryId);
    return acceptOffer({
      itineraryId,
      offerChannel: ChannelTypes[channelType].lw
    })
      .then(resp => {
        registerOfferListAcceptSuccess(index, itineraryId);
        return resp;
      })
      .catch(responseError => {
        registerOfferListAcceptError(index, itineraryId);

        let message = 'Erro indeterminado';
        if ('errors' in responseError && responseError.errors.length) {
          message = responseError.errors[0].message;
        }

        throw new Error(message);
      });
  }

  registerOfferListAcceptRestClick(index, demandId);
  return acceptOfferRest(id, demandId, ChannelTypes[channelType].allocation)
    .then(resp => {
      registerOfferListAcceptRestSuccess(index, demandId);
      return resp;
    })
    .catch(err => {
      registerOfferListAcceptRestError(index, demandId);
      throw new Error(err.message);
    });
}
