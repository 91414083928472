import { makeStyles } from '@material-ui/core/styles';

const useRoundedBorder = makeStyles(() => ({
  root: {
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px'
  }
}));

// eslint-disable-next-line import/prefer-default-export
export { useRoundedBorder };
