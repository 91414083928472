import React from 'react';
import { statusCodes } from 'operations/update-status';
import { Typography } from '@material-ui/core';

const totalNumberOfPackagesToBeDelivered = packages =>
  packages.length -
  packages.filter(pkg => pkg.status.code === statusCodes.DELIVERED).length;

function formatTextAssignments(packages, pickups, assignmentsWaypoints = []) {
  const totalPkgs = totalNumberOfPackagesToBeDelivered(packages);
  const totalPickups = pickups.length;
  const totalActivities =
    totalPkgs + totalPickups + assignmentsWaypoints.length;

  const noActivity = (
    <Typography variant="h5">
      No momento, você não tem <b>nenhuma atividade</b> para fazer.
    </Typography>
  );
  const withActivity = (
    <Typography variant="h5">
      {' '}
      Você tem{' '}
      <b>
        {totalActivities} {totalActivities === 1 ? 'atividade' : 'atividades'}
      </b>{' '}
      para fazer
    </Typography>
  );

  return totalActivities > 0 ? withActivity : noActivity;
}

export default formatTextAssignments;
