import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function SurveySkeleton() {
  return (
    <>
      <Box mt={3}>
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} width="40%" />
      </Box>
      <Box mt={1.5} mb={5}>
        <Skeleton variant="text" height={24} />
        <Skeleton variant="text" height={24} />
        <Skeleton variant="text" height={24} width="60%" />
      </Box>
      {['reason_1', 'reason_2', 'reason_3'].map(key => (
        <Skeleton
          data-testid={`survey-skeleton-${key}`}
          key={key}
          component={Box}
          mt={1.5}
          variant="rect"
          height={60}
          borderRadius={12}
        />
      ))}
    </>
  );
}
