import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Divider,
  FormControl,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import sendEventToAnalytics from 'operations/firebase';
import { getReasonsForUncollectedVolumes } from 'operations/pickup';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import sharedPropTypes from 'view/shared-prop-types';
import Header from 'view/molecules/header';
import CustomRadioButtonGroup from 'view/molecules/radio-button-group';
import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';
import OpenFieldForm from 'view/templates/open-field-form';
import uncollectedVolumesReportMachine, {
  states,
  transitions
} from './machine';
import { ReactComponent as UnidentifiedIcon } from './icons/unidentified-icon.svg';
import UncollectedVolumesReportSkeleton from './skeleton';

export const texts = {
  title: itensQuantity => (
    <>
      {itensQuantity > 1 ? (
        <Typography variant="h6">
          <b>{itensQuantity} itens não identificados</b>
        </Typography>
      ) : (
        <Typography variant="h6">
          <b>{itensQuantity} item não identificado</b>
        </Typography>
      )}
    </>
  ),
  subtitle:
    'Identifique os itens ou informe o motivo que mais se aplica aos não identificados.',
  buttonBack: 'Voltar para identificação',
  multiModals: {
    title: 'Faltou algum item?',
    subtitle:
      'Se você não conseguiu coletar um ou mais itens, escolha o motivo abaixo.'
  },
  buttonConfirm: 'Informar',
  buttonSkip: 'Pular',
  openFieldForm: {
    subtitle:
      'Tudo bem. Se quiser, você pode dar mais detalhes do que aconteceu.',
    buttonText: 'Informar e prosseguir',
    infoBoxTexts: {
      title: 'É bom saber!',
      subtitle:
        'Informar o motivo ajuda a melhorar a experiência de retirar os itens.'
    }
  }
};

export default function UncollectedVolumesReport({
  pickup,
  uncollectedVolumes,
  onGoBack,
  onNextClick,
  userIdentification
}) {
  const history = useHistory();
  const { pickupCode } = useParams();
  const { colors, typography } = useTheme();
  const { pxToRem } = typography;
  const [uncollectedVolumesSaved, saveUncollectedVolumes] = usePersistedState(
    `${persistedStateKeys.pickupUncollectedVolumes}/${pickupCode}`,
    {}
  );
  const [reason, setReason] = useState(
    uncollectedVolumesSaved?.reasonKey || ''
  );

  const enabledLMCsForListOfPlannedVolumesWithMultimodals = useFeatureSwitch(
    featureSwitches.enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  const isListOfPlannedVolumesEnabledForMultimodals = featureSwitchEnabledForDriverLMC(
    enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  const isPickupWithMultiModals =
    parseInt(pickup?.assignments[0].scheduledVehicles, 10) > 1 &&
    isListOfPlannedVolumesEnabledForMultimodals;

  useEffect(() => {
    sendEventToAnalytics('uncollected_volumes_report_page', {
      pickup: pickup.pickupId,
      driverId: userIdentification
    });
  }, [pickup.pickupId, userIdentification]);

  const [currentState, send] = useMachine(uncollectedVolumesReportMachine, {
    actions: {
      goForNextPage: () => onNextClick(),
      backToPreviousPage: () => {
        setReason('');
        onGoBack();
      },
      confirmReason: context => {
        saveUncollectedVolumes({
          openField: context.openFieldAnswer,
          reasonKey: context.selectedReason.key,
          loggiKeys: uncollectedVolumes.map(v => v.loggiKey)
        });
      }
    },
    guards: {
      shouldClearPersistedState: () => {
        return (
          uncollectedVolumesSaved.length > 0 && uncollectedVolumes.length === 0
        );
      },
      shouldSkipThisPage: () => {
        return (
          uncollectedVolumesSaved.length === 0 &&
          uncollectedVolumes.length === 0
        );
      },
      shouldRedirectWhenComingBackFromAnotherPage: () => {
        // In Pickup flow we use "Replace" for go back action
        return history.action === 'REPLACE' || history.action === 'POP';
      }
    },
    services: {
      getReasons: async () => {
        const { reasons } = await getReasonsForUncollectedVolumes().json();
        return reasons;
      }
    }
  });

  const registerEvent = event => {
    sendEventToAnalytics(event, {
      pickup: pickup.pickupId,
      driverId: userIdentification
    });
  };

  return (
    <>
      {currentState.matches(states.showingOpenField) ? (
        <OpenFieldForm
          title={currentState.context.selectedReason.label}
          subtitle={texts.openFieldForm.subtitle}
          buttonText={texts.openFieldForm.buttonText}
          infoBoxTexts={{
            title: texts.openFieldForm.infoBoxTexts.title,
            subtitle: texts.openFieldForm.infoBoxTexts.subtitle
          }}
          initialValue={uncollectedVolumesSaved?.openField}
          onGoBack={() => send(transitions.backToReasonSelection)}
          onSubmit={openFieldAnswer =>
            send(transitions.confirm, { openFieldAnswer })
          }
        />
      ) : (
        <Box
          bgcolor={colors.neutrals.white.pure}
          color={colors.neutrals.typeface.primary}
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Header
            backButton={{ action: () => send(transitions.back) }}
            extraStyles={{
              position: 'sticky',
              backgroundColor: colors.neutrals.white.pure,
              top: 0,
              zIndex: 1
            }}
          />
          {currentState.matches(states.fetchingReasons) ||
          currentState.matches(states.confirming) ? (
            <UncollectedVolumesReportSkeleton />
          ) : (
            <>
              <Box px={4} pb={3}>
                <UnidentifiedIcon style={{ marginBottom: pxToRem(24) }} />
                {isPickupWithMultiModals ? (
                  <>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: pxToRem(16) }}
                    >
                      <b>{texts.multiModals.title}</b>
                    </Typography>
                    <Typography variant="subtitle">
                      {texts.multiModals.subtitle}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      {texts.title(uncollectedVolumes.length)}
                    </Typography>
                    <Typography variant="subtitle">{texts.subtitle}</Typography>
                  </>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    registerEvent(
                      'uncollected_volumes_report_back_button_clicked'
                    );
                    send(transitions.back);
                  }}
                  style={{ marginTop: pxToRem(16) }}
                >
                  {texts.buttonBack}
                </Button>
              </Box>
              <Divider />
              <Box px={4} py={3}>
                {currentState.matches(states.fetchingReasons) ||
                  (currentState.matches(states.confirming) && (
                    <UncollectedVolumesReportSkeleton />
                  ))}
                {currentState.matches(states.reasonSelection) && (
                  <FormControl fullWidth>
                    <CustomRadioButtonGroup
                      radioValues={currentState.context.reasons}
                      ariaLabel="pickup-volumes-reasons"
                      value={reason}
                      onValueChanged={setReason}
                    />
                  </FormControl>
                )}
              </Box>
            </>
          )}
          <Box
            px={4}
            py={2}
            mt="auto"
            bgcolor={colors.neutrals.white.pure}
            position="sticky"
            bottom={0}
            borderTop={`2px solid ${colors.neutrals.shapes.lighter}`}
          >
            <Button
              disabled={!reason}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={() => send(transitions.confirm, { reason })}
            >
              {currentState.matches(states.confirming) ? (
                <CircularProgress
                  style={{
                    width: 24,
                    height: 24,
                    color: colors.neutrals.white.pure
                  }}
                />
              ) : (
                <>{texts.buttonConfirm}</>
              )}
            </Button>
            <Button
              fullWidth
              size="large"
              color="primary"
              style={{ marginTop: pxToRem(12) }}
              onClick={() => {
                registerEvent('uncollected_volumes_report_skip_button_clicked');
                send(transitions.skip);
              }}
            >
              {texts.buttonSkip}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

UncollectedVolumesReport.propTypes = {
  pickup: sharedPropTypes.pickup.isRequired,
  uncollectedVolumes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      recipientName: PropTypes.string,
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  onGoBack: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  userIdentification: PropTypes.number.isRequired
};

UncollectedVolumesReport.url = '/v2/pacotes_nao_identificados';
