const browserGeolocation = window.navigator && window.navigator.geolocation;
const LAST_KNOWN_LOCATION = 'last_known_location';
const LOCATION_ERROR = 'location_error';

export const errorCodes = {
  permissionDenied: 1,
  positionUnavailable: 2,
  timeout: 3
};

/**
 * This will set an error object containing location error code and
 * message related to the error when get the geolocation position from
 * watchPosition function.
 *
 * @param {GeolocationPositionError}
 * @see {@link https://w3c.github.io/geolocation-api/#position_error_interface}
 */
function setLocationError(error) {
  const locationError = {
    code: error.code,
    message: error.message
  };
  localStorage.setItem(LOCATION_ERROR, JSON.stringify(locationError));
}

export { setLocationError };

/**
 * Clean position error data from
 * local storage.
 */
function clearLocationError() {
  localStorage.setItem(LOCATION_ERROR, null);
}

/**
 * This will never activate sensors to compute a new location,
 * and will only ever return a cached location by getCurrentPosition.
 *
 * @return {GeolocationCoordinates}
 * @see {@link https://w3c.github.io/geolocation-api/#coordinates_interface}
 */
function getLastKnownLocation() {
  const location = JSON.parse(localStorage.getItem(LAST_KNOWN_LOCATION));

  /**
   * location is only valid if it is recently max last known location time is 5 minutes
   * in milliseconds it is 300000
   */
  if (location && location.updated && Date.now() - location.updated <= 300000) {
    return location;
  }

  return null;
}

export { getLastKnownLocation };

/**
 * This will return the location error object saved in local storage
 *
 */
function getLocationError() {
  return JSON.parse(localStorage.getItem(LOCATION_ERROR));
}

export { getLocationError };

/**
 * JSON.stringify does not preserve any of the not-owned properties of the object,
 * it means properties inherited from Prototype will not be parsed, so we need
 * to extract the properties from coords to a new object
 *
 * @param {GeolocationCoordinates} coords
 * @see {@link https://w3c.github.io/geolocation-api/#coordinates_interface}
 */
function setLastKnownLocation(coords) {
  const parsed = {
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: coords.latitude,
    longitude: coords.longitude,
    speed: null,
    updated: Date.now()
  };

  localStorage.setItem(LAST_KNOWN_LOCATION, JSON.stringify(parsed));
}

export { setLastKnownLocation };

/**
 * Call watchPosition to start to get user geolocation
 * coordinates. It defines the callback functions to deal
 * with success and error while collecting position
 */
function setWatchPosition() {
  const positionCollectedWithSuccess = pos => {
    setLastKnownLocation(pos.coords);
    clearLocationError();
  };

  const positionError = err => {
    setLocationError(err);
  };

  const options = {
    enableHighAccuracy: false,
    timeout: 1000,
    maximumAge: 0
  };

  browserGeolocation.watchPosition(
    positionCollectedWithSuccess,
    positionError,
    options
  );
}

export { setWatchPosition };

/**
 * Interface to retrieve user lat/lng information used on `leve.updateStatus` request
 *
 * @param {function(float: lat, float: lng, [string: error message])} callback
 */
export default ({ callback = () => {} } = {}) => {
  const lastKnownLocation = getLastKnownLocation();

  if (lastKnownLocation !== null) {
    callback(lastKnownLocation.latitude, lastKnownLocation.longitude);
    return new Promise(resolve => resolve(lastKnownLocation));
  }

  const error = getLocationError();

  const hadPermissionDenied =
    error?.code === errorCodes.permissionDenied ||
    error?.code === errorCodes.positionUnavailable;

  if (hadPermissionDenied) {
    return new Promise((_, reject) => {
      const err = new Error(error?.message);
      err.code = error?.code;
      reject(err);
    });
  }

  callback('0', '0');

  return new Promise(resolve => {
    resolve({
      // keep same return interface
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: '0',
      longitude: '0',
      speed: null
    });
  });
};
