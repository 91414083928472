import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Box, Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useTheme } from '@material-ui/core/styles';

const hasProps = param => {
  return param && Object.keys(param).length > 0;
};

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function Header({
  backButton,
  helpButton,
  middleButton,
  backgroundIsDark,
  extraStyles
}) {
  const theme = useTheme();
  const { colors } = theme;

  return (
    <Box
      p={2}
      pt={3}
      display="flex"
      align-items="center"
      justifyContent="space-between"
      style={{ ...extraStyles }}
    >
      {hasProps(backButton) && (
        <IconButton
          onClick={backButton.action}
          data-testid="header-back-button"
        >
          <ArrowBack
            style={{
              color: backgroundIsDark
                ? colors.neutrals.white.pure
                : colors.neutrals.typeface.primary
            }}
          />
        </IconButton>
      )}
      {hasProps(middleButton) && (
        <Button
          onClick={middleButton.action}
          size="medium"
          data-testid="header-middle-button"
          style={{
            color: backgroundIsDark
              ? colors.neutrals.white.pure
              : colors.neutrals.typeface.primary,
            marginLeft: !hasProps(helpButton) ? 'auto' : '',
            marginRight: !hasProps(backButton) ? 'auto' : ''
          }}
        >
          {middleButton.text}
        </Button>
      )}
      {hasProps(helpButton) && (
        <IconButton
          onClick={helpButton.action}
          data-testid="header-help-button"
          style={!hasProps(backButton) ? { marginLeft: 'auto' } : {}}
        >
          <HelpOutlineIcon
            style={{
              color: backgroundIsDark
                ? colors.neutrals.white.pure
                : colors.neutrals.typeface.primary
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

Header.propTypes = {
  backButton: PropTypes.shape({
    action: PropTypes.func
  }),
  middleButton: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.func
  }),
  helpButton: PropTypes.shape({
    action: PropTypes.func
  }),
  backgroundIsDark: PropTypes.bool,
  extraStyles: PropTypes.shape({
    position: PropTypes.string
  })
};

Header.defaultProps = {
  backButton: null,
  middleButton: null,
  helpButton: null,
  backgroundIsDark: false,
  extraStyles: {}
};
