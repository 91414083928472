export default {
  TODAY: 'Hoje',
  TOMORROW: 'Amanhã',
  TO: 'até',
  CAPACITY_RESERVE: {
    V2: {
      TITLE: 'Oferta programada para',
      BUTTON_TEXT: 'Aceitar oferta programada'
    }
  }
};
