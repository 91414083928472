import { PopupButton } from '@typeform/embed-react';
import decamelizeKeys from 'decamelize-keys';
import React from 'react';
import { getCurrentUser } from 'operations/auth';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'view';
import { DRIVER_APP_DEEP_LINK } from 'view/templates/order-resume/order-resume';

export default function OpportunisticOfferSurvey() {
  const formId = `${process.env.REACT_APP_OPPORTUNISTIC_OFFER_SURVEY_FORM_ID}`;
  const driverEmail = getCurrentUser()?.email;
  const history = useHistory();
  const { earnings = 0 } = history.location;

  const hiddenFields = decamelizeKeys({
    driverEmail
  });

  return (
    <PopupButton
      id={formId}
      hidden={hiddenFields}
      open="load"
      autoClose
      hideFooter
      hideHeaders
      style={{ display: 'none' }}
      data-testid="opportunistic-offer-survey"
      onClose={() => {
        if (window?.driverAppBridge) {
          window.location = `${DRIVER_APP_DEEP_LINK}?earnings=${earnings}`;
        } else {
          history.push(PAGES.NO_ATTRIBUTION);
        }
      }}
    />
  );
}
