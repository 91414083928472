import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PopupButton } from '@typeform/embed-react';
import decamelizeKeys from 'decamelize-keys';
import PropTypes from 'prop-types';

export default function PickupSurvey({
  userIdentification,
  tag,
  pickupType,
  pickupCode,
  packagesIds,
  shipperName,
  onClose
}) {
  const history = useHistory();
  const formId = `${process.env.REACT_APP_PICKUP_SURVEY_FORM_ID}`;
  const hiddenFields = decamelizeKeys({
    userIdentification,
    pickupType,
    pickupOrderScheduleId: pickupCode,
    packagesIds,
    shipperName,
    tag
  });

  useEffect(() => {
    const unblock = history.block((_, action) => {
      const goBackAction = 'POP';
      return action !== goBackAction;
    });

    return () => {
      unblock();
    };
  }, [history]);

  return (
    <PopupButton
      id={formId}
      hidden={hiddenFields}
      open="load"
      autoClose
      hideFooter
      hideHeaders
      style={{ display: 'none' }}
      data-testid="pickup-survey-form"
      onClose={() => onClose()}
    />
  );
}

PickupSurvey.propTypes = {
  userIdentification: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  pickupType: PropTypes.number.isRequired,
  pickupCode: PropTypes.string,
  packagesIds: PropTypes.arrayOf(PropTypes.string),
  shipperName: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

PickupSurvey.defaultProps = {
  pickupCode: '',
  packagesIds: [],
  shipperName: ''
};

PickupSurvey.url = '/avaliacao';
