import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Typography,
  Drawer,
  IconButton,
  Snackbar
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from '@loggi/mar';
import { useRoundedBorder } from 'view/utils/styles';
import { ALERT_TYPES } from 'view/atoms/alert';
import { createErrorMessage } from 'view/utils';
import { ReactComponent as ErrorIcon } from './icons/error-generic-small.svg';
import { ReactComponent as WifiOffIcon } from './icons/no-wifi.svg';
import { ReactComponent as NoPhoneIcon } from './icons/call-disabled.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as SuccessIcon } from './icons/sucess.svg';
import { ReactComponent as CopyIcon } from './icons/click-copy.svg';

const SUCCESS_DATA = {
  title: 'Aviso em andamento',
  description: 'Nosso time foi avisado e você receberá uma ligação em breve',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <SuccessIcon />
};

const ERROR_DATA = {
  title: 'Eita! Algo deu errado',
  description: 'Você pode tentar novamente daqui a pouco',
  button: 'Fechar',
  buttonVariant: 'outlined',
  icon: <ErrorIcon />
};

const ERROR_NETWORK_DATA = {
  title: 'Eita! Algo deu errado',
  description: 'Você pode tentar novamente daqui a pouco',
  button: 'Fechar',
  buttonVariant: 'outlined',
  icon: <WifiOffIcon />
};

const ERROR_INVALID_PHONE_DATA = {
  title: 'Eita, esse número não existe!',
  description:
    'O recebedor pode ter adicionado um telefone que não existe ou é inválido.',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <NoPhoneIcon />
};

const ERROR_PHONE_DATA = {
  title: 'Eita! Algo deu errado',
  description:
    'Não foi possível se comunicar com o destinatário agora, mas você pode tentar novamente daqui a pouco.',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <NoPhoneIcon />
};

const UNAUTHORIZED_DATA = {
  title: 'O link de acesso expirou!',
  description:
    'Por isso, não é possível fazer ligações. Para resolver, entre em contato com a base e peça um novo link de acesso ao aplicativo.',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <WarningIcon />
};

const RETURN_LIST_NETWORK_ERROR_DATA = {
  title: '',
  description: 'Aconteceu algum erro para carregar o endereço da agência.',
  button: 'Voltar',
  buttonVariant: 'outlined',
  icon: <WarningIcon />
};

const FRAUD_DATA = {
  title: 'Opa! Algo deu errado, avise quem te atendeu',
  description:
    'Fale para a pessoa que está te entregando os pacotes entrar em contato com suporte da Loggi. ' +
    '\n\n Não leve os pacotes até que o problema seja resolvido.',
  button: 'Ok, entendi',
  buttonVariant: 'contained',
  icon: <WarningIcon />
};

const STATE_ENUM = {
  OPEN: 'OPEN',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ERROR_NETWORK: 'ERROR_NETWORK',
  ERROR_PHONE: 'ERROR_PHONE',
  ERROR_INVALID_PHONE: 'ERROR_INVALID_PHONE',
  UNAUTHORIZED: 'UNAUTHORIZED',
  CLOSED: 'CLOSED',
  CUSTOM: 'CUSTOM',
  RETURN_LIST_NETWORK_ERROR: 'RETURN_LIST_NETWORK_ERROR',
  FRAUD: 'FRAUD',
  isOpen: state => state !== STATE_ENUM.CLOSED
};

const STATE_ENUM_TO_DATA_MAP = {
  SUCCESS: SUCCESS_DATA,
  ERROR: ERROR_DATA,
  ERROR_NETWORK: ERROR_NETWORK_DATA,
  ERROR_PHONE: ERROR_PHONE_DATA,
  ERROR_INVALID_PHONE: ERROR_INVALID_PHONE_DATA,
  UNAUTHORIZED: UNAUTHORIZED_DATA,
  RETURN_LIST_NETWORK_ERROR: RETURN_LIST_NETWORK_ERROR_DATA,
  FRAUD: FRAUD_DATA
};

export const copyMessage = {
  successMessage: 'Texto copiado com sucesso!',
  errorMessage: 'Erro ao criar a mensagem de erro!'
};

function DrawerContent({ data, alertData, onClose, extraButton }) {
  const [sharedNotification, setSharedNotification] = useState(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState('');

  const handleErrorMessage = () => {
    const errorMessage = createErrorMessage(alertData?.message);
    setAlertErrorMessage(errorMessage);
  };

  useEffect(() => {
    handleErrorMessage();
  });

  const hideNotificationAfterTime = 3000;
  const handleOpenNotification = () => setSharedNotification(true);
  const handleCloseNotification = () => setSharedNotification(false);

  return (
    <Box px={2.5} pt={4} pb={2.5} color={colors.smoke[900]}>
      <Box textAlign="center">{data.icon}</Box>

      <Box pt={2.5} display="flex" justifyContent="center">
        <Typography variant="h5">{data.title}</Typography>
      </Box>
      <Box
        pt={2.5}
        color={colors.smoke[800]}
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
          {data.description}
        </Typography>
        {data.descriptionHtmlElement}
      </Box>
      {alertData && (
        <Box>
          <Box
            mt={2.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius={8}
            bgcolor={colors.smoke[50]}
            maxWidth="100%"
          >
            <Box
              display="flex"
              width="100%"
              pl={2}
              py={2}
              justifyContent="center"
              style={{ wordBreak: 'break-word' }}
            >
              {alertData.message}
            </Box>
            <CopyToClipboard
              text={alertErrorMessage}
              onCopy={handleOpenNotification}
            >
              <IconButton data-testid="copy-icon-button">
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Box>
      )}
      <Box pt={3}>
        {extraButton}
        <Button
          variant={data.buttonVariant}
          data-testid="main-button"
          color="primary"
          size="large"
          fullWidth
          onClick={() => {
            onClose();
          }}
        >
          {data.button}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!sharedNotification}
        onClose={handleCloseNotification}
        autoHideDuration={hideNotificationAfterTime}
      >
        <Box
          bgcolor={colors.root[0]}
          p={2.5}
          m={1.5}
          borderRadius={8}
          boxShadow={13}
          width="100%"
          display="flex"
          alignItems="center"
          color={ALERT_TYPES.SUCCESS.color}
        >
          <Box pr={2.5}>{ALERT_TYPES.SUCCESS.startAdornment}</Box>
          <Typography variant="body1">{copyMessage.successMessage}</Typography>
        </Box>
      </Snackbar>
    </Box>
  );
}

DrawerContent.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionHtmlElement: PropTypes.element,
    button: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string.isRequired,
    icon: PropTypes.node
  }).isRequired,
  alertData: PropTypes.shape({
    message: PropTypes.string,
    icon: PropTypes.node
  }),
  onClose: PropTypes.func.isRequired,
  extraButton: PropTypes.element
};

DrawerContent.defaultProps = {
  alertData: undefined,
  extraButton: null
};

function NotificationDrawer({
  setDrawerState,
  drawerState,
  extraButton,
  alertData,
  data,
  onClose
}) {
  const roundedBorder = useRoundedBorder();
  const drawerData = data || STATE_ENUM_TO_DATA_MAP[drawerState];
  const closeDrawer = () => {
    if (setDrawerState) {
      setDrawerState(STATE_ENUM.CLOSED);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={STATE_ENUM.isOpen(drawerState)}
      onClose={closeDrawer}
      classes={{ paper: roundedBorder.root }}
    >
      {STATE_ENUM.isOpen(drawerState) && (
        <DrawerContent
          extraButton={extraButton}
          data={drawerData}
          alertData={alertData}
          onClose={closeDrawer}
        />
      )}
    </Drawer>
  );
}

export {
  NotificationDrawer,
  STATE_ENUM,
  SUCCESS_DATA,
  ERROR_NETWORK_DATA,
  ERROR_DATA,
  ERROR_PHONE_DATA,
  ERROR_INVALID_PHONE_DATA,
  UNAUTHORIZED_DATA,
  RETURN_LIST_NETWORK_ERROR_DATA,
  FRAUD_DATA
};

NotificationDrawer.propTypes = {
  drawerState: PropTypes.string.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string,
    buttonVariant: PropTypes.string,
    icon: PropTypes.element
  }),
  alertData: PropTypes.shape({
    message: PropTypes.string,
    icon: PropTypes.node
  }),
  extraButton: PropTypes.element,
  onClose: PropTypes.func
};

NotificationDrawer.defaultProps = {
  data: null,
  extraButton: null,
  alertData: undefined,
  onClose: () => null
};
