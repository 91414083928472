import { colors } from '@loggi/mar';
import { makeStyles } from '@material-ui/core/styles';
import { messages } from 'hooks/use-assignment-list';
import { errorTypes } from 'operations/assignment-list/constants';

const tryAgainText = 'Tentar novamente';
const closeText = 'Fechar';

const networkErrorMessages = {
  title: 'Opa! Problema com a sua internet',
  description:
    'Confira sua conexão e tente outra vez. Se persistir, apresente essa tela ao suporte:',
  buttonConfirm: tryAgainText,
  buttonDismiss: closeText
};

export const TEXT = {
  ASSIGNMENT_LIST: 'Sua lista de atividades',
  LOADING: 'Carregando suas atividades...',
  EMPTY_ASSIGNMENT_LIST_TITLE: 'Sua lista ainda está vazia',
  EMPTY_ASSIGNMENT_LIST:
    'Assim que adicionarem novas entregas, elas aparecerão aqui.',
  BUTTON_ASK_FOR_OFFER_ROUTE: 'Roteirizar',
  BUTTON_ASK_FOR_OFFER_ROUTE_LOADING: 'Roteirizando...',
  BUTTON_READ_PACKAGES: 'Ler pacotes',
  BUTTON_NEED_HELP: 'Preciso de ajuda',
  BUTTON_READ_BARCODE: 'Ler código de barras',
  SEARCH_PACKAGE: 'Buscar um pacote',
  PACKAGE_STATUS_UPDATE_SYNC_FAIL: messages.packageStatusUpdateSyncFail,
  DEFAULT_RETURN_TIME: ''
};

export const DRAWER_FAIL_TO_UPDATE_TEXTS = {
  [errorTypes.networkError]: networkErrorMessages,
  [errorTypes.genericError]: {
    title: 'Opa! Problema ao atualizar a lista',
    description: 'Se persistir, apresente essa tela ao suporte:',
    buttonConfirm: tryAgainText,
    buttonDismiss: closeText
  }
};

export const DRAWER_FAIL_TO_FETCH_TEXTS = {
  [errorTypes.networkError]: networkErrorMessages,
  [errorTypes.storageError]: {
    title: 'Opa! Parece que você está com pouca memória em seu dispositivo',
    description:
      'Limpe a memória do seu dispositivo apagando fotos, mensagens ou aplicativos e tente outra vez.',
    buttonConfirm: tryAgainText
  },
  [errorTypes.genericError]: {
    title: 'Não foi possível carregar sua lista',
    description: 'Você pode tentar novamente daqui a pouco.',
    buttonConfirm: tryAgainText
  }
};

export const DRAWER_FAIL_TO_UPDATE_PACKAGE_TEXTS = {
  [errorTypes.networkError]: networkErrorMessages,
  [errorTypes.genericError]: {
    title: 'Opa! Problema ao atualizar o pacote',
    description: 'Se persistir, apresente essa tela ao suporte:',
    buttonConfirm: tryAgainText,
    buttonDismiss: closeText
  }
};

export const deliveryFlowEnum = {
  deliverToDC: 'deliver_to_dc',
  deliverToRecipient: 'deliver_to_recipient'
};

export const useStyles = makeStyles(({ typography }) => ({
  container: {
    backgroundImage: colors.gradients.sanches
  },
  containerCircularProgress: {
    color: colors.smoke[100]
  },
  card: {
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px'
  },
  tab: {
    border: `2px solid ${colors.smoke[100]}`,
    borderRadius: '3px',
    width: typography.pxToRem(52)
  },
  searchIcon: {
    color: colors.storm[300]
  },
  containerFooter: {
    backgroundImage: colors.gradients.whiteSky
  },
  buttonNeedHelp: {
    color: colors.red[500]
  },
  bottom: {
    position: 'absolute',
    bottom: '0px',
    width: '100%'
  }
}));
