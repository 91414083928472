import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function UncollectedVolumesReportSkeleton() {
  return (
    <>
      <Box px={4} pb={3}>
        <Skeleton variant="rect" height={36} width={35} />
        <Skeleton
          variant="rect"
          height={36}
          width="30%"
          component={Box}
          mt={2}
        />
        <Skeleton variant="rect" height={30} component={Box} mt={1.5} />
        <Skeleton
          variant="rect"
          height={50}
          width="20%"
          component={Box}
          mt={1.5}
        />
      </Box>
      <Divider />
      <Box px={4} py={2}>
        {['reason_1', 'reason_2', 'reason_3'].map((key, idx) => (
          <Skeleton
            key={key}
            data-testid={`unidentified-skeleton-${idx}`}
            component={Box}
            mt={1.5}
            variant="rect"
            height={60}
            borderRadius={12}
          />
        ))}
      </Box>
    </>
  );
}
