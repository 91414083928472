import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useSanchesBackground = makeStyles({
  root: {
    background: colors.gradients.sanches
  }
});
const useWhiteBackground = makeStyles({
  root: {
    background: colors.root[0]
  }
});
const useCardStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: `-${theme.spacing(2.5)}`
  }
}));

export { useSanchesBackground, useWhiteBackground, useCardStyles };
