import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { colors } from '@loggi/mar';
import {
  useFeatureSwitch,
  useRemoteConfig
} from '@loggi/firebase-feature-switches';
import { useHistory } from 'react-router-dom';
import PhoneBox from 'view/molecules/phone-box';
import SharedPropTypes from 'view/shared-prop-types';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import ConfirmDismissDrawer from 'view/molecules/confirm-dismiss-drawer';
import LoadingTemplate from 'view/templates/order-resume/loading-template';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import { ReactComponent as WifiOffIcon } from 'view/molecules/notification-drawer/icons/no-wifi.svg';
import { cancelPickup } from 'operations/pickup';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC,
  featureSwitchEnabledForPickupFlow,
  featureSwitchEnabledForPickupProblem,
  remoteConfigFlags
} from 'operations/feature-switches';
import FacadePhoto from 'view/pages/facade-photo';
import PickupProblemComplementInput from 'view/pages/pickup/problems/pickup-problem-complement-input';
import InfoBox from './info-box';
import messages from '../messages';
import { pickupFlowEnum } from '../constants';

const ERROR_ICON_MAP = {
  noWifi: <WifiOffIcon data-testid="network-error-icon" />,
  error: <ErrorOutlineIcon data-testid="error-icon" />
};

const STATES = {
  loading: 'loading',
  initial: 'initial',
  confirmingCancel: 'confirmingCancel',
  facadePhoto: 'facadePhoto',
  error: 'error',
  success: 'success'
};

const useConfirmCancelPickup = (
  pickup,
  problem,
  problemComplement,
  ignoreProxy,
  sendDriverLocationOnQuitPickup,
  requireFacadePhoto
) => {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const onCancelPickup = () => {
    cancelPickup({
      pickup,
      problemSlug: problem.slug,
      problemComplement,
      ignoreProxy,
      sendDriverPosition: sendDriverLocationOnQuitPickup
    }).then(result => {
      result
        .fetchError(() => {
          setError({
            errorMessage: messages.defaultErrors.network,
            errorIcon: 'noWifi'
          });
          setStatus(STATES.error);
        })
        .json(() => {
          if (!requireFacadePhoto) {
            setStatus(STATES.success);
          } else {
            setStatus(STATES.facadePhoto);
          }
        })
        .catch(err => {
          setStatus(STATES.error);
          const serverErrorMessages = err.json?.errors
            ? JSON.stringify(err.json?.errors)
            : messages.pickupProblemDetails.genericError;
          setError({
            errorMessage: serverErrorMessages,
            errorIcon: 'error'
          });
        });
    });
  };

  return { status, error, onCancelPickup, setStatus };
};

export default function PickupProblemDetails({ problem, pickup }) {
  const lmcsEnabledToCallAllocation = useFeatureSwitch(
    featureSwitches.callAllocationDirectly
  );
  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    lmcsEnabledToCallAllocation
  );
  const sendDriverLocationOnQuitPickup = useFeatureSwitch(
    featureSwitches.sendDriverLocationOnQuitPickup
  );
  const enablePickupFacadePhotoFS = useFeatureSwitch(
    featureSwitches.enablePickupFacadePhoto
  );
  const isFacadePhotoEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    enablePickupFacadePhotoFS
  );
  const enablePickupFacadePhotoForPickupFlowFS = useFeatureSwitch(
    featureSwitches.enablePickupFacadePhotoForPickupFlow
  );
  const isFacadePhotoEnabledForPickupFlow = featureSwitchEnabledForPickupFlow({
    pickupFlow: pickup.pickupFlow,
    featureSwitchValue: enablePickupFacadePhotoForPickupFlowFS
  });
  const problemSlugsThatRequireFacadePhoto = useRemoteConfig(
    remoteConfigFlags.problemSlugsThatRequireFacadePhoto.name,
    remoteConfigFlags.problemSlugsThatRequireFacadePhoto.defaultValue
  );
  const enablePickupProblemComplementFS = useFeatureSwitch(
    featureSwitches.enablePickupProblemComplement
  );
  const isProblemComplementEnabled = featureSwitchEnabledForPickupProblem({
    pickupProblem: problem.slug,
    featureSwitchValue: enablePickupProblemComplementFS
  });

  const requireFacadePhoto =
    isFacadePhotoEnabledForDriverLMC &&
    isFacadePhotoEnabledForPickupFlow &&
    problemSlugsThatRequireFacadePhoto[problem.slug];

  const [problemComplement, setProblemComplement] = useState('');

  const { status, error, onCancelPickup, setStatus } = useConfirmCancelPickup(
    pickup,
    problem,
    problemComplement,
    ignoreProxy,
    sendDriverLocationOnQuitPickup,
    requireFacadePhoto
  );
  const [drawerState, setDrawerState] = useState(DRAWER_STATE.CLOSED);

  const drawerData = {
    title: '',
    description: error?.errorMessage,
    button: messages.pickupProblemDetails.buttonErrorConfirm,
    buttonVariant: 'contained',
    icon: ERROR_ICON_MAP[(error?.errorIcon)]
  };

  const history = useHistory();

  const confirmingCancelIsDisabled =
    (!problemComplement && isProblemComplementEnabled) ||
    drawerState === STATES.loading;

  useEffect(() => {
    if (status === STATES.error) setDrawerState(DRAWER_STATE.CUSTOM);
    if (status === STATES.loading) setDrawerState(STATES.loading);
    if (status === STATES.success) {
      window?.driverAppBridge?.clearOrder();
      setDrawerState(STATES.initial);
    }
  }, [status]);

  const onGoBack = () => {
    setStatus(null);
    setDrawerState(DRAWER_STATE.CLOSED);
  };

  if (status === STATES.loading) return <LoadingTemplate />;

  if (status === STATES.facadePhoto) {
    return (
      <FacadePhoto
        onGoBack={onGoBack}
        facadePhotoContext={{
          pickupId: pickup.pickupId,
          problemSlug: problem.slug,
          pickupProblemEvidenceSignedUrl: pickup.pickupProblemEvidenceSignedUrl
        }}
      />
    );
  }

  return (
    <>
      <>
        <Box display="flex" flexDirection="column" height="100%">
          <Box mx={1} mt={3}>
            <IconButton onClick={() => history.goBack()} data-testid="back">
              <ArrowBack color="primary" />
            </IconButton>
          </Box>

          <Box flexGrow={1} overflow="auto">
            <Box mx={3} mt={2}>
              <Typography variant="h5">{problem.description}</Typography>
              {isProblemComplementEnabled && (
                <PickupProblemComplementInput
                  onChange={setProblemComplement}
                  problemComplement={problemComplement}
                />
              )}
              {!isProblemComplementEnabled && (
                <Box mt={1.25} color={colors.smoke[800]}>
                  <Typography>
                    {messages.pickupProblemDetails.subtitle}
                  </Typography>
                </Box>
              )}
              {pickup?.pickupFlow === pickupFlowEnum.useBarcodeBip &&
                pickup.shipper?.phone &&
                !isProblemComplementEnabled && (
                  <PhoneBox recipient={pickup.shipper} />
                )}
              <InfoBox
                title={messages.pickupProblemDetails.infoBoxTitle}
                infoText={messages.pickupProblemDetails.infoBoxSubtitle}
              />
            </Box>
          </Box>

          <Box mx={3.5} mb={2} mt={2.5}>
            <Button
              disabled={confirmingCancelIsDisabled}
              size="large"
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                setDrawerState(STATES.confirmingCancel);
              }}
            >
              {messages.pickupProblemDetails.buttonCancelPickup}
            </Button>
          </Box>
        </Box>
      </>

      <NotificationDrawer
        data={drawerData}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
      />

      <ConfirmDismissDrawer
        icon={<ErrorOutlineIcon />}
        data={messages.pickupProblemDetails.drawerConfirmPickupCancel}
        isOpen={drawerState === STATES.confirmingCancel}
        handleConfirmAction={onCancelPickup}
        handleDismissAction={() => {
          setDrawerState(DRAWER_STATE.CLOSED);
        }}
        isError={false}
      />
    </>
  );
}

PickupProblemDetails.propTypes = {
  problem: PropTypes.shape({
    slug: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  pickup: SharedPropTypes.pickup.isRequired
};

PickupProblemDetails.url = '/:problemCode';
