const messages = {
  pendingDeliverToDcTemplate: {
    multiplePendingPackages: 'pacotes com você.',
    singlePendingPackage: 'pacote com você.',
    pendingDescription:
      'Caso já tenha terminado a entrega, aguarde o time da agência receber para finalizar a rota.',
    continueDeliveriesButton: 'Continuar entrega'
  },
  pendingProtocol: {
    confirmDelivery: 'Confirme a entrega para poder finalizar.',
    pendingDescription:
      'Agora que já receberam os pacotes, confirme a entrega com o nome de quem te atendeu.',
    confirmDeliveryButton: 'Confirmar entrega'
  }
};

export default messages;
