import React from 'react';
import { PAGES } from 'view';
import { NearbyDCs } from 'view/pages/package-return/components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DEADLINE_DAY, TIME_MESSAGES } from './constants';

const DEADLINE_TIME_MESSAGES = {
  [DEADLINE_DAY.today.withTime]: time =>
    `${TIME_MESSAGES.today} ${TIME_MESSAGES.until} às ${time}`,
  [DEADLINE_DAY.today.default]: TIME_MESSAGES.today,
  [DEADLINE_DAY.nextWorkday.defaultUntil]: `${TIME_MESSAGES.until} o ${
    TIME_MESSAGES.nextWorkday
  }`,
  [DEADLINE_DAY.nextWorkday.defaultOn]: `no ${TIME_MESSAGES.nextWorkday}`
};

function getDeadlineDeliveryTimeMessage(dayOfWeek, deadlineDeliveryTime) {
  if (dayOfWeek < 6) {
    return (
      deadlineDeliveryTime || DEADLINE_TIME_MESSAGES[DEADLINE_DAY.today.default]
    );
  }
  return (
    deadlineDeliveryTime ||
    DEADLINE_TIME_MESSAGES[DEADLINE_DAY.nextWorkday.defaultUntil]
  );
}

function getReturnPointCloseTimeMessage(dayOfWeek, closeTime, defaultMessage) {
  if (dayOfWeek < 6) {
    return closeTime
      ? DEADLINE_TIME_MESSAGES[DEADLINE_DAY.today.withTime](closeTime)
      : DEADLINE_TIME_MESSAGES[DEADLINE_DAY.today.default];
  }
  return DEADLINE_TIME_MESSAGES[DEADLINE_DAY.nextWorkday[defaultMessage]];
}

const getNearbyDCsLink = () => `${PAGES.PACKAGE_RETURN}${NearbyDCs.url}`;

const messages = {
  newHowToReturn: {
    title: 'Siga os passos para devolver ainda hoje',
    subtitle: 'Siga os passos abaixo para ter sucesso!',
    steps: [
      'Confira o endereço e horário de devolução da base indicada para a devolução dos pacotes que estão com você.',
      'Ao chegar, apresente-se e avise que quer devolver alguns pacotes.',
      'Após o bipe e a confirmação da devolução, finalize a rota e veja seus ganhos.'
    ],
    seeNearbyDCsButtonText: 'Conferir informações da base'
  },
  howToReturn: {
    title: 'Saiba como devolver',
    subtitle: 'É só seguir os passos abaixo!',
    steps: [
      'Volte até a mesma agência que você pegou o pacote que quer devolver.',
      'Ao chegar, se apresente e solicite a devolução dos pacotes.',
      'Após o bipe e a confirmação da devolução, finalize a rota e veja seus ganhos.'
    ],
    dcReturnTimeAlert:
      'Atenção: O pacote tem que ser devolvido no mesmo dia que você coletou na agência, ok?',
    seeNearbyDCsButtonText: 'Ver agência para devolver',
    iHaveQuestionsAboutPackageReturnButtonText: 'Ainda tenho dúvidas',
    startDeliveryButton: 'Entregar pacotes'
  },
  questionsAboutPackageReturn: {
    title: 'Principais dúvidas sobre sua entrega',
    qAndAs: (dayOfWeek, returnPointCloseAt, deadlineDeliveryTime) => [
      {
        question: 'O que acontece se eu não entregar os pacotes?',
        answer: (
          <>
            <p>
              Para você receber o valor dos pacotes quando terminar de entregar,
              é preciso finalizar a entrega{' '}
              <strong>
                {getDeadlineDeliveryTimeMessage(
                  dayOfWeek,
                  deadlineDeliveryTime
                )}
              </strong>
              .
            </p>
            <p>
              Se não conseguir entregar, os pacotes devem ser devolvidos na
              agência indicada{' '}
              <strong>
                {getReturnPointCloseTimeMessage(
                  dayOfWeek,
                  returnPointCloseAt,
                  'defaultUntil'
                )}
              </strong>
              .
            </p>
            <p>
              Você não pode finalizar a entrega nem receber outras ofertas
              enquanto estiver com pacotes que precisam ser devolvidos.
            </p>
          </>
        )
      },
      {
        question: 'Quando preciso devolver os pacotes?',
        answer: (
          <p>
            Todos os pacotes que não foram entregues devem ser devolvidos{' '}
            <strong>
              {getReturnPointCloseTimeMessage(
                dayOfWeek,
                returnPointCloseAt,
                'defaultOn'
              )}
            </strong>{' '}
            na agência indicada.
          </p>
        )
      },
      {
        question: 'Onde eu posso devolver os pacotes?',
        answer: (
          <p>
            Encontre a melhor agência tocando em{' '}
            <strong>
              <Link to={getNearbyDCsLink()} style={{ textDecoration: 'none' }}>
                Ver agências para devolver{' '}
              </Link>
            </strong>
            e vamos te ajudar a chegar lá.
          </p>
        )
      },
      {
        question: 'Como aumentar minhas chances de entregar com sucesso? ',
        answer: (
          <>
            <p>
              Você pode ligar de graça pelo aplicativo para quem for receber o
              pacote pelo aplicativo e confirmar se tem alguém no endereço.
            </p>
            <p>
              Além disso, você pode organizar os pacotes na ordem que quiser
              entregar e tentar de novo, caso precisar.
            </p>
            <p>
              Mas, é bom lembrar: se você achar que não consegue entregar,
              devolva na agência indicada no aplicativo.
            </p>
          </>
        )
      }
    ]
  },
  nearbyDCs: {
    getTitle: size =>
      size > 1 ? 'Devolva em uma das bases' : 'Informações da devolução',
    getReturnUntilLabel: ({ from, until }) =>
      `Devolução das ${from} até ${until}`,
    howToGetThereLabelText: 'Como chegar \u00a0❯', // &nbsp; unicode
    finishShipmentButtonText: 'Finalizar entregas',
    closeDCMessageWeek:
      'Fique atento ao horário de funcionamento da base. Após o encerramento, só será possível devolver os pacotes amanhã.',
    closeDCMessageWeekend:
      'Fique atento ao horário de funcionamento da base. Após o encerramento, só será possível devolver os pacotes no próximo dia útil.',
    almostCloseDCMessageWeekend:
      'A base já está quase fechando. Se não conseguir entregar hoje, você precisa devolver os pacotes no próximo dia útil. Fique atento ao horário de devolução.',
    almostCloseDCMessageWeek:
      'A base já está quase fechando. Se não conseguir entregar hoje, você precisa devolver os pacotes amanhã. Fique atento ao horário de devolução.',
    getCloseDCTitle: 'Devolva os pacotes na base indicada',
    closedDCTitle: 'Essa base está fechada',
    closeDCTitle: 'E se a base estiver fechada?',
    closedDCMessageWeekend: `Você precisa devolver os pacotes no próximo dia útil. Fique atento ao horário de devolução.`,
    closedDCMessageWeek: `Você precisa devolver os pacotes amanhã. Fique atento ao horário de devolução.`,
    getCloseDCMessage:
      'Você precisa devolver os pacotes o mais cedo possível no próximo dia útil. Fique atento aos horários de devolução.',
    closeDCAlert:
      'Atenção! É impossível finalizar a entrega enquanto você tiver pacotes que precisam ser devolvidos.',
    newCloseDCAlert:
      'É bom lembrar: os pacotes devolvidos na base devem estar com a indicação de insucesso na entrega'
  }
};

export default messages;
export { DEADLINE_TIME_MESSAGES };
