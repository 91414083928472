import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function CustomFormLabel(props) {
  const { colors } = useTheme();
  const { checked } = props;

  if (checked)
    return (
      <FormControlLabel
        data-testid="custom-form-label-active"
        style={{
          backgroundColor: colors.primary.light,
          border: `1px solid ${colors.primary.pure}`
        }}
        checked={checked}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    );

  return (
    <FormControlLabel
      data-testid="custom-form-label"
      checked={checked}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  );
}

CustomFormLabel.propTypes = {
  checked: PropTypes.bool.isRequired
};
