import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import { Box, IconButton, Card } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { playActionCameraClick } from '../../sounds';
import cameraIcon from './assets/camera.svg';

const videoElementId = 'videoPhoto';

const message = {
  permission_guide_title: `Esse erro geralmente acontece por falta de permissão do uso da câmera. Tente os seguintes passos:`,
  permission_guide: `À direita da barra de endereço do Chrome, toque nos três pontos
  → Configurações → Configurações do site → Câmera → ativar a câmera.`
};

const CameraPhoto = ({
  videoReference,
  visible,
  errorMessage,
  setErrorMessage
}) => {
  const onUserMediaError = error => {
    const errorMsg = `camera load error: ${error} `;
    setErrorMessage(errorMsg);
  };

  return (
    <Box>
      {errorMessage && (
        <Box my={1.5}>
          <Alert severity="error">
            <b>{message.permission_guide_title}</b>
            <li>{message.permission_guide}</li>
            {errorMessage}
          </Alert>
        </Box>
      )}
      <Webcam
        data-testid="webcam-testid"
        style={{ display: visible ? 'block' : 'none' }}
        ref={videoReference}
        forceScreenshotSourceSize
        screenshotFormat="image/jpeg"
        id={videoElementId}
        onUserMediaError={onUserMediaError}
        audio={false}
        videoConstraints={{ facingMode: 'environment' }}
      />
    </Box>
  );
};

CameraPhoto.propTypes = {
  videoReference: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func
};

CameraPhoto.defaultProps = {
  errorMessage: null,
  setErrorMessage: () => {}
};

function PhotoTemplate({ imageData, setImageData }) {
  const webcamRef = useRef(null);
  const imgRef = useRef(null);
  const [errorMessage, setErrorMessage] = React.useState('');

  let videoSize = { width: 0, height: 0 };

  const handleCameraClick = () => {
    videoSize = {
      width: webcamRef.current.video.clientWidth,
      height: webcamRef.current.video.clientHeight
    };

    imgRef.current.width = videoSize.width;
    imgRef.current.height = videoSize.height;

    const data = webcamRef.current.getScreenshot();
    setImageData(data);
    playActionCameraClick();
  };

  return (
    <>
      <Card>
        <Box
          data-testid="container-photo"
          position="relative"
          display="flex"
          alignItems="center"
          height="25rem"
          justifyContent="space-around"
        >
          <CameraPhoto
            visible={imageData == null}
            videoReference={webcamRef}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <img
            data-testid="img-photo-testid"
            src={imageData}
            style={{ display: imageData == null ? 'none' : 'block' }}
            width="100%"
            height="100%"
            ref={imgRef}
            alt="Foto"
          />
        </Box>
      </Card>

      {imageData == null && (
        <Box display="flex" alignItems="center" justifyContent="space-around">
          <IconButton data-testid="take-picture" onClick={handleCameraClick}>
            <img
              data-testid="btn-photo-testid"
              src={cameraIcon}
              width="70px"
              height="70px"
              alt="Foto"
            />
          </IconButton>
        </Box>
      )}
    </>
  );
}

PhotoTemplate.propTypes = {
  setImageData: PropTypes.func.isRequired,
  imageData: PropTypes.string
};

PhotoTemplate.defaultProps = {
  imageData: null
};

export default PhotoTemplate;
