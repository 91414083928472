import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const BackHeader = ({ handleBackButtonClick }) => {
  return (
    <Box display="flex" justifyContent="space-between" pb={4}>
      <ArrowBackIcon data-testid="backButton" onClick={handleBackButtonClick} />
    </Box>
  );
};

BackHeader.propTypes = {
  handleBackButtonClick: PropTypes.func.isRequired
};

export default React.memo(BackHeader);
