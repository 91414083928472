import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import usePersistedState, {
  cleanPersistedState,
  persistedStateKeys
} from 'hooks/use-persisted-state';
import {
  clearClientDocument,
  setClientDocument
} from 'operations/client-document';
import {
  finishPickup,
  markPickupAsCompleted,
  registerReasonsForUncollectedVolumes,
  clearPickupHadTypedBarcodes
} from 'operations/pickup';
import PropTypes from 'prop-types';
import { PAGES } from 'view';
import {
  NotificationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/notification-drawer';
import { ReactComponent as ErrorOutlineIcon } from 'view/molecules/notification-drawer/icons/error-outline.svg';
import { Document } from 'view/pages/protocol/document';
import AlertDelivery from 'view/pages/alert-delivery-day';
import SharedPropTypes from 'view/shared-prop-types';
import { setShowOpportunisticSurvey } from 'operations/offer';
import { isEmptyObject } from 'view/utils';
import {
  featureSwitches,
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import FakeLoading from './fake-loading';
import messages from './messages';
import PickupSurveyDrawer from './pickup-survey-drawer';
import pickupSurveyIsEnabled from './pickup-switches';
import LoadingCompletePickupModal from './loading-complete-modal';

export const errorMessageDefault = 'Você pode tentar novamente daqui a pouco';

const states = {
  loading: 'loading',
  error: 'error',
  success: 'success',
  fraud: 'fraud'
};

// Equivalent to CompletePickupStatus, in the
// CompletePickupResponse.status field
const STATUS_FRAUD = 'COMPLETE_PICKUP_STATUS_SUSPECTED_FRAUD';

function useFinishPickup(
  pickup,
  shouldRegisterUncollectedVolumes,
  uncollectedVolumes
) {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const { pickupCode } = useParams();
  const [scanningStartingTime] = usePersistedState(
    `${persistedStateKeys.packageScanningStart}/${pickupCode}`,
    ''
  );
  const [distributionCenter, setDistributionCenter] = useState({});

  const ignoreProxy = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.callCompletePickupFromAllocation)
  );

  function callAPIService(packages, pickupProtocol, driverId) {
    setStatus(states.loading);
    const finishPickupPromise = finishPickup({
      pickup,
      packages,
      pickupProtocol,
      scanningStartingTime,
      ignoreProxy
    })
      .json(response => {
        if (response?.distributionCenter) {
          setDistributionCenter(response?.distributionCenter);
        }
        if (response?.opportunistic) {
          setShowOpportunisticSurvey();
        }
        cleanPersistedState(persistedStateKeys.pickupScannedPackages);
        cleanPersistedState(persistedStateKeys.packageScanningStart);
        clearPickupHadTypedBarcodes(pickupCode);
        setStatus(
          response?.status === STATUS_FRAUD ? states.fraud : states.success
        );
        markPickupAsCompleted(pickup);
      })
      .catch(err => {
        const notification = err?.json?.errors?.[0]?.[0];
        const notDriverNotification = !notification?.type;

        if (notDriverNotification) {
          setStatus(states.error);
          if (err?.json?.errors) {
            setError(err.json.errors[0].message);
          } else {
            setError(errorMessageDefault);
          }
        }
      });
    if (shouldRegisterUncollectedVolumes) {
      finishPickupPromise
        .then(() => {
          return registerReasonsForUncollectedVolumes({
            pickupId: pickup.pickupId,
            driverId,
            uncollectedVolumes
          }).json();
        })
        .then(() =>
          cleanPersistedState(persistedStateKeys.pickupUncollectedVolumes)
        )
        .catch(() =>
          cleanPersistedState(persistedStateKeys.pickupUncollectedVolumes)
        );
    }
    return finishPickupPromise;
  }

  return {
    status,
    error,
    callAPIService,
    distributionCenter
  };
}

export default function PickupSenderDocumentPage({
  driverId,
  packages,
  pickup
}) {
  const history = useHistory();
  const [alertLoadingCompleteOpen, setLoadingCompleteOpen] = useState(false);

  const [uncollectedVolumes] = usePersistedState(
    `${persistedStateKeys.pickupUncollectedVolumes}/${pickup.pickupCode}`,
    {}
  );
  const driversLMCsEnabledToNewPickupFlowByRelation = useFeatureSwitch(
    featureSwitches.enableNewPickupScannerFlowForDriverByRelation
  );
  const isNewScannerFlowEnabledForRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    driversLMCsEnabledToNewPickupFlowByRelation
  );
  const driversLMCsToUncollectedVolumesReport = useFeatureSwitch(
    featureSwitches.enableNewPickupScannerFlowPhaseThree
  );
  const uncollectedVolumesReportEnabledForLMC = featureSwitchEnabledForDriverLMC(
    driversLMCsToUncollectedVolumesReport
  );
  const shouldRegisterUncollectedVolumes =
    uncollectedVolumesReportEnabledForLMC &&
    isNewScannerFlowEnabledForRelation &&
    uncollectedVolumes?.loggiKeys?.length > 0;

  const { status, error, callAPIService, distributionCenter } = useFinishPickup(
    pickup,
    shouldRegisterUncollectedVolumes,
    uncollectedVolumes
  );
  const [drawerState, setDrawerState] = useState(DRAWER_STATE.CLOSED);
  const [alertDeliveryDayOpen, setAlertDeliveryDayOpen] = useState(false);
  const [pickupSurveyOpen, setPickupSurveyOpen] = useState(false);

  const drawerData = {
    title: messages.pickupProblemDetails.title,
    description: error,
    button: messages.pickupProblemDetails.buttonErrorConfirm,
    buttonVariant: 'contained',
    icon: <ErrorOutlineIcon />
  };

  const onComplete = useCallback(() => {
    clearClientDocument();
    history.push(PAGES.PACKAGE_LIST);
  }, [history]);

  const pickupSurveyEnabled = pickupSurveyIsEnabled();

  useEffect(() => {
    if (status === states.error) setDrawerState(DRAWER_STATE.CUSTOM);
    if (status === states.fraud) setDrawerState(DRAWER_STATE.FRAUD);
    if (
      status === states.success &&
      !pickupSurveyEnabled &&
      isEmptyObject(distributionCenter)
    ) {
      onComplete();
    }
    if (status === states.success && !isEmptyObject(distributionCenter)) {
      setLoadingCompleteOpen(true);
    }
    if (status === states.success && !isEmptyObject(distributionCenter)) {
      setAlertDeliveryDayOpen(true);
    }
    if (
      status === states.success &&
      pickupSurveyEnabled &&
      isEmptyObject(distributionCenter)
    ) {
      setPickupSurveyOpen(true);
    }
  }, [status, pickupSurveyEnabled, distributionCenter, onComplete]);

  if (status === states.loading) return <FakeLoading />;

  const onCloseLoadingComplete = () => {
    setLoadingCompleteOpen(false);
    setAlertDeliveryDayOpen(true);
  };

  const onCloseAlertDeliveryDay = () => {
    setAlertDeliveryDayOpen(false);

    if (pickupSurveyEnabled) {
      setPickupSurveyOpen(true);
    } else {
      onComplete();
    }
  };

  return (
    <>
      <Document
        isDelivery={false}
        onGoBack={history.goBack}
        onConfirm={({ name, document }) => {
          // We need to store the client document locally because in case of error api response
          // the form is erased due to the component re rendering
          setClientDocument(name, document);

          callAPIService(
            packages,
            {
              name,
              document
            },
            driverId
          );
        }}
      />
      <LoadingCompletePickupModal
        isOpen={alertLoadingCompleteOpen}
        onClose={onCloseLoadingComplete}
      />
      <NotificationDrawer
        data={drawerState === DRAWER_STATE.FRAUD ? null : drawerData}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        onClose={drawerState === DRAWER_STATE.FRAUD ? onComplete : null}
      />
      <AlertDelivery
        isOpen={alertDeliveryDayOpen}
        onClose={onCloseAlertDeliveryDay}
        distributionCenter={distributionCenter}
      />
      <PickupSurveyDrawer isOpen={pickupSurveyOpen} closeDrawer={onComplete} />
    </>
  );
}

PickupSenderDocumentPage.propTypes = {
  driverId: PropTypes.string.isRequired,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      recipientName: PropTypes.string,
      barcode: PropTypes.string
    }).isRequired
  ).isRequired,
  pickup: SharedPropTypes.pickup.isRequired
};

PickupSenderDocumentPage.url = '/protocolo';
