import {
  cleanPickupStatesBasedOnAssignmentList,
  mergeAssignmentListWithPickupStates
} from 'operations/pickup/pickup-state-db';
import { cleanOptimizedPackagesBasedOnPackages } from 'operations/routing-optimization';
import { cleanupWarningRecords } from 'view/templates/package-details/mailbox-delivery/warning-storage';
import {
  mergePackageList,
  cleanMutationsBasedOnPackageList
} from '../update-status';
import { errorTypes } from './constants';
import getPosition from '../geolocation';
import getAssignmentListService from './assignment-list-service';
import db from './assignment-list-db';

const { saveAssignmentList, getAssignmentList: getAssignmentListFromDb } = db;

/**
 * @see {mergePackageList, cleanMutationsBasedOnPackageList} infra/database/draft-db
 * @param {Array} packageList
 * @returns {Promise<Array>} containing a list of packages object after the merge with offline requests
 */
async function mergePendingList(
  packageList,
  listDeliveredPackages,
  considerDeliveredPackagesFromBackend
) {
  await cleanMutationsBasedOnPackageList(packageList);
  const mergedPackageList = await mergePackageList({
    packageList,
    listDeliveredPackages,
    considerDeliveredPackagesFromBackend
  });
  return mergedPackageList;
}

/**
 * @typedef {Object} PackageWithMutation
 *
 * @property {string} Package.packageId
 * @property {string} Package.barcode
 * @property {string} Package.assignedTo
 * @property {string} Package.companyName
 * @property {string} Package.offerId
 *
 * @property {Object} Package.status
 * @property {number} Package.status.code
 * @property {string} Package.status.description
 * @property {string} Package.status.updated
 *
 * @property {Object} Package.recipient
 * @property {string} Package.recipient.name
 * @property {string} Package.recipient.phone
 *
 * @property {Object} Package.destination
 * @property {string} Package.destination.addressStreet
 * @property {string} Package.destination.addressNumber
 * @property {string} Package.destination.addressComplement
 * @property {string} Package.destination.city
 * @property {string} Package.destination.state
 * @property {string} Package.destination.vicinity
 * @property {string} Package.destination.zipCode
 * @property {number} Package.destination.lat
 * @property {number} Package.destination.lng
 * @property {number} Package.destination.distance
 *
 * @property {Object} Package.safeDelivery
 * @property {number} Package.safeDelivery.safeDeliveryId
 * @property {string} Package.safeDelivery.tokenHash
 * @property {string} Package.safeDelivery.salt
 *
 * @property {string?} Package.mutationSyncState
 *
 * @property {string?} Package.assignmentIndex
 * @property {string} Package.assignmentDisplayId
 */

/**
 * @typedef {Object} Pickup
 * @property {Object} Pickup.destination
 * @property {string} Pickup.destination.addressStreet
 * @property {string} Pickup.destination.addressNumber
 * @property {string} Pickup.destination.addressComplement
 * @property {string} Pickup.destination.city
 * @property {string} Pickup.destination.state
 * @property {string} Pickup.destination.vicinity
 * @property {string} Pickup.destination.zipCode
 * @property {number} Pickup.destination.lat
 * @property {number} Pickup.destination.lng
 * @property {number} Pickup.destination.distance
 * @property {Object} Pickup.shipper
 * @property {string} Pickup.shipper.name
 * @property {string} Pickup.shipper.phone
 * @property {string} Pickup.pickupCode
 */

/**
 * @typedef {Object} AssignmentListWithMutationResponse
 * @property {Array<PackageWithMutation>} AssignmentListWithMutationResponse.packages
 * @property {Array<Pickup>} AssignmentListWithMutationResponse.pickups
 * @property {string} AssignmentListWithMutationResponse.deliveryFlow
 * Filter packages sorting by distance of current position
 * Filter pickups to be made by the user
 * @return {Promise<AssignmentListWithMutationResponse>} Returns an array
 * of formed by an array of packages with mutations, array of pickups and
 * delivery flow indicating if it is a delivery for a recipient or a distribution center.
 */

function getGeolocationErrorType(error) {
  return error.code === 2
    ? errorTypes.geolocationLostPermission
    : errorTypes.geolocationError;
}

const itineraryID = 'itineraryId';

export const setItineraryID = serverResponse =>
  localStorage.setItem(itineraryID, serverResponse.itineraryId);

export const getItineraryID = () => localStorage.getItem(itineraryID);

function makeParsedError(type, message) {
  return { type, message };
}

async function getPositionWithErrorParsing() {
  return getPosition().catch(error => {
    const type = getGeolocationErrorType(error);
    throw makeParsedError(type, error.message);
  });
}

async function getAssignmentListServiceWithErrorParsing(...input) {
  return getAssignmentListService(...input).catch(error => {
    const { networkError, genericError } = errorTypes;
    const type = error.isFetchError ? networkError : genericError;
    const message = error.message || error.response.message;
    throw makeParsedError(type, message);
  });
}

async function saveAssignmentListWithErrorParsing(...input) {
  return saveAssignmentList(...input).catch(error => {
    throw makeParsedError(errorTypes.storageError, error.message);
  });
}

async function updateAssignmentList() {
  const position = await getPositionWithErrorParsing();
  const updatedList = await getAssignmentListServiceWithErrorParsing({
    pos: position
  });
  await saveAssignmentListWithErrorParsing(updatedList);

  setItineraryID(updatedList);
  cleanupWarningRecords(updatedList.packages);
}

async function makeAssignmentListReturn({
  assignmentList,
  error = null,
  listDeliveredPackages,
  considerDeliveredPackagesFromBackend
}) {
  const packages = assignmentList
    ? await mergePendingList(
        assignmentList.packages,
        listDeliveredPackages,
        considerDeliveredPackagesFromBackend
      )
    : null;

  const [pkg] = packages || [];

  cleanPickupStatesBasedOnAssignmentList(assignmentList);
  cleanOptimizedPackagesBasedOnPackages(packages);

  return {
    ...mergeAssignmentListWithPickupStates(assignmentList),
    packages,
    deliveryFlow: pkg?.deliveryFlow,
    hasReturnSteps: pkg?.hasReturnSteps,
    error
  };
}

export async function fetchAndSaveNewAssignmentList(
  listDeliveredPackages = false,
  considerDeliveredPackagesFromBackend = false
) {
  return updateAssignmentList()
    .then(() => null) // return error as null
    .catch(error => error) // do not throw
    .then(async error =>
      makeAssignmentListReturn({
        // here we need to hit the db because `saveAssignmentList`
        // saves some metadata as `localTime`
        assignmentList: await getAssignmentListFromDb(),
        error,
        listDeliveredPackages,
        considerDeliveredPackagesFromBackend
      })
    );
}

export default async function getAssignmentListWithMutation(
  listDeliveredPackages = false,
  considerDeliveredPackagesFromBackend = false
) {
  const assignmentList = await getAssignmentListFromDb();
  if (assignmentList) {
    return makeAssignmentListReturn({
      assignmentList,
      listDeliveredPackages,
      considerDeliveredPackagesFromBackend
    });
  }

  return fetchAndSaveNewAssignmentList(
    listDeliveredPackages,
    considerDeliveredPackagesFromBackend
  );
}

/**
 * Get a package on the PackageList by its id
 * @param {number} packageId
 * @returns {PackageWithMutation}
 */
export async function getPackageById(packageId, listDeliveredPackages = false) {
  const assignmentList = await getAssignmentListWithMutation(
    listDeliveredPackages
  );
  const { packages } = assignmentList;
  const desiredPackage = packages.find(pkg => pkg.packageId === packageId);

  return desiredPackage;
}
