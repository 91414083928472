import React from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';

import * as deliveryTimeStorage from 'operations/delivery-storage';
import { getPickupDate, getDayOfWeek } from 'view/utils';

import useReminder from './use-reminder';
import PackageReturnReminder from './package-return-reminder';

import { TEXT } from '../constants';

const isDeliveryToRecipient = pkg =>
  pkg.deliveryFlow === 'deliver_to_recipient';

function getDeadlineDeliveryTime(pkg = {}) {
  if (pkg.deadlineDeliveryTime) {
    return pkg.deadlineDeliveryTime;
  }

  if (isDeliveryToRecipient(pkg)) {
    return TEXT.DEFAULT_RETURN_TIME;
  }

  return undefined;
}

export default function Reminders({
  packages,
  deliveryFlow,
  hasReturnSteps,
  children
}) {
  const deadlineDeliveryTime = getDeadlineDeliveryTime(packages[0]);
  deliveryTimeStorage.setDeadlineDeliveryTime(deadlineDeliveryTime);

  const [shouldShowReminder, closeReminder] = useReminder();
  if (shouldShowReminder && hasReturnSteps) {
    const pickupDate = getPickupDate(packages);

    const dayOfWeek = getDayOfWeek(pickupDate);

    deliveryTimeStorage.setDayOfWeek(dayOfWeek);

    if (deliveryFlow !== 'deliver_to_dc') {
      return (
        <PackageReturnReminder onOk={closeReminder} pickupDate={pickupDate} />
      );
    }
  }

  return <>{children}</>;
}

Reminders.defaultProps = {
  deliveryFlow: '',
  hasReturnSteps: false
};

Reminders.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  deliveryFlow: PropTypes.string,
  hasReturnSteps: PropTypes.bool,
  children: PropTypes.node.isRequired
};
