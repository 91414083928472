import CameraClick from './medias/camera_click.wav';
import SuccessBeep from './medias/beep_success.wav';
import ErrorBeep from './medias/beep_error.wav';

function playAudio(audioFile) {
  const audio = new Audio(audioFile);
  audio.play().catch(error => ({ error }));
}

export function playActionCameraClick() {
  playAudio(CameraClick);
}

export function playSuccessBeep() {
  playAudio(SuccessBeep);
}

export function playErrorBeep() {
  playAudio(ErrorBeep);
}
