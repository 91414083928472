import { Machine, assign } from 'xstate';
import {
  persistedStateKeys,
  cleanPersistedState
} from 'hooks/use-persisted-state';

export const states = {
  initial: 'initial',
  fetchingReasons: 'fetchingReasons',
  reasonSelection: 'reasonSelection',
  showingOpenField: 'showingOpenField',
  back: 'back',
  skip: 'skip',
  redirect: 'redirect',
  confirming: 'confirming'
};

export const transitions = {
  backToReasonSelection: 'backToReasonSelection',
  back: 'back',
  skip: 'skip',
  choose: 'choose',
  redirect: 'redirect',
  confirm: 'confirm'
};

export default Machine(
  {
    id: 'uncollected-volumes-report',
    initial: states.initial,
    context: {
      reasons: []
    },
    states: {
      [states.initial]: {
        on: {
          '': [
            {
              cond: 'shouldClearPersistedState',
              actions: ['clearUncollectedVolumesState'],
              target: states.redirect
            },
            {
              cond: 'shouldSkipThisPage',
              target: states.redirect
            },
            {
              target: states.fetchingReasons
            }
          ]
        }
      },
      [states.fetchingReasons]: {
        invoke: {
          src: 'getReasons',
          onDone: {
            target: states.reasonSelection,
            actions: ['setReasons']
          },
          onError: {
            target: states.redirect
          }
        },
        on: {
          [transitions.back]: states.back
        }
      },
      [states.reasonSelection]: {
        on: {
          '': {
            cond: context => {
              return context.reasons.length === 0;
            },
            target: states.redirect
          },
          [transitions.back]: states.back,
          [transitions.confirm]: [
            {
              cond: (context, event) => {
                const { reason } = event;
                const selectedReason = context.reasons.find(
                  r => r.key === reason
                );
                return selectedReason && selectedReason.hasOpenField;
              },
              actions: assign({
                selectedReason: (context, event) =>
                  context.reasons.find(r => r.key === event.reason)
              }),
              target: states.showingOpenField
            },
            {
              target: states.confirming,
              actions: [
                assign({
                  selectedReason: (context, event) =>
                    context.reasons.find(r => r.key === event.reason)
                }),
                'confirmReason'
              ]
            }
          ],
          [transitions.skip]: {
            target: states.skip
          }
        }
      },
      [states.showingOpenField]: {
        on: {
          [transitions.backToReasonSelection]: states.reasonSelection,
          [transitions.confirm]: {
            target: states.confirming,
            actions: [
              assign({
                openFieldAnswer: (_, event) => event.openFieldAnswer
              }),
              'confirmReason'
            ]
          }
        }
      },
      [states.redirect]: {
        on: {
          '': [
            {
              cond: 'shouldRedirectWhenComingBackFromAnotherPage',
              target: states.back
            },
            {
              target: states.skip
            }
          ]
        }
      },
      [states.confirming]: {
        after: {
          300: states.skip
        }
      },
      [states.skip]: {
        entry: ['goForNextPage'],
        type: 'final'
      },
      [states.back]: {
        entry: ['backToPreviousPage'],
        type: 'final'
      }
    }
  },
  {
    actions: {
      setReasons: assign({
        reasons: (_, event) => {
          if (!event.data) return [];
          return event.data
            .sort((a, b) => a.visualPosition - b.visualPosition)
            .map(reason => {
              return {
                key: reason.key,
                label: reason.title,
                hasOpenField: reason.hasOpenField
              };
            });
        }
      }),
      clearUncollectedVolumesState: () => {
        cleanPersistedState(persistedStateKeys.pickupUncollectedVolumes);
      }
    }
  }
);
