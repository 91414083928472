import React, { useState } from 'react';
import PropTypes, { string, func, number } from 'prop-types';

import md5 from 'md5';
import { Button, Box, Typography, Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { capitalizeString } from 'view/text-formatters';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import InputVerificationCode from './input-verification-code';

const STATE_ENUM = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  isOpen: state => state !== STATE_ENUM.CLOSED
};

const TEXTS = {
  title: 'Pacote',
  nextTitle: 'para',
  subTitle: 'Peça o código de confirmação para concluir essa entrega.',
  buttonSendToken: 'Confirmar código',
  tokenError: 'Eita, esse código está errado. Confirme e tente de novo.'
};

const tokenValidation = ({
  insertedToken,
  salt,
  tokenHash,
  onConfirm,
  setNotificationMessage,
  countState,
  setCountState,
  insertedTokenAttempts
}) => {
  const insertedTokenLower = insertedToken.toLowerCase();

  // Md5 creates a hash that is the concatenation of the token with the salt.
  // If this hash is the same as that of the backend, it means that the token is valid.
  const resultTokenHashInserted = md5(`${insertedTokenLower}${salt}`);

  if (resultTokenHashInserted === tokenHash) {
    onConfirm(insertedTokenAttempts);
  } else {
    setNotificationMessage(TEXTS.tokenError);
    setCountState(countState + 1);
  }

  insertedTokenAttempts.push(insertedTokenLower);
};

const tokenProblems = ({ setDrawerState, setDrawerStateProblem }) => {
  setDrawerState(STATE_ENUM.CLOSED);
  setDrawerStateProblem(STATE_ENUM.OPEN);
};

function SafeDeliveryTokenDrawer({
  drawerState,
  setDrawerState,
  setDrawerStateProblem,
  countState,
  setCountState,
  onConfirm,
  safeDelivery,
  companyName,
  recipient,
  insertedTokenAttempts
}) {
  const TOKEN_LENGTH = 4;

  const NUMBER_OF_ATTEMPTS = 4;

  const { salt, tokenHash } = safeDelivery;

  const [insertedToken, setInsertedToken] = useState('');

  const [notificationMessage, setNotificationMessage] = useState('');

  const sendToken = () => {
    tokenValidation({
      insertedToken,
      tokenHash,
      salt,
      onConfirm,
      setNotificationMessage,
      countState,
      setCountState,
      insertedTokenAttempts
    });
    if (countState >= NUMBER_OF_ATTEMPTS) {
      tokenProblems({
        setDrawerState,
        setDrawerStateProblem
      });
    }
  };

  return (
    <>
      <Alert
        open={!!notificationMessage}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...ALERT_TYPES.ERROR}
      />

      <Drawer
        data-testid="safe-delivery-token-drawer"
        anchor="bottom"
        open={STATE_ENUM.isOpen(drawerState)}
        onClose={() => setDrawerState(STATE_ENUM.CLOSED)}
        PaperProps={{ square: false, component: Box, mb: -3 }}
      >
        <Box pb={3}>
          {STATE_ENUM.isOpen(drawerState) && (
            <Box px={2.5} py={2.5} color={colors.smoke[900]}>
              <Box>
                <IconButton
                  data-testid="close-icon-button"
                  onClick={() => setDrawerState(STATE_ENUM.CLOSED)}
                >
                  <Close color="primary" />
                </IconButton>
              </Box>

              <Box py={1} display="flex" justifyContent="center">
                <Typography variant="h6" align="center">
                  {`${TEXTS.title} ${companyName} ${TEXTS.nextTitle}`}
                </Typography>
              </Box>

              <Box py={1} display="flex" justifyContent="center">
                <Typography variant="h5" align="center">
                  {capitalizeString(recipient.name)}
                </Typography>
              </Box>

              <Box
                py={1}
                color={colors.smoke[800]}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="body1" align="center">
                  {TEXTS.subTitle}
                </Typography>
              </Box>

              <Box>
                <Box pb={2}>
                  <Box
                    py={4}
                    display="flex"
                    justifyContent="center"
                    data-testid="input-verification-code"
                  >
                    <InputVerificationCode
                      length={TOKEN_LENGTH}
                      onChange={setInsertedToken}
                    />
                  </Box>

                  <Button
                    disabled={insertedToken.length !== TOKEN_LENGTH}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={sendToken}
                  >
                    {TEXTS.buttonSendToken}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}

SafeDeliveryTokenDrawer.propTypes = {
  drawerState: string.isRequired,
  setDrawerState: func.isRequired,
  setDrawerStateProblem: func,
  countState: number,
  setCountState: func,
  onConfirm: func,
  safeDelivery: PropTypes.shape({
    safeDeliveryId: string,
    tokenHash: string,
    salt: string
  }),
  companyName: string.isRequired,
  recipient: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string
  }),
  insertedTokenAttempts: PropTypes.arrayOf(PropTypes.string)
};

SafeDeliveryTokenDrawer.defaultProps = {
  setDrawerStateProblem: () => {},
  countState: null,
  setCountState: null,
  onConfirm: null,
  safeDelivery: {},
  recipient: {},
  insertedTokenAttempts: []
};

export { SafeDeliveryTokenDrawer, STATE_ENUM, TEXTS, tokenValidation };
