import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DeviceWithQrCode } from 'view/atoms/illustrations/device-with-qr-code.svg';
import {
  Box,
  Typography,
  Button,
  IconButton,
  MobileStepper
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import messages from './messages';
import useStyles from './styles';

export default function BipInform({ onBackButtonClicked, onContinue }) {
  const classes = useStyles();

  return (
    <Box
      py={2.5}
      px={0.5}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <IconButton onClick={onBackButtonClicked} data-testid="bip-inform-back">
          <ArrowBack color="primary" />
        </IconButton>
      </Box>

      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <DeviceWithQrCode />

        <Box pb={1.25} display="flex">
          <Typography variant="h5" align="center">
            {messages.pickupBipInform.title}
          </Typography>
        </Box>
        <Typography variant="subtitle2" align="center">
          {messages.pickupBipInform.description}
        </Typography>
      </Box>

      <Box alignItems="center" justifyContent="center" display="flex">
        <MobileStepper
          variant="dots"
          steps={2}
          position="static"
          activeStep={0}
          className={classes.root}
        />
      </Box>

      <Box pt={2} px={2.5}>
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          data-testid="btn-start-bip"
          onClick={onContinue}
        >
          {messages.pickupBipInform.nextButton}
        </Button>
      </Box>
    </Box>
  );
}

BipInform.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBackButtonClicked: PropTypes.func.isRequired
};

BipInform.url = '/instrucoes_da_coleta';
