import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';

import {
  Box,
  IconButton,
  Button,
  Typography,
  Fade,
  Divider,
  CircularProgress
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Error } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import askAssignmentListRoute from 'operations/assignment-list/assignment-list-offer-route';
import { useSnackbar } from 'notistack';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import { setScrollToTop } from 'view/utils';

import { errorTypes } from 'operations/assignment-list/constants';
import { useStyles, TEXT } from './constants';
import formatTextAssignments from './utils';

export const notificationMessages = {
  askForOfferRouteSuccess: 'Pronto, lista roteirizada com sucesso!',
  askForOfferRouteFail:
    'Não foi possível roteirizar sua lista no momento, tente mais tarde!'
};

export function AlertIcon() {
  return <Error style={{ color: colors.smoke[900] }} fontSize="inherit" />;
}

export function AskForOfferRouteBox({ successCallback }) {
  const [disableButton, setDisableButton] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const thisButton = useRef();

  const handleStickButton = () => {
    const { current } = thisButton;
    if (current) {
      const { top } = current.parentNode.getBoundingClientRect();
      const { innerHeight } = window;
      const isButtonVisible = top + 32 < innerHeight;
      current.style.position = isButtonVisible ? 'relative' : 'fixed';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleStickButton);

    return () => {
      window.removeEventListener('scroll', handleStickButton);
    };
  }, []);

  const onAskForOfferRouteClick = async () => {
    setDisableButton(true);

    const error = await askAssignmentListRoute();

    if (error) {
      if (error.type === errorTypes.networkError) {
        showSnackbar({
          message: notificationMessages.askForOfferRouteFail,
          variant: 'error',
          enqueueSnackbar
        });
        setDisableButton(false);
      } else {
        showSnackbar({
          message: error.message,
          variant: 'info',
          enqueueSnackbar
        });
      }
    } else {
      showSnackbar({
        message: notificationMessages.askForOfferRouteSuccess,
        variant: 'successSnackbar',
        enqueueSnackbar
      });
      successCallback();
    }
    setDisableButton(false);
  };

  return (
    <>
      <Box
        justifyContent="center"
        display="flex"
        p={2}
        data-testid="askForOfferRouteBox"
      >
        <Box ref={thisButton} position="fixed" zIndex={1} bottom={0} p={2}>
          <Button
            data-testid="askForOfferRouteBoxButton"
            variant="contained"
            startIcon={
              disableButton ? <CircularProgress size={20} /> : <RefreshIcon />
            }
            style={{
              background: disableButton
                ? colors.smoke[300]
                : colors.gradients.sanches,
              color: colors.root[0]
            }}
            onClick={onAskForOfferRouteClick}
            disabled={disableButton}
          >
            {disableButton
              ? TEXT.BUTTON_ASK_FOR_OFFER_ROUTE_LOADING
              : TEXT.BUTTON_ASK_FOR_OFFER_ROUTE}
          </Button>
        </Box>
      </Box>
      <Divider />
    </>
  );
}
AskForOfferRouteBox.propTypes = {
  successCallback: PropTypes.func.isRequired
};

export function BackToTopButton() {
  const minScreenHeightPosition = 500;
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleVisibleButton = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);

    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
  }, []);

  function BackToTop() {
    return (
      <Box zIndex={1} position="fixed" bottom={1} right={1} p={2}>
        <IconButton
          data-testid="backToTopButton"
          variant="outlined"
          style={{
            backgroundColor: colors.smoke[900],
            color: colors.root[0]
          }}
          onClick={setScrollToTop}
        >
          <ArrowUpwardIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Fade in={scrollPosition > minScreenHeightPosition}>
      <Box>
        <BackToTop />
      </Box>
    </Fade>
  );
}

export function GradientContainer({ children }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      minHeight="100%"
      flexDirection="column"
      className={classes.container}
    >
      {children}
    </Box>
  );
}
GradientContainer.propTypes = { children: PropTypes.node.isRequired };

export function ContainerHeaderInfo({ children }) {
  return (
    <Box
      px={2.5}
      py={4.5}
      display="flex"
      alignItems="flex-end"
      bgcolor={colors.root[0]}
    >
      {children}
    </Box>
  );
}
ContainerHeaderInfo.propTypes = { children: PropTypes.node.isRequired };

export function OverlayingCard({ children }) {
  return (
    <Box py={1} flexGrow={1} bgcolor={colors.root[0]}>
      {children}
    </Box>
  );
}
OverlayingCard.propTypes = { children: PropTypes.node.isRequired };

export function ProgressInfo({
  packages,
  pickups,
  assignmentsWaypoints,
  updateList
}) {
  return (
    <>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="left">
          {updateList}
        </Box>
        <Box pb={1.5} pt={1}>
          <Typography variant="h5">
            {formatTextAssignments(packages, pickups, assignmentsWaypoints)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

ProgressInfo.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  pickups: SharedPropTypes.pickups.isRequired,
  updateList: PropTypes.node,
  assignmentsWaypoints: SharedPropTypes.assignmentsWaypoints
};
ProgressInfo.defaultProps = {
  assignmentsWaypoints: [],
  updateList: null
};
