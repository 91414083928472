import {
  func,
  shape,
  oneOf,
  oneOfType,
  string,
  number,
  arrayOf
} from 'prop-types';
import { mutationStates } from 'models/package-mutation';

const postalAddress = shape({
  addressLines: arrayOf(string)
});

const correiosAddress = shape({
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cep: string,
  cidade: string,
  uf: string
});

const coordinates = shape({
  latitude: number,
  longitude: number
});

const loggiAddress = shape({
  postalAddress,
  correiosAddress,
  coordinates
});

const destination = shape({
  addressStreet: string,
  addressNumber: string,
  addressComplement: string,
  city: string,
  state: string,
  vicinity: string,
  zipCode: string,
  lat: number,
  lng: number
});

const shipper = shape({
  name: string.isRequired,
  phone: string
});

const safeDeliveryRestriction = shape({
  safeDeliveryId: string,
  tokenHash: string,
  salt: string
});

const pkg = shape({
  packageId: string,
  companyName: string,
  trackingKey: string,
  destination,
  addressDestination: loggiAddress,
  status: shape({
    code: number,
    description: string,
    updated: string
  }),
  mutationSyncState: oneOf(Object.values(mutationStates)),
  assignmentIndex: string,
  assignmentDisplayId: string,
  safeDelivery: safeDeliveryRestriction
});

const pickup = shape({
  destination: destination.isRequired,
  shipper: shipper.isRequired,
  pickupCode: string.isRequired
});

const problemDetails = shape({
  id: number.isRequired
});

const deliveryFacadePhotoContext = shape({
  pkg: pkg.isRequired,
  status: number,
  name: string,
  document: string,
  tokenAttempts: arrayOf(string),
  problem: problemDetails
});

const pickupProblemEvidenceSignedUrl = shape({
  signedUrl: string.isRequired,
  signedUrlContentTypeHeader: string.isRequired
});

const pickupFacadePhotoContext = shape({
  pickupId: number.isRequired,
  problemSlug: string.isRequired,
  pickupProblemEvidenceSignedUrl
});

const facadePhotoContext = oneOfType([
  deliveryFacadePhotoContext,
  pickupFacadePhotoContext
]);

const packages = arrayOf(pkg);

const pickups = arrayOf(pickup);

const assignmentContact = shape({
  name: string.isRequired,
  howToFind: string,
  phoneNumber: string.isRequired
});

const assignmentCargo = shape({
  code: string,
  shipperName: string,
  recipientName: string,
  destination: loggiAddress
});

const assignmentFlowEnum = {
  assignmentFlowInvalid: 'ASSIGNMENT_FLOW_INVALID',
  assignmentFlowDeliveryToDc: 'ASSIGNMENT_FLOW_DELIVERY_TO_DC',
  assignmentFlowDeliveryToRecipient: 'ASSIGNMENT_FLOW_DELIVERY_TO_RECIPIENT',
  assignmentFlowPickupAtShipper: 'ASSIGNMENT_FLOW_PICKUP_AT_SHIPPER',
  assignmentFlowPickupAtDc: 'ASSIGNMENT_FLOW_PICKUP_AT_DC'
};

const assignment = shape({
  pickupCode: string,
  contact: assignmentContact,
  cargos: arrayOf(assignmentCargo),
  flow: oneOf(Object.values(assignmentFlowEnum))
});

const assignments = arrayOf(assignment);

const assignmentWaypoint = shape({
  destination: loggiAddress,
  deadlineTime: string,
  index: number,
  displayId: string,
  assignments,
  waypointId: string,

  actions: shape({
    goToDetails: func
  })
});

const assignmentsWaypoints = arrayOf(assignmentWaypoint);

const addressTypesEnum = {
  PACKAGE_DESTINATION: 'package_destination',
  WALLY_V2: 'wally_v2'
};

const addressTypes = oneOf(Object.values(addressTypesEnum));

export default {
  destination,
  package: pkg,
  packages,
  pickup,
  pickups,
  facadePhotoContext,
  assignment,
  assignments,
  assignmentWaypoint,
  assignmentsWaypoints,
  addressTypesEnum,
  addressTypes,
  loggiAddress,
  assignmentCargo,
  assignmentContact,
  assignmentFlowEnum
};
