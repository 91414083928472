import React from 'react';
import { useMachine } from '@xstate/react';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import {
  cleanPersistedState,
  persistedStateKeys
} from 'hooks/use-persisted-state';
import { goTo } from 'operations/history';
import { cleanLastOptimization } from 'operations/routing-optimization/routing-optimization-db';
import { getItineraryID } from 'operations/assignment-list/assignment-list';
import {
  getShowOpportunisticSurvey,
  setOpportunisticSurveyAnswered
} from 'operations/offer';

import orderResumeMachine, { STATES } from './machine';
import LoadingTemplate from './loading-template';
import PendingDeliveryTemplate from './pending-delivery-template';
import PendingDeliverToDcTemplate from './pending-deliver-to-dc-template';
import PendingSyncTemplate from './pending-sync-template';
import ErrorTemplate from './error-template';

export const DRIVER_APP_DEEP_LINK =
  'https://driver-app/open/order-resume-activity';

export function shipmentHasFinished({
  earnings,
  isGoogleMapsSurveyEnabled,
  isOpportunisticOfferSurveyEnabled
} = {}) {
  cleanPersistedState(persistedStateKeys.pickupScannedPackages);
  cleanPersistedState(persistedStateKeys.packageScanningStart);

  const itineraryId = getItineraryID();
  const hasItinerary = () => itineraryId !== '0';
  const showOpportunisticOfferSurvey = () =>
    isOpportunisticOfferSurveyEnabled && getShowOpportunisticSurvey();

  if (isGoogleMapsSurveyEnabled && hasItinerary()) {
    goTo.googleMapsSurvey(earnings);
  } else if (showOpportunisticOfferSurvey()) {
    setOpportunisticSurveyAnswered();
    goTo.opportunisticOfferSurvey(earnings);
  } else if (window?.driverAppBridge) {
    cleanLastOptimization();
    window.location = `${DRIVER_APP_DEEP_LINK}?earnings=${earnings}`;
  } else if (process.env.REACT_APP_DRIVERS_URL) {
    cleanLastOptimization();
    window.location.href = process.env.REACT_APP_DRIVERS_URL;
  } else {
    cleanLastOptimization();
    goTo.noAtribution();
  }
}

export default function OrderResume() {
  const isListDeliveredPackagesEnabledForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByCompanyRelation
    )
  );

  const isListDeliveredPackagesEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByLastMileCompany
    )
  );

  const isEnableSendDataToInsider = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enableSendDataToInsider)
  );

  const isListDeliveredPackagesEnabledForDriver =
    isListDeliveredPackagesEnabledForCompanyRelation &&
    isListDeliveredPackagesEnabledForDriverLMC;

  const [current, send] = useMachine(
    orderResumeMachine.withContext({
      clearPackagesMutationAfterFinishItinerary: isListDeliveredPackagesEnabledForDriver,
      sendDataToInsider: isEnableSendDataToInsider
    })
  );

  const isGoogleMapsSurveyEnabled = useFeatureSwitch(
    featureSwitches.googleMapsSurvey
  );

  const isOpportunisticOfferSurveyEnabled = useFeatureSwitch(
    featureSwitches.opportunisticOfferSurvey
  );

  if (current.value === STATES.success) {
    shipmentHasFinished({
      earnings: current.context.earnings,
      isGoogleMapsSurveyEnabled,
      isOpportunisticOfferSurveyEnabled
    });
  }

  const retryFinishItinerary = () => send('RETRY');

  return (
    <>
      {(current.matches(STATES.loading) ||
        current.matches(STATES.clearStorage) ||
        current.matches({ pending: STATES.checkingDB }) ||
        current.matches({ pendingDcDelivery: STATES.checkingDB })) && (
        <LoadingTemplate />
      )}
      {(current.matches({ pending: STATES.pendingSync }) ||
        current.matches({ pendingDcDelivery: STATES.pendingSync })) && (
        <PendingSyncTemplate
          pendingSyncPackages={current.context.pendingSyncPackages}
        />
      )}
      {current.matches({ pending: STATES.pendingDelivery }) && (
        <PendingDeliveryTemplate
          pendingDeliveryPackages={current.context.pendingDeliveryPackages}
        />
      )}
      {current.matches({ pendingDcDelivery: STATES.pendingDcPackages }) && (
        <PendingDeliverToDcTemplate
          pendingPackages={current.context.pendingDeliveryPackages}
        />
      )}
      {current.matches(STATES.failure) && (
        <ErrorTemplate
          response={current.context.response}
          onRetry={retryFinishItinerary}
        />
      )}
      {current.matches(STATES.pendingProtocol) && (
        <PendingDeliverToDcTemplate pendingPackages={0} />
      )}
    </>
  );
}
