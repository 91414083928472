export default {
  FOR_TODAY: 'Pra hoje',
  WE_DONT_FIND: 'Não achamos ',
  IN_YOUR_LIST: ' na sua lista',
  EMPTY_SEARCH_INSTRUCTIONS:
    'Busque por outras informações da etiqueta, ou encontre o pacote lendo o código de barras.',
  BUTTON_READ_BARCODE: 'Ler código de barras',
  DELIVER: 'Entregar',
  TODAY: 'hoje',
  UNTIL: 'até',
  DEFAULT_RETURN_TIME: 'às 22h',
  NEXT_WEEKDAY: 'do próximo dia útil.'
};
