import React, { useState, useEffect } from 'react';
import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box } from '@material-ui/core';
import { BarcodeFormat } from '@zxing/library';
import {
  useFeatureSwitch,
  getSplittedValuesFromFS
} from '@loggi/firebase-feature-switches';
import { featureSwitches } from 'operations/feature-switches';
import { checkBarcodes } from 'operations/pickup';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import BarcodeReaderTemplate, {
  playSuccessBeep,
  playErrorBeep
} from 'view/templates/barcode-reader';
import SharedPropTypes from 'view/shared-prop-types';
import PackageScanning from './package-scanning';
import BarcodeInput, { drawerBarcodeInputStates } from './pickup-barcode-input';
import TEXTS from './messages';
import { packageError } from './constants';
import PackageScanningForMultipleBarcodes from './package-scanning-for-multiple-barcodes';
import PackageNotIntegratedDrawerError from './package-not-integrated-error-drawer';

const notificationInitialState = {
  message: '',
  type: ''
};

const findPackageByBarcodes = (packages, expectedBarcodes) => {
  return packages.find(pkg => pkg.barcodes === expectedBarcodes);
};

const isNotEmpty = list => !!list.length;

export default function PickupScanner({
  initialScannedBarcodes,
  initialScannedPackages,
  saveScannedPackages,
  onBarcodesCheckSuccess,
  onGoBack,
  persistedStateKey,
  allowedFormats,
  pickup,
  setOpenInstructions
}) {
  const enableRemovePackageWhenBarcodeIsDeleted = useFeatureSwitch(
    featureSwitches.enableRemovePackageWhenBarcodeIsDeleted
  );
  const enableUseScannerFormatFromFS = useFeatureSwitch(
    featureSwitches.enableUseScannerFormatsFromFS
  );
  const possibleFormatFSValues = useFeatureSwitch(
    featureSwitches.pickupScannerPossibleFormats
  );

  const scannerPossibleFormatValues = getSplittedValuesFromFS(
    possibleFormatFSValues
  ).filter(value => value in BarcodeFormat);

  const [notification, setNotification] = useState(notificationInitialState);
  const [barcodes, setBarcodes] = useState(initialScannedBarcodes);
  const [drawerBarcodeInputState, setDrawerBarcodeInputState] = useState(
    drawerBarcodeInputStates.closed
  );
  const [readedBarcode, setReadedBarcode] = useState({});
  const [deletedBarcode, setDeletedBarcode] = useState('');
  const [scanMultiBarcodesVisible, setScanMultiBarcodesVisible] = useState(
    false
  );
  const [
    packageNotIntegratedDrawerVisible,
    setPackageNotIntegratedDrawerVisible
  ] = useState(false);
  const [currentMultibarcodes, setCurrentMultibarcodes] = useState([]);
  const [, setScanningStart] = usePersistedState(
    `${persistedStateKeys.packageScanningStart}/${persistedStateKey}`,
    ''
  );

  const [packages, setPackages] = useState(initialScannedPackages);
  const barcodesTypesEnum = {
    TYPED: 'TYPED',
    AZTEC: 'AZTEC',
    CODABAR: 'CODABAR',
    CODE_39: 'CODE_39',
    CODE_93: 'CODE_93',
    CODE_128: 'CODE_128',
    DATA_MATRIX: 'DATA_MATRIX',
    EAN_8: 'EAN_8',
    EAN_13: 'EAN_13',
    ITF: 'ITF',
    MAXICODE: 'MAXICODE',
    PDF_417: 'PDF_417',
    QR_CODE: 'QR_CODE',
    RSS_14: 'RSS_14',
    RSS_EXPANDED: 'RSS_EXPANDED',
    UPC_A: 'UPC_A',
    UPC_E: 'UPC_E',
    UPC_EAN_EXTENSION: 'UPC_EAN_EXTENSION'
  };

  useEffect(() => {
    if (!initialScannedBarcodes.length) {
      setScanningStart(new Date().toISOString());
    }
  }, [initialScannedBarcodes, setScanningStart]);

  const onPackagesAdded = returnedPackages => {
    const currentPackages = returnedPackages.map(
      pkg => findPackageByBarcodes(packages, pkg.barcodes) || pkg
    );
    if (isNotEmpty(returnedPackages)) {
      return setPackages([...packages, ...returnedPackages]);
    }

    return setPackages(currentPackages);
  };

  const onPackageRemoved = removedPackage => {
    setPackages(
      packages.filter(
        pkg => pkg.barcodes.toString() !== removedPackage.barcodes.toString()
      )
    );
  };

  const removeFromBarcodeState = multibarcodeToRemove => {
    return barcodes.filter(
      packageBarcodes =>
        packageBarcodes.toString() !==
        multibarcodeToRemove.map(barcode => barcode.content).toString()
    );
  };

  const logEventBarcodeFormat = barcodeAdded => {
    /**
     * Register event only for barcodes different that
     * the values configured in FS possibleFormatFSValues
     */
    if (
      barcodeAdded.format &&
      barcodeAdded.format !== barcodesTypesEnum.TYPED &&
      scannerPossibleFormatValues.length > 0 &&
      !scannerPossibleFormatValues.includes(barcodeAdded.format)
    ) {
      firebase.analytics().logEvent('barcode_format', {
        barcode: barcodeAdded.content,
        format: barcodeAdded.format
      });
    }
  };

  const onCheckMultibarcodeAction = (multibarcode, response) => {
    if (response.action === 'BARCODE_CHECK_ACTION_SCAN_OTHER_BARCODE') {
      setBarcodes(removeFromBarcodeState(multibarcode));

      if (multibarcode.length === 1) {
        setPackageNotIntegratedDrawerVisible(true);
      }

      setCurrentMultibarcodes(multibarcode);
      setNotification(notificationInitialState);
    }
  };

  const onCheckBarcodes = (
    barcodesToCheck,
    isCheckingAllBarcodeList = false
  ) => {
    const companyId = pickup?.shipper?.companyId;
    const pickupCode = pickup?.pickupCode;
    return checkBarcodes({ barcodes: barcodesToCheck, companyId, pickupCode })
      .fetchError(error => {
        setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
        if (barcodesToCheck.length === 1) {
          const pkgWithNetworkError = {
            barcodes: barcodesToCheck[0].map(({ content }) => content),
            error: packageError.networkError
          };
          setPackages([pkgWithNetworkError, ...packages]);
          return;
        }
        const errorMessage = `${
          TEXTS.pickupScanner.networkErrorNotification
        } Erro: ${error.message}`;

        setNotification({
          message: errorMessage,
          type: 'ERROR'
        });
        playErrorBeep();
        throw Error(errorMessage);
      })
      .json(({ barcodesPackagesCheck }) => {
        if (
          drawerBarcodeInputState === drawerBarcodeInputStates.checkingBarcode
        ) {
          setDrawerBarcodeInputState(drawerBarcodeInputStates.idle);
        }

        const thereAreBarcodesWithErrors = barcodesPackagesCheck.some(
          item => !!item.error
        );
        const thereAreBarcodesWithAction = barcodesPackagesCheck.some(
          item => !!item.action
        );

        onPackagesAdded(barcodesPackagesCheck);

        if (
          thereAreBarcodesWithErrors &&
          (!thereAreBarcodesWithAction || isCheckingAllBarcodeList)
        ) {
          setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
          setNotification({
            message: `${TEXTS.pickupScanner.packageNotFoundNotitification}`,
            type: 'ERROR'
          });
          playErrorBeep();
        } else if (isCheckingAllBarcodeList) {
          onBarcodesCheckSuccess(barcodesPackagesCheck);
        } else {
          logEventBarcodeFormat(barcodesToCheck[0]);
          onCheckMultibarcodeAction(
            barcodesToCheck[0],
            barcodesPackagesCheck[0]
          );
        }
      })
      .catch(error => {
        setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
        const errorMessage = error.message
          ? error.message
          : TEXTS.pickupScanner.genericErrorNotification;
        setNotification({
          message: errorMessage,
          type: 'ERROR'
        });
        playErrorBeep();
      });
  };

  const onBarcodeAdded = newBarcode => {
    setNotification(notificationInitialState);
    logEventBarcodeFormat(newBarcode);
    if (
      currentMultibarcodes &&
      currentMultibarcodes.some(
        currentBarcodes => currentBarcodes.content === newBarcode.content
      )
    ) {
      setNotification({
        message:
          TEXTS.packageScanningForMultipleBarcodes.errorBarcodeAlreadyIncluded,
        type: 'ERROR'
      });
      playErrorBeep();
      setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
      return;
    }
    const multibarcodeToCheck = [...currentMultibarcodes, newBarcode];
    if (
      barcodes.some(
        barcode =>
          barcode.length === multibarcodeToCheck.length &&
          barcode.every(a => multibarcodeToCheck.some(b => a === b.content))
      )
    ) {
      setNotification({
        message: TEXTS.pickupScanner.errorBarcodeAlreadyIncluded,
        type: 'ERROR'
      });
      playErrorBeep();
      setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
      return;
    }
    playSuccessBeep();
    setNotification({
      message: TEXTS.pickupScanner.notificationPackageAdded,
      type: 'SUCCESS'
    });
    setBarcodes(oldBarcodesList => [
      ...oldBarcodesList,
      multibarcodeToCheck.map(({ content }) => content)
    ]);
    setScanMultiBarcodesVisible(false);
    setCurrentMultibarcodes([]);

    onCheckBarcodes([multibarcodeToCheck]);
  };

  const onBarcodeDeleted = packageToBeRemoved => {
    setBarcodes(
      barcodes.filter(
        barcode => barcode.toString() !== packageToBeRemoved.barcodes.toString()
      )
    );
    if (enableRemovePackageWhenBarcodeIsDeleted) {
      onPackageRemoved(packageToBeRemoved);
    }
    setNotification({
      message: TEXTS.pickupScanner.notificationPackageRemoved,
      type: 'SUCCESS'
    });
  };

  useEffect(() => {
    if (Object.keys(readedBarcode).length) {
      onBarcodeAdded(readedBarcode);
      setReadedBarcode({});
    }
    if (deletedBarcode) {
      onBarcodeDeleted(deletedBarcode);
      setDeletedBarcode('');
    }
  }, [readedBarcode, deletedBarcode, barcodes]); // eslint-disable-line react-hooks/exhaustive-deps

  const packagesMapByBarcode = packages.reduce((map, pkg) => {
    return {
      ...map,
      [pkg.barcodes]: pkg
    };
  }, {});

  const scannedPackages = barcodes.map(packageBarcodes => {
    return (
      packagesMapByBarcode[packageBarcodes] || { barcodes: packageBarcodes }
    );
  });

  // The linter wants us to include `scannedPackages` in the
  // dependency array, but because it's a new array every render,
  // it re-renders infinetely if we include it. Instead, the inclusion
  // of `barcodes` and `packages`, the variables used to build the
  // `scannedPackages` array, serves the same purpose as including it
  // on the dependency array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => saveScannedPackages(scannedPackages), [
    barcodes,
    packages,
    saveScannedPackages
  ]);

  const getPossibleFormats = () => {
    if (enableUseScannerFormatFromFS) {
      return scannerPossibleFormatValues.map(value => BarcodeFormat[value]);
    }
    return allowedFormats;
  };

  return (
    <Box
      bgcolor={colors.smoke[900]}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      data-testid="pickup-scanner"
    >
      {!!notification.message && (
        <Alert
          message={notification.message}
          startAdornment={
            ALERT_TYPES[notification?.type || 'SUCCESS']?.startAdornment
          }
          color={ALERT_TYPES[(notification?.type)]?.color}
          open={!!notification.message}
          onClose={() => setNotification(notificationInitialState)}
        />
      )}
      <BarcodeReaderTemplate
        onGoBack={() => onGoBack(scannedPackages)}
        onBarcodeRead={newBarcode => {
          setReadedBarcode(newBarcode);
          firebase.analytics().logEvent('add_packages_method', {
            add_type: 'camera_beep'
          });
        }}
        onTypeBarcodeClick={() =>
          setDrawerBarcodeInputState(drawerBarcodeInputStates.idle)
        }
        allowedFormats={getPossibleFormats()}
        showHelpButton
        onClickHelpButton={setOpenInstructions}
      />
      {scanMultiBarcodesVisible ? (
        <PackageScanningForMultipleBarcodes
          barcodesContent={currentMultibarcodes}
        />
      ) : (
        <PackageScanning
          scannedPackages={scannedPackages}
          onCheckPackages={onCheckBarcodes}
          onDelete={barcode => {
            setDeletedBarcode(barcode);
            firebase.analytics().logEvent('deleted_package', {
              page_title: 'beep_ui'
            });
          }}
        />
      )}
      <BarcodeInput
        onClose={() => {
          setDrawerBarcodeInputState(drawerBarcodeInputStates.closed);
        }}
        onSubmit={newBarcode => {
          setDrawerBarcodeInputState(drawerBarcodeInputStates.checkingBarcode);
          setReadedBarcode({
            content: newBarcode,
            format: barcodesTypesEnum.TYPED
          });
          firebase.analytics().logEvent('add_packages_method', {
            add_type: 'written_code'
          });
        }}
        drawerState={drawerBarcodeInputState}
      />
      {packageNotIntegratedDrawerVisible && (
        <PackageNotIntegratedDrawerError
          barcodeNotFound={currentMultibarcodes?.[0]?.content}
          onTypeBarcode={() => {
            setPackageNotIntegratedDrawerVisible(false);
            setDrawerBarcodeInputState(drawerBarcodeInputStates.idle);
            setCurrentMultibarcodes([]);
          }}
          onReadOtherBarcode={() => {
            setPackageNotIntegratedDrawerVisible(false);
            setScanMultiBarcodesVisible(true);
          }}
        />
      )}
    </Box>
  );
}

PickupScanner.propTypes = {
  onBarcodesCheckSuccess: PropTypes.func.isRequired,
  saveScannedPackages: PropTypes.func,
  initialScannedBarcodes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  ]),
  initialScannedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  onGoBack: PropTypes.func.isRequired,
  persistedStateKey: PropTypes.string,
  allowedFormats: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  pickup: SharedPropTypes.pickup,
  setOpenInstructions: PropTypes.func
};
PickupScanner.defaultProps = {
  saveScannedPackages: () => ({}),
  initialScannedBarcodes: [],
  initialScannedPackages: [],
  persistedStateKey: '',
  allowedFormats: null,
  pickup: undefined,
  setOpenInstructions: () => ({})
};

PickupScanner.url = '/bipar_pacotes';
