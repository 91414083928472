import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import SharedPropTypes from 'view/shared-prop-types';
import AssignmentRow from 'view/molecules/assignment-row';
import SearchBar from 'view/molecules/assignment-list/search';
import { Virtuoso } from 'react-virtuoso';
import { Box, Divider } from '@material-ui/core';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { statusCodes } from 'operations/update-status';
import { SetMarkersOnGoogleMaps } from 'view/pages/assignment-list/google-maps/google-maps-provider';
import EmptySearch from './empty-search';
import {
  splitAssignmentsWaypointsIntoPickupsAndDeliveries,
  searchPackages,
  rearrangePackagesList
} from './utils';
import AssignmentWaypointRow from './assignment-waypoint-row';
import CapacityReserveList from './capacity-reserve/list';
import PackageDetailsDrawer from '../package-details-drawer';
import ExpandablePackagesList from './expandable-packages-list';

export default function AssignmentList({
  packages,
  pickups,
  assignmentsWaypoints,
  onPackageClick,
  onPickupClick,
  onRetry,
  useWindowScroll
}) {
  const newTheme = getThemeForUserType({
    userType: userTypeEnum.drivers,
    version: availableThemeVersions.drivers.v2
  });

  const assignmentListRef = useRef(null);
  const [search, setSearch] = useState('');

  const [deliveredPackages, setDeliveredPackages] = useState([]);
  const [packagesWithActionPriority, setPackagesWithActionPriority] = useState(
    []
  );
  const [packagesToFilter, setPackagesToFilter] = useState([]);
  const [assignmentsPickups, setAssignmentsPickups] = useState({
    capacityReserve: [],
    general: []
  });

  const isConsiderDeliveredPackagesFromBackendEnabled = useFeatureSwitch(
    featureSwitches.considerDeliveredPackagesFromBackend
  );

  const filteredPackages = searchPackages(search, packagesToFilter);

  const shouldShowEmptySearch = !filteredPackages.length && !!search;
  const showSearchResult = !!search;

  const [openPackageDetailsModal, setOpenPackageDetailsModal] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const [
    assignmentsWaypointsPickups,
    assignmentsWaypointsDeliveries
  ] = splitAssignmentsWaypointsIntoPickupsAndDeliveries(assignmentsWaypoints);

  SetMarkersOnGoogleMaps(
    [...pickups, ...packagesWithActionPriority, ...assignmentsWaypoints],
    assignmentListRef
  );

  useEffect(() => {
    if (isConsiderDeliveredPackagesFromBackendEnabled) {
      const {
        packagesWithActionPriority: pkgsWithActionPriotiry,
        deliveredPackages: pkgsDelivered
      } = rearrangePackagesList(packages);

      setPackagesWithActionPriority(pkgsWithActionPriotiry);
      setDeliveredPackages(pkgsDelivered);
      setPackagesToFilter([...pkgsWithActionPriotiry, ...pkgsDelivered]);
    } else {
      setPackagesWithActionPriority(packages);
      setPackagesToFilter(packages);
    }
  }, [packages, isConsiderDeliveredPackagesFromBackendEnabled]);

  useEffect(() => {
    const sumToCompare =
      assignmentsPickups.capacityReserve.length +
      assignmentsPickups.general.length;
    const needToUpdate = assignmentsWaypointsPickups.length !== sumToCompare;
    if (needToUpdate) {
      const capacityReservePickups = assignmentsWaypointsPickups.filter(
        aw => aw.assignments[0]?.isCapacityReserve
      );
      const generalPickups = assignmentsWaypointsPickups.filter(
        aw => !aw.assignments[0]?.isCapacityReserve
      );

      setAssignmentsPickups({
        capacityReserve: capacityReservePickups,
        general: generalPickups
      });
    }
  }, [
    assignmentsWaypointsPickups,
    assignmentsPickups.capacityReserve.length,
    assignmentsPickups.general.length
  ]);

  const onSearchChanged = value => setSearch(value);

  const enableNewAssignmentList = useFeatureSwitch(
    featureSwitches.enableNewAssignmentList
  );

  const enableNewAssignmentListByDriverRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewAssignmentList
  );

  const shouldShowPackageDetailsModalByLastMileCompany = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enablePackageDetailsModalByLastMileCompany)
  );

  const showPackageDetailsModalForDriver =
    enableNewAssignmentListByDriverRelation &&
    shouldShowPackageDetailsModalByLastMileCompany;

  const showPackageDetailsDrawer = pkg => {
    setSelectedPackage(pkg);
    setOpenPackageDetailsModal(true);
  };

  function onDeliveryAssignmentClick(pkg) {
    if (
      showPackageDetailsModalForDriver &&
      pkg.status?.code !== statusCodes.PICKUP
    ) {
      showPackageDetailsDrawer(pkg);
    } else {
      onPackageClick(pkg);
    }
  }

  return (
    <>
      {packages.length !== 0 && (
        <SearchBar search={search} onSearchChanged={onSearchChanged} />
      )}
      {!shouldShowEmptySearch && (
        <>
          {!showSearchResult && (
            <>
              {assignmentsPickups.general &&
                assignmentsPickups.general.map(waypoint => (
                  <AssignmentWaypointRow
                    key={waypoint.assignments[0].pickupCode}
                    assignmentWaypoint={waypoint}
                  />
                ))}
              {pickups &&
                pickups.map(pickup => (
                  <AssignmentRow
                    deadlineTime={pickup.deadlineCollectTime}
                    key={pickup.pickupCode}
                    assignment={pickup}
                    onClick={onPickupClick}
                  />
                ))}
              {assignmentsWaypointsDeliveries &&
                assignmentsWaypointsDeliveries.map(waypoint => (
                  <AssignmentWaypointRow
                    key={waypoint.waypointId}
                    assignmentWaypoint={waypoint}
                  />
                ))}
            </>
          )}
          {search && filteredPackages.length > 0 ? (
            <Virtuoso
              useWindowScroll={useWindowScroll}
              data={filteredPackages}
              itemContent={(_, pkg) => (
                <AssignmentRow
                  deadlineTime={pkg.deadlineDeliveryTime}
                  key={pkg.packageId}
                  assignment={pkg}
                  onClick={onDeliveryAssignmentClick}
                  onRetry={onRetry}
                />
              )}
            />
          ) : (
            <>
              <Virtuoso
                useWindowScroll={useWindowScroll}
                data={packagesWithActionPriority}
                ref={assignmentListRef}
                itemContent={(_, pkg) => (
                  <AssignmentRow
                    deadlineTime={pkg.deadlineDeliveryTime}
                    key={pkg.packageId}
                    assignment={pkg}
                    onClick={onDeliveryAssignmentClick}
                    onRetry={onRetry}
                  />
                )}
              />
              <ThemeProvider theme={newTheme}>
                <Box pt={2}>
                  <Divider />
                  <ExpandablePackagesList
                    packages={deliveredPackages}
                    onPackageClick={onDeliveryAssignmentClick}
                  />
                  <Divider />
                </Box>
                <CapacityReserveList
                  assignmentsWaypoints={assignmentsPickups.capacityReserve}
                />
              </ThemeProvider>
            </>
          )}
          <ThemeProvider
            theme={getThemeForUserType({
              userType: userTypeEnum.drivers,
              version: availableThemeVersions.drivers.v2
            })}
          >
            {openPackageDetailsModal && (
              <PackageDetailsDrawer
                open={openPackageDetailsModal}
                onCloseClick={() => setOpenPackageDetailsModal(false)}
                pkg={selectedPackage}
              />
            )}
          </ThemeProvider>
          {enableNewAssignmentListByDriverRelation && !deliveredPackages && (
            <Box mt={2.5}>
              <Divider />
            </Box>
          )}
        </>
      )}
      {shouldShowEmptySearch && <EmptySearch search={search} />}
    </>
  );
}

AssignmentList.propTypes = {
  packages: SharedPropTypes.packages,
  pickups: SharedPropTypes.pickups,
  assignmentsWaypoints: SharedPropTypes.assignmentsWaypoints,
  onPackageClick: PropTypes.func,
  onPickupClick: PropTypes.func,
  onRetry: PropTypes.func,
  useWindowScroll: PropTypes.bool
};

AssignmentList.defaultProps = {
  packages: [],
  pickups: [],
  assignmentsWaypoints: [],
  onPackageClick: () => ({}),
  onPickupClick: () => ({}),
  onRetry: () => ({}),
  useWindowScroll: true
};
