import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import SharedPropTypes from 'view/shared-prop-types';
import { Button, Box, Typography, Drawer } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { PAGES } from 'view';
import { useRoundedBorder } from 'view/utils/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const messages = {
  title: 'Tem certeza que quer cancelar?',
  reminderMessage: (
    <Typography variant="body1">
      Ah, que pena.{' '}
      <span role="img" aria-label="emoji triste">
        😕
      </span>{' '}
      <br />
      Lembrando que você pode aceitar outras ofertas disponíveis.
    </Typography>
  ),
  buttonKeepReservation: 'Manter programação',
  buttonDismissReservation: 'Cancelar mesmo assim'
};

export default function ConfirmCancelationDrawer({
  isOpen,
  onCloseDrawerClick,
  pickup
}) {
  const history = useHistory();
  const roundedBorder = useRoundedBorder();
  const onReserveCancelationClick = () =>
    history.push(PAGES.CAPACITY_RESERVE_CANCELATION, { pickup });

  const { typography } = useTheme();
  const { pxToRem } = typography;

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onCloseDrawerClick}
      classes={{ paper: roundedBorder.root }}
    >
      {/* <Box pt={4} p={3}>
        <ErrorOutlineIcon fontSize="large" />
      </Box> */}
      <Box p={4} pb={0}>
        <ErrorOutlineIcon fontSize="large" />
        <Typography
          variant="h6"
          style={{
            fontWeight: 400,
            marginTop: pxToRem(16),
            marginBottom: pxToRem(16)
          }}
        >
          {messages.title}
        </Typography>
        {messages.reminderMessage}
      </Box>
      <Box p={4} pb={1}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => onCloseDrawerClick()}
          style={{ marginBottom: pxToRem(10) }}
        >
          <Typography>{messages.buttonKeepReservation}</Typography>
        </Button>
        <Button
          color="primary"
          size="large"
          fullWidth
          onClick={onReserveCancelationClick}
        >
          <Typography>{messages.buttonDismissReservation}</Typography>
        </Button>
      </Box>
    </Drawer>
  );
}

ConfirmCancelationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDrawerClick: PropTypes.func.isRequired,
  pickup: SharedPropTypes.pickup.isRequired
};
