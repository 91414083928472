import { getCurrentUserInfo } from '@loggi/driver-authentication';
import { isFeatureSwitchEnabledForValue } from '@loggi/firebase-feature-switches';

const featureSwitches = {
  enableCognitoAuth: 'enableCognitoAuth',
  enableLogOfferNotificationPresented: 'enableLogOfferNotificationPresented',
  enableNewCapacityReserveCard: 'enableNewCapacityReserveCard',
  enableSortByInOfferList: 'enableSortByInOfferList',
  enabledUseAllocationToListOffers: 'enabledUseAllocationToListOffers',
  enabledUseAllocationToListOffersByDriverId:
    'enabledUseAllocationToListOffersByDriverId',
  enableShowCapacityReserveConfirmation: 'enableShowCapacityReserveConfirmation'
};

/**
 * Possible values for featureSwitchValue:
 * LMCs separated by commas -> 'loggi_sp_sao_paulo,loggi_tower_0,loggi_tower_2'
 * one LMC only -> 'loggi_sp_sao_paulo'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForDriverLMC = featureSwitchValue => {
  const driverLMC = getCurrentUserInfo()?.lmcIdentification;
  return isFeatureSwitchEnabledForValue(driverLMC, featureSwitchValue);
};

/**
 * Possible values for featureSwitchValue:
 * Driver id separated by commas -> '4604665,222355,77432'
 * one Driver id only -> '346632'
 * boolean as string -> 'true'/'false'
 * boolean as boolean -> true/false
 */
export const featureSwitchEnabledForDriverId = featureSwitchValue => {
  const driverId = getCurrentUserInfo()?.id?.toString();
  return isFeatureSwitchEnabledForValue(driverId, featureSwitchValue);
};

export default featureSwitches;
