import { Machine, assign, send } from 'xstate';
import firebase from 'firebase/app';
import { updateStatus } from 'operations/update-status';
import getPosition from 'operations/geolocation';
import { messages, problemReasons } from './constants';
import { shouldNotifyDriverAboutDistance } from '../driver-distance-from-delivery-point/utils';

export const ACTIONS = {
  CHOOSE_PROBLEM: 'CHOOSE_PROBLEM',
  CONTINUE: 'CONTINUE',
  CHANGE_BARCODE_ID: 'CHANGE_BARCODE_ID',
  CLEAN_NOTIFICATION: 'CLEAN_NOTIFICATION',
  BACK: 'BACK',
  CONFIRM_PROBLEM: 'CONFIRM_PROBLEM',
  SKIP: 'SKIP',
  FACADE_PHOTO: 'FACADE_PHOTO',
  UPDATE_STATUS: 'UPDATE_STATUS',
  NO_ATTEMPT: 'NO_ATTEMPT',
  CHANGE_NOTES: 'CHANGE_NOTES',
  SUBOPTION: 'SUBOPTION'
};

export const STATES = {
  selection: 'selection',
  loading: 'loading',
  successStep: 'successStep',
  offlineStep: 'offlineStep',
  redirectStep: 'redirect',
  detailsStep: 'details',
  detailsStepSuboption: 'detailsStepSuboption',
  redirectToDriverDistanceFromDeliveryPoint:
    'redirectToDriverDistanceFromDeliveryPoint',
  facadePhoto: 'facadePhoto',
  checkDriverLocation: 'checkDriverLocation',
  showGeolocationError: 'showGeolocationError',
  driverIsOutsideDestinationAreaRadius: 'driverIsOutsideDestinationAreaRadius',
  redirectToPkgDetails: 'redirectToPkgDetails'
};

const deliveringProblemsMachine = Machine(
  {
    id: 'deliveringProblems',
    context: {
      packageId: null,
      problem: null,
      notificationMessage: null,
      tokenAttempts: null,
      pkg: null,
      notes: null,
      options: null,
      safeDelivery: null,
      deliveryEvidenceSignedUrl: null,
      problemsList: [],
      problemsListFiltered: [],
      isPackageDelivered: false
    },
    initial: STATES.checkDriverLocation,
    states: {
      [STATES.checkDriverLocation]: {
        on: {
          '': [
            {
              cond: context => {
                const { driverLastKnownLocation } = context;

                return !driverLastKnownLocation;
              },
              target: 'showGeolocationError'
            },
            {
              cond: context => {
                const {
                  driverCoords,
                  destinationCoords,
                  hasSafeDelivery,
                  backAction
                } = context;

                return (
                  !backAction &&
                  shouldNotifyDriverAboutDistance(
                    driverCoords,
                    destinationCoords,
                    hasSafeDelivery
                  )
                );
              },
              target: 'driverIsOutsideDestinationAreaRadius'
            },
            {
              target: 'selection'
            }
          ]
        }
      },
      [STATES.showGeolocationError]: {},
      [STATES.driverIsOutsideDestinationAreaRadius]: {
        on: {
          [ACTIONS.CONTINUE]: STATES.selection,
          [ACTIONS.BACK]: [
            {
              target: STATES.redirectStep,
              cond: context => context.isPackageDelivered
            },
            {
              target: STATES.redirectToPkgDetails
            }
          ]
        }
      },
      [STATES.redirectToPkgDetails]: {},

      [STATES.selection]: {
        entry: assign({
          problemsListFiltered: context =>
            context.problemsList.filter(el => {
              if (
                !context.safeDelivery &&
                el.id === problemReasons.unavailableSafeDeliveryToken.id
              )
                return false;

              if (
                !!context.deliveryEvidenceSignedUrl &&
                el.id === problemReasons.unableToGoToDeliveryLocation.id
              )
                return false;

              const showAccordingToPkgStatus =
                (!context.isPackageDelivered && el.showForGeneralStatus) ||
                (context.isPackageDelivered && el.showForDeliveredStatus);

              return showAccordingToPkgStatus;
            })
        }),
        on: {
          BACK: {
            actions: ['goBack']
          },
          [ACTIONS.CHOOSE_PROBLEM]: [
            {
              target: STATES.detailsStepSuboption,
              cond: (_, event) => {
                return (
                  event.problem.id ===
                  problemReasons.unableToGoToDeliveryLocation.id
                );
              },
              actions: assign({
                problem: (_, event) => event.problem
              })
            },
            {
              target: STATES.detailsStep,
              actions: assign({
                problem: (_, event) => event.problem
              })
            }
          ]
        }
      },
      [STATES.detailsStepSuboption]: {
        on: {
          BACK: STATES.selection,
          UPDATE_STATUS: STATES.loading,
          [ACTIONS.CONFIRM_PROBLEM]: [
            {
              cond: context => {
                const { deliveryEvidenceSignedUrl } = context;
                return !!deliveryEvidenceSignedUrl;
              },
              target: STATES.facadePhoto
            },
            {
              actions: 'callUpdateStatus'
            }
          ],
          [ACTIONS.SUBOPTION]: {
            actions: assign({
              options: (_, event) => {
                return event.value.options;
              }
            })
          }
        }
      },
      [STATES.detailsStep]: {
        on: {
          BACK: STATES.selection,
          UPDATE_STATUS: STATES.loading,
          SKIP: STATES.successStep,
          [ACTIONS.CONFIRM_PROBLEM]: [
            {
              cond: context => {
                const { deliveryEvidenceSignedUrl } = context;
                return !!deliveryEvidenceSignedUrl;
              },
              target: STATES.facadePhoto
            },
            {
              actions: 'callUpdateStatus'
            }
          ],
          [ACTIONS.CHANGE_NOTES]: {
            actions: assign({
              notes: (_, event) => {
                return event.value.notes;
              }
            })
          }
        }
      },
      [STATES.facadePhoto]: {
        on: {
          BACK: STATES.detailsStep,
          SKIP: STATES.successStep,
          UPDATE_STATUS: STATES.loading
        }
      },

      [STATES.loading]: {
        invoke: {
          id: 'update status',
          src: context =>
            getPosition()
              .then(({ latitude, longitude }) =>
                updateStatus({
                  updateStatusData: {
                    latitude,
                    longitude,
                    problemDetails: context.options
                      ? context.options
                          .filter(option => option.checked)
                          .map(option => {
                            return {
                              key: option.key,
                              value: option.value
                            };
                          })
                      : [],
                    receiverName: '',
                    receiverDocument: '',
                    status: context.problem.id,
                    process: context.problem.process,
                    destination: context.problem.destination,
                    packageId: context.packageId,
                    updateStatusTime: new Date().toISOString(),
                    safeDeliveryTokenAttempts: context.tokenAttempts,
                    deliveryEvidenceSignedUrl:
                      context.pkg.deliveryEvidenceSignedUrl,
                    driverNotes: context.notes,
                    experiments: []
                  }
                })
              )

              .catch(error => {
                if (!error.response) {
                  return {
                    offline: true
                  };
                }

                throw error;
              }),
          onDone: [
            {
              target: STATES.successStep
            }
          ],
          onError: {
            target: STATES.selection,
            actions: assign({
              notificationMessage: (_, event) =>
                event.data.message ||
                messages.notifications.defaultUpdateStatusFailureText
            })
          }
        }
      },
      [STATES.successStep]: {
        on: {
          [ACTIONS.CONTINUE]: STATES.redirectStep
        }
      },
      [STATES.redirectStep]: {}
    },
    on: {
      [ACTIONS.CLEAN_NOTIFICATION]: {
        actions: assign({
          notificationMessage: null
        })
      }
    }
  },
  {
    actions: {
      goBack: () => {
        throw new Error(
          'this must be passed down from whom is instantiating the machine'
        );
      },
      callUpdateStatus: send(context => {
        const { text } = context.problem;
        const markedSuboptions = context.options || [];

        firebase.analytics().logEvent('delivery_problem', {
          problem: text,
          packageId: context.packageId,
          notes: context.notes,
          options: markedSuboptions
        });

        const eventType = ACTIONS.UPDATE_STATUS;

        const event = {
          type: eventType
        };
        return event;
      })
    }
  }
);

export default deliveringProblemsMachine;
