import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Divider,
  ListItem,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Virtuoso } from 'react-virtuoso';
import { checkBarcodes } from 'operations/pickup';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import sendEventToAnalytics from 'operations/firebase';
import SharedPropTypes from 'view/shared-prop-types';
import ConfirmDrawer from 'view/molecules/confirm-drawer';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import Header from 'view/molecules/header';
import InfoDrawer from 'view/molecules/info-drawer';
import DrawerWithSteps from 'view/molecules/drawer-with-steps';
import { ReactComponent as ErrorBipImage } from 'view/pages/pickup/pickup-scanner-v2/icons/bip-error.svg';
import { ReactComponent as PackageToScanIcon } from './icons/package-to-scan.svg';
import { ReactComponent as ScannedPackageIcon } from './icons/scanned-package.svg';
import { ReactComponent as ScannedPackageErrorIcon } from './icons/scanned-package-error.svg';
import { ReactComponent as ScannedPackageWarningIcon } from './icons/scanned-package-warning.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as PersonReactionIcon } from './icons/person-reaction.svg';
import FilterDrawer, { filterValues } from './filter-drawer';
import PickupPackagesListOnBoarding from './on-boarding';

export const texts = {
  title: itensQuantity => (
    <>
      {itensQuantity > 1 ? (
        <Typography variant="h6">
          Identificar os {itensQuantity} <b>itens com etiqueta</b>
        </Typography>
      ) : (
        <Typography variant="h6">
          Identificar o <b>item com etiqueta</b>
        </Typography>
      )}
    </>
  ),
  subtitle:
    'Fique atento ao código do item para identificar os pacotes aceitos por você.',
  buttonStartIdentification: 'Iniciar identificação',
  buttonContinueIdentification: 'Continuar identificação',
  buttonConfirm: 'Fazer retirada',
  multimodals: {
    title: 'Não há itens identificados',
    subtitle: 'Para adicionar itens na listagem, clique em ',
    subtitleHighlight: 'iniciar identificação'
  }
};

export const helpSteps = [
  {
    title: 'Itens a serem coletados',
    subtitle:
      'A lista exibida prevê todos os itens  que devem ser bipados para esta coleta. Você pode conferir qual a quantidade e quais pacotes ainda precisam ser bipados.'
  },
  {
    title: 'Itens identificados',
    subtitle:
      'Ao bipar um item da lista, ele será identificado em verde. Isso indica que está tudo certo com a bipagem e o pacote está pronto para ser coletado.'
  },
  {
    title: 'Itens não previstos para coleta',
    subtitle:
      'Durante a coleta itens não previstos podem ser bipados. Nesse caso, você ainda pode adicioná-los, mas atente-se a sua capacidade. Os itens serão identificados em amarelo na listagem.'
  }
];

/**
 * @param {Object} pickup The driver's pickup demand
 * @param {Object} packagesToPickup Packages linked to the pickup demand
 * @param {Object} scannedPackages Scanned packages (not necessarily linked to the pickup)
 * @param {Function} onStartIdentificationClick called in initial stage, when button clicked.
 * @param {Function} onGoBack called in any stage when back button clicked
 * @param {Function} saveScannedPackages called in checkout stage when remove a package button is clicked
 * @param {Function} onSuccess called in checkout stage when confirm button is clicked
 * @param {Number} userIdentification driver's ID
 */
export default function PickupPackagesList({
  pickup,
  packagesToPickup,
  scannedPackages,
  onStartIdentificationClick,
  onGoBack,
  saveScannedPackages,
  onSuccess,
  userIdentification
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { colors } = useTheme();

  const containerRef = useRef();
  const [confirmDrawer, setConfirmDrawerProps] = useState({ open: false });
  const [infoDrawerProps, setInfoDrawerProps] = useState({ open: false });
  const [showHelpDrawer, setShowHelpDrawer] = useState(false);
  const [allPackages, setAllPackages] = useState([]);
  const [packagesToList, setPackagesToList] = useState([]);
  const [expectedPackagesCodesMap, setExpectedPackagesCodesMap] = useState(
    new Set()
  );

  const [filterValue, setFilterValue] = useState(filterValues.default);
  const [isCheckingAllBarcodes, setIsCheckingAllBarcodes] = useState(false);

  const enableEventPackageWithoutBarcode = useFeatureSwitch(
    featureSwitches.enableEventPackageWithoutBarcode
  );
  const enablePlaceholder = useFeatureSwitch(featureSwitches.enablePlaceholder);

  const enabledLMCsForListOfPlannedVolumesWithMultimodals = useFeatureSwitch(
    featureSwitches.enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  const isListOfPlannedVolumesEnabledForMultimodals = featureSwitchEnabledForDriverLMC(
    enabledLMCsForListOfPlannedVolumesWithMultimodals
  );
  // Even with the FS configured as number, we need to convert it because the FS returns a string
  const percentageToAddInVolumesLengthForMultimodalsFS =
    useFeatureSwitch(
      featureSwitches.percentageToAddInVolumesLengthForMultimodals
    ) || '0.20';
  const percentageToAddInVolumesLengthForMultimodals = Number(
    percentageToAddInVolumesLengthForMultimodalsFS
  );

  /**
   * INIT SECTION
   * Defines the minimum and maximum length of a barcode to truncate
   * W
   * The config through FS will allow us to quick changes without deploying
   * a new code.
   *
   * minBarcodeLengthToTruncate: Configure the minimum number of characters before truncate.
   * maxBarcodeLengthToTruncate: Configure the maximum number of characters that we are going to display.
   *
   * Ex:
   * minBarcodeLengthToTruncate = 5
   * maxBarcodeLengthToTruncate = 9
   * barcode A = 12345           -> display 12345        -> barcode length(5) is equal than min len, doesnt truncate
   * barcode B = 123456          -> display 12...56      -> barcode length(6) is greater than min len, truncate.
   * barcode C = 1234567891234   -> display 123...234    -> barcode length(14) is greater than max len, truncate.
   *
   */
  const minBarcodeLengthToTruncateValue = useFeatureSwitch(
    featureSwitches.minBarcodeLengthToTruncate
  );
  const minBarcodeLengthToTruncate =
    Number(minBarcodeLengthToTruncateValue) > 0
      ? Number(minBarcodeLengthToTruncateValue)
      : 18;

  const maxBarcodeLengthToTruncateValue = useFeatureSwitch(
    featureSwitches.maxBarcodeLengthToTruncate
  );
  const maxBarcodeLengthToTruncate =
    Number(maxBarcodeLengthToTruncateValue) > 0
      ? Number(maxBarcodeLengthToTruncateValue)
      : 18;
  // END SECTION

  const isPickupWithMultiModals = useCallback(() => {
    return (
      parseInt(pickup?.assignments[0].scheduledVehicles, 10) > 1 &&
      isListOfPlannedVolumesEnabledForMultimodals
    );
  }, [pickup, isListOfPlannedVolumesEnabledForMultimodals]);

  const getNumberOfVolumesToIdentifyInMultimodals = useCallback(() => {
    const expectedNumberVolumes = Math.floor(
      packagesToPickup.length /
        parseInt(pickup?.assignments[0].scheduledVehicles, 10)
    );
    return (
      expectedNumberVolumes +
      Math.floor(
        expectedNumberVolumes * percentageToAddInVolumesLengthForMultimodals
      )
    );
  }, [packagesToPickup, pickup, percentageToAddInVolumesLengthForMultimodals]);

  /**
   * Filter packages according to filter value
   * @returns {Array<Package>}
   */
  const filterList = useCallback(
    packages => {
      if (filterValue === filterValues.toIdentify) {
        return packages.filter(pkg => !pkg.packageId);
      }
      if (filterValue === filterValues.identified) {
        return packages.filter(pkg => pkg.packageId);
      }
      if (filterValue === filterValues.notPlanned) {
        return packages.filter(pkg =>
          !expectedPackagesCodesMap.has(pkg.code) ? pkg.packageId : null
        );
      }
      return packages;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValue]
  );

  const companyId = pickup?.shipper?.companyId;
  const pickupCode = pickup?.pickupCode;

  useEffect(() => {
    sendEventToAnalytics('pickup_packages_list_page', {
      pickup: pickupCode,
      driverId: userIdentification
    });
  }, [pickupCode, userIdentification]);

  /**
   * Set the parameter 'open' to false for InfoDrawer
   */
  const closeInfoDrawer = () => {
    setInfoDrawerProps(props => {
      return { ...props, open: false };
    });
  };

  useEffect(() => {
    const scannedPackagesMap = {};
    if (scannedPackages.length > 0) {
      scannedPackages.forEach(pkg => {
        scannedPackagesMap[pkg.barcodes[0]] = pkg;
      });
    }

    const packagesToPickupBarcodes = packagesToPickup.map(pkg => pkg.code);
    setExpectedPackagesCodesMap(new Set(packagesToPickupBarcodes));

    /**
     * In phase 1 we'll allow the driver to scan packages that are not from
     * the pickup, for that, we need to get the packages that were not planned
     * to show in the list.
     */
    const extraPackagesScanned = Object.keys(scannedPackagesMap)
      .filter(barcode => !packagesToPickupBarcodes.includes(barcode))
      .map(barcode => scannedPackagesMap[barcode]);

    const packagesPlanned = packagesToPickup.map(pkgToPickup => {
      if (scannedPackagesMap[pkgToPickup.code]) {
        return {
          ...pkgToPickup,
          ...scannedPackagesMap[pkgToPickup.code]
        };
      }
      return pkgToPickup;
    });

    const packages = [...packagesPlanned, ...extraPackagesScanned];
    setAllPackages(packages);
    setPackagesToList(filterList(packages));

    if (isPickupWithMultiModals() && scannedPackages.length === 0) {
      setInfoDrawerProps({
        open: true,
        image: <PersonReactionIcon />,
        title: `Esperamos que você identifique ${getNumberOfVolumesToIdentifyInMultimodals()} itens.`,
        subtitle:
          'Por conta da quantidade de itens previstos, a listagem mostrará apenas os itens identificados.',
        onCloseClick: closeInfoDrawer,
        mainButton: {
          show: true,
          text: 'Ok, entendi',
          action: closeInfoDrawer
        }
      });
    }
  }, [
    packagesToPickup,
    scannedPackages,
    setPackagesToList,
    setAllPackages,
    filterList,
    getNumberOfVolumesToIdentifyInMultimodals,
    isPickupWithMultiModals
  ]);

  useEffect(() => {
    if (scannedPackages.length > 0 && allPackages.length > 0) {
      setPackagesToList(filterList(allPackages));
    }
  }, [filterValue, allPackages, filterList, scannedPackages.length]);

  /**
   * Set the parameter 'open' to false for ConfirmDrawer
   */
  const closeConfirmDrawer = useCallback(() => {
    setConfirmDrawerProps(props => {
      return { ...props, open: false };
    });
  }, [setConfirmDrawerProps]);

  /**
   * Confirms the action of remove the scanned package from driver's list
   * @param {String} barcodes barcodes of the package to be removed
   * We don't pass the packageId because in case of error in backend
   * the packageId is not returned, only the error and barcodes.
   */
  const confirmPackageRemoval = useCallback(
    barcodes => {
      saveScannedPackages(
        scannedPackages.filter(
          pkg => pkg.barcodes.toString() !== barcodes.toString()
        )
      );
      showSnackbar({
        message: 'Bipe removido do item',
        variant: 'successSnackbar',
        enqueueSnackbar
      });
      closeConfirmDrawer();
    },
    [scannedPackages, enqueueSnackbar, saveScannedPackages, closeConfirmDrawer]
  );

  /**
   * Opens drawer to confirm the scanned package removal
   * @param {Object} pkg represents the scanned package to be removed from drivers list
   */
  const removeScannedPackage = pkg => {
    setConfirmDrawerProps({
      open: true,
      title: 'Tem certeza que deseja remover o item identificado?',
      subtitle: `O item ${
        pkg.barcodes?.[0]
      } voltará para a listagem de não identificados.`,
      confirmButton: {
        text: 'Sim, quero remover',
        action: () => confirmPackageRemoval(pkg.barcodes)
      },
      dismissButton: {
        text: 'Cancelar',
        action: closeConfirmDrawer
      }
    });
  };

  /**
   * checks if the barcode is predicted in the list
   * of items to be collected.
   * @param {string} pkg barcode beeped or typed
   * @returns {bool}
   */
  const itemsInPickup = pkg => {
    return (
      expectedPackagesCodesMap.has(pkg.code) ||
      expectedPackagesCodesMap.has(pkg.barcodes[0])
    );
  };

  /**
   * Set the parameter 'loading' to confirmButton of ConfirmDrawer component
   * @param {bool} loadingfilterValue
  };

  /**
   * Checks all scanned packages barcodes
   */
  const confirmCheckPackages = useCallback(() => {
    const barcodesToCheck = scannedPackages.map(pkg =>
      pkg.barcodes.map(barcode => {
        return {
          content: barcode
        };
      })
    );
    setIsCheckingAllBarcodes(true);
    checkBarcodes({ barcodes: barcodesToCheck, companyId, pickupCode })
      .json(({ barcodesPackagesCheck }) => {
        const packagesWithError = barcodesPackagesCheck.filter(
          item => !!item.error
        );
        saveScannedPackages(
          barcodesPackagesCheck.map(barcodesCheck => barcodesCheck)
        );
        if (packagesWithError.length > 0) {
          closeConfirmDrawer();
          setInfoDrawerProps({
            open: true,
            image: <ErrorBipImage />,
            title: 'Você possui itens que não podem ser coletados',
            subtitle:
              'Itens já retirados ou de outras retiradas não podem ser coletados. Remova-os da sua lista',
            onCloseClick: closeInfoDrawer,
            mainButton: {
              show: true,
              text: 'Ok, entendi',
              action: closeInfoDrawer
            }
          });
        } else {
          onSuccess();
        }
      })
      .catch(() => {
        showSnackbar({
          message: 'Não conseguimos processar os itens. Tente novamente.',
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => {
        setIsCheckingAllBarcodes(false);
      });
  }, [
    closeConfirmDrawer,
    setIsCheckingAllBarcodes,
    enqueueSnackbar,
    onSuccess,
    saveScannedPackages,
    scannedPackages,
    pickupCode,
    companyId
  ]);

  /**
   * Get the correct icon to render in packages list
   * @param {Object} pkg
   * @returns {Node}
   */
  const getPackageListItemIcon = pkg => {
    const pkgWasChecked = pkg.packageId || pkg.error;
    if (pkgWasChecked) {
      if (pkg.error) {
        return <ScannedPackageErrorIcon />;
      }
      if (!itemsInPickup(pkg)) {
        return <ScannedPackageWarningIcon />;
      }
      return <ScannedPackageIcon />;
    }
    return <PackageToScanIcon />;
  };

  /**
   * Get the barcode to show to driver
   * @param {Object} pkg
   * @returns {String}
   */
  const getPackageBarcode = pkg => {
    const placeholder = enablePlaceholder ? 'XXXXXxx' : null;
    const barcode = pkg.code || pkg.barcodes?.[0] || placeholder;

    if (barcode === placeholder && enableEventPackageWithoutBarcode) {
      sendEventToAnalytics('package_without_barcode', {
        pickup: pickupCode,
        driverId: userIdentification,
        packagesToPickup: JSON.stringify(packagesToPickup)
      });
    }

    if (
      barcode.length > maxBarcodeLengthToTruncate ||
      barcode.length > minBarcodeLengthToTruncate
    ) {
      const middleOfText =
        barcode.length > maxBarcodeLengthToTruncate
          ? Math.ceil(maxBarcodeLengthToTruncate / 2)
          : Math.ceil(barcode.length / 2);
      const range =
        barcode.length > maxBarcodeLengthToTruncate
          ? middleOfText - 2
          : middleOfText - 1;
      return `${barcode.substr(0, range)}...${barcode.substr(
        barcode.length - range,
        barcode.length
      )}`;
    }

    return barcode;
  };

  return (
    <Box
      bgcolor={colors.neutrals.white.pure}
      color={colors.neutrals.typeface.primary}
      display="flex"
      flexDirection="column"
      height="100%"
      ref={containerRef}
    >
      <Header
        backButton={{ action: onGoBack }}
        helpButton={{ action: () => setShowHelpDrawer(true) }}
        extraStyles={{
          position: 'sticky',
          backgroundColor: colors.neutrals.white.pure,
          top: 0,
          zIndex: 1
        }}
      />
      <Box px={4}>
        {texts.title(
          isPickupWithMultiModals()
            ? getNumberOfVolumesToIdentifyInMultimodals()
            : packagesToPickup.length
        )}
        <Typography variant="body2" style={{ marginTop: 16 }}>
          {texts.subtitle}
        </Typography>
      </Box>
      <Box mt={3}>
        <Divider />
      </Box>
      {(scannedPackages.length > 0 ||
        (isPickupWithMultiModals() && scannedPackages.length === 0)) && (
        <Box p={3}>
          <FilterDrawer
            disabled={isPickupWithMultiModals()}
            defaultValue={
              isPickupWithMultiModals() ? filterValues.identified : null
            }
            itensIdentified={packagesToList.filter(pkg => pkg.packageId).length}
            itensToIdentify={
              packagesToList.filter(pkg => !pkg.packageId).length
            }
            itensNotPlanned={allPackages.length - packagesToPickup.length}
            onFilterConfirm={value => setFilterValue(value)}
          />
        </Box>
      )}
      {isPickupWithMultiModals() && scannedPackages.length === 0 ? (
        <Box height="100%">
          <Box
            px={3}
            bgcolor={colors.neutrals.shapes.lighter}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
              {texts.multimodals.title}
            </Typography>
            <>
              <Typography variant="body2">
                {texts.multimodals.subtitle}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: colors.primary.pure }}
              >
                {texts.multimodals.subtitleHighlight}
              </Typography>
            </>
          </Box>
        </Box>
      ) : (
        <Box>
          <Virtuoso
            useWindowScroll
            data={isPickupWithMultiModals() ? scannedPackages : packagesToList}
            endReached={() => {
              sendEventToAnalytics(
                'pickup_packages_list_page_scrolled_to_end',
                {
                  pickup: pickupCode,
                  driverId: userIdentification,
                  packagesToPickup: packagesToPickup.length,
                  scannedPackages: scannedPackages.length,
                  listedPackages: allPackages.length
                }
              );
            }}
            itemContent={(_, pkg) => (
              <ListItem divider={!!pkg} key={pkg.code || pkg.barcodes?.[0]}>
                <Box width="100%" p={2} display="flex" alignItems="center">
                  <Box flexShrink="0">{getPackageListItemIcon(pkg)}</Box>
                  <Box mx={2}>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 600, wordBreak: 'break-word' }}
                    >
                      {getPackageBarcode(pkg)}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ color: colors.neutrals.typeface.secondary }}
                    >
                      {pkg.recipientName}
                    </Typography>
                    {!itemsInPickup(pkg) ? (
                      <Box
                        width="fit-content"
                        bgcolor={
                          pkg.error
                            ? colors.status.negative.light
                            : colors.status.attention.light
                        }
                      >
                        <Typography
                          variant="caption"
                          style={{
                            fontWeight: 600,
                            color: colors.neutrals.typeface.primary
                          }}
                        >
                          {pkg.error ? 'Item já retirado' : 'Item não previsto'}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {pkg.error && (
                          <Box
                            mt={1.5}
                            width="fit-content"
                            bgcolor={colors.status.negative.light}
                          >
                            <Typography
                              variant="caption"
                              style={{
                                fontWeight: 600,
                                color: colors.neutrals.typeface.primary
                              }}
                            >
                              {pkg.error}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {scannedPackages.length > 0 && (pkg.packageId || pkg.error) && (
                    <Box flexShrink="0" ml="auto">
                      <DeleteIcon
                        data-testid={`pickup-packages-list-delete-package-${
                          pkg.packageId
                        }`}
                        onClick={() => {
                          sendEventToAnalytics(
                            'pickup_packages_list_page_package_removed',
                            {
                              pickup: pickupCode,
                              packageId: pkg.packageId,
                              driverId: userIdentification
                            }
                          );
                          removeScannedPackage(pkg);
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </ListItem>
            )}
          />
        </Box>
      )}
      <Box
        px={3}
        py={2}
        mt="auto"
        bgcolor={colors.neutrals.white.pure}
        position="sticky"
        bottom={0}
      >
        {scannedPackages.length === 0 ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onStartIdentificationClick}
          >
            {texts.buttonStartIdentification}
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              disabled={isCheckingAllBarcodes}
              color="primary"
              size="large"
              fullWidth
              onClick={confirmCheckPackages}
            >
              {isCheckingAllBarcodes ? (
                <CircularProgress
                  data-testid="pickup-check-barcodes-loading"
                  style={{
                    width: 24,
                    height: 24,
                    color: colors.neutrals.white.pure
                  }}
                />
              ) : (
                <>{texts.buttonConfirm}</>
              )}
            </Button>
            <Button
              color="primary"
              size="large"
              fullWidth
              style={{ marginTop: 8 }}
              onClick={onStartIdentificationClick}
            >
              {texts.buttonContinueIdentification}
            </Button>
          </>
        )}
      </Box>
      {confirmDrawer.open && (
        <ConfirmDrawer
          open={confirmDrawer.open}
          title={confirmDrawer.title}
          subtitle={confirmDrawer.subtitle}
          confirmButton={confirmDrawer.confirmButton}
          dismissButton={confirmDrawer.dismissButton}
        />
      )}
      {infoDrawerProps.open && (
        <InfoDrawer
          open={infoDrawerProps.open}
          image={infoDrawerProps.image}
          title={infoDrawerProps.title}
          subtitle={infoDrawerProps.subtitle}
          onCloseClick={closeInfoDrawer}
          mainButton={infoDrawerProps.mainButton}
        />
      )}
      {showHelpDrawer && (
        <DrawerWithSteps
          open={showHelpDrawer}
          title="Ajuda"
          steps={helpSteps}
          onCloseClick={() => setShowHelpDrawer(false)}
        />
      )}
      <PickupPackagesListOnBoarding />
    </Box>
  );
}

PickupPackagesList.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired,
  packagesToPickup: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      recipientName: PropTypes.string
    })
  ),
  scannedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      barcode: PropTypes.string,
      barcodes: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  onGoBack: PropTypes.func.isRequired,
  onStartIdentificationClick: PropTypes.func,
  saveScannedPackages: PropTypes.func,
  onSuccess: PropTypes.func,
  userIdentification: PropTypes.number.isRequired
};

PickupPackagesList.defaultProps = {
  packagesToPickup: [],
  scannedPackages: [],
  onStartIdentificationClick: () => ({}),
  saveScannedPackages: () => ({}),
  onSuccess: () => ({})
};

PickupPackagesList.url = '/v2/pacotes_coleta';
