import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

export default function DynamicLabelTextField(props) {
  const { onChange, label, focusedLabel, filledLabel, ...otherProps } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = event => {
    onChange(event.target.value);
    setIsFilled(!!event.target.value);
  };

  const getLabel = () => {
    if (isFocused && focusedLabel) {
      return props.focusedLabel;
    }

    if (isFilled && filledLabel) {
      return props.filledLabel;
    }

    return props.label;
  };

  return (
    <TextField
      onChange={handleChange}
      InputProps={{
        onFocus: handleFocus,
        onBlur: handleBlur
      }}
      label={getLabel()}
      data-testid="dynamic-label-text-field"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
    />
  );
}

DynamicLabelTextField.defaultProps = {
  focusedLabel: undefined,
  filledLabel: undefined,
  onChange: () => ({})
};

DynamicLabelTextField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  focusedLabel: PropTypes.string,
  filledLabel: PropTypes.string
};
