import { errorTypes } from './constants';
import askAssignmentListRouteService from './assignment-list-offer-route-service';

function makeParsedError(type, message) {
  return { type, message };
}

export default async function askAssignmentListRoute() {
  return askAssignmentListRouteService()
    .then(() => {
      return null;
    })
    .catch(error => {
      const { networkError, genericError } = errorTypes;
      const type = error.isFetchError ? networkError : genericError;
      const message = error.message || error.response.message;
      return makeParsedError(type, message);
    });
}
