import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { BarcodeReaderPage } from '@loggi/driverapp-routes-list';
import FirebaseConfigProvider from '@loggi/firebase-feature-switches';
import GoogleMaps from 'view/pages/assignment-list/google-maps/google-maps-provider';
import { SnackbarProvider } from 'notistack';
import Routes from 'view/routes';
import ErrorTracker from 'view/molecules/error-tracker';
import PrivateRoute from 'view/atoms/private-route';
import AssignmentList from 'view/pages/assignment-list';
import PackageDetailsScreen from 'view/pages/package-details';
import CapacityReserveDetails from 'view/pages/capacity-reserve-details';
import DeliveringProblemsPage from 'view/pages/delivering-problems';
import ProtocolPage from 'view/pages/protocol';
import UnauthenticatedPage from 'view/pages/unauthenticated';
import OrderResumePage from 'view/pages/order-resume';
import PickupPage from 'view/pages/pickup';
import PackageReturnPage from 'view/pages/package-return';
import ItineraryDisassociationPage from 'view/pages/itinerary-disassociation';
import RouteCanceledPage from 'view/pages/route-canceled';
import FacadePhoto from 'view/pages/facade-photo';
import NoAttribution from 'view/pages/no-attribution';
import DirectPickup from 'view/pages/pickup/direct-pickup';
import EmergencyWrapper from 'view/pages/emergency-wrapper';
import OfferScreenWrapper from 'view/pages/offer-screen-wrapper';
import GoogleMapsSurvey from 'view/pages/assignment-list/google-maps/google-maps-survey';
import CapacityReserveCancelation from 'view/pages/capacity-reserve-details/capacity-reserve-cancelation';
import OpportunisticOfferSurvey from 'view/pages/pickup/opportunistic-offer-survey';
import './styles/index.css';
import useSurvey from 'hooks/useSurvey';
import AssignmentWaypointDetails from './pages/assignmnet-waypoint-details';
import AuthError from './pages/auth-error';
import OfferNotificationPage from './pages/offer-notification';
import SurveyWrapper from './pages/survey/wrapper';

export const PAGES = {
  PACKAGE_LIST: '/lista-atividades',
  PACKAGE_DETAILS: '/pacote/:packageId',
  CAPACITY_RESERVE_DETAILS: '/reserva-capacidade/:pickupCode',
  DELIVERING_PROBLEMS: '/entrega/problemas',
  PROTOCOL: '/entrega/protocolo',
  BARCODE_READ: '/pesquisa/leitura-barcode',
  UNAUTHENTICATED: '/sem-pacotes',
  EMERGENCY: '/emergencia',
  FINISH_DELIVERING: '/finalizar-entrega',
  PICKUP: '/coleta/:pickupCode',
  PACKAGE_RETURN: '/devolucao',
  ITINERARY_DISASSOCIATION: '/sem-rota',
  ROUTE_CANCELED: '/rota-cancelada-limite',
  NO_ATTRIBUTION: '/sem-nada-para-fazer',
  FACADE_PHOTO: '/facade-photo',
  DIRECT_PICKUP: '/coleta-direta',
  STOP_POINT: '/ponto-de-parada',
  AUTH_ERROR: '/erro-autenticacao',
  GOOGLE_MAPS_SURVEY: '/pesquisa-mapa',
  CAPACITY_RESERVE_CANCELATION: '/cancelamento-reserva-capacidade',
  OPPORTUNISTIC_OFFER_SURVEY: '/pesquisa-oportunistica',
  CAPACITY_RESERVE_BARCODE_READER: '/leitor-saca-reserva-capacidade/:pickupId',
  CAPACITY_RESERVE_OFFER_SCREEN: '/ofertas/:offerId',
  OFFER_NOTIFICATION: '/oferta-notification',
  SURVEY: '/survey/:surveySlug'
};

function Flecha() {
  useSurvey();

  let redirectTo = window.location.pathname.replace('/atividades', '');

  if (!redirectTo) {
    redirectTo = PAGES.PACKAGE_LIST;
  }

  return (
    <Routes>
      <Route
        exact
        path={PAGES.UNAUTHENTICATED}
        component={UnauthenticatedPage}
      />
      <Route path={PAGES.AUTH_ERROR} component={AuthError} />
      <PrivateRoute
        path={PAGES.DELIVERING_PROBLEMS}
        component={DeliveringProblemsPage}
      />
      <PrivateRoute
        exact
        path={PAGES.PACKAGE_LIST}
        component={AssignmentList}
      />
      <PrivateRoute
        path={PAGES.PACKAGE_DETAILS}
        component={PackageDetailsScreen}
      />
      <PrivateRoute
        path={PAGES.CAPACITY_RESERVE_DETAILS}
        component={CapacityReserveDetails}
      />
      <PrivateRoute path={PAGES.PROTOCOL} component={ProtocolPage} />
      <PrivateRoute path={PAGES.EMERGENCY} component={EmergencyWrapper} />
      <PrivateRoute
        path={PAGES.FINISH_DELIVERING}
        component={OrderResumePage}
      />
      <PrivateRoute path={PAGES.PICKUP} component={PickupPage} />
      <PrivateRoute path={PAGES.PACKAGE_RETURN} component={PackageReturnPage} />
      <PrivateRoute
        path={PAGES.ITINERARY_DISASSOCIATION}
        component={ItineraryDisassociationPage}
      />
      <PrivateRoute path={PAGES.ROUTE_CANCELED} component={RouteCanceledPage} />
      <PrivateRoute path={PAGES.FACADE_PHOTO} component={FacadePhoto} />
      <PrivateRoute path={PAGES.NO_ATTRIBUTION} component={NoAttribution} />
      <PrivateRoute path={PAGES.DIRECT_PICKUP} component={DirectPickup} />
      <PrivateRoute
        path={PAGES.STOP_POINT}
        component={AssignmentWaypointDetails}
      />
      <PrivateRoute
        path={PAGES.GOOGLE_MAPS_SURVEY}
        component={GoogleMapsSurvey}
      />
      <PrivateRoute
        path={PAGES.CAPACITY_RESERVE_CANCELATION}
        component={CapacityReserveCancelation}
      />
      <PrivateRoute
        path={PAGES.OPPORTUNISTIC_OFFER_SURVEY}
        component={OpportunisticOfferSurvey}
      />
      <PrivateRoute
        path={PAGES.CAPACITY_RESERVE_BARCODE_READER}
        component={BarcodeReaderPage}
      />
      <PrivateRoute
        path={PAGES.CAPACITY_RESERVE_OFFER_SCREEN}
        component={OfferScreenWrapper}
      />
      <PrivateRoute
        path={PAGES.OFFER_NOTIFICATION}
        component={OfferNotificationPage}
      />
      <PrivateRoute path={PAGES.SURVEY} component={SurveyWrapper} />
      <Route path="*">
        <Redirect to={redirectTo} />
      </Route>
    </Routes>
  );
}

export default function App() {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.default
      })}
    >
      <CssBaseline />
      <ErrorTracker>
        <FirebaseConfigProvider>
          <GoogleMaps />
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top'
            }}
          >
            <Flecha />
          </SnackbarProvider>
        </FirebaseConfigProvider>
      </ErrorTracker>
    </ThemeProvider>
  );
}
