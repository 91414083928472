import { Machine, assign } from 'xstate';
import { getPickupProblems } from 'operations/pickup';
import messages from '../messages';

export const STATES = {
  loading: 'loading',
  error: 'error',
  loaded: 'loaded'
};

export const ERROR_DATA = {
  network: {
    message: messages.defaultErrors.network,
    icon: 'noWifi'
  },
  error: {
    message: messages.defaultErrors.generic,
    icon: 'error'
  }
};

export default Machine(
  {
    id: 'pickup-problems',
    initial: STATES.loading,
    context: {
      problems: [],
      errorMessage: '',
      errorIcon: undefined,
      errorEvent: undefined,
      pickup: undefined
    },
    states: {
      [STATES.loading]: {
        invoke: {
          src: 'getPickupProblems',
          onDone: { target: STATES.loaded, actions: ['setProblems'] },
          onError: { target: STATES.error, actions: ['handleApiError'] }
        }
      },
      [STATES.loaded]: {
        type: 'final'
      },
      [STATES.error]: {
        type: 'final'
      }
    }
  },
  {
    services: {
      getPickupProblems: async () => {
        return getPickupProblems()
          .fetchError(error => {
            const errorData = {
              errorData: ERROR_DATA.network,
              ...error
            };

            throw errorData;
          })
          .json();
      }
    },
    actions: {
      setProblems: assign({
        problems: (_, event) => {
          return event.data.reasons;
        }
      }),
      handleApiError: assign({
        errorMessage: (_, event) => {
          return (
            event.data?.json?.errors ||
            event.data?.errorData?.message ||
            ERROR_DATA.error.message
          );
        },
        errorIcon: (_, event) => {
          return event.data?.errorData?.icon || ERROR_DATA.error.icon;
        },
        errorEvent: (_, event) => {
          return event.data?.errorData?.event;
        }
      })
    }
  }
);
