// eslint-disable-next-line import/no-named-as-default
import api from '../config/api';

/**
 * Wrapped POST request o Leve's API for package status updating.
 * Primarly used by core use case `updateStatus` that executes the request
 * and handle validations with callbacks.
 *
 * @param {string} status
 * @param {string} packageId
 * @param {double} latitude
 * @param {double} longitude
 * @param {string} receiverName
 * @param {string} receiverDocument
 * @param {string} receiverDocumentType
 * @param {string} receiverSignature
 * @param {string} process
 * @param {string} destination
 * @param {string} updateStatusTime ISO 8601
 * @param {string} safeDeliveryTokenAttempts
 * @param {string} offerId
 * @returns {object} Axios' response object containing the following information
 * {
 *   // `data` is the response that was provided by the server
 *   data: {},
 *
 *   // `status` is the HTTP status code from the server response
 *   status: 200,
 *
 *   // `statusText` is the HTTP status message from the server response
 *   statusText: 'OK',
 *
 *   // `headers` the headers that the server responded with
 *   // All header names are lower cased
 *   headers: {},
 *
 *   // `config` is the config that was provided to `axios` for the request
 *   config: {},
 *
 *   // `request` is the request that generated this response
 *   // It is the last ClientRequest instance in node.js (in redirects)
 *   // and an XMLHttpRequest instance the browser
 *   request: {}
 * }
 * Extracted from Axios' README: https://github.com/axios/axios
 *
 */
export default function updateStatusService({
  status,
  packageId,
  offerId,
  problemDetails,
  latitude,
  longitude,
  receiverName,
  receiverDocument,
  receiverDocumentType,
  receiverSignature,
  updateStatusTime,
  safeDeliveryTokenAttempts,
  deliveryEvidenceSignedUrl,
  digitalSignatureSignedUrl,
  driverNotes,
  recipientRelationship,
  deliveryLocationCode,
  experiments
}) {
  const requestBody = {
    packageIds: [packageId],
    offerId,
    newPackageStatus: status,
    problemDetails,
    signatureContent: receiverSignature,
    recipientName: receiverName,
    recipientDocument: receiverDocument,
    position: {
      lat: latitude,
      lng: longitude
    },
    updateStatusTime,
    safeDeliveryTokenAttempts,
    deliveryEvidenceSignedUrl,
    digitalSignatureSignedUrl,
    driverNotes,
    recipientRelationship,
    deliveryLocationCode,
    experiments
  };
  if (receiverDocumentType) {
    requestBody.recipientDocumentType = receiverDocumentType;
  }
  return api.post('/last-mile/v1/packages/status-update', requestBody);
}
