const DOCUMENT_KEY = 'clientDocument';

function getClientDocument() {
  return JSON.parse(localStorage.getItem(DOCUMENT_KEY)) || '';
}

function clearClientDocument() {
  localStorage.removeItem(DOCUMENT_KEY);
}

function setClientDocument(name, document) {
  localStorage.setItem(DOCUMENT_KEY, JSON.stringify({ name, document }));
}

export { getClientDocument, clearClientDocument, setClientDocument };
