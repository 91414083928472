import React, { useState } from 'react';
import firebase from 'firebase/app';
import SharedPropTypes from 'view/shared-prop-types';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Typography, Button, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  formatAddress,
  capitalizeString,
  parseDateToHours
} from 'view/text-formatters';
import {
  colors,
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { PAGES } from 'view';
import RecipientPhone from 'view/molecules/recipient-phone';
import Alert, { ALERT_TYPES } from 'view/atoms/alert';
import { getPhoneMask } from 'operations/proxy-phone';
import {
  DestinationDrawer,
  STATE_ENUM as DRAWER_STATE
} from 'view/molecules/destination-drawer';
import {
  splitLoggiAddress,
  isValidHourMinuteFormat,
  buildLoggiAddressV2
} from 'view/utils';
import { featureSwitches } from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import getDeadlineCollectMessage from 'operations/deadline-time';
import AssignmentIndex from 'view/molecules/assignment-row/assignment-index';
import messages from './messages';
import { pickupFlowEnum } from './constants';

export default function PickupDetails({
  pickup,
  handleNextPage,
  handleProblemsPage
}) {
  const enableNewPickupDetails = useFeatureSwitch(
    featureSwitches.enableNewPickupAndPackageDetails
  );

  const [destinationDrawerState, setDestinationDrawerState] = useState(
    DRAWER_STATE.CLOSED
  );
  const [notificationMessage, setNotificationMessage] = useState('');

  const hasCompanyAndResponsibleName =
    pickup.shipper.name && pickup.shipper.companyName;

  const addressLines = buildLoggiAddressV2(pickup?.addressDestination);
  const hasLoggiAddress = !!addressLines;
  const { addressTitle, addressBody } = splitLoggiAddress(addressLines);

  const pickupDestination = hasLoggiAddress
    ? pickup?.addressDestination
    : pickup.destination;

  const history = useHistory();

  const deadlineTimeMessage = getDeadlineCollectMessage({
    isPickup: true,
    deadlineTime: pickup.deadlineCollectTime,
    startTime: pickup.startTime,
    showNewDeadlineTime: true
  });
  return (
    <>
      <Alert
        open={!!notificationMessage}
        message={notificationMessage}
        onClose={() => setNotificationMessage('')}
        startAdornment={ALERT_TYPES.SUCCESS.startAdornment}
        color={ALERT_TYPES.SUCCESS.color}
      />
      <Grid
        container
        direction="column"
        style={{
          backgroundImage: colors.gradients.sanches,
          minHeight: '100%'
        }}
      >
        <Box p={2} pl={0.5} color={colors.root[0]}>
          <Grid container justify="space-between" alignItems="center">
            <IconButton
              onClick={() => {
                /**
                 * Using replace instead of goBack because this is inside of a nested component,
                 * and by doing goBack we can fall into a loop of screens pushed.
                 * By adding replace, we know exactly where the go back button should go next.
                 */
                history.replace(PAGES.PACKAGE_LIST);
              }}
              data-testid="back"
            >
              <ArrowBack style={{ color: colors.root[0] }} />
            </IconButton>

            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                setDestinationDrawerState(DRAWER_STATE.OPEN);
              }}
            >
              {messages.pickupDetails.buttonNavigation}
            </Button>
          </Grid>
        </Box>

        <Box
          px={2.5}
          py={5}
          color={colors.root[0]}
          display="flex"
          flexGrow={1}
          alignItems="flex-end"
        >
          <Box>
            {enableNewPickupDetails ? (
              <>
                <Box mb={1}>
                  <ThemeProvider
                    theme={getThemeForUserType({
                      userType: userTypeEnum.drivers,
                      version: availableThemeVersions.drivers.v2
                    })}
                  >
                    <AssignmentIndex
                      assignmentIndex={pickup?.displayId}
                      shouldShowScheduledPickupIcon={pickup?.isCapacityReserve}
                      useNewTheme
                      fromDetails
                    />
                  </ThemeProvider>
                </Box>
                <Box
                  data-testid="pickup-detail-deadline-collect"
                  color={colors.blue[50]}
                  mb={0.5}
                >
                  <Typography variant="body2" style={{ fontWeight: 500 }}>
                    {deadlineTimeMessage.charAt(0).toUpperCase() +
                      deadlineTimeMessage.slice(1)}
                  </Typography>
                </Box>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  {messages.pickupDetails.actionText.toDo}
                </Typography>
                <Box pt={1} color={colors.blue[50]} data-testid="address">
                  <Typography variant="body1">
                    {hasLoggiAddress
                      ? `${addressTitle} ${addressBody}`
                      : `${formatAddress({
                          destination: pickup.destination
                        })} ${capitalizeString(pickup.destination.vicinity)}`}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box pb={1.5} color={colors.blue[50]}>
                  <Typography variant="body2">
                    {isValidHourMinuteFormat(
                      parseDateToHours(pickup.deadlineCollectTime)
                    ) ? (
                      <Box
                        component="span"
                        data-testid="pickup-detail-deadline-collect"
                      >
                        {messages.pickupDetails.collectUntil}{' '}
                        <Box component="span" fontWeight="bold">
                          {parseDateToHours(pickup.deadlineCollectTime)}
                        </Box>{' '}
                        {messages.pickupDetails.textIn}
                      </Box>
                    ) : (
                      messages.pickupDetails.title
                    )}
                  </Typography>
                </Box>
                <Typography variant="h4">
                  {hasLoggiAddress
                    ? addressTitle
                    : formatAddress({ destination: pickup.destination })}
                </Typography>

                <Box
                  pt={1.5}
                  color={colors.blue[50]}
                  data-testid="address-vicinity"
                >
                  <Typography variant="body2">
                    {hasLoggiAddress
                      ? addressBody
                      : capitalizeString(pickup.destination.vicinity)}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box
          px={2.5}
          pt={2.5}
          bgcolor={colors.root[0]}
          style={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px'
          }}
        >
          <Grid container alignItems="center">
            <Grid xs item>
              {hasCompanyAndResponsibleName ? (
                <>
                  <Box pt={2.5} color={colors.smoke[800]}>
                    <Typography variant="body2">
                      Procurar na {pickup.shipper.companyName} por
                    </Typography>
                  </Box>
                  <Box py={1.5}>
                    <Typography variant="h5">{pickup.shipper.name}</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box pt={2.5} color={colors.smoke[800]}>
                    <Typography variant="body2">
                      {messages.pickupDetails.shipperLabel}
                    </Typography>
                  </Box>
                  <Box py={1.5}>
                    <Typography variant="h5">
                      {pickup.shipper.companyName || pickup.shipper.name}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            {pickup?.pickupFlow !== pickupFlowEnum.useXDAppAgencyTransfer && (
              <Grid item data-testid="phone">
                <Box ml={3.5}>
                  <RecipientPhone
                    getPhone={() => {
                      firebase.analytics().logEvent('phone_call', {
                        page_title: 'package_details_ui'
                      });
                      return getPhoneMask(
                        pickup.shipper.phone,
                        pickup.pickupCode
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          <Box pt={5} pb={2.5}>
            <Grid container spacing={1} wrap="nowrap">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleProblemsPage}
                >
                  {messages.pickupDetails.buttonProblems}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={handleNextPage}
                >
                  {messages.pickupDetails.buttonStartPickup}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <DestinationDrawer
        drawerState={destinationDrawerState}
        setDrawerState={setDestinationDrawerState}
        notificationFunction={setNotificationMessage}
        destination={pickupDestination}
      />
    </>
  );
}

PickupDetails.propTypes = {
  pickup: SharedPropTypes.pickup.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handleProblemsPage: PropTypes.func.isRequired
};
