import { makeStyles } from '@material-ui/core';
import driverImg from 'view/assets/driver.jpg';

export const SMS_SIGN_IN_BUTTON_ID = 'sms-sign-in-button';

export const onboardingPageTexts = {
  titleCognitoSms: 'Bem-vindo(a) a Loggi para Entregar',
  subtitleCognitoSms:
    'A partir de agora você também pode entrar com sua conta Google.',
  title: 'Fazer entregas é nosso negócio.',
  subtitle: 'Locais e nacionais, para conectar o Brasil.',
  titleCognito: 'Conta Google não está associada',
  notFoundAccount: ', não encontramos uma conta Loggi associada ao email:',
  titleNotAssociateCognito: 'A conta não está associada a um(a) entregador(a)',
  notFoundAccountAction: ', mas você pode associar seu cadastro existente.',
  questionNotAssociateCognito:
    'Deseja associar sua conta google a um telefone de entregador(a) existente ou cadastrar uma nova conta?',
  questionNotAssociateCognitoAlreadyDriver:
    'Deseja associar sua conta google a um telefone de entregador(a) existente?',
  createAccount: 'Cadastrar uma nova conta',
  loginAction: 'Associar com conta existente',
  wrongEmail: 'seu e-mail está errado?',
  logoutAction: 'Entrar com outra conta',
  help: 'Ficou com dúvida? Tire todas na',
  helpAction: 'Central de ajuda',
  helpLink: 'https://ajuda.loggi.com/hc/pt-br/sections/360010985111-Acesso',
  isNewUser: 'Novo(a) aqui?',
  signup: 'Cadastre-se',
  smsLogin: 'Entrar com SMS',
  googleLogin: 'Entrar com Google',
  alreadyDriver: 'Já entrego com a Loggi',
  associateAccount: 'Associar Conta',
  wantCreateAccount: 'Quero cadastrar uma nova conta'
};

export const useStyles = makeStyles(theme => ({
  headerDesktop: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundPositionX: 'center'
  },
  headerMobile: {
    height: '325px',
    width: '100%',
    backgroundPositionX: '-30px'
  },
  header: {
    backgroundImage: `url(${driverImg})`,
    backgroundSize: 'cover',
    width: '100%'
  },
  contentGridDesktop: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentGridMobile: {
    height: 'calc(100% - 325px)'
  },
  contentTextDesktop: {
    width: '50%'
  },
  contentTextMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  cognitoSmsBox: {
    paddingTop: `65%`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }
}));
