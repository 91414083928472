import React, { useState } from 'react';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Drawer,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useRoundedBorder } from 'view/utils/styles';

const texts = {
  inputTitle: 'Digite o código do pacote',
  inputSubtitle:
    'É possível achar os números perto do código de barras. Em alguns casos, podem ter letras no início, como LG da Loggi.',
  buttonClose: 'Fechar',
  buttonAdd: 'Adicionar'
};

export const drawerBarcodeInputStates = {
  idle: 'idle',
  closed: 'closed',
  checkingBarcode: 'checkingBarcode'
};

export default function BarcodeInput({ onClose, onSubmit, drawerState }) {
  const [barcode, setBarcode] = useState('');
  const roundedBorder = useRoundedBorder();

  const handleSubmit = event => {
    event.preventDefault();
    if (barcode) {
      onSubmit(barcode);
      setBarcode('');
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={drawerState !== drawerBarcodeInputStates.closed}
      classes={{ paper: roundedBorder.root }}
    >
      <Box px={3.5}>
        <Box pt={3.5}>
          <Typography variant="h5" paragraph>
            {texts.inputTitle}
          </Typography>
          <Typography variant="body1">{texts.inputSubtitle}</Typography>
          <Box borderRadius={4} boxShadow={13}>
            <Box mt={2.5}>
              <form onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  placeholder="ex: LG764654654"
                  fullWidth
                  autoFocus
                  value={barcode}
                  onInput={e => setBarcode(e.target.value)}
                />
                <Box mt={2.75}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    data-testid="button-add-barcode"
                  >
                    {drawerState ===
                    drawerBarcodeInputStates.checkingBarcode ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      texts.buttonAdd
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
            <Box my={1}>
              <Button
                data-testid="button-close-barcode-drawer"
                my={1}
                background={colors.root[0]}
                color="primary"
                onClick={onClose}
                fullWidth
              >
                {texts.buttonClose}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

BarcodeInput.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  drawerState: PropTypes.string.isRequired
};
