import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Snackbar, SnackbarContent } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { variantColors, variantIcon } from './constants';

const autoHideDuration = 5000;

const SnackbarAlert = forwardRef((props, ref) => {
  const { id, open, message, variant } = props;
  const Icon = variantIcon[variant];
  const { backgroundColor, textColor } = variantColors[variant] || {};

  const { closeSnackbar } = useSnackbar();

  return (
    <Snackbar
      ref={ref}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => closeSnackbar(id)}
      onClick={() => closeSnackbar(id)}
    >
      <SnackbarContent
        style={{
          backgroundColor
        }}
        message={
          <Box
            display="flex"
            alignItems="center"
            data-testid="snackbar-content"
          >
            <Box pr={2.5}>
              <Icon />
            </Box>
            <Typography variant="body1" style={{ color: textColor }}>
              {message}
            </Typography>
          </Box>
        }
      />
    </Snackbar>
  );
});

SnackbarAlert.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default SnackbarAlert;
