import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Chip, Grid } from '@material-ui/core';
import calendarIcon from 'view/molecules/capacity-reserve-list-button/calendar.svg';

import {
  parseISODateToHours,
  getDiffInDaysBetweenDateAndNow
} from '../../offer-card/util';

const getStartDayString = collectStartTime => {
  const days = getDiffInDaysBetweenDateAndNow(collectStartTime);

  return days === 0 ? 'Hoje' : 'Amanhã';
};

const CapacityReserveToolBody = ({
  collectStartTime,
  collectEndTime,
  regionName
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ height: '100%' }}
      data-testid="capacity-reserve-tool-body"
    >
      <Grid item xs>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <img src={calendarIcon} alt="Ícone da programação" />
          <Chip label="Programada" variant="outlined" size="small" />
        </Box>
      </Grid>
      <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1">
          Retirada <strong>{getStartDayString(collectStartTime)}</strong>, das{' '}
          {parseISODateToHours(collectStartTime)} às{' '}
          {parseISODateToHours(collectEndTime)}
        </Typography>
      </Grid>
      <Grid item xs>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          height="100%"
        >
          <Typography variant="body2" color="textSecondary">
            Entregue em {regionName}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

CapacityReserveToolBody.defaultProps = {
  regionName: 'região a definir'
};

CapacityReserveToolBody.propTypes = {
  collectStartTime: PropTypes.string.isRequired,
  collectEndTime: PropTypes.string.isRequired,
  regionName: PropTypes.string
};

export default CapacityReserveToolBody;
