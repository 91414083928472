import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import PickupProblemsHeader from 'view/molecules/pickup-problems-header';
import { Box } from '@material-ui/core';

export default function PickupProblemsTemplate({
  title,
  subtitle,
  onGoBack,
  children
}) {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <PickupProblemsHeader
          title={title}
          subtitle={subtitle}
          onGoBack={onGoBack}
        />
        {children}
      </Box>
    </ThemeProvider>
  );
}

PickupProblemsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
