import { parseDateToHours } from 'view/text-formatters';
import { isValidHourMinuteFormat } from 'view/utils';
import { messages } from './constants';

export const formatStartTimeMessage = (startTime, deadLineCollectValue) => {
  const datetime = new Date(startTime);
  const time = datetime.toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit'
  });
  return `${time} até ${deadLineCollectValue}`;
};

export const getStartTimeDay = dateParam => {
  const datetimeFromParam = new Date(dateParam);

  const date = datetimeFromParam.toDateString();
  const today = new Date().toDateString();

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const tomorrow = tomorrowDate.toDateString();

  if (date === today) {
    return 'hoje';
  }

  if (date === tomorrow) {
    return 'amanhã';
  }

  const dateFormatOptions = { month: '2-digit', day: '2-digit' };
  return datetimeFromParam.toLocaleDateString('pt-br', dateFormatOptions);
};

export default function getDeadlineCollectMessage({
  isPickup,
  deadlineTime,
  startTime,
  showStartTime = false,
  showNewDeadlineTime = false,
  commaAsSeparator = true
}) {
  const deadLineCollectValue = parseDateToHours(deadlineTime);
  const deadlineDefaultMessage = isPickup ? messages.now : messages.today;

  if (showNewDeadlineTime) {
    if (showStartTime) {
      const separator = commaAsSeparator ? ', ' : ' de ';
      return isValidHourMinuteFormat(deadLineCollectValue)
        ? `${getStartTimeDay(startTime)}${separator}${formatStartTimeMessage(
            startTime,
            deadLineCollectValue
          )}`
        : deadlineDefaultMessage;
    }
    return isValidHourMinuteFormat(deadLineCollectValue)
      ? `${deadlineDefaultMessage}, ${messages.until} ${deadLineCollectValue}`
      : deadlineDefaultMessage;
  }

  if (showStartTime)
    return isValidHourMinuteFormat(deadLineCollectValue)
      ? `${getStartTimeDay(startTime)}, ${formatStartTimeMessage(
          startTime,
          deadLineCollectValue
        )}`
      : '';

  return isValidHourMinuteFormat(deadLineCollectValue)
    ? `${messages.until} ${deadLineCollectValue}`
    : deadlineDefaultMessage;
}
