import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Drawer, Box, Typography, Button } from '@material-ui/core';
import { PAGES } from 'view';
import SharedPropTypes from 'view/shared-prop-types';
import { DELIVERY_OPTIONS_MAP } from 'view/pages/delivery-options/constants';

const statesEnum = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  isOpen: state => state !== statesEnum.CLOSED
};

const messages = {
  title: 'Você pode deixar esse pacote na caixa de correspondência',
  subtitle: 'Caso a casa possua uma, você pode depositar o seu pacote ali.',
  button: 'Ok, vou deixar',
  failureButton: 'Não é possível entregar'
};

function DrawerMailBox({ drawerState, setDrawerState, pkg }) {
  const history = useHistory();
  const sendToProtocol = () =>
    history.push({
      pathname: PAGES.PROTOCOL,
      state: {
        pkg,
        deliveryOption: DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY
      }
    });

  useEffect(() => {
    setDrawerState(statesEnum.OPEN);
  }, [setDrawerState]);

  return (
    <Drawer
      anchor="bottom"
      open={statesEnum.isOpen(drawerState)}
      onClose={() => setDrawerState(statesEnum.CLOSED)}
      PaperProps={{ square: false, component: Box, mb: -3 }}
    >
      <Box pb={4}>
        {statesEnum.isOpen(drawerState) && (
          <Box px={3} pt={4}>
            <Box>
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                {messages.title}
              </Typography>
            </Box>

            <Box pt={2.5}>
              <Typography>{messages.subtitle}</Typography>
            </Box>

            <Box pt={2.5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={sendToProtocol}
              >
                {messages.button}
              </Button>
            </Box>

            <Box py={2.5} textAlign="center">
              <Typography
                color="primary"
                style={{ fontWeight: '600' }}
                onClick={() => setDrawerState(statesEnum.CLOSED)}
              >
                {messages.failureButton}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}

DrawerMailBox.propTypes = {
  drawerState: string.isRequired,
  setDrawerState: func.isRequired,
  pkg: SharedPropTypes.package.isRequired
};

export { DrawerMailBox, statesEnum, messages };
