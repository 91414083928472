import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import useSanchesBackground from './styles';

import TEXT from './constants';

function SuccessSync({ onContinue, title, buttonTitle }) {
  const sanchesBackgroundStyles = useSanchesBackground();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      color="white"
      px={3.5}
      classes={sanchesBackgroundStyles}
      data-testid="success-sync-template"
    >
      <CheckCircleOutline style={{ fontSize: '80px' }} />

      <Box pt={5} pb={3.5}>
        <Typography color="inherit" variant="subtitle1" align="center">
          {title}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        onClick={onContinue}
        data-testid="continue-delivering-button"
      >
        {buttonTitle}
      </Button>
    </Box>
  );
}

SuccessSync.defaultProps = {
  title: TEXT.TITLE,
  buttonTitle: TEXT.BUTTON_CONTINUE
};

SuccessSync.propTypes = {
  onContinue: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonTitle: PropTypes.string
};

export default React.memo(SuccessSync);
