import { useState } from 'react';
import {
  shouldShowPackageReturnReminder,
  userHasReadPackageReturnReminder
} from 'operations/package-return';

function useReminder() {
  const [shouldShowPopup, setShowPopup] = useState(
    // using lazy init here
    // @see https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    shouldShowPackageReturnReminder
  );

  const closePopup = () => {
    userHasReadPackageReturnReminder();
    setShowPopup(false);
  };

  return [shouldShowPopup, closePopup];
}

export default useReminder;
