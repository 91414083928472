import React from 'react';

import SnackbarAlert from './snackbar-alert';

const showSnackbar = ({ message, variant, enqueueSnackbar }) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <SnackbarAlert open id={key} message={msg} variant={variant} />
    )
  });
};

export default showSnackbar;
