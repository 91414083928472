import capitalizeString from './capitalize-string';

export const distanceFormatter = new Intl.NumberFormat('pt-BR', {
  maximumFractionDigits: 1
});

export const formatAddress = ({ destination }) =>
  `${capitalizeString(destination.addressStreet)}, ${
    destination.addressNumber
  }`;

export const formatAddressWithoutZip = ({ destination }) =>
  `${capitalizeString(destination.addressStreet)}, ${
    destination.addressNumber
  } - ${destination.vicinity}, ${destination.city}, ${destination.state}`;

export const formatDistance = distanceInMeters => {
  if (distanceInMeters <= 0) {
    return '';
  }

  const distanceInKm = distanceInMeters / 1000;
  const formatedDistance = distanceFormatter.format(distanceInKm);

  return `${formatedDistance} km`;
};

/**
 * @param {Object} args
 * @param {string} args.vicinity
 * @param {number} args.distance - distance in meters
 * @returns {string} - formatted string with distance and vicinity
 */
export const formatVicinity = ({ vicinity, distance = 0, city, state }) => {
  const info = {
    vicinity: vicinity ? `${capitalizeString(vicinity)}, ` : '',
    distance: distance ? `${formatDistance(distance)} • ` : '',
    city: city ? `${capitalizeString(city)} - ` : '',
    state: state ? state.toUpperCase() : ''
  };
  return `${info.distance}${info.vicinity}${info.city}${info.state}`;
};

/**
 * @param {Object} destination
 * @param {string} destination.vicinity
 * @param {string} destination.number
 * @param {string} destination.complement
 * @param {string} destination.city
 * @param {string} destination.state
 * @param {string} destination.zipCode
 * @param {string} destination.lat
 * @param {string} destination.lng
 * @param {boolean} useZipCode
 * @returns {string} - formatted string with full address
 */
export const formatFullAddress = (destination, { useZipCode = true } = {}) => {
  const address = formatAddress({ destination });
  const cityState = `${destination.city} - ${destination.state}`;

  const { vicinity, zipCode } = destination;
  const zipCodeText = useZipCode ? `${zipCode} - ` : '';

  return `${address} - ${zipCodeText}${vicinity}, ${cityState}`;
};
