import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';

import STATUS_CODE_MAPPER from './constants';

function StatusCodeChip({ status, useNewTheme }) {
  const isValidStatus = status in STATUS_CODE_MAPPER;

  return (
    isValidStatus && (
      <Chip
        data-testid="status-code-chip"
        icon={STATUS_CODE_MAPPER[status].icon}
        size={STATUS_CODE_MAPPER[status].size}
        label={STATUS_CODE_MAPPER[status].label}
        style={
          useNewTheme
            ? STATUS_CODE_MAPPER[status].style2
            : STATUS_CODE_MAPPER[status].style
        }
      />
    )
  );
}

StatusCodeChip.propTypes = {
  status: PropTypes.number.isRequired,
  useNewTheme: PropTypes.bool
};

StatusCodeChip.defaultProps = {
  useNewTheme: false
};

export default StatusCodeChip;
