import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, LinearProgress } from '@material-ui/core';

const getBarProgress = (currentCounter, totalCounter) => {
  if (totalCounter <= 0) {
    return 1;
  }
  return currentCounter > totalCounter
    ? 100
    : Math.ceil((currentCounter * 100) / totalCounter);
};
export default function PackageCounter({ totalCounter, currentCounter }) {
  const barProgress = getBarProgress(currentCounter, totalCounter);

  return (
    <>
      <Box py={1.5}>
        <Typography
          variant="body2"
          style={{ fontWeight: 700, alignSelf: 'center' }}
        >
          {`${currentCounter} de ${totalCounter}`}
        </Typography>
      </Box>
      <Box>
        <LinearProgress
          variant="determinate"
          value={barProgress}
          color="primary"
        />
      </Box>
    </>
  );
}

PackageCounter.propTypes = {
  totalCounter: PropTypes.number.isRequired,
  currentCounter: PropTypes.number.isRequired
};
