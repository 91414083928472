import { createMuiTheme } from '@material-ui/core/styles';
import { baseThemeProps } from '../../theme';
import themeV2 from './theme-v2';

export const availableThemeVersion = {
  default: 'default',
  v2: 'v2'
};

const getThemeDrivers = ({ version = availableThemeVersion.default }) => {
  if (version === availableThemeVersion.v2) {
    return createMuiTheme(themeV2);
  }
  return createMuiTheme(baseThemeProps);
};
export default getThemeDrivers;
