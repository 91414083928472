import React from 'react';
import { useLocation } from 'react-router-dom';

import Protocol from 'view/templates/protocol';

function ProtocolPage() {
  const location = useLocation();

  return <Protocol pkg={location.state} />;
}

export default ProtocolPage;
