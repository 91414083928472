import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';
import MESSAGES from './messages';

const useSanchesBackground = makeStyles({
  root: {
    background: colors.gradients.sanches
  }
});

export default function FakeLoading() {
  const [message, setMessage] = useState(MESSAGES.fakeLoading.mounting);
  const DISPLAY_MESSAGE_TIME = 1500;
  useEffect(() => {
    setTimeout(() => {
      setMessage(MESSAGES.fakeLoading.optimizing);
      setTimeout(
        () => setMessage(MESSAGES.fakeLoading.finishing),
        DISPLAY_MESSAGE_TIME
      );
    }, DISPLAY_MESSAGE_TIME);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      classes={useSanchesBackground()}
      color="white"
      px={3.5}
    >
      <Box pb={2.5}>
        <CircularProgress color="inherit" data-testid="fake-loading" />
      </Box>
      <Typography color="inherit" variant="subtitle1" align="center">
        {message}
      </Typography>
    </Box>
  );
}
