import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import DeliveringProblems from 'view/templates/delivering-problems';

function DeliveringProblemsPage() {
  const location = useLocation();
  const history = useHistory();

  return (
    <DeliveringProblems
      pkg={location.state}
      recipient={location.state.recipient}
      goBack={history.goBack}
    />
  );
}

export default DeliveringProblemsPage;
