import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button, IconButton, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function DrawerWithSteps({ open, title, steps, onCloseClick }) {
  const theme = useTheme();
  const { colors } = theme;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    onCloseClick();
  };

  return (
    <Drawer
      data-testid="drawer-with-steps"
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
      p={1.5}
    >
      <Box pl={2.5} pr={1} pt={3} display="flex" justifyContent="flex-end">
        <IconButton
          onClick={handleClose}
          data-testid="drawer-with-steps-close-button"
        >
          <CloseIcon style={{ color: colors.neutrals.typeface.primary }} />
        </IconButton>
      </Box>
      <Box px={2.5} color={colors.neutrals.typeface.primary}>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Box my={1.5}>
          {steps[activeStep].titleNode ? (
            <>{steps[activeStep].titleNode}</>
          ) : (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {steps[activeStep].title}
            </Typography>
          )}
          <Typography variant="body2" style={{ marginTop: 16 }}>
            {steps[activeStep].subtitle}
          </Typography>
        </Box>
        <Box>
          <MobileStepper
            style={{ justifyContent: 'center' }}
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
          />
        </Box>
      </Box>
      <Box display="flex" my={1.5} px={2.5}>
        {activeStep > 0 && (
          <Button
            data-testid="drawer-with-steps-left-button"
            color="primary"
            onClick={handleBackStep}
            style={{ marginRight: 10 }}
          >
            <ArrowBackIcon />
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            data-testid="drawer-with-steps-ok-button"
            variant="contained"
            color="primary"
            size="large"
            flex={2}
            style={{ flexGrow: 3 }}
            onClick={handleClose}
          >
            Ok, entendi
          </Button>
        ) : (
          <Button
            data-testid="drawer-with-steps-forward-button"
            disabled={activeStep === steps.length - 1}
            variant="contained"
            color="primary"
            size="large"
            style={{ marginLeft: 'auto' }}
            onClick={handleNext}
          >
            <ArrowForwardIcon />
          </Button>
        )}
      </Box>
    </Drawer>
  );
}

DrawerWithSteps.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      titleNode: PropTypes.node,
      title: PropTypes.string,
      subtitle: PropTypes.string
    })
  ),
  open: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired
};

DrawerWithSteps.defaultProps = {
  title: '',
  steps: [],
  open: false
};
