import React, { useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Typography, Divider, FormControl } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useRemoteConfig } from '@loggi/firebase-feature-switches';
import usePersistedState, {
  cleanPersistedState
} from 'hooks/use-persisted-state';
import {
  getSurveyConfig,
  sendSurveyAnswer,
  surveyPersistedStateKeys
} from 'operations/survey';
import { getCurrentUser } from 'operations/auth';
import { remoteConfigFlags } from 'operations/feature-switches';
import { PAGES } from 'view';
import CustomRadioButtonGroup from 'view/molecules/radio-button-group';
import CustomCheckboxGroup from 'view/molecules/custom-checkbox-group';
import surveyMachine, { states, transitions, configType } from './machine';
import SurveyDrawer from './drawer';
import ThankYouPage from './thankyou-page';
import SurveySkeleton from './skeleton';
import SurveyFooter from './footer';

function Survey() {
  const { state } = useLocation();
  const history = useHistory();
  const { surveySlug } = useParams();
  const { typography } = useTheme();
  const { pxToRem } = typography;

  const { id: driverId } = getCurrentUser() || {};
  const [singleValue, setSingleValue] = useState('');
  const [multipleValues, setMultipleValue] = useState([]);

  const [, setSurveyAnswered] = usePersistedState(
    `${surveyPersistedStateKeys.surveyAnswered.key}/${surveySlug}`,
    surveyPersistedStateKeys.surveyAnswered.default
  );

  const surveys = useRemoteConfig(
    remoteConfigFlags.surveys.name,
    remoteConfigFlags.surveys.defaultValue
  );

  const surveyRemoteConfig = surveys.availableSurveys.find(
    s => s.slug === surveySlug
  );

  const [current, send] = useMachine(surveyMachine, {
    actions: {
      goForNextPage: () => {
        const route = state?.routeToGo || PAGES.PACKAGE_LIST;
        delete state?.routeToGo;
        return history.replace(route, state);
      },
      saveAnsweredSurveyKey: () => {
        setSurveyAnswered(new Date().toISOString());
      },
      clearPersistedState: () => {
        cleanPersistedState(
          `${surveyPersistedStateKeys.surveyAnswered.key}/${surveySlug}`
        );
      }
    },
    guards: {
      noRemoteConfigFound: () => !surveyRemoteConfig
    },
    services: {
      answerSurvey: async context => {
        const extras =
          surveyRemoteConfig.stateFields
            ?.map(extra =>
              state[extra] ? { key: extra, value: state[extra] } : null
            )
            .filter(extra => extra) || [];
        return sendSurveyAnswer({
          surveySlug,
          driverId,
          options: context.selectedOptions,
          extras
        }).json();
      },
      getSurveyConfig: async () => {
        const { survey } = await getSurveyConfig(surveySlug).json();
        return survey;
      }
    }
  });

  const getSelectedOptions = () => {
    if (current.context.config.type === configType.singleChoice) {
      return current.context.options.filter(op => op.id === singleValue);
    }
    return multipleValues.filter(op => op.checked);
  };

  useEffect(() => {
    setMultipleValue(
      current.context.options?.map(option => {
        return {
          ...option,
          checked: false
        };
      })
    );
  }, [setMultipleValue, current.context.options]);

  const changeMultipleOption = (key, value) => {
    setMultipleValue(oldOptions =>
      oldOptions.map(option => {
        if (option.key === key) {
          return { ...option, checked: value };
        }
        return option;
      })
    );
  };

  return (
    <>
      {current.matches(states.introduction) && (
        <SurveyDrawer
          open={current.matches(states.introduction)}
          title={surveyRemoteConfig.introductionTitle}
          answerSurvey={() => send(transitions.fetchingConfig)}
        />
      )}
      {current.matches(states.acknowledgment) && (
        <ThankYouPage onConfirm={() => send(transitions.skip)} />
      )}
      {(current.matches(states.fetchingConfig) ||
        current.matches(states.confirm)) && (
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={3}>
            <CloseIcon
              data-testid="survey-close-button-loading"
              style={{ marginBottom: pxToRem(32) }}
              onClick={() => send(transitions.skip)}
            />
            <SurveySkeleton />
          </Box>
          <SurveyFooter disableButton onConfirm={() => ({})} />
        </Box>
      )}
      {current.matches(states.choose) && (
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={3}>
            <CloseIcon
              data-testid="survey-close-button"
              style={{ marginBottom: pxToRem(32) }}
              onClick={() => send(transitions.skip)}
            />
            <Typography
              variant="h5"
              style={{
                fontWeight: typography.fontWeightBold,
                marginBottom: pxToRem(16)
              }}
            >
              {current.context.config.title}
            </Typography>
            <Typography variant="body">
              {current.context.config.subTitle}
            </Typography>
          </Box>
          <Divider />
          <Box px={3} pt={3}>
            <FormControl mt={1.5} fullWidth>
              {current.context.config.type === configType.singleChoice ? (
                <CustomRadioButtonGroup
                  radioValues={current.context.options}
                  ariaLabel="survey-radio-options"
                  value={singleValue}
                  onValueChanged={setSingleValue}
                />
              ) : (
                <CustomCheckboxGroup
                  options={multipleValues}
                  ariaLabel="survey-checkbox-options"
                  onValueChanged={changeMultipleOption}
                />
              )}
            </FormControl>
          </Box>
          <SurveyFooter
            onConfirm={() =>
              send(transitions.confirm, {
                selectedOptions: getSelectedOptions()
              })
            }
            disableButton={getSelectedOptions().length === 0}
          />
        </Box>
      )}
    </>
  );
}

export default Survey;
