import React from 'react';
import { string, bool, element, func } from 'prop-types';
import { Typography, Box, Snackbar } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { ReactComponent as SuccessIcon } from 'view/atoms/icons/circle-confirmation.svg';

const autoHideDuration = 5000; // is defined in miliseconds

const SUCCESS_TYPE = {
  startAdornment: <SuccessIcon />,
  color: colors.smoke[900]
};

const ERROR_TYPE = {
  startAdornment: <Cancel fontSize="large" />,
  color: colors.red[700]
};

export const ALERT_TYPES = {
  SUCCESS: SUCCESS_TYPE,
  ERROR: ERROR_TYPE
};

function Alert({ startAdornment, message, open, onClose, color }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      ClickAwayListenerProps={{
        onClickAway: () => {} // disable default behavior of call onClose on click away
      }}
    >
      <Box
        onClick={onClose}
        bgcolor={colors.root[0]}
        p={2.5}
        m={1.5}
        borderRadius={8}
        boxShadow={13}
        width="100%"
        display="flex"
        alignItems="center"
        color={color}
      >
        <Box pr={2.5}>{startAdornment}</Box>

        <Typography variant="body1">{message}</Typography>
      </Box>
    </Snackbar>
  );
}

Alert.propTypes = {
  startAdornment: element.isRequired,
  message: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  color: string
};

Alert.defaultProps = {
  color: colors.smoke[900]
};

export default React.memo(Alert);
