/**
 * This module serves the purpose of exposing the history
 * object to calls outside React componentes. Its use is
 * discouraged unless absolutely necessary. Always prefer
 * using `useHistory`.
 *
 * @see {How do I access the history object outside of components?}
 * https://github.com/ReactTraining/react-router/blob/master/FAQ.md#how-do-i-access-the-history-object-outside-of-components
 */
import { createBrowserHistory } from 'history';
import { PAGES } from 'view';
import { googleMapsSurveyCalledFrom } from 'view/pages/assignment-list/google-maps/google-maps-survey';
import PickupPackages from 'view/pages/pickup/pickup-packages';

const history = createBrowserHistory({ basename: '/atividades' });
export const goTo = {
  itineraryDisassociation: ({ packagesInCustodyCount }) =>
    history.push(PAGES.ITINERARY_DISASSOCIATION, { packagesInCustodyCount }),
  itineraryDeadlineDeallocation: ({ allowedDelay, withoutItinerary }) => {
    history.push(PAGES.ROUTE_CANCELED, { allowedDelay, withoutItinerary });
  },
  offerNotification: ({ notificationType, eventAddress, deadline }) => {
    history.push({
      pathname: PAGES.OFFER_NOTIFICATION,
      state: {
        notificationType,
        eventAddress,
        deadline
      }
    });
  },
  noAtribution: () => {
    history.push(PAGES.NO_ATTRIBUTION);
  },
  googleMapsSurvey: earnings => {
    history.push({
      pathname: PAGES.GOOGLE_MAPS_SURVEY,
      page: googleMapsSurveyCalledFrom.finishShipment,
      earnings
    });
  },
  opportunisticOfferSurvey: earnings => {
    history.push({
      pathname: PAGES.OPPORTUNISTIC_OFFER_SURVEY,
      earnings
    });
  },
  authError: () => {
    history.push(PAGES.AUTH_ERROR);
  },
  assignmentWaypointDetails: assignmentWaypoint => {
    const { actions, ...withNoActions } = assignmentWaypoint;
    history.push(PAGES.STOP_POINT, { assignmentWaypoint: withNoActions });
  },
  pickupDetails: pickup => {
    const pathname = pickup.isCapacityReserve
      ? PAGES.CAPACITY_RESERVE_DETAILS
      : PAGES.PICKUP;
    history.push(pathname.replace(':pickupCode', pickup.pickupCode), pickup);
  },
  pickupPackages: pickup => {
    const basePathname = PAGES.PICKUP.replace(':pickupCode', pickup.pickupCode);
    history.push(`${basePathname}${PickupPackages.url}`, pickup);
  },
  assignmentList: () => {
    history.push(PAGES.PACKAGE_LIST);
  },
  survey: (surveySlug, state) => {
    history.push(PAGES.SURVEY.replace(':surveySlug', surveySlug), state);
  }
};

export default history;
