import { useState, useLayoutEffect } from 'react';

const ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait'
};

const getOrientationBySize = size =>
  size[0] > size[1] ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT;

/**
 * Returns the window orientation based on the calculation of width/height
 */
export default function useWindowOrientation() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return getOrientationBySize(size);
}

export { ORIENTATION };
