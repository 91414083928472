import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '@loggi/mar';
import { Box } from '@material-ui/core';

const Puller = ({ mt, height }) => (
  <Box width="100%" display="flex" justifyContent="center" mt={mt}>
    <Box
      width="2.5rem"
      height={height}
      bgcolor={colors.smoke[100]}
      borderRadius="2rem"
    />
  </Box>
);

Puller.propTypes = {
  mt: PropTypes.number,
  height: PropTypes.string
};

Puller.defaultProps = {
  mt: 1,
  height: '0.5rem'
};

export default React.memo(Puller);
