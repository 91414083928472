export default function moveGoogleMapsToNode(rootNode) {
  const mapNode = document.getElementById('map-container');

  if (mapNode === null) {
    return;
  }

  if (rootNode) {
    rootNode.appendChild(mapNode);
    return;
  }

  const mapBenchNode = document.getElementById('map-bench');
  if (mapBenchNode === null) {
    return;
  }
  mapBenchNode.appendChild(mapNode);
}
