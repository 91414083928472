import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Puller from 'view/molecules/puller';

const ShowcaseDrawer = ({ open, onClose, onOpen }) => {
  return (
    <SwipeableDrawer
      data-testid="ShowcaseDrawer"
      open={open}
      anchor="bottom"
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        style: {
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          height: window.innerHeight / 2,
          paddingTop: '24px'
        }
      }}
    >
      <Puller />
      <Box display="flex" flexDirection="column" height="100%" padding={3}>
        <Box>
          <Typography variant="body1">
            <Box fontWeight={400}>Novidades para você</Box>
          </Typography>
          <Typography variant="h5">
            <Box py={2}>
              <strong>Ofertas mais atrativas </strong>
              destacadas para você!
            </Box>
          </Typography>
          <Typography variant="body1">
            <Box pb={3}>
              Agora, nós destacamos na página inicial as ofertas com valores
              mais atrativos que se encaixam na sua rotina.
            </Box>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Button
            data-testid="OnCloseButton"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
          >
            Ok, entendi!
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

ShowcaseDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func
};

ShowcaseDrawer.defaultProps = {
  onOpen: () => ({})
};

export default ShowcaseDrawer;
