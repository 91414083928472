import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, LinearProgress } from '@material-ui/core';
import { colors } from '@loggi/mar';

import {
  shipmentHasFinished,
  useSimpleFinishShipment,
  useSimpleFinishShipmentStatusEnum as statusEnum
} from 'view/templates/order-resume';
import { PAGES } from 'view';

import { ReactComponent as PackageWarningIcon } from 'view/atoms/illustrations/package-with-warning-triangle.svg';
import { ReactComponent as PackageWaitingIcon } from 'view/atoms/illustrations/package-with-waiting-clock.svg';
import { ReactComponent as PackageIcon } from './icons/package.svg';

export const messages = {
  finishShipmentTitle: 'Terminou suas atividades?',
  finishShipmentWarningText:
    'Só finalize a rota se você entregou todos os pacotes',
  hadErrorWhenFinishingShipment:
    'Aconteceu algum erro para finalizar sua lista...',
  finishingShipment:
    'Finalizando sua lista de entregas para você receber novas ofertas e pacotes...',
  tryAgain: 'Tentar Novamente',
  iWantToFinish: 'Quero finalizar'
};

export function AutoFinishing() {
  return (
    <Box px={2.5} py={5}>
      <PackageWaitingIcon />
      <Box pt={2.5} color={colors.smoke[800]}>
        <Typography variant="body1">{messages.finishingShipment}</Typography>
      </Box>
      <Box pt={2.5}>
        <LinearProgress />
      </Box>
    </Box>
  );
}

export function HadErrorWhenAutoFinishing({ finishShipment }) {
  return (
    <Box px={2.5} py={5}>
      <PackageWarningIcon />
      <Box pt={2.5} color={colors.smoke[900]}>
        <Typography variant="body1">
          {messages.hadErrorWhenFinishingShipment}
        </Typography>
      </Box>
      <Box pt={2.5}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={finishShipment}
        >
          {messages.tryAgain}
        </Button>
      </Box>
    </Box>
  );
}
HadErrorWhenAutoFinishing.propTypes = {
  finishShipment: PropTypes.func.isRequired
};

export function ManualFinish({ finishShipment }) {
  return (
    <Box px={2.5} py={5}>
      <PackageIcon />
      <Box pt={2.5}>
        <Typography variant="subtitle1">
          {messages.finishShipmentTitle}
        </Typography>
      </Box>
      <Box pt={1.5} color={colors.smoke[700]}>
        <Typography variant="body1">
          {messages.finishShipmentWarningText}
        </Typography>
      </Box>
      <Box pt={3.5}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={finishShipment}
        >
          {messages.iWantToFinish}
        </Button>
      </Box>
    </Box>
  );
}
ManualFinish.propTypes = {
  finishShipment: PropTypes.func.isRequired
};

export default function FinishDelivering({ canTryAutoFinish = false }) {
  const history = useHistory();
  const { status, earnings } = useSimpleFinishShipment(canTryAutoFinish);

  const finishShipment = () => {
    history.push(PAGES.FINISH_DELIVERING);
  };

  if (!canTryAutoFinish) {
    return <ManualFinish finishShipment={finishShipment} />;
  }

  if (status === statusEnum.loading) {
    return <AutoFinishing />;
  }

  if (status === statusEnum.failure) {
    return <HadErrorWhenAutoFinishing finishShipment={finishShipment} />;
  }

  if (status === statusEnum.success) {
    shipmentHasFinished({ earnings });
  }

  // success case; user will be redirected by the hook
  return null;
}
FinishDelivering.propTypes = {
  canTryAutoFinish: PropTypes.bool.isRequired
};
