import * as Sentry from '@sentry/browser';
import hasCognitoAuthToken from 'operations/auth/cognito';
import { releaseTag } from '../../../package.json';

const dsn = process.env.REACT_APP_DSN_SENTRY;
const env = process.env.REACT_APP_SENTRY_ENV;
const sentryRelease = releaseTag.replace(/\//g, '-');

// Function that looks at navigator userAgent and use a regex to identify the os/model of the device being used
const setCurrentDevice = () => {
  const device = 'Unknown';
  // this block is commented for now because on Windows this step is breaking the application, we are investigating how
  // we can fix this and we will open a new PR uncommenting this step and fixing it.
  // if (navigator && navigator.userAgent) {
  //   /*
  //     The regex just fetches the first match between () as far as I understood about userAgent this is the reference to os/model
  //    */

  //   [, device] = navigator.userAgent.match(/\((\w+;.*?)\)\s/);
  // }

  Sentry.configureScope(scope => {
    scope.setTag('custom_device', device);
  });
};

const captureExceptionToSentry = exception => {
  Sentry.captureException(new Error(exception?.message));
};

export const setUserInSentry = async userData => {
  const isCognitoUser = await hasCognitoAuthToken();

  if (
    userData &&
    Object.keys(userData).length !== 0 &&
    userData.constructor === Object
  ) {
    Sentry.setUser({ ...userData, isCognitoUser });
  }
};

export const errorsToIgnore = [
  /**
   * This error is thrown by the browser's ResizeObserver API. In Flecha,
   * this is triggered by react-virtuoso in the AssignmentList. For more
   * context, see this PR: https://github.com/loggi/ui/pull/4798
   */
  /ResizeObserver loop limit exceeded/i,
  /**
   * This error is the same as the above, but it occours on Firefox browser
   */
  /ResizeObserver loop completed with undelivered notifications/i,

  /**
   * This error is thrown when the device is offline and tries to update
   * the service worker.
   */
  /A bad HTTP response code (403) was received when fetching the script/i
];

export function initSentry(overrides = {}) {
  Sentry.init({
    dsn,
    environment: env,
    release: `${sentryRelease}`,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
    beforeSend: (event, hint) => {
      const message = hint?.originalException?.message;
      // see: https://docs.sentry.io/platforms/node/guides/aws-lambda/configuration/filtering/hints/#hints-for-events
      const syntheticExceptionMessage = hint?.syntheticException?.message;
      const shouldIgnoreError = errorsToIgnore.reduce(
        (shouldIgnore, errorToIgnore) =>
          shouldIgnore ||
          message?.match(errorToIgnore) ||
          syntheticExceptionMessage?.match(errorToIgnore),
        false
      );
      if (shouldIgnoreError) {
        return null;
      }
      return event;
    },
    ignoreErrors: errorsToIgnore,
    ...overrides
  });
}

if (env) {
  initSentry();
  setCurrentDevice();
}

export default captureExceptionToSentry;
