import {
  isMutationStatusEqualToPackageStatus,
  statusCodes,
  sortPackagesByIndexAndDisplayId,
  isPackageMutationSynced
} from 'operations/update-status';
import { mutationStates } from 'models/package-mutation';
import {
  pickupFlowEnum,
  assignmentFlowEnum
} from 'view/pages/pickup/constants';
import { buildLoggiAddressV2 } from 'view/utils';

const isDelivered = pkg => pkg.status.code === statusCodes.DELIVERED;
const isSyncing = pkg => pkg.mutationSyncState === mutationStates.SYNCING;

const hasFailedToSync = pkg =>
  pkg.mutationSyncState === mutationStates.FAILED_TO_SYNC;

const shouldDisplayInPackageList = pkg =>
  !isDelivered(pkg) || isSyncing(pkg) || hasFailedToSync(pkg);

const formattedAddressToSearch = ({
  destination,
  addressDestination,
  recipient
}) => {
  const addressLines = buildLoggiAddressV2(addressDestination);
  const { name } = recipient;

  const hasLoggiAddress = !!addressLines;
  if (hasLoggiAddress) return `${addressLines} - ${name}`;

  const {
    addressStreet,
    addressNumber,
    city,
    vicinity,
    addressComplement
  } = destination;

  const complement = addressComplement ? `. ${addressComplement}` : '';

  return `${addressStreet}, ${addressNumber} - ${city}${complement} - ${vicinity} - ${name}`;
};

const normalize = str =>
  str
    .toLocaleLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const searchPackages = (search, packages) => {
  if (!search) return packages;

  const formatedSearch = normalize(search);
  const properties = ['barcode', 'packageId'];

  return packages.filter(
    pkg =>
      normalize(formattedAddressToSearch(pkg)).includes(formatedSearch) ||
      properties.some(prop => normalize(pkg[prop]).includes(formatedSearch))
  );
};

function isAssignmentWaypointPickup(waypoint) {
  return waypoint?.assignments?.[0].flow.includes('PICKUP');
}

function parseAssignmentWaypointToLastMilePickup(assignment) {
  let pickupFlow = pickupFlowEnum.automaticFill;

  if (assignment.assignments[0].flow === assignmentFlowEnum.useBarcodeBip) {
    pickupFlow = pickupFlowEnum.useBarcodeBip;
  } else if (
    assignment.assignments[0].flow === assignmentFlowEnum.useXDAppAgencyTransfer
  ) {
    pickupFlow = pickupFlowEnum.useXDAppAgencyTransfer;
  }

  return {
    destination: assignment.destination,
    addressDestination: assignment.destination,
    pickupFlow,
    pickupCode: assignment.assignments[0].pickupCode,
    pickupId: assignment.assignments[0].pickupId,
    pickupProblemEvidenceSignedUrl:
      assignment.assignments[0].pickupProblemEvidenceSignedUrl,
    deadlineCollectTime: assignment.deadlineTime,
    shipper: {
      ...assignment.assignments[0].contact,
      phone: assignment.assignments[0].contact?.phoneNumber
    },
    assignments: assignment.assignments,
    startTime: assignment.startTime,
    isCapacityReserve: assignment.assignments[0].isCapacityReserve,
    regionName: assignment.assignments[0].regionName
  };
}

const splitAssignmentsWaypointsIntoPickupsAndDeliveries = assignmentsWaypoint => {
  return assignmentsWaypoint.reduce(
    ([pickups, deliveries], waypoint) =>
      isAssignmentWaypointPickup(waypoint)
        ? [[...pickups, waypoint], deliveries]
        : [pickups, [...deliveries, waypoint]],
    [[], []]
  );
};

/**
 * Receive a list of packages and rearrange it according to the following rules:
 * - Packages unacked or with status not synced or with status different from the mutation status
 * - Packages delivered and synced and with status equal to the mutation status
 *
 * All packages are sorted by index and displayId
 * @param {*} packages Packages with ou without mutation
 * @returns {List<Package>} List of packages with mutation sorted by index and displayId
 */
const rearrangePackagesList = packages => {
  const packagesWithActionPriority = [];
  const deliveredPackages = [];

  packages.forEach(pkg => {
    if (
      pkg.status.code === statusCodes.DELIVERED &&
      isPackageMutationSynced(pkg.mutationSyncState) &&
      isMutationStatusEqualToPackageStatus(pkg)
    ) {
      deliveredPackages.push(pkg);
    } else {
      packagesWithActionPriority.push(pkg);
    }
  });

  return {
    packagesWithActionPriority: sortPackagesByIndexAndDisplayId(
      packagesWithActionPriority
    ),
    deliveredPackages: sortPackagesByIndexAndDisplayId(deliveredPackages)
  };
};

export {
  shouldDisplayInPackageList,
  searchPackages,
  isAssignmentWaypointPickup,
  parseAssignmentWaypointToLastMilePickup,
  splitAssignmentsWaypointsIntoPickupsAndDeliveries,
  rearrangePackagesList
};
