import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '@loggi/mar';

import { Box, Typography } from '@material-ui/core';

import getProxyPhone, { getPhoneMask } from 'operations/proxy-phone';

import { capitalizeString } from 'view/text-formatters';
import RecipientPhone from 'view/molecules/recipient-phone';

import { useRecipientPhoneStyles } from './styles';
import messages from './constants';

export default function PhoneBox({ recipient, packageId, phone }) {
  const recipientPhoneStyles = useRecipientPhoneStyles();

  /*
    Each parameter follows a specific rule.

    recipient: the phone contained in the recipient parameter should be encrypted due to the 
    sensitive nature of the data.

    packageId: retrieves the recipient (masked) phone by the package id. The package id is needed
    for data analysis purpose.

    phone: 'raw' phone. Makes a call using directly the href property. 
  */
  const getRecipientPhone = (() => {
    if (packageId) {
      return () => getProxyPhone(packageId);
    }
    if (phone) {
      return async () => ({ recipientPhone: phone });
    }

    return () => getPhoneMask(recipient.phone);
  })();

  return (
    <Box
      classes={recipientPhoneStyles}
      borderRadius={8}
      my={2.5}
      p={2}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box pb={0.5} color={colors.smoke[700]}>
          <Typography variant="body2">
            {recipient.title || messages.tryToSolve}
          </Typography>
        </Box>
        <Box color={colors.smoke[900]}>
          <Typography variant="subtitle1">
            {capitalizeString(recipient.name)}
          </Typography>
        </Box>
      </Box>

      <Box px={1.5} py={1.5} data-testid="recipient-phone">
        <RecipientPhone
          recipientName={recipient.name}
          getPhone={getRecipientPhone}
        />
      </Box>
    </Box>
  );
}

PhoneBox.defaultProps = {
  packageId: null,
  phone: null
};
PhoneBox.propTypes = {
  recipient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  packageId: PropTypes.string,
  phone: PropTypes.string
};
