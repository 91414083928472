import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@material-ui/core';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { featureSwitches } from 'operations/feature-switches';

export const messages = {
  labelNotes: 'Quer adicionar mais alguma informação?',
  deliveryOptionOthers: 'Quer adicionar mais alguma informação?',
  deliveryOptionOthersWithDocumentType:
    'Quer adicionar mais alguma informação?\nEx.: Vizinho do número 201',
  deliveryProblemsPlaceholder:
    'Conta pra gente o motivo pelo qual essa entrega não foi feita.'
};

export function Notes({ isSuccess, isDelivery, value, onChange }) {
  // This component is used in both the delivery success flow and the delivery failure flow.
  // The variable isSucces is passed as true for the success stream
  // and as false for the problem stream.

  const enabledShowCustomLabelFieldsPod = useFeatureSwitch(
    featureSwitches.enabledShowCustomLabelFieldsPod
  );
  const labelNotes = useFeatureSwitch(featureSwitches.podTitleOpenField);
  const podDescriptionWarning = useFeatureSwitch(
    featureSwitches.podDescriptionWarning
  );
  const podDescriptionPlaceHolder = useFeatureSwitch(
    featureSwitches.podDescriptionPlaceHolder
  );

  let NOTES_PLACEHOLDER_TEXT = messages.deliveryProblemsPlaceholder;

  if (isSuccess) {
    if (enabledShowCustomLabelFieldsPod) {
      NOTES_PLACEHOLDER_TEXT = podDescriptionPlaceHolder;
    } else {
      NOTES_PLACEHOLDER_TEXT = isDelivery
        ? messages.deliveryOptionOthersWithDocumentType
        : messages.deliveryOptionOthers;
    }
  }

  return (
    <>
      {!isDelivery && (
        <Box pt={4}>
          <Typography variant="body1">
            {enabledShowCustomLabelFieldsPod ? labelNotes : messages.labelNotes}
          </Typography>
        </Box>
      )}
      <Box borderRadius={4} boxShadow={13} mt={3}>
        <TextField
          variant="outlined"
          placeholder={NOTES_PLACEHOLDER_TEXT}
          multiline
          fullWidth
          data-testid="notes"
          rows={5}
          inputProps={{ maxLength: 140 }}
          value={value}
          onChange={onChange}
        />
      </Box>
      {enabledShowCustomLabelFieldsPod && (
        <Box pt={4}>
          <Typography variant="body1">{podDescriptionWarning}</Typography>
        </Box>
      )}
    </>
  );
}

Notes.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  isDelivery: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

Notes.defaultProps = {
  isDelivery: false
};

export default Notes;
