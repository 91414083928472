import apiLoggiWeb from 'operations/config/api/loggiweb';
import getPosition from '../geolocation';

export default async function getReturnPointsListDB() {
  const pos = await getPosition();
  const latLngQueryParameters =
    +pos.latitude && +pos.longitude
      ? `lat=${pos.latitude}&lng=${pos.longitude}&`
      : '';

  const url = `/last-mile/v1/shipment/return_points_list?${latLngQueryParameters}`;
  return apiLoggiWeb()
    .url(url)
    .get()
    .json();
}
