import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PAGES } from 'App';
import { ArrowBack } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import showSnackbar from 'view/atoms/snackbar/show-snackbar';
import { useSnackbar } from 'notistack';

import { playErrorBeep, playSuccessBeep } from 'view/molecules/barcode/sounds';
import identifyBarcodeAllocation from 'infra/services/identify-barcode';

import { ZebraCameraScanner } from 'view/molecules/barcode/zebra-camera-scanner';
import InputBarcodeDrawer from './input-barcode-drawer';
import LoadingPage from './loading';

export const messages = {
  title: (
    <Typography variant="h5">
      Leia o <b>código da saca</b> utilizando sua câmera
    </Typography>
  ),
  enterCodeButton: 'Digitar o código',
  errorNotFound: 'Código de barras não encontrado'
};

export default function BarcodeReaderPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { pickupId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleRead = async barcode => {
    setLoading(true);

    let offerId = null;
    if (typeof barcode === 'string') {
      const response = await identifyBarcodeAllocation(
        barcode.toUpperCase(),
        pickupId
      ).catch(err => {
        showSnackbar({
          message: err.message || messages.errorNotFound,
          variant: 'error',
          enqueueSnackbar
        });
        playErrorBeep();
      });
      offerId = response?.offerId;
    }

    setLoading(false);
    if (!offerId) {
      return;
    }

    playSuccessBeep();

    history.push({
      pathname: PAGES.OFFER_SCREEN.replace(':offerId', offerId),
      search: location.search,
      state: { goToOfferDetails: pickupId }
    });
  };

  return (
    <>
      {loading && !openDrawer ? (
        <LoadingPage />
      ) : (
        <>
          <Box height="100vh" display="flex" flexDirection="column">
            <Box align="start" px={1} py={2}>
              <IconButton
                onClick={() => history.goBack()}
                data-testid="onBackIconButton"
              >
                <ArrowBack style={{ color: colors.smoke[900] }} />
              </IconButton>
            </Box>
            <Box
              height="100vh"
              pb={2}
              px={3}
              alignItems="stretch"
              display="flex"
              flexDirection="column"
            >
              <Box flexGrow={1}>
                <Box color={colors.root[900]}>
                  <Typography variant="h5">{messages.title}</Typography>
                </Box>
                <Box
                  my={2.5}
                  data-testid="camera-scanner"
                  height="140px"
                  p={0.5}
                >
                  <ZebraCameraScanner onDetected={handleRead} />
                </Box>
              </Box>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => setOpenDrawer(true)}
              >
                {messages.enterCodeButton}
              </Button>
            </Box>
          </Box>
          <InputBarcodeDrawer
            isOpen={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onBarcodeRead={handleRead}
            loading={loading}
          />
        </>
      )}
    </>
  );
}
