import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import getDeadlineCollectMessage, { messages } from 'operations/deadline-time';
import { statusCodes } from 'operations/update-status/update-status-codes';
import { messages as packageDetailsMessages } from 'view/templates/package-details/constants';
import pickupMessages from 'view/pages/pickup/messages';
import { unsucessfulStatusCodes } from 'view/atoms/status-code-chip/constants';

function DeadlineTime({
  isPickup,
  deadlineTime,
  startTime,
  showStartTime,
  showNewDeadlineTime,
  statusCode,
  isCapacityReserve
}) {
  const isUnsuccessfulDelivery = unsucessfulStatusCodes.includes(statusCode);

  const newSelectMessageType = () => {
    if (isPickup) {
      return pickupMessages.pickupDetails.actionText.toDo;
    }
    return statusCode === statusCodes.DELIVERED
      ? packageDetailsMessages.actionText.done
      : packageDetailsMessages.actionText.toDo;
  };

  const selectMessageType = () => {
    return isPickup ? messages.collect : messages.deliver;
  };

  const notCapacityReserve = !isCapacityReserve;
  const deadlineCollectMessage = getDeadlineCollectMessage({
    isPickup,
    deadlineTime,
    startTime,
    showStartTime,
    showNewDeadlineTime,
    commaAsSeparator: notCapacityReserve
  });

  if (isCapacityReserve) {
    return (
      <Box display="flex" alignItems="center">
        <Typography
          variant="subtitle2"
          style={{
            color: '#666666',
            marginBottom: '5px'
          }}
        >
          {messages.pickUp} {deadlineCollectMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      {showNewDeadlineTime ? (
        <Box mb={1}>
          <Typography
            variant="subtitle2"
            style={{
              color: '#666666',
              marginBottom: '5px',
              textDecoration: isUnsuccessfulDelivery ? 'line-through' : 'none'
            }}
          >
            {deadlineCollectMessage}
          </Typography>
          <Typography
            variant="h6"
            style={{
              color: isUnsuccessfulDelivery ? '#666666' : colors.smoke[900],
              textDecoration: isUnsuccessfulDelivery ? 'line-through' : 'none'
            }}
            fontWeight="bold"
            textAlign="center"
            data-testid="deadline-action-title"
          >
            {newSelectMessageType()}
          </Typography>
        </Box>
      ) : (
        <Typography>
          <Box
            component="span"
            display="inline"
            color={colors.blue[500]}
            fontWeight="bold"
            textAlign="center"
          >
            {selectMessageType()}{' '}
          </Box>

          <Box component="span" display="inline" color={colors.blue[500]}>
            {deadlineCollectMessage}
          </Box>
        </Typography>
      )}
    </Box>
  );
}

DeadlineTime.propTypes = {
  deadlineTime: PropTypes.string,
  startTime: PropTypes.string,
  isPickup: PropTypes.bool,
  showStartTime: PropTypes.bool,
  showNewDeadlineTime: PropTypes.bool,
  statusCode: PropTypes.number,
  isCapacityReserve: PropTypes.bool
};

DeadlineTime.defaultProps = {
  deadlineTime: '',
  startTime: '',
  isPickup: false,
  showStartTime: false,
  showNewDeadlineTime: false,
  statusCode: statusCodes.PICKUP,
  isCapacityReserve: false
};

export default DeadlineTime;
