import React from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import getReturnPointsListDB from 'operations/return-points-list';
import {
  NearbyDC,
  NearbyDCs,
  HowToReturn,
  QuestionsAboutPackageReturn
} from './components';
import messages from './messages';

function PackageReturnPage() {
  // based on react-router nesting example
  // @see https://reactrouter.com/web/example/nesting

  const { path } = useRouteMatch();

  const history = useHistory();
  const onOk = () => history.goBack();

  return (
    <Switch>
      <Route exact path={path}>
        <HowToReturn />
      </Route>
      <Route path={`${path}${QuestionsAboutPackageReturn.url}`}>
        <QuestionsAboutPackageReturn onOk={onOk} />
      </Route>
      <Route path={`${path}${NearbyDCs.url}`}>
        <NearbyDCs getReturnPointsList={getReturnPointsListDB} />
      </Route>
    </Switch>
  );
}

export { messages, NearbyDC, QuestionsAboutPackageReturn };
export default PackageReturnPage;
