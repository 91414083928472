import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { Restore } from '@material-ui/icons';

import Moment from 'react-moment';
import 'moment/locale/pt-br';

import { colors } from '@loggi/mar';

export const messages = {
  whenItWasLastUpdated: 'Última atualização da lista:',
  updateListLabel: 'Atualizar a lista'
};

export default function AssignmentListLastUpdate({ timestamp, onClick }) {
  const cursorPointer = { cursor: 'pointer' };

  return (
    <Box
      bgcolor={colors.smoke[50]}
      px={2.5}
      py={1.5}
      onClick={onClick}
      style={cursorPointer}
      display="flex"
    >
      <Box display="flex" alignItems="center">
        <Box component="span" color={colors.smoke[700]} mr={1.5}>
          <Typography variant="caption">
            <Restore fontSize="small" />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box color={colors.root[700]}>
          <Typography variant="body2">
            {messages.whenItWasLastUpdated}{' '}
            <Moment fromNow locale="pt-br" date={timestamp} />.
          </Typography>
        </Box>
        <Box py={0.5} color="primary.main" fontWeight={600}>
          <Typography variant="body2" style={{ fontWeight: '700' }}>
            {messages.updateListLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

AssignmentListLastUpdate.defaultProps = {
  timestamp: null
};

AssignmentListLastUpdate.propTypes = {
  timestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]),
  onClick: PropTypes.func.isRequired
};
