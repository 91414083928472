import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import {
  noDecimalFormatDistance,
  formatMoney,
  parseISODateToHours
} from 'view/molecules/offer-card/util';
import { smoke } from '@loggi/mar/src/principles/colors';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CONSTANTS from 'view/molecules/offer-card/constants';
import { AcceptOfferButton } from 'view/molecules/offer-card/common';

const OfferCard = ({ offer, onAccept }) => {
  const [deadline, setDeadline] = useState('');
  const [origin, setOrigin] = useState('');

  useEffect(() => {
    if (offer.deadlineCollect) {
      setDeadline(parseISODateToHours(offer.deadlineCollect));
    }
    const originWaypoint = offer.waypoints[0];
    setOrigin(`${originWaypoint.neighborhood} - ${originWaypoint.city}`);
  }, [offer]);

  const offerDeadline = () => {
    let message;

    if (deadline) {
      message = `${CONSTANTS.TODAY_UNTIL} ${deadline}`;
    }

    return (
      <Typography variant="body1">
        <Box color={smoke[900]}>{message}</Box>
      </Typography>
    );
  };

  return (
    <Box
      py={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="body2">
          <Box color={smoke[900]}>{CONSTANTS.EARN_UP_TO}</Box>
        </Typography>

        <Typography variant="h5">
          <Box fontWeight="fontWeightBold" color={smoke[900]}>
            {formatMoney(offer.price)}
          </Box>
        </Typography>

        <Typography variant="body1">
          <Box pt={1}>
            {offer.steps} {CONSTANTS.STOPS} •{' '}
            {noDecimalFormatDistance(offer.distance)} {CONSTANTS.TOTALS}
          </Box>
        </Typography>
        {offerDeadline()}

        <Typography variant="body2">
          <Box color={smoke[900]} pt={1}>
            {origin}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignContent="space-between">
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <ArrowForwardIcon alt="details button" />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          flexGrow="1"
        >
          <AcceptOfferButton
            mx={1}
            handleAccept={() => {
              onAccept(offer);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

OfferCard.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itineraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    demandId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distance: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    recommended: PropTypes.bool,
    deadlineCollect: PropTypes.string,
    waypoints: PropTypes.arrayOf(
      PropTypes.shape({
        neighborhood: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        indexDisplay: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired
        })
      }).isRequired
    ).isRequired
  }).isRequired,
  onAccept: PropTypes.func.isRequired
};

export default OfferCard;
