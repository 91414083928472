import React from 'react';
import SharedPropTypes from 'view/shared-prop-types';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  List,
  ListItem,
  Typography,
  IconButton
} from '@material-ui/core';
import { ArrowBack, ChevronRight } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { DELIVERY_OPTIONS, DELIVERY_OPTIONS_MAP, messages } from './constants';

function DeliveryOptions({ onGoBack, onOptionSelection, pkg }) {
  const Header = React.memo(() => (
    <Box py={2.5}>
      <Box px={0.5}>
        <Grid container justify="space-between" alignItems="center">
          <IconButton onClick={onGoBack} data-testid="back-button">
            <ArrowBack style={{ color: colors.blue[500] }} />
          </IconButton>
        </Grid>
      </Box>
    </Box>
  ));

  const filteredOptionsList = DELIVERY_OPTIONS.filter(
    option =>
      option.id !== DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id ||
      (option.id === DELIVERY_OPTIONS_MAP.MAILBOX_DELIVERY.id &&
        (pkg.canDeliveryMailbox && pkg.deliveryEvidenceSignedUrl))
  );

  return (
    <Box
      colors={colors.root[0]}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Header backButtonOnClick={onGoBack} />

      <Box flexGrow={1}>
        <Card elevation={0} mt={2.5}>
          <Box pt={1.5}>
            <Box px={2.5}>
              <Typography variant="h5">{messages.headerText}</Typography>
            </Box>
            <List>
              {filteredOptionsList.map(option => (
                <ListItem
                  button
                  divider
                  onClick={() => onOptionSelection(option)}
                  key={option.id}
                >
                  <Box
                    py={1.5}
                    px={0.5}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Box flex={1} justifyContent="flex-start">
                      <Typography variant="body1">{option.text}</Typography>
                    </Box>
                    <Box
                      color={colors.blue[500]}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ChevronRight data-testid="chevron-right-icon" />
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

DeliveryOptions.propTypes = {
  onOptionSelection: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  pkg: SharedPropTypes.package.isRequired
};

export default DeliveryOptions;
