import { Box, Button, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Illustration } from 'view/atoms/illustrations/journey-white.svg';
import { pickupFlowEnum } from './constants';
import allMessages from './messages';

export default function PickupAutomaticFill({
  pickupFlow,
  onContinue,
  onCloseButtonClicked
}) {
  const messages = allMessages.pickupCode[pickupFlow];

  return (
    <>
      <Box
        p={2.5}
        px={0.5}
        display="flex"
        flexDirection="column"
        minHeight="100%"
      >
        <Box>
          <IconButton
            onClick={onCloseButtonClicked}
            data-testid="close"
            color="inherit"
          >
            <ArrowBack color="primary" />
          </IconButton>
        </Box>
        <Box
          px={2.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
        >
          <Box flexGrow={1} overflow="auto">
            <Box py={2.5}>
              <Illustration height="143px" width="265px" />
            </Box>
            <Box>{messages.title}</Box>
            <Box>{messages.subtitle}</Box>
            <Box py={3}>{messages.description}</Box>
          </Box>
          <Box>
            <Button
              size="large"
              fullWidth
              color="primary"
              variant="contained"
              onClick={onContinue}
            >
              {messages.onContinueButtonLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

PickupAutomaticFill.propTypes = {
  pickupFlow: PropTypes.oneOf(Object.values(pickupFlowEnum)).isRequired,
  onContinue: PropTypes.func.isRequired,
  onCloseButtonClicked: PropTypes.func.isRequired
};

PickupAutomaticFill.url = '/instrucoes_sem_bipagem';
