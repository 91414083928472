const TEXTS = {
  TYPE_BARCODE: 'Digitar código',
  SUBTITLE:
    'Para buscar o pacote, aponte a câmera para o código de barras na etiqueta.',
  BARCODE_NOT_FOUND:
    'Não achamos esse código na sua lista de entregas. Tente buscar na lista pelo endereço ou fale com sua base',
  helpButton: 'Ajuda'
};

export default TEXTS;
