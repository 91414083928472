import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { colors } from '@loggi/mar';

import { getDiffInDaysBetweenDateAndNow } from 'view/molecules/offer-card/util';
import { ReactComponent as OpportunisticIcon } from 'view/images/opportunistic_ico.svg';
import pinRoundIcon from './pin-round.svg';
import recommendedIcon from './recommended.svg';
import capacityReserveIcon from './capacity_reserve_icon.svg';
import pinRoundVipIcon from './round-vip-arrow.svg';

export const DistanceTooltip = ({ distance }) => {
  return (
    <Box>
      <Box>
        <img src={pinRoundIcon} alt="Position Icon" />
      </Box>
      <Box pt={1}>
        {distance ? (
          <Typography color="textPrimary" variant="subtitle1">
            Oferta a{' '}
            <strong data-testid="offer-distance-calculated">{distance}</strong>{' '}
            de você!
          </Typography>
        ) : (
          <Box
            textAlign="center"
            color={colors.blue[500]}
            data-testid="offer-distance-loading"
          >
            <CircularProgress size={20} color="inherit" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

DistanceTooltip.propTypes = {
  distance: PropTypes.string
};

DistanceTooltip.defaultProps = {
  distance: undefined
};

export const RecommendedTooltip = () => {
  return (
    <>
      <Box>
        <img src={recommendedIcon} alt="Recommended icon" />
      </Box>
      <Box pt={1}>
        <Typography color="textPrimary" variant="subtitle1">
          Maior chance de <strong>sucesso!</strong>
        </Typography>
      </Box>
    </>
  );
};

const daysToCollect = startTimeCollect => {
  const days = getDiffInDaysBetweenDateAndNow(startTimeCollect);
  if (days > 1) {
    return `para daqui ${days} dias!`;
  }
  if (days === 0) {
    return 'para as próximas horas!';
  }
  return 'para amanhã!';
};

export const CapacityReserveToolTip = ({ startTimeCollect }) => {
  const text = {
    title: () => (
      <Typography
        color={colors.blue[500]}
        variant="subtitle1"
        font-weight="600"
      >
        Programe uma rota{' '}
        <Box fontWeight="fontWeightBold">{daysToCollect(startTimeCollect)}</Box>
      </Typography>
    )
  };
  return (
    <>
      <Box>
        <Box width={20}>
          <img src={capacityReserveIcon} alt="Ícone da programação" />
        </Box>
        <Box pt={1.5}>{text.title()}</Box>
      </Box>
    </>
  );
};

CapacityReserveToolTip.propTypes = {
  startTimeCollect: PropTypes.string.isRequired
};

export const OpportunisticToolTip = ({ distance }) => {
  const text = {
    title: () => (
      <Typography variant="subtitle1" font-weight="600">
        No seu caminho,{' '}
        <Box fontWeight="fontWeightBold">{distance} de você!</Box>
      </Typography>
    )
  };
  return (
    <>
      <Box>
        <Box width={20}>
          <OpportunisticIcon
            data-testid="opportunistic-ico"
            alt="Opportunistic icon"
          />
        </Box>
        <Box pt={1.5}>{text.title()}</Box>
      </Box>
    </>
  );
};

OpportunisticToolTip.propTypes = {
  distance: PropTypes.string.isRequired
};

export const RoundVIPToolTip = () => {
  const text = {
    title: () => (
      <Typography>
        Ganhe mais: <strong data-testid="round-vip-message">novo valor!</strong>{' '}
      </Typography>
    )
  };
  return (
    <>
      <Box width={23.32}>
        <img src={pinRoundVipIcon} alt="Ícone round VIP" />
      </Box>
      <Box pt={1}>{text.title}</Box>
    </>
  );
};
