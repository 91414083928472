import getMultibarcodesFromNotIntegratedPackages from 'operations/package-not-integrated';
import apiLoggiWeb from 'operations/config/api/loggiweb';
import apiAllocation from 'operations/config/api/allocation';
import deprecatedApi from '../config/api';

const shipmentUrl = '/last-mile/v1/shipment';
const pickupProblemEvidenceUrl = '/security/fraud/v1/pickup-problem-evidence';

/**
 * @typedef {Object} Package
 *
 * @property {string} Package.packageId
 * @property {string} Package.barcode
 * @property {string} Package.assignedTo
 * @property {string} Package.companyName
 *
 * @property {Object} Package.status
 * @property {number} Package.status.code
 * @property {string} Package.status.description
 * @property {string} Package.status.updated
 *
 * @property {Object} Package.recipient
 * @property {string} Package.recipient.name
 * @property {string} Package.recipient.phone
 *
 * @property {Object} Package.destination
 * @property {string} Package.destination.addressStreet
 * @property {string} Package.destination.addressNumber
 * @property {string} Package.destination.addressComplement
 * @property {string} Package.destination.city
 * @property {string} Package.destination.state
 * @property {string} Package.destination.vicinity
 * @property {string} Package.destination.zipCode
 * @property {number} Package.destination.lat
 * @property {number} Package.destination.lng
 * @property {number} Package.destination.distance
 *
 */

/**
 * @typedef {Object} AssignmentListResponse
 * `data` is the response that was provided by the server.
 * The response should include an array of packages and an array
 * of pickups.
 *
 * @property {Array<Package>} AssignmentListResponse.packages
 * `data.packages` The list of packages returned in the response.
 */

/**
 * @typedef {Object} PickupProblem
 *
 * @property {string} slug
 * @property {string} description
 */

/**
 * @typedef {Object} PickupProtocol
 *
 * @property {string} name
 * @property {string} document - cpf
 */

/**
 * console.log(response);
 * // An example of the output
 * {
 *   data: {
 *     packages: [
 *       {
 *          "packageId": "330949",
 *          "barcode": "0fdafa9",
 *          "assignedTo": "Mauricio Guerta3",
 *          "companyName": "Loggi",
 *          "status": {
 *            "code": 0,
 *            "description": "Não disponível",
 *            "updated": "2020-01-28T18:06:11.046Z"
 *          },
 *          "recipient": {
 *            "name": "João Dorimê Amenô",
 *            "phone": "5511912345600",
 *            "document": ""
 *          },
 *          "destination": {
 *            "addressStreet": "Township Hwy 132",
 *            "addressNumber": "24189",
 *            "addressComplement": "close to a heart. This address is literally close to a heart, see on the map",
 *            "zipCode": "44028",
 *            "city": "Columbia Station",
 *            "state": "OH",
 *            "vicinity": "Heidi Smith",
 *            "lat": 41.303921,
 *            "lng": -81.901693,
 *            "distance": 1000.0
 *          },
 *        }
 *     ],
 */
function getPickupPackages({ pickupId } = {}) {
  const baseUrl = `/last-mile/v1/pickup/packages`;
  const url = pickupId ? `${baseUrl}?pickup_id=${pickupId}` : baseUrl;

  return apiLoggiWeb()
    .url(url)
    .get();
}

/**
 * @param {Array<Package>} packages - `packages` is used to inform server the packages what was visualized by driver
 * it will be used to match the server packages to check if the list is updated
 * @param {PickupProtocol} pickupProtocol
 * @param {string} scanningStartingTime
 * @param {number} pickupType
 * @param {number} latitude
 * @param {number} longitude
 * @return {Promise<{}>} Wretch response object that contain an empty response in case of success
 */
function shipmentPickup({
  packages,
  pickupProtocol,
  scanningStartingTime,
  pickupType,
  latitude,
  longitude
}) {
  const packageIds = packages.map(item => item.packageId);
  const packageNotIntegratedMultibarcodes = getMultibarcodesFromNotIntegratedPackages(
    packages
  );

  return apiLoggiWeb()
    .url(`${shipmentUrl}/pickup`)
    .post({
      packageIds,
      pickupProtocol,
      pickupType,
      driverPosition: {
        lat: latitude,
        lng: longitude
      },
      startTime: scanningStartingTime,
      packageNotIntegratedMultibarcodes
    });
}

/**
 * @return {Promise<{Array<PickupProblem>}>}
 */
function getPickupProblems() {
  return apiLoggiWeb()
    .url(`${shipmentUrl}/pickup_problem_reasons`)
    .get();
}

/**
 * @param {string} problemSlug
 */
function reportPickupProblem(problemSlug) {
  return apiLoggiWeb()
    .url(`${shipmentUrl}/report_pickup_problem`)
    .post({ reason: problemSlug });
}

/**
 * @param {Array<string>} barcodes
 */
function checkBarcodes({ barcodes, companyId, pickupCode }) {
  const packageBarcodesList = barcodes.map(item => ({ packageBarcodes: item }));
  const data = {
    multibarcodes: packageBarcodesList,
    company_id: parseInt(companyId, 10) || undefined,
    pickup_order_schedule_id: parseInt(pickupCode, 10) || undefined
  };
  return apiLoggiWeb()
    .url(`${shipmentUrl}/check_barcodes`)
    .post(data);
}

/**
 * @param {string} waypointId
 */
function getUndeliveredCargosForWaypoint(waypointId) {
  return apiLoggiWeb()
    .url(`${shipmentUrl}/assignment-cargos/waypoints/${waypointId}`)
    .get(waypointId);
}

function getAcceptedPickups() {
  return deprecatedApi.get(`${shipmentUrl}/accepted_pickups`);
}

/**
 * Creates an evidence for a pickup problem using the Loggi Web API.
 *
 * @param {Object} params - The parameters for creating the evidence.
 * @param {number} params.pickupId - The identifier for the pickup order.
 * @param {string} params.problemSlug - The problem slug that describes the problem.
 * @param {number} params.collectedTime - The timestamp when the evidence was collected.
 *
 * @returns {Wretcher.ResponseChain & Promise<any>} - An instance of the Wretcher library with the HTTP request for creating the pickup problem evidence, which returns a Promise that resolves to any data.
 */
function createPickupProblemEvidence({ pickupId, problemSlug, collectedTime }) {
  return apiLoggiWeb()
    .url(pickupProblemEvidenceUrl)
    .post({
      pickup_order_id: pickupId,
      pickup_problem_slug: problemSlug,
      collected_time: new Date(collectedTime).toISOString()
    });
}

/**
 * Get reasons for volume not collected.
 * @returns {Wretcher.ResponseChain & Promise<any>} - An instance of the Wretcher library with the HTTP request for creating the pickup problem evidence, which returns a Promise that resolves to any data.
 */
function getReasonsForUncollectedVolumes() {
  return apiAllocation()
    .url('/v1/volume/getfailurereasons')
    .get();
}

/**
 * Register reasons for each volume not collected.
 * @param {number} pickupId
 * @param {number} driverId
 * @param {Array<Object>} uncolletedVolumes
 *                        {
 *                            loggiKeys: [],
 *                            reasonKey: "",
 *                            openField: ""
 *                         }
 * @returns {Wretcher.ResponseChain & Promise<any>} - An instance of the Wretcher library with the HTTP request for creating the pickup problem evidence, which returns a Promise that resolves to any data.
 */
function registerReasonsForUncollectedVolumes({
  pickupId,
  driverId,
  uncollectedVolumes
}) {
  return apiAllocation()
    .url('/v1/volume/schedulefailures')
    .post({
      pickup_id: pickupId,
      driver_id: driverId,
      failures: uncollectedVolumes.loggiKeys.map(loggiKey => {
        return {
          loggi_key: loggiKey,
          reason_key: uncollectedVolumes.reasonKey,
          open_field: uncollectedVolumes.openField
        };
      })
    });
}

export {
  getPickupPackages,
  getPickupProblems,
  reportPickupProblem,
  checkBarcodes,
  shipmentPickup,
  getUndeliveredCargosForWaypoint,
  getAcceptedPickups,
  createPickupProblemEvidence,
  getReasonsForUncollectedVolumes,
  registerReasonsForUncollectedVolumes
};
