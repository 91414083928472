import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import sendEventToAnalytics from 'operations/firebase';
import SharedPropTypes from 'view/shared-prop-types';
import AssignmentRow from 'view/molecules/assignment-row';

export const texts = {
  expanded: 'Ocultar pacotes entregues',
  collapsed: 'Mostrar pacotes entregues'
};

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
const ExpandablePackagesList = ({ packages, onPackageClick }) => {
  const [expandAccordion, setExpandAccordion] = useState(false);

  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  if (!packages.length) return <></>;

  const toggleAccordion = () => {
    if (!expandAccordion) {
      sendEventToAnalytics('expandable_packages_list_click', {
        numPackages: packages.length
      });
    }
    setExpandAccordion(prev => !prev);
  };

  return (
    <Accordion
      data-testid="expandable-packages-list"
      onChange={toggleAccordion}
      pt={2}
      style={{ boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              marginRight: pxToRem(4),
              color: colors.neutrals.typeface.primary
            }}
          />
        }
        aria-controls="expandable-package-list-content"
        id="expandable-package-list-header"
      >
        <Typography
          style={{
            marginLeft: pxToRem(8),
            fontWeight: 600,
            color: colors.neutrals.typeface.primary
          }}
        >
          {expandAccordion ? texts.expanded : texts.collapsed}
        </Typography>
      </AccordionSummary>
      <Virtuoso
        useWindowScroll
        data={packages}
        itemContent={(_, pkg) => (
          <AssignmentRow
            deadlineTime={pkg.deadlineDeliveryTime}
            key={pkg.packageId}
            assignment={pkg}
            onClick={onPackageClick}
          />
        )}
      />
    </Accordion>
  );
};

ExpandablePackagesList.propTypes = {
  packages: SharedPropTypes.packages.isRequired,
  onPackageClick: PropTypes.func.isRequired
};

export default React.memo(ExpandablePackagesList);
