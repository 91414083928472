import geolocation from 'infra/services/geolocation';

export const redirToNewHome = async () => {
  geolocation().then(pos => {
    window.location.href = `${process.env.REACT_APP_DRIVERS_URL}/?latitude=${
      pos.latitude
    }&longitude=${pos.longitude}`;
  });
};

export const getDriverAppBridge = () =>
  window?.driverAppOfferBridge || window?.driverAppBridge;

export const getDriverAppOfferBridgeCognitoToken = () =>
  window?.driverAppOfferBridge?.getCognitoToken?.() ||
  window?.driverAppBridge?.getCognitoToken?.();

export const isFromNewHome = () =>
  getDriverAppBridge() &&
  getDriverAppBridge().isFromNewHome &&
  getDriverAppBridge().isFromNewHome();

/**
 * When accept offer mutation has been called with success the
 * WebView must open DriverApp to redirect to offer flow
 */
export const openDeepLink = (urlRedirection = null) => {
  if (
    isFromNewHome() ||
    window.location.href.startsWith(process.env.REACT_APP_DRIVERS_URL)
  ) {
    if (urlRedirection)
      window.location.href = `${
        process.env.REACT_APP_DRIVERS_URL
      }${urlRedirection}`;
    else redirToNewHome();
  } else {
    window.location.href = 'loggi://driver/open';
  }
};

/* When accept offer we can call app function to
 * resolve offer accepted
 */
export const onAcceptOffer = () => {
  if (getDriverAppBridge()) {
    getDriverAppBridge().onAcceptOfferSuccess();

    return true;
  }

  return false;
};

/* When reject offer we can call app function to
 * resolve offer reject
 */
export const onRejectOffer = () => {
  if (getDriverAppBridge()) {
    getDriverAppBridge().onRejectOffer();

    return true;
  }

  return false;
};

/* When close offer screen error alert we can call app function to
 * close web view activity
 */
export const onCloseOfferError = () => {
  if (getDriverAppBridge()) {
    getDriverAppBridge().closeActivity();

    return true;
  }

  return false;
};

export default function getDriverAppVersion() {
  return getDriverAppBridge()?.getAppVersion?.();
}
