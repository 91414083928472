import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const STATE = {
  LOADING: 'LOADING',
  INITIAL: 'INITIAL'
};

export const NOTIFICATIONS_ENUM = {
  WHATSAPP_INVITATION_SENT: 'WHATSAPP_INVITATION_SENT',
  WHATSAPP_CONVERSATION_LIMIT_REACHED: 'WHATSAPP_CONVERSATION_LIMIT_REACHED'
};

export const EXPERIMENT_ENUM = {
  WHATSAPP_TWILIO_PROXY: 'whatsapp_twilio_proxy',
  WHATSAPP: 'whatsapp'
};

export const useIconHeaderStyles = makeStyles(({ typography }) => ({
  root: {
    border: `solid ${typography.pxToRem(1)} ${colors.blue[500]}`,
    color: colors.blue[500],
    backgroundColor: colors.root[0]
  }
}));

export const recipientPhoneTexts = {
  whatsappInvitationSent:
    'recebeu um convite para começar a conversa. Se aceitar, vocês podem se falar pelo WhatsApp.',
  duplicatedInvitation:
    'Opa! Você já pediu para começar essa conversa no WhatsApp.',
  conversationLimit: 'Você já está conversando com',
  closeConversation1: 'Para começar outra conversa com',
  closeConversation2: ', é preciso fechar a que está ativa.'
};
