import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Typography, List, Divider } from '@material-ui/core';
import { colors } from '@loggi/mar';
import sharedPropTypes from 'view/shared-prop-types';
import messages from '../messages';
import CargoRow from './cargo-row';
import DeliveryToDcButton from './delivery-to-dc-button';

export default function DeliveryToDcRow({ assignment, waypointId }) {
  return (
    <Box>
      <Box px={3.5}>
        <Box pt={2.5} color={colors.smoke[700]}>
          <Typography variant="body2">
            {messages.deliveryToAgency.deliverPkgsTo(assignment.cargos.length)}
          </Typography>
        </Box>
        <Box py={1.5}>
          <Typography variant="h5">{assignment.contact.name}</Typography>
        </Box>
      </Box>

      <Divider />

      <Box p={1} pl={0} pt={0} pb={9} divider="true" bgcolor={colors.root[0]}>
        <List divider="true">
          {assignment.cargos.map(cargo => (
            <CargoRow key={cargo.code} cargo={cargo} />
          ))}
        </List>
      </Box>

      <DeliveryToDcButton assignment={assignment} waypointId={waypointId} />
    </Box>
  );
}

DeliveryToDcRow.propTypes = {
  assignment: sharedPropTypes.assignment.isRequired,
  waypointId: PropTypes.string.isRequired
};
