import React, { useState, useRef } from 'react';
import { number, func } from 'prop-types';
import { TextField, Box } from '@material-ui/core';

const BACKSPACE_KEYCODE = 8;

const tokenInputStyles = {
  maxLength: 1,
  style: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase'
  }
};

export const isInvalidValue = value => {
  return /[^0-9A-z]/.test(value);
};

const InputVerificationCode = ({ length, onChange }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));

  const inputs = useRef([]);

  const onTokenChange = token => {
    const insertedToken = token.filter(x => x !== '').join('');
    onChange(insertedToken);
  };

  const onInputChange = (e, slot) => {
    const newValue = e.target.value;
    if (isInvalidValue(newValue)) return;

    const newCode = [...code];
    newCode[slot] = newValue;
    setCode(newCode);

    // move to next input
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }

    onTokenChange(newCode);
  };

  const onBackspace = (e, slot) => {
    if (e.keyCode === BACKSPACE_KEYCODE && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1].focus();

      onTokenChange(newCode);
    }
  };

  return (
    <>
      {code.map((value, idx) => {
        return (
          <Box
            key={`input-${idx}`.toString()}
            borderRadius="16px"
            m={1}
            height={90}
            maxWidth={70}
          >
            <TextField
              key={`input-token-${idx}`.toString()}
              size="medium"
              variant="outlined"
              type="text"
              inputProps={{
                ...tokenInputStyles,
                'data-testid': `input-token-${idx}`
              }}
              autoFocus={!code[0].length && idx === 0}
              onChange={e => onInputChange(e, idx)}
              onKeyUp={e => onBackspace(e, idx)}
              inputRef={ref => inputs.current.push(ref)}
              value={value}
            />
          </Box>
        );
      })}
    </>
  );
};

InputVerificationCode.propTypes = {
  length: number.isRequired,
  onChange: func.isRequired
};

export default InputVerificationCode;
