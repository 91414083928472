import { isAssignmentWaypointPickup } from 'view/molecules/assignment-list/utils';
import { completePickup, quitPickup } from 'operations/offer';
import { reportPickupProblem } from './pickup-service';

/**
 * Allocation pickup is the only one that has a pickupId
 * Last Mile pickup has only pickupCode
 * @param {*} waypoint
 * @returns {Boolean} true if is a pickup from allocation
 */
const isAllocationPickup = waypoint => {
  return waypoint?.pickupId && isAssignmentWaypointPickup(waypoint);
};

function finishPickup({
  packages,
  pickupProtocol,
  scanningStartingTime,
  pickup,
  ignoreProxy = false
}) {
  const companyId = pickup?.shipper?.companyId;
  return completePickup({
    packages,
    pickupId: pickup?.pickupId,
    firstBeepStartTime: scanningStartingTime,
    pickupProtocol,
    companyId,
    ignoreProxy
  });
}

async function cancelPickup({
  pickup,
  problemSlug,
  problemComplement = '',
  ignoreProxy = false,
  sendDriverPosition = false
}) {
  if (isAllocationPickup(pickup)) {
    return quitPickup({
      pickupId: pickup?.pickupId,
      problemSlug,
      problemComplement,
      ignoreProxy,
      sendDriverPosition
    });
  }
  return reportPickupProblem(problemSlug);
}

export { finishPickup, cancelPickup };
