import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

export const TEXT = {
  BUTTON_BACK: 'Voltar',
  BUTTON_NAVIGATE: 'Como chegar',
  BUTTON_PROBLEMS: 'Não entreguei',
  BUTTON_SIGNATURE: 'Entregar',
  DELIVER_IN: 'Entregar em',
  BUTTON_DC_SIGNATURE: 'Entregar',
  COLLAPSE_MINIMIZE: 'Minimizar',
  COLLAPSE_EXPAND: 'Ver dicas do destinatário',
  RECIPIENT_INSTRUCTIONS: 'Instruções do destinatário:',
  WHO_CAN_RECEIVE_PKG: 'Pode receber essa entrega:',
  ADDRESS_TYPE: 'Tipo do endereço:',
  MAILBOX_ALERT: 'Deixar na caixa de correspondência',
  PREVENT_MAILBOX_WARNING: 'Não deixar na caixa de correspondência'
};

export const messages = {
  doNotDeliverToMailbox: {
    title: 'Esse pacote NÃO PODE ser deixado na caixa de correspondência!',
    description:
      'É importante que você entregue esse pacote para o destinatário ou algum conhecido que possa recebê-lo.',
    buttonConfirm: 'Ok, entendi'
  },
  packageDirectionReturn: {
    title: 'Em devolução ao remetente',
    description:
      'Siga o endereço que aparece no aplicativo, mesmo que esteja diferente da etiqueta.'
  },
  actionText: {
    toDo: 'Entregar',
    done: 'Entregue'
  }
};

export const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: colors.gradients.sanches,
    minHeight: '100%'
  },
  card: {
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px'
  },
  tab: {
    color: colors.root[0]
  }
}));

export const packageDirection = {
  deliveryDirection: 'PACKAGE_DIRECTION_DELIVERY',
  returnDirection: 'PACKAGE_DIRECTION_RETURN',
  invalidDirection: 'PACKAGE_DIRECTION_INVALID'
};
