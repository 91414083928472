import { useState, useEffect } from 'react';

/**
 * useState, but it persists the state to localStorage.
 * WARNING: remember to clean the persisted state to prevent
 *   unintended side effects
 *
 * @see {https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go}
 *
 * @param {string} key - a string the ids the persisted state
 * @param {*} defaultValue - the default value if there's no persisted state
 * @returns {Array} - [state, setState]
 */
function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    JSON.parse(localStorage.getItem(key)) ?? defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

/**
 * Delete persisted state `key` and its children, in the form
 * of keys named `${key}/${something}`.
 *
 * @param {string} key -
 */
function cleanPersistedState(key) {
  Object.keys(localStorage)
    .filter(k => k.split('/')[0] === key)
    .forEach(k => localStorage.removeItem(k));
}

const persistedStateKeys = {
  pickupScannedPackages: 'pickup-scanned-packages',
  packageScanningStart: 'pickup-scanning-start',
  appUpdateAvailable: 'app-update-available',
  showAgencyReminderDrawer: 'show-agency-reminder-drawer',
  skipPickupScanningTutorial: 'skip-pickup-scanning-tutorial',
  hasShowedOptimizationDrawer: 'has-showed-optimization-drawer',
  pickupUncollectedVolumes: 'pickup-uncollected-volumes'
};

export default usePersistedState;
export { cleanPersistedState, persistedStateKeys };
