const remoteConfigFlags = {
  /**
   * Context: Pickup
   * Structure: {"no_one_in_pickup": true, "no_packages_to_pickup": true, "not_enough_space": true, "couldnt_beep_packages": true}
   */
  problemSlugsThatRequireFacadePhoto: {
    name: 'problemSlugsThatRequireFacadePhoto',
    description:
      'Defines the slugs of the problems that require the facade photo',
    defaultValue: {
      no_one_in_pickup: true,
      no_packages_to_pickup: true,
      not_enough_space: true,
      couldnt_beep_packages: true,
      didnt_find_the_address: true
    }
  },
  /**
   * Context: Pickup/Survey
   * Structure: {
                  "availableSurveys": [{
                    "from": {
                        "route": "/lista-atividades",
                        "useRegex": false
                    },
                    "to": {
                        "route": "\/coleta\/\d{4}",
                        "useRegex": true
                    },
                    "slug": "slug_1",
                    "rule": "always", // options: always, once, x:days, x:weeks
                    "stateFields": ["pickupCode"],
                    "rolloutLMCs": "all" // options: "all"; "loggi_sp_sao_paulo,loggi_sp_campinas"; "",
                    "rolloutCompanyRelation": "all" // options: "all"; "loggi_sp_sao_paulo,loggi_sp_campinas"; ""
                  }]
                }
   */
  surveys: {
    name: 'surveys',
    description:
      'Define all surveys that should be presented to the driver in pickup context',
    defaultValue: {
      availableSurveys: []
    },
    extras: {
      rules: {
        always: 'always',
        once: 'once',
        days: 'days',
        weeks: 'weeks'
      },
      requiredFields: ['slug', 'rule', 'rolloutLMCs', 'rolloutCompanyRelation']
    }
  },
  /**
   * Context: Delivery
   * Structure: {"limitAttemptsToChangeStatus": 3, "expirationTime": 5 // minutes}
   */
  changePackageDeliveredStatus: {
    name: 'changePackageDeliveredStatus',
    description:
      'Defines the rules for the driver to change the status of a delivered package',
    defaultValue: {
      limitAttemptsToChangeStatus: 'limitAttemptsToChangeStatus',
      expirationTimeInMinutes: 'expirationTimeInMinutes'
    }
  }
};
export default remoteConfigFlags;
