import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitchEnabledForDriverLMC,
  featureSwitches
} from 'operations/feature-switches';
import { getPackageById } from 'operations/assignment-list/assignment-list';
import { statusCodes } from 'operations/update-status';
import { PAGES } from 'view';
import PackageDetailsTemplate from 'view/templates/package-details';

/**
 * This hook was built to improve UX. If it goes to the Package Details route
 * through a click on the PackageList, we are able to give'em instant access
 * to the page, because the information is passed through /location.state/.
 * But if he goes to the PackageDetails route directly (e.g. copying and pasting
 * the url into another tab), it takes a little longer, but it works.
 */

function usePackage({ packageInHistory, packageId }) {
  const [state, setState] = useState('loading');
  const [pkg, setPkg] = useState(null);

  const isListDeliveredPackagesEnabledForCompanyRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByCompanyRelation
    )
  );

  const isListDeliveredPackagesEnabledForDriverLMC = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(
      featureSwitches.enableListDeliveredPackagesByLastMileCompany
    )
  );

  const isListDeliveredPackagesEnabledForDriver =
    isListDeliveredPackagesEnabledForCompanyRelation &&
    isListDeliveredPackagesEnabledForDriverLMC;

  useEffect(() => {
    if (packageInHistory) {
      // we don't need to get the package, but we can't early return on a hook,
      // because hooks can't be called conditionally
      return;
    }

    getPackageById(packageId, isListDeliveredPackagesEnabledForDriver).then(
      packageInDB => {
        if (
          packageInDB &&
          packageInDB?.status?.code !== statusCodes.DELIVERED
        ) {
          setPkg(packageInDB);
          setState('success');
          return;
        }

        setState('failure');
      }
    );
  }, [packageId, packageInHistory, isListDeliveredPackagesEnabledForDriver]);

  if (packageInHistory) {
    return { pkg: packageInHistory, state: 'success' };
  }
  return { pkg, state };
}

export default function PackageDetails() {
  const location = useLocation();
  const history = useHistory();
  const packageInHistory = location.state;
  const { packageId } = useParams();
  const { pkg, state } = usePackage({ packageInHistory, packageId });

  const onProblems = insertedTokenAttempts => {
    history.push({
      pathname: PAGES.DELIVERING_PROBLEMS,
      state: {
        pkg,
        tokenAttempts: insertedTokenAttempts
      }
    });
  };

  const onSignature = insertedTokenAttempts => {
    history.push({
      pathname: PAGES.PROTOCOL,
      state: {
        pkg,
        tokenAttempts: insertedTokenAttempts
      }
    });
  };

  return (
    <>
      {state === 'success' && (
        <PackageDetailsTemplate
          onGoBack={() => history.push(PAGES.PACKAGE_LIST)}
          onProblems={onProblems}
          onSignature={onSignature}
          pkg={pkg}
        />
      )}

      {state === 'loading' && 'Carregando pacote...'}
      {state === 'failure' && 'Você não tem acesso à esse pacote.'}
    </>
  );
}
