import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Divider } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { PAGES } from 'view';
import { Link } from 'react-router-dom';
import { ReactComponent as EmergencyButton } from './icons/emergency-button.svg';

export default function PickupProblemsHeader({ title, subtitle, onGoBack }) {
  const theme = useTheme();
  const { colors, typography } = theme;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={1.5}
      >
        <IconButton onClick={onGoBack} data-testid="back-button">
          <ArrowBack style={{ color: colors.neutrals.typeface.primary }} />
        </IconButton>
        <IconButton
          component={Link}
          to={PAGES.EMERGENCY}
          data-testid="emergency-button"
        >
          <EmergencyButton fill={colors.neutrals.typeface.primary} />
        </IconButton>
      </Box>
      <Box>
        <Box mx={3}>
          <Typography
            variant="h6"
            style={{ fontWeight: typography.fontWeightBold }}
          >
            {title}
          </Typography>
        </Box>
        <Box mx={3} mt={2}>
          <Typography variant="body2" style={{ lineHeight: '1.25rem' }}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box mt={5}>
        <Divider style={{ height: '2px' }} />
      </Box>
    </Box>
  );
}

PickupProblemsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired
};
