import React from 'react';
import { ReactComponent as LocationBluePoint } from 'view/atoms/illustrations/location-blue-point.svg';
import { Box, Typography, Button } from '@material-ui/core';

export const messages = {
  title: 'Ative a localização para continuar',
  text:
    'Para fazer entregas, é preciso compartilhar sua localização em tempo real com a Loggi. Assim que ativar, atualize a página.',
  howToActivateButton: 'Veja como ativar'
};

export default function GeolocationPermission() {
  return (
    <Box
      px={2.5}
      py={2.5}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <LocationBluePoint />

        <Box pt={5} pb={1.25} display="flex">
          <Typography variant="h5" align="center">
            {messages.title}
          </Typography>
        </Box>
        <Typography variant="subtitle2" align="center">
          {messages.text}
        </Typography>
      </Box>

      <Box>
        <Box pb={2}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            data-testid="btn-start-delivery-to-agency"
            href="https://ajuda.loggi.com/hc/pt-br/articles/360059843571"
          >
            {messages.howToActivateButton}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
