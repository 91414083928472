import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { colors } from '@loggi/mar';
import {
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  Collapse
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  NearMe,
  PeopleAlt,
  Apartment,
  SettingsBackupRestore
} from '@material-ui/icons';
import { featureSwitches } from 'operations/feature-switches';
import { TEXT, messages, packageDirection } from '../constants';

export default function AdditionalData({
  addressInstructions,
  whoCanReceiveThePackage,
  addressType,
  direction
}) {
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleaAdditionalDataClick = () => {
    setOpenCollapse(!openCollapse);
  };

  const { recipientInstructions, senderInstructions } = addressInstructions;

  const isPackageDirectionReturnMessageEnabled = useFeatureSwitch(
    featureSwitches.enablePackageDirectionReturnMessage
  );

  const isPackageDirectionReturn =
    direction && direction === packageDirection.returnDirection;

  const isEnabledPackageReturn =
    isPackageDirectionReturn && isPackageDirectionReturnMessageEnabled;

  return (
    <List>
      {!isPackageDirectionReturn && (
        <ListItem
          button
          onClick={handleaAdditionalDataClick}
          disableGutters
          style={{ color: colors.blue[500] }}
          data-testid="collapse"
        >
          <ListItemText
            primary={
              openCollapse ? TEXT.COLLAPSE_MINIMIZE : TEXT.COLLAPSE_EXPAND
            }
          />
          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {isEnabledPackageReturn && (
        <Box bgcolor={colors.smoke[50]} borderRadius={8} p={2.5}>
          <Box pb={0.5} display="flex">
            <Box color={colors.smoke[800]} mr={1.5}>
              <SettingsBackupRestore />
            </Box>
            <Typography variant="subtitle1">
              {messages.packageDirectionReturn.title}
            </Typography>
          </Box>
          <Box color={colors.smoke[900]}>
            <Typography variant="body1">
              {isPackageDirectionReturnMessageEnabled}
            </Typography>
          </Box>
        </Box>
      )}

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {(recipientInstructions || senderInstructions) && (
            <ListItem component="div">
              <Box color={colors.smoke[400]}>
                <ListItemIcon>
                  <NearMe />
                </ListItemIcon>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box color={colors.smoke[700]}>
                  <Typography variant="body2">
                    {TEXT.RECIPIENT_INSTRUCTIONS}
                  </Typography>
                </Box>
                {recipientInstructions && (
                  <Box pt={1.25}>{recipientInstructions}</Box>
                )}
                {senderInstructions && (
                  <Box pt={1.25}>{senderInstructions}</Box>
                )}
              </Box>
            </ListItem>
          )}

          {whoCanReceiveThePackage && (
            <ListItem component="div">
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <Box display="flex" flexDirection="column">
                <Box color={colors.smoke[700]}>
                  <Typography variant="body2">
                    {TEXT.WHO_CAN_RECEIVE_PKG}
                  </Typography>
                </Box>
                <Box pt={1.25}>{whoCanReceiveThePackage}</Box>
              </Box>
            </ListItem>
          )}

          {addressType && (
            <ListItem component="div">
              <ListItemIcon>
                <Apartment />
              </ListItemIcon>
              <Box display="flex" flexDirection="column">
                <Box color={colors.smoke[700]}>
                  <Typography variant="body2">{TEXT.ADDRESS_TYPE}</Typography>
                </Box>
                <Box pt={1.25} data-testid="address-type">
                  {addressType}
                </Box>
              </Box>
            </ListItem>
          )}
        </List>
      </Collapse>
    </List>
  );
}

AdditionalData.propTypes = {
  addressInstructions: PropTypes.shape({
    recipientInstructions: PropTypes.string,
    senderInstructions: PropTypes.string
  }),
  whoCanReceiveThePackage: PropTypes.string,
  addressType: PropTypes.string,
  direction: PropTypes.string
};

AdditionalData.defaultProps = {
  addressInstructions: {},
  whoCanReceiveThePackage: '',
  addressType: '',
  direction: null
};
