import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Button, Drawer } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as Icon } from './icons/icon-beep.svg';

export const texts = {
  subtitle:
    'Conte sua experiência e ajude a melhorar a cada vez mais o nosso aplicativo.',
  button: 'Responder pesquisa'
};

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function SurveyDrawer({ open, answerSurvey, title }) {
  const { colors } = useTheme();

  return (
    <Drawer
      data-testid="confirm-drawer"
      open={open}
      anchor="bottom"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16
        }
      }}
    >
      <Box p={3}>
        <Icon />
        <Box pt={2} color={colors.neutrals.typeface.primary}>
          <Typography
            variant="body1"
            style={{ marginBottom: 8, fontWeight: 600 }}
          >
            {title}
          </Typography>
          <Typography variant="body2">{texts.subtitle}</Typography>
        </Box>
        <Box display="flex" pt={5} flexDirection="column">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={answerSurvey}
          >
            {texts.button}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

SurveyDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  answerSurvey: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
