import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useRecipientPhoneStyles = makeStyles({
  root: {
    background: colors.smoke[50]
  }
});

const useIconHeaderStyles = makeStyles(({ typography }) => ({
  root: {
    border: `solid ${typography.pxToRem(1)} ${colors.blue[500]}`,
    color: colors.blue[500],
    backgroundColor: colors.root[0]
  }
}));

export { useRecipientPhoneStyles, useIconHeaderStyles };
