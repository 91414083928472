import wretch from 'wretch';
import { NETWORK_ERROR_MESSAGE } from 'infra/services/constants';
import authMiddleware from './middlewares';
import { translateStatusCode } from './index';

export default function apiAllocation() {
  return wretch()
    .url(process.env.REACT_APP_ALLOCATION_URL)
    .headers({
      appVersion: process.env.REACT_APP_VERSION,
      service: 'driverapp-routes-list'
    })
    .middlewares([authMiddleware], true)
    .errorType('text')
    .catcher('Error', err =>
      Promise.reject(translateStatusCode(err.status, err.text))
    )
    .catcher('FetchError', () =>
      Promise.reject(translateStatusCode(null, NETWORK_ERROR_MESSAGE))
    )
    .catcher('__fromFetch', () =>
      Promise.reject(translateStatusCode(null, NETWORK_ERROR_MESSAGE))
    );
}
