export const errorTypes = {
  networkError: 'NetworkError',
  genericError: 'GenericError',
  storageError: 'StorageError',
  geolocationError: 'GeolocationError',
  geolocationLostPermission: 'GeolocationLostPermission'
};

const geolocationErrorTypes = [
  errorTypes.geolocationError,
  errorTypes.geolocationLostPermission
];

export function isGeolocationErrorType(errorType) {
  return errorType in geolocationErrorTypes;
}

export default { errorTypes };
