import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  listItem: {
    padding: 0
  },
  button: {
    width: '100%',
    display: 'flex'
  }
}));
