import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';

import {
  Box,
  Grid,
  Card,
  Typography,
  IconButton,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { ArrowBack, EditOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnackbar from 'view/atoms/alert/show-snackbar';
import { useCardStyles } from './styles';
import UserFilledSuboption from './delivering-problems-new-suboption';
import { messages } from './constants';

export default function DetailsStepSuboptions({
  onConfirmProblem,
  onGoBack,
  problem
}) {
  const cardStyles = useCardStyles();

  const newOptionKey = 'newOptionKey';

  const problemSuboptions = problem.problemSuboptions.map(suboption => {
    return { ...suboption, checked: false };
  });

  const [options, setOptions] = useState(problemSuboptions);

  const changeSuboption = (id, checked, value) => {
    setOptions(oldOptions =>
      oldOptions.map(option => {
        if (option.key === id) {
          return value ? { ...option, checked, value } : { ...option, checked };
        }
        return option;
      })
    );
  };

  const hasCheckedOptions = options.some(v => v.checked);

  const { enqueueSnackbar } = useSnackbar();

  const addNewSuboption = (text, action) => {
    setOptions(oldOptions => [
      ...oldOptions,
      {
        key: newOptionKey,
        value: text,
        checked: true,
        action
      }
    ]);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box>
          <Box px={0.5} py={2.5}>
            <Grid container justify="space-between" alignItems="center">
              <IconButton onClick={onGoBack}>
                <ArrowBack style={{ color: colors.blue[500] }} />
              </IconButton>
            </Grid>
          </Box>
        </Box>

        <Box flexGrow={2} overflow="auto">
          <Card elevation={0} classes={cardStyles}>
            <Box pt={1.5}>
              <Box px={2.5}>
                <Typography
                  variant="h5"
                  data-testid="details-step-suboptions-title"
                >
                  {problem.detailsScreenTitle}
                </Typography>
                <Box color={colors.smoke[700]} mt={1.5}>
                  <Typography variant="body1">
                    {messages.detailsScreenTextOptions}
                  </Typography>
                </Box>
              </Box>
              <Box mt={1.5} px={3}>
                <FormGroup>
                  {options.map(suboption => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        style={{ wordBreak: 'break-word' }}
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label={suboption.value}
                          isSuccess={false}
                          data-testid={`check-option-${suboption.key}`}
                          checked={suboption.checked}
                          value={options}
                          style={{ marginTop: 10 }}
                          onChange={event => {
                            changeSuboption(
                              suboption.key,
                              event.target.checked
                            );
                          }}
                        />
                        {suboption.action && (
                          <Button
                            style={{ marginTop: 10 }}
                            data-testid="edit-added-option"
                            onClick={() => suboption.action()}
                          >
                            <EditOutlined />
                          </Button>
                        )}
                      </Box>
                    );
                  })}
                </FormGroup>
              </Box>

              <Box>
                <UserFilledSuboption
                  onConfirmNewOption={(text, action) => {
                    const newSuboptionExists = options.find(
                      option => option.key === newOptionKey
                    );
                    if (!newSuboptionExists) {
                      addNewSuboption(text, action);
                    } else {
                      changeSuboption(
                        newOptionKey,
                        newSuboptionExists.checked,
                        text
                      );
                    }
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Box>

        <Box p={2.5}>
          <Button
            disabled={!hasCheckedOptions}
            onClick={() => {
              onConfirmProblem(options);
              showSnackbar({
                message: messages.notifications.noAttempToDeliver,
                variant: 'successSnackbar',
                enqueueSnackbar
              });
            }}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {messages.confirmSendProblemButtonLabel}
          </Button>
        </Box>
      </Box>
    </>
  );
}

DetailsStepSuboptions.propTypes = {
  onConfirmProblem: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  problem: PropTypes.shape({
    id: PropTypes.number,
    detailsScreenTitle: PropTypes.string,
    textProblemToken: PropTypes.string,
    helpLink: PropTypes.string,
    problemSuboptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
      })
    )
  }).isRequired
};
