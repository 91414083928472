function getMultibarcodesFromNotIntegratedPackages(packages) {
  return packages
    .filter(
      item =>
        item.action === 'BARCODE_CHECK_ACTION_CREATE_PACKAGE_NOT_INTEGRATED' ||
        item.packageId === '-1'
    )
    .map(item => {
      return {
        package_barcodes: item.barcodes.map(barcode => {
          return { content: barcode };
        })
      };
    });
}

export default getMultibarcodesFromNotIntegratedPackages;
