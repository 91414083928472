export default async function doUploadFileToS3(file, url, contentType) {
  const NOTIFICATIONS = {
    UNABLE_TO_LOAD_PHOTO: 'Não foi possível realizar o envio. Tente novamente',
    ERROR_S3: 'Não foi possível comunicar com o S3',
    ERROR_NO_S3_RESPONSE: 'S3 sem resposta'
  };

  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': contentType ?? 'binary/octet-stream'
    }),
    body: file
  });
  if (!response.ok) {
    const messageError = `${NOTIFICATIONS.UNABLE_TO_LOAD_PHOTO}: ${
      NOTIFICATIONS.ERROR_NO_S3_RESPONSE
    }: ${response.status} ${response.statusText}`;
    throw Error(messageError);
  }
  return Promise.resolve(response);
}
