const dateDriverHasSeenOnboarding = 'date-has-already-seen-onboarding';
const dateDriverHasSeenChangePackageStatus =
  'date-has-already-seen-change-package-status';

function getDatesDriverHasSeenOnboarding(key) {
  const dates = localStorage.getItem(key);
  return dates ? dates.split(';') : null;
}

function setDatesDriverHasSeenOnboarding(key) {
  const dates = getDatesDriverHasSeenOnboarding(key);
  const dateToSave =
    dates?.length > 0
      ? `${dates.join(';')};${new Date().toISOString()}`
      : new Date().toISOString();
  return localStorage.setItem(key, dateToSave);
}

function clearDatesDriverHasSeenOnboarding(key) {
  localStorage.removeItem(key);
}

export {
  dateDriverHasSeenOnboarding,
  setDatesDriverHasSeenOnboarding,
  getDatesDriverHasSeenOnboarding,
  clearDatesDriverHasSeenOnboarding,
  dateDriverHasSeenChangePackageStatus
};
