import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { List, Typography, Backdrop } from '@material-ui/core';
import { colors } from '@loggi/mar';
import getFilters from 'infra/services/get-driver-filters';
import getAvailableOffers from 'infra/services/get-driver-offers';
import { parseOfferProtoToOfferCard } from 'infra/services/parsers';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  featureSwitches,
  featureSwitchEnabledForDriverLMC
} from 'operations/feature-switches';
import FilterItem from 'view/molecules/offer-list-item';
import OfferCard from 'view/molecules/offer-card/v2/index';
import OfferCardCapacityReserveV3 from 'view/molecules/offer-card/v3/offer-card-capacity-reserve';
import Loading from 'view/molecules/loading';
import BackHeader from 'view/molecules/back-header';
import OfferDetails from 'view/pages/offer-details';
import { useStyles } from 'view/molecules/offer-card/util';
import ErrorAlert from 'view/molecules/error-alert';
import EmptyOfferComponent from 'view/molecules/empty-list';
import { acceptOfferService } from 'infra/services/accept-offer';
import CapacityReserveDetailsV2 from 'view/pages/offer-details/v2/capacity-reserve-details';
import OfferCardOpportunistic from 'view/molecules/offer-card/v2/offer-card-opportunistic';
import OrderButtonDrawer from 'view/molecules/order-button-drawer';
import logOfferNotificationService from 'infra/services/log-offer-notification';
import getOffer from 'infra/services/get-offer';
import { sortRdOffers } from 'infra/services/sort-rd-offers';
import { SORT_BY } from 'infra/services/constants';
import { openDeepLink } from 'infra/services/app-bridge';
import CapacityReserveConfirmationDrawer from 'view/templates/showcase/showcase-message/capacity-reserve-confirmation-drawer';
import { featureSwitchEnabledForDriverId } from 'operations/feature-switches/feature-switches';
import Puller from '../../../molecules/puller';
import CapacityReserveListButton from '../../../molecules/capacity-reserve-list-button';
import TEXT from '../constants';

/**
 * Filter shown in the select representing all routes
 */
const NO_FILTER = { label: 'Todas as bases', value: '' };

/**
 * The default value for the minimum number of availaber offers
 * to also render the filters.
 */
const MINIMUN_OFFERS_FOR_FILTER_DEFAULT = 4;

export const KEY_LAST_SORT_BY_SELECTED = 'last_sort_by_selected';

function shouldRenderFilter(filterPayload, offersLength, stickFilter) {
  // We initially render the filter if there are filter items available
  // and a minimum number of offers in the list.
  // If the user interacted if the filter once, we stick it.
  if (!filterPayload) {
    return false;
  }

  const minOffersToShowFilter =
    filterPayload.minOffersToShowFilter == null
      ? MINIMUN_OFFERS_FOR_FILTER_DEFAULT
      : filterPayload.minOffersToShowFilter;

  const hasFilters = Boolean(
    filterPayload && filterPayload.items && filterPayload.items.length > 0
  );

  const hasMinimumOffers = offersLength >= minOffersToShowFilter;

  return Boolean(hasFilters) && Boolean(hasMinimumOffers || stickFilter.value);
}

const OffersPage = ({ offersService, filterService, getOfferService }) => {
  const [offerCards, setOfferCards] = useState([]);
  const [cachedOffers, setCachedOffers] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [filterPayload, setFilterPayload] = useState();
  const [selectedFilter, setSelectedFilter] = useState(NO_FILTER);
  const [sortBySelected, setSortBySelected] = useState(SORT_BY.SORT_BY_CLOSEST);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [offerLoading, setOfferLoading] = useState(true);
  const [isOfferCardLoading, setIsOfferCardLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(-1);
  const [showCapacityReserveList, setShowCapacityReserveList] = useState(false);
  const [
    capacityReserveConfirmDrawerOpen,
    setCapacityReserveConfirmDrawerOpen
  ] = useState(false);
  const classes = useStyles();

  const isEnabledUseAllocationToListOffersByDriverId = featureSwitchEnabledForDriverId(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffersByDriverId)
  );
  const isEnabledUseAllocationToListOffers = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enabledUseAllocationToListOffers)
  );
  const useAllocation =
    isEnabledUseAllocationToListOffers ||
    isEnabledUseAllocationToListOffersByDriverId;

  const isEnableSortByInOfferList = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enableSortByInOfferList)
  );

  const isEnableShowCapacityReserveConfirmation = featureSwitchEnabledForDriverLMC(
    useFeatureSwitch(featureSwitches.enableShowCapacityReserveConfirmation)
  );

  const handleOfferList = async offers => {
    setOfferCards(
      isEnableSortByInOfferList
        ? await sortRdOffers(offers, sortBySelected)
        : offers
    );
  };

  const updateOffers = () => {
    setOfferLoading(true);
    if (!filterPayload) return;

    const { type: filterType } = { ...filterPayload };
    const filterValues = selectedFilter.value;
    offersService({
      filterType,
      filterValues,
      sortBy: sortBySelected,
      useAllocation
    })
      .then(response => {
        const offers = response.offers?.offerList
          ? response.offers.offerList.map(offer =>
              parseOfferProtoToOfferCard(offer)
            )
          : [];
        handleOfferList(offers);
        setDisabled(response.disabled);
        // Closes the filter drawer when loading new offers.
        setDrawerOpen(false);
        setCachedOffers({});
      })
      .catch(err => {
        if (err.status === 503) {
          setDisabled(true);
        } else {
          setError(err.message);
        }
      })
      .finally(() => setOfferLoading(false));
  };

  // Run on start up, and everytime selectedFilter changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateOffers, [
    offersService,
    selectedFilter,
    filterPayload,
    sortBySelected
  ]);

  useEffect(() => {
    const lastSortBy = localStorage.getItem(KEY_LAST_SORT_BY_SELECTED);
    setSortBySelected(sortBy => lastSortBy || sortBy);

    setFilterLoading(true);
    filterService()
      .then(filters => {
        if (filters) {
          setFilterPayload(filters);
        } else {
          setFilterPayload({ filter: {} });
        }
      })
      .catch(err => setError(err.message))
      .finally(() => setFilterLoading(false));
  }, [filterService]);

  const handleCloseErrorAlert = () => setError('');

  // eslint-disable-next-line no-unused-vars
  const handleAcceptOffer = index => async offer => {
    setOfferLoading(true);
    const resp = await acceptOfferService(offer, index, 'AVAILABLE_OFFERS')
      .catch(err => {
        setError(err.message);
      })
      .finally(() => setOfferLoading(false));

    if (
      isEnableShowCapacityReserveConfirmation &&
      resp?.isDriverBusy &&
      offer.capacityReserve
    ) {
      setCapacityReserveConfirmDrawerOpen(true);
    } else {
      openDeepLink();
    }
  };

  const handleLoadOfferError = () => {
    setError(TEXT.V2.ERROR.LOAD_OFFER);
  };

  const handleOfferSelect = (offer, index, reloadOffer = false) => {
    if (reloadOffer && useAllocation) {
      if (cachedOffers[offer.id]) {
        setSelectedOffer(cachedOffers[offer.id]);
        setSelectedOfferIndex(index);
        return;
      }

      setIsOfferCardLoading(true);
      getOfferService(offer.id)
        .then(response => {
          const offerCard = response?.offerCard
            ? parseOfferProtoToOfferCard(response.offerCard)
            : handleLoadOfferError();

          setSelectedOffer(offerCard);
          setCachedOffers({ ...cachedOffers, [offer.id]: offerCard });
        })
        .catch(err => {
          updateOffers();
          setSelectedOfferIndex(-1);
          setError(err.message);
        })
        .finally(() => {
          setIsOfferCardLoading(false);
        });
    }

    setSelectedOffer(offer);
    setSelectedOfferIndex(index);
  };

  const handleShowCapacityReserveList = () =>
    setShowCapacityReserveList(
      prevshowCapacityReserveList => !prevshowCapacityReserveList
    );

  const setSortByInLocalStorage = sortBy => {
    localStorage.setItem(KEY_LAST_SORT_BY_SELECTED, sortBy);
    setSortBySelected(sortBy);
  };

  const handleOpenOfferDetails = () => {
    if (selectedOffer && selectedOfferIndex > -1) {
      return (
        <Backdrop className={classes.backdrop} open={selectedOffer}>
          {selectedOffer.capacityReserve ? (
            <CapacityReserveDetailsV2
              offer={selectedOffer}
              onAccept={handleAcceptOffer(selectedOfferIndex)}
              onBack={() => handleOfferSelect(null, -1)}
            />
          ) : (
            <OfferDetails
              offer={selectedOffer}
              onAccept={handleAcceptOffer(selectedOfferIndex)}
              onBack={() => handleOfferSelect(null, -1)}
              isLoading={isOfferCardLoading}
            />
          )}
        </Backdrop>
      );
    }
    return null;
  };

  const history = useHistory();

  const handleBackButtonClick = () => {
    if (showCapacityReserveList) {
      handleShowCapacityReserveList();
    } else {
      history.goBack();
    }
  };

  const filterList = () => {
    if (!filterPayload) return <></>;

    // There's a bug in material UI v4, that when using a SwipeableDrawer,
    // when the anchor is bottom or top, we can't scroll.
    // Using height 100% and overflow auto in this nested div inside the drawer
    // fixes the issue.
    // https://github.com/mui/material-ui/issues/16942#issuecomment-1077980288
    return (
      <Box px="24px" mt="52px" height="100%" overflow="auto">
        <Typography variant="subtitle1">
          <Box fontWeight={600}>Filtrar por base</Box>
        </Typography>

        {filterPayload.title ? (
          <Typography variant="body2" data-testid="filterTitle">
            <Box mt="24px" fontWeight={700} color={colors.smoke[500]}>
              {filterPayload.title.toUpperCase()}
            </Box>
          </Typography>
        ) : null}

        <List overflow="auto">
          <FilterItem
            item={NO_FILTER}
            divider={filterPayload.items.length === 0}
            selected={selectedFilter.value === NO_FILTER.value}
            onSelect={() => setSelectedFilter(NO_FILTER)}
          />
          {filterPayload.items.map((item, index) => (
            <FilterItem
              item={item}
              divider={filterPayload.items.length === index + 1}
              selected={selectedFilter.value === item.value}
              onSelect={() => setSelectedFilter(item)}
            />
          ))}
        </List>
      </Box>
    );
  };

  const filterButton = () => {
    if (!shouldRenderFilter(filterPayload, offerCards.length, selectedFilter)) {
      return null;
    }

    return (
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel px="20px">{selectedFilter.label}</InputLabel>
          <Select
            data-testid="filterBox"
            style={{ borderRadius: 30, fontSize: '14px' }}
            label="Todas as bases"
            disabled
            value=""
            onClick={() => setDrawerOpen(true)}
          />
        </FormControl>

        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: '1rem',
              borderTopRightRadius: '1rem',
              maxHeight: window.innerHeight - 40
            }
          }}
        >
          <Puller />
          {filterList()}
        </SwipeableDrawer>
      </Box>
    );
  };

  const offerCardsCapacityReserve = offerCards.filter(
    offer => offer.capacityReserve
  );
  const offerCardsOpportunistic = offerCards.filter(
    offer => offer.opportunistic
  );
  const offerCardsDefaults = offerCards.filter(
    offer => !offer.capacityReserve && !offer.opportunistic
  );

  const enableLogOfferNotification = useFeatureSwitch(
    featureSwitches.enableLogOfferNotificationPresented
  );

  useEffect(() => {
    if (offerCards.length === 0 || !enableLogOfferNotification) return;

    logOfferNotificationService.logOffersPresented(offerCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCards.length, enableLogOfferNotification]);

  const onCapacityReserveConfirmDrawerClose = () => {
    setCapacityReserveConfirmDrawerOpen(false);
    openDeepLink('/atividades/lista-atividades');
  };

  const renderOfferList = () => {
    let component = (
      <>
        {offerCardsOpportunistic.length > 0 && (
          <>
            <Box>
              <Typography variant="body1">
                <b>{TEXT.V2.SUBTITLE.OPPORTUNISTIC}</b>
              </Typography>
            </Box>
            {offerCardsOpportunistic.map((offer, index) => (
              <Box
                data-testid={`opportunistic-offer-${offer.id}`}
                key={`opportunistic-offer-${offer.id}`}
                onClick={() => handleOfferSelect(offer, index, true)}
                tabIndex="0"
                mb={2}
              >
                <OfferCardOpportunistic offer={offer} />
              </Box>
            ))}
          </>
        )}
        {offerCardsCapacityReserve?.length > 0 && (
          <Box onClick={() => handleShowCapacityReserveList()} tabIndex="0">
            <CapacityReserveListButton />
          </Box>
        )}
        {offerCardsDefaults?.length > 0 && (
          <>
            {isEnableSortByInOfferList && (
              <Box pt={3}>
                <OrderButtonDrawer
                  selectedSortOrder={sortBySelected}
                  setSelectedSortOrder={setSortByInLocalStorage}
                />
              </Box>
            )}
            {!isEnableSortByInOfferList && (
              <Box pt={3}>
                <Typography variant="body1">
                  <b>{TEXT.V2.SUBTITLE.DEFAULT}</b>
                </Typography>
              </Box>
            )}
            {offerCardsDefaults.map((offer, index) => (
              <Box
                data-testid={`offer-${offer.id}`}
                key={`offer-${offer.id}`}
                onClick={() => handleOfferSelect(offer, index, true)}
                onKeyDown={() => ({})}
                tabIndex="0"
              >
                <OfferCard
                  offer={offer}
                  onAccept={handleAcceptOffer(selectedOfferIndex)}
                />
                {index < offerCards.length - 1 ? <Divider /> : null}
              </Box>
            ))}
          </>
        )}
      </>
    );

    if (!offerCards.length || disabled) {
      component = <EmptyOfferComponent disabled={disabled} />;
    }

    if (showCapacityReserveList) {
      component = (
        <>
          {offerCardsCapacityReserve.map((offer, index) => (
            <Box
              data-testid={`capacity-offer-${offer.id}`}
              key={`offer-${offer.id}`}
              onClick={() => handleOfferSelect(offer, index)}
              tabIndex="0"
            >
              <OfferCardCapacityReserveV3
                offer={offer}
                onAccept={handleAcceptOffer(selectedOfferIndex)}
              />
              {index < offerCardsCapacityReserve.length - 1 ? (
                <Divider />
              ) : null}
            </Box>
          ))}
        </>
      );
    }

    return (
      <Box height="100%" display="flex" flexDirection="column">
        {filterButton()}
        {component}
        {handleOpenOfferDetails()}
      </Box>
    );
  };

  const renderHeaderText = () => {
    return showCapacityReserveList
      ? TEXT.V2.HEADER.CAPACITY_RESERVE
      : TEXT.V2.HEADER.DEFAULT;
  };

  return (
    <>
      <Box
        px="24px"
        py="24px"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <BackHeader handleBackButtonClick={handleBackButtonClick} />

        <Typography variant="h6" mb={3}>
          <Box fontWeight={600}>{renderHeaderText()}</Box>
        </Typography>

        {offerLoading || filterLoading ? (
          <Loading />
        ) : (
          <>
            {renderOfferList()}

            {error && (
              <ErrorAlert error={error} onClose={handleCloseErrorAlert} />
            )}
          </>
        )}
      </Box>

      <CapacityReserveConfirmationDrawer
        open={capacityReserveConfirmDrawerOpen}
        onClose={onCapacityReserveConfirmDrawerClose}
      />
    </>
  );
};

OffersPage.propTypes = {
  offersService: PropTypes.func,
  filterService: PropTypes.func,
  getOfferService: PropTypes.func
};

OffersPage.defaultProps = {
  offersService: getAvailableOffers,
  filterService: getFilters,
  getOfferService: getOffer
};

export default OffersPage;
