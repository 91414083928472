import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  setAlreadySeenNewPickupFlowOnboarding,
  getAlreadySeenNewPickupFlowOnboarding
} from 'operations/pickup';
import DrawerWithSteps from 'view/molecules/drawer-with-steps';

export const texts = {
  title: 'Novidades para você!',
  firstStep:
    'A lista exibida prevê todos os itens que devem ser bipados para esta coleta. Você pode conferir qual a quantidade e quais pacotes ainda precisam ser bipados.',
  secondStep:
    'Ao bipar um item da lista, ele será identificado em verde. Isso indica que está tudo certo com a bipagem e o pacote está pronto para ser coletado'
};

function PickupPackagesListOnBoarding() {
  const driverSawOnBoarding = getAlreadySeenNewPickupFlowOnboarding();
  const [shouldShowOnBoarding, setShouldShowOnBoarding] = useState(false);

  useEffect(() => {
    if (!driverSawOnBoarding) {
      setShouldShowOnBoarding(true);
    }
  }, [driverSawOnBoarding]);

  const titleNode = (
    <>
      <Box mt={2} mb={1}>
        <Typography variant="body2">{texts.title}</Typography>
      </Box>
      <Typography variant="h6">
        <b>Veja rapidamente</b> o que já foi bipado
      </Typography>
    </>
  );

  const steps = [
    {
      titleNode,
      subtitle: texts.firstStep
    },
    {
      titleNode,
      subtitle: texts.secondStep
    }
  ];

  return (
    <DrawerWithSteps
      open={shouldShowOnBoarding}
      steps={steps}
      onCloseClick={() => {
        setAlreadySeenNewPickupFlowOnboarding();
        setShouldShowOnBoarding(false);
      }}
    />
  );
}

export default React.memo(PickupPackagesListOnBoarding);
