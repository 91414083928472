import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useSanchesBackground = makeStyles({
  root: {
    background: colors.gradients.sanches
  }
});

export default function LoadingTemplate() {
  return (
    <Box
      data-testid="order-resume-loading-template"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      classes={useSanchesBackground()}
      color="white"
      px={3.5}
    >
      <Box pb={5}>
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );
}
