import { Machine, assign } from 'xstate';
import { useMachine } from '@xstate/react';

const nearbyDCsMachine = Machine(
  {
    id: 'nearby dcs',
    initial: 'loading',
    context: {
      returnPoints: []
    },
    states: {
      loading: {
        invoke: {
          id: 'get Return Points List',
          src: 'getReturnPointsList',
          onDone: {
            actions: assign({
              returnPoints: (_, event) => {
                return event.data.returnPoints;
              }
            }),
            target: 'success'
          },
          onError: {
            target: 'failure'
          }
        }
      },
      success: {
        type: 'final'
      },
      failure: {
        on: {
          retry: 'loading'
        }
      }
    }
  },
  {
    services: {
      // action implementations
      getReturnPointsList: () => {}
    }
  }
);

function useNearbyDCs(getReturnPointsList) {
  const [currentState, sendEvent] = useMachine(
    nearbyDCsMachine.withConfig({
      services: {
        getReturnPointsList
      }
    })
  );

  const returnPointsList = currentState.context.returnPoints;
  const state = currentState.value;
  const isLoading = state === 'loading';
  const hasReturnPointsList = returnPointsList.length > 0;

  const retry = () => sendEvent('retry');

  return {
    state,
    isLoading,

    returnPointsList,
    hasReturnPointsList,

    retry
  };
}

export default useNearbyDCs;
