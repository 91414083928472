import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import Survey from './index';

function SurveyWrapper() {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Survey />
    </ThemeProvider>
  );
}

export default SurveyWrapper;
