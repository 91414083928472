export const messages = {
  failedToSyncLabel: 'A sincronia falhou',
  syncLabel: 'Sincronizar',
  weWereAbleToSyncBut:
    'Conseguimos atualizar o status desse pacote, mas se tiver algum problema com sua rota, você pode tentar novamente.',
  sendAgainButton: 'Atualizar',
  tryToSyncAgainButton: 'Sincronizar novamente',
  tryToSyncAgainMessage:
    'Se este pacote não estiver sincronizado no sistema, toque em ”Sincronizar novamente” e depois que carregar toque em ”Atualizar a lista” no final da tela.',
  syncingLabel: 'Atualizando status',
  collect: 'Coletar ',
  deliver: 'Entregar ',
  pickUp: 'Retirar ',
  until: 'até',
  now: 'agora',
  today: 'hoje'
};

export default { messages };
