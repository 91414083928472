import React from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton, Box, Grid, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CameraScanner from 'view/atoms/scanner2';
import * as Sentry from '@sentry/browser';
import { colors } from '@loggi/mar';
import useWindowOrientation from 'hooks/useWindowOrientation';
import SuccessBeep from './assets/beep_success.wav';
import ErrorBeep from './assets/beep_error.wav';
import TEXTS from './constants';

export function playSuccessBeep() {
  const audio = new Audio(SuccessBeep);
  audio.play();
}

export function playErrorBeep() {
  const audio = new Audio(ErrorBeep);
  audio.play();
}

export default function BarcodeReaderTemplate({
  onGoBack,
  onBarcodeRead,
  onTypeBarcodeClick,
  subtitle,
  allowedFormats,
  showHelpButton,
  onClickHelpButton
}) {
  const orientation = useWindowOrientation();

  const Header = React.memo(() => {
    return (
      <Box width="100%" zIndex="2">
        <Box pl={0.6} pt={2.5} pb={2.7}>
          <Grid container alignItems="center">
            <IconButton
              py={0.5}
              onClick={onGoBack}
              data-testid="button-back-scanner"
            >
              <ArrowBack style={{ color: colors.root[0] }} />
            </IconButton>
            <Box pr={2} ml="auto">
              {showHelpButton && (
                <Button
                  data-testid="help-button-barcode-reader"
                  style={{ color: colors.root[0] }}
                  size="large"
                  onClick={onClickHelpButton}
                >
                  {TEXTS.helpButton}
                </Button>
              )}

              {onTypeBarcodeClick && (
                <Button
                  size="large"
                  variant="outlined"
                  onClick={onTypeBarcodeClick}
                >
                  {TEXTS.TYPE_BARCODE}
                </Button>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  });

  const onDetected = result => {
    Sentry.addBreadcrumb({
      category: 'ui',
      message: 'Barcode captured using camera',
      level: 'info',
      type: 'user'
    });

    onBarcodeRead(result);
  };

  return (
    <Box flex="0 0">
      <Box
        position="relative"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        color="#fff"
        pb={5}
      >
        <Header />
        {subtitle && (
          <Box p={2.5}>
            <Typography align="center" variant="subtitle1">
              {subtitle}
            </Typography>
          </Box>
        )}
        <Box width="100%">
          <CameraScanner
            onDetected={onDetected}
            orientation={orientation}
            allowedFormats={allowedFormats}
          />
        </Box>
      </Box>
    </Box>
  );
}

BarcodeReaderTemplate.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onBarcodeRead: PropTypes.func.isRequired,
  onTypeBarcodeClick: PropTypes.func,
  subtitle: PropTypes.string,
  allowedFormats: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  showHelpButton: PropTypes.bool,
  onClickHelpButton: PropTypes.func
};

BarcodeReaderTemplate.defaultProps = {
  subtitle: '',
  onTypeBarcodeClick: undefined,
  allowedFormats: null,
  showHelpButton: false,
  onClickHelpButton: () => ({})
};
