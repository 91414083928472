import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import OnboardingPage from 'view/pages/onboarding';
import SmsVerificationCodePage from 'view/pages/sms-verification-code';
import LoginPage from 'view/pages/login';

export const pages = {
  login: '/login',
  smsVerificationCode: '/validar_telefone',
  onboardingPage: '/entrar'
};

export default function AuthenticationRoutes({
  createAccountPath,
  onSuccessLoginRedirectPath,
  enableSmsAuth,
  sendEventToAnalytics,
  enableFixSignInWithAnotherAccount
}) {
  return (
    <BrowserRouter>
      <Route path={pages.login} component={LoginPage} />
      <Route
        path={pages.smsVerificationCode}
        component={() => (
          <SmsVerificationCodePage
            onSuccessLoginRedirectPath={onSuccessLoginRedirectPath}
            onGoBack={pages.login}
          />
        )}
      />
      <Route
        exact
        path={pages.onboardingPage}
        component={() => (
          <OnboardingPage
            createAccountPath={createAccountPath}
            onSuccessLoginRedirectPath={onSuccessLoginRedirectPath}
            enableSmsAuth={enableSmsAuth}
            sendEventToAnalytics={sendEventToAnalytics}
            enableFixSignInWithAnotherAccount={
              enableFixSignInWithAnotherAccount
            }
          />
        )}
      />
      <Route
        path="*"
        component={() => <Redirect to={pages.onboardingPage} />}
      />
    </BrowserRouter>
  );
}

AuthenticationRoutes.propTypes = {
  createAccountPath: PropTypes.string,
  onSuccessLoginRedirectPath: PropTypes.string,
  enableSmsAuth: PropTypes.bool,
  sendEventToAnalytics: PropTypes.func,
  enableFixSignInWithAnotherAccount: PropTypes.bool
};

AuthenticationRoutes.defaultProps = {
  createAccountPath: null,
  onSuccessLoginRedirectPath: null,
  enableSmsAuth: false,
  sendEventToAnalytics: () => {},
  enableFixSignInWithAnotherAccount: false
};
