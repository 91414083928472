import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import DynamicLabelTextField from 'view/atoms/dynamic-label-text-field';
import InfoBox from 'view/atoms/infobox';
import { LABELS } from './constants';

export default function PickupProblemsDetail({
  onChange,
  onSubmit,
  infobox,
  defaultTextValue
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box p={3}>
          <DynamicLabelTextField
            fullWidth
            color="primary"
            variant="outlined"
            label={LABELS.reasonTextBox.label}
            focusedLabel={LABELS.reasonTextBox.focusedLabel}
            filledLabel={LABELS.reasonTextBox.focusedLabel}
            type="text"
            onChange={onChange}
            multiline
            rows="5"
            defaultValue={defaultTextValue}
          />
        </Box>
        <InfoBox title={infobox.title} subtitle={infobox.subtitle} />
      </Box>
      <Box p={3}>
        <Button
          size="large"
          fullWidth
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {LABELS.submitProblemButton}
        </Button>
      </Box>
    </Box>
  );
}

PickupProblemsDetail.defaultProps = {
  defaultTextValue: ''
};

PickupProblemsDetail.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  infobox: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
  }).isRequired,
  defaultTextValue: PropTypes.string
};
