import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import InfoIcon from '@material-ui/icons/Info';

export default function InfoBox({ title, infoText }) {
  return (
    <Box borderRadius={8} my={2.5} p={2} display="flex">
      <Box display="flex" justifyContent="center">
        <Box pr={1} pt={0.6}>
          <InfoIcon fontSize="small" data-testid="info-icon" />
        </Box>
      </Box>

      <Box flexDirection="column">
        <Box color={colors.smoke[900]}>
          <Typography variant="h6">
            <Box fontWeight="600">{title}</Box>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{infoText}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

InfoBox.propTypes = {
  title: PropTypes.string,
  infoText: PropTypes.string
};

InfoBox.defaultProps = {
  title: '',
  infoText: ''
};
