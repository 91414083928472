import apiAllocation from 'infra/api/allocation';
import geolocation from './geolocation';
import getDriverAppVersion from './app-bridge';

const urls = {
  loggiWeb: '/api/v1/pedido/oferta-apresentada/',
  allocation: '/v1/offerlogs/logoffernotification'
};

async function logOfferPresented(offer) {
  const isLWOffer = offer.itineraryId;
  if (isLWOffer) return Promise.resolve();

  const pos = await geolocation();
  const appVersion = getDriverAppVersion();
  const body = {
    offer_id: offer.id,
    coords: {
      latitude: pos.latitude,
      longitude: pos.longitude
    },
    app_version: appVersion ? `${appVersion}` : null,
    action: 'OFFER_NOTIFICATION_ACTION_PRESENTED'
  };

  return apiAllocation()
    .url(urls.allocation)
    .post(body)
    .text()
    .catch(e => `Falha ao enviar notificação: ${e}`);
}

async function logOffersPresented(offers = []) {
  if (!offers || offers.length === 0) return Promise.resolve();

  const offerLogs = offers.map(logOfferPresented);
  return Promise.all(offerLogs);
}

export default { logOffersPresented };
