import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { ReactComponent as EmptySearchIcon } from 'view/atoms/illustrations/empty-search/empty-search.svg';
import TEXT from './constants';

export default function EmptySearch({ search, onBarcodeRead }) {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        pt={5.5}
        zIndex={1}
        bgcolor={colors.root[0]}
        flexDirection="column"
        px={3.5}
        textAlign="center"
      >
        <Box pb={5}>
          <EmptySearchIcon />
        </Box>

        <Box color={colors.smoke[700]}>
          <Typography variant="body1">
            {TEXT.WE_DONT_FIND}
            <Box component="span" fontWeight="600">
              {search}
            </Box>
            {TEXT.IN_YOUR_LIST}
          </Typography>
        </Box>

        <Box py={1.5} color={colors.smoke[500]}>
          <Typography variant="body2">
            {TEXT.EMPTY_SEARCH_INSTRUCTIONS}
          </Typography>
        </Box>
      </Box>
      {onBarcodeRead && (
        <Box px={2.5} py={3.5}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onBarcodeRead}
          >
            {TEXT.BUTTON_READ_BARCODE}
          </Button>
        </Box>
      )}
    </>
  );
}

EmptySearch.propTypes = {
  search: PropTypes.string.isRequired,
  onBarcodeRead: PropTypes.func
};

EmptySearch.defaultProps = {
  onBarcodeRead: undefined
};
