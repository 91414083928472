import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';

import { PAGES } from 'view';
import { errorTypes } from 'operations/assignment-list/constants';
import GeolocationPermission from 'view/templates/geolocation-permission';
import GeolocationPermissionNativeApp from 'view/templates/geolocation-permission/geolocation-native-app';

import useAssignmentList, { statesEnum } from 'hooks/use-assignment-list';
import GoogleMapsSurveyOption from 'view/pages/assignment-list/google-maps/google-maps-survey-button';
import AssignmentListHeader from 'view/molecules/assignment-list-header';

import { Box } from '@material-ui/core';
import useRedirectToPickupPackages from 'hooks/use-redirect-to-pickup-packages';
import AssignmentListLastUpdate from 'view/molecules/assignment-list-last-update';
import { goTo } from 'operations/history';
import getAssignmentListWithMutation from 'operations/assignment-list';
import UpdateList from './update-list';
import { GradientContainer, BackToTopButton } from './components';

import LoadingState from './loading-state';
import EmptyListState from './empty-list-state';
import ListWithDataState from './list-with-data-state';
import ErrorDrawers from './error-drawers';
import Reminders from './reminders';
import OnBoardingAssignmentList from './onboarding';

export default function AssignmentList({ getAssignmentList }) {
  const history = useHistory();
  const {
    currentState,
    errorType,
    errorMessage,
    cleanCurrentState,

    packages = [],
    pickups = [],
    assignmentsWaypoints = [],
    hasReturnSteps,
    deliveryFlow,
    timestamp,

    isAnEmptyList,
    isAListWithData,
    didFailToFetchList,
    didFailToUpdateList,
    didFailToUpdatePackage,

    fetchNewList,
    retryPackageSync,
    retryFetchingList,

    allocationErrorType,
    allocationErrorMessage
  } = useAssignmentList(getAssignmentList);

  useRedirectToPickupPackages(assignmentsWaypoints);
  const isLoading = currentState.state === statesEnum.LOADING;

  const onItemClick = item => {
    history.push(
      PAGES.PACKAGE_DETAILS.replace(':packageId', item.packageId),
      item
    );
  };

  const onPickupItemClick = item => {
    goTo.pickupDetails(item);
  };

  if (errorType === errorTypes.geolocationError) {
    return <GeolocationPermission />;
  }

  if (errorType === errorTypes.geolocationLostPermission) {
    return (
      <GeolocationPermissionNativeApp onPermissionVerify={retryFetchingList} />
    );
  }

  function UpdatesSection() {
    return (
      <AssignmentListLastUpdate
        timestamp={timestamp}
        onClick={retryFetchingList}
      />
    );
  }

  const updateList = <UpdateList updateList={fetchNewList} />;

  return (
    <Reminders
      packages={packages}
      deliveryFlow={deliveryFlow}
      hasReturnSteps={hasReturnSteps}
    >
      <GradientContainer>
        <Box position="sticky" zIndex={1} width="100%" top="0">
          <AssignmentListHeader displayHeaderButtons={!isLoading} />
        </Box>

        {isLoading && <LoadingState />}
        {isAnEmptyList && (
          <EmptyListState updateList={updateList}>
            <UpdatesSection />
          </EmptyListState>
        )}
        {isAListWithData && (
          <>
            <ListWithDataState
              packages={packages}
              pickups={pickups}
              assignmentsWaypoints={assignmentsWaypoints}
              onItemClick={onItemClick}
              onPickupItemClick={onPickupItemClick}
              retryPackageSync={retryPackageSync}
              updateList={updateList}
              offerRouteButtonCallback={retryFetchingList}
            />
            <GoogleMapsSurveyOption />

            <UpdatesSection />
            <BackToTopButton />
            <ThemeProvider
              theme={getThemeForUserType({
                userType: userTypeEnum.drivers,
                version: availableThemeVersions.drivers.v2
              })}
            >
              <OnBoardingAssignmentList />
            </ThemeProvider>
          </>
        )}
      </GradientContainer>

      <ErrorDrawers
        currentState={currentState}
        cleanCurrentState={cleanCurrentState}
        errorType={errorType || allocationErrorType}
        didFailToFetchList={didFailToFetchList}
        didFailToUpdateList={didFailToUpdateList}
        didFailToUpdatePackage={didFailToUpdatePackage}
        errorMessage={errorMessage || allocationErrorMessage}
        retryFetchingList={retryFetchingList}
      />
    </Reminders>
  );
}

AssignmentList.displayName = 'AssignmentList';
AssignmentList.propTypes = {
  getAssignmentList: PropTypes.func
};
AssignmentList.defaultProps = {
  getAssignmentList: getAssignmentListWithMutation
};
