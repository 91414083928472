import { BarcodeFormat } from '@zxing/library';

const pickupFlowEnum = {
  useBarcodeBip: 'use_barcode_bip',
  useXDAppAgencyTransfer: 'use_xd_app_agency_transfer',
  automaticFill: 'automatic_fill'
};

const assignmentFlowEnum = {
  useBarcodeBip: 'ASSIGNMENT_FLOW_PICKUP_AT_SHIPPER',
  useXDAppAgencyTransfer: 'ASSIGNMENT_FLOW_PICKUP_AT_DC',
  automaticFill: 'ASSIGNMENT_FLOW_PICKUP_AUTOMATIC_FILL'
};

const packageError = {
  networkError: 'Falha de conexão'
};

const PICKUP_TYPES = {
  INVALID: 0,
  AGENCY: 1,
  SHIPPER: 2,
  DIRECT: 3
};

const ZENDESK_URL = 'https://ajuda.loggi.com/hc/pt-br';
const ZENDESK_PICKUP = 'sections/4406948199181';
const DRIVER_APP_DEEP_LINK =
  'http://driver-app/open/order-resume-activity?earnings=0.0';

const barcodesAllowedFormats = [
  BarcodeFormat.AZTEC,
  BarcodeFormat.CODABAR,
  BarcodeFormat.CODE_39,
  BarcodeFormat.CODE_93,
  BarcodeFormat.CODE_128,
  BarcodeFormat.EAN_8,
  BarcodeFormat.EAN_13,
  BarcodeFormat.ITF,
  BarcodeFormat.MAXICODE,
  BarcodeFormat.PDF_417,
  BarcodeFormat.QR_CODE,
  BarcodeFormat.RSS_14,
  BarcodeFormat.RSS_EXPANDED,
  BarcodeFormat.UPC_A,
  BarcodeFormat.UPC_E
];

const TAG_PICKUP_SURVEY = {
  SUCCESS: 'pickup-successful'
};
export {
  pickupFlowEnum,
  assignmentFlowEnum,
  packageError,
  PICKUP_TYPES,
  ZENDESK_URL,
  ZENDESK_PICKUP,
  barcodesAllowedFormats,
  DRIVER_APP_DEEP_LINK,
  TAG_PICKUP_SURVEY
};
