import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import MobileStepper from '@material-ui/core/MobileStepper';
import {
  useFeatureSwitch,
  isFeatureSwitchEnabledForValue
} from '@loggi/firebase-feature-switches';

import getReturnPointsListDB from 'operations/return-points-list';
import * as storageDB from 'operations/package-return';
import * as deliveryTimeStorage from 'operations/delivery-storage';
import {
  featureSwitchEnabledForDriverCompanyTypeRelation,
  featureSwitches
} from 'operations/feature-switches';
import { getCurrentUser } from 'operations/auth';

import { PAGES as PagesURLs } from 'view';
import { getDayOfWeek } from 'view/utils';
import { QuestionsAboutPackageReturn } from 'view/pages/package-return';
import { ReactComponent as BoxIcon } from './icons/box.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as NewsIcon } from './icons/news.svg';
import messages from './messages';

const RENDER_STATE = {
  LOADING: 'loading',
  REMINDER: 'reminder'
};

export default function PackageReturnReminder({ onOk, pickupDate }) {
  const [returnPointMaxCloseAt, setReturnPointMaxCloseAt] = useState('');
  const [rendering, setRendering] = useState(RENDER_STATE.LOADING);
  const dayOfWeek = getDayOfWeek(pickupDate);
  const deadlineDeliveryTime = deliveryTimeStorage.getDeadlineDeliveryTime();

  const { id: driverId } = getCurrentUser() || {};
  const driversEnabledForGeofence = useFeatureSwitch(
    featureSwitches.driversEnabledForGeofence
  );
  const showGeofenceReminder = isFeatureSwitchEnabledForValue(
    driverId?.toString(),
    driversEnabledForGeofence
  );
  const enableNewPackageReturnReminder = useFeatureSwitch(
    featureSwitches.enableNewPackageReturnReminder
  );

  const enableNewPackageReturnReminderByRelation = featureSwitchEnabledForDriverCompanyTypeRelation(
    enableNewPackageReturnReminder
  );

  useEffect(() => {
    if (rendering === RENDER_STATE.LOADING) {
      getReturnPointsListDB()
        .then(data => {
          setRendering(RENDER_STATE.REMINDER);
          storageDB.setReturnPointCloseAt(data?.returnPoints || []);
          setReturnPointMaxCloseAt(storageDB.getReturnPointCloseAt());
        })
        .catch(() => {
          setRendering(RENDER_STATE.REMINDER);
          setReturnPointMaxCloseAt('');
          storageDB.clearReturnPointCloseAt();
        });
    }
  }, [rendering, returnPointMaxCloseAt]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    onOk();
  };

  return (
    <>
      {rendering === RENDER_STATE.LOADING && (
        <Box
          px={2.5}
          py={2.5}
          minHeight="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box px={3.5}>
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              height={64}
            />
            <Skeleton
              component={Box}
              variant="rect"
              borderRadius="8px"
              my={1.5}
              height={32}
              width={85}
            />
          </Box>
        </Box>
      )}
      {rendering === RENDER_STATE.REMINDER && (
        <Box
          px={0.5}
          py={2.5}
          minHeight="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <IconButton onClick={handleClose} data-testid="close-button">
              <Close color="primary" />
            </IconButton>
          </Box>

          {activeStep === 0 && showGeofenceReminder ? (
            <>
              <Box
                px={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
              >
                <MapIcon />
                <NewsIcon />

                <Box pt={5} pb={1.25}>
                  <Typography variant="h5">
                    {messages.packageReturnReminder.geofence.title}
                  </Typography>
                </Box>
                <Typography variant="body1" align="center">
                  {messages.packageReturnReminder.geofence.subtitle}
                </Typography>
              </Box>
              <Box mt="auto" px={2}>
                <MobileStepper
                  data-testid="mobile-stepper-reminder-geofence"
                  style={{ justifyContent: 'center', marginBottom: 12 }}
                  variant="dots"
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                />
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleNext}
                >
                  {messages.packageReturnReminder.geofence.button}
                </Button>
              </Box>
            </>
          ) : (
            <>
              {enableNewPackageReturnReminderByRelation ? (
                <>
                  <Box
                    px={2}
                    mt={9}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <BoxIcon />

                    <Box pt={5} pb={1.25} textAlign="center">
                      <Typography variant="h5">
                        {messages.newPackageReturnReminder.title(
                          deadlineDeliveryTime
                        )}
                      </Typography>
                    </Box>
                    <Typography variant="body1" align="center">
                      {messages.newPackageReturnReminder.dcReturnTimeAlert}
                    </Typography>
                    <Box py={2.5}>
                      <Alert severity="info">
                        {
                          messages.newPackageReturnReminder
                            .agencyReturnReminderAlert
                        }
                      </Alert>
                    </Box>
                  </Box>
                  <Box pb={2} px={2} mt="auto">
                    {showGeofenceReminder && (
                      <MobileStepper
                        data-testid="mobile-stepper-reminder-package-return"
                        style={{ justifyContent: 'center', marginBottom: 12 }}
                        variant="dots"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                      />
                    )}
                    <Button
                      fullWidth
                      color="primary"
                      size="large"
                      variant="contained"
                      data-testid="btn-proceed-to-package-list"
                      onClick={handleClose}
                    >
                      {messages.newPackageReturnReminder.proceedButtonText}
                    </Button>
                    <Button
                      style={{ marginTop: 15 }}
                      fullWidth
                      color="primary"
                      size="large"
                      variant="outlined"
                      component={Link}
                      data-testid="btn-go-to-questions-return-pkg"
                      to={{
                        pathname: `${PagesURLs.PACKAGE_RETURN}${
                          QuestionsAboutPackageReturn.url
                        }`,
                        state: {
                          displayButtonToCloseReminder: true
                        }
                      }}
                    >
                      {messages.packageReturnReminder.howToReturnButtonText}
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    px={2}
                    mt={9}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <BoxIcon />

                    <Box pt={5} pb={1.25}>
                      <Typography variant="h5">
                        {messages.packageReturnReminder.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" align="center">
                      {messages.packageReturnReminder.returnPackage}

                      <Box component="span" fontWeight="bold">
                        {!deadlineDeliveryTime
                          ? messages.packageReturnReminder.defaultReturnReminder
                          : deadlineDeliveryTime}
                      </Box>

                      {dayOfWeek >= 6
                        ? messages.packageReturnReminder.returnWeekend
                        : messages.packageReturnReminder.getReturnWeekday(
                            returnPointMaxCloseAt
                          )}
                    </Typography>
                    <Box py={2.5}>
                      <Alert severity="info">
                        {messages.packageReturnReminder.dcReturnTimeAlert}
                      </Alert>
                    </Box>
                  </Box>
                  <Box pb={2} px={2} mt="auto">
                    {showGeofenceReminder && (
                      <MobileStepper
                        data-testid="mobile-stepper-reminder-package-return"
                        style={{ justifyContent: 'center', marginBottom: 12 }}
                        variant="dots"
                        steps={2}
                        position="static"
                        activeStep={activeStep}
                      />
                    )}
                    <Button
                      fullWidth
                      color="primary"
                      size="large"
                      variant="contained"
                      component={Link}
                      data-testid="btn-go-to-questions-return-pkg"
                      to={{
                        pathname: `${PagesURLs.PACKAGE_RETURN}${
                          QuestionsAboutPackageReturn.url
                        }`,
                        state: {
                          displayButtonToCloseReminder: true
                        }
                      }}
                    >
                      {messages.packageReturnReminder.howToReturnButtonText}
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
}
PackageReturnReminder.propTypes = {
  onOk: PropTypes.func.isRequired,
  pickupDate: PropTypes.instanceOf(Date).isRequired
};
