import React from 'react';
import { ReactComponent as BlueLoggiAgency } from 'view/atoms/icons/loggi/blue-loggi-agency.svg';
import { Box, Typography, Drawer, Button } from '@material-ui/core';
import { useRoundedBorder } from 'view/utils/styles';
import usePersistedState, {
  persistedStateKeys
} from 'hooks/use-persisted-state';
import messages from './messages';
import { ZENDESK_URL, ZENDESK_PICKUP } from './constants';

export default function AgencyReminder() {
  const roundedBorder = useRoundedBorder();
  const [showingReminder, setShowingReminder] = usePersistedState(
    `${persistedStateKeys.showAgencyReminderDrawer}`,
    true
  );

  const handleOnClick = () => {
    window.location.href = `${ZENDESK_URL}/${ZENDESK_PICKUP}`;
  };
  return (
    <Drawer
      anchor="bottom"
      open={showingReminder}
      classes={{ paper: roundedBorder.root }}
    >
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        pt={7}
        pb={2.5}
        px={2.5}
      >
        <BlueLoggiAgency />

        <Box pt={3.5} pb={7} display="flex">
          <Typography variant="h5" align="center">
            {messages.agencyRemider.title}
          </Typography>
        </Box>
        <Typography variant="subtitle2" align="center">
          {messages.agencyRemider.text}
        </Typography>
      </Box>
      <Box>
        <Box pt={3.5} pb={2.5} mx={4.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            data-testid="btn-start-delivery-to-agency"
            onClick={() => setShowingReminder(false)}
          >
            {messages.agencyRemider.startPickupButton}
          </Button>
        </Box>
        <Box pb={2.5} mx={4.5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="outlined"
            data-testid="btn-problems"
            onClick={() => handleOnClick()}
          >
            {messages.agencyRemider.buttonProblems}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
