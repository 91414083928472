import React from 'react';
import PropTypes from 'prop-types';
import { Router, Switch } from 'react-router-dom';
import history from 'operations/history';

const Routes = props => {
  const { children } = props;
  return (
    <Router history={history}>
      <Switch>{children}</Switch>
    </Router>
  );
};

Routes.propTypes = {
  children: PropTypes.node,
  menuConfig: PropTypes.shape({
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired
      })
    )
  })
};

Routes.defaultProps = {
  children: null,
  menuConfig: null
};

export default Routes;
