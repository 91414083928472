import React from 'react';
import { Typography } from '@material-ui/core';

export const problemReasons = {
  invalidDestination: {
    id: 13,
    text: 'Não encontrei o endereço',
    description:
      'Endereço errado ou incompleto \u2022 destinatário se mudou \u2022 problema com localização ou GPS',
    process: 'delivery',
    destination: 'in_progress',
    detailsScreenTitle: 'Não achou o endereço?',
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: true,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: true
  },
  recipientUnavailable: {
    id: 12,
    text: 'Não encontrei ninguém',
    description:
      'Destinatário ausente  \u2022 local fechado  \u2022  portaria se recusou a receber  \u2022 ninguém com esse nome',
    process: 'delivery',
    destination: 'in_progress',
    detailsScreenTitle: 'Não achou ninguém pra receber?',
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: true,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: false
  },
  recipientRefused: {
    id: 11,
    text: 'Se recusou a receber',
    description:
      'Destinatário não reconheceu o pacote \u2022 não quis aceitar o pacote',
    process: 'delivery',
    destination: 'in_progress',
    detailsScreenTitle: 'Não quiseram receber?',
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: true,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: true
  },
  unavailableSafeDeliveryToken: {
    id: 117,
    text: 'Não consegui o código de confirmação',
    process: 'delivery',
    destination: 'in_progress',
    detailsScreenTitle: 'Não consegui o código de confirmação',
    textProblemToken: 'Principais dúvidas sobre o código na ',
    helpLink:
      'https://ajuda.loggi.com/hc/pt-br/sections/360013018051-Entrega-com-C%C3%B3digo-de-Seguran%C3%A7a',
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: true,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: false
  },
  unableToGoToDeliveryLocation: {
    id: 0,
    text: 'Não consegui ir ao local',
    description:
      'Motivo pessoal \u2022 local difícil de estacionar \u2022 região perigosa \u2022 acesso ao local estava bloqueado',
    detailsScreenTitle: (
      <Typography variant="h5">
        Por que <b>não foi possível</b> ir até o local da entrega?
      </Typography>
    ),
    confirmProblemButtonLabel: 'Enviar problema',
    problemSuboptions: [
      { key: 'dangereousPlace', value: 'Local perigoso' },
      { key: 'parkingProblem', value: 'Região difícil de estacionar' },
      {
        key: 'tooManyPackages',
        value:
          'Peguei muitos pacotes e por isso não vou conseguir entregar esse'
      },
      {
        key: 'distantPlace',
        value: 'Esse pacote está muito distante (fora da rota)'
      },
      {
        key: 'scheduleProblem',
        value:
          'Comecei a rota muito tarde e por isso não vou conseguir entregar esse'
      },
      {
        key: 'unforeseenProblem',
        value: 'Tive um imprevisto (problema no veículo, roubo, etc.)'
      },
      {
        key: 'blockedAccess',
        value: 'O acesso ao local está bloqueado (alagamento, obra, etc.)'
      },
      { key: 'newLogger', value: 'Sou novo na Loggi e tive dificuldades' },
      { key: 'dontWantToDeliver', value: 'Não quero entregar esse pacote' }
    ],
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: true,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: false
  },
  /**
   * This status is a fake door to change a status of a delivered package
   * to an "in progress" status(code 3 - PICKUP)
   */
  didntDelivered: {
    id: 3,
    text: 'Não consegui entregar',
    description: '',
    process: 'delivery',
    destination: 'in_progress',
    detailsScreenTitle: 'Não consegui entregar',
    // Flag to list this problem for a package with a general status except delivered
    showForGeneralStatus: false,
    // Flag to list this problem for a package with a delivered status
    showForDeliveredStatus: true
  }
};

export const problemsList = Object.values(problemReasons);

export const messages = {
  notifications: {
    defaultUpdateStatusFailureText:
      'Eita! Deu algum ruim ao atualizar a entrega, tente novamente.',
    noAttempToDeliver: 'Problema reportado!'
  },
  selectionStep: {
    headerText: 'O que aconteceu?',
    subtitle: quantity => `Selecione um dos ${quantity} motivos:`
  },
  loadingStep: {
    infoText: 'Estamos atualizando a informação da sua entrega...'
  },
  tryToSolve: 'Tentar resolver ligando para',
  confirmProblemButtonLabel: 'Informar tentativa',
  confirmSendProblemButtonLabel: 'Enviar problema',
  addNewOption: 'Incluir outro motivo',
  addNewOptionLabel: 'Inserir alternativa',
  tokenDoubtsButtonLabel: 'Tenho dúvidas sobre o código',
  detailsScreenText:
    'Tudo bem. Nesse caso você pode tentar novamente mais tarde ou devolver ainda hoje para a agência, mas antes não esqueça de informar a tentativa de entrega.',
  detailsScreenTextOptions: 'Você pode selecionar várias opções.',
  anotherReason: 'Adicionar alternativa'
};
