const keyPickupIdFromOfferCapacityReserve = 'pickup-id-offer-capacity-reserve';
const keyNewPickupFlowOnboarding = 'already-seen-new-pickup-flow-onboarding';
const keyPickupHadTypedBarcodes = 'pickup-had-typed-barcodes';

const setPickupIdFromOfferCapacityReserve = pickupId => {
  localStorage.setItem(keyPickupIdFromOfferCapacityReserve, pickupId);
};

const getPickupIdFromOfferCapacityReserve = () => {
  return localStorage.getItem(keyPickupIdFromOfferCapacityReserve);
};

const removePickupIdFromOfferCapacityReserve = () => {
  localStorage.removeItem(keyPickupIdFromOfferCapacityReserve);
};

const setAlreadySeenNewPickupFlowOnboarding = () => {
  localStorage.setItem(keyNewPickupFlowOnboarding, true);
};

const getAlreadySeenNewPickupFlowOnboarding = () => {
  return localStorage.getItem(keyNewPickupFlowOnboarding);
};

const clearAlreadySeenNewPickupFlowOnboarding = () => {
  localStorage.removeItem(keyNewPickupFlowOnboarding);
};

const setPickupHadTypedBarcodes = pickupCode => {
  localStorage.setItem(`${keyPickupHadTypedBarcodes}/${pickupCode}`, true);
};

const getPickupHadTypedBarcodes = pickupCode => {
  return (
    JSON.parse(
      localStorage.getItem(`${keyPickupHadTypedBarcodes}/${pickupCode}`)
    ) || false
  );
};

const clearPickupHadTypedBarcodes = pickupCode => {
  localStorage.removeItem(`${keyPickupHadTypedBarcodes}/${pickupCode}`);
};

export {
  setPickupIdFromOfferCapacityReserve,
  removePickupIdFromOfferCapacityReserve,
  getPickupIdFromOfferCapacityReserve,
  setAlreadySeenNewPickupFlowOnboarding,
  getAlreadySeenNewPickupFlowOnboarding,
  clearAlreadySeenNewPickupFlowOnboarding,
  setPickupHadTypedBarcodes,
  getPickupHadTypedBarcodes,
  clearPickupHadTypedBarcodes
};
