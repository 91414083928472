import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as SuccessIcon } from 'view/atoms/icons/circle-confirmation.svg';
import { ReactComponent as RedSuccessIcon } from 'view/atoms/icons/red-circle-confirmation.svg';

const variantIcon = {
  alert: RedSuccessIcon,
  successSnackbar: SuccessIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const variantColors = {
  alert: {
    backgroundColor: '#333333',
    textColor: '#FFFFFF'
  }
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export { variantIcon, useStyles, variantColors };
