import React from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import sharedPropTypes from 'view/shared-prop-types';

export default function CargoRow({ cargo }) {
  return (
    <ListItem divider alignItems="flex-start" style={{ paddingLeft: 30 }}>
      <Box display="flex" flexDirection="column" flexGrow={1} pt={1.5}>
        <Box display="flex">
          <Box width="100%" textAlign="left">
            <Box mt={0.3}>
              <Typography variant="body2">
                <Box component="span" color={colors.smoke[800]}>
                  {cargo.shipperName}
                </Box>
              </Typography>
            </Box>
            <Box mt={0.3} pb={1.5}>
              <Typography variant="body2">
                #
                <Box component="span" color={colors.smoke[800]}>
                  {cargo.code}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
CargoRow.propTypes = {
  cargo: sharedPropTypes.assignmentCargo.isRequired
};
