import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@material-ui/core';

/** This component introduces yet another side effect to
 *  the assignment list, which will make it really hard to
 *  test other things.
 *  The functions below are for tests/stories to disable this
 *  functionality. */
export const disableAutoUpdateList = () =>
  localStorage.setItem('disable-auto-update-list', true);
const shouldDisableAutoUpdateList = () =>
  localStorage.getItem('disable-auto-update-list');
/** end */

export const messages = {
  searchingForUpdates: 'Buscando atualizações...',
  yourAssignments: 'Sua lista de atividades'
};

function UpdateList({ updateList }) {
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    if (shouldDisableAutoUpdateList()) {
      setIsUpdating(false);
      return;
    }

    updateList().finally(() => setIsUpdating(false));
  }, [updateList]);

  if (!isUpdating) {
    return <Typography variant="body2">{messages.yourAssignments}</Typography>;
  }

  return (
    <Box px={2.5}>
      <Box display="flex" alignItems="center" color="primary.contrastText">
        <Box pr={2} ml={-1} display="flex">
          <CircularProgress size="20px" style={{ color: 'white' }} />
        </Box>
        {messages.searchingForUpdates}
      </Box>
    </Box>
  );
}

UpdateList.propTypes = {
  updateList: PropTypes.func.isRequired
};

export default UpdateList;
