/**
 * DeliveryEvidenceDB is a database where we keep all the delivery evidences picked by the user.
 * "Delivery Evidence" here refers to the delivery evidences picked by the user.
 *
 * DeliveryEvidenceDB keeps its information on a table in the IndexedDB and all operations made
 * to this table are coordinated by a library called Dexie.
 *
 * Before triggering a request to the server with the update status data, that mutation
 * is registrered on DraftDB. This ensures that the information inputted by the user is
 * not lost between offline/online cycles and page refreshes.
 */

import Dexie from 'dexie';
import wrapOperationsInDexieErrorHandler from 'operations/handle-dexie-errors';

/** @type {Dexie} */
const deliveryEvidenceDB = new Dexie('deliveryEvidence', {
  chromeTransactionDurability: 'relaxed'
});

deliveryEvidenceDB.version(1).stores({
  deliveryEvidence: `
        packageId,
        image,
        url,
        contentType
      `
});

/** @returns {Promise<void>} */
const clearDB = async () => {
  await deliveryEvidenceDB.deliveryEvidence.clear();
};

/** @param {DeliveryEvidence.payload} payload
 *  @returns {Promise<number>} - primary key of the delivery evidence */
const putDeliveryEvidence = async payload =>
  deliveryEvidenceDB.table('deliveryEvidence').put({
    packageId: payload.packageId,
    url: payload.deliveryEvidence.url,
    created: Date.now(),
    image: payload.deliveryEvidence.image,
    contentType: payload.deliveryEvidence.contentType
  });

/** @param {number} packageId
 *  @returns {Promise<void>} */
const deleteDeliveryEvidence = async packageId =>
  deliveryEvidenceDB.table('deliveryEvidence').delete(packageId);

/** @param {number} id - package id
 *  @returns {Promise<Array<DeliveveryEvidence>>} */
const getDeliveryEvidenceByPackageId = async id => {
  const deliveryEvidence = await deliveryEvidenceDB
    .table('deliveryEvidence')
    .where({ packageId: id })
    .first();

  if (!deliveryEvidence) return undefined;

  const { packageId, image, url, contentType, created } = deliveryEvidence;
  return { packageId, image, url, contentType, created };
};

export default wrapOperationsInDexieErrorHandler({
  dbName: 'deliveryEvidenceDB',
  operations: {
    clearDB,
    putDeliveryEvidence,
    getDeliveryEvidenceByPackageId,
    deleteDeliveryEvidence
  }
});
