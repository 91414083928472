import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Typography, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as Icon } from './icons/thankyou-icon.svg';

export const texts = {
  title: 'Agradecemos muito pela participação!',
  subtitle:
    'Utilizaremos os dados coletados para melhorar cada vez mais a experiência de entregar com a Loggi!',
  button: 'Ok, entendi'
};

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function ThankYouPage({ onConfirm }) {
  const { colors, typography } = useTheme();
  const { pxToRem } = typography;

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={3}
      color={colors.neutrals.white.pure}
      bgcolor={colors.primary.pure}
    >
      <CloseIcon onClick={onConfirm} />
      <Box>
        <Icon />
        <Typography
          variant="h6"
          style={{
            fontWeight: typography.fontWeightBold,
            marginTop: pxToRem(24),
            marginBottom: pxToRem(16)
          }}
        >
          {texts.title}
        </Typography>
        <Typography variant="body">{texts.subtitle}</Typography>
      </Box>
      <Button
        mt="auto"
        size="large"
        fullWidth
        variant="outlined"
        color={colors.neutrals.white.pure}
        onClick={onConfirm}
      >
        {texts.button}
      </Button>
    </Box>
  );
}

ThankYouPage.propTypes = {
  onConfirm: PropTypes.func.isRequired
};
