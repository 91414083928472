import { Machine, send, assign } from 'xstate';
import finishItinerary from 'operations/finish-itinerary';
import {
  pendingSyncPackages,
  clearMutationsOfDeliveredPackages
} from 'operations/update-status';
import { clearHasReadPackageReturnReminder } from 'operations/package-return';
import sendItineraryFinishedUTCDateToInsider from 'operations/insider';

export const STATES = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
  pending: 'pending',
  checkingDB: 'checkingDB',
  pendingSync: 'pendingSync',
  pendingDelivery: 'pendingDelivery',
  pendingProtocol: 'pendingProtocol',
  pendingDcDelivery: 'pendingDcDelivery',
  pendingDcPackages: 'pendingDcPackages',
  clearStorage: 'clearStorage'
};

export default Machine({
  id: 'order resume',
  initial: STATES.loading,
  context: {
    pendingSyncPackages: undefined,
    pendingDeliveryPackages: undefined,
    response: undefined,
    earnings: 0,
    clearPackagesMutationAfterFinishItinerary: false,
    sendDataToInsider: false
  },
  states: {
    loading: {
      on: {
        SUCCESS: STATES.success,
        PENDING: STATES.pending,
        FAILURE: STATES.failure,
        PENDING_PROTOCOL: STATES.pendingProtocol,
        PENDING_DC_DELIVERY: STATES.pendingDcDelivery,
        CLEAR_STORAGE: STATES.clearStorage
      },
      invoke: {
        id: 'finish itinerary',
        src: finishItinerary,
        onDone: {
          actions: [
            assign({
              earnings: (_, event) => {
                return event.data.total_earnings;
              }
            }),
            clearHasReadPackageReturnReminder,
            send(context => {
              if (context.clearPackagesMutationAfterFinishItinerary) {
                return { type: 'CLEAR_STORAGE' };
              }
              return { type: 'SUCCESS' };
            })
          ]
        },
        onError: {
          actions: [
            assign({
              pendingDeliveryPackages: (_, event) => {
                if (!event.data.response) return null;

                const { response } = event.data;

                if (!response.errors) {
                  return null;
                }

                const pendingPackagesError = response.errors.find(
                  element =>
                    element.type === 'pending_packages' ||
                    element.type === 'pending_packages_to_dc'
                );

                return pendingPackagesError
                  ? pendingPackagesError.amount
                  : null;
              },
              response: (_, event) => {
                return event.data?.response;
              }
            }),
            send((_, event) => {
              const errors = event.data?.response?.errors || [];

              const pendingPackagesError = errors.find(
                element => element.type === 'pending_packages'
              );
              if (pendingPackagesError) {
                return { type: 'PENDING' };
              }

              const driverWithoutItinerary = errors.find(
                element => element.type === 'itinerary_not_found'
              );
              if (driverWithoutItinerary) {
                return { type: 'SUCCESS' };
              }

              const pendingProtocolError = errors.find(
                element => element.type === 'pending_protocol'
              );

              if (pendingProtocolError) {
                return { type: 'PENDING_PROTOCOL' };
              }

              const pendingDcDeliveryError = errors.find(
                element => element.type === 'pending_packages_to_dc'
              );

              if (pendingDcDeliveryError) {
                return { type: 'PENDING_DC_DELIVERY' };
              }

              return { type: 'FAILURE' };
            })
          ]
        }
      }
    },
    clearStorage: {
      invoke: {
        id: STATES.clearStorage,
        src: () => clearMutationsOfDeliveredPackages(),
        onDone: {
          target: STATES.success
        },
        onError: {
          target: STATES.failure
        }
      }
    },
    success: {
      type: 'final',
      invoke: {
        id: 'send data to insider',
        src: async context => {
          if (context.sendDataToInsider) {
            const finishedItineraryAt = new Date().toISOString();
            await sendItineraryFinishedUTCDateToInsider(finishedItineraryAt);
          }
        }
      }
    },
    failure: {
      id: STATES.failure,
      on: {
        RETRY: STATES.loading
      }
    },
    pending: {
      initial: STATES.checkingDB,
      on: {
        SYNC_FAILURE: STATES.failure
      },
      states: {
        checkingDB: {
          on: {
            PENDING_SYNC: STATES.pendingSync,
            PENDING_DELIVERY: STATES.pendingDelivery,
            FAILURE: `#${STATES.failure}`
          },
          invoke: {
            id: 'check sync packages',
            src: pendingSyncPackages,
            onDone: {
              actions: [
                assign({
                  pendingSyncPackages: (_, event) => {
                    return event.data;
                  }
                }),
                send((_, event) => {
                  if (event.data > 0) {
                    return { type: 'PENDING_SYNC' };
                  }

                  return { type: 'PENDING_DELIVERY' };
                })
              ]
            },
            onError: {
              actions: [send('FAILURE')]
            }
          }
        },
        pendingSync: {
          type: 'final'
        },
        pendingDelivery: {
          type: 'final'
        }
      }
    },
    pendingProtocol: {
      type: 'final'
    },
    pendingDcDelivery: {
      initial: STATES.checkingDB,
      on: {
        FAILURE: STATES.failure
      },
      states: {
        checkingDB: {
          on: {
            PENDING_SYNC: STATES.pendingSync,
            PENDING_DC_PACKAGES: STATES.pendingDcPackages,
            FAILURE: `#${STATES.failure}`
          },
          invoke: {
            id: 'check sync packages',
            src: pendingSyncPackages,
            onDone: {
              actions: [
                assign({
                  pendingSyncPackages: (_, event) => {
                    return event.data;
                  }
                }),
                send((_, event) => {
                  if (event.data > 0) {
                    return { type: 'PENDING_SYNC' };
                  }
                  return { type: 'PENDING_DC_PACKAGES' };
                })
              ]
            },
            onError: {
              actions: [send('FAILURE')]
            }
          }
        },
        pendingSync: {
          type: 'final'
        },
        pendingDcPackages: {
          type: 'final'
        }
      }
    }
  }
});
