import React from 'react';
import { colors } from '@loggi/mar';
import { Box, Typography, CircularProgress } from '@material-ui/core';

function LoadingPage() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100%"
      width="100%"
      bgcolor={colors.blue[500]}
      data-testid="loading-page"
    >
      <Box display="flex" alignItems="center" color={colors.root[0]}>
        <Box pr={2} ml={-1} display="flex">
          <CircularProgress size="20px" style={{ color: 'white' }} />
        </Box>
        <Typography>Carregando...</Typography>
      </Box>
    </Box>
  );
}

export default LoadingPage;
