import wretch from 'wretch';
import { releaseTag } from '../../../../package.json';
import {
  authMiddleware,
  intercept401Middleware,
  intercept403Middleware,
  grpcInterceptors
} from './middlewares';

export default function apiAllocation() {
  return wretch()
    .url(process.env.REACT_APP_ALLOCATION_URL)
    .headers({
      appVersion: process.env.REACT_APP_VERSION || releaseTag,
      service: 'flecha'
    })
    .middlewares(
      [
        authMiddleware,
        intercept401Middleware,
        intercept403Middleware,
        grpcInterceptors.intercept409Middleware
      ],
      true
    )
    .errorType('json');
}
