export const messages = {
  driverAppNotFound: 'User is not logged via driver-app',
  errorInGetDriverApp: 'Error in get driver-app version'
};

function getDriverAppVersion() {
  let version = messages.driverAppNotFound;
  if (window.driverAppBridge?.getAppVersion) {
    try {
      version = window.driverAppBridge?.getAppVersion();
    } catch (error) {
      return `${messages.errorInGetDriverApp} ${error}`;
    }
  }
  return version;
}

export default getDriverAppVersion;
