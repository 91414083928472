import { colors } from '@loggi/mar';
import { useTheme } from '@material-ui/core/styles';
import { unsucessfulStatusCodes } from 'view/atoms/status-code-chip/constants';
import { statusCodes } from 'operations/update-status';

export const indexIconType = {
  number: 'assignment',
  default: 'default_assignment',
  capacityReserve: 'capacity_reserve',
  numberNewTheme: 'assignment_new_theme'
};

export const indexIconStyle = {
  [indexIconType.number]: {
    color: colors.blue[500],
    borderColor: colors.blue[500],
    border: 2,
    style: {
      backgroundColor: colors.root[0]
    }
  },
  [indexIconType.numberNewTheme]: (fromDetails, statusCode) => {
    const isUnsuccessfulDelivery = unsucessfulStatusCodes.includes(statusCode);
    const isSucessfulDelivered = statusCode === statusCodes.DELIVERED;

    const { colors: newThemeColors } = useTheme();

    const defaultProps = {
      color: newThemeColors.primary.pure,
      borderColor: newThemeColors.primary.pure,
      border: fromDetails ? 'none' : 1,
      style: {
        backgroundColor: newThemeColors.neutrals.white.pure,
        height: 26,
        borderRadius: '20px',
        padding: 9,
        inlineSize: 'fit-content'
      }
    };

    if (isUnsuccessfulDelivery) {
      return {
        ...defaultProps,
        color: newThemeColors.neutrals.white.pure,
        borderColor: newThemeColors.neutrals.typeface.secondary,
        style: {
          ...defaultProps.style,
          backgroundColor: newThemeColors.neutrals.typeface.secondary
        }
      };
    }

    if (isSucessfulDelivered) {
      return {
        ...defaultProps,
        color: newThemeColors.neutrals.white.pure,
        borderColor: newThemeColors.status.positive.pure,
        style: {
          ...defaultProps.style,
          backgroundColor: newThemeColors.status.positive.pure
        }
      };
    }

    return defaultProps;
  }
};
