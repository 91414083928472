import getPosition from 'operations/geolocation';
import {
  makeBatches,
  orderByOptimization,
  getCoordinates,
  shouldAddOriginToFirstBatch
} from './service-helper';

/* Documentation for Google's API
 *  https://developers.google.com/maps/documentation/javascript/directions#DirectionsRequests
 */

/**
 * defaultMinAllowedPerBatch: 1 origin + 1 destination + min of 2 waypoints to order through the API
 */
const defaultMinAllowedPerBatch = 4;

let scriptLoaded = false;

export const initializeService = () => {
  scriptLoaded = true;
};

function waitUntilScriptLoaded() {
  return new Promise(resolve => {
    const myInterval = setInterval(() => {
      if (scriptLoaded) {
        resolve();
        clearInterval(myInterval);
      }
    }, 100);
  });
}

/**
 *
 * @param {*} batch List of packages to be optimized
 *                  obs: In the first batch we could receive the driver's
 *                        position as the first element of the batch
 * @returns DirectionsRequest object as described in Google's documentation
 * https://developers.google.com/maps/documentation/javascript/directions#DirectionsRequests
 */
const getDirectionsRequest = (
  batch,
  minAllowedPerBatch = defaultMinAllowedPerBatch
) => {
  if (batch.length < minAllowedPerBatch) return batch;

  const firstIndex = 0;
  const lastIndex = batch.length - 1;

  const origin = batch[firstIndex];
  const destination = batch[lastIndex];
  const waypoints = batch.slice(firstIndex + 1, lastIndex);

  return {
    origin: getCoordinates(origin),
    destination: getCoordinates(destination),
    waypoints: waypoints.map(w => {
      return { location: getCoordinates(w), stopover: true };
    }),
    optimizeWaypoints: true,
    unitSystem: window.google.maps.UnitSystem.METRIC,
    travelMode: window.google.maps.TravelMode.DRIVING,
    drivingOptions: {
      departureTime: new Date(),
      trafficModel: 'bestguess'
    }
  };
};

/**
 * Calls Google's Directions API and process its response
 * response status ok -> order the list os packages(batch)
 * response not ok -> throws an error
 * @param {*} DirectionsService
 * @param {*} batch
 * @param {*} addOriginToFirstBatch
 * @param {Integer} minAllowedPerBatch minimum value allowed per batch
 * @returns
 */
const processGoogleRequest = async (
  DirectionsService,
  batch,
  addOriginToFirstBatch,
  minAllowedPerBatch
) => {
  const response = await DirectionsService.route(
    getDirectionsRequest(batch, minAllowedPerBatch)
  );
  if (response?.status === window.google.maps.DirectionsStatus.OK) {
    return orderByOptimization(
      batch,
      response.routes[0].waypoint_order,
      addOriginToFirstBatch
    );
  }
  throw new Error(`Error optimizing route ${response?.status}`);
};

/**
 * Optimize a list of packages passed as param, including the driver's position as origin
 * @param {*} packages
 * @returns List of packages ordered according to Google's API
 */
const optmizeRoute = async (
  packages,
  minAllowedPerBatch = defaultMinAllowedPerBatch
) => {
  await waitUntilScriptLoaded();

  const DirectionsService = new window.google.maps.DirectionsService();
  const driverPosition = await getPosition();
  const batches = makeBatches({ packages, driverPosition });

  const optimizableBatches = batches.filter(
    batch => batch.length >= minAllowedPerBatch
  );

  const optimizationRequests = optimizableBatches.map((batch, index) =>
    processGoogleRequest(
      DirectionsService,
      batch,
      shouldAddOriginToFirstBatch(index, driverPosition)
    )
  );

  return (
    Promise.all(optimizationRequests)
      // flatten ordered batches
      .then(orderedBatches => orderedBatches.flatMap(b => b))
      .then(orderedPoints => {
        const nonOptimizableBatch =
          batches.filter(batch => batch.length < minAllowedPerBatch)[0] || [];
        const organizedNonOptimizableBatch =
          batches.length > 1
            ? nonOptimizableBatch.slice(1)
            : nonOptimizableBatch;

        return [...orderedPoints, ...organizedNonOptimizableBatch];
      })
  );
};

export default optmizeRoute;
export { getDirectionsRequest };
