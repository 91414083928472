import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import constants from '../../templates/showcase/constants';

const LoadingShowcase = () => (
  <Box
    height="100%"
    alignItems="left"
    display="flex"
    width="100%"
    px={3}
    py={2}
    flexDirection="column"
  >
    <Box>
      <Typography color="textPrimary" variant="subtitle1">
        <Box fontWeight="fontWeightMedium" my={2}>
          {constants.FEATURED_OFFERS}
        </Box>
      </Typography>
    </Box>

    <Grid container spacing={2}>
      {/* eslint-disable react/no-array-index-key */}
      {Array.from({ length: 2 }).map((_, index) => (
        <Grid item xs={6} key={index}>
          <Skeleton
            variant="rect"
            style={{
              width: '100%',
              height: 220,
              borderRadius: '10px'
            }}
          />
          <Skeleton variant="text" style={{ width: '85%', height: 30 }} />
          <Skeleton variant="text" style={{ width: '60%', height: 30 }} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default React.memo(LoadingShowcase);
